import EntryFactory from './Entries/EntryFactory';
import GenericSpinner from '../GenericSpinner';
import Select2PageResult from '../Select2/Select2PageResult'
import consumer from '../../src/channels/consumer';
import ReviewQueueFilter from './Filters/ReviewQueueFilter';

class DashboardApp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      limit: this.props.limit,
      count: 0,
      total_pages: 0,
      previous_page: this.props.current_page == 1 ? 0 : parseInt(this.props.current_page) - 1,
      next_page: this.props.current_page == 1 ? 0 : parseInt(this.props.current_page) + 1,
      current_page: parseInt(this.props.current_page) || 1,
      dashboard: props.dashboard,
      assignedEntries: [],
      assignedLoading: true,
      entries: [],
      loading: true,
      id: 0,
      page: 1,
      limit: 20,
      filterParams: {
        institution: '',
        exam: '',
        testTaker: '',
        startDate: '',
        endDate: '',
      }
    }
  }

  componentDidMount() {
    const { id } = this.state.dashboard

    if (typeof(id) !== undefined && id !== null) {
      this.dashboardsChannel = consumer.subscriptions.create(
        { channel: 'DashboardsChannel', dashboardId: id },
        { received: data => this.handleMessage(data) }
      )
    }

    this.fetchEntries()
    this.fetchAssignedEntries()
  }

  componentWillUnmount() {
    this.dashboardsChannel.unsubscribe()
  }

  updateInstitutionId = (id) => {
    let filterParams = this.state.filterParams;
    filterParams['institution'] = id;
    this.setState({ filterParams: filterParams });
  }

  updateFilterState = (event) => {
    let id = event.currentTarget.id;
    let filterParams = this.state.filterParams;
        filterParams[id] = event.currentTarget.value;

    this.setState({ filterParams: filterParams });
  }

  submitFilterForm = (event) => {
    event.preventDefault();
    $('#submit').prop('disabled',true);
    let type = event.currentTarget.id;

    if (type === 'reset') {
      $('#select2-institution').val(null).trigger('change')
      
      const filterParams = this.state.filterParams;
      filterParams.exam = '';
      filterParams.testTaker = '';
      filterParams.startDate = '';
      filterParams.endDate = '';
      
      this.setState( {filterParams: filterParams })
    }
    this.fetchEntries();
  }

  fetchEntries() {
    const { current_page, dashboard: { slug }, limit } = this.state
    let urlParams = { page: current_page, per_page: limit, ...this.state.filterParams};
    let queryString = Object.keys(urlParams).map((key) => { return encodeURIComponent(key) + '=' + encodeURIComponent(urlParams[key]) }).join('&');
    fetch(`/internal/dashboards/${slug}/entries?${queryString}`)
      .then(response => response.json())
      .then(({ count, entries, total_pages, previous_page, next_page, current_page }) => {
        this.setState({
          entries,
          count,
          total_pages,
          previous_page,
          next_page,
          current_page,
        }, () => {this.clearLoading() })
        $('#submit').prop('disabled',false);
      })
  }

  clearLoading() {
    this.setState({ loading: false })
  }

  fetchAssignedEntries() {
    const { dashboard: { slug } } = this.state

    fetch(`/internal/dashboards/${slug}/entries/assigned`)
      .then(response => response.json())
      .then(({ entries }) => {
        this.setState({
          assignedEntries: entries,
          assignedLoading: false,
        })
      })
  }

  clearAssignedLoading() {
    setTimeout(_ => this.setState({ assignedLoading: false }), 250)
  }

  handleMessage = (data) => {
    const { assignedEntries, entries, limit  } = this.state
    const { action, entry, id} = data

    const out = (entry) => ((e) => (e.id !== entry.id))
    const disabled = (entry) => (entry.disabled !== true)
    const byCompleteBy = (a, b) => (new Date(a.data.complete_by) - new Date(b.data.complete_by))

    switch(action) {
      /*
        When a 'pickup' message is recieved, we need to remove it from entries
        and add it to assignedEntries if the current_user is the user that
        performed the action.
      */
      case "pickup":
        // `pickup` action received that was initiated by this user
        if (entry.user_id === window.data.userId) {
          this.setState({ assignedEntries: [...assignedEntries, entry], })
        }

        // Iterate over entries and set updated entry to disabled (if it's in the entries)
        //  otherwise do nothing
        this.setState({
          entries: entries.map(target_entry => (
            entry.id === target_entry.id ? {...entry, disabled: true} : target_entry
          ))
        })

        break;
      /*
          When a 'drop' message is recieved, we need to add the entry back to
          entries and remove it from assignedEntries
      */
      case "drop":
        const myEntry = assignedEntries.find(e => e.id === entry.id)
        const myDisabledEntry = entries.find(e => (e.id === entry.id && e.disabled === true))

      // 1. Assigned and displayed disabled on my screen - re-enable entry
      // 2. Assigned and not displayed on my screen - sort into my entries
      // 3. Not assigned to me but displayed disabled on my screen - re-enable entry
      // 4. Not assigned to me and not displayed on my screen - do nothing
        if (myDisabledEntry) { // case 1 and 3
          this.setState({ entries: entries.map(target_entry => entry.id === target_entry.id ? entry : target_entry) })
        } else if (myEntry) { // case 2
          this.setState({ entries: [...entries.filter(out(entry)), entry].sort(byCompleteBy).slice(0, limit) })
        } // case 4

        this.setState({
          assignedEntries: assignedEntries.filter(out(entry))
        })
        break;
      /*
        A 'remove' message informs the frontend that an entry was completed and
        removed from the entries table.
        In this instance, we need to remove the item from `entries` and `assignedEntries`
      */
      case "remove":
        const myDisplayedEntry = entries.find(e => (e.id === id))
        // Just set removed (completed) entries to disabled
        this.setState({
          assignedEntries: assignedEntries.filter(out({id: id})),
          entries:
            entries.map(target_entry => id === target_entry.id ? {...myDisplayedEntry, disabled: true} : target_entry),
        })
        break;
      /*
        An 'add' message informs the frontend that an entry was added to the entries table.
        In this instance, we need to add the entry to `entries`
      */
      case "add":
        if (entries.length < limit) {
          this.setState({entries: [...entries, entry]})
        }
        break;
    }
  }

  renderEntries = (entries) => {
    return (
      <div>
        <table className='table table-semibordered'>
          <thead>
            <tr>
              <th>Details</th>
              <th>Time</th>
              <th>Type</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            { entries.map((entry) => { return this.renderEntry(entry) }) }
          </tbody>
        </table>
      </div>
    )
  }

  renderEntry = (entry) => {
    return (
      <EntryFactory
        key={entry.id}
        entry={entry}
        renderAction={this.renderAction }
      />
    )
  }

  handleAction = (entryAction, entryId, userId) => {
    this.dashboardsChannel.send({ entryId, entryAction, userId})
  }

  renderAction = (action, entryId) => {
    return (
      <div
        key={action.event}
        className='btn btn-secondary btn-sm ml-2'
        onClick={_ => this.handleAction(action.event, entryId, window.data.userId)}
      >
        {action.name}
      </div>
    )
  }

  renderNothing() {
    return <h3 className="text-center p-4">Great job! You're all caught up.</h3>
  }

  renderSections = () => {
    const {
      assignedEntries,
      assignedLoading,
      entries,
      limit,
      loading,
      dashboard: {
        settings: {
          show_assigned_section
        }
      }
    } = this.state
    return (
      <div>
        { show_assigned_section && this.renderSection('Assigned To Me', assignedEntries, assignedLoading, false) }
        { this.renderSection('Ready for Review', entries.slice(0, limit), loading, true) }
      </div>
    )
  }

  renderSection(title, entries, loading, paginationEnabled) {
    return (
      <div className="card mb-4">
        <div className="card-header">{title}</div>
        { entries.length > 0 ? this.renderEntries(entries) : this.renderNothing() }
        { paginationEnabled && this.renderPagination() }
      </div>
    )
  }

  renderPagination() {
    const {
      limit,
      previous_page,
      next_page
    } = this.state

    const previousPageDisabled = previous_page == null ? 'disabled' : ''
    const nextPageDisabled = next_page == null ? 'disabled' : ''
    return (
      <div className="row p-2">
        <div className="col-sm-12 col-md-3">
          <Select2PageResult result={limit} />
        </div>
        <div className="col-sm-12 col-md-9 text-right">
          <a href="#"
            className={`btn btn-secondary btn-sm mr-2 ${previousPageDisabled}`}
            onClick={  (event) => { this.getPreviousPage(event) } }
          >
            Previous
          </a>
          <a href="#"
            className={`btn btn-secondary btn-sm ${nextPageDisabled}`}
            onClick={ (event) => { this.getNextPage(event) } }
          >
            Next
          </a>
        </div>
      </div>
    )
  }

  getNextPage = (event) => {
    event.preventDefault()
    const { current_page } = this.state
    this.setState({ current_page: (current_page + 1), loading: true }, () => {
      this.fetchEntries()
    })
  }

  getPreviousPage = (event) => {
    event.preventDefault()
    const { current_page } = this.state
    this.setState({ current_page: current_page - 1, loading: true }, () => {
      this.fetchEntries()
    })
  }

  render() {
    let filterDiv = (
      <ReviewQueueFilter
        updateFilterState={ this.updateFilterState }
        updateInstitutionId={ this.updateInstitutionId }
        submitFilterForm={ this.submitFilterForm }
        filterParams={ this.state.filterParams } />
    );
    return (
      <div>
        <h1 className="mb-4 fs-3"> { this.props.dashboard.name } Dashboard </h1>
        <div className='row mb-4'>
            <div className='col-md-12'>
              <div className='card'>
                { filterDiv }
              </div>
            </div>
          </div>
        <div className="card">
          <div className="card-body">
            { this.state.loading ? <GenericSpinner /> : this.renderSections() }
          </div>
        </div>
      </div>
    )
  }
}

export default DashboardApp;
