import PrechecksHeaderGroup from './CardSections/PrechecksHeaderGroup';
import PrechecksFooterButton from './CardSections/PrechecksFooterButton';
import { useEffect } from 'react';
import parse from 'html-react-parser';

const OtherResourcesCard = ({
  id,
  icon,
  iconPrefix,
  title,
  buttonText,
  handleNext,
  sendEvent,
  precheckData,
}) => {
  const otherResources = precheckData.otherResources;
  const resourcesImg = precheckData.images.resourcesImg;

  useEffect(() => {
    sendEvent('Event::OtherResourcesLoaded');
  }, []);

  const handleBtnClick = () => {
    sendEvent('Event::OtherResourcesCompleted');
    handleNext();
  };

  const renderOtherResource = () => {
    return (
      <div className="text-left"> {<div>{parse(otherResources)}</div>}</div>
    );
  };

  return (
    <div className="container-fluid text-center" id={id}>
      <PrechecksHeaderGroup
        title={title}
        image={resourcesImg}
        icon={icon}
        iconPrefix={iconPrefix}
      />
      {renderOtherResource()}
      <PrechecksFooterButton
        buttonText={buttonText}
        handleClick={handleBtnClick}
      />
    </div>
  );
};

OtherResourcesCard.defaultProps = {
  titleResources: polyglot.t('prechecks_other_resources_title'),
  icon: 'fa-pen-alt',
  statusColor: 'primary',
  iconPrefix: 'fal',
  title: polyglot.t('prechecks_other_resources_title'),
  buttonText: polyglot.t('prechecks_other_resources_next_button'),
};

export default OtherResourcesCard;
