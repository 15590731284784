import Callbacks from '../utils/Callbacks.js';
import csrfToken from '../utils/csrf'
import Toast from '../utils/Toast';
import urlEncoder from '../utils/urlEncoder';
import CancelledAppointment from '../../components/Cancellations/CancelledAppointment';

class CancellationModal {
  constructor() {
    // Bootstrap runs on jQuery, so we need jQuery here

    this.cancelModalBtn = document.querySelector(
      '[data-behavior="cancel-modal-button"]'
    );
    this.submitBtn = document.querySelector('.js-btn-cancel');

    this.handleReasonChange = this.handleReasonChange.bind(this);
    this.submitCancellation = this.submitCancellation.bind(this);
    this.createCancellationMessage = this.createCancellationMessage.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.redirectToCart = this.redirectToCart.bind(this);
  }

  init() {
    this.bindEventListeners();
  }

  bindEventListeners() {
    $('#cancel-modal').on(
      'show.bs.modal',
       (e) => {
        this.cancelResBtn = e.relatedTarget;
        this.cancelResBtnData = this.cancelResBtn.dataset;
        this.formUrl = this.cancelResBtnData.formUrl;
        this.confirmMessage = this.cancelResBtnData.submitConfirm;
        this.isAdmin = !!document.querySelector('.cancel-first-name');
        this.stopSession = this.cancelResBtnData.stopSession == 'true';
        this.fulfillmentUuid = this.cancelResBtnData.fulfillmentUuid;
        this.isWaived = document.querySelector('input[name=cancellation_fee]')
        this.forceReasonChange();
        this.populateModal();
      }
    );
    document
      .querySelector('[data-behavior="cancel-modal-button"]')
      .addEventListener('click', this.handleCancelClick);
    this.submitBtn.addEventListener('click', this.submitCancellation);
    document
      .querySelector('select#reason')
      .addEventListener('change', this.handleReasonChange);
  }

  /**
   * Checks to see whether the selected reason required comments
   * If it does, adds 'required' astrisk and triggers handleCommentChange
   */
  handleReasonChange() {
    if (this.commentsRequired()) {
      document
        .querySelector('[for="explanation"].col-form-label')
        .classList.add('required');
    } else {
      document
        .querySelector('[for="explanation"].col-form-label')
        .classList.remove('required');
    }
  }

  handleCancelClick(e) {
    e.preventDefault();
    this.closeModal();
    this.redirectToCart();
  }

  closeModal() {
    document.querySelector('#cancel-modal textarea').value = '';
    $('#cancel-modal').modal('hide');
    this.resetSubmitBtn();
  }

  resetSubmitBtn() {
    this.submitBtn.classList.remove('disabled');
    this.submitBtn.value = 'Submit';
    this.cancelModalBtn.classList.remove('disabled');
  }

  submitCancellation(e) {
    e.preventDefault();

    //If exam is not a ptc exam the isWaived checkbox will be null
    if (this.isAdmin && this.isWaived != null && !this.isWaived.checked ){
      alert('You cannot cancel this exam without agreeing to waive the test taker\'s cancellation fee.');
      this.resetSubmitBtn();
      return;
    }

    // if operating through Chrome extension
    if (this.fulfillmentUuid && this.stopSession) {
      this.createCancellationMessage();
    }

    this.submitBtn.classList.add('disabled');
    this.submitBtn.value = 'Submitting...';
    this.cancelModalBtn.classList.add('disabled');

    this.createCancellation()
      .then(response => response.json())
      .then(response => {
        const errorEl = document.querySelector(
          '[data-element="cancellation-explanation-error"]'
        );

        this.cancellation_fee_in_cart = response['cancellation_fee_in_cart']

        if (response.errors) {
          let messages;
          if (response.errors.explaination) {
            messages = response.errors.explaination.join(', ');
            errorEl.innerText = messages;
            errorEl.classList.remove('display-none');
          } else {
            // if the error is not on the explaination column we want to display
            // a toast instead
            messages = response.errors.external_cancellation.join(', ');
            new Toast().danger({ message: messages });
          }
          this.resetSubmitBtn();
        } else {
          this.updatePageAfterCancellation(errorEl);
        }
      });
  }

  /**
   * Cancel the flight path if on flight path page, otherwise
   * remove the tr that contains the 'Cancel' button that was pushed.
   * Additionally, we clear the Countdown card if on the student reservations page
   */
  updatePageAfterCancellation(errorEl) {
    const flightPathExists = document.querySelector('.js-flightpath-card');
    if (flightPathExists) {
      this.cancelFlightPath();
      this.adjustReservationPanel();
    } else {
      // If on any other page, remove relevant table row
      this.cancelResBtn.closest('tr').remove(0);
    }

    const reservationCountdown = document.querySelector(
      '.js-row-panel-countdown'
    );
    // Remove the countdown panel, because we cannot promise the exam they
    // canceled is the closest one or not, so the countdown could be wrong.
    if (reservationCountdown) {
      reservationCountdown.remove();
    }

    errorEl.classList.add('display-none');
    errorEl.innerText = '';
    this.closeModal();
    this.redirectToCart();
  }

  /*
   * Disables the flight path component and removes buttons
   */
  cancelFlightPath() {
    const newNode = document.createElement('div');
    newNode.id = 'alert-container';
    document.querySelector('.card').after(newNode);
    if(!document.querySelector('.js-show-disabled-flight-path')) {
      ReactDOM.render(
        <CancelledAppointment />,
        document.querySelector('#alert-container')
      );
      document
        .querySelector('.js-flightpath-card')
        .classList.add('not-authorized');
      document.querySelector('.btn-danger').remove(0);
      document.querySelector('.btn-success').remove(0);
    }
  }

  adjustReservationPanel() {
    document.querySelector('[data-behavior="session-status-dd"]').innerText =
      'Cancelled';
    document.querySelector(
      '[data-behavior="appointment-status-dd"]'
    ).innerText = 'Cancelled';
  }

  createCancellation() {
    const cancellationsUrl = this.formUrl;
    const xvoucherOption = document.querySelector('input[name=xvoucher]:checked')

    // if any of these params are `null` our system tests break!
    const params = {
      cancellation: {
        cancellation_reason_id: document.querySelector('select#reason').value,
        explaination: this.populateExplanation(),
        xvoucher_excused: !!xvoucherOption && xvoucherOption.value === 'excused',
        credit: document.querySelector('input[name=credit]:checked').value,
        is_waived: this.isWaived == null ? false : this.isWaived.checked
      }
    };

    const symbol = cancellationsUrl.includes('?') ? '&' : '?';
    if (!this.confirmMessage || confirm(this.confirmMessage) == true) {
      return fetch(`${cancellationsUrl}${symbol}${urlEncoder.encode(params)}`, {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          // Accept: '*/*',
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': csrfToken()
        }
      });
    }
  }

  /*
   * Handles chrome extension cancellation messages
   */
  createCancellationMessage() {
    const messages_params = {
      uuid: this.fulfillmentUuid
    };
    fetch(`/api/cancellation_messages?${urlEncoder.encode(messages_params)}`, {
      method: 'POST',
      credential: 'same-origin',

      headers: {
        Accept: '*/*',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': csrfToken()
      }
    });
  }

  forceReasonChange() {
    const event = new Event('change');
    document.querySelector('select#reason').dispatchEvent(event);
  }

  populateModal() {
    if (this.isWaived  != null){
      this.isWaived.checked = false
    }
    if (this.isAdmin) {
      document.querySelector(
        '.cancel-first-name'
      ).innerHTML = this.cancelResBtnData.firstName;
    }
    document.querySelector(
      '.cancel-exam-name'
    ).innerHTML = this.cancelResBtnData.examName;
    document.querySelector(
      '.cancel-starts-at'
    ).innerHTML = this.cancelResBtnData.startsAt;
  }

  populateExplanation() {
    const userCancel = this.isAdmin ? 'Proctor' : 'Test-Taker';
    return document.querySelector('textarea#explanation').value || `Session cancelled by ${userCancel}`;
  }

  /*
   * Checks whether the currently selected reason requires an explanation
   */
  commentsRequired() {
    this.selectedReason = document.querySelector('select#reason').options[
      document.querySelector('select#reason').selectedIndex
    ];
    const selectedReasonId = this.selectedReason.value;
    const reasonsNeedingCommentIds = this.cancelResBtn
      .getAttribute('data-comments-required-ids')
      .slice(1, -1)
      .split(',');
    return reasonsNeedingCommentIds.indexOf(this.selectedReason.value) > -1;
  }

  redirectToCart() {
    if (this.cancellation_fee_in_cart) {
      window.location.href = window.location.protocol + "//" + window.location.host + '/students/order';
    }
  }
}

export default CancellationModal;
