/**
 * This class handles the Session Packaging Configuration section on the Institution form
 */

 class SessionPackagingConfiguration {
  constructor() {
    this.TransportFulfillmentsCheckbox = document.querySelector(
      '[data-behavior=transport-fulfillments-checkbox]'
    );
    this.TransportFulfillmentsInDays = document.querySelector(
      '[data-element="transport-fulfillments-in-days"]'
    )
    this.toggleSessionPackaging = this.toggleSessionPackaging.bind(this);
  }

  init() {
    this.bindEventListeners()
    this.toggleSessionPackaging()
  }

  bindEventListeners() {
    this.TransportFulfillmentsCheckbox.addEventListener('change', this.toggleSessionPackaging);
  }

  toggleSessionPackaging() {
    this.randomizationSection = $('#randomization_section')
    this.fulfillmentDaysSection = this.TransportFulfillmentsInDays.parentElement.parentElement
      if (this.TransportFulfillmentsCheckbox.checked ) {
        this.fulfillmentDaysSection.style.display = ''
        this.randomizationSection.show()
        this.TransportFulfillmentsInDays.disabled = false
      } else {
        this.fulfillmentDaysSection.style.display = 'none'
        this.randomizationSection.hide()
        this.TransportFulfillmentsInDays.disabled = true
      }
  }
}

export default SessionPackagingConfiguration;
