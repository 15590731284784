import React from 'react';

const WizardStepMenu = ({ currentStep, stepMenu }) => {
  return (
    <>
      <div
        id="stepper-default"
        className="d-flex justify-content-between flex-wrap"
      >
        {stepMenu.map(({ menuIdx, menuLabel }, index) => (
          <div
            className="font-size-075 d-block flex-fill step-container"
            key={`divOuter_${index}`}
          >
            <p
              className="lms-progress-bar-text text-left"
              key={`menuText_${index}`}
            >
              {menuLabel}
            </p>
            <div
              key={`divInner_${index}`}
              className={`precheck-progress-bar ${
                menuIdx <= currentStep && 'btn-precheck-primary'
              } lms-progress-bar-width`}
            ></div>
          </div>
        ))}
      </div>
      <div
        id="stepper-mobile"
        className="d-flex justify-content-between flex-wrap"
      >
        <div className="w-100 lms-progress-bar-text small">
          {stepMenu.find((step) => step.menuIdx === currentStep).menuLabel}
        </div>
        {stepMenu.map(({ menuIdx }, index) => (
          <div
            className="font-size-075 d-block flex-fill"
            key={`divOuter_${index}`}
          >
            <div
              key={`divInner_${index}`}
              className={`precheck-progress-bar ${
                menuIdx <= currentStep && 'btn-precheck-primary'
              } lms-progress-bar-width`}
            ></div>
          </div>
        ))}
      </div>
      <div className="w-100 font-size-075 step-count text-right">
        {currentStep} / 6 steps
      </div>
    </>
  );
};

export { WizardStepMenu };
