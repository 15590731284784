import axios from 'axios';

class TotalCount extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      count: 0,
    };
  }

  fetchCount() {
    axios
      .get(this.props.url, { params: this.props.params })
      .then((response) => {
        this.setState({ loading: false, count: response.data.count });
      })
      .catch((error) => {
        this.setState({ loading: false, count: 'N/A' });
      });
  }

  componentDidMount() {
    this.fetchCount();
  }

  renderCount() {
    return <span>{this.state.count}</span>;
  }

  render() {
    return (
      <span>
        -{' '}
        {this.state.loading ? (
          <i className="fa fa-circle-notch fa-spin"></i>
        ) : (
          this.renderCount()
        )}
      </span>
    );
  }
}

export default TotalCount;
