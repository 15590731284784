import AdjustContentHeight from './shared_actions/AdjustContentHeight';
import ShowNextTab from './shared_actions/ShowNextTab';
import ShowPreviousTab from './shared_actions/ShowPreviousTab';
import UpdateNextAndPreviousButtons from './shared_actions/UpdateNextAndPreviousButtons';

class FlightPathNav {
  constructor() {
    this.flightPathTabs = document.querySelectorAll('.nav-flightpath li a')
  }

  init() {
    this.flightPathTabs.forEach((tab, index) => {
      tab.textContent = index + 1;
      tab.addEventListener('click', (event) => {
        this.handleClick(event, tab)
      });
    });
  }

  handleClick(event, tab) {
    event.preventDefault();
    if(document.querySelector('.nav-flightpath li.active')) {
      const activeTab = parseInt(document.querySelector('.nav-flightpath li.active').textContent)
      const clickedTab = parseInt(tab.textContent)
      if (clickedTab < activeTab) {
        new ShowPreviousTab()
      } else if(clickedTab > activeTab) {
        new ShowNextTab()
      }
    }
    new UpdateNextAndPreviousButtons().init();
  }
}

export default FlightPathNav;
