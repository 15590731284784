class Select2Proctors extends React.Component {
  constructor(props) {
    super(props);
    this.$selector = '#select2-proctors';
    this.url = props.url;
  }

  componentDidMount() {
    $(this.$selector).select2({
      placeholder: 'Find by Name',
      theme: 'bootstrap4',
      delay: 500,
      width: 'auto',
      dropdownParent: $('#transfer-form-modal'),
      ajax: {
        url: this.props.url,
        dataType: 'json',
        data: function (search) {
          return { search: search.term };
        },
        processResults: function (data) {
          return { results: data };
        },
      },
      allowClear: true,
    });
  }

  render() {
    return (
      <div>
        <label htmlFor="proctor" className="required">
          Proctor
        </label>
        <select
          name="Proctor"
          id="select2-proctors"
          placeholder="Find by Name"
          className="form-control transfer-proctor"
        ></select>
      </div>
    );
  }
}

export default Select2Proctors;
