import moment from 'moment';
import Slots from './Slots';
import SlotGreeting from './SlotGreeting';
import axios from 'axios';

class FindSlotsButton {
  constructor() {
    this.findSlotsButton = document.querySelector('#js-find-slots')
  }

  init() {
    if(this.findSlotsButton) {
      this.findSlotsButton.addEventListener('click', () => { 
        new SlotGreeting().hide();
        new Slots().init()
      })
    }
  }
}

 export default FindSlotsButton;
