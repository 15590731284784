class LocalTime {
  constructor() {
    if (document.querySelector('#local-time') == null) {
      return;
    }
    var localTime = document.querySelector('#local-time').dataset.event
    this.date = new Date(localTime);
  }

  init() {
    if (this.date) {
      var browserTime = this.getLocalTimeFromBrowser();
      var tag = document.querySelector('#local-time')
      tag.textContent = browserTime
    }
  }

  getLocalTimeFromBrowser() {
    var options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      timeZoneName: "short"
    };
    var formattedDate = this.date.toLocaleDateString("en-us", options);
    return formattedDate;
  }
}

export default LocalTime;
