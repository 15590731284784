import Logger from '../../shared/Logger';
import csrfToken from '../../utils/csrf';

class EventManager {
  constructor() {
    this.EventButtons = document.querySelectorAll(
      "[data-behavior='event-button']"
    );
    this.handleEventButtonClick = this.handleEventButtonClick.bind(this);
  }

  init() {
    this.bindEventListeners();
  }

  bindEventListeners() {
    [...this.EventButtons].forEach(button => {
      button.addEventListener('click', this.handleEventButtonClick);
    });
  }

  handleEventButtonClick(event) {
    const fulfillmentUuid = event.target.dataset.fulfillmentUuid;
    const eventType = event.target.dataset.type;
    this.sendEvent(eventType, fulfillmentUuid);
  }

  sendEvent(eventName, fulfillmentUuid) {
    const eventData = { type: eventName, uuid_mode: true, event: { comment: eventName }};
    const url = `/fulfillments/${fulfillmentUuid}/events`;

    let logger = new Logger();
    logger.setContext({
      fulfillmentUuid: fulfillmentUuid,
      eventName: eventName
    });

    logger.info('Sending Event through EventManager');

    return fetch(url, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken()
      },
      body: JSON.stringify(eventData)
    }).catch(error => logger.error('EventManager Error', error));
  }
}

export default EventManager;
