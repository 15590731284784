import React from 'react';

import { LMI_STATUS } from './lib/lmiUtils';

/**
 * @typedef {Object} TestUiProps
 *
 * @property {boolean} isLoading
 * @property {Function} handleTestStart
 * @property {{data: string, origin: string}[]} messages
 * @property {typeof LMI_STATUS} status
 * @property {typeof LMI_STATUS} serverStatus
 * @property {string} failureReason
 */

/**
 * The UI for the LMI chat test application.
 *
 * Contains the view for the LMI chat test application.
 *
 * @param {TestUiProps} props
 * @returns {React.ReactElement]
 */
export default function TestUi({
  isLoading,
  handleTestStart,
  messages,
  status,
  serverStatus,
  failureReason,
}) {
  return (
    <main className="mt-4">
      <div className="card">
        <div className="card-header">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <button
                className="btn btn-precheck-primary"
                onClick={handleTestStart}
                disabled={isLoading}
                style={{ cursor: isLoading ? 'wait' : 'pointer' }}
              >
                {isLoading ? (
                  <div
                    className="spinner-border"
                    style={{
                      width: '1rem',
                      height: '1rem',
                      cursor: 'wait',
                      fontSize: '1rem',
                    }}
                    role="status"
                    disabled
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  'Test LMI Chat'
                )}
              </button>
            </div>

            <div>
              {status === LMI_STATUS.SUCCESS && (
                <div>
                  <span className="badge badge-success ms-2">Success</span>
                </div>
              )}

              {status === LMI_STATUS.FAILURE && (
                <div>
                  <span className="badge badge-danger ms-2">Failure</span>
                  <p className="text-muted">{failureReason}</p>
                </div>
              )}
            </div>

            <div>
              <span className="p-2 badge badge-secondary">
                LMI Servers:{' '}
                {serverStatus === null
                  ? '🟡'
                  : serverStatus === LMI_STATUS.SUCCESS
                    ? '🟢'
                    : '🔴'}
              </span>
            </div>
          </div>
        </div>

        <div className="card-body" style={{ textAlign: 'left' }}>
          {messages.length === 0 &&
            (isLoading
              ? 'Waiting for a message...'
              : 'Click the button to begin testing the LMI chat application.')}

          {messages.length > 0 &&
            messages.map((message, index) => (
              <div key={index}>
                <span className="badge badge-success mr-2">
                  {message.origin}
                </span>
                <code>{JSON.stringify(message.data)}</code>
                <hr />
              </div>
            ))}
        </div>
      </div>
    </main>
  );
}
