import { createConsumer } from '@rails/actioncable';

const consumer = createConsumer();

class SecondCameraSubscription {
  constructor(fulfillmentUuid, callback) {
    this.identifier = {
      channel: 'SecondCameraChannel',
      fulfillment_uuid: fulfillmentUuid,
    };
    this.secondCamera = null;
    this.callback = callback;
  }

  subscribe() {
    console.log('second_camera#subscribing');
    let cb = this.callback;
    this.secondCamera = consumer.subscriptions.create(this.identifier, {
      received: (data) => {
        console.log('Received: ', data);
        cb();
      },
      connected: () => {
        console.log('Connected');
      },
    });
  }

  unsubscribe() {
    this.secondCamera.unsubscribe();
  }
}

export default SecondCameraSubscription;
