class DownloadBrowserModal {
  constructor() {
    this.downloadBrowserModal = $('#download-browser-modal');
  }
  init() {
    if (this.downloadBrowserModal) {
      this.downloadBrowserModal.modal()
    }
  }
}

export default DownloadBrowserModal