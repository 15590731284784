document.addEventListener('DOMContentLoaded', () => {
  const passwordField = document.getElementById('password-field');
  const passwordToggle = document.getElementById('password-toggle');
  const passwordConfirmationField = document.getElementById(
    'password-confirmation-field',
  );
  const passwordConfirmationToggle = document.getElementById(
    'password-confirmation-toggle',
  );

  /**
   * @param {HTMLInputElement} field
   * @param {HTMLElement} toggle
   * @returns {void}
   */
  const togglePasswordVisibility = (field, toggle) => {
    if (!field || !toggle) return;

    toggle.addEventListener('click', () => {
      switch (field.type) {
        case 'password':
          field.type = 'text';
          toggle.classList.remove('fa-eye');
          toggle.classList.add('fa-eye-slash');
          break;
        default:
          field.type = 'password';
          toggle.classList.remove('fa-eye-slash');
          toggle.classList.add('fa-eye');
          break;
      }
    });
  };

  togglePasswordVisibility(passwordField, passwordToggle);
  togglePasswordVisibility(
    passwordConfirmationField,
    passwordConfirmationToggle,
  );
});
