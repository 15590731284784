class Split {
  constructor() {
    this.iterationTypeSelector =
      document.querySelector('[data-element="iteration-types"]') ||
      document.querySelector('[data-element="iteration-type-hidden"]');

    this.iterationDepartment = document.querySelector('[data-element="iteration-department"]');
  }

  init() {
    if (!this.hasSplit()) {
      this.setSplit()
    }
  }

  hasSplit() {
    const splitEl = document.querySelector('[name="iteration[split_id]"]');
    if (!splitEl) return false;
    return splitEl.value == '' ? false : true;
  }

  setSplit() {
    let department = this.iterationDepartment;
    let selectedDepartment = department.options[department.selectedIndex];
    let examTypeSelected = null;
    if (document.querySelector('[data-staff]').dataset.staff === 'false') {
      examTypeSelected = this.iterationTypeSelector.value
    } else {
      let examType = this.iterationTypeSelector;
      examTypeSelected = examType.options[examType.selectedIndex].value;
    }
    this.updateSplit(selectedDepartment, examTypeSelected)
  }

  updateSplit(selectedDepartment, examTypeSelected) {
    let splitId = null;
    if (examTypeSelected === 'Iteration::Automatic') {
      splitId = selectedDepartment.getAttribute('data-auto-split');
    } else {
      splitId = selectedDepartment.getAttribute('data-default-split');
    }
    document.querySelector('#iteration_split_id').value = splitId;
  }
}

export default Split;
