import axios from 'axios';
import {useEffect, useState} from 'react';
import { Card } from 'react-bootstrap';
import { snakeCase, mapKeys } from 'lodash';
import EnteredIntoExamProcedure from "./GlProcedures/EnteredIntoExamProcedure";
import InstructionsProcedure from "./GlProcedures/InstructionsProcedure";
import PhoneCheckProcedure from "./GlProcedures/PhoneCheckProcedure";
import ResourceValidationProcedure from "./GlProcedures/ResourceValidationProcedure";
import SecureEnvironmentProcedure from "./GlProcedures/SecureEnvironmentProcedure";
import VerifyIdProcedure from "./GlProcedures/VerifyIdProcedure";
import VerifyTestSubmissionProcedure from "./GlProcedures/VerifyTestSubmissionProcedure";
import FulfillSessionProcedure from "./GlProcedures/FulfillSessionProcedure";

const GlFlightpathProcedure = (props) => {
	const procedure = props.procedure;
	const procIndex = props.proc_index;
	const procType = procedure.proc_type;
	const procName = procedure.proc_name;
	const procContent = procedure.proc_content;
	const procPosition = procedure.position;
	const sendEvent = props.sendEvent;
	const handleNext = props.handleNext;
	const openModal = props.openModal;
	const closeModal = props.closeModal;
	const markProcCompleted = props.markProcCompleted;
	const getFulfillmentEvents = props.getFulfillmentEvents;
	const fulfillment = props.fulfillment
	const completedProcedureMap = props.completedProcedureMap
	const callbacks = props.callbacks
	const getVideoPlayerEvent = props.getVideoPlayerEvent
	const procedureCompleted = props.procedureCompleted
	const disabledBtn = props.disabledBtn

	const showInPlayer = (event) => {
    callbacks.fire({
      type: 'showEvent',
      data: { 
				incident: event
			}
    })
  }

	const PROCEDURE_MAP = {
		'verify_id': VerifyIdProcedure,
		'resource_validation': ResourceValidationProcedure,
		'secure_environment': SecureEnvironmentProcedure,
		'phone_check': PhoneCheckProcedure,
		'instructions': InstructionsProcedure,
		'enter_into_exam': EnteredIntoExamProcedure,
		'verify_test_submission': VerifyTestSubmissionProcedure,
		'fulfill_session': FulfillSessionProcedure
	};

	const CurrentProcedureComponent = PROCEDURE_MAP[procType];
	return (
		<div className={`tab-pane tab-incomplete active in`} id={`proc_${procedure.id}`}>
			<div className={'row mb-4'}>
				<div className={'col-md-12'}>
					<h4>{procName}</h4>
					<br/>
					<p dangerouslySetInnerHTML={{__html: procContent}} />
					<br/>
					<br/>
					<CurrentProcedureComponent
						procedure={procedure}
						sendEvent={sendEvent}
						handleNext={handleNext}
						markProcCompleted={markProcCompleted}
						openModal={openModal}
						closeModal={closeModal}
						getFulfillmentEvents={getFulfillmentEvents}
						fulfillment={fulfillment}
						procedureCompleted={procedureCompleted}
						showInPlayer={showInPlayer}
						getVideoPlayerEvent={getVideoPlayerEvent}
						disabledBtn={disabledBtn}
					/>
				</div>
			</div>
		</div>
)
}

export default GlFlightpathProcedure;
