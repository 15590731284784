import Logger from '../../shared/Logger';
import csrfToken from '../../utils/csrf';
class IframeCheck {
  constructor() {
    this.captureClickEvent = this.captureClickEvent.bind(this);

    this.token = csrfToken();
  }

  init() {
    if (window.location != window.parent.location) {
      document.querySelectorAll('.start_button').forEach((link) => {
        link.setAttribute('target', '_blank');
        if (link.href.endsWith('auto')) {
          link.addEventListener('click', (e) => {
            this.captureClickEvent(e);
          })
        }
      })
    }
  }

  captureClickEvent(e) {
    new Logger().captureMessage('Start Session was clicked in an Iframe');

    this.sendEvent(e.currentTarget.id);
  }

  sendEvent(id) {
    return fetch(`/internal/iframe-events`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': this.token,
        Accept: 'application/json',
        dataType: 'application/json',
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        event: 'Event::IframeDetected',
        uuid: id
      })
    });
  }


}

export default IframeCheck;
