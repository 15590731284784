class CheckAllCheckBox {

  constructor() {
    this.checkBox = document.querySelector('.check-all')
  }
  init() {
    this.checkBox.addEventListener('change', (event) => { this.handleClick(event) })
  }

  handleClick(event) {
    document.querySelectorAll('#alter_').forEach((checkbox) => {
      if (event.currentTarget.checked) { checkbox.checked = true }
      else { checkbox.checked = false; }
    })
  }
}

export default CheckAllCheckBox
