import {Collapse, Button} from "react-bootstrap"
import PropTypes from 'prop-types'
import axios from 'axios';

import NotepadForm from './NotepadForm';
import IncidentModal from './IncidentModal';
import FulfillmentVideoHelper from "../../../utils/FulfillmentVideoHelper";
import csrfToken from '../../../src/utils/csrf'
import Toast from '../../../src/utils/Toast';

/**
 * React Component for 'notepad' component on fulfillments#show.
 */
class Notepad extends React.Component {
  constructor(props) {
    super(props)

    // ref
    this.notepadForm
    this.state = {
      comment: '',
      incidentSubtypeId: '',
      chat_log: '',
      modalError: false,
      modalErrors: '',
      expanded: true,
    }
    this.initialState = this.state
  }

  linkToNotepad = (event) => {
    event.nativeEvent.stopPropagation()
    event.preventDefault()
    let url = this.props.fulfillment.notepad_url
    const fulfillmentVideoHelper = new FulfillmentVideoHelper(this.props.fulfillment.uuid, this.props.proctorId, false, null);
    let sender = 'archimedes_fulfillment_page_ww_btn';
    let message = fulfillmentVideoHelper.constructWsMessage(sender);
    fulfillmentVideoHelper.broadcastMessage(message);
    fulfillmentVideoHelper.showPauseModel(true);
    window.open(url, '_blank', 'height=525,width=384,noopener')
    event.stopPropagation()
  }

  openModal = () => {
    let component = ReactDOM.findDOMNode(this),
      $modal = $(component.querySelector('.modal-incident'))

    $modal.modal('show')
    if (this.state.comment) {
      $modal.find('.js-focus-urgency').addClass('warning-fade')
      $modal.find('.js-focus-comment').removeClass('warning-fade')
    } else {
      $modal.find('.js-focus-comment').addClass('warning-fade')
      $modal.find('.js-focus-urgency').removeClass('warning-fade')
    }
  }

  updateComment = (event) => {
    let value = event.currentTarget.value
    this.setState({ comment: value })
  }

  updateIncidentSubtype = (event) => {
    let value = event.currentTarget.value
    this.setState({ incidentSubtypeId: value })
  }

  updateChatLog = (event) => {
    let value = event.currentTarget.value
    this.setState({ chat_log: value })
  }

  /**
   * Sends a request to server to file Incident Report
   */
  saveIncident = (isFulfillmentCancelled, event) => {
    if (this.state['incidentSubtypeId'] == '') {
      alert("Choose an Incident Type")
      return
    }

    event.preventDefault()
    let button = event.currentTarget,
      url = this.props.fulfillment.events_url,
      cancellation_url = this.props.fulfillment.cancellation_url,
      formInital = document.querySelector('form[name=notepadForm]'),
      formModal = document.querySelector('form[name=notepadModalForm]'),
      params = {
        type: 'Incident',
        format: 'json',
        event: {
          comment: this.state.comment,
          incident_subtype_id: this.state.incidentSubtypeId,
          chat_log: this.state.chat_log
        }
      }
    const headers = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken()
      }
    }
    button.classList.add('is-loading')
    if (this.state.comment === "" || this.state.comment === null ) {
      new Toast().danger({ message: 'Comment can\'t be blank' })
      button.classList.remove('is-loading')
      return;
    }
    axios.post(url, params, headers).then(response => {
      $('.modal-incident').modal('hide')
      this.setState((this.initialState))
      formInital.reset()
      formModal.reset()
      /**
       * Sends a message into the web-socket to abort the exam on the students side
       * and updates fulfillment status on the back-end
       */
      if (isFulfillmentCancelled == true) {
        axios.post('/api/cancellation_messages',
          { uuid: this.props.fulfillment.uuid },
          headers)
        this.cancelFulfillment(cancellation_url)
      }
    }).then(() => {
      button.classList.remove('is-loading')
    }).catch(error => (xhr, textStatus, error) => {
      component.setState({ modalError: true, modalErrors: errors })
    })
  }

  cancelFulfillment = (cancellation_url) => {
    let params = {
      cancellation: {
        cancellation_reason_id: '1',
        explaination: 'Session stopped by Proctor',
        credit: 'none'
      }
    }
    const headers = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken()
      }
    }
    axios.post(cancellation_url, params, headers).
    then(response => {
        new Toast().success({message: 'This reservation has successfully been cancelled.'})
        $('*[data-target="#cancel-modal"]').hide()
    })
  }

  reset = () => {
    this.setState(this.initialState);
  }

  isStopSessionCheckboxVisible = () => {
    return this.props.fulfillment.status === 'running'
  }

  render() {
    return (
      <div>
        <div className='card mb-4'>
          <div
            className='card-header collapsible cursor-pointer'
            onClick={() => {
              this.setState({ expanded: !this.state.expanded })
            }}
          >
            <span className='float-right'>
              <Button
                onClick={this.linkToNotepad}
                className='btn btn-secondary btn-sm'
              >
                Open Watcher Window
              </Button>
            </span>
            <span className='mr-2'>
            { this.state.expanded
              ? <i className="fa fa-minus" />
              : <i className="fa fa-plus" />
            }
            </span>
            Notepad
          </div>
          <Collapse in={this.state.expanded}>
            <div className='card-body'>
              <form name='notepadForm'>
                <NotepadForm
                  ref={ref => this.notepadForm = ref}
                  fulfillment={this.props.fulfillment}
                  openModal={this.openModal}
                  comment={this.state.comment}
                  updateComment={this.updateComment}
                  reset={this.reset}
                />
              </form>
            </div>
          </Collapse>
        </div>

        <IncidentModal
          incidentSubtypes={this.props.incidentSubtypes}
          comment={this.state.comment}
          updateComment={this.updateComment}
          updateIncidentSubtype={this.updateIncidentSubtype}
          updateChatLog={this.updateChatLog}
          saveIncident={this.saveIncident}
          modalError={this.state.modalError}
          modalErrors={this.state.modalErrors}
          isStopSessionCheckboxVisible={this.isStopSessionCheckboxVisible}
        />
      </div>
    )
  }
}

Notepad.propTypes = {
  fulfillment: PropTypes.object.isRequired,
  incidentSubtypes: PropTypes.array.isRequired,
  proctorId: PropTypes.string.isRequired
}

export default Notepad
