import setHint from './utils/setHint';
import PresetsBrowserTabs from './PresetsBrowserTabs';

class PresetsRadios {
  constructor() {
    this.presetsRadios = document.querySelectorAll(
      '[data-element="preset-radio"]'
    );
    if (this.presetsRadios.length > 0) {
      this.iterationPresetCustom = $('#iteration_preset_custom');
    }
    const presetsBlock = document.querySelector('[data-element="presets-block"]');
     if (presetsBlock) {
       this.isExtendedLostFocus = JSON.parse(presetsBlock.dataset.props)['extended_lost_focus'];
     }
    this.presetsConfigurationSelects = [
      document.querySelector('[data-element="browser_tabs"]'),
      document.querySelector('[data-element="allow_copy_paste"]'),
      document.querySelector('[data-element="window_size_allowed"]'),
      document.querySelector('[data-element="lost_focus"]')
    ].filter(element => element)

  }

  init() {
    this.bindEventListeners();
    this.onPresetsRadiosChange();
    this.handleIterationClick();
  }

  bindEventListeners() {
    if (this.presetsRadios.length > 0) {
      this.presetsRadios.forEach(radio => {
        radio.addEventListener('change', () => { this.onPresetsRadiosChange() });
        radio.addEventListener('click', (event) => { this.onPresetsRadiosClick(event) });
        radio.addEventListener('click', () => { this.handleIterationClick() });
      });

      this.iterationPresetCustom.on('change', () => {this.onPresetsRadiosChange(); });
    }
  }

  onPresetsRadiosChange() {
    if ($('#iteration_preset_high').is(':checked')) {
      $('.js-resource-switch').css('pointer-events', 'none');
      $('.js-resource-switch input[type=checkbox]:not(.js-input)').trigger(
        'change'
      );
      $('.js-resource-switch [type=checkbox]').prop('checked', false);
      $('#iteration_no_resources_allowed').prop('checked', true);
    } else {
      $('.js-resource-switch').css('pointer-events', 'auto');
      if ($('#iteration_preset_medium').is(':checked')) {
        // We don't want to uncheck the allowed resources unless the iteration
        // type is an Automated exam
        if (this.iterationTypeSelector) {
          const selectedType = this.iterationTypeSelector.value;
          if (
            selectedType === 'Iteration::Classic' ||
            selectedType === 'Iteration::Ucard'
          ) {
            return;
          }
        }
        $('[data-element="lost-focus-allowed-resource"]')
          .prop('checked', false)
          .closest('.checkbox')
          .removeClass('selected');
      }
    }
  }

  onPresetsRadiosClick(e) {
    const $this = $(e.target);
    // iteration_preset_low
    $('#iteration_browser_tabs').val($this.attr('browser_tabs'));
    $('#iteration_window_size_allowed').val($this.attr('window_size_allowed'));
    $('#iteration_lost_focus').val($this.attr('lost_focus'));
    $('#iteration_lighting_level').val($this.attr('lighting_level'));
    $('#iteration_allow_copy_paste').val($this.attr('allow_copy_paste'));
    // TODO: remove this guard once extended lost focus takes root
    if (!this.isExtendedLostFocus) {
      return;
    }
    setHint($this.attr('lost_focus'));
    new PresetsBrowserTabs().browserTabChange()
  }

  onPresetInputChange(input) {
    const $this = $(input);
    input.checked = true
    // iteration_preset_low
    $('#iteration_browser_tabs').val($this.attr('browser_tabs'));
    $('#iteration_window_size_allowed').val($this.attr('window_size_allowed'));
    $('#iteration_lost_focus').val($this.attr('lost_focus'));
    $('#iteration_lighting_level').val($this.attr('lighting_level'));
    $('#iteration_allow_copy_paste').val($this.attr('allow_copy_paste'));
    // TODO: remove this guard once extended lost focus takes root
    if (!this.isExtendedLostFocus) {
      return;
    }
    setHint($this.attr('lost_focus'));
    new PresetsBrowserTabs().browserTabChange()
  }

  handleIterationClick() {
    this.presetsConfigurationSelects.forEach(element => {
      let hint = element.options[element.selectedIndex].getAttribute('hint');
      element.parentElement.querySelector('.hint').textContent = hint;
    });
  }
}

export default PresetsRadios;
