class ReEnableCancelledFlightPathLink {
  constructor() {
    this.button = document.querySelector('.js-show-disabled-flight-path');
  }

  init() {
    if (this.button === null) { return }
    this.button.addEventListener('click', (event) => { this.handleClick(event) });
  }

  handleClick(event) {
    event.preventDefault();
    document.querySelector('.js-flightpath-card').classList.remove('not-authorized');
  }
}

export default ReEnableCancelledFlightPathLink;
