import Dropzone from 'dropzone';
import Toast from '../../src/utils/Toast';
class UploadMedia {
  constructor() {
    this.screenshotIncidentToggleButtons = document.querySelectorAll(
      '[data-behavior="screenshot-toggle-incident"]'
    );

    this.incidentReportForm = document.querySelector(
      '[data-behavior=submit-incident-report]'
    );
    this.screenshotForm = document.querySelector(
      '[data-behavior=submit-screenshot]'
    );
    this.incidentReportFormContainer = document.querySelector([
      '[data-behavior=incident-report-form-container]'
    ]);
    this.incidentReportFormErrors = document.querySelector(
      '[data-behavior=incident-report-error]'
    );
    this.identifyScreenshot = document.querySelector(
      '[data-behavior=identify-screenshot]'
    );

    this.screenshotFormContainer = document.querySelector(
      '[data-behavior=screenshot-form-container]'
    );

    this.manuallyUploadScreenshotButton = document.querySelector(
      '.js-screenshot-button'
    );

    // This needs to be jQuery because we are listening for the hidden.bs.modal event
    this.$eventMediaModal = $('#event-media-modal');

    this.toggleIncidentModal = this.toggleIncidentModal.bind(this);
    this.buildIncidentForm = this.buildIncidentForm.bind(this);
    this.createIncidentReport = this.createIncidentReport.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.resetScreenshotModal = this.resetScreenshotModal.bind(this);
    this.handleManualScreenShotUpload = this.handleManualScreenShotUpload.bind(
      this
    );
    this.screenshotDragDrop = this.screenshotDragDrop.bind(this);
    this.processFileUpload = this.processFileUpload.bind(this);
    this.containsFiles = this.containsFiles.bind(this);
    this.showToast = this.showToast.bind(this);
  }

  init() {
    this.bindEventListeners();
    this.screenshotDragDrop();
  }

  bindEventListeners() {
    // Add an event listener to handle for if they click yes or no on if the
    // screenshot is an incident
    this.screenshotIncidentToggleButtons.forEach(toggleButton => {
      toggleButton.addEventListener('click', this.toggleIncidentModal);
    });

    // Adds an event listener for the submission of the incident creation form
    this.incidentReportForm.addEventListener(
      'submit',
      this.createIncidentReport
    );

    // Reset the modal when you click off of it.
    // Yes this needs to be jQuery
    this.$eventMediaModal.on('hidden.bs.modal', this.resetScreenshotModal);

    this.manuallyUploadScreenshotButton.addEventListener(
      'click',
      this.handleManualScreenShotUpload
    );
  }

  resetScreenshotModal() {
    this.incidentReportFormContainer.classList.add('d-none');
    this.screenshotFormContainer.classList.add('d-none');
    this.identifyScreenshot.classList.remove('d-none');
    // Yes this needs to be jquery
    this.$eventMediaModal.find('.modal-dialog').addClass('modal-sm');
    // Not sure if this one needs to be jQuery...
    $('.js-dnd-error-screens').addClass('d-none');
    this.incidentReportForm.reset();
    this.clearIncidentReportErrors();
    this.resetUploadButton();
  }

  clearIncidentReportErrors() {
    this.incidentReportFormErrors.innerHTML = '';
    this.incidentReportFormErrors.classList.add('d-none');
  }

  resetUploadButton() {
    $('.js-screenshot-button').removeClass('disabled');
    $('.js-screenshot-button').text('Upload');
  }

  // The user has an option to toggle if the screenshot needs an incident report
  // If it does, we build the incident report form
  // Otherwise we let them drag and drop a screenshot or select one
  toggleIncidentModal(event) {
    event.preventDefault();

    if (event.target.dataset.value === 'yes') {
      this.buildIncidentForm();
    } else {
      // Event is not an incident so we want to proceed to upload a screenshot
      this.buildScreenshotForm();
    }
  }

  // Shows the user the incident building information
  buildIncidentForm() {
    this.incidentReportFormContainer.classList.remove('d-none');
    this.identifyScreenshot.classList.add('d-none');
    // Yes, this needs to be jquery
    this.$eventMediaModal.find('.modal-dialog').removeClass('modal-sm');
  }

  // Show the user the dropzone and field for uploading a screenshot
  buildScreenshotForm() {
    this.screenshotFormContainer.classList.remove('d-none');
    this.identifyScreenshot.classList.add('d-none');
    // Yes, this needs to be jQuery
    this.$eventMediaModal.find('.modal-dialog').removeClass('modal-sm');
  }

  // Submits the form for creating an incident report
  createIncidentReport(event) {
    event.preventDefault();

    // Submit the incident.
    // Eventually this will need to be refactored to not use jQuery
    const $form = $(event.currentTarget);
    const formData = new FormData($form[0]);
    this.submitForm($form, formData);
  }

  submitForm(form, data){
    $.ajax({
      context: this,
      type: 'POST',
      url: form.attr('action'),
      data: data,
      processData: false,
      contentType: false,
      dataType: 'json',
      success: response => {
        this.resetScreenshotModal();
        this.$eventMediaModal.modal('hide');
        this.appendScreenshotToTimeline(response);
        this.resetScreenshotModal();
        this.showToast('success', 'Upload successful.');
      },
      error: response => {
        this.showToast('danger', `There was an error uploading.")}`);
        this.clearIncidentReportErrors();
        this.resetUploadButton();
        const errors = response.responseJSON;
        Object.keys(errors).forEach(function(key, index) {
          let errorEl = $('[data-field=' + key + ']');
          errorEl.html(errors[key]).removeClass('d-none');
        });
      }
    });
  }

  // When the user selects the 'Select Screenshot' button instead of dragging and dropping
  handleManualScreenShotUpload() {
    $('.js-screenshot-file').click();

    // Add an event listener for when the dialog box changes
    $('.js-screenshot-file').on('change', this.handleFileSelection.bind(this));
  }

  handleFileSelection() {
    this.processFileUpload(
      $('.js-screenshot-button'),
      $('.js-screenshot-button')
    );
  }

  processFileUpload(button, buttonText) {
    button.addClass('disabled');
    buttonText.text('Uploading...');
    const $form = $(this.screenshotForm);
    const formData = new FormData($form[0]);
    this.submitForm($form, formData);
  }

  appendScreenshotToTimeline(response) {
    // Bail out if there is no timeline to append to
    if (!document.querySelector('#js-timeline')) {
      return;
    }
    let liItem = `<li class="list-group-item warning-fade">
                    <div class="media">
                      <div class="float-left">
                        <div class="iconbox">
                          <div class="icon">
                            <span class="fa fa-image"></span>
                          </div>
                        </div>
                      </div>
                      <div class="media-body">
                        <small class="text-muted mx-auto">${response.date}</small>
                        <strong class="media-heading mb-0">
                          Screenshot uploaded by ${response.name}
                        </strong>
                        <div class="mt-3">
                          <image src="${response.picture}" class="img-fluid rounded img-thumbnail">
                        </div>
                      </div>
                    </div>
                  </li>
                  `;
    $('ul.timeline').prepend(liItem);
  }

  screenshotDragDrop() {
    Dropzone.autoDiscover = false;
    Dropzone.options.jsDragAndDropScreen = {
      paramName: 'event[attachment]'
    };

    let mediaDropzone = new Dropzone('#js-drag-and-drop-screen');
    mediaDropzone.on('dragenter', event => {
      event.preventDefault();

      if ($('#event-media-modal').hasClass('in')) {
        return;
      }

      if (!this.containsFiles(event)) {
        return;
      }

      counter++;

      return $('.js-image-overlay').addClass('in');
    });

    mediaDropzone.on('success', (file, response) => {
      $('.js-image-overlay').removeClass('in');
      $('.js-dnd-error-screens').text('');
      this.$eventMediaModal.modal('hide');
      this.appendScreenshotToTimeline(response);
      this.showToast('success', 'Upload successful.');
    });

    mediaDropzone.on('error', (file, respone) => {
      $('.js-image-overlay').removeClass('in');
      $('.js-dnd-error-screens').text(response.attachment[0]);
      this.showToast('danger', 'There was an error uploading.');
    });
  }

  // Checks that the file dragged and dropped for the screenshot uploader is a file
  containsFiles(event) {
    if (event.dataTransfer.types) {
      let i = 0;
      while (i < event.dataTransfer.types.length) {
        if (event.dataTransfer.types[i] === 'Files') {
          return true;
        }
        i++;
      }
    }
    return false;
  }

  showToast(type, message){
    const options = { message: message,
      replaceable: true,
      classList: ['alert-transparent', 'alert-dismissible']
    }
    new Toast().sendToNotify(type, options);
  }
}

export default UploadMedia;
