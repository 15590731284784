import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import MediaDeletionHandler from '../MediaDeletionHandler';

const MediaScreenshots = ({ allMedia, permission, images, target }) => {

  const [disable, setDisable] = React.useState(false);

  useEffect(() => {
    const screenshotDeletionIcons = document.querySelectorAll('[data-behavior="js-delete-screenshot"]');
    screenshotDeletionIcons.forEach(icon =>
      icon.addEventListener('click', (event) => {
        setDisable(true)
        event.preventDefault();
        event.stopPropagation();
        if(confirm(polyglot.t('view_media_deletion_confirm'))) {
          new MediaDeletionHandler().handleDeletion(event)
        }
        setDisable(false)
      })
    );
  }, [])

  return (
    <ul className="image-grid list-unstyled row">
      {allMedia.map((mediaObj, mediaIndex) => {
        return mediaObj.screenshots.map((screenshotObj, screenshotIndex) => {
          return (
            <li key={`${mediaIndex}_${screenshotIndex}`} className="col-md-4 mb-4">
              <div
                className="view-media-screenshot"
                id={`screenshot_${screenshotObj.screenshot_id}`}
                key={screenshotObj.screenshot_id}
              >
                <div className="js-view-media">
                  <img
                    src={screenshotObj.url}
                    alt={screenshotObj.url}
                    className="rounded img-thumbnail"
                  />
                  {
                    permission === 'true' ? (
                    <div className="js-view-media-delete-icon">
                      <button className="btn btn-sm btn-danger" data-behavior="js-delete-screenshot" disabled={disable}>
                        Delete
                      </button>
                    </div>
                  ) : null
                }
                </div>
              </div>
            </li>
          );
        });
      })}
    </ul>
  );
}

MediaScreenshots.propTypes = {
  allMedia: PropTypes.array.isRequired,
  permission: PropTypes.string.isRequired,
  images: PropTypes.array.isRequired,
  target: PropTypes.string.isRequired,
};

export default MediaScreenshots;
