import AdjustContentHeight from './shared_actions/AdjustContentHeight';
import IncreaseProgressBar from './shared_actions/IncreaseProgressBar';
import ShowActiveTab from './shared_actions/ShowActiveTab';
import UpdateNextAndPreviousButtons from './shared_actions/UpdateNextAndPreviousButtons';

class Initializer {
  constructor() {
    new ShowActiveTab();
    new IncreaseProgressBar().init();
    new UpdateNextAndPreviousButtons().init();
    new AdjustContentHeight().init();
  }
}

export default Initializer;
