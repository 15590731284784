// AdministratorDashboard
//
// This class handles initializing the Administrator Dashboard view
// retrieved from Tableau
class AdministratorDashboard {
  constructor() {
    this.containerDiv = document.getElementById('vizContainer');
  }

  init() {
    if (!this.containerDiv) {
      return;
    }
    const height = this.containerDiv.offsetHeight;
    const width = this.containerDiv.offsetWidth;
    const institutionId = document.getElementById('institution_uuid').value;
    const userId = document.getElementById('user_id').value;
    const termId = document.getElementById('term_id').value;
    const authorizedTicket = document.getElementById('authorized_ticket').value;

    let url =
      `https://tableau-ext.proctoru.com` +
      `/trusted/${authorizedTicket}` +
      `/views/AdminDashboard/Summary` +
      `?Institution UUID=${institutionId}` +
      `&User ID=${userId}`;

    if (termId) {
      url += `&Term ID=${termId}`;
    }

    const options = {
      hideTabs: false,
      hideToolbar: true,
      width: width + 'px',
      height: height + 'px',
      onFirstInteractive: () => { }
    };

    // if tableau JS has not loaded yet, refresh the page
    // it should be loaded after a page refresh
    if (typeof tableau == 'undefined' || tableau.Viz == undefined) {
      location.reload();
    } else {
      new tableau.Viz(this.containerDiv, url, options);
    }
  }
}

export default AdministratorDashboard;
