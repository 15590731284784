import React, { useEffect, useRef } from 'react';
import AppointmentWizardNew from '../NewAppointmentWizard/AppointmentWizardNew';
import LoadingIndicator from '../LoadingIndicator';

const getRequiredElementIdByStep = (step) => {
  switch (step) {
    case 1:
      return 'wizards_form_objects_proctor_config_iteration_term_id';
    case 2:
      return 'wizards_form_objects_proctor_config_exam_department_id';
    default:
      null;
  }
};

function RenderLMSComponent({
  showProgress,
  isAppointmentWindows,
  html,
  pageUrl,
  formData,
  step,
  handleChangeElement,
}) {
  const iframeRef = useRef(null);

  useEffect(() => {
    const handleIframeLoad = () => {
      const iframe = iframeRef.current;
      if (iframe && iframe.contentWindow && iframe.contentWindow.document) {
        const iframeContent = iframe.contentWindow.document;
        if ([1, 2].indexOf(step) !== -1) {
          const elementInsideIframe = iframeContent.getElementById(
            getRequiredElementIdByStep(step),
          );

          if (elementInsideIframe) {
            elementInsideIframe.addEventListener('change', (event) => {
              handleChangeElement(event, true);
            });
          }
        }
      }
    };

    const iframe = iframeRef.current;
    if (iframe) {
      iframe.addEventListener('load', handleIframeLoad);
    }

    return () => {
      const iframe = iframeRef.current;
      if (iframe) {
        iframe.removeEventListener('load', handleIframeLoad);
      }
    };
  }, [handleChangeElement]);

  return (
    <>
      {!showProgress ? (
        isAppointmentWindows ? (
          <AppointmentWizardNew
            mountpoint={pageUrl}
            form={JSON.parse(formData)}
            previousStepUrl={'/'}
            handleChangeElement={handleChangeElement}
          />
        ) : (
          <iframe
            ref={iframeRef}
            style={{ height: '57vh', width: '100%', border: 'none' }}
            id="lmsIFrame"
            srcDoc={html.__html}
          />
        )
      ) : (
        <LoadingIndicator />
      )}
    </>
  );
}

export { RenderLMSComponent };
