import CustomModal from '../../../../shared/CustomModal';
import handleIterationChange from './utils/handleIterationChange';
import setHint from './utils/setHint';

class PresetsLostFocus {
  constructor() {
    this.iterationLostFocus = document.querySelector('[data-element="lost_focus"]');
    const presetsBlock = document.querySelector('[data-element="presets-block"]');
    if (presetsBlock) {
      const presetsProps = JSON.parse(presetsBlock.dataset.props);
      this.isExtendedLostFocus = JSON.parse(presetsBlock.dataset.props)['extended_lost_focus'];
      // TODO: remove this guard once extended lost focus takes root
      if (!this.isExtendedLostFocus) {
        return;
      }
      this.lostFocusMessages = presetsProps['lost_focus_messages']
      this.iterationLostFocusValue = this.iterationLostFocus.value;
    }
  }

  init() {
    if (!this.isExtendedLostFocus) { return }
    if (this.iterationLostFocus) {
      this.iterationLostFocus.addEventListener('focus', () => { this.updateIterationLostFocusValue() });
      this.iterationLostFocus.addEventListener('change', (event) => {
        handleIterationChange(event);
        this.handleIterationLostFocusChange()
      });
    }
  }

  handleIterationLostFocusChange() {
    const messages = {
      restricted: 'restricted',
      secure_restricted: 'secureRestricted'
    }
    const newValue = this.iterationLostFocus.value;

    if (newValue === 'allowed') {
      setHint(newValue)
      this.iterationLostFocusValue = newValue;
      return;
    }

    const currentValue = this.iterationLostFocusValue;

    const modalParams = {
      content: this.lostFocusMessages[messages[newValue]],
      confirm: (value) => {
        if (value) {
          setHint(newValue)
        } else {
          this.iterationLostFocus.value = currentValue;
        }
      }
    }
    const modal = new CustomModal('iteration-lost-focus-modal', modalParams);
    modal.create();
  }

  updateIterationLostFocusValue() {
    this.iterationLostFocusValue = this.iterationLostFocus.value;
  }
}

export default PresetsLostFocus;
