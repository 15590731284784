/**
 * This class handle Fulfillments section on the Institution form
 */

class AutoFulfillments {
  constructor() {
    this.fulfillmentsCheckbox = document.querySelector(
      '[data-element="enable-fulfillments-checkbox"]'
    )
    this.fulfillmentsThreshold = document.querySelector(
      '[data-element="auto-fulfill-threshold"]'
    )
    this.handleFulfillmentsCheckbox = this.handleFulfillmentsCheckbox.bind(this)
  }

  init() {
    if (this.fulfillmentsCheckbox != null) {
      this.bindEventListeners()
      this.handleFulfillmentsCheckbox()
    }
  }

  bindEventListeners() {
    this.fulfillmentsCheckbox.addEventListener('change', this.handleFulfillmentsCheckbox)
  }

  handleFulfillmentsCheckbox() {
    this.fulfillmentsThreshold.disabled = !this.fulfillmentsCheckbox.checked
  }
}

export default AutoFulfillments;
