import React from 'react';
import EmptyStateImg from '../../../assets/images/empty-state.svg';

const UnconfiguredEmptyExams = () => {
  return (
    <>
      <section className="empty-container">
        <img
          id="empty-state-image"
          src={EmptyStateImg}
          alt="Empty State Image"
        />
        <div className="mt-4 small">
          No exams to display. Create exams and configure them to be proctored.
        </div>
      </section>
    </>
  );
};

export default UnconfiguredEmptyExams;
