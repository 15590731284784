import React from 'react';

export const ExamPageHeader = () => {
  return (
    <div className="page-header-parent row">
      <h1 className="col-sm-6 page-header">
        {polyglot.t('my_sessions_v2_header_my_sessions')}
      </h1>
      <div className="col-sm-6 schedule-exam-btn-parent">
        <button className="btn btn-primary">
          <i className="fa fa-add mr-1"></i>
          {polyglot.t('my_sessions_v2_header_schedule_exam')}
        </button>
      </div>
    </div>
  );
};
