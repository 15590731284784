class WhiteListUrls {
  constructor() {
    this.iterationTypeSelector =
      document.querySelector('[data-element="iteration-types"]') ||
      document.querySelector('[data-element="iteration-type-hidden"]');

    this.whitelistUrlsSection = document.querySelector('#whitelist-urls-section');
  }

  init() {
    if (this.iterationTypeSelector) {
      this.iterationTypeSelector.addEventListener('change', () => { this.handleWhitelistUrls() });
      this.handleWhitelistUrls()
    }
  }

  handleWhitelistUrls() {
    const selectedType = this.iterationTypeSelector.value
    const displayWhitelistUrlsTypes = [
      'Iteraton::AutomatedLiveLaunch',
      'Iteration::AutomatedUnintegrated',
      'Iteration::Automatic'
    ];
    if (displayWhitelistUrlsTypes.indexOf(selectedType) > -1) {
      this.whitelistUrlsSection.classList.remove('d-none');
    } else {
      this.destroyWhitelistUrls();
    }
  }

  destroyWhitelistUrls() {
    document.querySelectorAll('.whitelist-url .checkbox + input').forEach(e =>
      e.setAttribute('value', '1')
    );
    this.whitelistUrlsSection.classList.add('d-none');
  }
}

export default WhiteListUrls;
