//= require ../utils/dynamicListener
import addDynamicEventListener from '../utils/dynamicListener';

class GoogleRequirementCheck {
  constructor() {
    this.userCountrySelect = document.querySelector(
      '[data-behavior="user-country-select"]'
    );
    this.enrollmentSelect = document.querySelector(
      '[data-behavior="enrollment-select"]'
    );

    this.addEnrollmentBtn = document.querySelector(
      '[data-behavior="add-enrollment-btn"]'
    );

    if (document.querySelector('[data-behavior="registration-submit"]')) {
      this.submitButton = document.querySelector(
        '[data-behavior="registration-submit"]'
      );
      this.accountEditPage = false;
    } else {
      // Used on the account#edit page
      this.submitButton = document.querySelector(
        '[data-behavior="account-submit"]'
      );

      this.accountEditPage = true;
    }

    this.campusFormGroup = document.querySelector(
      '[data-behavior="campus-form-group"]'
    );

    this.errors = {
      birthday: false,
      country: false
    };

    this.ageRequired = 18; // Age required for registration
  }

  init() {
    if (this.isGoogle) {
      this.bindEventListeners();
      this.removeCampusField();
    } else {
      this.handleEnrollmentSelectChange();
    }
    if (this.accountEditPage) {
      this.handleBirthdayChange();
    }
    if (this.addEnrollmentBtn) {
      // If they click the add enrollment button, lets create an event listener
      // for all Select2 Enrollment fields on change, to run the JS
      // yes this has to be jQuery because of Select2
      this.addEnrollmentBtn.addEventListener(
        'click',
        this.handleAddEnrollmentBtnClick()
      );
    }
  }

  get isGoogle() {
    const selectedInstitution = document.querySelector('[data-institution]');
    // Bail out if the enrollment select field isn't present on the page
    if (!this.enrollmentSelect) {
      if (
        selectedInstitution &&
        selectedInstitution.getAttribute('data-institution') === 'Google'
      ) {
        return true;
      } else {
        return false;
      }
    }

    // 3 main use cases
    // The first is the person is on the registration page and there is no select
    // The second is the selectbox is displayed so we check if they choose Google
    if (selectedInstitution) {
      return selectedInstitution.getAttribute('data-institution') === 'Google';
    } else {
      const selectedOption = this.enrollmentSelect.options[
        this.enrollmentSelect.selectedIndex
      ];

      // If the selectedOption is displayed from something like form errors from Rails
      // we need to doublecheck Google is selected
      if (selectedOption.text === 'Google') {
        return true;
      } else {
        return false;
      }
    }
  }

  removeCampusField() {
    if (this.campusFormGroup) {
      this.campusFormGroup.parentElement.removeChild(this.campusFormGroup);
    }
  }

  handleEnrollmentSelectChange() {
    // Select2 has a hard dependency on jQuery so this needs to be a jQuery selector
    $(this.enrollmentSelect).on('change', this.handleInstitutionChange.bind(this));
  }

  handleAddEnrollmentBtnClick() {
    this.bindEventListeners();
  }

  handleInstitutionChange(event) {
    if (this.isGoogle) {
      this.bindEventListeners();
      this.removeCampusField();
    } else {
      this.unbindEventListeners();
    }
  }

  bindEventListeners() {
    addDynamicEventListener(
      document.body,
      'change',
      '[data-type="date_select"]',
      () => { this.handleBirthdayChange() }
    );

    addDynamicEventListener(
      document.body,
      'change',
      '[data-type="email"]',
      () => { this.handleEmailFocusOut() }
    );

    addDynamicEventListener(
      document.body,
      'click',
      '[data-behavior="remove-enrollment"]',
      () => { this.handleRemoveEnrollment() }
    );

    this.userCountrySelect.addEventListener('change', () => {this.handleAddressChange});
  }

  unbindEventListeners() {
    this.userCountrySelect.removeEventListener(
      'change',
      this.handleAddressChange
    );
  }

  handleBirthdayChange(event) {
    let birthdayInput;

    // Use the event, if present.
    if (event) {
      birthdayInput = event.target;
    } else {
      birthdayInput = document.querySelector('[data-type="date_select"]');
    }

    // Get the selected country from the dropdown
    const value = this.userCountrySelect.value;

    this.validateAge(birthdayInput, this.ageRequired); // Age must validate at 18 or higher
  }

  handleAddressChange(event) {
    this.handleBirthdayChange();

    if (event.target.value === '') {
      this.renderError(event.target, polyglot.t('blank'));
    } else {
      this.removeErrorMessages(event.target);
    }
  }

  handleEmailFocusOut() {
    const emailInput = document.querySelector('[data-type="email"]');
    const email = emailInput.value;
    if (this.emailIsValid(email)) {
      this.removeErrorMessages(emailInput);
    } else {
      this.renderError(emailInput, polyglot.t('valid_email_format'));
    }
  }

  handleRemoveEnrollment() {
    this.enableForm();
  }

  emailIsValid(email) {
    return /[a-zA-Z0-9_.+\-]+@[a-zA-Z0-9\-]+\.[a-zA-Z0-9\-.]+/.test(email);
  }

  validateAge(selectedAgeInput, requiredAge) {
    if (!selectedAgeInput) {
      return; // no age field found, so we bail out.
    }

    const userBirthMonthInput = document.querySelector(
      "[data-type='date_select'].month"
    );
    const userBirthDayInput = document.querySelector(
      "[data-type='date_select'].day"
    );
    const userBirthYearInput = document.querySelector(
      "[data-type='date_select'].year"
    );

    if (
      !userBirthMonthInput.value ||
      !userBirthDayInput.value ||
      !userBirthYearInput.value
    ) {
      this.renderError(userBirthMonthInput, polyglot.t('valid_date'));
      // If the user doesn't enter part of their birthday we bail out until they do
      return;
    } else {
      // The user satisfied all requirements for birthday fields
      this.removeErrorMessages(userBirthMonthInput);
    }

    const valuesOfBirthday = [
      userBirthMonthInput.value,
      userBirthDayInput.value,
      userBirthYearInput.value
    ];

    const birthday = new Date(valuesOfBirthday.join('/'));
    const today = new Date();

    const timeAlive = today - birthday;
    const timeRequired =
      today - new Date().setYear(today.getFullYear() - requiredAge);

    if (timeAlive < timeRequired) {
      this.renderError(
        selectedAgeInput,
        polyglot.t('valid_age', {ageRequired: this.ageRequired})
      );
    } else {
      // Clear the error messages because the value is within required age and
      // not over our threshold
      this.removeErrorMessages(selectedAgeInput);
    }
  }

  renderError(element, msg, scroll = true, updateState = true) {
    const errorElement = element.parentElement.querySelector(
      'span.form-text.error'
    );
    const errorBlock = element.parentElement.querySelector(
      '[data-element="error-block"]'
    );
    if (errorElement) {
      if (!errorBlock) {
        element.parentElement.removeChild(errorElement);
      } else {
        errorBlock.removeChild(errorElement);
      }
    }
    const errorSpan = this.buildErrorSpan(msg);

    if (errorBlock) {
      // element.parentElement.insertBefore(errorSpan, errorBlock);
      errorBlock.append(errorSpan);
    } else {
      element.parentElement.append(errorSpan);
    }

    if (updateState) {
      // Set the error state for the specific field for the error state
      // but only if we've opted in
      this.setErrorState(element, true);
    }

    if (scroll) {
      // Only scroll if we haven't opted out
      element.parentElement.scrollIntoViewIfNeeded();
    }
  }

  buildErrorSpan(msg) {
    const errorSpan = document.createElement('span');
    errorSpan.classList.add('form-text');
    errorSpan.classList.add('error');
    errorSpan.appendChild(document.createTextNode(msg));
    return errorSpan;
  }

  removeErrorMessages(element) {
    const errorElement = element.parentElement.querySelector(
      'span.form-text.error'
    );

    const errorBlock = element.parentElement.querySelector(
      '[data-element="error-block"]'
    );

    if (errorElement) {
      if (errorBlock) {
        errorBlock.removeChild(errorElement);
      } else {
        element.parentElement.removeChild(errorElement);
      }
      // Remove the errors for this specific field from the error state
      this.setErrorState(element, false);
    }
  }

  setErrorState(element, value) {
    let errors = this.errors;
    errors = Object.assign(errors, {
      [element.getAttribute('data-field')]: value
    });
    this.errors = errors;
    if (value) {
      // Disable the form
      this.disableForm();
    } else {
      // Enable the form
      this.enableForm();
    }
  }

  // Disables the form from being submittable until all requirements are met.
  disableForm() {
    // Add Error Message to Submit button and disable button
    this.renderError(
      this.submitButton,
      polyglot.t('valid_form'),
      false, // No scrolling,
      false // no setting error state
    );
    this.submitButton.disabled = true;
  }

  // Enables the form to be submitted once all requirements are met.
  enableForm() {
    // Clear Submission Error Message and allow clicking
    this.removeErrorMessages(this.submitButton);
    this.submitButton.disabled = false;
  }
}

export default GoogleRequirementCheck;
