class Select2PageResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = { result: this.props.result };
  }

  componentDidMount() {
    $('#select2-results')
      .select2({
        theme: 'bootstrap4',
        minimumResultsForSearch: -1,
        width: 'auto',
      })
      .on('change', (event) => {
        let url = new URL(window.location);
        url.searchParams.set('limit', event.target.value);
        url.searchParams.set('page', '1');
        window.location.href = url;
      });
  }

  render() {
    return (
      <div>
        <select
          value={this.state.result}
          className="form-control"
          id="select2-results"
          readOnly={true}
        >
          {this.props.pageSplits.map((split) => (
            <option value={split.toString()} key={`search_results_${split}`}>
              {polyglot.t('search_results_prompt', { count: split })}
            </option>
          ))}
        </select>
      </div>
    );
  }
}

Select2PageResult.defaultProps = {
  pageSplits: [5, 10, 15, 20, 50, 100],
};

export default Select2PageResult;
