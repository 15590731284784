import { useEffect } from 'react';
import PrechecksHeaderGroup from './CardSections/PrechecksHeaderGroup';
import PrechecksFooterButton from './CardSections/PrechecksFooterButton';

const GuidelinesCard = ({
  id,
  handleNext,
  precheckData,
  buttonText,
  icon,
  iconPrefix,
  sendEvent,
  title_header,
}) => {
  const resourcesImg = precheckData.images.resourcesImg;
  const livePlus = precheckData.livePlus;

  useEffect(() => {
    sendEvent('Event::AccommodationsLoaded');
  }, []);

  const handleBtnClick = () => {
    sendEvent('Event::AccommodationsReviewed');
    handleNext();
  };

  const accommodationListItemText = (accommodation) => {
    if (accommodation.note) return accommodation.note;
    else return accommodation.name;
  };

  const accommodationHeader = () => {
    const accommodations = precheckData.accommodations;
    const durationModifierAccommodation =
      precheckData.durationModifierAccommodation;
    if (accommodations.length > 0 || durationModifierAccommodation) {
      return polyglot.t('prechecks_accommodations_heading');
    } else {
      return title_header;
    }
  };

  const accommodationText = () => {
    const accommodations = precheckData.accommodations;
    const durationModifierAccommodation =
      precheckData.durationModifierAccommodation;
    if (accommodations.length > 0 || durationModifierAccommodation) {
      return (
        <ul>
          {accommodations.map((accommodation, index) => {
            return (
              <li key={`accommodation_${index}`}>
                {accommodationListItemText(accommodation)}
              </li>
            );
          })}
          {durationModifierAccommodation && (
            <li key={`accommodation_duration_modifier`}>
              {durationModifierAccommodation}
            </li>
          )}
        </ul>
      );
    } else {
      if (livePlus) {
        return (
          <p style={{ textAlign: 'center' }}>
            {polyglot.t('prechecks_no_accommodations_live_plus')}
          </p>
        );
      } else {
        return (
          <p style={{ textAlign: 'center' }}>
            {polyglot.t('prechecks_no_accommodations_record_plus')}
          </p>
        );
      }
    }
  };

  return (
    <div className="container-fluid text-center precheck-card-content" id={id}>
      <PrechecksHeaderGroup
        title={accommodationHeader()}
        image={resourcesImg}
        icon={icon}
        iconPrefix={iconPrefix}
      />
      <div className="text-left">{accommodationText()}</div>
      <PrechecksFooterButton
        buttonText={buttonText}
        handleClick={handleBtnClick}
      />
    </div>
  );
};

GuidelinesCard.defaultProps = {
  title_header: polyglot.t('prechecks_no_accommodations_heading'),
  icon: 'fa-pen-alt',
  iconPrefix: 'fal',
  statusColor: 'primary',
  buttonText: polyglot.t('prechecks_no_accommodations_next_button'),
};

export default GuidelinesCard;
