export default class RefundChoice {
  constructor() {
    this.methodSelect = document.querySelector('select#refund_via');
    this.handleChange = this.handleChange.bind(this);
  }

  init() {
    this.bindEventListeners();
    this.handleChange();
  }

  bindEventListeners() {
    $('#refund_via').on('select2:select', this.handleChange);
  }

  handleChange() {
    this.hideRefund('.via-check');
    this.hideRefund('.via-transaction');
    this.hideRefund('.via-manual');
    this.showRefund();
  }

  hideRefund(refundType) {
    const refund = document.querySelector(refundType);
    if (refund) {
      refund.classList.add('d-none');
    }
  }

  showRefund() {
    const selectedRefund =
      this.methodSelect.options[this.methodSelect.selectedIndex];
    const refund = document.querySelector(`.via-${selectedRefund.value}`);
    if (refund) {
      refund.classList.remove('d-none');
    }
  }
}
