class Summernote {
  constructor(selector) {
    this.selector = $(selector)
  }

  init(includeFPClasses = false) {
      let summernoteOptions = {
        toolbar: [["style", ["style"]], ["style", ["bold", "italic", "underline", "clear"]], ["para", ["ul", "ol"]], ["insert", ["link"]], ["help", ["help"]], ["view", ["fullscreen", "codeview"]]],
      height: 200,
      disableDragAndDrop: true,
      codeviewFilter: true,
      codeviewFilterRegex:  /<\/*(?:applet|b(?:ase|gsound|link)|embed|frame(?:set)?|ilayer|l(?:ayer|ink)|meta|object|s(?:cript|tyle)|t(?:itle|extarea)|xml)[^>]*?>/gi,
      codeviewIframeFilter: true,
      onpaste: function(e) {
        var thisNote, updatePastedText;
        thisNote = $(this);

        updatePastedText = function(note) {
          var cleaned, original;
          original = note.code();
          cleaned = this.cleanPastedHTML(original);
          note.code("").html(cleaned);
        };
        setTimeout((function() {
          updatePastedText(thisNote);
        }), 10);
      },
      callbacks: {
        onBlurCodeview: function(e) {
          const thisNote = $(this);

          let cleanBadTags = function(text) {
            const badTags = ["style", "script", "applet", "embed", "noframes", "noscript"];
            let output = text;
            let tagStripper;
            let i = 0;
            while (i < badTags.length) {
              tagStripper = new RegExp("<" + badTags[i] + ".*?" + badTags[i] + "(.*?)>", "gi");
              output = output.replace(tagStripper, "");
              i++;
            }
            return output;
          };
          const cleanCode = cleanBadTags(thisNote.summernote('code'));
          thisNote.summernote('code', cleanCode);
        }
      }
      }
      if (includeFPClasses)
      {
          summernoteOptions.styleTags = [
            {title: 'Normal', tag: 'div', className: 'flightpath-regular-text', value: 'div'},
            {title: 'Important', tag: 'p', className: 'flightpath-important', value: 'p'},
            {title: 'Unique', tag: 'p', className: 'flightpath-unique', value: 'p'},
            {title: 'Standard', tag: 'p', className: 'flightpath-standard', value: 'p'},
            {title: 'Proctor Action', tag: 'p', className: 'flightpath-proctor-action', value: 'p'}
          ];
      }
      this.selector.summernote(summernoteOptions);
      this.selector.on("summernote.enter", function(we, e) {
              $(this).summernote("pasteHTML", "<br><br>");
              e.preventDefault();
          });
  }

  cleanPastedHTML(input) {
    var attributeStripper, badAttributes, badTags, commentSripper, i, output, stringStripper, tagStripper;
    stringStripper = /(\n|\r| class=(")?Mso[a-zA-Z]+(")?)/g;
    output = input.replace(stringStripper, " ");
    commentSripper = new RegExp("<!--(.*?)-->", "g");
    output = output.replace(commentSripper, "");
    tagStripper = new RegExp("<(/)*(meta|link|span|\\?xml:|st1:|o:|font)(.*?)>", "gi");
    output = output.replace(tagStripper, "");
    badTags = ["style", "script", "applet", "embed", "noframes", "noscript"];
    i = 0;
    while (i < badTags.length) {
      tagStripper = new RegExp("<" + badTags[i] + ".*?" + badTags[i] + "(.*?)>", "gi");
      output = output.replace(tagStripper, "");
      i++;
    }
    badAttributes = ["style", "start"];
    i = 0;
    while (i < badAttributes.length) {
      attributeStripper = new RegExp(" " + badAttributes[i] + "=\"(.*?)\"", "gi");
      output = output.replace(attributeStripper, "");
      i++;
    }
    return output;
  };
}

export default Summernote;
