import axios from 'axios';
import React, { useEffect } from 'react';
import * as mobx from 'mobx';
import {observer} from 'mobx-react';
import _ from 'lodash';
import moment from 'moment';
import ExamStartNotification from '../../src/channels/subscriptions/ExamStartNotification';
import HelpNotification from '../../src/channels/subscriptions/HelpNotification';
import CircleSpinner from '../Loaders/CircleSpinner';
import StreamStatus from './StreamStatus';
import HoverOverIcon from '../VideoPlayer/HoverOverIcon'
import InputRange from 'react-input-range';
import {
  Modal,
  Button,
  ListGroup,
  ListGroupItem
} from 'react-bootstrap'
import {
  FaPause,
  FaPlay,
  FaClock,
  FaUser,
  FaDesktop,
  FaVolumeOff,
  FaVolumeUp } from 'react-icons/fa';
import { MdViewAgenda, MdVolumeOff, MdVolumeUp, MdFullscreen, MdFullscreenExit, MdOpenInFull} from 'react-icons/md';
import Callbacks from '../../src/utils/Callbacks';
import Logger from '../../src/shared/Logger';
import csrfToken from '../../src/utils/csrf';
import Toast from '../../src/utils/Toast';
import posterImage from '../../../assets/images/video/video-stream-available-legacy.png';

const emptyDuration = '--:--:--';
const playerTypes = {
  camVideoPlayer: 'camVideoPlayer',
  screenVideoPlayer: 'screenVideoPlayer'
};
const windowName = 'VideoPlayer';
const noMulti = {
  top: '160px'
};
const multiPresent = {
  top: '150px'
};

window.mobx = mobx;
window.observer = observer;

/**
 * The component creates double video player.
 */

const VideoPlayer = observer(
  class VideoPlayer extends React.Component {

    /*
    propTypes: {
      examStarted: React.PropTypes.string,
      examCompleted: React.PropTypes.string,
      fulfillmentId: React.PropTypes.string.isRequired,
      userId: React.PropTypes.string.isRequired,
      streamHost: React.PropTypes.string.isRequired,
      videoPlayerController: React.PropTypes.object.isRequired,
      videoPlayerStore: React.PropTypes.object.isRequired,
      isWatcher: React.PropTypes.Boolean,
      isMultiPart: React.PropTypes.Boolean,
      accommodations: React.PropTypes.Array,
      durationModifierAccommodation: React.PropTypes.Object,
      integrityBreach: React.PropTypes.Array,
      eventAlertConfigs: React.PropTypes.Array,
      prechecksCompletedAt: React.PropTypes.string,
      examDuration: React.PropTypes.string,
      managerRequest: React.PropTypes.bool, //Did this request come from the manager queue
      raiseHand: React.PropTypes.bool, //Show help modal on initial render
      allowedResources: React.PropTypes.Array,
      otherResources: React.PropTypes.string,
      eventsUrl: React.PropTypes.string,
      incidentSubtypes: React.PropTypes.array,
      showScreenRecording: React.PropTypes.bool
    };
    */

    constructor(props) {
      super(props);

      this.state = {
        muted: false,
        volumeValue: 100,
        contrastValue: 100,
        brightnessValue: 100,
        fullScreenModeEnabled: false,
        hiddenVideo: null,
        imageSettingsOn: false,
        speedSettingsOn: false,
        showModal: true,
        showIntegrityBreachModal: true,
        showHelpModal: props.raiseHand,
        showTestTakerOnCheckoutModal: false,
        examDuration: props.examDuration || 0,
        userSpeaking: false,
        showAllowedResourcesModal: false,
        showCommentModal: false,
        showIncidentsModal: false,
        comment: '',
        formDisabled: false,
      };

      // refs
      this.screenVideo = null;
      this.screenVideo_1 = null;
      this.screenVideo_2 = null;
      this.camVideo = null;
      this.camVideo_1 = null;
      this.camVideo_2 = null;
      this.videoPlayerContainer = null;

      this.callbacks = new Callbacks();

      this.examTimer = 0;
      this.userSpeakingTimer = 0;

      //We set the window name here that allows the watcher window to direct back to this page
      //instead of opening a new tab (fix for when multiple watcher windows are opened)
      this.changeWindowName(windowName);
      new ExamStartNotification(props.fulfillmentId).init(
        this.startTimer,
        this.userSpeakingNotification
      );
      new HelpNotification().init(this.sendHelpNotification);
    }



    componentWillMount() {
      this.props.videoPlayerController.onBeforeRender();
    }

    /**
     * Adds listeners on fullscreenchange, on document click.
     * @see Component#componentDidMount()
     */
    componentDidMount() {
      $('[data-toggle="tooltip"]').tooltip();
      const {
        managerRequest,
        videoPlayerController,
        renderWatcherOverlays
      } = this.props;
      const {
        screenVideo,
        screenVideo_1,
        screenVideo_2,
        camVideo,
        camVideo_1,
        camVideo_2,
        videoPlayerContainer
      } = this;
      videoPlayerController.onAfterRender({
        screenVideo,
        screenVideo_1,
        screenVideo_2,
        camVideo,
        camVideo_1,
        camVideo_2
      });

      if (videoPlayerController.isLive()) {
        screenVideo.addEventListener('loadedmetadata', () => {
          if (camVideo.paused) {
            videoPlayerController.togglePlayPause()
          }
        })
      }

      videoPlayerContainer.addEventListener(
        'webkitfullscreenchange',
        this.handleFullScreenChange
      )
      videoPlayerContainer.addEventListener(
        'mozfullscreenchange',
        this.handleFullScreenChange
      )
      document.addEventListener('click', this.handlePopupControls)
      this.callbacks.on(this.handleEvents)
      renderWatcherOverlays //Renders the exam timer only on the watcher window
        ? this.startTimer()
        : null

      managerRequest //Check if the WW was navigated to from the manager queue. If so initiate call
        ? this.initiateCall()
        : null
      this.cancelFullScreen =
        document.webkitCancelFullScreen
          ? document.webkitCancelFullScreen.bind(document)
          : document.mozCancelFullScreen.bind(document)
      this.enterFullScreen =
        videoPlayerContainer.webkitRequestFullScreen
          ? videoPlayerContainer.webkitRequestFullScreen.bind(videoPlayerContainer)
          : videoPlayerContainer.mozRequestFullScreen.bind(videoPlayerContainer)
    }

    /**
     * Removes click listener from document.
     * @see Component#componentWillUnmount()
     */
    componentWillUnmount() {
      this.processUnmount();
    }

    /**
     * Handle various events
     * @param {Object} event - an incoming event
     */
    handleEvents = (event) => {
      const eventHandlers = {
        unmountPlayer: this.processUnmount
      }

      const eventHandler = eventHandlers[event.type]
      eventHandler && eventHandler()
    };

    /**
     * Format duration time
     * @param {Number} duration - a duration to be formatted
     */
    formatDuration = (duration) => {
      const durationDate = new Date(Math.floor(duration) * 1000)
      let hours = durationDate.getUTCHours().toString()
      let minutes = durationDate.getUTCMinutes().toString()
      let seconds = durationDate.getUTCSeconds().toString()
      hours.length == 1 && (hours = `0${hours}`)
      minutes.length == 1 && (minutes = `0${minutes}`)
      seconds.length == 1 && (seconds = `0${seconds}`)
      return duration ? `${hours}:${minutes}:${seconds}` : emptyDuration
    }

    /**
     * Removes click listener from document.
     */
    processUnmount = () => {
      this.props.videoPlayerController.onAfterDestroy()
      this.videoPlayerContainer.removeEventListener(
        'webkitfullscreenchange',
        this.handleFullScreenChange
      )
      this.videoPlayerContainer.removeEventListener(
        'mozfullscreenchange',
        this.handleFullScreenChange
      )
      document.removeEventListener('click', this.handlePopupControls)
    }

    /**
     * Changes the current window's name
     * @param {String} name - name to be used for the window name
     */
    changeWindowName = (name) => {
      window.name = name
    }

    /**
     * Render video player
     * @param {String} name - a name of a video player
     * @param {String} state - a current state of a video player
     * @returns {Array} a rendered video player
     */
    renderVideo = (name, state) => {
      const {videoPlayerController} = this.props
      const { live } = this.props.videoPlayerStore.videoData
      const {currentPlayer, currentChunk, chunks} = state
      let className1,
        className2,
        src1,
        src2,
        chunk1Idx,
        chunk2Idx
      if (currentPlayer == 1) {
        className1 = live ? 'hidden-video' : ''
        className2 = 'hidden-video'
        chunk1Idx = currentChunk
        chunk2Idx = currentChunk + 1
      } else {
        className1 = 'hidden-video'
        className2 = live ? 'hidden-video' : ''
        chunk1Idx = currentChunk + 1
        chunk2Idx = currentChunk
      }

      if (chunks) {
        const chunk1 = chunks[chunk1Idx],
          chunk2 = chunks[chunk2Idx]
        src1 = chunk1 && chunk1.url
        src2 = chunk2 && chunk2.url
      }

      const key1 = `${name}_1`,
        key2 = `${name}_2`

      return [
        <video
          {...(this.props.renderWatcherOverlays && { autoPlay: true })}
          className={!live ? 'hidden-video' : ''}
          ref={ref => this[name] = ref}
          key={name}
          poster= {posterImage}
          onPlay={this.handleLiveCanPlay}
        />,
        <video
          className={className1}
          ref={ref => this[key1] = ref}
          key={key1}
          src={src1}
          onPlay={() => this.handleVideoCanPlay({[name]: {...state, error_1: null}})}
          onEnded={src1 ? () => videoPlayerController.onVideoEnd(name, state) : null}
          onCanPlay={videoPlayerController.getPlayerReadyState(name) ?
            () => videoPlayerController.getPlayerReadyState(name).resolve() :
            null
          }
          onError={() => videoPlayerController.onVideoError(name, 1, videoPlayerController.getPlayerReadyState(name))}
          preload="auto"
        />,
        <video
          className={className2}
          ref={ref => this[key2] = ref}
          key={key2}
          src={src2}
          onPlay={() => this.handleVideoCanPlay({[name]: {...state, error_2: null}})}
          onEnded={src2 ? () => videoPlayerController.onVideoEnd(name, state) : null}
          onError={() => videoPlayerController.onVideoError(name, 2)}
          preload="auto"
        />
      ]
    }


    /**
     * Handles video can play event.
     * @param {Object} videoState - updated video state.
     */
    handleVideoCanPlay = (videoState) => {
      this.props.videoPlayerController.updateStore(videoState);
    }

    /**
     * Handles video can play event.
     */
    handleLiveCanPlay = () => {
      this.props.videoPlayerController.manageLiveTimestampInterval(true);
    }

    /**
     * Show an incident
     * @param {Object} event - a processing event
     */
    handleShowIncident = (event) => {
      const {
        target: {dataset: {incidentPosition, incidentCreatedAt}}
      } = event
      this.props.videoPlayerController.onPositionChange(
        parseFloat(incidentPosition),
        new Date(incidentCreatedAt)
      )
    }

    /**
     * Mutes or un-mutes video volume.
     */
    handleMuteStateChange = () => {
      const muted = !this.state.muted
      this.setVolumeLevel(muted ? 0 : this.state.volumeValue)
      this.setState({muted})
    }

    /**
     * Change volume of video.
     * @param {Number} currentVolumeLevel - current volume value.
     */
    handleVolumeStateChange = (currentVolumeLevel) => {
      this.setVolumeLevel(currentVolumeLevel)
      this.setState({volumeValue: currentVolumeLevel})
    }

    /**
     * Applies volume level to video.
     * @param {Number} currentVolumeLevel - current volume value.
     */
    setVolumeLevel = (currentVolumeLevel) => {
      this.camVideo.volume = this.camVideo_1.volume = this.camVideo_2.volume =
        currentVolumeLevel / 100
    }

    /**
     * Applies contrast and brightness value to screen and camera videos.
     * @param {string} filterName - filter name.
     * @param {number} currentLevel - current filter value.
     */
    applyFilters = (filterName, currentLevel) => {
      const {contrastValue, brightnessValue} = this.state
      const filter = `-webkit-filter:
            contrast(${(filterName == 'contrast'
        ? currentLevel
        : contrastValue) / 100})
            brightness(${(filterName == 'brightness'
        ? currentLevel
        : brightnessValue) / 100})`

      [
        this.screenVideo,
        this.screenVideo_1,
        this.screenVideo_2,
        this.camVideo,
        this.camVideo_1,
        this.camVideo_2
      ].forEach(video => video.setAttribute('style', filter))
      this.setState({[filterName]: currentLevel})
    }

    /**
     * Render an incident on a time line
     * @param {Object} incident - an incident to be rendered on a time line
     * @returns {ReactNode} a rendered incident
     */
    renderIncident = (incident, index) => {
      const { createdAtISO, starts_at: startsAt, type, position } = incident
      const createdDate =
        type == 'Event::SuspiciousBehavior' ? startsAt : createdAtISO
      const incidentPosition = this.props.videoPlayerController.findIncidentPosition(position ? position : createdDate)

      return incidentPosition ? (
        <span
          className="input-range__slider-container"
          key={index}
          style={{ left: `${incidentPosition.position}%` }}
        >
          <div
            className={`input-range__slider incident ${
              type == 'Event::SuspiciousBehavior'
                ? 'type_s_behaviour'
                : 'type_incident'
              }`}
            data-incident-position={incidentPosition.position}
            data-incident-created-at={incidentPosition.date}
            onClick={this.handleShowIncident}
          />
        </span>
      ) : null
    }

    /**
     * Toggle popup controls.
     * @param {object} event - an event.
     */
    handlePopupControls = (event) => {
      const {target} = event
      this.setState({
        imageSettingsOn: target.closest('.expanded-image-settings')
          ? true
          : target.closest('.image-settings') && !this.state.imageSettingsOn,
        speedSettingsOn: target.closest('.player-speed') && !this.state.speedSettingsOn
      })
    }

    /**
     * Toggle full screen video mode.
     * @param {String} hiddenVideo - non active video.
     */
    handleFullScreenMode = (hiddenVideo) => {
      const {videoPlayerContainer} = this
      const cancelFullScreen = document.webkitCancelFullScreen ? document.webkitCancelFullScreen.bind(document) : document.mozCancelFullScreen.bind(document)
      const enterFullScreen =
        videoPlayerContainer.webkitRequestFullScreen
          ? videoPlayerContainer.webkitRequestFullScreen.bind(videoPlayerContainer)
          : videoPlayerContainer.mozRequestFullScreen.bind(videoPlayerContainer)
      const fullScreenRequest =
        this.state.fullScreenModeEnabled
          ? cancelFullScreen
          : enterFullScreen
      fullScreenRequest()
      this.setState({hiddenVideo})
    }

    handleFullScreenChange = () => {
      const fullScreenModeEnabled = !this.state.fullScreenModeEnabled
      const newState = {fullScreenModeEnabled}
      !fullScreenModeEnabled && (newState.hiddenVideo = null)
      this.setState(newState)
    }

    /**
     * Changes video speed.
     * @param {number} speed - current speed value.
     */
    handleSpeedStateChange = (speed) => {
      this.props.videoPlayerController.changeSpeedValue(speed)
    }

    /**
     * Handles video position change.
     * @param {number} value - updated video position.
     */
    handlePositionChangeCompleted = (value) => {
      this.props.videoPlayerController.onPositionChange(value)
    }

    /**
     * Changes video position.
     * @param {number} value - current video position.
     */
    handlePositionChange = (value) => {
      this.props.videoPlayerController.changePosition(value)
    }

    /**
     * Render a player time line
     * @returns {ReactNode} a rendered player time line
     */
    renderTimeLine = () => {
      const {
        incidents,
        durationValue,
        durationTime,
        live,
        liveDurationTime
      } = this.props.videoPlayerStore.videoData
      const inputRange = (
        <InputRange
          maxValue={100}
          minValue={0}
          value={durationValue}
          onChange={this.handlePositionChange}
          onChangeComplete={this.handlePositionChangeCompleted}
        />
      )

      return (
        <span
          className="player-timeline"
          title={this.formatDuration(live ? liveDurationTime : durationTime)}
        >
          {incidents && incidents.length ? (
            <div className="player-timeline-incidents">
              {incidents.map((incident, index) =>
                this.renderIncident(incident, index))}
              {inputRange}
            </div>
          ) : (
            inputRange
          )}
        </span>
      )
    }

    /**
     * Handles change of live state
     * @param {Boolean} value - current live state
     */
    handleLiveStateChange = (value) => {
      this.props.videoPlayerController.toggleLive(value)
    }

    /**
     * Handles change of play/pause state
     * @param {Boolean} value - current play/pause state
     */
    handlePlayPause = (value) => {
      this.props.videoPlayerController.togglePlayPause(value)
    }

    /**
     * Render a video player's controls
     * @returns {ReactNode} React element
     */
    renderControls = () => {
      const {
        muted,
        volumeValue,
        contrastValue,
        brightnessValue,
        imageSettingsOn,
        speedSettingsOn
      } = this.state
      const {
        videoData: {
          play,
          live,
          speedValue
        }
      } = this.props.videoPlayerStore

      return (
        <div className="controls">
        <span className="play-pause-btn" onClick={this.handlePlayPause}>
          {play ? <FaPause/> : <FaPlay/>}
        </span>
          {this.renderTimeLine()}
          {this.props.videoPlayerController.isLive() ? (
            <span
              className={`live-btn ${live && 'live-btn-active'}`}
              onClick={this.handleLiveStateChange}
            >
            &#183;live
          </span>
          ) : null}
          <span className="mute-btn" onClick={this.handleMuteStateChange}>
          {muted ? <FaVolumeOff/> : <FaVolumeUp/>}
        </span>
          <span className="volume-controls">
          <InputRange
            maxValue={100}
            minValue={0}
            value={volumeValue}
            onChange={value => this.setState({volumeValue: value})}
            onChangeComplete={this.handleVolumeStateChange}
          />
        </span>
          <span className={`player-speed ${live ? 'disabled' : ''}`}>
          {speedValue}x
          <div
            className={`expanded-speed-settings ${
              speedSettingsOn ? '' : 'hidden-field'
              }`}
            ref="expandedSpeedSettings"
          >
              <div onClick={() => this.handleSpeedStateChange(10)}>10x</div>
              <div onClick={() => this.handleSpeedStateChange(5)}>5x</div>
              <div onClick={() => this.handleSpeedStateChange(4)}>4x</div>
              <div onClick={() => this.handleSpeedStateChange(3)}>3x</div>
              <div onClick={() => this.handleSpeedStateChange(2)}>2x</div>
              <div onClick={() => this.handleSpeedStateChange(1)}>1x</div>
          </div>
        </span>
        </div>
      )
    }

    /**
     * Handles contrast change
     * @param {number} value - current contrast value
     */
    handleContrastChanged = (value) => {
      this.applyFilters('contrastValue', value)
    }

    /**
     * Handles brightness change
     * @param {number} value - current brightness value
     */
    handleBrightnessChanged = (value) => {
      this.applyFilters('brightnessValue', value)
    }

    /**
     * Starts timer for watcher window
     * @param {Object} startTimeNotification - star time notification
     */
    startTimer = (startTimeNotification) => {
      //If a param is passed in, this is called from the ActionCable. This means the Watcher Window was
      //already open before the TT started the test. We start the timer based on the passed in value.
      if (startTimeNotification) {
        this.setState({'examDuration': startTimeNotification.duration})
        this.destroyTimer()
        this.examTimer = setInterval(() => { //Sets an interval to update the currentTime every second
          this.setState({
            'currentTime': this.setCurrentTime(startTimeNotification.start)
        })}, 1000)
      } else { //This is done if the timer is initialed without a paramater (initialized with something other than ActionCable)
        if (this.props.prechecksCompletedAt && _.isNil(this.props.examCompleted)) {
          this.examTimer = setInterval(() => this.setState({
            //Watcher window was opened after the exam had started. Grab the time from the props and start the timer then.
            'currentTime': this.setCurrentTime(this.props.prechecksCompletedAt)
          }), 1000)
        }
        else {
          this.setState({'currentTime': emptyDuration}) //Set timer to --:--:-- for all other cases
        }
      }
    }

    /**
     * This adds a css class to the watcher window video panel whenever a TT is speaking.
     * After 5 seconds the css class is removed.
     */
    userSpeakingNotification = () => {
      this.setState({userSpeaking: true})

      if (this.userSpeakingTimer > 0) {
        clearTimeout(this.userSpeakingTimer)
      }

      this.userSpeakingTimer = setTimeout(() => {
        this.setState({userSpeaking: false})
      }, 5000)
    }

    sendHelpNotification = (subtype) => {
      switch (subtype) {
        case 'raise_hand_popup':
          this.setState({showHelpModal: true});
          break;
        case 'proctor_notification_popup':
          this.setState({showTestTakerOnCheckoutModal: true})
          break;
        default:
      }
    }

    /**
     * @param {String} examTimeIn
     * This takes in an examTime and calculates the amount of time in HH:mm:ss that has passed since then.
     * This is used for display for the exam timer,
     */
    setCurrentTime = (examTimeIn) => {
      let examTime = moment.utc(examTimeIn).format('HH:mm:ss')
      let now = moment.utc().format('HH:mm:ss')

      return moment.utc(moment(now, "HH:mm:ss").diff(moment(examTime, 'HH:mm:ss'))).format('HH:mm:ss')
    }

    closeModal = () => {
      // Currently there is an error being thrown (It doesn't affect functionality) if you just setState here
      // instead of waiting any amount of time at all before calling setState. I think it destroys the Modal
      // before removing the modal.dialog so it no longer has a rootNode (the error getting thrown).
      setTimeout(() => {
        this.setState({showModal: false, showAllowedResourcesModal: false,
                      showCommentModal: false, showIncidentsModal: false})
      }, 1)
    }

    closeIntegrityBreachModal = () => {
      setTimeout(() => {
        this.setState({showIntegrityBreachModal: false})
      }, 1)
    }

    initiateCall = () => {
      const callButton = document.querySelector('.text-chat-phone')
      callButton.click()

      fetch(`/internal/raise-hand-acknowledgements`, {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
        },
        body: JSON.stringify({
          uuid: this.props.fulfillmentId
        })
      })
      // See closeModal message about the timeout here
      setTimeout(() => {
        this.setState({showHelpModal: false})
      }, 1)
    }

    notifyProctorTestTakerOnCheckout = () => {
      fetch(`/fulfillments/${this.props.fulfillmentId}/events`, {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
        },
        body: JSON.stringify({
          type: 'TestTakerOnCheckoutAcknowledge',
          uuid_mode: true,
          event: { comment: 'Event::TestTakerOnCheckoutAcknowledge' }
        })
      })
      // See closeModal message about the timeout here
      setTimeout(() => {
        this.setState({showTestTakerOnCheckoutModal: false})
      }, 1)
    }

    getTimerDuration = () => {
      const tempTime = moment(this.state.currentTime, 'HH:mm:ss')
      return tempTime.hours() * 60 + tempTime.minutes()
    }

    /**
     * Destroys timer for watcher window
     */
    destroyTimer = () => {
      if (this.examTimer) {
        clearInterval(this.examTimer) // Clears any existing Timer interval
      }
    }

    /**
     * Render extra buttons and features for Watcher Windows
     * @param {Boolean} isTimerStopped - a flag that shows that timer for watcher window is stopped
     */
    renderWatcherLeftButtons = (isTimerStopped) => {
      //Removes the name off of the watcher window so it doesn't just try to open the fulfillments link
      //in the watcher window and it redirects it to the already opened tab or opens a new one if the
      //fulfillments page has been closed
      this.changeWindowName('')
      if (isTimerStopped) {
        this.destroyTimer()
      }

      return (
        <div>
          <div
            className='return-fulfillment-btn'
            onClick={() => {
              //This pulls the referrer url from the window object. This then opens that link in the
              //tab named windowName or in a new tab if that tab has been closed. Doesn't seem to
              //focus the tab in Firefox, but does reload it in the same tab
              let newTab = document.createElement('a')
              newTab.setAttribute('href', `${window.origin}/fulfillments/${this.props.fulfillmentId}`)
              newTab.setAttribute('target', `${windowName}`)
              newTab.rel = 'noopener noreferrer'
              newTab.click()
            }}
          >
            <i className="fa fa-external-link-alt" data-toggle="tooltip" data-placement="right" title="View Session"></i>
          </div>
          {this.props.isMultiPart
            ? this.renderMultiPartExam()
            : null}
          {(this.props.accommodations.length > 0 || this.props.durationModifierAccommodation)
            ? this.renderAccommodations()
            : null}
          {this.renderTouchpoints()}

        </div>
      )
    }

    // TODO: event is being used here but is not being accepted as a parameter in the onClick function
    // It is also being flagged as deprecated
    renderWatcherRightButtons() {
      return <div className="watcher-right mt-2" style={{textAlign: "center"}}>
        <ul className="list-unstyled">
          <li className='text-white' key="Test Driver Actions" id="proctor-actions-btn">
            <i
              className="fa fa-person-sign"
              style={{color: "#ffffff"}}
              data-toggle="tooltip"
              data-placement="right"
              title="Test Driver Actions"></i>
          </li>
          <li className="text-white" key="AllowResources" onClick={() => {
            this.setState({showAllowedResourcesModal: true})
          }}><i
                className="fa fa-book"
                data-toggle="tooltip"
                data-placement="left"
                title="Allowed Resources"
              >
              </i>
          </li>
          <li
            key="AddComment"
            className="text-white"
            onClick={() => {
              this.setState({showCommentModal: true})
            }}
          >
            <i
              className="fa fa-comment"
              data-toggle="tooltip"
              data-placement="left"
              title="Add Comment"
            >
            </i>
          </li>
          <li
            key="AddIncident"
            className="text-white"
            onClick={() => {
              this.setState({showIncidentsModal: true})
            }}
          >
            <i
              className="fa fa-exclamation-circle"
              data-toggle="tooltip"
              data-placement="left"
              title="Add an Incident"
            ></i>
          </li>
          <li
            key="Upload"
            className="text-white"
            data-toggle="modal"
            data-target='#event-media-modal'
          >
            <i
              className="fa fa-upload"
              data-toggle="tooltip"
              data-placement="left"
              title="Upload"
            ></i>
          </li>
        </ul>
      </div>
    }

    renderAllowedResourcesModal = () => {
      return (
        <Modal
          show={this.state.showAllowedResourcesModal}
          onHide={this.closeModal}
          size='lg'
          animation={false}
          backdrop={'static'}
          dialogClassName="modal-dialog-centered"
        >
          <Modal.Header closeButton={true}>
            <Modal.Title>Allowed Resources / Other Resources</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Allowed Resources</h4>
            {this.renderAllowedResources()}
            <h4>Other Resources</h4>
            {this.renderOtherResources()}
          </Modal.Body>
        </Modal>
      )
    }

    renderCommentsModal = () => {
      return (
        <Modal
          show={this.state.showCommentModal}
          onHide={this.closeModal}
          size='lg'
          animation={false}
          backdrop={'static'}
          dialogClassName="modal-dialog-centered"
        >
          <Modal.Header closeButton={true}>
            <Modal.Title>Add A Comment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="text-chat-input" onSubmit={this.handleCommentSubmit}>
              <label
                htmlFor="comment"
                className="col-form-label required"
              >
                Comment
              </label>
              <textarea className="form-control required"
                        rows="5"
                        onChange={this.updateComment}
                        disabled={this.state.formDisabled} />
              <div className="text-right mt-3">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={this.state.formDisabled}
                >
                  Submit
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      )
    }

    renderIncidentsModal = () => {
      return (
        <Modal
          show={this.state.showIncidentsModal}
          onHide={this.closeModal}
          size='lg'
          animation={false}
          backdrop={'static'}
          dialogClassName="modal-dialog-centered"
        >
          <Modal.Header closeButton={true}>
            <Modal.Title>Add An Incident</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="text-chat-input" onSubmit={this.handleIncidentSubmit}>
              <div className="form-group">
                <label
                  htmlFor="incident-subtype"
                  className="col-form-label required"
                >
                  Type
                </label>
                <select
                  className="form-control"
                  name="incident-subtype"
                  disabled={this.state.formDisabled}
                >
                  <option defaultValue value="">
                    Select an incident type
                  </option>
                  {this.props.incidentSubtypes.map(function(
                    incident_subtype
                  ) {
                    return (
                      <option
                        key={incident_subtype.id}
                        value={incident_subtype.id}
                      >
                        {incident_subtype.status}
                      </option>
                    )
                  })}
                </select>
              </div>
              <div className="form-group mt-2">
                <label
                  htmlFor="comment"
                  className="col-form-label required"
                >
                  Comment
                </label>
                  <textarea
                    className="form-control"
                    name="comment"
                    disabled={this.state.formDisabled}
                  />
              </div>
              <div className="form-group man mt-2">
                <label
                  htmlFor="chat-log"
                  className="col-form-label"
                >
                  Chat Log
                </label>
                <textarea
                  className="form-control"
                  name="chat-log"
                  disabled={this.state.formDisabled}
                />
              </div>
              <div className="text-right mt-3">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={this.state.formDisabled}
                >
                  Submit
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      )
    }

    handleCommentSubmit = (event) => {
      this.setState({formDisabled: true})
      event.preventDefault()
      if(event.currentTarget[0].value == '') {
        this.showToast('warning', 'Comment cannot be blank.')
        this.setState({formDisabled: false})
        return
      }
      const params = {
        type: 'Comment',
        fulfillmentId: this.props.fulfillmentId,
        uuidMode: true,
        event: {
          comment: event.currentTarget[0].value
        }
      }
      this.handleSubmit('Comment', params)
    }

    handleIncidentSubmit = (event) => {
      this.setState({formDisabled: true})
      event.preventDefault()
      if(event.currentTarget[0].selectedOptions[0].value == '') {
        this.showToast('warning', 'Incident type must be selected.')
        this.setState({formDisabled: false})
        return
      }
      if(event.currentTarget[1].value == '') {
        this.showToast('warning', 'Comment cannot be blank.')
        this.setState({formDisabled: false})
        return
      }
      const params = {
        type: 'Incident',
        fulfillmentId: this.props.fulfillmentId,
        uuidMode: true,
        event: {
          incident_subtype_id: event.currentTarget[0].selectedOptions[0].value,
          comment: event.currentTarget[1].value,
          chat_log: event.currentTarget[2].value
        }
      }
      this.handleSubmit('Incident', params)
    }

    handleSubmit = (type, params) => {
      const headers = {
        headers: {
          Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken()
        }
      }
      axios.post(this.props.eventsUrl, JSON.stringify(params), headers)
        .then(response => {
          this.setState({comment: '', formDisabled: false})
          this.showToast('success', `${type} added.`)
          this.closeModal()
        })
        .catch(error => {
          this.setState({formDisabled: false});
          this.showToast('danger', `Unable to add ${type}.`);
          new Logger().error(`Unable to add ${type}`, error)
          this.closeModal();
        });
    }

    showToast = (type, message)=> {
      const options = { message: message,
        replaceable: true,
        classList: ['alert-transparent', 'alert-dismissible']
      }
      new Toast().sendToNotify(type, options)
    }

    updateComment = (event) => {
      var value = event.currentTarget.value
      this.setState({ comment: value })
    }

    renderAllowedResources = () => {
      if (this.props.allowedResources.length > 0) {
        return (<ul>{
          //Maps over the allowed resources list and builds a ListGroup
          _.map(this.props.allowedResources, (item, index) => {
              return (
                <li key={index}>{item}</li>
              )
          })}
        </ul>)
      }
      else { return 'No resources allowed' }
    }

    renderOtherResources = () => {
      if (this.props.otherResources.length > 0) {
        return (<div dangerouslySetInnerHTML={{ __html: this.props.otherResources }}></div>) }
      else {
        return "No other resources found."
      }
    }

    renderMultiPartExam = () => {
      return (
        <div className='multi-exam-display'>
          <i className='fa fa-list-ol'></i><div className='return-description'>Multipart Exam</div>
        </div>
      )
    }

    renderAccommodations = () => {
      return (
        //We pass in a dynamic style based on if it's a multipart exam or not. This dynamically sets the height
        //based on if the multi-part exam button is present.
        <div style={this.props.isMultiPart ? multiPresent : noMulti} className='accommodations-display' onClick={() => {
          this.setState({showModal: true})
          event.stopPropagation()
        }}>
          <i className="far fa-id-card"></i> <div className='return-description'>Accommodations</div>
        </div>
      )
    }

    renderModalItem = (item) => {
      let display = ''
      const key = `${item.id}${item.name}`

      if (_.isNil(item.note)) {
        display = item.name
      } else {
        display = `${item.name} - ${item.note}`
      }

      return (
        <ListGroupItem className='list-wrap' key={key}>{display}</ListGroupItem>
      )
    }

    renderIntegrityBreachItem = (item) => {
      return (
        <ListGroupItem className='list-wrap'>{item.status}</ListGroupItem>
      )
    }

    renderEventAlertConfigsItem = (item) => {
      return (
        <ListGroupItem className="list-wrap">{item.alert_text}</ListGroupItem>
      )
    }

    /**
     * Renders the accommodations modal based on if it's a watcher window or not.
     */
    renderAccommodationsModal = () => {
      if (this.props.renderWatcherOverlays && (this.props.accommodations.length > 0 || this.props.durationModifierAccommodation)) {
        return (
          <Modal
            show={this.state.showModal}
            onHide={this.closeModal}
            size='sm'
            animation={false}
            backdrop={'static'}

          >
            <Modal.Header closeButton={false}>
              <Modal.Title>Accommodations</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ListGroup>{
                //Maps over the accommodation list and builds a ListGroup
                _.map(this.props.accommodations, (item) => {
                  return (
                    this.renderModalItem(item)
                  )
                })}
                {
                  this.props.durationModifierAccommodation && this.renderModalItem(this.props.durationModifierAccommodation)
                }
              </ListGroup>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.closeModal} bsClass='btn btn-primary btn-block'>
                Acknowledge
              </Button>
            </Modal.Footer>
          </Modal>
        )
      }
    }

    /**
     * Renders the integrity breach modal based on if it's a watcher window or not.
     */
    renderIntegrityBreachModal = () => {
      if (this.props.renderWatcherOverlays && (this.props.integrityBreach || this.props.eventAlertConfigs)) {
        return (
          <Modal show={this.state.showIntegrityBreachModal} onHide={this.closeModal} size='sm' animation={false} backdrop={'static'}>
            <Modal.Header closeButton={false}>
              <Modal.Title>Warning!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              { this.props.integrityBreach && <h4>Previous Integrity Breach</h4>}
              <ListGroup>{
                React.Children.toArray(
                //Maps over the integrity breach list and builds a ListGroup
                  _.map(this.props.integrityBreach, (item) => {
                    return (
                      this.renderIntegrityBreachItem(item)
                    )
                  })
                )
              }
                {
                  React.Children.toArray(
                    //Maps over the event alert configurations list and builds a ListGroup
                    _.map(this.props.eventAlertConfigs, (item) => {
                      return (
                        this.renderEventAlertConfigsItem(item)
                      )
                    })
                  )
                }
              </ListGroup>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.closeIntegrityBreachModal} bsClass='btn btn-primary btn-block'>
                Acknowledge
              </Button>
            </Modal.Footer>
          </Modal>
        )
      }
    }

    renderTouchpoints = () => {
      let top = 110
      if (this.props.accommodations.length > 0 || this.props.durationModifierAccommodation) {
        top += 35
      }
      if (this.props.isMultiPart){
        top += 35
      }

      return (
        <div style={{top: top}} className='js-touchpoint touchpoints-display' onClick={() => {
          event.stopPropagation()
        }}>
          <i className="fa fa-hand-pointer" data-toggle="tooltip" data-placement="right" title="Add Touchpoint"></i>
        </div>
      )
    }

    /**
     * Renders the help modal based on if it's a watcher window or not.
     */
    renderHelpNotificationModal = () => {
      if (this.props.renderWatcherOverlays && (this.state.showHelpModal || this.props.raiseHand)) {
        return (
          <Modal show={this.state.showHelpModal} onHide={this.closeHelpModal} size='sm' animation={false} backdrop={false} className={'help-notification-modal'}>
            <Modal.Footer>
              <Button onClick={this.initiateCall} bsClass='btn btn-block' className={'help-notification-btn'}>
                <i className="fa fa-phone"></i>
                Answer
              </Button>
            </Modal.Footer>
          </Modal>
        )
      }
    }

    renderTestTakerOnCheckoutModal = () => {
      const proctorNotification = ["Disconnect from LMI", "Fulfill Exam Session"]
      if (this.props.renderWatcherOverlays && this.state.showTestTakerOnCheckoutModal) {
        return (
          <Modal show={this.state.showTestTakerOnCheckoutModal} size='sm' animation={false} backdrop={'static'}>
            <Modal.Header>
              <Modal.Title>
                <i className="fa fa-exclamation-circle text-danger mr-2"></i>
                Proctor Close Out Steps
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p className='font-weight-bold'>These steps MUST be completed to close out this session:</p>
              {proctorNotification.map((item) => {
                  return (
                    <ul><li className='list-wrap'>{item}</li></ul>
                  )
                })}
              <p className='font-weight-bold mt-5'>Exam must be fulfilled and proctor disconnected for candidate to complete non-proctored section.</p>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.notifyProctorTestTakerOnCheckout} bsClass='btn btn-primary btn-block' className='proctor-notified-btn'>
                Acknowledge
              </Button>
            </Modal.Footer>
          </Modal>
        )
      }
    }

    /**
     * @see Component#render()
     */
    render() {
      const {
        fullScreenModeEnabled,
        hiddenVideo
      } = this.state
      const {
        camVideo,
        screenVideo,
        fetching,
        play,
        live,
        isTimerStopped,
        showStudentStatus,
        cameraStatus,
        screenStatus,
        socketErrorAppeared
      } = this.props.videoPlayerStore.videoData
      const {examStarted, spinnerPath} = this.props
      const isVideoAvailable = examStarted
      const camVideoPlayerError = camVideo[`error_${camVideo.currentPlayer}`]
      const screenVideoPlayerError =
        screenVideo[`error_${screenVideo.currentPlayer}`]
      const {togglePlayPause} = this.props.videoPlayerController

      const bothPlayersFailed = camVideoPlayerError && screenVideoPlayerError
      bothPlayersFailed && play && setTimeout(() => togglePlayPause(), 0)


      return (
        <div
          id="video-player-container"
          ref={ref => this.videoPlayerContainer = ref}
          className={`${fullScreenModeEnabled ? 'fullscreen-mode' : ''}`}
        >
          <div>
            {this.renderHelpNotificationModal()}
            {this.renderTestTakerOnCheckoutModal()}
            {this.renderAccommodationsModal()}
            {this.renderIntegrityBreachModal()}
            {this.renderAllowedResourcesModal()}
            {this.renderCommentsModal()}
            {this.renderIncidentsModal()}
          </div>
          <div
            className={`half-screen-wrapper mb-3 ${
              hiddenVideo == playerTypes.camVideoPlayer ? 'hidden-video' : ''
              }
              ${this.props.renderWatcherOverlays
                ? this.state.userSpeaking ? 'user-speaking' : ''
                : ''}`}
            ref={ref => this.camVideoPlayer = ref}
            id={playerTypes.camVideoPlayer}
          >
            {this.props.renderWatcherOverlays && this.props.externalCameraEnabled && (
              <span className="mr-1 badge badge-secondary external-camera-badge">
                Ext. Camera Req.
              </span>
            )}
            {(!live || socketErrorAppeared) && (
              <div className="player-error" style={{ top: `${this.props.videoRecordingDisabled && isVideoAvailable ? "70" : "40"}px` }}>
                {camVideo.error && <span>{camVideo.error}</span>}
                {camVideoPlayerError && <span>{camVideoPlayerError}</span>}
              </div>
            )}
            {live && showStudentStatus && <StreamStatus status={cameraStatus}></StreamStatus>}
            {this.renderVideo('camVideo', camVideo)}
            {fetching && <CircleSpinner path={spinnerPath}/>}
            {isVideoAvailable
                ? <HoverOverIcon videoRecordingDisabled={this.props.videoRecordingDisabled} />
                : null}
            <div
              //Test-Taker css adjusts the height to account for the exam timer where desktp-view places the button at the top
              className={`full-screen-btn ${this.props.renderWatcherOverlays ? 'test-taker' : 'desktop-view'}`}
              style={{ top: `${this.props.videoRecordingDisabled && isVideoAvailable ? "40" : "10"}px` }}
              onClick={() => this.handleFullScreenMode(playerTypes.screenVideoPlayer) }
            >
              <i className="fa fa-arrows" data-toggle="tooltip" data-placement="right" title="Fullscreen"></i>
            </div>
            {this.props.renderWatcherOverlays
              ? this.renderWatcherLeftButtons(isTimerStopped)
              : null}

            {this.props.renderWatcherOverlays
              ? this.renderWatcherRightButtons()
              : null}
          </div>
          { this.props.showScreenRecording && (
            <div
              className={`half-screen-wrapper ${
                hiddenVideo == playerTypes.screenVideoPlayer ? 'hidden-video' : ''
                }`}
              ref={ref => this.screenVideoPlayer = ref}
              id={"screenVideoPlayer"}
            >
              {(!live || socketErrorAppeared) && (
                <div className="player-error">
                  {screenVideo.error && <span>{screenVideo.error}</span>}
                  {screenVideoPlayerError && <span>{screenVideoPlayerError}</span>}
                </div>
              )}
              {live && showStudentStatus && <StreamStatus status={screenStatus}></StreamStatus>}
              {this.renderVideo('screenVideo', screenVideo)}
              {fetching && <CircleSpinner path={spinnerPath}/>}
              <div
                className="full-screen-btn desktop-view"
                onClick={() => this.handleFullScreenMode(playerTypes.camVideoPlayer)}
                >
                  <i className="fa fa-arrows" data-toggle="tooltip" data-placement="right" title="Fullscreen"></i>
              </div>
              {isVideoAvailable ? this.renderControls() : null}
          </div>
          )}
        </div>
      )
    }
  }
)

VideoPlayer.defaultProps = {
  showScreenRecording: true
}

export default VideoPlayer;
