import Toast from '../utils/Toast';

class TestDeliverySettingsInstanceValidator {

  init() {
    this.activePanel = document.querySelector(
      '[role="tabpanel"].active'
    );
    if(this.activePanel) {
      this.validatorButton = this.activePanel.querySelector(
        '[data-element="test-delivery-settings-validator"]'
      );

      this.fields = this.activePanel.querySelectorAll(
        '[data-platform]'
      );

      this.platform = this.activePanel.id;

      if (!this.validatorButton.configured) {
        this.validatorButton.addEventListener('click', this.handleValidate.bind(this))
        this.validatorButton.configured = true;
      }
    }

  }

  handleValidate(event) {
    const url = event.target.dataset.url;
    const data = this.fetchData();
    $.ajax({
      type: 'GET',
      url: url,
      contentType: 'application/json',
      dataType: 'json',
      data: data,
      success: this.handleSuccess.bind(this),
      error: this.handleError.bind(this)
    });
  }

  fetchData(){
    var map = {};
    for(var input of this.fields) {
      map[input.dataset.field.replace(/\-/g, '_')] = input.value;
    }

    map['type'] = 'settings'
    map['platform'] = this.platform;

    return map;
  }

  handleSuccess(data) {
    new Toast().success({
      message: data['message']
    });
  }

  handleError(data) {
    new Toast().danger({
      message: data.responseJSON['message']
    });
  }

}

export default TestDeliverySettingsInstanceValidator;
