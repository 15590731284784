class DepartmentExamUnlockingAutoSubmitting {
  constructor() {
    this.iterationDepartment = document.querySelector('[data-element="iteration-department"]');
    this.enableExamUnlockingCheckBox = document.querySelector('#iteration_enable_exam_unlocking');
    this.enableAutoSubmittingCheckBox = document.querySelector('#iteration_enable_auto_submitting');
  }

  init() {
    if (this.iterationDepartment) {
      this.iterationDepartment.addEventListener('change', () => { this.handleDepartmentChange() })
    }
  }

  handleDepartmentChange() {
    if (!this.iterationDepartment || !this.enableExamUnlockingCheckBox || !this.enableAutoSubmittingCheckBox || !this.allowMultipleGbWindowsCheckBox) {
     return;
    }
    const select = this.iterationDepartment;
    const selectedDepartment = select.options[select.selectedIndex];
    const enableExamUnlocking = selectedDepartment.getAttribute('data-enable-exam-unlocking');
    const enableAutoSubmitting = selectedDepartment.getAttribute('data-enable-auto-submitting');
    const allowMultipleGbWindows = selectedDepartment.getAttribute('data-allow-multiple-gb-windows');
    this.setCheckBoxForNewDepartment(enableExamUnlocking, this.enableExamUnlockingCheckBox)
    this.setCheckBoxForNewDepartment(enableAutoSubmitting, this.enableAutoSubmittingCheckBox)
    this.setCheckBoxForNewDepartment(allowMultipleGbWindows, this.allowMultipleGbWindowsCheckBox)
  }

  setCheckBoxForNewDepartment(departmentConfig, checkBoxConfig){
    if (departmentConfig === 'true') {
      checkBoxConfig.disabled = true
      checkBoxConfig.checked = true
    } else {
      checkBoxConfig.disabled = false
      checkBoxConfig.checked = false
    }
  }
}

export default DepartmentExamUnlockingAutoSubmitting;