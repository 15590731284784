import axios from 'axios';
import csrfToken from '../../utils/csrf';
import Toast from '../../utils/Toast';

class CreateInternalNoteForm {
  constructor() {
    this.internalReviewForm = document.querySelector('.new_internal_review_note');
    this.errorContainer = document.querySelector("[data-behavior=internal-review-note-error]")
    this.notesStream = document.querySelector("[data-behavior=internal-review-note-stream]")
    this.count = document.querySelector(".irn_size_incident_report_center_incident_report")
  };

  init() {
    this.bindEventListeners();
  }

  bindEventListeners() {
    this.internalReviewForm.addEventListener('submit', (event) => { this.handleSubmit(event) })
  }

  handleSubmit(event) {
    this.internalReviewForm.querySelector('button').classList.add('disabled')
    event.preventDefault();
    const headers = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken()
      }
    }
    this.sendRequest(headers)
  }

  sendRequest(headers) {
    const formData = new FormData(event.currentTarget);
    axios.post(this.internalReviewForm.getAttribute('action'), formData, headers).
    then(response => {
      this.count.innerHTML = parseInt(this.count.textContent) + 1
      this.errorContainer.classList.add("d-none");
      this.internalReviewForm.reset();
      this.notesStream.insertAdjacentHTML('beforeend', this.render(response.data))
      new Toast().success({message: 'Internal note created.'})
    }).catch(error => {
      this.errorContainer.classList.remove("d-none");
      this.errorContainer.innerHTML = error.response.data.error
    })
    this.internalReviewForm.querySelector('button').classList.remove('disabled')
  }

  render(data) {
    return `
      <div class="card mb-2">
        <div class="card-body">
          <div class="row">
            <div class="col-md-1">
              <img src="${data.avatar}" class='media-object rounded mt-2' />
            </div>
            <div class="col-md-11">
              ${this.renderDeleteButton(data)}
              <h5 class="media-heading mtn mb-0">
                ${data.name}
              </h5>
              <small class="text-muted ml-2">
                ${data.created_at}
              </small>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-12">
              <p class="mb-0">
                ${data.content}
              </p>
            </div>
          </div>
        </div>
      </div>
    `
  }

  renderDeleteButton(data) {
    if(data.can_destroy){
      return `
        <a href="#" data-toggle="tooltip" class="float-right btn btn-danger btn-sm" data-behavior="destroy-internal-review-note" data-url="${data.destroy_url}">DELETE</a>
      `
    }
  }
}

export default CreateInternalNoteForm
