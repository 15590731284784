import PrechecksHeaderGroup from './CardSections/PrechecksHeaderGroup';
import PrechecksBodyText from './CardSections/PrechecksBodyText';
import PrechecksFooterButton from './CardSections/PrechecksFooterButton';
import PaymentsIframe from './PaymentsIframe';
import { useEffect, useState } from 'react';

const PaymentsCard = ({
  id,
  title,
  bodyText,
  buttonText,
  sendEvent,
  precheckData,
}) => {
  const paymentImg = precheckData.images.paymentImg;
  const [showIframe, setShowIframe] = useState(false);

  useEffect(() => {
    sendEvent('Event::PaymentsStepLoaded');
  }, []);

  const handleProceedToPayment = () => {
    setShowIframe(true);
  };

  return (
    <div className="container-fluid text-center precheck-card-content" id={id}>
      <PrechecksHeaderGroup title={title} image={paymentImg} />
      <PrechecksBodyText bodyText={bodyText} />
      <PrechecksFooterButton
        buttonText={buttonText}
        handleClick={handleProceedToPayment}
      />
      {showIframe ? <PaymentsIframe /> : null}
    </div>
  );
};

PaymentsCard.defaultProps = {
  title: polyglot.t('prechecks_payments_step_title'),
  bodyText: polyglot.t('prechecks_payments_step_body'),
  buttonText: polyglot.t('prechecks_payments_step_button'),
  statusColor: 'primary',
};

export default PaymentsCard;
