import EventAlertConfigurationForm from "./EventAlertConfigurationForm";

const EventAlertConfigurationTable = (props) => {

	const configActionButtons = (configId, configIndex) => {
		return (
			<div>
				<div className="d-inline-block">
					<input type="button"
					       name="edit_config"
					       value="Edit"
					       className="btn btn-primary"
					       data-config-index={configIndex}
					       onClick={props.editForm}
					/>
				</div>
				<div className="d-inline-block ml-2">
					<input type="button"
					       name="delete_config"
					       value="Delete"
					       className="btn btn-danger"
					       data-config-id={configId}
					       onClick={props.deleteConfig}
					/>
				</div>
			</div>
		)
	}

	return (
		<div className={'table-responsive'}>
			<table className={"table table-semibordered table-default"}>
				<thead>
					<tr>
						<th>Event Type</th>
						<th>Comment</th>
						<th>Location</th>
						<th>Alert Text</th>
						<th className={'text-right'}></th>
					</tr>
				</thead>
				<tbody>
					{
						props.eventAlertConfigs.map((config, config_index) => {
							return (
								<tr key={`event_config_row_${config_index}`}>
									<td>{config.event_type}</td>
									<td>{config.comment}</td>
									<td>{config.location == 'warnings' ? 'Warnings!' : 'Watcher Window'}</td>
									<td>{config.alert_text}</td>
									<td className={'text-right'}>{configActionButtons(config.id, config_index)}</td>
								</tr>
							)
						})
					}
				</tbody>
			</table>
		</div>
	)
}

EventAlertConfigurationTable.defaultProps = {
	eventAlertConfigs: []
};

export default EventAlertConfigurationTable;
