import moment from 'moment';
import getMoment from './getMoment';

export default function getDate(date) {
  if(date === undefined) {
    return getMoment(moment()).parseZone();
  } else {
    return getMoment(date).parseZone();
  }
}
