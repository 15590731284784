// For the Bootstrap datetimepicker located on the Throttles and Location Loads pages
//
// Handles initializing the datetimepicker and making sure it modifies the URL
// correctly so the user can select dates and have the page refresh accordingly
//
class DatePicker {
  constructor() {
    // the main datetimepicker element
    // must be jquery as this is a bootstrap-jquery element
    this.$throttleDatePicker = $('#throttle-date-picker');
  }

  init() {
    this.initDatePicker();
  }

  // initializes the datetimepicker with correct date formats and iconography
  initDatePicker() {
    this.$throttleDatePicker.datetimepicker({
      defaultDate: this.$throttleDatePicker.data('date'),
      icons: {
        next: 'fa fa-arrow-right',
        previous: 'fa fa-arrow-left'
      }
    });

    // replaces the date param in the url with the selected date in YYYY-MM-DD format
    this.$throttleDatePicker.on('dp.change', e => {
      const date = this.$throttleDatePicker.val();
      let stem;
      let path;
      if (location.pathname.match('location-loads')) {
        stem = location.pathname.match(/(.*\/location-loads)/)[1];
        path = [stem, `?date=${date}`].join('/');
      } else {
        stem = location.pathname.match(/(.*\/throttles)/)[1];
        path = [stem, date, 'edit'].join('/') + `?date=${date}`;
      }
      location.href = path;
    });
  }
}

export default DatePicker;
