import csrfToken from '../utils/csrf';
import axios from 'axios';

class GuardianExtensionBrowserSwitchButton {
  constructor() {
    this.form = document.getElementById('guardian_extension_switch');
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  init() {
    if (!this.form || this.eventAttached) return;
    this.form.addEventListener('submit', this.handleSubmit);
  }

  async handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    const formData = new FormData(this.form);
    const button = this.form.querySelector('button[type="submit"]');
    const hiddenInput = this.form.querySelector(
      'input[name="user[guardian_extension_override]"]',
    );
    const isGuardianExtensionOverrideEnabledOnForm = hiddenInput.value === '0';
    const confirmationKey = isGuardianExtensionOverrideEnabledOnForm
      ? 'confirm_switch_to_guardian_browser'
      : 'confirm_switch_to_guardian_extension';

    const confirmation = confirm(polyglot.t(confirmationKey));
    if (!confirmation) return;

    try {
      const response = await axios({
        method: 'patch',
        url: this.form.action,
        data: formData,
        headers: {
          'X-CSRF-Token': csrfToken(),
          Accept: 'application/json',
        },
      });

      const isGuardianExtensionOverrideEnabled =
        response.data.guardian_extension_override;

      if (isGuardianExtensionOverrideEnabled) {
        // updates button text and form confirmation (i.e., "Switch to Guardian Browser" language)
        button.textContent = polyglot.t('switch_to_browser');
        hiddenInput.value = '0';
      } else {
        // updates button text and form confirmation (i.e., "Switch to Guardian Extension" language)
        button.textContent = polyglot.t('switch_to_extension');
        hiddenInput.value = '1';
      }

      console.log('Success. User updated.', response.data);
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error);
    }
  }
}

export default GuardianExtensionBrowserSwitchButton;
