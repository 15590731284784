import csrfToken from '../../utils/csrf.js';

const AUTO_EXAM_VOUCHER_PATTERN = /^[A-Za-z0-9]+-[A-Za-z0-9]+$/;

class CustomEligibilityService {
  constructor() {
    this.prevVoucherCode = null;
    this.examURL = null;
    this.reservationId = null;
    this.cancellationReasonId = null;

    // DOM elements
    // exam start buttons
    this.startExamBtnArr = document.getElementsByClassName('start_button');
    // enter new voucher button after error with voucher update
    this.enterNewVoucherCodeBtn = document.getElementById(
      'enter-new-voucher-btn',
    );
    // voucher input textarea
    this.voucherInput = document.getElementById('voucher-input');
    //  voucher code update/schedule appointment button
    this.voucherUpdateScheduleBtn = document.getElementById(
      'voucher-update-schedule-apt-btn',
    );
    // voucher code update error
    this.voucherUpdateError = document.getElementById('voucher-update-err');
    // previous voucher display area
    this.prevVoucherCodeDisplay = document.getElementById('prev-voucher-code');
    // voucher error modal
    this.voucherErrorModal = $('#voucher-error-modal');
    // voucher update modal
    this.voucherUpdateModal = $('#voucher-update-modal');
  }

  init() {
    // register click event listeners

    if (this.startExamBtnArr) {
      this.startExamBtnArr.forEach((startExamBtn) => {
        startExamBtn.addEventListener('click', (event) => {
          event.preventDefault();
          void this.onClickStartExam(event);
        });
      });
    }

    this.enterNewVoucherCodeBtn.addEventListener('click', () => {
      void this.onClickEnterNewVoucherCode();
    });
  }

  /**
   * Handles the "Start Exam" button click event.
   *
   * @param {MouseEvent} event
   */
  async onClickStartExam(event) {
    this.reservationId = event.target.dataset?.reservationId;
    this.cancellationReasonId = event.target.dataset?.cancellationReasonId;
    this.examURL = event.target.href;

    const customEligibilityEnabled =
      event.target.dataset?.customEligibilityEnabled === 'true';

    if (!customEligibilityEnabled) {
      window.location.href = this.examURL;
      return;
    }

    const oldText = event.target.innerText;
    event.target.classList.add('disabled');
    event.target.innerText = polyglot.t(
      'custom_eligibility_exam_start_loading',
    );

    try {
      const response = await fetch('/internal/eligibilities/redeem', {
        method: 'POST',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'content-type': 'application/json',
          'X-CSRF-Token': csrfToken(),
        },
        body: JSON.stringify({ reservation_id: this.reservationId }),
      });

      if (!response.ok) {
        return this.#showErrorModal(
          polyglot.t('custom_eligibility_generic_error_title'),
          polyglot.t('custom_eligibility_generic_error_description'),
        );
      }

      const data = await response.json();

      if (data.valid) {
        window.location.href = this.examURL;
        return;
      }

      if (data.voucher_update_supported) {
        this.prevVoucherCode = data.voucher;
      }

      return this.#showErrorModal(
        data.message_title,
        data.message_long,
        data.voucher_update_supported,
      );
    } catch (error) {
      this.#showErrorModal(
        polyglot.t('custom_eligibility_generic_error_title'),
        polyglot.t('custom_eligibility_generic_error_description'),
      );
    } finally {
      event.target.classList.remove('disabled');
      event.target.innerText = oldText;
    }
  }

  /**
   * Handles the 'Enter New Voucher Code' button click event.
   */
  async onClickEnterNewVoucherCode() {
    this.voucherErrorModal.modal('hide');

    this.voucherErrorModal.one('hidden.bs.modal', () => {
      this.voucherUpdateModal.modal('show');
    });

    // prep the voucher update modal
    this.prevVoucherCodeDisplay.innerHTML = this.prevVoucherCode;
    this.voucherInput.placeholder = AUTO_EXAM_VOUCHER_PATTERN.test(
      this.prevVoucherCode,
    )
      ? 'XX-XXXXX'
      : 'XXXXX';
    this.voucherInput.value = '';
    this.voucherUpdateScheduleBtn.innerText = polyglot.t(
      'custom_eligibility_voucher_update_button',
    );
    this.#toggleVoucherInput('enabled');
    this.#clearVoucherErrorMessage();

    // add event listener for voucher update schedule button
    this.voucherUpdateScheduleBtn.addEventListener('click', () => {
      this.onClickUpdateVoucherCode();
    });
  }

  /**
   * Handles the 'Update' button click event on the voucher update modal.
   */
  async onClickUpdateVoucherCode() {
    const voucher = this.voucherInput.value.trim();

    if (!voucher) {
      return this.#displayVoucherErrorMessage(
        polyglot.t('custom_eligibility_no_voucher'),
      );
    }

    // check if new voucher code is for a different type of exam
    if (this.#hasVoucherCodeTypeChanged(this.prevVoucherCode, voucher)) {
      // prep the modal for scheduling a new appointment
      this.voucherUpdateScheduleBtn.addEventListener('click', () => {
        this.onClickSchedule();
      });

      this.voucherUpdateScheduleBtn.innerText = polyglot.t(
        'custom_eligibility_schedule_appointment_button',
      );
      this.#toggleVoucherInput('disabled');
      return this.#displayVoucherErrorMessage(
        polyglot.t('custom_eligibility_schedule_new_appointment'),
      );
    }

    this.voucherUpdateScheduleBtn.setAttribute('disabled', true);
    const oldText = this.voucherUpdateScheduleBtn.innerText;
    this.voucherUpdateScheduleBtn.innerText = polyglot.t(
      'custom_eligibility_voucher_update_button_loading',
    );

    try {
      const response = await fetch('/internal/eligibilities/validate', {
        method: 'POST',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'content-type': 'application/json',
          'X-CSRF-Token': csrfToken(),
        },
        body: JSON.stringify({ voucher: voucher }),
      });

      const data = await response.json();

      if (!data.valid) {
        return this.#displayVoucherErrorMessage(data.message_short);
      }

      window.location.href = this.examURL;
    } catch (error) {
      this.#clearVoucherErrorMessage(
        polyglot.t('custom_eligibility_generic_error_desc'),
      );
    } finally {
      this.voucherUpdateScheduleBtn.removeAttribute('disabled');
      this.voucherUpdateScheduleBtn.innerText = oldText;
    }
  }

  /**
   * Handles the 'Schedule Appointment' button click event on the voucher update modal
   */
  async onClickSchedule() {
    this.voucherUpdateScheduleBtn.setAttribute('disabled', true);
    this.voucherUpdateScheduleBtn.innerText = polyglot.t(
      'custom_eligibility_schedule_appointment_button_loading',
    );

    // TODO: Only for testing. FIX LATER
    const reservationId = this.reservationId.split(' ')[0];

    try {
      const response = await fetch(
        `/students/reservations/${reservationId}/cancellations`,
        {
          method: 'POST',
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            'content-type': 'application/json',
            'X-CSRF-Token': csrfToken(),
          },
          body: JSON.stringify({
            cancellation: {
              cancellation_reason_id: this.cancellationReasonId,
              explaination: 'Session cancelled by Proctor cancellation',
            },
          }),
        },
      );
    } catch (error) {
      // Do nothing
    } finally {
      window.location.href = 'exams/select';

      // Keep buttons disabled if in the process of navigating to exam select page
      if (document.readyState === 'ready') {
        this.voucherUpdateScheduleBtn.removeAttribute('disabled');
        this.voucherUpdateScheduleBtn.innerText = polyglot.t(
          'custom_eligibility_schedule_appointment_button',
        );
      }
    }
  }

  /**
   * Shows an error modal with the given information. Additionally, the voucher
   * code update buttons are shown, if supported by the error context.
   *
   * @param {string} title - title of the error modal
   * @param {string} description - description of the error to be shown in the modal
   * @param {boolean} [isUpdateSupported=false] - whether the error context supports entering a new voucher code
   * @returns {void}
   */
  #showErrorModal(title, description, isUpdateSupported = false) {
    this.voucherErrorModal.modal('show');
    $('#accuplacer-err-text-title').text(title);
    $('#accuplacer-err-text-description').text(description);
    $('#voucher-update-btns').toggle(isUpdateSupported);
  }

  /**
   * Toggles the user's ability to edit the voucher code input field.
   *
   * @param {'enabled' | 'disabled'} style - whether to enable or disable the
   *   voucher code input field
   */
  #toggleVoucherInput(style) {
    if (style === 'enabled') {
      this.voucherInput.removeAttribute('disabled');
    } else if (style === 'disabled') {
      this.voucherInput.setAttribute('disabled', true);
    }
  }

  /**
   * Displays the given voucher validation error message.
   *
   * @param {string} message - the result message to be displayed
   */
  #displayVoucherErrorMessage(message) {
    this.voucherUpdateError.classList.add('text-danger');
    this.voucherUpdateError.textContent = message;
    this.voucherInput.classList.add('is-invalid');
  }

  /**
   * Clears the voucher update error message display in the voucher update modal.
   */
  #clearVoucherErrorMessage() {
    this.voucherUpdateError.classList.remove('text-danger');
    this.voucherUpdateError.textContent = null;
    this.voucherInput.classList.remove('is-invalid');
  }

  /**
   * Checks if the current voucher code type is different from the previous one.
   *
   * @param {string} prevCode - the previous voucher code
   * @param {string} currentCode - the current voucher code
   * @returns {boolean} `true` if the voucher codes are of different types, as
   *   denoted by their prefixes; `false` if they are of the same type.
   */
  #hasVoucherCodeTypeChanged(prevCode, currentCode) {
    const isPrevExamAuto = AUTO_EXAM_VOUCHER_PATTERN.test(prevCode);
    const isCurrentExamAuto = AUTO_EXAM_VOUCHER_PATTERN.test(currentCode);
    return isPrevExamAuto !== isCurrentExamAuto;
  }
}

export default CustomEligibilityService;
