import PropTypes from 'prop-types';
import isInstitution from '../../../src/incident_report_center/utils/isInstitution'

class IncidentReportMoreOptionsButton extends React.Component {
  navigateToUrgencyLevels = (event) => {
    event.preventDefault();
    Turbolinks.visit('/urgency-levels');
  }

  navigateToIncidentUsers = (event) => {
    event.preventDefault();
    Turbolinks.visit("/incident-users");
  }

  navigateToUrgencyTemplates = (event) => {
    event.preventDefault();
    Turbolinks.visit("/incident-subtypes");
  }

  linkToManageUrgencyLevels = () => {
    if (this.props.abilities.manageUrgencyLevels) {
      return (
        <a
          href="#"
          onClick={this.navigateToUrgencyLevels}
          className="dropdown-item">
          Manage Urgency Levels
        </a>
      );
    }
  }

  linkToManageUsers = () => {
    if (this.props.abilities.manageUsers) {
      return (
          <a
            href="#"
            onClick={this.navigateToIncidentUsers}
            className="dropdown-item">
            Manage Users
          </a>
      );
    }
  }

  linkToManageTemplates = () => {
    if (this.props.abilities.manageTemplates) {
      return (
        <a
          href="#"
          onClick={this.navigateToUrgencyTemplates}
          className="dropdown-item">
          Manage Incident Templates
        </a>
      );
    }
  }

  renderMoreOptions = () => {
    if (!isInstitution()) {
      return(
        <span className="dropdown mr-1">
          <button className="btn btn-secondary dropdown-toggle"
            type="button" id="irc-actions"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="true">
            More options
            <span className="caret ml-1"></span>
          </button>
          <div className="dropdown-menu" aria-labelledby="irc-actions">
            {this.linkToManageTemplates()}
            {this.linkToManageUsers()}
          </div>
        </span>
      );
    }
  }

  render = () => {
    const moreOptions = this.renderMoreOptions();
    return (
      <>
        {moreOptions}
      </>
    );
  }
}

IncidentReportMoreOptionsButton.propTypes = {
  id: PropTypes.number.isRequired,
  abilities: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired
}

export default IncidentReportMoreOptionsButton;
