/**
 * Component represent "read more" feature in a modal window
 */
import ResizeObserver from 'resize-observer-polyfill';
import { Component } from 'react';
import PropTypes from 'prop-types';

class CompactCard extends Component {
  constructor(props) {
    const timestampId = Date.now();
    super(props);

    this.cardModalId = `card-modal-${timestampId}`
    this.cardId = `card-${timestampId}`

    this.state = { isShowMore: false }
  }

  componentDidMount() {
    const cardModalId = `#${this.cardModalId}`;

    document.body.insertAdjacentHTML('beforeend', this.modalElement());

    $(this.props.parentModalSelector).on('shown.bs.modal', () => {
      this.ShowClickMoreLinkForLargeContent();
    })
    const cardResizeObserver = new ResizeObserver(() => {
        this.ShowClickMoreLinkForLargeContent();
    });
    cardResizeObserver.observe(document.getElementById(this.cardId));
  }

  ShowClickMoreLinkForLargeContent() {
    const el = document.getElementById(this.cardId);
    const isBigContent = el.scrollHeight > el.clientHeight;
    this.setState({ isShowMore: isBigContent });
  }

  textAsHTML() {
    return {
      __html: this.props.text
    };
  }

  modalElement() {
    return `
      <div id="${this.cardModalId}" class="modal" role="dialog" style="z-index: 1100">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                <span aria-hidden="true">×</span>
              </button>
              <h4 class="modal-title">${this.props.title}</h4>
            </div>
            <div class="modal-body">${this.props.text}</div>
          </div>
        </div>
      </div>
    `
  }

  render() {
    return(
      <div id={this.cardId} className="card h-100 compact-card">
        <div className="card-header">
          {this.props.title}
        </div>

        <div className="card-body compact-card__body" dangerouslySetInnerHTML={this.textAsHTML()}/>

        {this.state.isShowMore && (
          <div>
            <div className="compact-card__read-more"></div>
            <a className="compact-card__link" href="" data-toggle="modal" data-target={`#${this.cardModalId}`}>Click for more</a>
          </div>
        )}
      </div>
    )
  }
}

CompactCard.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default CompactCard;
