class WindowEndsAtChecker {
  constructor() {
    this.windowsContainer = document.querySelector('[data-element=exam-windows-block]');
  }

  init() {
    this.windowsContainer.addEventListener('change', (event) => {
      if(event.target.parentNode.classList.contains('js-window-ends-at')){
        let date = '';

        const parent = event.target.parentNode
        parent.children.forEach(dateInput => {
          date += dateInput.value + '-';
        })
        date = date.slice(0, -1)
        date = moment(date, "YYYY-MM-DD-HH-mm")
        if (moment(date).diff(Date.now(), 'hours') < 72) {
              $(parent).popover('show');
              $('.popover-title').append('<button type="button" class="close">&times;</button>');
              $('.popover-title').find('.close').click(() => {
                return $(this).parent().parent().popover('destroy');
              });
              $('.popover-title .close').click(() => {
                $(parent).popover('destroy')
              })
        }
      }
    })
  }

  handleChange(field) {
    // const $parent = $(field).parent();
    // let date = '';
    // $parent.children().each(function() {
    //   return date += this.value + '-';
    // });
    // date = moment(date, "YYYY-MM-DD-HH-mm-");
    // if (date <= moment().add(72, 'hours')) {
    //   if ($parent.attr('aria-describedby') === void 0) {
    //     $parent.popover('show');
    //     $('.popover-title').append('<button type="button" class="close">&times;</button>');
    //     $('.popover-title').find('.close').click(function() {
    //       return $(this).parent().parent().popover('destroy');
    //     });
    //     return parentFind($parent, '.js-window-name').click(function() {
    //       return parentFind($(this), '.js-window-ends-at').popover('destroy');
    //     });
    //   }
    // } else {
    //   return $parent.popover('destroy');
    // }
  }
}

export default WindowEndsAtChecker;
