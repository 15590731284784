const ImageGroup = ({ images, rowLength, imageHeight, imageWidth }) => {
  const imageContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    fontSize: '.8em',
  };

  const imageRowStyle = {
    display: 'flex',
    justifyContent: 'space-evenly',
  };

  const renderImages = (images) => {
    return images.map((img, index) => {
      return (
        <div key={index} className={'col-md-4 guided_launch_img_container'}>
          {img.caption.length > 0 && (
            <p className="precheck-gray">{img.caption}</p>
          )}
          <div className={'guided_launch_img'}>
            {img.image_url.length > 0 && (
              <img src={img.image_url} alt={img.caption} />
            )}
          </div>
        </div>
      );
    });
  };

  const renderRows = () => {
    let numRows = Math.ceil(images.length / rowLength);
    let rowContent = [];
    const glImages = images;

    for (let x = 0; x < numRows; x++) {
      let imgList = glImages.splice(0, rowLength);
      if (imgList.length > 0) {
        rowContent.push(
          <div
            key={`row_${x}`}
            className={'row guided_launch_row'}
            style={imageRowStyle}
          >
            {renderImages(imgList)}
          </div>,
        );
      }
    }
    return rowContent;
  };

  return <div style={imageContainerStyle}>{renderRows()}</div>;
};

ImageGroup.defaultProps = {
  images: [],
  rowLength: 3,
  imageHeight: '50px',
  imageWidth: '50px',
};

export default ImageGroup;
