class DatePicker {
  init() {
    if ($('.institution-date-picker').length > 1) {
      $('.institution-date-picker').datetimepicker({
        format: 'MM/DD/YYYY',
        ignoreReadonly: true,
        icons: {
          next: 'fa fa-arrow-right',
          previous: 'fa fa-arrow-left'
        }
      });
    }
  }
}

export default DatePicker;
