import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

/**
 * @typedef BiometricsConsentModalProps
 * @property {boolean} show
 * @property {() => void} onHide
 * @property {() => void} onAgree
 */

/**
 * @param {BiometricsConsentModalProps} props
 * @returns {React.ReactElement}
 */
const BiometricsConsentModal = ({ show, onHide, onAgree }) => {
  return (
    <>
      <Modal show={show} onHide={onHide} contentClassName="border-0" centered>
        <Modal.Header className="border-0 pb-0">
          <Modal.Title
            style={{ font: 'Roboto', fontSize: '16px', color: '#494949' }}
          >
            <i
              className="far fa-face-viewfinder mr-2"
              style={{
                color: '#A16207',
                padding: '12px',
                borderRadius: '24px',
                background: '#FEF9C3',
              }}
            />
            {polyglot.t('prechecks_biometrics_consent_modal_title')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="border-0 pr-4 pl-4" style={{ color: '#5C5C5C' }}>
          {polyglot.t('prechecks_biometrics_consent_modal_content')}
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            className="btn-precheck-primary border-0"
            onClick={() => onAgree()}
          >
            {polyglot.t('prechecks_biometrics_consent_modal_button')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

BiometricsConsentModal.defaultProps = {
  show: false,
};

export default BiometricsConsentModal;
