import React, { useState, useEffect, useCallback } from 'react';
import {
  useQuery,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';

import ChatRoomManagerSubscription from '../../../src/channels/subscriptions/ChatRoomManagerSubscription';
import { getChatRooms, getChatRoom } from '../api';
import ManagerTable from '../ManagerTable';

const queryClient = new QueryClient();

/**
 * @typedef {Object} ChatRoomManagerProps
 * @property {number} userId
 */

/**
 * The chat room manager application allows internal users to manage chat rooms.
 *
 * @param {ChatRoomManagerProps} props
 * @return {React.ReactElement}
 */
const App = ({ userId }) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [activeChatRooms, setActiveChatRooms] = useState([]);

  const { data, isLoading } = useQuery({
    queryKey: ['chatRooms', page, perPage],
    queryFn: () => getChatRooms(page, perPage),
    // Refetch the data every 30 seconds to help keep the chat room list up to date.
    refetchInterval: 30_000,
  });
  const queryClient = useQueryClient();

  const chatRooms = data?.chatRooms || [];
  const totalPages = data?.totalPages || 1;
  const totalItems = data?.totalItems || 0;

  /**
   * @type {[ChatRoomManagerSubscription?, (socket: ChatRoomManagerSubscription?) => void]}
   */
  const [socket, setSocket] = useState();

  const handleConnect = async (id) => {
    const result = confirm(
      'Are you sure you want to connect to this chat room?',
    );
    if (!result) {
      return;
    }
    console.log('Connecting to', id);

    await getChatRoom(id).catch((error) => {
      console.error(error);
      alert('Failed to connect to chat room. Please try again.');
    });
    await queryClient.invalidateQueries('chatRooms');
    window.open(`/chat_rooms/${id}`, `popup_${id}`, 'width=600,height=600');
  };

  const handleDelete = (id) => {
    const result = confirm(
      'Are you sure you are finished with this chat room? This action cannot be undone.',
    );
    if (!result) {
      return;
    }
    console.log('Deleting', id);
    socket.connection.send({
      type: 'delete_clicked',
      chat_room_id: id,
    });
  };

  const handleSocketMessage = (message) => {
    console.log('Received', message);

    switch (message.type) {
      case 'chat_room_updated':
      case 'chat_room_closed':
        queryClient.invalidateQueries('chatRooms');
        break;
      case 'error':
        console.error(message.message);
        break;
      default:
        console.warn('Unhandled message type', message.type);
        break;
    }
  };

  const handlePageChange = useCallback((newPage) => {
    setPage(newPage);
  }, []);

  const handlePerPageChange = useCallback(
    async (newPerPage) => {
      setPerPage(newPerPage);
      setPage(1); // Reset to first page when changing items per page
      // Invalidate and refetch the query with new perPage value
      await queryClient.invalidateQueries(['chatRooms']);
    },
    [queryClient],
  );

  useEffect(() => {
    const newSocket = new ChatRoomManagerSubscription();
    newSocket.init();
    newSocket.connection.received = handleSocketMessage;
    newSocket.connection.disconnected = () => {
      console.log('Disconnected');
    };
    newSocket.connection.connected = () => {
      console.log('Connected');
    };

    setSocket(newSocket);

    return () => {
      newSocket.connection.unsubscribe();
    };
  }, []);

  return (
    <ManagerTable
      chatRooms={chatRooms}
      isLoading={isLoading}
      currentPage={page}
      itemsPerPage={perPage}
      totalItems={totalItems}
      totalPages={totalPages}
      userId={userId}
      onConnect={handleConnect}
      onDelete={handleDelete}
      onPageChange={handlePageChange}
      onItemsPerPageChange={handlePerPageChange}
    />
  );
};

/**
 * @param {ChatRoomManagerProps} props
 * @returns {React.ReactElement}
 */
export default function ChatRoomManager(props) {
  return (
    <QueryClientProvider client={queryClient}>
      <App {...props} />
    </QueryClientProvider>
  );
}
