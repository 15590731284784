class SecureBrowserDownload {
  constructor() {
    this.redirectTestTaker = document.querySelector('[data-behavior="redirect-test-taker"]');
  }

  init() {
    this.extensionSupportInfo = document.querySelector('[data-element="extension-info"]');
    this.useSecureBrowser = this.extensionSupportInfo?.dataset?.secureBrowserEnabled;
    this.isGuardianExtension = this.extensionSupportInfo?.dataset?.guardianExtensionOverrideEnabled;

    if(this.useSecureBrowser === 'true' && this.isGuardianExtension !== 'true') {
      this.redirectToExam();
    }
  }

  redirectToExam() {
    if(this.redirectTestTaker?.dataset?.examUrl?.length > 0){
      window.location.replace(this.redirectTestTaker.dataset.examUrl);
    }
  }
}

export default SecureBrowserDownload
