import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const RegionConfirmationModal = ({ show, handleConfirm, handleCancel }) => {
  return (
    <Modal show={show} onHide={handleCancel} centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ width: '100%', textAlign: 'center' }}>
          Chargeable Feature
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Data Regionalization is a chargeable feature. Please contact the Product
        team for more information before creating this institution. Click
        "CONFIRM" if you have connected with the Product team and they are aware
        of this regionalized institution. Click "CANCEL" and wait to create this
        institution until you have connected with the Product team.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleConfirm}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RegionConfirmationModal;
