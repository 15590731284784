import PropTypes from 'prop-types';

class GMap extends React.Component {
  constructor(props) {
    super(props)

    this.mapCanvas = null
  }

  componentDidMount() {
    if (this.googleAvailable()) {
      this.zoom = 16
      this.map = this.createMap()
      this.marker = this.createMarker()
    }
  }

  googleAvailable() {
    return typeof google === 'object' && typeof google.maps === 'object'
  }

  createMap = () => {
    let mapOptions = {
      zoom: this.zoom,
      center: this.mapCenter()
    }
    return new google.maps.Map(this.mapCanvas, mapOptions)
  }

  mapCenter = () => {
    return new google.maps.LatLng(this.props.gMap.lat, this.props.gMap.lng)
  }

  createMarker = () => {
    return new google.maps.Marker({
      position: this.mapCenter(),
      map: this.map
    })
  }

  render() {
    return (
      <div className="GMap">
        <div className="GMap-canvas" ref={ref => this.mapCanvas = ref} />
      </div>
    )
  }
}

GMap.propTypes = {
  gMap: PropTypes.object.isRequired
}

export default GMap
