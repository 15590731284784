class ResourcesCheckboxes {
  constructor() {
    this.permittedResources = document.querySelectorAll('.js-checkbox-group [type=checkbox]');
  }

  init() {
    this.bindEventListeners();
  }

  bindEventListeners() {
    this.permittedResources.forEach((resource) => {
      resource.addEventListener('change', () => {
        this.handleChange(resource);
      })
    })
  }

  handleChange(resource) {
    const noResourcesAllowed = document.querySelector('.js-no-resources-allowed');
    if (resource !== noResourcesAllowed) {
      noResourcesAllowed.checked = false;
    } else if (resource === resource && !noResourcesAllowed.checked) {
      noResourcesAllowed.checked = false;
    } else {
      this.uncheckAllowedResources();
      noResourcesAllowed.checked = true;
    }
  }

  uncheckAllowedResources(){
    this.permittedResources.forEach((resource) => {
      resource.checked = false;
    })
  }
}

export default ResourcesCheckboxes;
