class MaxAttempts {
  constructor() {
    this.checkbox = document.querySelector(
      '[data-behavior="max-attempts-checkbox"]'
    );

    this.toggleMaxAttempts = this.toggleMaxAttempts.bind(this);
  }

  // Check to see if the current user has the ability to toggle Max Attempts.
  get enabled() {
    return this.checkbox;
  }

  init() {
    if (this.enabled) {
      this.toggleMaxAttempts();
      this.bindEventListeners();
    }
  }

  bindEventListeners() {
    this.checkbox.addEventListener('change', this.toggleMaxAttempts);
  }

  toggleMaxAttempts() {
    if (this.checkbox.checked) {
      this.toggleGroup('block');
      this.toggleCopy('Disable max attempts?');
    } else {
      this.toggleGroup('none');
      this.toggleCopy('Enable max attempts?');
      this.resetValues();
    }
  }

  toggleGroup(style) {
    const selector = document.querySelector(
      '[data-behavior="max-attempts-group"]'
    );
    selector.style.display = style;
  }

  toggleCopy(text) {
    const selector = document.querySelector(
      '[data-behavior=max-attempts-text]'
    );
    selector.innerHTML = text;
  }

  resetValues() {
      [
        ...document.querySelectorAll(
          '[data-behavior="max-attempts-value"], [data-behavior="max-attempts-within-range-value"]'
        )
      ].forEach(selector => {
        selector.value = '0';
      });
  }
}

export default MaxAttempts;
