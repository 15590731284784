class CollapseButton {
  constructor() {
    $('.collapse-button').on('click', function(e) {
      var $collapse, $this;
      $this = $(this);
      $collapse = $this.next();
      if ($this.find('.fa').hasClass('fa-minus')) {
        $this.find('.fa-minus').addClass('fa-plus');
        $this.find('.fa-minus').removeClass('fa-minus');
      } else {
        $this.find('.fa-plus').addClass('fa-minus');
        $this.find('.fa-plus').removeClass('fa-plus');
      }
      $collapse.collapse('toggle')
    });
  }
}

export default CollapseButton
