class GuardianBrowserInjection {
  constructor() {
  }

  init() {
    if (typeof window.PU_Secure_Browser === 'object') {
      try {
        const user = JSON.parse(document.getElementById('hidden_user_info').innerHTML);

        if (user) {
          window.PU_Secure_Browser.register_new_user(user);
        } else {
          throw new Error('Could not find user information...');
        }
      } catch (e) {
        console.error('[Guardian Browser]', e);
      }
    }
  }
}

export default GuardianBrowserInjection;
