export default function handleIteratioNChange(event) {
  const preset_error = document.getElementById('preset-error');
  const windowSize = document.getElementById('iteration_window_size_allowed');
  const lost_focus = document.getElementById('iteration_lost_focus');
  const hint = event.target.options[event.target.selectedIndex].getAttribute('hint')
  event.target.nextElementSibling.textContent = hint;
  if(['maximized', 'fullscreen'].includes(windowSize.value) && lost_focus.value === 'allowed') {
    preset_error.classList.remove('d-none');
    preset_error.innerText = window.polyglot.t('iteration_preset_errors');
  } else {
    preset_error.classList.add('d-none');
    preset_error.innerText = '';
  }
}
