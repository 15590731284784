import { useState, useContext, useEffect } from 'react';
import { StreamContext } from '../components/SecondCamera/contexts';
import { getPrioritizedDeviceList } from '../components/SecondCamera/DeviceHelper';

export default function useCamAndMicPermissions(onSuccess = () => {}) {
  const [permissionDenied, setPermissionDenied] = useState(false);
  const { setDeviceList, setSelectedDevice, setVideoStream } =
    useContext(StreamContext);

  useEffect(() => {
    requestCamAndMicPermission();
  }, []);

  const requestCamAndMicPermission = async () => {
    const aspectRatio = (16 / 9).toFixed(2);

    try {
      const initialStream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });

      initialStream.getTracks().forEach((track) => track.stop());
      await new Promise((resolve) => setTimeout(resolve, 1000));

      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter((d) => d.kind === 'videoinput');
      const audioDevices = devices.filter((d) => d.kind === 'audioinput');

      setDeviceList(videoDevices);
      const prioritizedDevices = getPrioritizedDeviceList(videoDevices);
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: prioritizedDevices[0].device.deviceId,
          aspectRatio: { ideal: aspectRatio },
        },
        audio: {
          deviceId: audioDevices[0].deviceId,
        },
      });
      setSelectedDevice(stream.getVideoTracks()[0].getSettings().deviceId);
      setVideoStream(stream);
      onSuccess();
    } catch (err) {
      if (err instanceof DOMException && err.name === 'NotAllowedError') {
        setPermissionDenied(true);
      } else {
        console.error('Error capturing media', err);
      }
    }
  };

  return {
    requestCamAndMicPermission,
    permissionDenied,
  };
}
