// BISummaryDashboard
//
// This class handles initializing the Summary Dashboard view
// retrieved from Tableau
class BISummaryDashboard {
  constructor() {
    this.containerDiv = document.getElementById('vizContainer');
  }

  init() {
    if (!this.containerDiv) {
      return;
    }
    const height = this.containerDiv.offsetHeight;
    const width = this.containerDiv.offsetWidth;
    const iterationId = document.getElementById('iteration_uuid').value;
    const institutionId = document.getElementById('institution_uuid').value;
    const authorizedTicket = document.getElementById('authorized_ticket').value;

    let url =
      `https://tableau-ext.proctoru.com` +
      `/trusted/${authorizedTicket}` +
      `${this.getDashboardType()}` +
      `?Institution UUID=${institutionId}` +
      `&Iteration UUID=${iterationId}`;

    const options = {
      hideTabs: false,
      hideToolbar: true,
      width: width + 'px',
      height: height + 'px',
      onFirstInteractive: () => { }
    };

    // if tableau JS has not loaded yet, refresh the page
    // it should be loaded after a page refresh
    if (typeof tableau == 'undefined' || tableau.Viz == undefined) {
      location.reload();
    } else {
      // Create a viz object and embed it in the container div.
      new tableau.Viz(this.containerDiv, url, options);
    }

}
  getDashboardType() {
    if (this.containerDiv.dataset.dashboardType == 'single-user')
      return '/views/ClientPortal-SingleUserv2/SingleUserExperience'
    else
      return '/views/ClientPortal/Summary';
  }
}

export default BISummaryDashboard;
