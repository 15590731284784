import handleUnintegratedAutoServiceType from './utils/handleUnintegratedAutoServiceType';
import setIterationType from './utils/setIterationType';

class ServiceType{
  constructor() {
    this.iterationServiceTypeList = document.querySelector('#iteration_service_type_list');
    this.iterationTypeSelector =
          document.querySelector('[data-element="iteration-types"]') ||
          document.querySelector('[data-element="iteration-type-hidden"]');
  }

  init() {
    if (this.iterationServiceTypeList) {
      // Jquery required for select2
      $('#iteration_service_type_list').on('change', () => { this.handleChange() })
    }
  }

  handleChange() {
    if (!this.iterationServiceTypeList || !this.iterationTypeSelector) {
      return;
    }
    const select = this.iterationServiceTypeList;
    const selectedServiceType = select.options[select.selectedIndex];
    const isUintegratedAuto = selectedServiceType && selectedServiceType.value === 'unintegrated-auto';

    handleUnintegratedAutoServiceType(isUintegratedAuto);

    const iterationType = selectedServiceType ? selectedServiceType.getAttribute('data-iteration-type') : null;
    setIterationType(iterationType);
  }


}

export default ServiceType;
