import csrfToken from '../../src/utils/csrf';
import AssignedStartsRow from './AssignedStartsRow';
import Toast from '../../src/utils/Toast';
import consumer from '../../src/channels/consumer';

class AssignedStartsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      loading: true,
    };
    this.setupActionCable();
  }

  componentDidMount() {
    fetch('/internal/assigned-starts.json')
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        this.setState({ tableData: this.convertDates(res.data) });
      });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  setupActionCable() {
    this.assignedStarts = consumer.subscriptions.create(
      {
        channel: 'AssignedStartsChannel',
      },
      {
        received: (data) => this.handleMessage(data),
      },
    );
  }

  handleMessage(data) {
    switch (data.action) {
      case 'DELETE':
        this.handleRowDeletion(data.data[0]);
        break;
      case 'PUT':
        this.handleRowUpdate(data.data[0]);
        break;
      case 'POST':
        this.handleRowAdd(data.data[0]);
        break;
    }
  }

  handleRowDeletion(data) {
    const tempData = this.state.tableData;
    const indexToRemove = tempData.findIndex(
      (item) => item.fulfillment_uuid === data.fulfillment_uuid,
    );

    if (indexToRemove > -1) {
      tempData.splice(indexToRemove, 1);
      this.setState({ tableData: tempData });
    }
  }

  handleRowUpdate(data) {
    const tempData = this.state.tableData;
    tempData[
      tempData.findIndex(
        (item) => item.fulfillment_uuid === data.fulfillment_uuid,
      )
    ] = data;
    this.setState({ tableData: tempData });
  }

  handleRowAdd(data) {
    const updatedData = this.state.tableData;
    updatedData.push(data);
    this.setState({ tableData: updatedData });
  }

  unsubscribe() {
    this.assignedStarts.unsubscribe();
  }

  handleClick(data, type) {
    const userId = data.user_link.split('/')[2];
    fetch(
      `/internal/waiting-proctors/${userId}?fulfillment_uuid=${data.fulfillment_uuid}&type=${data.waiting_proctor_type}`,
      {
        method: type,
        credentials: 'same-origin',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken(),
        },
      },
    )
      .then((response) => {
        if (response.ok) {
          return response;
        }
        throw new Error();
      })
      .then(() => {
        new Toast().success({ message: 'Success!' });
      })
      .catch(() => {
        new Toast().danger({ message: 'Fail!' });
      });
  }

  convertDates(data) {
    data.map((fulfillment) => {
      fulfillment.fulfillment_state_created_at = moment(
        fulfillment.fulfillment_state_created_at,
      );
      fulfillment.fulfillment_state_updated_at = moment(
        fulfillment.fulfillment_state_updated_at,
      );
    });

    return this.sortData(data);
  }

  sortData(data) {
    return data.sort((a, b) =>
      a.fulfillment_state_created_at.diff(b.fulfillment_state_created_at),
    );
  }

  renderTable(tableData) {
    return (
      <div className="card table-responsive">
        <table className="table table-sm table-semibordered table-hover table-default">
          <thead>
            <tr>
              <td>Test-taker ID</td>
              <td>Institution</td>
              <td>Exam</td>
              <td>Proctor</td>
              <td>State</td>
              <td>Type</td>
              <td />
            </tr>
          </thead>
          <tbody>
            {tableData.map((session) => {
              return (
                <AssignedStartsRow
                  session={session}
                  key={session.fulfillment_uuid + session.waiting_proctor_type}
                  handleClick={this.handleClick}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    const { tableData } = this.state;
    return tableData.length > 0 ? (
      this.renderTable(tableData)
    ) : (
      <div className="p-4 text-center">
        <h4>There are no assigned starts to display.</h4>
      </div>
    );
  }
}

export default AssignedStartsTable;
