class Spinner extends React.Component {
  constructor(props){
    super()
  }

  render() {
    return (
      <div className="text-center">
        <i className="far fa-circle-notch fa-spin fa-2x text-primary"></i>
      </div>
    )
  }
}

export default Spinner;
