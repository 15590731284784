import '../utils/closest';
import Toast from '../utils/Toast';
import axios from 'axios';
import csrfToken from '../utils/csrf';

class MarkAsRead {
  constructor() {
    this.markAsReadLinks = document.querySelectorAll(
      '[data-behavior="mark-release-note-read"]'
    );

  }

  init() {
    this.bindEventListeners();
  }

  bindEventListeners() {
    this.markAsReadLinks.forEach(link => {
      link.addEventListener('click', (event) => { this.handleClick(event) });
    });
  }

  handleClick(event) {
    event.preventDefault();

    const currentTarget = event.currentTarget;
    currentTarget.innerText = 'Loading...';

    axios.get(currentTarget.getAttribute('data-url'), this.getHeaders())
    .then(response => {
      this.handleSuccessResponse(currentTarget);
      this.checkForEmptyPanel();
    })
    .catch(error => {
      this.handleErrorResponse();
    });
  }

  handleSuccessResponse(currentTarget) {
    currentTarget.parentElement.remove();
  }

  handleErrorResponse() {
    new Toast().danger({message: 'An error occurred trying to hide this Release Note.'});
  }

  checkForEmptyPanel() {
    const releaseNotes = document.querySelectorAll(
      '.release-note'
    );
    // If there are no more Release Note panels... show the up to date message.
    if (releaseNotes.length === 0) {
      const element = document.querySelector('.card');
      element.classList.add('p-3');
      element.textContent = 'You are all up to date!';
    }
    this.updateNavbarStatusIcon();
  }

  updateNavbarStatusIcon() {
    const removeNavbarStatus = document.querySelector('.navbar-status');
    removeNavbarStatus.classList.remove('with-unread');
    removeNavbarStatus.setAttribute(
      'data-original-title',
      'You are all up to date!'
    );
  }

  getHeaders() {
    return {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken()
      }
    }
  }
}

export default MarkAsRead;
