class FlightpathPopoutButton {
  constructor() {
    this.button = document.querySelector('.js-flightpath-popout');
  }

  init() {
    if(this.button) {
      this.button.addEventListener('click', (event) => { this.handleClick(event) });
    }
  }

  handleClick(event) {
    event.preventDefault();
    window.open(this.button.getAttribute('href'), '_blank', 'height=600, width=600')
  }
}

export default FlightpathPopoutButton;
