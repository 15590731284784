import React, { Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import Paginator from '../Paginator';
import LoadingIndicator from '../LoadingIndicator';

const UserReport = ({
  statusLabel,
  showModal,
  handleClose,
  examName,
  showReportLoadingProgress,
  currentItems,
  statusBadge,
  statusForMapper,
  paginatorReport,
}) => {
  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        dialogClassName="d-flex w-100 h-0 lms-modal-layout modal-dialog-full-width lms-modal-dialog-centered"
      >
        <Modal.Header closeButton id="step-modal-header">
          <Modal.Title className="custom-header">User Reports</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0" id="step-modal-body">
          <div className="d-flex flex-column w-100 lms-modal-body-layout lms-modal-body-text">
            <div className="modal-exam-name">{examName}</div>
            {!showReportLoadingProgress ? (
              <>
                <div className="w-100 card">
                  <table className="table table-sm lms-table-hover table-default mb-0">
                    <thead>
                      <tr className="report-table-header">
                        <th key={Math.random()} scope="col">
                          Candidate Name
                        </th>
                        <th key={Math.random()} scope="col">
                          Candidate Email
                        </th>
                        <th key={Math.random()} scope="col">
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems.map(({ name, email, status }, index) => (
                        <Fragment key={index}>
                          <tr className="h-3 report-table-body">
                            <td scope="row">{name}</td>
                            <td scope="row">{email}</td>
                            <td scope="row">
                              <span
                                className={statusBadge(
                                  statusForMapper(statusLabel(status)),
                                )}
                              >
                                {statusLabel(status)}
                              </span>
                            </td>
                          </tr>
                        </Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
                <Paginator paginator={paginatorReport}></Paginator>
              </>
            ) : (
              <div className="w-100">
                <LoadingIndicator />
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UserReport;
