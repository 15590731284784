const Select = ({field, generatedId}) => {
  return (
    <div>
      <select className="form-control"
        name={`[user][enrollments_attributes][${generatedId}][fields][${field.field_as_attribute}]`}
        id={`[user][enrollments_attributes][${generatedId}][fields][${field.field_as_attribute}]`}
        data-type="select_field">
       {
         field.select_options.map(option => {
          return <option key={option} value={option}>{option}</option>
         })
       }
      </select>
      <span className="form-text text-muted">{field.description}</span>
    </div>
  )
}

export default Select
