class BootstrapPopover {
  constructor() {
    let $popovers = $('[data-toggle=popover]');
    if ($popovers.length) {
      $popovers.popover();
    }
  }
}

export default BootstrapPopover;
