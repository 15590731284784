import React, { useEffect } from 'react';

import UserReport from './UserReport';

const UserReportContainer = (props) => {
  const {
    exam,
    showModal,
    handleClose,
    paginatorReport,
    showReportLoadingProgress,
    setShowReportLoadingProgress,
  } = props || {};
  const { exam_name: examName, id: examId } = exam || {};
  useEffect(() => {
    async function getUserReport(examId) {
      let response;
      const pageUrl = `/institutions/exams/${examId}/proctor_configs/user_report`;
      response = await fetch(pageUrl, { method: 'GET' });
      return response.json();
    }

    if (exam && showModal) {
      getUserReport(exam.id).then((result) => {
        setShowReportLoadingProgress(false);
        paginatorReport.setAllItems(result);
      });
    }
  }, [showModal]);

  const isValidData = () => examName !== undefined && examId !== undefined;

  const statusForMapper = (status) => status.replace(/\s/g, '').toLowerCase();

  const statusBadge = (status) => {
    const badgeMapper = {
      scheduled: 'badge-scheduled',
      voided: 'badge-voided',
      cancelled: 'badge-cancelled',
      fulfilled: 'badge-fulfilled',
      pending: 'badge-pending',
      notscheduled: 'badge-light',
    };

    return status
      ? `text-capitalize badge badge-pill ${badgeMapper[status]}`
      : '';
  };

  const statusLabel = (status) => status ?? 'Not Scheduled';

  return isValidData ? (
    <UserReport
      statusLabel={statusLabel}
      showModal={showModal}
      handleClose={handleClose}
      examName={examName}
      showReportLoadingProgress={showReportLoadingProgress}
      currentItems={paginatorReport.currentItems}
      statusBadge={statusBadge}
      statusForMapper={statusForMapper}
      paginatorReport={paginatorReport}
    />
  ) : null;
};

export { UserReportContainer };
