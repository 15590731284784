import Cjs from '@meazure/copernicusjs';
import _ from 'lodash';

/**
 * This class is a helper class to connect to Copernicus.js on the fulfillment show page
 * and watcher window pages for proctors.
 *
 * These pages contain 2 individual react apps that need to share the same Copernicus.js instance:
 * 1. a ChatApp for audio
 * 2. a VideoPlayer (either legacy or combined)
 *
 * This class will act as a singleton on each page to ensure the right instance gets shared with the right Apps.
 */
class _CopernicusHelper {
  constructor() {
    /** @type {import('@meazure/copernicusjs').CjsSettings} */
    this.options = {};
    /** @type {import('@meazure/copernicusjs').CjsCallbacks} */
    this.callbacks = {};
    /** @type {Record<'ChatApp' | 'VideoPlayer', boolean>} */
    this.registeredApps = {};

    // Note: this promise will never settle until one of the callbacks is fired
    /** @type {Promise<Cjs>} */
    this.instancePromise = new Promise((resolve, reject) => {
      this.resolveInstance = resolve;
      this.rejectInstance = reject;
    });
  }

  /**
   * Actually initialize the instance for C.js, the apps need to be registered first.
   */
  async _init() {
    // NOTE: Guard against this being somehow accidentally called twice
    if (this._instance) return;

    try {
      console.log('[CopernicusHelper] Initializing C.js instance:', this.options);
      this._instance = new Cjs(this.options, this.callbacks);
      await this._instance.initialize();
      this.resolveInstance(this._instance);
    } catch (error) {
      console.error('[CopernicusHelper] Error creating C.js instance:', error);
      this.rejectInstance(error);
    }
  }

  /**
   * Returns true if all the required apps are registred
   * @returns {boolean}
   */
  _allAppsRegistered() {
    if (window.location.href.indexOf('reservations/') > -1) {
      // NOTE: the ChatApp is not present on the reservations page
      return this.registeredApps['VideoPlayer'];
    } else {
      return this.registeredApps['ChatApp'] && this.registeredApps['VideoPlayer'];
    }
  }

  /**
   * Register one of the apps
   *
   * @param {'ChatApp' | 'VideoPlayer'} app - The name of the app you want to register
   * @param {import('@meazure/copernicusjs').CjsSettings} options - The copernicus.js options associated with this app
   * @param {import('@meazure/copernicusjs').CjsCallbacks} callbacks - Callbacks to register with copernicus.js
   * @returns
   */
  async _register(app, options, callbacks) {
    if (this.registeredApps[app]) return;

    console.info('[CopernicusHelper] Registering app:', app);

    this.registeredApps[app] = true;
    // Deeply merge the options so that each app can specify its own config
    this.options = _.merge(this.options, options);
    this.callbacks = _.merge(this.callbacks, callbacks);

    if (this._allAppsRegistered()) {
      await this._init();
    }
  }

  /**
   * Registers one of the 2 apps on the page, and then awaits the instance
   *
   * @param {'ChatApp' | 'VideoPlayer'} app - The name of the app you want to register
   * @param {import('@meazure/copernicusjs').CjsSettings} options - The copernicus.js options associated with this app
   * @param {import('@meazure/copernicusjs').CjsCallbacks} callbacks - Callbacks to register with the copernicus.js instance
   * @returns {Promise<Cjs>} The C.js instance
   */
  async getInstance(app, options = {}, callbacks = {}) {
    await this._register(app, options, callbacks);
    return this.instancePromise;
  }

  /**
   * Close the C.js instance and return everything to its initial state
   *
   * NOTE: this is super important for when the user navigates around with turbolinks as it will not use
   * a full page refresh
   */
  async closeInstance() {
    this.registeredApps = {};
    this.options = {};
    this.callbacks = {};
    this._instance = null;

    const oldInstancePromise = this.instancePromise;
    this.instancePromise = new Promise((resolve, reject) => {
      this.resolveInstance = resolve;
      this.rejectInstance = reject;
    });

    const instance = await oldInstancePromise;
    await instance.close();
  }
}

const CopernicusHelper = new _CopernicusHelper();

export default CopernicusHelper;
