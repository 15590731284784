class RegistrationDelay {
  constructor() {
    this.registrationForm = document.querySelector('#student-user-form')
  }

  init() {
    if(this.registrationForm) {
      this.registrationForm.addEventListener('submit', (event) => {
        this.handleSubmit(event)
      });
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    let delay = 0;
    try {
      delay = parseInt(this.registrationForm.dataset.delay, 10);
    } catch (_error) {
     delay = 0;
    }
    setTimeout(() => { this.registrationForm.submit() }, delay);
  }
}

export default RegistrationDelay;
