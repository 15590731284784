class DefaultEntry extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      elapsedTime: this.getElapsedTime(props.entry.data.created_at)
    }

    this.startTimer = this.startTimer.bind(this)
  }

  componentDidMount() {
    this.startTimer()
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  startTimer() {
    if (this.timer) clearInterval(this.timer)

    this.timer = setInterval(() => {
      this.setState({
        elapsedTime: this.getElapsedTime(this.props.entry.data.created_at)
      })
    }, 1000)
  }

  getElapsedTime(createdAt) {
    const originalTime = moment(createdAt)
    const currentTime = moment.utc()
    return moment.utc(currentTime.diff(originalTime)).format('HH:mm:ss')
  }

  render () {
    const {
      entry: {
        id,
        fulfillmentUrl,
        watcherWindowUrl,
        disabled,
        tt_id,
        data: {
          exam_name,
          actions,
          type
        }
      },
      renderAction
    } = this.props

    const { elapsedTime } = this.state

    return (
      <tr className={ disabled ? 'd-none' : '' }>
        <td>
          <ul className=''>
            <li><b>Test-taker ID:</b> {tt_id}</li>
            <li><b>Exam:</b> {exam_name}</li>
            <li>
              <span className="">
                <a href={fulfillmentUrl} target="_blank">Fulfillment</a>
              </span>
              { watcherWindowUrl &&
                <span className="">
                  <a href={watcherWindowUrl} target="_blank">Watcher Window</a>
                </span>
              }
            </li>
          </ul>
        </td>
        <td>
          { elapsedTime }
        </td>
        <td>
          {type}
        </td>
        <td className="text-right">
          <ul className=''>
            { actions.map(action => renderAction(action, id)) }
          </ul>
        </td>
      </tr>
    )
  }
}

export default DefaultEntry;
