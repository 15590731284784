import ResourcesCheckboxes from './ResourcesCheckboxes';

class NoResourcesCheckbox {
  constructor() {
    this.noResourcesAllowed = document.querySelector('.js-black-sheep');
  }

  init() {
    this.bindEventListeners();
  }

  bindEventListeners() {
    this.noResourcesAllowed.addEventListener('click', (event) => {
      this.handleClick(event);
    })
  }

  handleClick(event) {
    if(this.noResourcesAllowed.checked) {
      new ResourcesCheckboxes().uncheckAllowedResources()
    }
  }
}

export default NoResourcesCheckbox;
