class DepartmentSelect {
  constructor() {
    this.department = document.querySelector('.js-department-select');
    this.cost = document.querySelector('.js-cost-field');
    this.split = document.querySelector('.js-split-field');
  }

  init() {
    this.bindEventListeners();
  }

  bindEventListeners() {
    this.department.addEventListener('change', (event) => {
      this.handleChange()
    });
  }

  handleChange() {
    const selectedOption =  this.department.options[ this.department.selectedIndex];
    const defaultCost = selectedOption.getAttribute('data-default-cost');
    const defaultSplit = selectedOption.getAttribute('data-default-split');
    this.cost.value = defaultCost;
    this.split.value = defaultSplit;
  }
}

export default DepartmentSelect;
