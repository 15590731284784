import moment from 'moment';
import getMoment from './shared/getMoment';

class TimePicker {
  constructor() {
    this.time = $('#reservation-time-picker')
  }

  init() {
    this.time.datetimepicker({
      defaultDate: this.time.val(),
      format: 'LT',
      inline: true,
      icons: {
        up: 'far fa-chevron-up fa-lg',
        down: 'far fa-chevron-down fa-lg'
      },
      stepping: 5,
      disabledTimeIntervals: this.getDisabledTimeIntervals()
    });
  }

  getDisabledTimeIntervals() {
    let timeIntervals = [];
    $.each(this.time.data('disabled-time-intervals'), function(i, v) {
      var moments;
      moments = [];
      $.each(v, function(i, v) {
        return moments.push(getMoment(v));
      });
      return timeIntervals.push(moments);
    });
    return timeIntervals;
  };
}

 export default TimePicker;
