import axios from 'axios';
import { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { snakeCase, mapKeys } from 'lodash';
import * as FullStory from '@fullstory/browser';

import {
  getMediaDevicesInfo,
  startVideoStream,
} from '../../utils/MediaDevices';
import csrfToken from '../../src/utils/csrf';
import SecureBrowserSubscription from '../../src/channels/subscriptions/SecureBrowserSubscription';
import GenericSpinner from '../GenericSpinner';
import ProgressBar from '../Shared/ProgressBar';
import LanguageDropdown from '../Shared/LanguageDropdown';

import WelcomeCard from './Welcome/WelcomeCard';
import LmiDownloadCard from './LmiDownloadCard';
import PaymentsCard from './PaymentsCard';
import ShareScreenpageCard from './ShareScreenpageCard';
import AllowedResourcesCard from './AllowedResourcesCard';
import OtherResourcesCard from './OtherResourcesCard';
import GuidelinesCard from './GuidelinesCard';
import IdStepCard from './IdStepCard';
import ProfilePhotoCard from './ProfilePhotoCard';
import ExamLobbyCard from './ExamLobbyCard';
import PaymentsMessage from './PaymentsMessage';
import GuidedLaunchInstructionsCard from './GuidedLaunchInstructionsCard';
import GuidedLaunchBeginScanCard from './GuidedLaunchBeginScanCard';
import GuidedLaunchCaptureWorkspaceCard from './GuidedLaunchCaptureWorkspaceCard';
import GuidedLaunchCaptureBelowWorkspaceCard from './GuidedLaunchCaptureBelowWorkspaceCard';
import GuidedLaunchCaptureBackWallCard from './GuidedLaunchCaptureBackWallCard';
import GuidedLaunchRoomScanReviewCard from './GuidedLaunchRoomScanReviewCard';
import GuidedLaunchCaptureLeftWallCard from './GuidedLaunchCaptureLeftWallCard';
import GuidedLaunchCaptureRightWallCard from './GuidedLaunchCaptureRightWallCard';
import GuidedLaunchCellPhoneCheckCard from './GuidedLaunchCellPhoneCheckCard';
import GuidedLaunchCaptureComputer from './GuidedLaunchCaptureComputer';
import GuidedLaunchSelectResources from './GuidedLaunchSelectResources';
import GuidedLaunchResourceCheck from './GuidedLaunchResourceCheck';
import SecondCameraLaunchCard from './SecondCameraLaunchCard';
import SecondCameraPositioningCard from './SecondCameraPositioningCard';
import UtilityCard from './UtilityCard';
import ExamInfoContainer from './ExamInfoContainer';

const PRECHECK_CARD_MAP = {
  WELCOME: WelcomeCard,
  LMI_DOWNLOAD: LmiDownloadCard,
  PAYMENTS: PaymentsCard,
  SHARESCREENPAGE: ShareScreenpageCard,
  ALLOWED_RESOURCES: AllowedResourcesCard,
  OTHER_RESOURCES: OtherResourcesCard,
  GUIDELINES: GuidelinesCard,
  PROFILE_PHOTO: ProfilePhotoCard,
  ID_STEP: IdStepCard,

  GUIDED_LAUNCH_BEGIN_SCAN: GuidedLaunchBeginScanCard,
  GUIDED_LAUNCH_INSTRUCTIONS: GuidedLaunchInstructionsCard,
  GUIDED_LAUNCH_CAPTURE_WORKSPACE: GuidedLaunchCaptureWorkspaceCard,
  GUIDED_LAUNCH_CAPTURE_LEFT_WALL: GuidedLaunchCaptureLeftWallCard,
  GUIDED_LAUNCH_CAPTURE_BACK_WALL: GuidedLaunchCaptureBackWallCard,
  GUIDED_LAUNCH_CAPTURE_RIGHT_WALL: GuidedLaunchCaptureRightWallCard,
  GUIDED_LAUNCH_CAPTURE_BELOW_WORKSPACE: GuidedLaunchCaptureBelowWorkspaceCard,
  GUIDED_LAUNCH_CAPTURE_COMPUTER: GuidedLaunchCaptureComputer,
  GUIDED_LAUNCH_ROOM_SCAN_REVIEW: GuidedLaunchRoomScanReviewCard,
  GUIDED_LAUNCH_CELL_PHONE_CHECK: GuidedLaunchCellPhoneCheckCard,
  GUIDED_LAUNCH_SELECT_RESOURCES: GuidedLaunchSelectResources,
  GUIDED_LAUNCH_RESOURCE_CHECK: GuidedLaunchResourceCheck,

  EXAM_LOBBY: ExamLobbyCard,

  SECOND_CAMERA_LAUNCH: SecondCameraLaunchCard,
  SECOND_CAMERA_POSITIONING: SecondCameraPositioningCard,

  UTILITY: UtilityCard,
};

const PrechecksApp = (props) => {
  const precheckData = props.precheckData;
  const fulfillmentUuid = precheckData.fulfillment.uuid;
  const [loading, setLoading] = useState(true);
  const [precheckConfig, setPrecheckConfig] = useState([]);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [sbConnection, setSbConnection] = useState(
    new SecureBrowserSubscription(precheckData.user.id),
  );
  const [hideMessage, setHideMessage] = useState(true);
  const [messageTimer, setMessageTimer] = useState(3);
  const [lmiConnected, setLmiConnected] = useState(false);
  const [proctorConnected, setProctorConnected] = useState(false);
  const fullStoryEnabled = precheckData.fullStoryEnabled;
  const lmiConnectedFlipper = precheckData.lmiConnectedEnabled;
  const [glImages, setGlImages] = useState([]);
  const [selectedResources, setSelectedResources] = useState({});
  const [selectedResourceCategories, setSelectedResourceCategories] = useState(
    {},
  );
  const [webcamName, setWebcamName] = useState('');

  if (fullStoryEnabled) {
    FullStory.init({
      orgId: 'NRQ1W',
      debug: false,
      host: 'fullstory.com',
      script: 'edge.fullstory.com/s/fs.js',
      namespace: 'FS',
    });
  }

  const [styleInvisible, setStyleInvisible] = useState();
  const invisibleCard = {
    backgroundColor: '#F8FCFC',
    border: 0,
  };

  const showExamInfo = (configName) => {
    return (
      !configName.includes('WELCOME') &&
      configName !== 'SHARESCREENPAGE' &&
      configName !== 'SECOND_CAMERA_POSITIONING'
    );
  };

  /**
   * Sends a user workflow event to the backend.
   * @param {string} eventType
   * @param {unknown?} payload
   * @param {string?} eventEndpointType
   * @param {((response: AxiosResponse) => void)?} responseFunction
   * @param {((error?: any) => void)?} errorFunction
   * @returns {true | void}
   */
  const sendEvent = (
    eventType,
    payload = {},
    eventEndpointType = 'prechecks',
    responseFunction = undefined,
    errorFunction = undefined,
  ) => {
    const eventPayload = {
      uuid: fulfillmentUuid,
      event: {
        type: eventType,
        ...payload,
      },
    };

    if (
      eventType == 'Event::UnlockExam' &&
      precheckData.unlockExamEventExists
    ) {
      return true;
    }

    if (
      eventType == 'Event::ShareScreenpageLoaded' ||
      eventType == 'Event::SecondCameraPositioningLoaded'
    ) {
      setStyleInvisible(invisibleCard);
    } else {
      setStyleInvisible();
    }

    // this would be an axios call or possible action cable sequence to send the events
    console.log(`Sending ${eventType} event:`, eventPayload);
    axios
      .post(
        `${window.location.origin}/api/secure_browser/events/${eventEndpointType}`,
        mapKeys(eventPayload, (value, key) => snakeCase(key)),
        {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken(),
        },
      )
      .then((response) => {
        console.log(response);
        if (responseFunction !== undefined) {
          responseFunction(response);
        }
      })
      .catch((error) => {
        console.log(error);
        if (errorFunction !== undefined) {
          errorFunction(error);
        }
      });
  };

  const updateFulfillment = (params) => {
    console.log('Setting fulfillment to running state');
    axios
      .post(
        `${window.location.origin}/api/secure_browser/fulfillments/${fulfillmentUuid}`,
        { ...params },
        {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken(),
        },
      )
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };

  useEffect(() => {
    const createFulfillmentLocation = () => {
      axios
        .post(
          `${window.location.origin}/api/secure_browser/fulfillments/${fulfillmentUuid}/location`,
          {},
          {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken(),
          },
        )
        .then((response) => console.log(response))
        .catch((error) => console.log(error.response.data.message));
    };

    createFulfillmentLocation();
  }, []);

  useEffect(() => {
    if (hideMessage === false && messageTimer > 1) {
      const timer = setInterval(() => {
        setMessageTimer((messageTimer) => messageTimer - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [hideMessage, messageTimer]);

  const getGlImages = async (img_size = null) => {
    let img_url = `${window.location.origin}/api/secure_browser/events/prechecks/${fulfillmentUuid}/images`;

    if (img_size != null) {
      img_url = `${img_url}?img_size=${img_size}`;
    }
    return await axios
      .get(
        img_url,
        {},
        {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken(),
        },
      )
      .then((response) => {
        if (response.data && response.data.hasOwnProperty('glImages')) {
          let newArray = JSON.parse(response.data.glImages);
          setGlImages(newArray);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const prechecksTimeout = () => {
    setHideMessage(true);
    handleNext();
  };

  const handleArchimedesMessage = (data) => {
    switch (data.messageType) {
      case 'redirect_to_exam_url':
        handleBeginExam();
        break;
      case 'payment_received':
        if (precheckConfig[currentPageIndex] == 'PAYMENTS') {
          setHideMessage(false);
          setTimeout(() => {
            prechecksTimeout();
          }, 3000);
        }
        break;
      case 'lmi_connected':
        setLmiConnected(true);
        break;
      case 'proctor_connected':
        setProctorConnected(true);
        break;
      case 'webcam_name':
        if (webcamName !== data.webcamName) {
          setWebcamName(data.webcamName);
        }
        break;
      case 'kiosk_mode_on':
        handleKioskMode(data);
        break;
      case 'kiosk_mode_off':
        handleKioskMode(data);
        break;
      case 'chromebook_begin_exam':
        handleNext();
        break;
      case 'utility_tab_loaded':
        break;
      default:
        console.log('Could not understand archimedes directive: ', data);
    }
  };

  const handleSecureBrowserMessage = (data) => {
    switch (data.messageType) {
      case 'screen_share_started':
        if (precheckConfig[currentPageIndex] == 'SHARESCREENPAGE') {
          sendEvent('Event::ScreenShareStart');
          updateFulfillment({ exam_started: true });
          handleNext();
        }
        break;
      case 'second_camera_added':
        sendEvent('Event::SecondCameraAdded');
        handleNext();
        break;
      case 'second_camera_setup_finished':
        sendEvent('Event::SecondCameraSetupFinished');
        handleNext();
        break;
      default:
        console.log('Could not understand secure browser directive: ', data);
    }
  };

  //UI will be done on the GB side
  const handleKioskMode = (data) => {
    switch (data.messageType) {
      case 'kiosk_mode_on':
        console.log('on');
        break;
      case 'kiosk_mode_off':
        console.log('off');
        break;
      default:
        console.log('Could not understand kiosk mode directive: ', data);
    }
  };

  const handleMessages = (data) => {
    switch (data.sender) {
      case 'archimedes':
        handleArchimedesMessage(data);
        break;
      case 'secure_browser':
        handleSecureBrowserMessage(data);
        break;
      default:
        console.log('Cannot understand message', data);
    }
  };

  const handleNext = () => {
    setCurrentPageIndex(currentPageIndex + 1);
  };

  const handlePrevious = (rescan = false) => {
    if (rescan && precheckConfig.includes('GUIDED_LAUNCH_BEGIN_SCAN')) {
      setCurrentPageIndex(
        precheckConfig.indexOf('GUIDED_LAUNCH_CAPTURE_WORKSPACE'),
      );
    } else {
      setCurrentPageIndex(currentPageIndex - 1);
    }
  };

  const handleResourceChange = (event, resourceKey, resourceCategory) => {
    const isChecked = event.target.checked;
    setSelectedResources((prevState) => ({
      ...prevState,
      [resourceKey]: isChecked,
    }));
    setSelectedResourceCategories((prevState) => ({
      ...prevState,
      [resourceCategory]: isChecked,
    }));
  };
  const handleResourceSelection = {
    selectedResourceCategories,
    selectedResources,
    setSelectedResources,
    handleResourceChange,
  };
  const selectVideoDevices = (videoDevices) => {
    if (videoDevices.length > 1 && webcamName) {
      const previousSelectedDevice = videoDevices.find(
        (device) => device.label.toLowerCase() === webcamName?.toLowerCase(),
      );

      if (previousSelectedDevice) {
        return previousSelectedDevice.deviceId;
      }
    }

    return videoDevices[0].deviceId;
  };

  const streamWebcam = (videoElement) => {
    getMediaDevicesInfo(true, true)
      .then((devices) => {
        const videoDevices = devices.filter(
          (device) => device.kind === 'videoinput',
        );
        const audioDevices = devices.filter(
          (device) => device.kind === 'audioinput',
        );
        startVideoStream(
          audioDevices[0].deviceId,
          selectVideoDevices(videoDevices),
          videoElement,
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const broadcastMessage = async (message) => {
    await axios
      .post(
        `${window.location.origin}/api/secure_browser/fulfillments/${fulfillmentUuid}/broadcasting`,
        { msg: JSON.stringify(message) },
        {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken(),
        },
      )
      .then((response) => {
        console.log('Message sent:', response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleBeginExam = () => {
    const { examUrl, userAgent, extensionOverride: isExtension } = precheckData;
    try {
      // Due to how LMSs skip the auto.haml.html because of their login_token in the url
      // it is possible some TT may reach this step with the GB even though the
      // guardian_extension_override is enabled. Thus, we want to check the UA too.
      if (!isExtension && userAgent.includes('GuardianBrowser')) {
        location.href = examUrl;
      } else {
        window.open(examUrl, '_blank');
        // Sent down the archimedes channel to the chromebook extension
        broadcastMessage({
          sender: 'archimedes',
          messageType: 'chromebook_begin_exam',
          data: {},
        });
      }
    } catch (error) {
      console.log('Error taking you to prechecks: ', error);
    }
  };

  useEffect(() => {
    // Will be used when integrated together with jsonapi+backend tables, unused for now
    const getPrecheckConfigData = async () => {
      await axios
        .get(
          `${window.location.origin}/api/secure_browser/configs/${fulfillmentUuid}/prechecks`,
          {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken(),
          },
        )
        .then((response) => {
          setPrecheckConfig(response.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    getPrecheckConfigData();
  }, []);

  useEffect(() => {
    sbConnection.init();

    sbConnection.secureBrowser.received = (res) => {
      const results = typeof res === 'string' ? JSON.parse(res) : res;
      handleMessages(results);
    };
  }, []);

  useEffect(() => {
    if (sbConnection.secureBrowser !== null) {
      sbConnection.secureBrowser.received = (res) => {
        const results = typeof res === 'string' ? JSON.parse(res) : res;
        handleMessages(results);
      };
      setSbConnection(sbConnection);
    }
  }, [currentPageIndex]);

  const handleLastPage = (event) => {
    event.preventDefault();

    const lastPageIndex = precheckConfig.length - 1;

    if (precheckConfig[currentPageIndex] == 'GUIDED_LAUNCH_INSTRUCTIONS') {
      const examLobbyIndex = precheckConfig.indexOf('EXAM_LOBBY');
      setCurrentPageIndex(
        examLobbyIndex !== -1 ? examLobbyIndex : lastPageIndex,
      );
      return;
    }
    setCurrentPageIndex(lastPageIndex);
  };

  const showLanguageDropdown = (configName) => {
    return configName.includes('WELCOME');
  };

  return (
    <>
      <ProgressBar
        precheckConfig={precheckConfig}
        currentPageIndex={currentPageIndex}
        precheckData={precheckData}
      />
      <Card className="precheck-card" style={styleInvisible}>
        <Card.Body>
          {loading ? (
            <GenericSpinner />
          ) : (
            precheckConfig.map((configName, index) => {
              const CurrentPrecheckComponent = PRECHECK_CARD_MAP[configName];
              return (
                <div key={`precheck-card-page-${index}`} aria-live={'polite'}>
                  {index == currentPageIndex ? (
                    <>
                      {showExamInfo(configName) && (
                        <ExamInfoContainer precheckData={precheckData} />
                      )}
                      {showLanguageDropdown(configName) && (
                        <LanguageDropdown languages={precheckData.languages} />
                      )}
                      <CurrentPrecheckComponent
                        id={`precheck-card-page-${index}`}
                        handleNext={handleNext}
                        handlePrevious={handlePrevious}
                        handleResourceSelection={handleResourceSelection}
                        sendEvent={sendEvent}
                        streamWebcam={streamWebcam}
                        precheckData={precheckData}
                        broadcastMessage={broadcastMessage}
                        lmiConnected={lmiConnected}
                        lmiConnectedFlipper={lmiConnectedFlipper}
                        proctorConnected={proctorConnected}
                        glImages={glImages}
                        getGlImages={getGlImages}
                        handleLastPage={handleLastPage}
                        handleBeginExam={handleBeginExam}
                      />
                    </>
                  ) : null}
                </div>
              );
            })
          )}
        </Card.Body>
      </Card>
      <div hidden={hideMessage} className="text-center">
        <PaymentsMessage messageTimer={messageTimer} />
      </div>
    </>
  );
};

export default PrechecksApp;
