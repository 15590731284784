import Toast from '../../../src/utils/Toast';
import csrfToken from '../../../src/utils/csrf';
import axios from 'axios';

class MediaDeletionHandler {
  handleDeletion(event) {
    const resource = event.target.dataset['behavior'].slice(10);
    const closest_li = event.target.closest('li');
    const div = (resource === 'image') ? closest_li.parentElement : closest_li.getElementsByTagName('div')[0];
    const id = (resource === 'image') ? div.id.slice(6) : div.id.slice(11);
    // To comply with WCAG and avoid the risk of any two div IDs on the page being the same,
    // we prefix either "image_" or "screenshot_" to the div ID.
    // That means we have to determine if the div is related to an image or screenshot first,
    // and then we slice off the prefix so we are left with just the pure ID of the object.
    const url = this.buildUrlForDeletion(resource, id);

    this.sendRequestForDeletion(url);
  }

  buildUrlForDeletion(resource, id) {
    const uuid = document.querySelector('#media-modal').dataset.attribute;
    const url = `${window.location.origin}/reservations/${uuid}/destroy_${resource}?${resource}_id=${id}`;
    return url;
  }

  sendRequestForDeletion(url) {
    const headers = {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken()
      }
    }
    axios.get(url, headers)
    .then((response) => {
      const target = (url.indexOf('image') !== -1) ? 'image' : 'screenshot';
      // determine if this is the image or screenshot tab
      const div = document.getElementById(`${target}_${response.data.id}`);
      // identify div that needs to be removed now that target is deleted
      div.parentNode.removeChild(div);
      // remove div

      new Toast().success({ message: polyglot.t('default_success') });

      if (document.querySelectorAll(`.view-media-${target}`).length == 0) {
      // if the list is now empty
        const resource = `${target}s`;
        // set the resource by making the target plural
        document.querySelector(`#${resource}`).innerHTML = polyglot.t('view_media_not_available', { resource: resource });
      }
    })
    .catch((error) => {
      console.log(error)
      const resource = (url.indexOf('image') !== -1) ? 'image' : 'screenshot';
      // set the resource
      new Toast().danger({ message: polyglot.t('view_media_deletion_error',
        { resource: resource }
      ) });
    })
  }
}

export default MediaDeletionHandler;
