import PrechecksFooterButtonGroup from './PrechecksFooterButtonGroup';

const PrechecksFooterButton = ({
  buttonText,
  handleClick,
  disabled,
  buttonStyleClass,
}) => {
  return (
    <PrechecksFooterButtonGroup
      buttonProps={[
        {
          buttonText,
          handleClick,
          disabled,
          buttonStyleClass,
        },
      ]}
    />
  );
};

PrechecksFooterButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
};

PrechecksFooterButton.defaultProps = {
  buttonText: polyglot.t('prechecks_card_sections_next_button'),
  buttonStyleClass: 'btn-precheck-primary',
};

export default PrechecksFooterButton;
