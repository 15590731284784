const event_types = [
  "Event::MouseClicked",
  "Event::MouseClickTotal",
  "Event::MouseScrolled",
  "Event::MouseScrollTotal",
  "Event::LowAudioLevel",
  "Event::ProctorDelayed",
  "Event::ProctorCanBeReassigned",
  "Event::AddedTimeForProctor",
  "Event::Assigned",
  "Event::LmiAppletLaunched",
  "Event::UnableToAssign",
  "Event::LmiTransferred",
  "Event::BroadcastMessagingError",
  "Event::ExamConfigFetchFailed",
  "Event::HardwareIssue",
  "Event::KurentoServerDown",
  "Event::KurentoServerUnavailable",
  "Event::MediaServerRegistrationIssue",
  "Event::RecordingSocketClosed",
  "Event::RecordingSocketCrushed",
  "Event::RecordingSocketNotOpened",
  "Event::StreamsFailed",
  "Event::InternetConnectionLoss",
  "Event::WebSocketIssue",
  "Event::WebrtcIssue",
  "Event::ScreenSharingRequestDenied",
  "Event::ScreenSharingCancellation",
  "Event::StreamTabClosed",
  "Event::WebcamPermissionRemoved",
  "Event::PingPongJavaIssue",
  "Event::TimeoutError",
  "Event::GuardianExtension",
  "Event::BeginExam",
  "Event::Authentication",
  "Event::ExamAssistance",
  "Event::GetHelp",
  "Event::ExamRules",
  "Event::PaymentPrompt",
  "Event::ExtensionSignIn",
  "Event::ExtensionSignUp",
  "Event::SystemChecks",
  "Event::SystemChecksRetry",
  "Event::RecordingStart",
  "Event::TakeIdPhoto",
  "Event::TakeFacePhoto",
  "Event::VerificationEmbedded",
  "Event::VerificationDownload",
  "Event::Welcome",
  "Event::ExamPrep",
  "Event::ExamStart",
  "Event::ExamRulesSpace",
  "Event::ExamRulesId",
  "Event::ExamRulesRecording",
  "Event::ExamRulesRecordingAckRules",
  "Event::ExamRulesRecordedAck",
  "Event::ExamRulesCheck",
  "Event::ExamRulesClose",
  "Event::ExamRulesDisconnectAck",
  "Event::ExamRulesAcknowledge",
  "Event::LmiDownload",
  "Event::LaunchExamClicked",
  "Event::ScreenShareStart",
  "Event::ConvertedToLive",
  "Event::LmiConnected",
  "Event::ApplicationTerminated",
  "Event::ApplicationDetected",
  "Event::RunningAppList",
  "Event::MicName",
  "Event::WebcamName",
  "Event::CPUTemp",
  "Event::ComputerModel",
  "Event::ComputerManufacturer",
  "Event::SystemType",
  "Event::ComputerName",
  "Event::DeviceID",
  "Event::WindowsActivationStatus",
  "Event::URLBlocked",
  "Event::MultipleKeyboardsDetected",
  "Event::MultipleMousesDetected",
  "Event::DevicePluggedIn",
  "Event::PrintScreen",
  "Event::WindowsKeyPrintScreen",
  "Event::NetworkData",
]

class TimelineEvent extends React.Component {
  constructor(props) {
    super(props)
  }

  iconWrapperClasses = () => {
    let {event} = this.props

    return ["icon", event.context_class].join(" ")
  }

  iconClasses = () => {
    let {event} = this.props

    return ["fa", "fa-" + event.icon].join(" ")
  }

  isHiddenEvent() {
    var {event} = this.props

    return event_types.indexOf(event.type) > -1
  }

  render() {
    let {event} = this.props,
      iconWrapperClasses = this.iconWrapperClasses(),
      iconClasses = this.iconClasses()

    if (event.webhook_url && event.webhook_url.length > 0) {
      webhookBtn = (
        <a href={event.webhook_url} className="btn btn-sm btn-secondary ml-2">
          View Webhook
        </a>
      )
    } else {
      webhookBtn = ""
    }

    if (event.score) {
      eventScore = (
        <p className="mb-0">
          Score: {event.score}
        </p>
      )
    } else {
      eventScore = ""
    }

    if (this.isHiddenEvent()) {
      return null
    }

    return (
      <li className="list-group-item" data-id={event.id}>
        <div className="media">
          <div className="float-right">
            <a
              href={event.edit_url}
              className="fa fa-pencil-alt no-hover"
              id={"edit_event_" + event.id}
            >
              <span
                id={"edit_event_check_" + event.id}
                className="fa fa-check-circle included js-included mt-2 d-none"
              />
            </a>
          </div>
          <div className="float-left">
            <div className="iconbox">
              <div className={iconWrapperClasses}>
                <span className={iconClasses} />
              </div>
            </div>
          </div>

          <div className="media-body">
            <small className="text-muted mx-auto">
              {event.created_at}
              {webhookBtn}
            </small>

            <strong className="media-heading mb-0">
              {event.heading}
            </strong>

            <p
              className="mb-0"
              dangerouslySetInnerHTML={{ __html: event.body }}
            />
            {eventScore}
          </div>
        </div>
      </li>
    )
  }
}

TimelineEvent.propTypes = {
  event: PropTypes.object.isRequired
}

export default TimelineEvent
