import axios from 'axios';
import csrfToken from '../utils/csrf';

class InstructorSelect2 {
  constructor() {
    this.selector = document.querySelector('.js-instructor-select');
    this.instructorName = document.getElementById('instructor_name');
    this.instructorEmail1 = document.getElementById('instructor_email1');
    this.instructorEmail2 = document.getElementById('instructor_email2');
    this.instructorPhone1 = document.getElementById('instructor_phone1');
    this.instructorPhone2 = document.getElementById('instructor_phone2');
    this.instructorNotes = document.getElementById('instructor_notes');
    this.instructorDepartmentId = document.getElementById('instructor_department_id');
    this.HEADERS = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken()
    }
  }

  init() {
    this.bindEventListeners();
    this.setInitialSelectionForSelect2();
  }

  bindEventListeners() {
    $(this.selector).select2({
      placeholder: 'Select an Instructor',
      theme: 'bootstrap4',
      allowClear: true,
      ajax: {
        url: `${this.selector.getAttribute('data-source')}`,
        dataType: 'json',
        data: function(params) { return { search: params.term } },
        processResults: function (instructors) { return { results: instructors }; }
      },
    });
    $(this.selector).on('change', () => {this.handleChange()});
  }

  getInstructorValue() {
    return this.selector.getAttribute('data-id') != null ? this.selector.getAttribute('data-id') : 0
  }

  setInitialSelectionForSelect2() {
    if(this.getInstructorValue().length < 1) { return }
    const url = `${this.selector.getAttribute('data-source')}`
    axios({method: 'get', url: url, params: {}, headers: this.HEADERS}).then(response => {
      response.data.forEach( instructor => {
        const option = new Option(instructor.text, instructor.id, true, true);
        $(this.selector).append(option)
      })
      $(this.selector).val(this.getInstructorValue())
    })
  }

  handleChange() {
    this.clearForm();
    if(this.selector.value !== "") {
      const id = this.selector.value;
      const url = `${this.selector.getAttribute('data-url')}/${id}`;
      axios({method: 'get', url: url, params: {}, headers: this.HEADERS}).then(response => {
        this.instructorName.value = `${response.data.first_name} ${response.data.last_name}`;
        this.instructorEmail1.value = response.data.email
        this.instructorPhone1.value = response.data.phone_mobile
        this.instructorPhone2.value = response.data.phone_home
        this.instructorDepartmentId.value = response.data.department_id
      });
    }
  }

  clearForm() {
    this.instructorName.value = '';
    this.instructorEmail1.value = '';
    this.instructorEmail2.value = '';
    this.instructorPhone1.value = '';
    this.instructorPhone2.value = '';
    this.instructorNotes.value = '';
  }
}

export default InstructorSelect2;
