class ServiceLine {
  constructor() {
    this.iterationTypeSelector =
      document.querySelector('[data-element="iteration-types"]') ||
      document.querySelector('[data-element="iteration-type-hidden"]');

    this.iterationServiceLineSelector = document.querySelector('[data-element="iteration-service-lines"]');
    this.iterationServiceTypeList = document.querySelector('#iteration_service_type_list');
  }

  init() {
    if (this.iterationTypeSelector) {
      this.iterationTypeSelector.addEventListener('change', () => { this.handleChange() });
    }
    
    if (this.iterationServiceLineSelector) { 
      this.iterationServiceLineSelector.addEventListener('change', () => { this.handleServiceLineChange() })
    }
  }

  handleServiceLineChange() {
    if(this.iterationServiceLineSelector.value == 'standalone_guardian_workflow'){
      this.iterationTypeSelector.value = 'Iteration::Automatic'
    }
  }

  handleChange() {
    switch (this.iterationTypeSelector.value) {
      case 'Iteration::Classic':
        this.setLiveServiceLine();
        break;
      case 'Iteration::AutomatedUnintegrated':
        this.setLivePlusServiceLine();
        break;
      case 'Iteration::Automatic':
        this.setAutoServiceLine();
        break;
      default:
        this.iterationServiceLineSelector.value = '';
        break;
    }
  }

  setLiveServiceLine() {
    const types = {
      'record_plus': ['auto-with-professional-review'],
      'review_plus': ['auto-with-live-launch-and-professional-review', 'live-launch-record-and-review'],
      'live_plus': ['live', 'live-plus', 'pro', 'pro-plus']
    }
    this.setServiceLine(types)
  }

  setLivePlusServiceLine() {
    const types = {
      'record_plus': ['auto-with-professional-review', 'unintegrated-auto'],
      'review_plus': ['auto-with-live-launch-and-professional-review', 'live-launch-record-and-review'],
      'live_plus':  ['live', 'live-plus', 'pro', 'pro-plus', '']
    }
    this.setServiceLine(types)
  }

  setAutoServiceLine() {
    const types = {
      'record': ['auto'],
      'record_plus': ['auto-with-professional-review'],
      'review_plus':  ['auto-with-live-launch-and-professional-review', 'live-launch-record-and-review']
    }
    this.setServiceLine(types)

  }

  setServiceLine(types) {
    if(this.iterationServiceTypeList) {
      const serviceType = this.iterationServiceTypeList.value;
      let serviceLine = ''

      if (types.record_plus?.includes(serviceType)) {
        serviceLine = 'record_plus';
      } else if (types.review_plus?.includes(serviceType)) {
        serviceLine = 'review_plus';
      } else if (types.live_plus?.includes(serviceType)) {
        serviceLine = 'live_plus';
      } else if (types.record?.includes(serviceType)) {
        serviceLine = 'record';
      } else {
        serviceLine = '';
      }
      this.iterationServiceLineSelector.value = serviceLine;
    }
  }
}

export default ServiceLine;
