import React from 'react';

const fullWidthRowStyle = {
  width: '100%',
};

const fullWidthCellStyle = {
  textAlign: 'center',
  width: '100%',
  padding: '10px', // Add padding for better appearance
  boxSizing: 'border-box',
};

/**
 * Reusable component to indicate `Loading` State
 * @param {string} entityBeingLoaded
 * @returns
 */
export const LoadingRow = ({ entityBeingLoaded }) => (
  <tr style={fullWidthRowStyle}>
    <td style={fullWidthCellStyle} colSpan="1000">
      Loading {entityBeingLoaded} ⏳
    </td>
  </tr>
);

/**
 * Reusable component to indicate `No results found` state
 * @param {string} entityNotFound
 * @returns
 */
export const NoResultsRow = ({ entityNotFound }) => (
  <tr style={fullWidthRowStyle}>
    <td style={fullWidthCellStyle} colSpan="1000" data-testid="no-results-row">
      No {entityNotFound} found 🧹
    </td>
  </tr>
);

export const PageNumbers = ({ currentPage, totalPages, onPageChange }) => (
  <nav aria-label="Chat room pagination" data-testid="pagination">
    <ul className="pagination">
      <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
        <button
          className="page-link"
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
          data-testid="pagination-previous-button"
        >
          Previous
        </button>
      </li>
      {[...Array(totalPages)].map((_, i) => (
        <li
          key={i}
          className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}
          data-testid="page-number-button"
        >
          <button className="page-link" onClick={() => onPageChange(i + 1)}>
            {i + 1}
          </button>
        </li>
      ))}
      <li
        className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}
      >
        <button
          className="page-link"
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          data-testid="pagination-next-button"
        >
          Next
        </button>
      </li>
    </ul>
  </nav>
);
