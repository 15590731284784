import Callbacks from '../utils/Callbacks.js';

class TurbolinksEventsHandler {
  constructor() {
    this.fireUnmountEvents = this.fireUnmountEvents.bind(this);
    this.callbacks = new Callbacks();
  }

  init() {
    this.bindEventListeners();
  }

  bindEventListeners() {
    window.addEventListener('turbolinks:before-visit', this.fireUnmountEvents);
  }

  fireUnmountEvents() {
    this.callbacks.fire({ type: 'unmountPlayer' });
    this.callbacks.fire({ type: 'unmountChat' });
    this.callbacks.fire({ type: 'unmountEventsTimeline' });
    this.callbacks.fire({ type: 'unmountExamStartNotification' });
    this.callbacks.fire({ type: 'unmountFulfillmentNotifications' });
    this.callbacks.removeAll();
    window.removeEventListener('turbolinks:before-visit', this.fireUnmountEvents);
  }
}

export default TurbolinksEventsHandler;
