/**
 * Toast - a plugin for rendering Toast notifications.
 *
 * How to use?
 * new Toast().success(options)
 *
 * Available Toasts: success, info, danger.
 *
 * Available Options:
 *
 * @param {string} className - class to add to toast element.
 * @param {array} classList - additional classes to add to toast element
 * @param {string} message - the text to be displayed in the toast.
 * @param {boolean} replaceable - only show one toast at a time if true.
 * @param {number} timeout - time in milliseconds until the Toast disappears. 0 is forever.
 * @param {boolean} dismissible - allow user to manually clear.
 * @param {function} onClick - allows us to pass an onClick to the toast to handle clicks.
 */

class Toast {

  constructor(rootNodeSelector) {
    this.$container = $('<div class="alert-toast-container"/>');
    this.$el = $('<div class="alert alert-toast animated"/>');
    this.$rootNode = $(rootNodeSelector) || $('body');
    this.animationSpeed = 200;
    this.timeout = 0;
    this.sort = 'asc';
    this.dismissible = true;
    this.replaceable = false;
    this.onClick = () => { };
    this.close = this.close.bind(this);
    this.sendToNotify = this.sendToNotify.bind(this);
    this.toasts = []
  }

  notify(options) {
    if (!$('.alert-toast-container').length) {
      this.$rootNode.append(this.$container);
    }
    // add data attribute & javascript function to container
    this.$el.click((val) => {
      const { localName } = val.target
      /**
         * If user clicks with the mouse they usually click the <span>, but occasionally
         * you can hit the <button> with the mouse. If the User TABS over to the 'X' then the localName will be button
         * stopPropagation (to make sure that the video doesn't rewind to that event) seems to break
         * the actual click of the 'X' to close the notification, so I made this workaround
         */
      if (localName == 'span' || localName == 'button') {
        options.toasts.forEach(toast => {
          toast.parentNode.removeChild(toast)
        })
      } else {
        options.onClick(options)
      }
    });
    let $toast = this.$el;

    if (options.dismissible) {
      const $dismissEl = $(
        '<button class="close ml-1" type="button" aria-label="Close" data-dismiss="alert" ><span aria-hidden="true">&times;</span></button>'
      );

      $toast.prepend($dismissEl);
    }

    $toast.addClass(options.className).append(options.message);

    if (options.classList) {
      options.classList.map(function (className) {
        $toast.addClass(className)
      });
    }

    if (options.replaceable) {
      $('.alert-toast-container').html($toast.fadeIn(this.animationSpeed));
    } else if (options.sort === 'desc') {
      $('.alert-toast-container').prepend($toast.fadeIn(this.animationSpeed));
    } else {
      $('.alert-toast-container').append($toast.fadeIn(this.animationSpeed));
    }

    if (options.timeout > 0) {
      setTimeout(() => this.close($toast), options.timeout);
    }
  }

  sendToNotify(alertType, options) {
    return this.notify({
      className: `alert-${alertType}`,
      classList: options.classList,
      message: options.message,
      replaceable: options.replaceable || this.replaceable,
      timeout: Number.isInteger(options.timeout) ? options.timeout : this.timeout,
      sort: options.sort || this.sort,
      dismissible: options.dismissible || this.dismissible,
      onClick: options.onClick || this.onClick,
      event: options.event || '',
      type: options.type || '',
      toasts: options.toasts || this.toasts
    });
  }

  success(options) {
    return this.sendToNotify('success', options);
  }

  info(options) {
    return this.sendToNotify('info', options);
  }

  danger(options) {
    return this.sendToNotify('danger', options);
  }

  warning(options) {
    return this.sendToNotify('warning', options);
  }

  close($toast) {
    $toast.fadeOut(this.animationSpeed, () => {
      $(this).remove();
    });
  }
};

export default Toast;
