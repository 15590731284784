import React, { useState, useEffect } from 'react';
import GenericSpinner from '../GenericSpinner';
import { ChatType } from './constants';

/**
 * @typedef {Object} ChatWidgetProps
 * @property {number} userId
 * @property {number} chatRoomId
 * @property {typeof ChatType} chatType
 * @property {boolean} isConnected
 * @property {boolean} isChatClosed
 * @property {object[]} messages
 * @property {number} roomSize
 * @property {boolean} isProctorConnected
 * @property {Function} onMessage
 */

/**
 * A chat application that allows proctors to communicate with test takers via chat rooms.
 *
 * @param {ChatWidgetProps} props
 * @returns  {React.ReactElement}
 */
export default function ChatWidget({
  userId,
  chatRoomId,
  chatType,
  isConnected,
  isChatClosed,
  messages,
  roomSize,
  isProctorConnected,
  onMessage,
}) {
  const [message, setMessage] = useState('');

  const handleSubmit = () => {
    if (!message) {
      return;
    }
    onMessage(message);
    setMessage('');
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleTimeString();
  };

  // Scroll window to last message when messages change
  useEffect(() => {
    const chatMessages = document.getElementById('chat-messages');
    chatMessages.scrollTop = chatMessages.scrollHeight;
  }, [messages]);

  return (
    <div className="container-fluid absolute-top p-0 w-100 h-100 scroll-none">
      <div className="card" style={{ height: '100%' }}>
        <div className="card-header">
          <h1
            className={`card-title badge ${
              isConnected === false ? 'badge-danger' : 'badge-success'
            }`}
          >
            Chat Room - {chatRoomId}
          </h1>

          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '2rem auto',
              gap: '0.25rem',
            }}
            className="card-subtitle text-muted"
          >
            <div className="text-center">
              <i className="fas fa-users" />
            </div>
            <div>
              {roomSize} {roomSize === 1 ? 'participant' : 'participants'}
            </div>
            {chatType === ChatType.TEST_TAKER && isProctorConnected && (
              <>
                <div className="text-center">
                  <i className="fas fa-user-tie" />
                </div>
                <div>
                  <span>Proctor connected 🟢</span>
                </div>
              </>
            )}
          </div>
        </div>

        {isConnected === false && (
          <div className="alert alert-danger text-center mx-4 my-1">
            <p>Please double check your internet connection.</p>
          </div>
        )}

        {isChatClosed && (
          <div className="alert alert-info text-center mx-4 my-1">
            <p>This chat room is no longer active.</p>
          </div>
        )}

        {!isChatClosed && roomSize < 2 && (
          <div className="card-body d-flex flex-column justify-content-center align-items-center align-content-center">
            <div className="d-flex flex-column justify-content-between">
              <GenericSpinner />
              <div className="text-center text-muted mt-2">
                <p className="my-0">
                  It looks like you're the only one here at the moment 👀
                </p>
                {chatType === ChatType.TEST_TAKER && (
                  <p className="my-0"> Please wait for your proctor to join.</p>
                )}
              </div>
            </div>
          </div>
        )}

        <div
          id="chat-messages"
          className="card-body d-flex flex-column"
          style={{
            height: '75%',
            width: '100%',
            overflowY: 'auto',
          }}
        >
          {messages.map((msg, index) => (
            <div
              key={index}
              className="d-flex align-items-center"
              style={{
                flexDirection:
                  msg.user_id === userId || msg.userId === userId
                    ? 'row-reverse'
                    : 'row',
              }}
            >
              <div
                className={`alert alert-${
                  msg.user_id === userId || msg.userId === userId
                    ? 'primary'
                    : 'secondary'
                }`}
                style={{
                  maxWidth: '75%',
                }}
              >
                {msg.message}
              </div>
              {msg.timestamp !== undefined && (
                <small className="text-muted mx-2">
                  {formatTimestamp(msg.timestamp)}
                </small>
              )}
            </div>
          ))}
        </div>

        <div className="card-footer">
          <div className="input-group">
            <input
              type="text"
              value={message}
              className="form-control rounded"
              placeholder="Type your message here..."
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSubmit();
                }
              }}
            />

            <button
              className="btn btn-prechecks-primary text-muted ml-2"
              onClick={handleSubmit}
            >
              <i className="fa fa-paper-plane" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
