import moment from 'moment';

const DATE_FORMAT = 'MMM D, YYYY';
const TIME_FORMAT = 'h:mmA';
const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

export default function getDateTime() {
  const formattedDate = moment($('#date-input').val()).format('MMM D, YYYY');
  const formattedTime = $('#time-input').val();
  const formattedDateTime = `${formattedDate} ${formattedTime}`;
  const language =
    $('#date-picker-container > div').data('reactProps')?.userLang || 'en';

  return moment(formattedDateTime, DATE_TIME_FORMAT, language);
}
