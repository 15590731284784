import ReactDOM from "react-dom";

import displayMediaDevicesInfo from "./displayMediaDevicesInfo";
import displayMonitorResolution from "./displayMonitorResolution";
import StatusIcons from '../../components/SystemMetrics/StatusIcons';
import CameraFeed from './CameraFeed';
import Spinner from '../../components/GenericSpinner';
import SystemDetails from '../../components/SystemMetrics/SystemDetails';
import Toast from '../utils/Toast';

class SystemMetrics {
  constructor() {
    this.showResultsCount = 0;

    this.cameraFeed = new CameraFeed();

    this.mainPageDiv = document.querySelector(
      '[data-behavior="main-page"]'
    );

    this.permissionsPromptDiv = document.querySelector(
      '[data-behavior="permissions-initial-prompt"]'
    );

    this.permissionsDenialDiv = document.querySelector(
      '[data-behavior="permissions-denial-prompt"]'
    );

    this.reloadPageBtn = document.querySelector(
      '[data-behavior="reload-page"]'
    );

    this.metricsLoadedObservable = document.querySelector(
      '[data-behavior="metrics-loaded-observable"]'
    );
    this.form = document.querySelector('[data-behavior="form"]');
    this.cameraWidthEl = document.querySelector(
      '[data-behavior="camera-width"]'
    );
    this.closeModalLink = document.querySelector("#continue-sys-metrics");
    this.helpDeskMessage = document.querySelector(
      '[data-behavior="help-desk-message"]'
    );
    this.systemDetailsDiv = document.querySelector(
      '[data-behavior="system-details-panel"]'
    );
    this.iconRowDiv = document.querySelector('[data-behavior="icon-row"]');
    this.extensionInfoDiv = document.querySelector(
      '[data-element="extension-info"]'
    );
    if (this.extensionInfoDiv) {
      this.extensionId = this.extensionInfoDiv.getAttribute("data-extension-id");
    } else {
      this.extensionId = null;
    }

    this.retestButton = document.querySelector(
      '[data-behavior="retest-button"]'
    );

    this.failureTimeout = 30000;
    this.inactivityTimeout = 900000; // 15 minutes
    this.redirectToHomePage = this.redirectToHomePage.bind(this);
    this.submitMetrics = this.submitMetrics.bind(this);
  }

  init() {
    if (!this.form) {
      return;
    }

    this.reloadPageBtn.addEventListener('click', () => location.reload(true));

    this.checkPermissions()
      .then(({ permissionsGranted }) => {
        if (permissionsGranted) {
          this.onPermissionsGranted();
        } else {
          this.promptPermissions();
        }
      })
  }

  checkPermissions() {
    const constraints = {
      video: true,
      audio: true
    };

    ReactDOM.render(
      <div className='text-center mb-3'>
        <p role='alert' aria-live='assertive' className='fs-4'>
          {polyglot.t('retrieving_results')}...
        </p>
        <Spinner />
      </div>,
      this.systemDetailsDiv
    );

    return navigator.mediaDevices.getUserMedia(constraints)
      .then(() => Promise.resolve({ permissionsGranted: true }))
      .catch(() => Promise.resolve({ permissionsGranted: false }))
  }

  promptPermissions() {
    this.permissionsDenialDiv.style.display = 'block';
    this.permissionsPromptDiv.style.display = 'none';
    this.mainPageDiv.style.display = 'none';
  }

  onPermissionsGranted() {
    this.renderEquipmentCheckWarning();

    this.permissionsPromptDiv.style.display = 'none';
    this.permissionsDenialDiv.style.display = 'none';
    this.mainPageDiv.style.display = 'block';

    this.failureTimeoutObject = setTimeout(
      this.showResults.bind(this),
      this.failureTimeout
    );
    setTimeout(this.redirectToHomePage, this.inactivityTimeout);
    displayMonitorResolution();
    this.setCloseExtensionVersionModalListener();

    this.cameraFeed.init()
      .then(() => displayMediaDevicesInfo(this.submitMetrics))
      .then(() => this.initObserver());
  }

  initObserver() {
    const self = this;
    const observer = new MutationObserver(() => self.showResults());
    const config = { attributes: true };
    observer.observe(this.metricsLoadedObservable, config);
  }

  setCloseExtensionVersionModalListener() {
    if(this.closeModalLink) {
      this.closeModalLink.addEventListener("click", (e) => {
        e.preventDefault();
        $("#update-extension-modal").modal("hide");
      });
    }
  }

  showResults() {
    if (this.showResultsCount > 4) {
      // to prevent creation of thousands of SystemMetric records
      return; }
    this.displayMetrics();
    this.checkExtensionUpToDate();
    this.showResultsCount++;
  }

  displayMetrics() {
    this.cameraFeed.gatherStats().finally(() => {
      clearTimeout(this.failureTimeoutObject);
      this.submitMetrics();
    });
  }

  submitMetrics() {
    var xhr = new XMLHttpRequest();
    let self = this;

    xhr.open("POST", this.form.getAttribute("data-url"));
    xhr.onload = (e) => {
      self.systemMetric = JSON.parse(e.target.response);
      this.onSubmitSuccess();
    };
    var formData = new FormData(this.form);
    xhr.send(formData);
  }

  onSubmitSuccess() {
    this.updateIcons();
    this.displayResults();

    if (!this.systemMetric.passed) {
      this.showRetestButton();
    }
  }

  displayResults() {
    const systemDetailsData = {
      cameraSettings: this.systemMetric.cameraSettings,
      computerSettings: this.systemMetric.computerSettings,
      internetSettings: this.systemMetric.internetSettings,
      firefoxData: this.systemMetric.firefoxData,
      helpLinks: this.systemMetric.helpLinks,
      unifyDownloadOption: this.systemMetric.unifyDownloadOption,
    };

    ReactDOM.render(
      <SystemDetails
        {...systemDetailsData}
      />,
      this.systemDetailsDiv
    );
  }

  updateIcons() {
    const iconsData = {
      cameraStatus: this.systemMetric.cameraStatus,
      micStatus: this.systemMetric.micStatus,
      computerStatus: this.systemMetric.computerStatus,
      internetStatus: this.systemMetric.internetStatus,
      cameraPath: this.systemMetric.cameraPath,
      micPath: this.systemMetric.micPath,
      computerPath: this.systemMetric.computerPath,
      internetPath: this.systemMetric.internetPath
    };

    ReactDOM.render(
      <StatusIcons {...iconsData} />,
      this.iconRowDiv
    );
  }

  renderEquipmentCheckWarning() {
    const message = polyglot.t("system_metrics_equipment_check_warning");
    new Toast().info({ message: message, replaceable: true, timeout: 0 });
  }

  renderUpToDateExtensionMessage() {
    const message = polyglot.t("system_metrics_up_to_date_extension_detected");
    new Toast().success({
      message: message,
      replaceable: true,
      timeout: 10000,
    });
  }

  checkExtensionUpToDate() {
    const browser = document.querySelector('[data-behavior="secure-browser"]').value;
    const upToDate = this.extensionInfoDiv.getAttribute("is-up-to-date");

    if (browser === "secure-browser") {
      this.toggleUpdateExtensionModal("hide")
    } else if (upToDate !== "true") {
      this.toggleUpdateExtensionModal("show");
    } else {
      this.toggleUpdateExtensionModal("hide");
      this.renderUpToDateExtensionMessage();
    }
  }

  toggleUpdateExtensionModal(modalAction) {
    const extensionLink = document.querySelector("#data-extension-link");
    const browser = document.querySelector('[data-behavior="browser"]').value;
    if (browser.toLowerCase().includes("firefox")) {
      if(extensionLink) {
        extensionLink.setAttribute("href", "https://www.proctoru.com/firefox");
      }
    } else {
      if (extensionLink) {
        extensionLink.setAttribute(
          "href",
          `https://chrome.google.com/webstore/detail/proctoru/${this.extensionId}`
        );
      }
    }
    $("#update-extension-modal").modal(modalAction);
  }

  showRetestButton() {
    this.retestButton.style.display = "block";
  }

  redirectToHomePage() {
    const homePageLinkElement = document.querySelector(
      '[data-element="home-page-link"]'
    );
    if (homePageLinkElement) {
      window.location.href = homePageLinkElement.dataset.link;
    }
  }
}

export default SystemMetrics;
