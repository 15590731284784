import { useContext, useEffect, useRef, useState } from 'react';

import { copernicus } from '../components/SecondCamera/CopernicusHelper';
import { StreamContext } from '../components/SecondCamera/contexts';

/**
 * NOTE: this only works for second camera right now, but could be expanded to work for more C.js use cases later on
 */
export default function useCjsStream(region, onExamComplete) {
  const [connected, setConnected] = useState(false);
  const [cjsInstance, setCJSInstance] = useState(
    /** @type {import('@meazure/copernicusjs').default} */ (null),
  );
  const videoRef = useRef();
  const { selectedDevice, videoStream } = useContext(StreamContext);

  const handleStartVideoStream = async () => {
    const envData = document.querySelector('span.env-data');

    if (!envData) {
      console.error('env-data not found');
      return;
    }

    const combinedStreamMaxBandwidth =
      parseInt(envData.getAttribute('combinedStreamMaxBandwidth')) * 1000;
    const secondCameraMaxBandwidth =
      parseInt(envData.getAttribute('secondCameraMaxBandwidth')) * 1000;
    const videoLayoutSettings = {
      videoLayout: envData.getAttribute('videoLayout'),
      combinedStreamMaxBandwidth,
      combinedStreamFrameRate: envData.getAttribute('combinedStreamFrameRate'),
      secondCameraMaxBandwidth,
    };
    const parts = window.location.pathname.split('/');
    const fulfillmentUuid = parts[3];

    /** @type {import('@meazure/copernicusjs').default} */
    const cjs = await copernicus.getInstance(
      fulfillmentUuid,
      videoRef.current,
      selectedDevice,
      region,
      videoLayoutSettings.videoLayout,
    );
    setCJSInstance(cjs);

    const iscjsInitialized = await cjs.initialize();
    if (iscjsInitialized) {
      await cjs.startLiveStream(videoLayoutSettings);
      setConnected(true);
    } else {
      console.warn('CJS failed to initialize');
    }
  };

  const handleEndVideoStream = () => {
    void cjsInstance?.close();

    if (videoStream) {
      const tracks = videoStream.getTracks();
      for (let track of tracks) {
        track.stop();
      }
    }
  };

  useEffect(() => {
    const disconnectHandler = () => setConnected(false);
    const completeHandler = async () => {
      if (document.fullscreenElement) {
        await document.exitFullscreen();
      }
      handleEndVideoStream();
      onExamComplete();
    };

    window.addEventListener('examDisconnect', disconnectHandler);
    window.addEventListener('examComplete', completeHandler);

    return () => {
      window.removeEventListener('examDisconnect', disconnectHandler);
      window.removeEventListener('examComplete', completeHandler);
    };
  }, []);

  useEffect(() => {
    videoRef.current.srcObject = videoStream;
    if (selectedDevice) {
      void handleStartVideoStream();
    }
    return handleEndVideoStream;
  }, [selectedDevice, videoStream]);

  return { connected, videoRef };
}
