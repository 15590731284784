import PropTypes from 'prop-types';
import DeleteIncident from '../DeleteIncident';
import Button from '../Button';

class CommentEvent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      commentEditMode: false,
      value: props.text,
      defaultComment: props.text,
      commentError: '',
      formGroupClass: ''
    }
  }

  handleChange = ({ target }) => {
    this.setState({ value: target.value })
  }

  resetCommentsToDefault = () => {
    const comment = this.state.defaultComment
    this.setState({
      value: comment,
      commentEditMode: false,
      commentError: '',
      formGroupClass: ''
    })
  }

  switchToEditMode = () => {
    this.setState({ commentEditMode: true })
  }

  confirm = (event) => {
    this.saveAndExit()
    event.preventDefault()
  }

  saveAndExit = () => {
    let comment = this.state.value
    const { uiMessageId, transformStore } = this.props

    if (comment.length === 0) {
      this.setState({
        commentError: "Comment can't be blank",
        formGroupClass: 'is-invalid'
      })
    } else {
      this.setState({
        defaultComment: comment,
        commentEditMode: false,
        commentError: '',
        formGroupClass: ''
      })
      transformStore({
        type: 'updateIncidentComment',
        params: {
          incident_id: uiMessageId,
          comment
        }
      })
    }
  }

  renderFooter = () => {
    const {
      createdAt,
      uiMessageId,
      transformStore,
      isDeleteCandidate,
      institutionUser
    } = this.props

    return (
      <>
        <Button
          className="mr-1"
          icon={"fa-pencil-alt edit-scores-icon"}
          callback={this.switchToEditMode}
        />
        {!institutionUser && (
        <DeleteIncident 
          uiMessageId={uiMessageId}
          transformStore={transformStore}
          isDeleteCandidate={isDeleteCandidate}
        />)}
      </>
    )
  }

  render() {
    const { showInPlayer, heading, createdAt } = this.props
    const { value, commentEditMode } = this.state

    var formGroupClass = this.state.formGroupClass

    return (
      <div>
        {commentEditMode ? (
          <form onSubmit={confirm}>
            <div className={formGroupClass}>
              <textarea
                autoFocus
                type='text'
                value={value}
                onChange={this.handleChange}
                className='edit-comment form-control'
              />
              <br />
              <span className='text-danger form-text'>
                {this.state.commentError}
              </span>
              <span className='scores-edit-container form-group float-right'>
                <Button
                  icon={'fa-check fa-border'}
                  type={'submit'}
                  callback={this.confirm}
                />
                <Button
                  icon={'fa-ban fa-border'}
                  callback={this.resetCommentsToDefault}
                />
              </span>
            </div>
          </form>
        ) : (
          <>
            <div className="row align-items-center">
              <div className="col-11 offset-1">
                <small className="text-muted">{createdAt}</small>
                <div>
                  {heading}
                </div>
                <div className="mt-2">
                  <div className="row">
                    <div className="col-10">
                      <span>{value}</span>
                    </div>
                    <div className="col-2 text-right">
                      { this.renderFooter() }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        </div>
    )
  }
}

CommentEvent.propTypes = {
  createdAt: PropTypes.string.isRequired,
  showInPlayer: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  isDeleteCandidate: PropTypes.bool.isRequired
}

export default CommentEvent
