class SideNavSubmit {
  constructor() {
    this.sideNavSubmitButton = document.querySelector('.js-update-iteration')
  }

  init() {
    const bottomSubmitButton = document.querySelector('.js-save-iteration')
    this.sideNavSubmitButton.textContent = bottomSubmitButton.value;
    this.sideNavSubmitButton.addEventListener('click', () => {
      this.sideNavSubmitButton.setAttribute('disabled', 'disabled')
      bottomSubmitButton.click()
    })
  }
}

export default SideNavSubmit;
