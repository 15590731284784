class ExamDropdown {
  constructor() {
    this.dropdown = document.querySelector('#exam-select-dropdown');
  }

  init() {
    this.dropdown.getElementsByClassName('btn')[0].addEventListener('click', (event) => { this.handleClick(event) })
  }

  handleClick(event) {
    event.preventDefault();
    this.dropdown.classList.toggle('show')
    this.dropdown.getElementsByClassName('dropdown-menu')[0].classList.toggle('show')
    event.stopPropagation();
  }
}

export default ExamDropdown;
