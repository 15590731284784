// getParam
//
// gets the value of a given parameter,
// or an empty string if the parameter is not found
export default function getParam(param) {
  const urlParams = window.location.href.replace('?', '&').split('&');
  const paramPair = urlParams.filter(p => {
    return p.includes(param);
  })[0];
  if (paramPair == null) {
    return '';
  }
  return paramPair.split('=')[1];
}
