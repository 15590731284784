class Select2Reviewer extends React.Component {
  constructor(props) {
    super(props);
    this.$selector = '#select2-reviewer';
  }

  getReviewerValue() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('reviewQueueReviewer') ?? 0;
  }

  componentDidMount() {
    $(this.$selector).select2({
      placeholder: 'Find by Name',
      theme: 'bootstrap4',
      minimumInputLength: 3,
      ajax: {
        url: '/internal/dashboards/reviews/entries/assignees',
        dataType: 'json',
        delay: 500,
        data: function (reviewer) {
          return { reviewer: reviewer.term };
        },
        processResults: function (data) {
          return { results: data.assignees };
        },
      },
      allowClear: true,
    });
    this.setInitialSelectionForSelect2();
  }

  setInitialSelectionForSelect2() {
    if (this.getReviewerValue().length < 1 || this.getReviewerValue() == 0) {
      return;
    }
    $.ajax({
      url: '/internal/dashboards/reviews/entries/assignees',
      data: { id: this.getReviewerValue() },
      dataType: 'json',
    }).then((data) => {
      for (var d = 0; d < data.assignees.length; d++) {
        var reviewer = data.assignees[d];
        var option = new Option(reviewer.text, reviewer.id, true, true);
        $(this.$selector).append(option);
      }
      $(this.$selector).val(this.getReviewerValue()).trigger('change');
    });
  }

  render() {
    return (
      <div>
        <label htmlFor="reviewQueueReviewer">Reviewer</label>
        <select
          name="reviewQueueReviewer"
          id="select2-reviewer"
          placeholder="Find by Name"
          className="form-control"
        ></select>
      </div>
    );
  }
}

export default Select2Reviewer;
