class AddressRequirementCheck {
  constructor() {
    this.userStateLabel = document.querySelector(
      '[data-behavior="user-state-label"]'
    );
    this.userStreet1Label = document.querySelector(
      '[data-behavior="user-street1-label"]'
    );
    this.userCityLabel = document.querySelector(
      '[data-behavior="user-city-label"]'
    );
    this.userZipcodeLabel = document.querySelector(
      '[data-behavior="user-zipcode-label"]'
    );
    this.userCountrySelect = document.querySelector(
      '[data-behavior="user-country-select"]'
    );
    this.userStateSelect = document.querySelector(
      '[data-behavior="user-state-select"]'
    );
    this.userStateInput = document.querySelector(
      '[data-behavior="user-state-input"]'
    );
  }

  init() {
    this.handleOnload();
    this.bindEventListeners();
  }

  handleOnload() {
    this.handleChange();
  }

  bindEventListeners() {
    var self = this;
    this.userCountrySelect.onchange = function() {
      self.handleChange();
    };
  }

  handleChange() {
    const value = this.userCountrySelect.value;

    if (value === 'United States') {
      this.addRequirementsToLabels();
      this.enableStateSelect();
    } else {
      this.removeRequirementsFromLabels();
      this.disableStateSelect();
    }
  }

  addRequirementsToLabels() {
    this.userStateLabel.classList.add('required');
  }

  enableStateSelect() {
    this.userStateSelect.removeAttribute('disabled');
    this.userStateSelect.classList.remove('d-none');
    this.userStateInput.classList.add('d-none');
    this.userStateInput.setAttribute('disabled', true);
    this.userStateLabel.setAttribute('for', 'state-select');
  }

  removeRequirementsFromLabels() {
    this.userStateLabel.classList.remove('required');
  }

  disableStateSelect() {
    this.userStateSelect.setAttribute('disabled', true);
    this.userStateSelect.classList.add('d-none');
    this.userStateInput.classList.remove('d-none');
    this.userStateInput.removeAttribute('disabled');
    this.userStateLabel.setAttribute('for', 'user_state');
  }
}

export default AddressRequirementCheck;
