import PrechecksHeaderIcon from './CardSections/PrechecksHeaderIcon';
import PrechecksBodyTitle from './CardSections/PrechecksBodyTitle';
import PrechecksBodyText from './CardSections/PrechecksBodyText';
import PrechecksFooterButton from './CardSections/PrechecksFooterButton';

const BaseCardContent = ({
  id,
  icon,
  iconColor,
  iconPrefix,
  assetIcon,
  title,
  bodyText,
  buttonText,
  handleClick,
}) => {
  return (
    <div className="container-fluid text-center precheck-card-content" id={id}>
      <PrechecksHeaderIcon
        icon={icon}
        iconColor={iconColor}
        iconPrefix={iconPrefix}
        assetIcon={assetIcon}
      />
      <PrechecksBodyTitle title={title} />
      <PrechecksBodyText bodyText={bodyText} />
      {buttonText.length > 0 && (
        <PrechecksFooterButton
          buttonText={buttonText}
          handleClick={handleClick}
        />
      )}
    </div>
  );
};

BaseCardContent.defaultProps = {
  title: 'Generic Card Content',
  body: 'This can be a baseline for simple precheck cards',
  icon: 'fa-check',
  iconPrefix: 'fal',
  buttonText: 'Next',
  iconColor: 'primary',
};

export default BaseCardContent;
