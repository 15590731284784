var mobx = require('mobx');

/**
 * Video player data.
 * Changes invokes rendering of video player.
 */
class VideoPlayerStore {

  constructor() {
    const videoData = {
      live: false,
      showStudentStatus: false,
      cameraStatus: 'disconnected',
      screenStatus: 'disconnected',
      play: true,
      fetching: false,
      speedValue: 1,
      durationValue: 0,
      durationTime: 0,
      chunksDuration: 0,
      incidents: null,
      refinedExamCompleted: null,
      videoPace: null,
      camVideo: {
        chunks: null,
        currentChunk: 0,
        currentPlayer: 1,
        error: null
      },
      screenVideo: {
        chunks: null,
        currentChunk: 0,
        currentPlayer: 1,
        error: null
      },
      videoDiscrepancy: 0,
      liveDurationTime: 0,
      lastCamVideoCurrentTime: 0,
      lastCamVideoChunk: 0,
      syncTime: null,
      durationTime: null,
      isTimerStopped: false
    };

    this.videoData = mobx.observable(videoData);
  }

  /**
   * Update video player data.
   * @param {object} data - updated data.
   */
  setData(data) {
    Object.keys(data).forEach(param => {
      this.videoData[param] = data[param];
    })
  }
}

export default VideoPlayerStore;
