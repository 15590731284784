import csrfToken from "../../src/utils/csrf";

class RoleSelect {
  constructor() {
    this.roleSelect = document.querySelector('#user_role_id')
  }

  init() {
    this.roleSelect.addEventListener('change', () => { this.handleChange() })
    const event = new Event('change');
    this.roleSelect.dispatchEvent(event)
  }

  async handleChange() {
    const selectedValue = this.roleSelect.options[this.roleSelect.selectedIndex]
    const role = selectedValue.getAttribute('data-role-type');
    const enrollments = document.querySelector('#enrollments');
    const override = document.querySelector('#override-cost-split');
    const faculty_users = document.querySelector('#faculty_users');
    const student_role = document.querySelector('#student-role');
    const institutionField = document.querySelector('#institution-fields');

    // Get userId from data attribute
    const userId = faculty_users ? faculty_users.dataset.userId : null;

    if (role === 'Role::Student') {
      if (override) { override.classList.remove('d-none') }
      if(enrollments) { enrollments.classList.remove('d-none'); }
    } else {
      if (override) { override.classList.add('d-none') }
      if(enrollments) { enrollments.classList.add('d-none') }
    }
    if (selectedValue.text === 'Institution instructor') {
      // load faculty users asynchronously
      if (!faculty_users?.dataset?.loaded) {
        try {
          await this.loadFacultyUsers(faculty_users, userId);
        } catch (error) {
          console.error('Error loading faculty users:', error);
        }
      }
      if (faculty_users) { faculty_users.classList.remove('d-none'); }
      if (student_role) { student_role.classList.remove('d-none'); }
    } else {
      if (faculty_users) { faculty_users.classList.add('d-none'); }
      if (student_role) { student_role.classList.add('d-none'); }
    }
    if (role === 'Role::Institution') {
      institutionField.classList.remove('d-none');
    } else {
      institutionField.classList.add('d-none');
    }
  }

  async loadFacultyUsers(container, userId) {
    try {
      const response = await fetch(`/users/${userId}/load_faculty_users`, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': csrfToken()
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const html = await response.text();
      container.innerHTML = html;
      container.dataset.loaded = true;
    } catch (error) {
      console.error('Error loading faculty users:', error);
      throw error;
    }
  }
}

export default RoleSelect;
