import axios from 'axios';
import csrfToken from '../utils/csrf';

class BillingAddressButton {
  constructor() {
    this.button = document.querySelector('.js-fill-billing-address')
  }

  init() {
    this.button?.addEventListener('click', (event) => {this.handleClick(event)})
  }

  handleClick(event) {
    event.preventDefault();
    const headers = {
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
        }
      }
    axios.get(this.button.getAttribute('data-url'), headers)
    .then(response => {
      for (const key in response.data) {
        document.querySelector(`#order_${key}`).value = response.data[key];
      }
    });
  }
}

export default BillingAddressButton;
