import PresetsBrowserTabs from './PresetsBrowserTabs'
import PresetsCopyPaste from './PresetsCopyPaste'
import PresetsLostFocus from './PresetsLostFocus'
import PresetsWindowSize from './PresetsWindowSize'
import PresetsRadios from './PresetsRadios';

class PresetsConfiguration {
  constructor() {
    this.iterationTypeSelector =
      document.querySelector('[data-element="iteration-types"]') ||
      document.querySelector('[data-element="iteration-type-hidden"]');
    this.iterationPresetCustom = $('#iteration_preset_custom');
    this.iterationDepartment = document.querySelector('[data-element="iteration-department"]');
    this.presetsConfigurationBlock = document.querySelector('[data-element="preset-configuration"]');
  }

  init() {
    new PresetsRadios().init();
    new PresetsBrowserTabs().init();
    new PresetsCopyPaste().init();
    new PresetsLostFocus().init();
    new PresetsWindowSize().init();
    if (this.iterationTypeSelector) {
      this.handlePresetsBlock();
      this.iterationTypeSelector.addEventListener('change', () => { this.handlePresetsBlock() });
    }
    if (this.iterationDepartment) {
      this.iterationDepartment.addEventListener('change', () => { this.handleDepartmentChange() }
      )
    }
    if (this.presetsConfigurationBlock) {
      this.presetsConfigurationBlock.addEventListener(
        'change',
        () => { this.onConfigurationBlockChange() }
      );
    }
  }

  handlePresetsBlock() {
    const selectedType = this.iterationTypeSelector.value;
    const annualFee = document.querySelector('[data-element="annual-fee"]');
    const presetsConfiguration = document.querySelector('.js-presets-configuration')
    if (selectedType == 'Iteration::Classic') {
      if (presetsConfiguration) { presetsConfiguration.classList.add('d-none'); }
      $('.js-resource-switch').css('pointer-events', 'auto');
      if (annualFee) { annualFee.classList.add('d-none'); }
    } else {
      if (presetsConfiguration) { presetsConfiguration.classList.remove('d-none'); }
      if (annualFee) {
        annualFee.classList.remove('d-none');
      }
    }
  }

  handleDepartmentChange() {
    const select = this.iterationDepartment;
    const selectedDepartment = select.options[select.selectedIndex];
    const departmentPreset = selectedDepartment.getAttribute('data-security-preset')
    const selectedPreset =  document.getElementById(`iteration_preset_${departmentPreset}`)
    this.setSecuritySettings(selectedPreset)
  }

  setSecuritySettings(preset) {
    new PresetsRadios().onPresetInputChange(preset)
  }

  onConfigurationBlockChange() {
    this.iterationPresetCustom.trigger('click');
  }
}

export default PresetsConfiguration;
