import axios from 'axios';
import {useState} from 'react';
import {mapKeys, snakeCase} from "lodash";
import csrfToken from "../../src/utils/csrf";
import Toast from '../../src/utils/Toast';
import EventAlertConfigurationForm from "./EventAlertConfigurationForm";
import EventAlertConfigurationTable from "./EventAlertConfigurationTable";

const EventAlertConfigurationsApp = (props) => {

	const [configId, setConfigId] = useState('');
	const [selectedEventType, setSelectedEventType] = useState(props.eventTypes[0]);
	const [eventComment, setEventComment] = useState('');
	const [eventLocation, setEventLocation] = useState('warnings');
	const [eventAlertText, setEventAlertText] = useState('');
	const [eventAlertConfigs, setEventAlertConfigs] = useState(props.configurations);
	const [showForm, setShowForm] = useState(false);
	const [disableDropdowns, setDisableDropdowns] = useState(false);
	const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);

	const updateSelectedEventType = e => {
		setSelectedEventType(e.target.value);
	};

	const updateEventComment = e => {
		setEventComment(e.target.value);
	};

	const updateEventLocation = e => {
		setEventLocation(e.target.value);
	};

	const updateEventAlertText= e => {
		setEventAlertText(e.target.value);
	};

	const clearForm = () => {
		setConfigId('');
		setSelectedEventType(props.eventTypes[0]);
		setEventComment('');
		setEventLocation('warnings');
		setEventAlertText('');
		setShowForm(false);
		setDisableDropdowns(false);
		setDisableSubmitBtn(false);
	}

	const showHideForm = (event) => {
		const showHide = event.target.dataset.showhide;
		if (showHide && showHide == 'hide') {
			setShowForm(false)
		} else {
			setShowForm(true)
		}
	}

	const submitForm = (e) => {
		e.preventDefault();
		setDisableSubmitBtn(true);
		const valid_form = validateParams();
		if (valid_form) {
			if (configId && configId != '') {
				editConfig(e)
			} else {
				addNewConfig(e)
			}
		} else {
			new Toast().warning({message: "All fields are required!"})
			setDisableSubmitBtn(false);
		}
	}

	const editForm = (e) => {
		const config_index = e.target.dataset.configIndex;
		const chosen_config = eventAlertConfigs[config_index];
		setConfigId(chosen_config.id);
		setSelectedEventType(chosen_config.event_type);
		setEventComment(chosen_config.comment);
		setEventLocation(chosen_config.location);
		setEventAlertText(chosen_config.alert_text);
		setShowForm(true);
		setDisableDropdowns(true);
	}

	const addNewConfig = () => {
		const configuration = {
			event_type: selectedEventType,
			comment: eventComment,
			location: eventLocation,
			alert_text: eventAlertText
		};
		axios.post(
			`${window.location.origin}/api/event_alert_configurations`,
			mapKeys(configuration, (value, key) => snakeCase(key)),
			{
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'X-CSRF-Token': csrfToken()
			}
		)
		.then( response => {
			if(response.data.error && response.data.error.length > 0) {
				new Toast().danger({message: response.data.error});
				setDisableSubmitBtn(false);
			} else {
				new Toast().success({message: "Event Alert Config successfully created."})
				setEventAlertConfigs(response.data.configurations);
				clearForm();
			}
		})
		.catch( error => {
			console.log(error);
			setDisableSubmitBtn(false);
		});
	}

	const editConfig = (e) => {
		const configuration = {
			id: configId,
			event_type: selectedEventType,
			comment: eventComment,
			location: eventLocation,
			alert_text: eventAlertText
		};
		axios.put(
			`${window.location.origin}/api/event_alert_configurations`,
			mapKeys(configuration, (value, key) => snakeCase(key)),
			{
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'X-CSRF-Token': csrfToken()
			}
		)
			.then( response => {
				console.log(response);
				new Toast().success({message: "Event Alert Config successfully edited."})
				setEventAlertConfigs(response.data.configurations);
				clearForm();
			})
			.catch( error => {
				console.log(error);
				setDisableSubmitBtn(false);
			});
	}

	const deleteConfig = (e) => {
		axios.delete(
			`${window.location.origin}/api/event_alert_configurations`,
			{data: {id: e.target.dataset.configId}},
			{
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'X-CSRF-Token': csrfToken()
			}
		)
			.then( response => {
				console.log(response);
				new Toast().success({message: "Event Alert Config successfully deleted."})
				setEventAlertConfigs(response.data.configurations);
			})
			.catch( error => {
				console.log(error);
			});
	}

	const validateParams = () => {
		return selectedEventType && selectedEventType.length > 0
			&& eventComment && eventComment.length > 0
			&& eventLocation && eventLocation.length > 0
			&& eventAlertText && eventAlertText.length > 0;
	}

	return(
		<div className={"col-md-12"}>
			<div className="row mb-4">
				<div className="col-md-12">
					<h1 className="fs-3">
						<span className="float-right mt-2" style={{display: showForm ? 'none' : 'block'}}>
							<a className="btn btn-primary" href="javascript:void(0);" onClick={showHideForm} data-showhide={'hide_form'}>Add New</a>
						</span>
					</h1>
				</div>
			</div>
			<div className={"col-md-12"}>
				<EventAlertConfigurationForm
					configId={configId}
					selectedEventType={selectedEventType}
					updateSelectedEventType={updateSelectedEventType}
					eventLocation={eventLocation}
					updateEventLocation={updateEventLocation}
					eventComment={eventComment}
					updateEventComment={updateEventComment}
					eventAlertText={eventAlertText}
					updateEventAlertText={updateEventAlertText}
					eventTypes={props.eventTypes}
					eventLocations={props.eventLocations}
					showForm={showForm}
					disableDropdowns={disableDropdowns}
					disableSubmitBtn={disableSubmitBtn}
					setShowForm={setShowForm}
					clearForm={clearForm}
					submitForm={submitForm}
				/>
				<EventAlertConfigurationTable
					eventAlertConfigs={eventAlertConfigs}
					submitForm={submitForm}
					editForm={editForm}
					deleteConfig={deleteConfig}
				/>
			</div>
		</div>
	)
}

EventAlertConfigurationsApp.defaultProps = {
	eventTypes: [],
	configurations: [],
	eventLocations: []
}

export default EventAlertConfigurationsApp;
