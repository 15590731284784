class Select2ProctorUContanct {
  constructor() {
    this.proctoruContactable = $('#proctoru-contactable');
  }

  init() {
    this.proctoruContactable.select2({
      placeholder: 'ProctorU Contacts',
      theme: 'bootstrap4',
      tags: true,
      tokenSeparators: [',', ' '],
      allowClear: true,
      ajax: {
        url: '/incident-users.json',
        dataType: 'json',
        data: function() {
          return {
            search: {
              contactable: true,
              incident_report_id: parseInt($('.js-incident-id').html()),
              contactable_type: 'proctoru_contacts'
            }
          };
        },
        processResults: function(data) {
          return {
            results: data
          };
        }
      }
    });
  }
}

export default Select2ProctorUContanct;
