import handleIterationChange from './utils/handleIterationChange';

class PresetsWindowSize {
  constructor() {
    this.windowSizeAllowed = document.querySelector('[data-element="window_size_allowed"]');
  }

  init() {
    if (this.windowSizeAllowed) {
      this.windowSizeAllowed.addEventListener('change', (event) => { handleIterationChange(event) })
    }
  }
}

export default PresetsWindowSize;
