import Toast from '../utils/Toast';
// Enrollment Fields Notifier
//
// Handles the situation where a user has not completed their account
// after an Institution adds a new Additional Enrollment Field.
class EnrollmentFieldsNotifier {
  constructor() {
    this.fetchAccountCompletion(() => {
      // Check if the user's account and enrollments are valid.
      // If they are both valid, bail out of this method.
      // We don't need to add an <alert> to the page.
      const userData = this.userData;
      const hasCompleteEnrollment = userData.has_completed_enrollment_fields;
      const hasCompleteAccount = userData.has_completed_account;
      if (hasCompleteEnrollment && hasCompleteAccount) {
        return;
      }

      this.renderAlert();
    });
  }

  fetchAccountCompletion(callback) {
    return $.getJSON('/students/accounts', (user) => {
      this.userData = user;
    }).then(callback);
  }

  renderAlert() {
    new Toast().danger({message: polyglot.t('update_account_settings')})
  }
}

export default EnrollmentFieldsNotifier;
