/**
 * A register for callbacks
 */
class Callbacks {

  constructor() {
    window.callbacks = window.callbacks || [];
  }
  /**
   * Add a callback
   * @param callback a callback to be registered
   */
  addCallback(callback) {
    window.callbacks.push(callback);
  }

  on(callback) {
    window.callbacks.push(callback);
  }

  /**
   * Remove a callback
   * @param callback a callback to be removed
   */
  removeCallback(callback) {
    _.reject(window.callbacks, function(element) {return element == callback});
  }

  off(callback) {
    _.reject(window.callbacks, function(element) {return element == callback});
  }

  /**
   * Remove all callbacks
   */
  removeAll() {
    window.callbacks = [];
  }

  /**
   * Fire an event for all registered callbacks
   */
  fire() {
    var args = Array.prototype.slice.call(arguments);
    _.each(window.callbacks, function(element) {
      return element.apply(element, args);
    });
  }
};

/** The global callbacks */
export default Callbacks;
