export const LMI_STATUS = {
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
};

/**
 * Opens a new window with the LMI chat widget.
 */
export const openChatWindow = () => {
  const width = 400;
  const height = 600;
  const left = window.screen.width - width;
  const top = window.screen.height - height;

  const chatWindow = window.open(
    '',
    'LMI Chat Test',
    `width=${width},height=${height},left=${left},top=${top},scrollbars=yes`,
  );

  window.chatWindow = chatWindow.id;

  chatWindow.document.write(
    '<div id="ICContainer" style="height:470px;width:356px"></div>',
  );
  chatWindow.document.close();

  loadInstantChatScript(chatWindow);
};

/**
 * Actually loads the LMI chat widget script.
 *
 * @param {Window} w
 */
export const loadInstantChatScript = (w) => {
  const logToParentWindow = function (message) {
    w.top.opener.postMessage({ message: message, lmi_chat: true }, '*');
  };
  const lmiHostedCssRoute =
    'https://staging.proctoru.com/public/stylesheets/lmi_chat.css';

  const script = w.document.createElement('script');
  script.id = 'lmiChatScript';
  script.src = 'https://secure.logmeinrescue.com/InstantChat/InstantChat.aspx';
  script.async = true;

  script.onload = function () {
    const message = 'LMI Instant Chat Script loaded successfully.';
    w.console.log(message);
    logToParentWindow(message);

    // RescueInstantChatLoader does not exist in our codebase. It comes from the above script.
    // eslint-disable-next-line no-undef, @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line no-undef
    const ICLoader = new w.RescueInstantChatLoader();
    ICLoader.ICContainer = 'ICContainer';
    ICLoader.HostedCSS = lmiHostedCssRoute;
    ICLoader.HostedLanguagesForChatOnlyMode =
      'https://secure.logmeinrescue.com/InstantChat/LanguagesForChatOnlyMode.js';
    ICLoader.HostedLanguagesForAppletMode =
      'https://secure.logmeinrescue.com/InstantChat/LanguagesForAppletMode.js';
    ICLoader.EntryID = '5409256';
    ICLoader.webSessionIdLength = 24;
    ICLoader.ChannelName = 'Channel 01';
    ICLoader.Name = 'Test';
    ICLoader.Comment1 = `Anonymously testing the LMI chat widget. User Agent: ${navigator.userAgent}`;
    ICLoader.Comment2 = 'false';
    ICLoader.Comment3 = '';

    ICLoader.HostedErrorHandler = function (ErrorName) {
      let msg;
      switch (ErrorName) {
        case 'NOTECHAVAILABLE':
          msg =
            'Currently no technicians are available. Please check back later.';
          break;
        case 'NOTECHWORKING':
          msg =
            "Sorry, we're closed. No technicians are available at this time. Please check back later during our hours of operation.";
          break;
        case 'INVALID_PARAMETERS':
          msg =
            'Invalid parameters supplied. Please contact your support provider.';
          break;
        case 'SESSIONALREADYSTARTED':
          msg =
            'A session using this PIN Code has already been started. Please ask your support provider for a new PIN Code.';
          break;
        case 'UCONNECTIONERROR':
          msg = 'Unknown connection error occurred.';
          break;
        case 'ERRNOSUCHSSESSION':
          msg = 'The support session cannot be started.';
          break;
        case 'ERRNOSUCHENTRY':
          msg =
            'The online support session cannot be started. Please contact your support provider directly.';
          break;
        case 'ERRCODEDOESNOTEXIST':
          msg =
            'PIN Code does not exist. Please contact your support provider.';
          break;
        case 'ERRCODEEXPIRED':
          msg = 'PIN Code has expired. Please contact your support provider.';
          break;
        case 'ERRNOTEXPIRED':
          msg =
            'Technician or company does not exist. Please contact your support provider.';
          break;
        case 'ERRMISSINGTECHLICENSE':
          msg =
            'The support session cannot be started. The technician is not configured to support this type of device.';
          break;
        default:
          msg = 'An unknown error occurred.';
      }
      if (msg) {
        logToParentWindow(msg);
      }
    };

    ICLoader.Start();
  };

  script.onerror = function () {
    const message = 'Failed to load the LMI Instant Chat script.';
    w.console.error(message);
    logToParent(message);
  };

  w.document.body.appendChild(script);
};
