const EventAlertConfigurationForm = (props) => {
	return (
		<div className="card-body">
			<div>
				<form className="new_event_alert_configuration" id="new_event_alert_configuration" role="form"
				      action="" acceptCharset="UTF-8" style={{display: props.showForm ? 'block' : 'none'}}>
					<input name={'configId'} id={'configId'} type={'hidden'} value={props.configId} />
					<div className="form-group row ">
						<label className="col-sm-3 required" htmlFor="selectedEventType">Event Type</label>
						<div className="col-form-field col-sm-9">
							<select className="form-control" name="selectedEventType" id="selectedEventType" value={props.selectedEventType}
								onChange={props.updateSelectedEventType} disabled={props.disableDropdowns}>
								{
									props.eventTypes.map((eventType, event_index) => {
										return (
											<option
												key={`event_type_${event_index}`}
												value={`${eventType}`}
											>
												{`${eventType}`}
											</option>
										)
									})
								}
							</select>
						</div>
					</div>
					<div className="form-group row ">
						<label className="col-sm-3 required" htmlFor="eventComment">Event Comment</label>
						<div className="col-form-field col-sm-9">
							<textarea
								className="form-control"
								name="eventComment"
								id="eventComment"
								value={props.eventComment}
								onChange={props.updateEventComment}/>
						</div>
					</div>
					<div className="form-group row ">
						<label className="col-sm-3 required" htmlFor="eventLocation">Location</label>
						<div className="col-form-field col-sm-9">
							<select className="form-control" name="eventLocation" id="eventLocation" value={props.eventLocation}
								onChange={props.updateEventLocation} disabled={props.disableDropdowns}>
								{
									props.eventLocations.map((location, location_index) => {
										return (
											<option
												key={`event_location_${location_index}`}
												value={`${location.code}`}
											>
												{`${location.location}`}
											</option>
										)
									})
								}
							</select>
						</div>
					</div>
					<div className="form-group row ">
						<label className="col-sm-3 required" htmlFor="eventAlertText">Alert Text</label>
						<div className="col-form-field col-sm-9">
							<textarea
								className="form-control"
								name="eventAlertText"
								id="eventAlertText"
								value={props.eventAlertText}
								onChange={props.updateEventAlertText} />
						</div>
					</div>
					<div className="form-group text-right">
						<div className="d-inline-block mr-2">
							<input type="button"
							       name="cancel_form"
							       value="Cancel"
							       className="btn btn-danger"
							       onClick={props.clearForm}
							/>
						</div>
						<div className="d-inline-block">
							<input type="submit"
							       name="submit"
							       disabled={props.disableSubmitBtn}
							       value={props.configId === '' ? 'Add Configuration' : 'Update Configuration'}
							       className="btn btn-primary"
							       onClick={props.submitForm}
							/>
						</div>
					</div>
				</form>
			</div>
		</div>)
}

EventAlertConfigurationForm.defaultProps = {
	configId: '',
	selectedEventType: '',
	eventLocation: 'warnings',
	eventComment: '',
	eventAlertText: '',
	eventTypes: [],
	showForm: false,
	disableDropdowns: false,
	disableSubmitBtn: false
};

export default EventAlertConfigurationForm;
