import PropTypes from 'prop-types';
import isInstitution from '../../../src/incident_report_center/utils/isInstitution'

const baseClass = 'mr-1 icon-set-single-'
const urgencyLevelClassMap = {
  'green': `${baseClass}success`,
  'yellow': `${baseClass}warning`,
  'red': `${baseClass}danger`
}

class IncidentReportRow extends React.Component {
  constructor(props){
    super(props);
  }

  urgencyIconMarkup() {
    // filter out non-unique priorities
    var iconPriorities =
      this.props.incident.urgency_levels.filter(
        (value, index, self) => self.indexOf(value) === index
      );

    var icons = iconPriorities.map((priority) => {
      return (
        <span key={priority} className={"icon-set-single " + urgencyLevelClassMap[priority]}></span>
      );
    })

    return (
      <div className="icon-set">
        {icons}
      </div>
    );
  }

  buttonText() {
    var status = this.props.incident.demodulized_status;

    if (this.isInstitutionUser()) {
      return 'View'
    };

    if (status == 'New' || status == 'In Progress') {
      return 'Process';
    } else {
      return 'Review';
    }
  }

  isInstitutionUser() {
    return this.props.id > 0;
  }

  navigateToLink(event) {
    event.preventDefault();

    Turbolinks.visit(this.props.incident.link);
  }

  labelClass() {
    return ['badge', this.props.incident.label_class].join(' ');
  }

  renderInstitution() {
    if (!isInstitution()) {
      var incident = this.props.incident;

      return (
        <td>
          <a href={incident.fulfillment.institution.link}>
            {incident.fulfillment.institution.name}
          </a>
        </td>
      );
    }
  }

  renderPreparer() {
    if (!isInstitution()) {
      var incident = this.props.incident;

      return (
        <td>{incident.preparer}</td>
      );
    }
  }

  renderButton() {
    return (
      <td className='text-right'>
        <a href={this.props.incident.link}
          className='btn btn-secondary btn-sm'>{this.buttonText()}</a>
      </td>
    );
  }

  renderStatus() {
    if (!isInstitution()) {
      var incident = this.props.incident;

      return (
        <td className="text-truncate">
          <span className={this.labelClass()}>{incident.demodulized_status}</span>
        </td>
      );
    }
  }

  renderIncidentSubtype() {
    var incidentSubtypes = this.props.incident.incident_subtypes;

    return (
      <td>{incidentSubtypes.map((incident_subtype, index) =>
        <p key={index}>
          {incident_subtype.status}
        </p>
      )}</td>
    );
  }

  render() {
    var incident = this.props.incident;
    var institution = this.renderInstitution();
    var preparer = this.renderPreparer();
    var status = this.renderStatus();
    var incidentSubtype = this.renderIncidentSubtype();
    var button = this.renderButton();

    return (
      <tr>
        <td>{incident.created_at_formatted}</td>
        <td>
          <a href={incident.fulfillment.user.link}>
            {incident.fulfillment.user.name}
          </a>
        </td>
        <td>
          <a href={incident.fulfillment.link}>
            {incident.fulfillment.exam.courseName}
          </a>
        </td>
        <td>
          <a href={incident.fulfillment.link}>
            {incident.fulfillment.exam.name}
          </a>
        </td>
        <td>
          <div className="content" dangerouslySetInnerHTML={{__html: incident.fulfillment.iteration.serviceType}}></div>
        </td>
        {institution}
        {preparer}
        {status}
        {incidentSubtype}
        {button}
      </tr>
    )
  }
}

IncidentReportRow.propTypes = {
  id: PropTypes.number.isRequired,
  incident: PropTypes.object.isRequired
}

export default IncidentReportRow;
