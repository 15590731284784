import { Spinner } from 'react-bootstrap';

function LoadingIndicator() {
  return (
    <div>
      <h3>Processing...</h3>
      <hr />
      <div className="mt-5 mb-5">
        <Spinner animation="grow" variant="primary" size="sm" />
        &nbsp;&nbsp;
        <Spinner animation="grow" variant="secondary" size="sm" />
        &nbsp;&nbsp;
        <Spinner animation="grow" variant="success" size="sm" />
      </div>
      <hr />
    </div>
  );
}

export { LoadingIndicator };
