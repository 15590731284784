import updateDatePicker from './shared/updateDatePicker';
import updateTimePicker from './shared/updateTimePicker';
import Slots from './Slots';

class NextSlotsButton {
  constructor() {
    this.slots = document.querySelector('.slots')
  }

  init() {
    if(this.slots) {
      this.slots.addEventListener('click', (e) => {
        if(e.target.id == 'js-reservation-next-selection') {
          const datetime = this.getNextDateTime()
          updateTimePicker(datetime)
          updateDatePicker(datetime)
          new Slots().init()
        }
      })
    }
  }

  getNextDateTime() {
    const formattedTime = $('#js-reservation-next-selection').data('time')
    return moment(formattedTime, 'YYYY-MM-DD HH:mm:ss').format()
  }

}

 export default NextSlotsButton;
