class Select2ExamContact {
  constructor() {
    this.examContactable = $('#exam-contactable');
    this.examContacts = JSON.parse(document.querySelector('.js-incident-exam-contacts').innerHTML);
  }

  init() {
    this.examContactable.select2({
      placeholder: 'Exam Contacts',
      tags: true,
      tokenSeparators: [',', ' '],
      allowClear: true,
      theme: 'bootstrap4',
      ajax: {
        url: '/incident-users.json',
        dataType: 'json',
        data: function() {
          return {
            search: {
              contactable: true,
              incident_report_id: parseInt($('.js-incident-id').html()),
              contactable_type: 'exam_contacts'
            }
          };
        },
        processResults: function(data) {
          return {
            results: data
          };
        }
      }
    });
    if (this.examContacts.length > 0) {
      this.examContacts.forEach(contact => {
        const option = new Option(contact.text, contact.id, false, true);
        $(this.examContactable).append(option)
      })
      this.examContactable.val(this.examContacts.map(contacts => contacts.id)).trigger('change');
    }
  }
}

export default Select2ExamContact;
