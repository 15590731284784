import Summernote from '../shared/Summernote';
import Toast from '../utils/Toast';

class AssessmentNotesWidget {
  constructor() {
    this.COMPONENT_SELECTOR = '.js-assessment-notes'
    this.$component = $(this.COMPONENT_SELECTOR);
    this.$modal = $('#assessment-notes-modal');
    this.editorClass = '.js-assessment-notes-editor'
    this.$editor = $(this.editorClass);
    this.institutionId = window.location.pathname.split('/')[2];
    this.$editLinkEl = $('.js-start-editing');
    this.$viewLinkEl = $('.js-view-mode');
    this.$formActionsEl = $('.modal-footer');
    this.$submitFormEl = $('.js-submit-notes');
    this.assessmentNoteId = this.$modal.data('note-id');
  }

  init() {
    this.bindEventListeners();
  }

  bindEventListeners() {
    this.$component.on('click', (e) => {
      this.renderModal(e);
    });

    this.$editLinkEl.on('click', (e) => {
      this.renderEditor(e);
    });

    this.$viewLinkEl.on('click', (e) => {
      this.destroyEditor(e);
    });

    this.$submitFormEl.on('click', (e) => {
      this.saveAssessmentNote(e);
    });
  }

  renderModal(e) {
    e.preventDefault();
    this.$modal.modal();
  }

  renderEditor(e) {
    this.$formActionsEl.removeClass('d-none');
    this.$editLinkEl.addClass('d-none');
    new Summernote(this.editorClass).init();
  }

  destroyEditor(e) {
    this.$formActionsEl.addClass('d-none');
    this.$editLinkEl.removeClass('d-none');
    this.$editor.summernote('destroy');
  }

  saveAssessmentNote(e) {
    e.preventDefault();
    this.$submitFormEl.addClass('disabled').text('Saving...');

    $.ajax({
      url: this.url(),
      type: this.ajaxType(),
      data: JSON.stringify(this.params()),
      dataType: 'json',
      contentType: 'application/json',
      success: this.handleSuccess.bind(this),
      error: this.handleError.bind(this)
    });
  }

  handleSuccess(response) {
    this.updateModalHeader(response);
    this.renderSuccessfulAlert();
    this.updateAssessmentId(response.id);
    this.resetForm();
    this.$modal.modal('hide');
  }

  updateModalHeader(response) {
    const updatedData = {
      'user': response.assessment_notes_updated_by,
      'time': response.assessment_notes_updated_at
    }
    const template = `Last updated by <strong>${updatedData.user}</strong> at <em>${updatedData.time}</em>`;

    $('.js-meta').html(template);
  }

  renderSuccessfulAlert() {
    const message = 'Assessment note saved.';

    new Toast().success({ message: message, replaceable: true });
  }

  updateAssessmentId(id) {
    this.assessmentNoteId = id;
    this.$modal.attr('data-note-id', id);
  }

  resetForm() {
    this.$submitFormEl.removeClass('disabled').text('Save changes').blur();
  }

  handleError() {
    const message = 'Oops, an error occured. Please try again.';
    new Toast().danger({ message: message, replaceable: true });
    this.resetForm();
  }

  isCreate() {
    return typeof this.assessmentNoteId === 'undefined';
  }

  ajaxType() {
    if (this.isCreate()) {
      return 'POST';
    } else {
      return 'PATCH';
    }
  }

  url() {
    const baseUrl = `/institutions/${this.institutionId}/assessment-notes`;

    if (this.isCreate()) {
      return baseUrl;
    } else {
      return `${baseUrl}/${this.assessmentNoteId}`;
    }
  }

  params() {
    return {
      'assessment_note': {
        'institution_id': this.institutionId,
        'notes_html': this.$editor.summernote('code')
      }
    }
  }
}

export default AssessmentNotesWidget;
