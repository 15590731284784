import ClipboardJS from 'clipboard';

class Clippy {
  constructor() {
    this.copiers = document.getElementsByClassName('js-tooltip-copy');
  }

  init() {
    if (!this.copiers.length > 0) return;

    const clipboard = new ClipboardJS(this.copiers);
    clipboard.on('success', this.handleSuccess);
  }

  handleSuccess(event) {
    const $trigger = $(event.trigger);
    const title = $trigger.attr('data-original-title');

    $trigger
      .attr('title', 'Copied!')
      .tooltip('_fixTitle')
      .tooltip('show')
      .attr('title', title)
      .tooltip('_fixTitle');
  }
}

export default Clippy;
