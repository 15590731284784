import consumer from '../consumer';

class ChatRoomSubscription {
  constructor(chatRoomId) {
    this.identifier = { channel: 'ChatRoomChannel', chat_room_id: chatRoomId };
    this.connection = null;
  }

  init() {
    console.log('chat_room#init');
    this.subscribe();
  }

  subscribe() {
    console.log('chat_room#subscribing');
    this.connection = consumer.subscriptions.create(this.identifier, {
      received: (data) => {
        console.log(`Received ${data}`);
      },
      connected: () => {
        console.log('Connected');
      },
      disconnected: () => {
        console.log('Disconnected');
      },
    });

    consumer.connection.webSocket.onerror = function (error) {
      console.error('Websocket connection error!', error);
    };
  }

  sendMessage(data) {
    console.log('Sending', data);
    if (this.connection) {
      this.connection.send(data);
    }
  }
}

export default ChatRoomSubscription;
