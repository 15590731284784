class StartUpdater {
  constructor() {
    this.updateStartBtn = document.querySelector(
      '[data-selector="update-start-btn"]'
    );
  }

  init() {
    if (this.updateStartBtn) {
      this.updateStartBtn.addEventListener("click", this.updateStart);
    }
  }

  updateStart(event) {
    event.preventDefault();
    const url = event.target.href;
    fetch(event.target.href, {
      method: "PATCH",
      credentials: "same-origin",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({})
    }).then(() => {
      alert("Successfully updated the start time to now!");
    }).catch(() => {
      alert("Error: Could not update start time.");
    });
  }
}

export default StartUpdater;
