class AutoExamScheduling {
  constructor() {
    this.iterationTypeSelector =
      document.querySelector('[data-element="iteration-types"]') ||
      document.querySelector('[data-element="iteration-type-hidden"]');
  }

  init() {
    if(this.iterationTypeSelector) {
      this.handleChange()
      this.iterationTypeSelector.addEventListener('change', () => { this.handleChange() });
    }
  }

  handleChange() {
    const selectedType = this.iterationTypeSelector.value;
    const autoExamScheduling = document.querySelector('[data-element="enable-auto-exam-scheduling"]');
    if (autoExamScheduling) {
      const examScheduling = autoExamScheduling.dataset.institutionAutoExamScheduling;
      if (examScheduling === 'true' && selectedType === 'Iteration::Automatic') {
        autoExamScheduling.classList.remove('d-none');
      } else {
        autoExamScheduling.classList.add('d-none');
      }
    }
  }
}

export default AutoExamScheduling;
