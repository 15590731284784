import addDynamicEventListener from '../../utils/dynamicListener';
import Toast from '../../utils/Toast';

class TermsOfService {
  constructor() {
    this.agreeTermsButton = document.querySelector(
      '[data-behavior="agree-terms-of-service"]'
    );
    this.$modal = $('#terms-of-service-modal');
    this.acceptedTerms = null;
    this.selectedSlot = null;

    this.scheduleExamSelector = '[data-behavior="schedule-exam"]';

    // On Demand flow elements
    // We need to account for the presence of these since the same controller renders
    // both default scheduling flow and on demand scheduling flow
    this.onDemandForm = document.querySelector(
      '#schedule-type-form'
    );
    this.onDemandSubmitButton = document.querySelector(
      'input[name="commit"]'
    );
    this.onDemandRadioBtn = document.querySelector(
      '#on_demand_true'
    );

    this.hideModal = this.hideModal.bind(this);
  }

  init() {
    if (this.$modal.length > 0) {
      this.bindEventListeners();
    }
  }

  bindEventListeners() {
    // first check if we're on the 'On Demand' flow, and bind event listeners to the
    // on demand selector if appropriate
    if (this.onDemandSubmitButton) {
      this.onDemandSubmitButton.addEventListener('click', (event) => {
        this.handleOnDemandScheduleClick(event)
      });
      this.agreeTermsButton.addEventListener('click', (event) => {
        this.handleOnDemandAcceptTerms(event)
      });

    } else {
      // otherwise assume we're on the regular flow
      addDynamicEventListener(
        document.body,
        'click',
        this.scheduleExamSelector,
        (event) => { this.handleDefaultScheduleClick(event) }
      );
      this.agreeTermsButton.addEventListener('click', (event) => {
        this.handleDefaultScheduleAcceptTerms(event)
      });
    }
    this.$modal.on('hidden.bs.modal', this.hideModal);
  }

  handleDefaultScheduleAcceptTerms(event) {
    event.preventDefault();
    this.handleAcceptTerms;
    this.selectedSlot.parentElement.submit();
  }

  handleOnDemandAcceptTerms(event) {
    event.preventDefault();
    this.handleAcceptTerms();
    this.onDemandForm.submit();
  }

  handleOnDemandScheduleClick(event) {
    if (this.onDemandRadioBtn.checked) {
      event.preventDefault();
      this.displayTermsModal();
    }
  }

  handleDefaultScheduleClick(event) {
    event.preventDefault();
    this.selectedSlot = event.target;
    this.displayTermsModal();
  }

  displayTermsModal() {
    this.$modal.modal('show');
  }

  handleAcceptTerms() {
    this.acceptedTerms = true;
    this.$modal.modal('hide');
  }

  hideModal() {
    if (!this.acceptedTerms) {
      if (!document.querySelector('div.alert.alert-danger.alert-wide')) {
        new Toast().danger({message: polyglot.t('must_agree_tos')})
      }
    }
  }
}

export default TermsOfService;
