import moment from 'moment';
import ChatInput from './ChatInput';
import ChatMessages from './ChatMessages';
import ChatApp from '../../ProctorChat/Apps/ChatApp';

// TODO: delete this component one day, or clean it up to follow react best practices

class ChatView extends React.Component {
  constructor(props) {
    super(props);

    // ref
    this.chatBody = null;
  }

  componentDidUpdate() {
    this.goToBottomOfChat();
  }

  /**
   * Scroll window to last message.
   */
  goToBottomOfChat = () => {
    this.chatBody.scrollTop = this.chatBody.scrollHeight;
  };

  /**
   * Render active/inactive phone icon with/without timer.
   */
  renderIcons = () => {
    const {
      activeCall,
      callDuration,
      acceptedCall,
      chatEnabled,
      sessionIsRunning,
    } = this.props;
    var chatTime = (
      <span className="text-chat-voice-time">
        {moment.utc(callDuration * 1000).format('mm:ss')}
      </span>
    );

    if (acceptedCall && !activeCall) {
      return (
        <div>
          <span>Calling...</span>
          {this.renderEndCallIcon()}
        </div>
      );
    } else if (acceptedCall && activeCall) {
      return (
        <div>
          {acceptedCall ? chatTime : null}
          {this.renderMicrophoneIcon()}
          {this.renderEndCallIcon()}
        </div>
      );
    } else if (!acceptedCall && activeCall) {
      return (
        <div>
          {this.renderAcceptCallIcon()}
          {this.renderEndCallIcon()}
        </div>
      );
    } else if (!sessionIsRunning || !chatEnabled) {
      return <div> {this.renderDisabledCallIcon()} </div>;
    } else {
      return <div> {this.renderStartCallIcon()} </div>;
    }
  };

  /**
   * Render microphone icon
   */
  renderMicrophoneIcon = () => {
    const { switchAudioOutputState, microphoneIsMuted } = this.props;
    return (
      <span>
        <a className="fa-stack cursor-pointer" onClick={switchAudioOutputState}>
          <i className="fas fa-circle fa-stack-2x fa-inverse"></i>
          <i
            className={`fas ${
              microphoneIsMuted ? 'fa-microphone-slash' : 'fa-microphone'
            } fa-stack-1x`}
          ></i>
        </a>
      </span>
    );
  };

  renderAcceptCallIcon = () => {
    const { acceptCall } = this.props;
    return (
      <a className="fa-stack cursor-pointer" onClick={acceptCall}>
        <i className="fas fa-circle fa-stack-2x text-white"></i>
        <i className="fas fa-phone-volume fa-stack-1x"></i>
      </a>
    );
  };

  renderStartCallIcon = () => {
    const { startCall } = this.props;
    return (
      <a
        className="fa-stack cursor-pointer text-chat-phone"
        onClick={startCall}
      >
        <i className="fas fa-circle fa-stack-2x fa-inverse"></i>
        <i className="fas fa-phone fa-stack-1x"></i>
      </a>
    );
  };

  renderDisabledCallIcon = () => {
    const {} = this.props;
    return (
      <div>
        <span
          className="fa-stack cursor-not-allowed"
          data-toggle="tooltip"
          data-placement="top"
          title="To enable, please start watching and ensure the session is running."
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <i className="fas fa-circle fa-stack-2x"></i>
          <i className="fas fa-phone-slash fa-stack-1x text-muted"></i>
        </span>
      </div>
    );
  };

  renderEndCallIcon = () => {
    const { endCall } = this.props;
    return (
      <a className="fa-stack cursor-pointer" onClick={endCall}>
        <i className="fas fa-circle fa-stack-2x text-danger"></i>
        <i className="fas fa-phone fa-stack-1x text-white fa-rotate-180"></i>
      </a>
    );
  };

  render() {
    const {
      studentName,
      messages,
      sendHandler,
      chatEnabled,
      sessionIsRunning,
      handleCollapse,
      expanded,
      useNativeChat,
      nativeChatPath,
    } = this.props;

    return (
      <div className="card text-chat-wrapper">
        <div
          className="card-header text-chat-header cursor-pointer"
          onClick={handleCollapse}
        >
          <div className="card-title text-white">
            <span className="mr-2">
              {expanded ? (
                <i className="fa fa-minus" />
              ) : (
                <i className="fa fa-plus" />
              )}
            </span>
            {studentName}
            <div className="float-right">{this.renderIcons()}</div>
          </div>
        </div>
        <div className={`collapse${this.props.expanded ? 'in' : ''}`}>
          {/* TODO: we want to hack our new "Native Chat" in here for now, we should really clean this up later */}
          {useNativeChat !== true ? (
            <>
              <div
                className="text-chat-body"
                ref={(ref) => (this.chatBody = ref)}
              >
                <ChatMessages messages={messages} />
              </div>
              <ChatInput
                chatEnabled={chatEnabled}
                sessionIsRunning={sessionIsRunning}
                sendHandler={sendHandler}
              />
            </>
          ) : (
            <div className="m-0" ref={(ref) => (this.chatBody = ref)}>
              {!nativeChatPath ? (
                <div className="text-center mt-2">
                  <p>
                    There is no chat room yet, please refresh after one is
                    active
                  </p>
                </div>
              ) : (
                // <iframe src={nativeChatPath} width="100%" height="600" />
                <div style={{ height: '600px' }}>
                  <ChatApp {...this.props.nativeChatProps} />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ChatView;
