class PushNotifications {
  constructor() {
    this.notificationsAvailable = false;
    this.subscriptionInfo = document.querySelector(
      '[data-behavior="subscription"]'
    );
  }

  init() {
    if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.registerServiceWorker();
      this.bindEventListeners();
    }
  }

  registerServiceWorker() {
    if (navigator.serviceWorker) {
      Notification.requestPermission().then(permission => {
        if (permission === "granted") {
          this.notificationsAvailable = true;
          navigator.serviceWorker
            .register('/ServiceWorker.js')
            .then(registration => {
              this.subscribeNotifications(registration);
              registration.update();
              window.register = registration;
            })
            .catch(error => {
              console.error('Registration failed with', error);
            });
        }
      });
    }
  }

  bindEventListeners() {
    if (this.notificationsAvailable) {
      $(window).off('pushNotification');
      $(window).on('pushNotification', (e, body) => {
        this.sendNotification(body);
      });
      $(window).on('unload', () => {
        this.unsubscribe();
      });
    }
  }

  subscribeNotifications(serviceWorkerRegistration) {
    const pushManager = serviceWorkerRegistration.pushManager;
    if (typeof pushManager != 'undefined') {
      pushManager.getSubscription().then(subscription => {
        this.pushManagerSubscribe(pushManager, subscription);
      });
    }
  }

  refreshSubscription(pushManager, subscription) {
    return subscription.unsubscribe().then(() => {
      this.pushManagerSubscribe(pushManager, subscription);
    });
  }

  pushManagerSubscribe(pushManager, subscription) {
    if (this.isSubscriptionRequired()) {
      pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: window.vapidPublicKey
      });
      $.post('/api/subscriptions', { subscription: subscription.toJSON() });
    }
  }

  sendNotification(body) {
    $.ajax({
      type: 'POST',
      url: '/api/push_notifications',
      data: JSON.stringify(body),
      contentType: 'application/json',
      dataType: 'json'
    });
  }

  getSubscription(register) {
    return register.pushManager.getSubscription();
  }

  isSubscriptionRequired() {
    if (this.subscriptionInfo) {
      return (
        this.subscriptionInfo.getAttribute('data-required') === 'true' ||
        this.isPageWithPushNotifications()
      );
    }
    return false;
  }

  isPageWithPushNotifications() {
    let pages = ['fulfillments:show', 'reservations:status'];
    let currentPage = document.body.dataset.page;
    return pages.indexOf(currentPage) > -1;
  }

  unsubscribe() {
    if (this.isPageWithPushNotifications()) {
      $.ajax({
        type: 'DELETE',
        url: '/api/subscriptions',
        contentType: 'application/json',
        dataType: 'json'
      });
    }
  }
}

export default PushNotifications;
