// This app handles all of the work done on the reservations/status page.
//
// It renders four different tables using data from the http response payload,
// respectively remapped in such a way that the data can be fed into generic
// table components (see 'NeedsVerificationController' for an example). We also   // TODO: Should this be 'SessionAwaitingVerificationController'?
// subscribe to an event channel for updates to the reservation. If an update is
// made, the reservation's tab or table will be updated as well.

import axios from 'axios';
import RowMapper from './RowMapper';
import GenericTable from '../../GenericTable.js';
import csrfToken from '../../../src/utils/csrf';
import consumer from '../../../src/channels/consumer';

class ReservationsStatusApp extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      data: [],
      slug:
        window.location.hash.length > 0
          ? window.location.hash.slice(1)
          : 'needs-verification',
      loading: true,
      tabsUpdated: {
        'needs-verification': false,
        'delayed-starts': false,
        'delayed-submissions': false,
        'in-progress': false,
      },
    };
    this.slugMap = {
      'needs-verification': 'awaiting_verification',
      'delayed-starts': 'delayed_startup',
      'delayed-submissions': 'delayed_exam_submission',
      'in-progress': 'in_progress',
    };

    window.location.hash = this.state.slug;
  }

  componentDidMount() {
    this.fetchData();
    this.setupChannel();
  }

  componentWillUnmount() {
    this.ReservationStatus.unsubscribe();
  }

  fetchData() {
    const url = new URL(window.location.origin);
    url.pathname = '/reservations/status';
    url.searchParams.set('status', this.slugMap[this.state.slug]);

    const config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken(),
      },
    };

    axios.get(url.href, config).then((response) => {
      this.setState({
        data: new RowMapper(response.data).map(),
        loading: false,
      });
    });
  }

  setupChannel() {
    this.ReservationStatus = consumer.subscriptions.create(
      {
        channel: 'ReservationStatusChannel',
      },
      {
        received: (data) => {
          this.handleTabs(data);
          this.handleRow(data);
        },
      },
    );
  }

  handleClick(slug) {
    this.setState(
      {
        data: [],
        slug: slug,
        loading: true,
        tabsUpdated: {
          ...this.state.tabsUpdated,
          [slug]: false,
        },
      },
      () => {
        // we have to do this to make sure that fetchData isn't asynchronously updated before the state is set
        this.fetchData();
        window.location.hash = slug;
      },
    );
  }

  handleRow(data) {
    if (this.rowCanBeAdded(data)) {
      const previousData = this.state.data;
      const newRow = new RowMapper([data]).map();
      this.setState({ data: previousData.concat(newRow) });
    }
  }

  handleTabs(data) {
    this.setState({
      // prettier-ignore
      tabsUpdated: {
        // Using 'or' (`||`) prevents the tabs in the state from being unset, if falsy for whatever reason.
        ['needs-verification' ]: this.state.tabsUpdated['needs-verification' ] || data['awaiting_verification'  ],
        ['delayed-starts'     ]: this.state.tabsUpdated['delayed-starts'     ] || data['delayed_startup'        ],
        ['delayed-submissions']: this.state.tabsUpdated['delayed-submissions'] || data['delayed_exam_submission'],
        ['in-progress'        ]: this.state.tabsUpdated['in-progress'        ] || data['in_progress'            ],

        [this.state.slug      ]: false, // Flip back the previous tab, if the tab is active.
      },
    });
  }

  rowCanBeAdded(data) {
    return this.tableDoesNotHaveRow(data) && this.selectedTabIsActive(data);
  }

  tableDoesNotHaveRow(data) {
    return !this.state.data.find((row) => row.id === data.fulfillment_uuid);
  }

  selectedTabIsActive(data) {
    return data[this.slugMap[this.state.slug]];
  }

  render() {
    return (
      <div className="row mb-3">
        <div className="col-md-12">
          <div role="tabpanel">
            <ul className="nav nav-tabs nav-fill" role="tablist">
              <li
                className="nav-item"
                onClick={() => this.handleClick('needs-verification')}
              >
                <a
                  href="#needs_verification"
                  data-toggle="tab"
                  className="nav-link active "
                >
                  <strong>Needing verification</strong>
                  {this.state.tabsUpdated['needs-verification'] ? (
                    <span>
                      <i className="fa fa-circle fa-xs text-primary float-right mt-2"></i>
                    </span>
                  ) : null}
                </a>
              </li>
              <li
                className="nav-item"
                onClick={() => this.handleClick('delayed-starts')}
              >
                <a
                  href="#delayed-starts"
                  data-toggle="tab"
                  className="nav-link"
                >
                  <strong>Starting > 9 minutes</strong>
                  {this.state.tabsUpdated['delayed-starts'] ? (
                    <span>
                      <i className="fa fa-circle fa-xs text-primary float-right mt-2"></i>
                    </span>
                  ) : null}
                </a>
              </li>
              <li
                className="nav-item"
                onClick={() => this.handleClick('delayed-submissions')}
              >
                <a
                  href="#delayed-submissions"
                  data-toggle="tab"
                  className="nav-link"
                >
                  <strong>Close-outs</strong>
                  {this.state.tabsUpdated['delayed-submissions'] ? (
                    <span>
                      <i className="fa fa-circle fa-xs text-primary float-right mt-2"></i>
                    </span>
                  ) : null}
                </a>
              </li>
              <li
                className="nav-item"
                onClick={() => this.handleClick('in-progress')}
              >
                <a href="#in-progress" data-toggle="tab" className="nav-link">
                  <strong>In progress</strong>
                  {this.state.tabsUpdated['in-progress'] ? (
                    <span>
                      <i className="fa fa-circle fa-xs text-primary float-right mt-2"></i>
                    </span>
                  ) : null}
                </a>
              </li>
            </ul>
            <div className="card tab-content border-top-0">
              <div className="card-body tab-pane active">
                <GenericTable
                  headers={this.props.headers}
                  rows={this.state.data}
                  loading={this.state.loading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ReservationsStatusApp.defaultProps = {
  headers: [
    'Started At',
    'Time since Start',
    'Test-taker ID',
    'Institution',
    'Exam',
    'Proctor',
    'Tags',
    '',
  ],
};

export default ReservationsStatusApp;
