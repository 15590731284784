import getRequestParams from '../utils/getRequestParams';
import axios from 'axios';
import csrfToken from '../utils/csrf';

class WatchingButtons {
  constructor() {
    this.stopWatchingButton = document.querySelector('[data-btn-selector="stop-watch"]');
    this.startWatchingButton = document.querySelector('[data-btn-selector="watch-session"]');
    this.finishWatchingButton = document.querySelector('[data-btn-selector="finish-watching"]');
    this.watchLabel = document.querySelector('[data-behavior="watch-label"]');
    this.chatApp = document.querySelector('[data-behavior="chat-app"]');
  }

  init() {
    this.bindEventListeners();
  }

  bindEventListeners() {
    if (this.startWatchingButton) {
      this.startWatchingButton.body = { type: 'FlightPath', event: {} };
      this.startWatchingButton.handler = (data) => { this.startWatching(data) };
      this.startWatchingButton.addEventListener('click', (event) => {
        this.sendRequest(event)
      });
    }
    if (this.stopWatchingButton) {
      this.stopWatchingButton.handler = (data) => { this.stopWatching() };
      this.stopWatchingButton.addEventListener('click', (event) => {
        this.sendRequest(event)
      })
    }
    if (this.finishWatchingButton) {
      this.finishWatchingButton.handler = () => { this.finishWatching() };
      this.finishWatchingButton.addEventListener('click', (event) => {
        this.sendRequest(event)
      });
    }
  }

  sendRequest(event) {
    if (!confirm(event.target.getAttribute('data-confirmation'))) { return; }
    const headers = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken()
      }
    }
    axios.post(event.target.getAttribute('data-url'), getRequestParams(event.target.body), headers)
      .then(response => {
        event.target.handler(response.data)
      })
      .catch((error) => {console.log(error); });
  }

  startWatching(data) {
    if (this.startWatchingButton.classList.contains('queue-page-watch-eye')) {
      this.startWatching.closest('tr').remove();
    }
    this.startWatchingButton.classList.add('d-none');
    if (this.stopWatchingButton) {
      this.stopWatchingButton.classList.remove('d-none');
    }
    if (this.finishWatchingButton) {
      this.finishWatchingButton.classList.remove('d-none');
    }
    if (this.chatApp) {
      let proctorName = data['proctorName'];
      this.chatApp.dispatchEvent(new CustomEvent('updateChatApp', { detail: proctorName }));
      this.chatApp.dispatchEvent(new CustomEvent('unlockChat'));
    }
    if (this.watchLabel) {
      this.watchLabel.classList.remove('d-none');
    }
  }

  stopWatching() {
    this.stopWatchingButton.classList.add('d-none');
    this.finishWatchingButton.classList.add('d-none');
    this.startWatchingButton.classList.remove('d-none');
    if (this.chatApp) {
      this.chatApp.dispatchEvent(new CustomEvent('updateChatApp', { detail: 'Proctor' }));
      this.chatApp.dispatchEvent(new CustomEvent('lockChat'));
    }
  }

  finishWatching() {
    this.stopWatching();
    if (this.watchLabel) {
      this.watchLabel.classList.add('d-none');
    }
  }
}

export default WatchingButtons;
