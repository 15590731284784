class CompleteIncidentButton {
  constructor() {
    this.completeIncidentButtons = document.querySelectorAll('.js-incident-completion')
  }

  init() {
    this.bindEventListeners()
  }

  bindEventListeners() {
    this.completeIncidentButtons.forEach(button => {
      button.addEventListener('click', (event) => {
        this.submitForm(event);
      });
    });
  }

  submitForm(event) {
    event.preventDefault();
    const input = document.createElement('input')
    input.setAttribute('type', 'hidden')
    input.setAttribute('name', 'complete')
    input.value = event.currentTarget.getAttribute('data-complete')
    document.querySelector('#incident-report-submission').insertAdjacentElement('beforeend', input)
    document.querySelector('#incident-report-submission').submit()
  }
}

export default CompleteIncidentButton
