import * as d3 from "d3";


// For the fulfillments graph that is shown on a Schedules index
//
// This graph shows the amount of total, fulfilled, and scheduled fulfillments,
// and displays the total amount per hour.
//
// It also shows the throttle count per hour.
//
// Within each hour, a bar is displayed with the height relative to the other heights
// on the graph, and calculated based on the number of fulfillments in that hour.
//
// The graph bars should be colored by how many fulfillments are in that hour,
// for instance, a bar with a low count (< 10) would be light blue, while a bar with
// a high count (> 500) would be purple.
//
// The specified hour is displayed below each bar in standard format (11PM, 12AM, etc)
// and these hours are clickable. When an hour is clicked, it should change the 'hour' param
// of the URL to reflect that (and thus only show fulfillments within that hour).
//
class ScheduleGraph {
  constructor() {
    this.graph = document.querySelector('[data-behavior="graph"]');
    this.hoursArr = [
      '12AM',
      '1AM',
      '2AM',
      '3AM',
      '4AM',
      '5AM',
      '6AM',
      '7AM',
      '8AM',
      '9AM',
      '10AM',
      '11AM',
      '12PM',
      '1PM',
      '2PM',
      '3PM',
      '4PM',
      '5PM',
      '6PM',
      '7PM',
      '8PM',
      '9PM',
      '10PM',
      '11PM'
    ];
  }

  init() {
    // don't continue if graph isn't here
    if (this.graph === null || this.graph.length == 0) {
      return;
    }

    // all the corresponding graph data is stored in the element's data attributes
    const graphData = this.graph.dataset;

    // need to JSON.parse this because it is originally an array as a string
    let schedules = JSON.parse(graphData.schedulesByHour);
    let schedulesLength = schedules.length;

    // don't parse throttles yet because it might be undefined
    let throttles = graphData.throttles;
    let throttlesLength;

    // if the throttles attribute is undefined, user is a proctor
    const isProctor = !throttles;

    let grassColors = graphData.grassColors;

    // start and end days, and hour according to daylight savings time
    const dstStartDay = JSON.parse(graphData.dstStartDay);
    const dstEndDay = JSON.parse(graphData.dstEndDay);

    const dstHour =
      graphData.dstHour === '' ? undefined : JSON.parse(graphData.dstHour);

    // max number of schedules or throttles in any hour
    let max;

    // if the user is not a proctor, set up throttles and grass colors
    if (!isProctor) {
      throttles = JSON.parse(throttles);
      throttlesLength = throttles.length;

      max = Math.max(...schedules.concat(throttles));

      // pad throttles with zeroes if it doesn't hold a value for each hour
      let dayOffset = 0;

      if (throttlesLength < 24 && !dstStartDay) {
        dayOffset = 24;
      } else if (throttlesLength < 23 && dstStartDay) {
        dayOffset = 23;
      } else if (throttlesLength < 25 && dstEndDay) {
        dayOffset = 25;
      }

      if (dayOffset > 0) {
        // make an array of zeroes that is the length of dayOffset - throttlesLength
        const zeroes = [].slice.apply(
          new Uint8Array(dayOffset - throttlesLength)
        );
        throttles = throttles.concat(zeroes);
        throttlesLength = dayOffset;
      }

      grassColors = JSON.parse(grassColors);
    } else {
      // user is a proctor. we don't need throttles or grass colors
      throttles = [];
      throttlesLength = 0;

      max = Math.max(...schedules);

      grassColors = [];
    }

    // if DST applies, add a DST hour to the beginning of each array
    if (dstStartDay) {
      throttles.splice(dstHour, 0, 0);
      schedules.splice(dstHour, 0, 0);
      throttlesLength += 1;
      schedulesLength += 1;
    }

    // graph width is the width of the parent div
    const graphWidth = this.graph.parentNode.offsetWidth;
    const graphHeight = 200;

    const scale = max > 0 ? graphHeight / max : 0;

    const barChartPadding = 2;

    const barWidth = graphWidth / schedulesLength - barChartPadding;
    const svg = d3
      .select('[data-behavior="graph"]')
      .attr('width', graphWidth)
      .attr('height', graphHeight + 20);

    // scale x axis to the width of the graph
    const x = d3.scaleBand().rangeRound([0, graphWidth]);
    const linear = d3.scaleLinear();
    const xAxis = d3.axisBottom(linear)
                  .ticks(x);


    // build throttle bars
    if (!isProctor) {
      svg
        .selectAll('rect')
        .data(throttles)
        .enter()
        .append('rect')
        .attr('x', (d, i) => {
          return i * (graphWidth / throttlesLength);
        })
        .attr('y', (d, i) => {
          return graphHeight - d * scale;
        })
        .attr('height', (d, i) => {
          return d * scale;
        })
        .attr('width', barWidth)
        .attr('fill', (d, i) => {
          return 'rgba(44, 62, 80, 0.1)';
        })
        .attr('class', 'text-cursor')
        .attr('data-hour', (d, i) => this.hoursArr[i]);
    }

    // build reservation bars
    svg
      .selectAll('.bar')
      .data(schedules)
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('x', (d, i) => {
        return i * (graphWidth / schedulesLength);
      })
      .attr('y', d => {
        return graphHeight - d * scale;
      })
      .attr('height', d => {
        return d * scale;
      })
      .attr('width', barWidth)
      .attr('fill', d => {
        if (isProctor) {
          return 'rgba(151,187,205,0.5)';
        }
        if (d <= grassColors[0]) return 'rgba(160, 231, 248, 0.6)';
        else if (d > grassColors[0] && d <= grassColors[1])
          return 'rgba(45, 201, 240, 0.6)';
        else if (d > grassColors[1] && d <= grassColors[2])
          return 'rgba(15, 171, 210, 0.6)';
        else if (d > grassColors[2] && d <= grassColors[3])
          return 'rgba(11, 124, 153, 0.6)';
        else if (d > grassColors[3] && d <= grassColors[4])
          return 'rgba(241, 196, 15, 0.6)';
        else if (d > grassColors[4] && d <= grassColors[5])
          return 'rgba(230, 126, 34, 0.6)';
        else if (d > grassColors[5] && d <= grassColors[6])
          return 'rgba(230, 126, 34, 0.6)';
        else if (d > grassColors[6] && d <= grassColors[7])
          return 'rgba(211, 84, 0, 0.6)';
        else if (d > grassColors[7] && d <= grassColors[8])
          return 'rgba(239, 72, 54, 0.6)';
        else if (d > grassColors[8] && d <= grassColors[9])
          return 'rgba(192, 57, 43, 0.6)';
        else return 'rgba(142, 68, 173, 0.6)';
      })
      .attr('class', 'text-cursor')
      .attr('data-hour', (d, i) => this.hoursArr[i]);

    // add throttle bar text
    if (!isProctor) {
      svg
        .selectAll()
        .data(throttles)
        .enter()
        .append('text')
        .text((d, i) => {
          if (dstStartDay && dstHour == i) {
            return '';
          }
          return 'T: ' + d;
        })
        .attr('x', (d, i) => {
          let offset = barWidth / 2 - 5;
          return (
            i * (graphWidth / throttlesLength) +
            (graphWidth / throttlesLength - barChartPadding) / 2
          );
        })
        .attr('y', graphHeight - 3)
        .attr('font-size', '10px')
        .attr('fill', '#555')
        .attr('font-weight', 'bold')
        .attr('text-anchor', 'middle');
    }

    // add reservation bar text
    svg
      .selectAll()
      .data(schedules)
      .enter()
      .append('text')
      .text((d, i) => {
        if (dstStartDay && dstHour == i) {
          return 'DST';
        }
        return 'R: ' + d;
      })
      .attr('x', (d, i) => {
        return (
          i * (graphWidth / schedulesLength) +
          (graphWidth / schedulesLength - barChartPadding) / 2
        );
      })
      .attr('y', (d, i) => {
        if (dstStartDay && dstHour == i) {
          return graphHeight - 6;
        }
        const height = d * scale;
        const offset = height > 30 ? 15 : -12;
        return graphHeight - d * scale + offset;
      })
      .attr('font-size', (d, i) => {
        if (dstStartDay && dstHour == i) {
          return '15px';
        }
        return '10px';
      })
      .attr('fill', '#555')
      .attr('font-weight', 'bold')
      .attr('text-anchor', 'middle');

    // add hour strings below bars
    svg
      .selectAll()
      .data(this.hoursArray(dstStartDay, dstEndDay, dstHour))
      .enter()
      .append('text')
      .attr('class', 'hour-text')
      .text((d, i) => {
        return d;
      })
      .attr('x', (d, i) => {
        const offset = barWidth / 2 - 5;
        return (
          i * (graphWidth / schedulesLength) +
          (graphWidth / schedulesLength - barChartPadding) / 2
        );
      })
      .attr('y', graphHeight + 20)
      .attr('font-size', (d, i) => {
        if (dstStartDay && dstHour == 1) {
          return '16px';
        }
        return '12px';
      })
      .attr('fill', '#a8abab')
      .attr('font-weight', '500')
      .attr('text-anchor', 'middle');

    // add redirect to hour string clicks
    svg.selectAll('.hour-text').on('click', (event) => { this.handleClick(event.currentTarget.innerHTML) });

    // add redirect to column clicks
    svg.selectAll('rect').on('click', () => {  this.handleClick(this.dataset.hour); });

    // if hour is set in params, mark that as active so user knows an hour is selected
    [...document.getElementsByClassName('hour-text')].forEach(el => {
      el.classList.remove('active');

      if (window.location.href.includes(`&hour=${el.innerHTML}`)) {
        el.classList.add('active');
      }
    });
  }

  handleClick(hourString){
    let url = window.location.href;

    // if url already includes hour being clicked, toggle that hour off and show all
    const hourToggledOff = url.indexOf(`hour=${hourString}`) > -1;

    // delete hour param in url
    // regex detects if param starts with ? or &,
    // and if it is the end of the string or has another param after it
    url = url.replace(/&hour=.*?((?:(?!&).)*|$)/, '');

    // if hour isn't being toggled off, add new hour to params
    if (!hourToggledOff) {
      url += `&hour=${hourString}`;
    }

    window.location.href = url;
  }


  hoursArray(dstStartDay, dstEndDay, dstHour) {
    let arr = this.hoursArr.slice(0);

    if (dstEndDay) {
      arr.splice(dstHour, 0, arr[dstHour - 1]);
    } else if (dstStartDay) {
      arr.splice(dstHour, 1, '*');
    }
    return arr;
  }
}

export default ScheduleGraph;
