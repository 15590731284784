// To handle tabs on the institution show page.
// Makes test it out results tab active after page refresh if searching has been used.
//
class InstitutionTabsManager {
  constructor() {
    this.resetButton = document.querySelector('[data-element="reset-button"]');
    this.searchForm = document.querySelector('[data-element="search-form"]');
    const pagination = document.querySelector('ul.pagination');
    if (pagination) {
      this.paginationLinks = pagination.getElementsByTagName('a');
    }
  }

  init() {
    if (this.paginationLinks) {
      [...this.paginationLinks].forEach((element) =>
        element.href += '#test-it-out-results'
      );
    }
    this.searchForm.action += '#test-it-out-results';
    this.resetButton.href += '#test-it-out-results';
    this.setActiveTab();
  }

  setActiveTab() {
    var activeTab = window.location.hash;
    if(activeTab != '')
      {
        $(".tab-pane").removeClass("active in");
        $(activeTab).addClass("active in");
        $('a[href="' + activeTab +'"]').tab('show');
      }
    }
   }

export default InstitutionTabsManager;
