import axios from 'axios';
import { useEffect } from 'react';
import ActionButtons from '../Shared/ActionButtons';
import csrfToken from '../../../../src/utils/csrf';
import Toast from '../../../../src/utils/Toast';

const FulfillSessionProcedure = (props) => {
  const procedure = props.procedure;
  const fulfillment = props.fulfillment;
  const sendEvent = props.sendEvent;
  const markProcCompleted = props.markProcCompleted;
  const getFulfillmentEvents = props.getFulfillmentEvents;
  const disabledBtn = props.disabledBtn;

  useEffect(() => {
    getFulfillmentEvents(
      ['Event::GuidedLaunchFulfillSessionComplete'],
      (response) => {
        if (response.data.events.length > 0) {
          markProcCompleted(procedure.id);
        }
      },
    );
  }, []);

  const completeProcedure = () => {
    if (
      confirm(
        'Please confirm the test-taker has submitted their exam. If they have not, the test-taker will be logged out of the learning management system and disconnected from ProctorU immediately.',
      )
    ) {
      sendEvent('Event::GuidedLaunchFulfillSessionCompleted', {});
      sendEvent('Event::FulfillmentEnded', {});
      markProcCompleted(procedure.id);

      const headers = {
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken(),
        },
      };

      axios
        .post(
          `${window.location.origin}/fulfillments/${fulfillment.uuid}/end`,
          {},
          headers,
        )
        .then((response) => {
          console.log('Fulfillment ended');
        })
        .catch((error) => {
          new Toast().danger({ message: 'Unable to complete flight path.' });
        });
    }
  };

  const resolveProcedure = () => {
    console.log('resolved button clicked');
  };

  return (
    <>
      <ActionButtons
        disabledBtn={disabledBtn}
        resovleBtnText={''}
        confirmBtnText={'Complete'}
        confirmBtnAction={completeProcedure}
        resolveBtnAction={resolveProcedure}
      />
      {disabledBtn && (
        <div
          data-testid="fulfill-session-procedure-warning-text"
          className="alert alert-danger alert-form"
        >
          <p>
            To enable the Complete button to fulfill this session, ensure that
            you have reviewed the post-exam task.
          </p>
        </div>
      )}
    </>
  );
};

export default FulfillSessionProcedure;
