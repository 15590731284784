// For the Settings tab on the institutions#show page
class SettingsTab {
  constructor() {
    this.$changeMessagePanes = $('.change-message-pane')
    this.modifyEditUrl = this.modifyEditUrl.bind(this);
  }

  init() {
    this.bindEventListeners();
  }

  bindEventListeners() {
    this.$changeMessagePanes.on('shown.bs.tab', this.modifyEditUrl);
  }

  modifyEditUrl(event) {
    const paneId = event.target.getAttribute('href');
    const url = document.querySelector(paneId).getAttribute('data-edit-url');
    document.querySelector('a.edit-message').setAttribute('href', url)
  }
};

export default SettingsTab;
