import setHint from './iterations/form/presets/utils/setHint';
import PresetsLostFocus from './iterations/form/presets/PresetsLostFocus';

class PresetsConfig {
  constructor() {
    this.presetsRadios = document.querySelectorAll(
      '[data-element="preset-radio"]'
    );
    this.presetCustom = document.querySelector('[data-element="preset-custom-radio"]');

    this.browserTabsSelect = document.querySelector('[data-element="browser_tabs"]'),
    this.allowCopyPasteSelect = document.querySelector('[data-element="allow_copy_paste"]'),
    this.windowSizeAllowedSelect = document.querySelector('[data-element="window_size_allowed"]'),
    this.lostFocusSelect = document.querySelector('[data-element="lost_focus"]')
  }

  init() {
    new PresetsLostFocus().init();
    this.bindEventListeners();
    this.onPresetsRadioChange();
  }

  bindEventListeners() {
    this.presetsRadios.forEach(radio => {
      radio.addEventListener('click', (event) => { this.onPresetsRadiosClick(event) });
    });

    this.browserTabsSelect.addEventListener('change', () => { this.onChangeSelect() });
    this.allowCopyPasteSelect.addEventListener('change', () => { this.onChangeSelect() });
    this.windowSizeAllowedSelect.addEventListener('change', () => { this.onChangeSelect() });
    this.lostFocusSelect.addEventListener('change', () => { this.onChangeSelect() });
  }

  onPresetsRadiosClick(e) {
    const $this = $(e.target);
    this.browserTabsSelect.value = $this.attr('browser_tabs')
    this.allowCopyPasteSelect.value = $this.attr('allow_copy_paste')
    this.windowSizeAllowedSelect.value = $this.attr('window_size_allowed')
    this.lostFocusSelect.value = $this.attr('lost_focus')

    setHint($this.attr('lost_focus'));
  }

  onPresetsRadioChange() {
    [this.browserTabsSelect, this.allowCopyPasteSelect, this.windowSizeAllowedSelect, this.lostFocusSelect].forEach(element => {
      let hint = element.options[element.selectedIndex].getAttribute('hint');
      element.parentElement.querySelector('.hint').textContent = hint;
    });
  }

  onChangeSelect() {
    this.presetCustom.click();
  }
};

export default PresetsConfig;
  