import PropTypes from 'prop-types';
import IncidentReportRow from './IncidentReportRow';
import IncidentReportBlankRow from './IncidentReportBlankRow';
import IncidentReportTablePagination from './IncidentReportTablePagination';
import isInstitution from '../../../src/incident_report_center/utils/isInstitution'

class IncidentReportTable extends React.Component {
  constructor(props){
    super(props)
  }

  renderColgroup() {
    if (!isInstitution()) {
      return (
        <colgroup></colgroup>
      )
    }
  }

  renderInstitutionHead() {
    if (!isInstitution()) {
      return (
        <th>Institution</th>
      )
    }
  }

  renderPreparerHead() {
    if (!isInstitution()) {
      return (
        <th>Preparer</th>
      )
    }
  }

  renderStatusHead() {
    if (!isInstitution()) {
      return (
        <th>Status</th>
      )
    }
  }

  renderRows() {
    var component = this;

    if (component.props.incidentReports.length > 0) {
      return component.props.incidentReports.map(function(incident) {
        return (
          <IncidentReportRow key={incident.id}
            id={component.props.id}
            incident={incident} />
        )
      })
    } else {
      return <IncidentReportBlankRow gifPath={component.props.gifPath} blankMsg={component.props.blankMsg} />
    }
  }

  render() {
    var institutionColgroup = this.renderColgroup();
    var preparerColgroup = this.renderColgroup();
    var statusColgroup = this.renderColgroup();
    var institutionHead = this.renderInstitutionHead();
    var preparerHead = this.renderPreparerHead();
    var statusHead = this.renderStatusHead();
    var rows = this.renderRows();

    return (
      <div className="table-responsive">
        <table className="table table-semibordered table-hover table-default">
          <colgroup className="colgroup-icon"></colgroup>
          <colgroup className="colgroup-id"></colgroup>
          <colgroup></colgroup>
          <colgroup></colgroup>
          <colgroup></colgroup>
          {institutionColgroup}
          {preparerColgroup}
          {statusColgroup}
          <colgroup></colgroup>
          <colgroup></colgroup>
          <thead>
            <tr>
              <th>Date</th>
              <th>Name</th>
              <th>Course Name</th>
              <th>Exam</th>
              <th>Service</th>
              {institutionHead}
              {preparerHead}
              {statusHead}
              <th>Type</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tfoot>
            <IncidentReportTablePagination
              id={this.props.id}
              goToPage={this.props.goToPage}
              changePageLimit={this.props.changePageLimit}
              itemsCount={this.props.incidentReportsCount}
              page={this.props.page}
              limit={this.props.limit} />
          </tfoot>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    );
  }
}

IncidentReportTable.propTypes = {
  id: PropTypes.number.isRequired,
  incidentReports: PropTypes.array.isRequired,
  goToPage: PropTypes.func.isRequired,
  changePageLimit: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  gifPath: PropTypes.string.isRequired
}

export default IncidentReportTable;
