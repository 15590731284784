import AccommodationCreator from './AccommodationCreator';
// AccommodationModifier
//
// Modifies the Accommodation from dropdown to input once a user
// selects 'Other' from the dropdown list
class AccommodationModifier {
  constructor() {
    this.enrollment = document.querySelector('#enrollments');
    this.handleModification = this.handleModification.bind(this);
  }

  init() {
    this.enrollment.addEventListener('change', this.handleModification);
  }

  handleModification(event) {
    if (event.target.localName !== 'select') {
      return;
    }
    if (
      $(event.target)
        .children('option')
        .filter(':selected')
        .text() === 'Other'
    ) {
      this.modify(event.target);
    }
  }

  modify(target) {
    const parent = $(target).parent();
    $(target).replaceWith(this.template(target));
    const button = parent
      .find('[data-element="accommodation-button"]')
      .get()[0];
    new AccommodationCreator(button).init();
  }

  template(target) {
    return `
    <div class="row">
      <div class="col-md-10">
        <input type='text'
          placeholder='Please type the accommodation and click add.'
          class='form-control'
          data-element='accommodation-input'>
        <input type="hidden" name="${
          target.name
        }" data-element='accommodation-id-value' value="">
      </div>
      <div class="col-md-2 text-center">
        <button class="btn btn-sm btn-primary"
        data-element="accommodation-button"
        data-url="${target.dataset.url}">
          Add
        </button>
      </div>
    </div>
    `;
  }
}

export default AccommodationModifier;
