import React, { useContext, useMemo } from 'react';
import { Context } from './ExamPickerContext';
import ExamConfigurationInfo from '../ExamConfigurationInfo';

const UnconfiguredExamRow = ({ exam, checkboxStates, lmsProviderName, examProcessed, validateDetails, handleCheckboxChange, handleUnconfiguredModal }) => {
  const { configureExamEndpoint, institutionId } = useContext(Context);

  const examIdAndCategory = useMemo(() => {
    const inputValue = ''.concat(exam.id, '_', exam.quiz_category)
    return inputValue
  }, [exam]);

  const isCheckboxChecked = () => checkboxStates.includes(examIdAndCategory);

  const { isValidExam, errorMsg } = validateDetails(exam, lmsProviderName);
    const examInUse = useMemo(() => examProcessed.includes(exam.id));

  return (
    <tr>
      <td className="lms-left-column">
        <input
          type="checkbox"
          className="mr-2 unconfigured-checkbox"
          id={examIdAndCategory}
          name={examIdAndCategory}
          checked={isCheckboxChecked()}
          onChange={() => handleCheckboxChange(examIdAndCategory)}
          disabled={!isValidExam || examInUse}
        />
        {exam.exam_name}
      </td>

      <td className="lms-right-column">
        <form
          id="new_wizards_form_objects_proctor_config"
          action={configureExamEndpoint}
          method="post"
          className='mb-0'
        >
          <input type="hidden" name="_method" value="put" />

          <input type="hidden" name="authenticity_token" id="authenticity_token" value={document.querySelector('meta[name="csrf-token"]')?.content || ''} />

          <input type="hidden" value="proctoring_settings" name="wizards_form_objects_proctor_config[current_step]" id="wizards_form_objects_proctor_config_current_step" />

          <input type="hidden" value={institutionId || ''} name="wizards_form_objects_proctor_config[institution_id]" id="wizards_form_objects_proctor_config_institution_id" />

          {Object.keys(exam).filter(k => k !== 'id').map(k => (
            <input type="hidden"
              value={exam[k] || ''}
              name={`wizards_form_objects_proctor_config[${k}]`}
              id={`wizards_form_objects_proctor_config_${k}`}
              key={k}
            />
          ))}
            <ExamConfigurationInfo errorMsg={errorMsg} examInUse={examInUse} examUrl={exam.iteration_exam_url} handleUnConfiguredExamClick={() => handleUnconfiguredModal(exam)} />
        </form>
      </td>
    </tr>
  );
};

export default UnconfiguredExamRow;
