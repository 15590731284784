import csrfToken from '../../../src/utils/csrf';

class TestDeliveryManagement {
  constructor() {
    this.testDeliveryManagementDiv = document.querySelector(
      '[data-behavior="test-delivery-management-links"]',
    );
    this.pauseExamButton = document.querySelector(
      '[data-behavior="pause-exam-btn"]',
    );
    this.resumeExamButton = document.querySelector(
      '[data-behavior="resume-exam-btn"]',
    );
    this.voidExamButton = document.querySelector(
      '[data-behavior="void-exam-btn"]',
    );

    this.responseMessageSpan = document.querySelector(
      '[data-behavior="test-delivery-management-response-msg"]',
    );

    this.handlePauseExamButtonClick =
      this.handlePauseExamButtonClick.bind(this);
    this.handleResumeExamButtonClick =
      this.handleResumeExamButtonClick.bind(this);
    this.handleVoidExamButtonClick = this.handleVoidExamButtonClick.bind(this);
  }

  init() {
    if (!this.testDeliveryManagementDiv) {
      return;
    }

    this.testDeliveryToken = this.testDeliveryManagementDiv.dataset.token;
    this.testDeliveryPlatform = this.testDeliveryManagementDiv.dataset.platform;
    this.testDeliveryRequestVerb =
      this.testDeliveryManagementDiv.dataset.requestVerb;
    this.testDeliveryRequestHeaders =
      this.testDeliveryManagementDiv.dataset.requestHeaders;
    this.fulfillmentId = this.testDeliveryManagementDiv.dataset.fulfillmentId;
    this.testDeliveryPayload = this.testDeliveryManagementDiv.dataset.payload;

    const headers = {
      'Content-Type': 'application/json',
    };
    Object.assign(headers, this.constructAuthorization());
    Object.assign(headers, this.parseTestDeliveryRequestHeaders());

    let requestBody;
    if (this.testDeliveryPayload) {
      requestBody = JSON.stringify(JSON.parse(this.testDeliveryPayload));
    }

    this.requestConfig = {
      method: this.getTestDeliveryRequestVerb(),
      headers: headers,
    };

    if (requestBody) this.requestConfig.body = requestBody;

    this.bindEventListeners();
  }

  parseTestDeliveryRequestHeaders() {
    if (this.testDeliveryRequestHeaders) {
      return JSON.parse(this.testDeliveryRequestHeaders);
    } else {
      return {};
    }
  }

  getTestDeliveryRequestVerb() {
    return this.testDeliveryRequestVerb || 'PUT';
  }

  buildRequestBodyFromEvent(event) {
    switch (this.testDeliveryPlatform) {
      case 'scorpion':
      case 'surpass':
        const event_body = {
          event: event,
          fulfillment_id: this.fulfillmentId,
        };
        this.requestConfig.body = JSON.stringify(event_body);
        break;
      default:
    }
  }

  constructAuthorization() {
    if (this.testDeliveryPlatform === 'cirrus') {
      return { authorization: `EAPI ${this.testDeliveryToken}` };
    } else {
      return {};
    }
  }

  bindEventListeners() {
    if (this.pauseExamButton) {
      this.pauseExamButton.addEventListener(
        'click',
        this.handlePauseExamButtonClick,
      );
    }
    if (this.resumeExamButton) {
      this.resumeExamButton.addEventListener(
        'click',
        this.handleResumeExamButtonClick,
      );
    }
    if (this.voidExamButton) {
      this.voidExamButton.addEventListener(
        'click',
        this.handleVoidExamButtonClick,
      );
    }
  }

  getRequestConfig(event) {
    const useTestDeliveryProxy =
      event.target.dataset.useTestDeliveryProxy === 'true';

    return useTestDeliveryProxy
      ? {
          method: 'POST',
          credentials: 'same-origin',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken(),
          },
        }
      : this.requestConfig;
  }

  handlePauseExamButtonClick(event) {
    event.preventDefault();

    const url = event.target.dataset.url;

    this.buildRequestBodyFromEvent('pause');
    const requestParams = this.getRequestConfig(event);

    event.target.disabled = true;
    fetch(url, requestParams)
      .then((response) => this.processResponseErrors(response))
      .then((response) => {
        if (response.ok) {
          this.responseMessage('Exam paused.', 'success');
        } else {
          this.responseMessage(this.errorMessage, 'danger');
        }
      })
      .catch((error) => {
        this.responseMessage(error.toString(), 'danger');
      })
      .finally(() => {
        event.target.disabled = false;
      });
  }

  handleResumeExamButtonClick(event) {
    event.preventDefault();

    const url = event.target.dataset.url;

    this.buildRequestBodyFromEvent('resume');
    const requestParams = this.getRequestConfig(event);

    event.target.disabled = true;
    fetch(url, requestParams)
      .then((response) => this.processResponseErrors(response))
      .then((response) => {
        if (response.ok) {
          this.responseMessage('Exam resumed.', 'success');
        } else {
          this.responseMessage(this.errorMessage, 'danger');
        }
      })
      .catch((error) => {
        this.responseMessage(error.toString(), 'danger');
      })
      .finally(() => {
        event.target.disabled = false;
      });
  }

  handleVoidExamButtonClick(event) {
    event.preventDefault();

    const url = event.target.dataset.url;

    this.buildRequestBodyFromEvent('suspend');

    const confirmationMessage =
      'You are about to void the fulfillment. Proceed?';
    const userConfirmation = confirm(confirmationMessage);

    if (!userConfirmation) return;

    const requestParams = this.getRequestConfig(event);

    event.target.disabled = true;
    fetch(url, requestParams)
      .then((response) => this.processResponseErrors(response))
      .then((response) => {
        if (response.ok) {
          this.responseMessage('Exam voided.', 'success');
          this.voidFulfillment();
        } else {
          this.responseMessage(this.errorMessage, 'danger');
        }
      })
      .catch((error) => this.responseMessage(error.toString(), 'danger'))
      .finally(() => (event.target.disabled = false));
  }

  responseMessage(msg, type) {
    // insert success or failure message
    this.responseMessageSpan.innerHTML = `<code class= 'text-${type}'>${msg}</code>`;
  }

  voidFulfillment() {
    const fulfillmentId = this.voidExamButton.dataset.id;
    const url = `/fulfillments/${fulfillmentId}/void`;
    fetch(url, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken(),
      },
    }).then((response) => {
      if (!response.ok) {
        this.responseMessage('Failed to update fulfillment', 'danger');
      }
    });
  }

  processResponseErrors(response) {
    if (response.ok) {
      return new Promise((resolve, reject) => {
        resolve(response);
      });
    }

    return new Promise((resolve, reject) => {
      switch (response.status) {
        case 401:
          this.errorMessage = 'Error: Unauthorized';
          resolve(response);
        case 403:
          this.errorMessage = 'Error: Forbidden';
          resolve(response);
        case 404:
          this.errorMessage = 'Error: Exam/Test-taker not found';
          resolve(response);
        case 500:
          this.errorMessage = 'Server Error';
          resolve(response);
        default:
          this.errorMessage = `Error: The API call to ${this.testDeliveryPlatform} did not succeed.`;
          if (typeof response.text === 'function') {
            response
              .text()
              .then((text) => {
                this.errorMessage = `Response Code: ${response.status}.\n ${text}`;
              })
              .then(() => resolve(response));
          } else {
            resolve(response);
          }
      }
    });
  }
}

export default TestDeliveryManagement;
