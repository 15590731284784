import React from 'react';

/**
 * @typedef DownloadGuardianExtensionProps
 * @property {string} guardianExtensionUrl
 */

/**
 * @param {DownloadGuardianExtensionProps} props
 * @returns {React.ReactElement}
 */
export default function DownloadGuardianExtension(props) {
  return (
    <div>
      <h3 className="fs-5 card-title mb-0">
        <a href={props.guardianExtensionUrl} target="_blank">
          <i className="fa-solid fa-download mr-2"></i>
          {polyglot.t('order_receipt_download_guardian_extension')}
        </a>
      </h3>
      <p className="card-text">
        {polyglot.t('order_receipt_guardian_extension_description')}
      </p>
    </div>
  );
}
