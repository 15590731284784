class TestDriverAction {
  init() {
    this.toggleTextBoxDisplay(
      '[data-behavior="generic_proctor_controls_start"]',
      '[data-behavior="generic_proctor_controls_start_exam_path"]',
    );

    this.toggleTextBoxDisplay(
      '[data-behavior="generic_proctor_controls_stop"]',
      '[data-behavior="generic_proctor_controls_stop_exam_path"]',
    );

    this.toggleTextBoxDisplay(
      '[data-behavior="generic_proctor_controls_pause"]',
      '[data-behavior="generic_proctor_controls_pause_exam_path"]',
    );

    this.toggleTextBoxDisplay(
      '[data-behavior="generic_proctor_controls_resume"]',
      '[data-behavior="generic_proctor_controls_resume_exam_path"]',
    );

    this.toggleTextBoxDisplay(
      '[data-behavior="generic_proctor_controls_terminate"]',
      '[data-behavior="generic_proctor_controls_terminate_exam_path"]',
    );

    this.toggleTokenAuthentication('[data-behavior="token_authentication"]');
  }

  toggleTextBoxDisplay(checkboxSelector, textBoxSelector) {
    const checkbox = document.querySelector(checkboxSelector);
    const textbox = document.querySelector(textBoxSelector);

    if (checkbox) {
      textbox.style.display = checkbox.checked ? '' : 'none';
      checkbox.addEventListener('change', function () {
        textbox.style.display = this.checked ? '' : 'none';
      });
    }
  }

  toggleTokenAuthentication(checkboxSelector) {
    const checkbox = document.querySelector(checkboxSelector);
    if (checkbox) {
      this.setElementVisibility(checkbox.checked);
      checkbox.addEventListener(
        'change',
        function () {
          const isChecked = checkbox.checked;
          this.setElementVisibility(isChecked);
        }.bind(this),
      );
    }
  }

  setElementVisibility(isVisible) {
    const authentication_token = document.querySelector(
      '.proctor_controls_authentication_token',
    );
    if (authentication_token) {
      authentication_token.style.display = isVisible ? 'none' : '';
    }

    const elementSelectors = [
      '.client_id',
      '.client_secret',
      '.oauth_token_url',
      '.token_scope',
    ];
    const displayStyle = isVisible ? '' : 'none';
    elementSelectors.forEach((selector) => {
      const element = document.querySelector(selector);
      if (element) {
        element.style.display = displayStyle;
      }
    });
  }
}

export default TestDriverAction;
