import React from 'react';
import PropTypes from 'prop-types';

const MediaVideos = ({ allMedia, permission, images, target}) => {
  let x = 0;
  let resourceLabel = target;
  let mediaObjectData;
  resourceLabel = target  == 'combined_screens' ? 'combined Videos' : target;
  const mediaLabel = resourceLabel[0].toUpperCase() + resourceLabel.slice(1,resourceLabel.length-1)
  return (
    <div>
       {target == 'recordings' && <h5>Recordings (newest to oldest)</h5>}
      <ul>
        {allMedia.map((mediaObj, mediaIndex) => {
          if(target == 'videos'){
            mediaObjectData = mediaObj.videos
          }else if(target == 'combined_screens'){
            mediaObjectData = mediaObj.combined_screens
          }
          else if(target == 'recordings'){
            mediaObjectData = mediaObj.recordings
          }
          return mediaObjectData.map((vidObj, vidIndex) => {
            if (!vidObj.hasOwnProperty('waiting')) {
              x += 1;
              return (
                <li className="m-1" key={`${mediaIndex}_${vidIndex}`}>
                  {`${polyglot.t('media_video')} ${x}`}{' '}
                  <a href={vidObj.url} className="btn btn-primary btn-xs">
                    {polyglot.t('download')}
                  </a>
                </li>
              );
            } else {
              if (vidObj.data.url) {
                x += 1;
                return (
                  <li className="m-1" key={`${mediaIndex}_${vidIndex}`}>
                    <div className="media d-block">
                      <video
                        className="mb-3 video-player"
                        width="100%"
                        crossOrigin="anonymous"
                        preload="auto"
                        controls
                      >
                        <source src={vidObj.data.url} type="video/webm" />
                        <track
                          label="English"
                          kind="captions"
                          src={vidObj.data.track_url}
                          srcLang="en"
                          default
                        />
                      </video>
                      <a href={vidObj.data.download_url} download>
                        {polyglot.t('download_video_timeline_data')}
                      </a>
                    </div>
                  </li>
                );
              } else if (vidObj.waiting) {
                x += 1;
                return (
                  <li className="m-1" key={`${mediaIndex}_${vidIndex}`}>
                    {polyglot.t('media_request', { resource: resourceLabel })}
                  </li>
                );
              } else if (vidObj.enabled) {
                x += 1;
                return (
                  <li
                    className="m-1"
                    id={`${target}_${mediaIndex}_${vidIndex}`}
                    key={`${mediaIndex}_${vidIndex}`}
                  >
                    <a
                      data-target = {target}
                      data-uuid={mediaObj.fulfillment_uuid}
                      data-element={`${target}_${mediaIndex}_${vidIndex}`}
                      className="request-resource btn btn-secondary"
                    >
                      {`${polyglot.t('request_resource', {
                        resource: mediaLabel,
                      })} ${x}`}
                    </a>
                  </li>
                );
              }
            }
          });
        })}
      </ul>
      {allMedia.length == 0 || x == 0 ? (
        <p className="mb-0">
          {polyglot.t('view_media_not_available', { resource: resourceLabel })}
        </p>
      ) : (
        <div>
          {target != 'combined_screens' && <div id="video-converter-text"></div>}
        </div>
      )}
    </div>
  );
};

MediaVideos.propTypes = {
  allMedia: PropTypes.array.isRequired,
  permission: PropTypes.string.isRequired,
  images: PropTypes.array.isRequired,
  target: PropTypes.string.isRequired,
};

export default MediaVideos;
