import Webcam from 'webcamjs';

class WebcamInitializer {
  init() {
    if (!this.isWebcamPage()) {
      return;
    }
    this.defineElements();
    this.initializeWebcam();
    this.bindEventListeners();
  }

  teardown() {
    if (!Webcam || !Webcam.loaded) {
      return;
    }
    Webcam.off('live');
    Webcam.off('error');
    Webcam.reset();
  }

  defineElements() {
    this.app = document.querySelector('.js-users-webcam-app');
    this.snapshotPreview = $('.js-user-webcam-snapshot-preview');
    this.snapshotButton = $('.js-user-webcam-take-snapshot');
    this.webcamContainer = $('.js-user-webcam-picture');
    this.uploadContainer = $('.js-user-webcam-upload');
    this.snapshotView = $('.js-user-webcam-snapshot-view');
    this.snapshotImage = $('.js-user-webcam-snapshot-image');
    this.switchButton = $('.js-user-webcam-switch');
    this.imageUrl = $('#user_pictures_attributes_0_image_url');
    this.image = $('#user_pictures_attributes_0_image');
  }

  bindEventListeners() {
    this.snapshotButton.unbind('click').on('click', e => this.snapshot(e));

    this.switchButton
      .unbind('click')
      .on('click', e => this.toggleCameraAndUpload(e));

    this.image.unbind('change').on('change', e => this.togglePreviewSource(e));

    if (this.reRenderButton) {
      this.reRenderButton.unbind('click').on('click', e => this.reRenderWebcam(e));
    }

    const teardownWrapper = () => {
      this.teardown();
      window.removeEventListener('turbolinks:before-render', teardownWrapper);
    }
    window.addEventListener('turbolinks:before-render', teardownWrapper);
  }

  // Initialize the Webcam.js
  initializeWebcam() {
    // Safari Private Browser doesn't allow for the HTML5 localStorage
    // feature to be used. Webcam.js depends on localStorage. So, if the user
    // is Safari on Private Browsing, remove the Webcam app elements and
    // add an alert.
    if (!window.localStorage) {
      const userAgent = navigator.userAgent.toLowerCase();

      if (userAgent.indexOf('safari') > -1) {
        const $alert = $(document.createElement('div'));
        $alert.addClass('alert alert-danger alert-form');
        $alert.text('To use the Webcam, please leave Private Browsing mode.');
        this.app.html($alert);
        return;
      }
    }

    Webcam.set({
      image_format: 'jpg',
      height: 240,
      width: 320
    });
    Webcam.setSWFLocation('/flash/webcam.swf');
    Webcam.attach('.js-user-webcam-snapshot-preview');
    Webcam.on('error', () => this.toggleCameraAndUpload());
    Webcam.on('live', () => this.snapshotButton.removeClass('disabled'));
  }

  // Toggle whether the form shows the Camera, or the file upload.
  toggleCameraAndUpload(e) {
    if (e) {
      e.preventDefault;
    }
    if ($('.js-webcam-well').length) {
      return;
    }
    this.webcamContainer.toggleClass('d-none');
    this.uploadContainer.toggleClass('d-none');

    if (this.uploadContainer.length) {
      return;
    }
    const wellTemplate = `<div class="well text-center js-webcam-well">
                            <h5 class="mb-4">
                              You have denied ProctorU access to your webcam.
                              Please click the button below to enable access to your webcam
                              to take your profile picture for our service.
                            </h5>

                            <button type="button" class="btn btn-secondary btn-sm js-webcam-render">
                              Enable Webcam
                            </button>
                          </div>`;
    $(this.app).append(wellTemplate);
    this.reRenderButton = $('.js-webcam-render');
    this.bindEventListeners();
  }

  // Take Snapshot
  snapshot(e) {
    e.preventDefault();

    Webcam.snap(dataUrl => {
      // returns true when error loading camera (parental control disables camera)
      if (dataUrl === true) {
        return;
      }
      this.snapshotView.removeClass('d-none');
      this.snapshotImage.attr('src', dataUrl);
      this.imageUrl.val(dataUrl);
      this.image.replaceWith(this.image.clone());
    });
  }

  togglePreviewSource(e) {
    const target = e.currentTarget;
    if (!target.files || !target.files[0]) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(target.files[0]);
    reader.onload = e => this.snapshotImage.attr('src', reader.result);
    this.snapshotView.removeClass('d-none');
    this.imageUrl.val('');
  }

  reRenderWebcam(e) {
    e.preventDefault();

    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf('chrome') > -1) {
      const template = `<hr>
                        <p class="text-muted mb-0">
                          In appears you are using the Chrome browser. To enable your webcam,
                          click the video icon to the right in your Chrome search bar. Then allow access
                          to this webpage and refresh your browser.
                        </p>`;
      this.reRenderButton.replaceWith(template);
      return;
    }

    this.webcamContainer.toggleClass('d-none');
    $('.js-webcam-well').remove();
  }

  isWebcamPage() {
    return document.querySelector('.js-users-webcam-app');
  }
}

export default WebcamInitializer;
