class DepartmentSelect2 {
  init() {
    $('.user-form-department-select').select2({
      placeholder: $(this).data('placeholder'),
      theme: 'bootstrap4',
      delay: 500,
      ajax: {
        url: $('.user-form-department-select').data('source'),
        dataType: 'json',
        data: function(params) {
          // depending on the form, grab the institutionId
          let institutionId = $(this).parents('#institution-fields').find('.js-institution-id').val()
          if (institutionId == null) {institutionId = $(this).parents('.enrollment-fields').find('.js-institution-id').val()}
          // if the user in question already belongs to an institution,
          // use the value embedded in the department form
          if (institutionId == null) {institutionId = $(this).data('institutionId')}
          return {
            id: $(this).data('userId'),
            institution_id: institutionId
          }
        },
        processResults: function(data) { return { results: data } }
      },
      allowClear: true
    });
    this.handleChange()
  }

  handleChange() {
    var switch_selection_by_text;

    $('.user-form-department-select').on('change', function(e) {
      var $departmentSelect, $roleSelect, centralized, institutionCentralized, role;
      $departmentSelect = $(this);
      $roleSelect = $('#user_role_id');
      role = $roleSelect.find('option:selected').text();
      centralized = $departmentSelect.select2('data').centralized;
      institutionCentralized = $('.user-form-institution-select').select2('data').centralized;
      if (centralized && !institutionCentralized && role === 'Institution instructor') {
        if (confirm('This institution is centralized. Change the role to instructor observer?')) {
          return switch_selection_by_text($roleSelect, 'Institution instructor observer');
        }
      } else if (!centralized && institutionCentralized && role === 'Institution instructor observer') {
        if (confirm('This institution is decentralized. Change the role to instructor?')) {
          return switch_selection_by_text($roleSelect, 'Institution instructor');
        }
      }
    });

    switch_selection_by_text = function(select, text) {
      return select.children().filter(function() {
        return $(this).text() === text;
      }).prop('selected', true);
    };
  }
}

export default DepartmentSelect2;
