class Cost {
  constructor() {
    this.iterationTypeSelector =
      document.querySelector('[data-element="iteration-types"]') ||
      document.querySelector('[data-element="iteration-type-hidden"]');

    this.iterationDepartment = document.querySelector('[data-element="iteration-department"]');
  }

  init() {
    if (!this.hasCost()) {
      this.setCost()
    }
  }

  hasCost() {
    const costEl = document.querySelector('[name="iteration[cost_id]"]');
    if (!costEl) return false;
    return costEl.value == '' ? false : true;
  }

  setCost() {
    let department = this.iterationDepartment;
    let selectedDepartment = department.options[department.selectedIndex];
    let examTypeSelected = null;
    if (document.querySelector('[data-staff]').dataset.staff === 'false') {
      examTypeSelected = this.iterationTypeSelector.value
    } else {
      let examType = this.iterationTypeSelector;
      examTypeSelected = examType.options[examType.selectedIndex].value;
    }
    this.updateCost(selectedDepartment, examTypeSelected)
  }

  updateCost(selectedDepartment, examTypeSelected) {
    let costId = null;
    if (examTypeSelected === 'Iteration::Automatic') {
      costId = selectedDepartment.getAttribute('data-auto-cost');
    } else {
      costId = selectedDepartment.getAttribute('data-default-cost');
    }
    document.querySelector('#iteration_cost_id').value = costId;
  }
}

export default Cost;
