const PrechecksHeaderIcon = ({ icon, iconColor, iconPrefix, assetIcon }) => {
  const iconElement = (icon, iconColor, iconPrefix, assetIcon) => {
    return assetIcon !== undefined &&
      'altText' in assetIcon &&
      'src' in assetIcon ? (
      <img
        alt={assetIcon.altText}
        className="d-block mx-auto mb-4"
        aria-hidden="true"
        width="40"
        src={assetIcon.src}
      />
    ) : (
      <i
        className={`${iconPrefix} ${icon} fa-3x text-${iconColor}`}
        aria-hidden="true"
      />
    );
  };

  return (
    <div className="row">
      <div className="col-md-12">
        {iconElement(icon, iconColor, iconPrefix, assetIcon)}
      </div>
    </div>
  );
};

PrechecksHeaderIcon.propTypes = {
  icon: PropTypes.string.isRequired,
};

PrechecksHeaderIcon.defaultProps = {
  icon: 'fa-circle-check',
  iconColor: 'primary',
  iconPrefix: 'fal',
  assetIcon: undefined,
};

export default PrechecksHeaderIcon;
