import axios from 'axios'
import Toast from '../utils/Toast';
import csrfToken from '../utils/csrf';

class Notifications {
  constructor() {
    this.selector = document.querySelector('.js-user-notification-checkbox');
    this.loader = document.querySelector('.js-subscribe-loader');
  }

  init() {
    this.bindEventListeners();
  }

  bindEventListeners() {
    this.selector.addEventListener('change', (event) => { this.handleChange(event) });
  }

  handleChange(e) {
    e.preventDefault();
    this.toggleLoader('show');
    const headers = {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken()
      }
    };
    axios.post(this.selector.closest('form').dataset.url, JSON.stringify({ preference: this.selector.getAttribute('name'), value: this.selector.checked }), headers)
    .then(() => {
      this.toggleLoader('hide')
    })
    .then(response => {
      new Toast().success({message: 'Notification settings saved.'});
    })
    .catch(error => {
      new Toast().danger({message: 'Unable to save notification settings.'});
    });
  }

  toggleLoader(action) {
    if (action == 'show') {
      this.loader.classList.remove('d-none');
    } else {
      this.loader.classList.add('d-none');
    }
  }
}

export default Notifications;
