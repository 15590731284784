class LostFocusAllowedResources {
  constructor() {
    this.lostFocusAllowedResource = document.querySelectorAll(
      '[data-element="lost-focus-allowed-resource"]'
    );
    this.iterationLostFocus = document.querySelector('#iteration_lost_focus');
    this.iterationPresetHigh = document.querySelector('#iteration_preset_high');
    this.iterationPresetCustom = document.querySelector('#iteration_preset_custom');
  }

  bindEventListeners() {
    if (this.lostFocusAllowedResource.length > 0){
      for (let i = 0; i < this.lostFocusAllowedResource.length; i++) {
        this.lostFocusAllowedResource[i].addEventListener(
          'change',
          this.onLostFocusAllowedResourcesChange.bind(this)
        );
      }
    };
    if (this.iterationLostFocus) {
      this.iterationLostFocus.addEventListener(
        'change',
        this.onIterationLostFocusChange.bind(this)
      );
    };
  }

  init() {
    this.bindEventListeners();
  }

  // Toggle Lost Focus option to Allowed, when one of lost-focus-allowed-resource is checked
  // If current value of lost focus isnt Allowed, preset option changed to Custom
  //
  onLostFocusAllowedResourcesChange() {
    $('.tooltip').remove()
    if (!this.iterationPresetHigh.checked){
      this.iterationPresetCustom.checked = true
      this.iterationLostFocus.value = 'allowed'
    }
  }

  // Uncheck all the lost-focus-allowed-resource, if Lost Focus option changed to Restricted
  //
  onIterationLostFocusChange() {
    $('.tooltip').remove()
    if (this.iterationLostFocus.value != 'allowed') {
      this.lostFocusAllowedResource.forEach(function(element) {
        element.checked = false
        $(element).closest('.checkbox').removeClass('selected')
      });
    }
  }
}

export default LostFocusAllowedResources;
