import React, { useEffect } from 'react';

import CollapsibleCard from './CollapsibleCard';
import TestDeliveryManagement from './TestDeliveryManagement';
import TestDeliveryButton from './TestDeliveryButton';

/**
 * @typedef {Object} ExamLink
 * @property {string} buttonText - The text to display on the button.
 * @property {string} buttonLink - The URL the button links to.
 */

/**
 * @typedef {Object} TestDriverPlatformProps
 * @property {boolean} isWatcherWindow - Indicates if it is a watcher window.
 * @property {string} platform - The platform name.
 * @property {string} token - The token for authentication.
 * @property {string} testDeliveryPayload - The payload for test delivery.
 * @property {string} requestVerb - The HTTP request verb.
 * @property {string} fulfillmentId - The fulfillment ID.
 * @property {Object} requestHeaders - The headers for the request.
 * @property {boolean} useTestDeliveryProxy - Indicates if a test delivery proxy should be used.
 * @property {Object<string, ExamLink>} examLinks - The links for the exam actions.
 * @property {string} fulfillmentStatus - The status of the fulfillment.
 * @property {string} proctorLink - The link for proctor when buttons not functioning
 */
const TestDriverPlatform = ({
  isWatcherWindow,
  platform,
  token,
  testDeliveryPayload,
  requestVerb,
  fulfillmentId,
  requestHeaders,
  useTestDeliveryProxy,
  examLinks = {},
  fulfillmentStatus,
  proctorLink,
}) => {
  useEffect(() => {
    const testDeliveryManagement = new TestDeliveryManagement();
    testDeliveryManagement.init();
  }, []);

  const renderButtons = () => {
    return Object.keys(examLinks).map((linkKey) => (
      <TestDeliveryButton
        key={linkKey}
        buttonClass={
          linkKey === 'resume-exam-btn'
            ? 'btn-success'
            : linkKey === 'pause-exam-btn'
              ? 'btn-warning'
              : 'btn-danger'
        }
        link={examLinks[linkKey].buttonLink}
        buttonAction={linkKey}
        useTestDeliveryProxy={useTestDeliveryProxy}
        id={fulfillmentId}
        disabled={fulfillmentStatus !== 'running'}
        buttonText={examLinks[linkKey].buttonText}
      />
    ));
  };

  return (
    <div
      data-behavior="test-delivery-management-links"
      data-platform={platform}
      data-token={token}
      data-payload={testDeliveryPayload}
      data-request-verb={requestVerb}
      data-fulfillment-id={fulfillmentId}
      data-request-headers={requestHeaders}
    >
      {isWatcherWindow ? (
        <div>
          <div className="card-body">{renderButtons()}</div>
          <br />
          <span data-behavior="test-delivery-management-response-msg"></span>
        </div>
      ) : (
        <CollapsibleCard headerText="Test Driver Actions">
          <div className="card-body">
            {renderButtons()}
            <br />
            {proctorLink && (
              <a href={proctorLink} target="_blank">
                Click here if the pause, resume, or void button is not
                functioning.
              </a>
            )}
            <br />
            <span data-behavior="test-delivery-management-response-msg"></span>
          </div>
        </CollapsibleCard>
      )}
    </div>
  );
};

export default TestDriverPlatform;
