import PropTypes from 'prop-types'

class IncidentReportExportButton extends React.Component {
  handleClick = () => {
    if (this.props.buttonDisabled) {
      return false;
    }
    this.props.handleExport();
  }

  render() {
    return (
      <button type="button" className="btn btn-secondary ml-2" onClick={this.handleClick} disabled={this.props.buttonDisabled}>
        Export
      </button>
    )
  }
}

IncidentReportExportButton.propTypes = {
  handleExport: PropTypes.func,
  buttonDisabled: PropTypes.bool
}

export default IncidentReportExportButton;
