import AccessCodeMessage from './AccessCodeMessage';

class ValidateAccessCode {
  constructor(name) {
    this.input = document.querySelector('.js-student-access-code-component input');
    this.message = new AccessCodeMessage(name)
  }

  init() {
    if(this.input.value === '') {
      this.message.toast('required');
      this.input.focus();
      return false
    } else {
      return true
    }
  }
}

export default ValidateAccessCode;
