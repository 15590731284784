class DetectImageBrightness {
  constructor() {
    this.imageSrc = document.querySelector('js-detect').getAttribute('src');
  }

  init() {
    let newImage = document.createElement('img');
    newImage.src = this.image;
    newImage.style.display = 'none';
    newImage.crossOrigin = 'Anonymous';
    document.body.appendChild(newImage);
    newImage.addEventListener('load', (event) => {
      imageBrightness = this.getImageBrightness(event);
      this.updateImageAlert(brightness)
    })
  }

  getImageBrightness(event) {
    const canvas = document.createElement('canvas');
    canvas.width = event.target.width;
    canvas.height = event.target.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(event.target, 0, 0);
    const imageData = ctx.getImageData(0, 0, event.target.width, event.target.height);
    const data = imageData.data;
    let x = 0;
    let colorSum = 0;
    const len = data.length;
    while (x < len) {
      const r = data[x];
      const g = data[x + 1];
      const b = data[x + 2];
      const avg = Math.floor((r + g + b) / 3);
      colorSum = colorSum + avg;
      x += 4;
    }
    return Math.floor(colorSum / (event.target.width * event.target.height));
  }

  updateImageAlert(brightness) {
    const wrapper = document.querySelector('.js-photo-prompt-wrapper');
    let warn = false;
    let text = '';
    if (document.querySelector('js-detect').dataset.picture === false) {
      warn = true;
      text = 'Please upload a photo of the test-taker';
    } else if (brightness < 50) {
      warn = true;
      text = 'It appears the uploaded photo of the test-taker is too dark.';
    } else if (brightness > 240) {
      warn = true;
      text = 'It appears the uploaded photo of the test-taker is too light.';
    } else {
      text = 'Does the test-taker need a new photo?';
    }
    if (warn) {
      wrapper.querySelector('.media-heading').classList.add('text-danger');
    }
    wrapper.querySelector('p').textContent = text;
  }
}

export default DetectImageBrightness;
