class Select2AdminContanct {
  constructor() {
    this.adminContactable = $('#admin-contactable');
    this.adminContacts = JSON.parse(document.querySelector('.js-incident-admin-contacts').innerHTML);
  }

  init() {
    this.adminContactable.select2({
      placeholder: 'Admin Contacts',
      tags: true,
      tokenSeparators: [',', ' '],
      allowClear: true,
      theme: 'bootstrap4',
      ajax: {
        url: '/incident-users.json',
        dataType: 'json',
        data: function() {
          return {
            search: {
              contactable: true,
              incident_report_id: parseInt($('.js-incident-id').html()),
              contactable_type: 'admin_contacts'
            }
          };
        },
      processResults: function(data) {
          return {
            results: data
          };
        }
      }
    });
    if (this.adminContacts.length > 0) {
      this.adminContacts.forEach(contact => {
        const option = new Option(contact.text, contact.id, false, true);
        $(this.adminContactable).append(option)
      })
      this.adminContactable.val(this.adminContacts.map(contacts => contacts.id)).trigger('change');
    }
  }
}

export default Select2AdminContanct;
