import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import AadhaarPopup from './AadhaarPopup';

const AadhaarVerification = ({
  declineBtnText,
  acceptBtnText,
  userEmail,
  userFirstName,
  userLastName,
  userId,
  institutionId,
}) => {
  const [show, setShow] = useState(false);
  const scheduleButtonRef = useRef(null);
  const onDemandSubmitButtonRef = useRef(null);
  const [accessToken, setAccessToken] = useState(null);

  const handleShowPopup = useCallback((event) => {
    const scheduleButton = event.target.closest(
      '[data-behavior="schedule-exam"]',
    );
    const onDemandSubmitButton = document.querySelector('input[name="commit"]');
    const onDemandRadioBtn = document.querySelector('#on_demand_true');

    if (scheduleButton) {
      event.preventDefault();
      scheduleButtonRef.current = scheduleButton;
      setShow(true);
    } else if (onDemandSubmitButton && onDemandRadioBtn) {
      if (onDemandRadioBtn.checked) {
        event.preventDefault();
        onDemandSubmitButtonRef.current = onDemandSubmitButton;
        setShow(true);
      }
    }
  }, []);

  useEffect(() => {
    const onDemandSubmitButton = document.querySelector('input[name="commit"]');
    const onDemandRadioBtn = document.querySelector('#on_demand_true');
    if (onDemandSubmitButton && onDemandRadioBtn) {
      onDemandSubmitButton.addEventListener('click', handleShowPopup);
    }

    // Use MutationObserver for dynamically added schedule buttons
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
          const scheduleButtons = document.querySelectorAll(
            '[data-behavior="schedule-exam"]',
          );
          scheduleButtons.forEach((button) =>
            button.addEventListener('click', handleShowPopup),
          );
        }
      }
    });

    // Start observing the document body for added nodes
    observer.observe(document.body, { childList: true, subtree: true });

    // Initial setup for already existing schedule buttons
    const initialScheduleButtons = document.querySelectorAll(
      '[data-behavior="schedule-exam"]',
    );
    initialScheduleButtons.forEach((button) =>
      button.addEventListener('click', handleShowPopup),
    );

    return () => {
      // Cleanup event listeners for onDemand elements
      if (onDemandSubmitButton) {
        onDemandSubmitButton.removeEventListener('click', handleShowPopup);
      }

      // Cleanup MutationObserver and event listeners for schedule buttons
      observer.disconnect();
      const allScheduleButtons = document.querySelectorAll(
        '[data-behavior="schedule-exam"]',
      );
      allScheduleButtons.forEach((button) =>
        button.removeEventListener('click', handleShowPopup),
      );
    };
  }, [handleShowPopup]);

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const response = await axios.get(
          `${window.location.origin}/api/hyperverge/token`,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );

        if (response.status === 200) {
          setAccessToken(response.data?.data);
        } else {
          console.error(response);
        }
      } catch (error) {
        console.error(
          'Error during Hyperverge Token request:',
          error.response ? error.response.data : error.message,
        );
      }
    };

    getAccessToken();
  }, []);

  const handleClose = () => {
    handleResponse({
      user_id: userId,
      user_consent: false,
    });
    setShow(false);
  };

  const handleConsent = () => {
    // make call Hyperverge
    startExamScheduleWorkflow();
    setShow(false);
  };

  const submitForm = () => {
    if (scheduleButtonRef.current) {
      scheduleButtonRef.current.form.submit();
    } else if (onDemandSubmitButtonRef.current) {
      onDemandSubmitButtonRef.current.form.submit();
    }
  };

  const examScheduleWorkflowCallback = (res) => {
    console.log(res);
    const payload = mapSdkResponseToPayload(res);
    handleResponse(payload);
  };

  const startExamScheduleWorkflow = () => {
    const hyperKycConfig = new window.HyperKycConfig(
      accessToken,
      'exam_schedule_workflow',
      `${userId}`,
    );

    const customInputs = {
      name: `${userFirstName} ${userLastName}`,
      email: userEmail,
    };

    hyperKycConfig.setInputs(customInputs);
    window.HyperKYCModule.launch(hyperKycConfig, examScheduleWorkflowCallback);
  };

  /**
   * Maps the SDK response to the payload structure required by the backend.
   * @param {Object} sdkResponse - The response object from the SDK.
   * @returns {Object} - The mapped payload object.
   */
  const mapSdkResponseToPayload = (sdkResponse) => {
    const userId = Number(sdkResponse.transactionId);
    const userConsent = true;

    // Base common payload
    const commonPayload = {
      user_id: userId,
      user_consent: userConsent,
      institution_id: institutionId,
    };

    // Shared details for auto_declined and auto_approved
    const sharedDetails = {
      ocr_name: sdkResponse.details?.ocrName,
      digilocker_name: sdkResponse.details?.digilockerName,
      digilocker_address: sdkResponse.details?.digilockerAddress,
      digilocker_gender: sdkResponse.details?.digilockerGender,
      id_file_url: sdkResponse.details?.digilockerFile,
      selfie_image: sdkResponse.details?.selfieImage,
      id_image_front: sdkResponse.details?.ocrImageFront,
      id_image_back: sdkResponse.details?.ocrImageBack,
      selfie_liveness_value: sdkResponse.details?.selfieLivenessValue,
      selfie_liveness_score: sdkResponse.details?.selfieLivenessScore,
      face_match_value: sdkResponse.details?.facematchValue,
      face_match_score: sdkResponse.details?.faceMatchScore,
    };

    // Return payload based on the status
    const payload = {
      ...commonPayload,
      status: sdkResponse.status,
    };

    // Include shared details only for auto_declined and auto_approved statuses
    if (['auto_declined', 'auto_approved'].includes(sdkResponse.status)) {
      return {
        ...payload,
        ...sharedDetails,
      };
    }

    return payload;
  };

  /**
   * Submits the payload to the specified API endpoint and returns the response status.
   * @param {Object} payload - The payload to be sent.
   * @returns {Promise<string>} - The status from the API response.
   */
  const submitPayload = async (payload) => {
    try {
      const response = await axios.post(
        `${window.location.origin}/api/hyperverge/create`,
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      return response.status;
    } catch (error) {
      console.error(
        'Error during API call:',
        error.response ? error.response.data : error.message,
      );
      return 'error';
    }
  };

  const handleResponse = async (payload) => {
    const status = await submitPayload(payload);

    if (status === 200) {
      submitForm();
    } else {
      console.error('API returned an error status:', status);
    }
  };

  return (
    <>
      <AadhaarPopup
        show={show}
        onClose={handleClose}
        onConsent={handleConsent}
        declineBtnText={declineBtnText}
        acceptBtnText={acceptBtnText}
      />
    </>
  );
};

export default AadhaarVerification;
