import PropTypes from 'prop-types';

/**
 *  No timestamp component. Some of the events should be displayed without a timestamp.
 *  Just with a comment. It's a common component for them.
 */

const NoTimestamp = ({createdAt, text}) => {
  return (
    <div className="row align-items-center">
      <div className="col-11 offset-1">
        <small className="text-muted">{createdAt}</small>
        <div>
          {text}
        </div>
      </div>
    </div>
  )
}

NoTimestamp.propTypes = {
  text: PropTypes.string.isRequired,
}

export default NoTimestamp
