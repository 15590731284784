/** This will be included in the VideoPlayer.js file for updates on when the TT needs help.  */
import consumer from '../consumer'

class HelpNotification {
  init(helpNotificationShow) {
    this.helpNotificationShow = helpNotificationShow;
    this.setupChannel();
  }

  setupChannel() {
    this.examNotifications = consumer.subscriptions.create(
      {
        channel: 'FulfillmentChannel',
        uuid: window.location.pathname.split('/').pop()
      },
      {
        received: data => {
          this.handleChange(data);
        }
      }
    );
  }

  handleChange(data) {
    this.helpNotificationShow(data.subtype)
  }
}

export default HelpNotification
