class InterventionOnlyHandler {

  constructor() {
    this.enabledCheckbox = document.getElementById('io-enabled');
    this.mixedCheckbox = document.getElementById('io-mixed-resources');
    this.allCheckbox = document.getElementById('io-all-resources');
    this.noCheckbox = document.getElementById('io-no-resources');

    this.handleChecked = this.handleChecked.bind(this);
    this.handleOnload = this.handleOnload.bind(this);
  }

  init() {
    this.handleOnload();
    this.enabledCheckbox.addEventListener('change', this.handleChecked);
  }

  handleOnload() {
    this.handleChecked();
  }

  handleChecked() {
    const disableCheckbox = !this.enabledCheckbox.checked;
    this.mixedCheckbox.disabled = disableCheckbox;
    this.allCheckbox.disabled = disableCheckbox;
    this.noCheckbox.disabled = disableCheckbox;
  }

}

export default InterventionOnlyHandler;
