import moment from 'moment'
import PropTypes from 'prop-types';

class SessionInterrupted extends React.Component {
  constructor(props) {
    super(props)
  }

  replaceDateAccordingTimezone = (text, timezone) => {
    const srcFormat = 'YYYY-MM-DD HH:mm:ss'
    const outFormat = 'h:mmA on MMM D, YYYY'
    const regexp = /\d{4}\-\d{2}\-\d{2}\s\d{2}:\d{2}:\d{2}\sUTC/g

    if(!text) return

    return text.replace(regexp, (match) => {
      return moment.utc(match, srcFormat).utcOffset(timezone).format(outFormat)
    })
  }

  render() {
    const { showInPlayer, createdAt, text, timezone } = this.props

    return (
      <div className="row align-items-center">
        <div className="col-1">
          <div className="h-100 text-center d-flex align-items-center justify-content-center">
            <span className="fad fa-play-circle fa-lg player-icon cursor-pointer" onClick={showInPlayer}/>
          </div>
        </div>
        <div className="col-11">
          <small className="text-muted">{createdAt}</small>
          <div>
            {this.replaceDateAccordingTimezone(text, timezone)}
          </div>
        </div>
      </div>
    )
  }
}

SessionInterrupted.propTypes = {
  showInPlayer: PropTypes.func.isRequired,
  text: PropTypes.string,
  timezone: PropTypes.string.isRequired
}

export default SessionInterrupted
