import FulfillmentVideoHelper from "../../utils/FulfillmentVideoHelper";
class WatcherPopout {
  constructor() {
    this.buttons = document.querySelectorAll('.js-watcher-popout');
  }

  init() {
    if(this.buttons) {
      this.buttons.forEach(button => {
        button.addEventListener('click', (event) => { this.handleClick(event) });
      })
    }
  }

  handleClick(event) {
    let url = null;
    let linkElement = event.target;
    if(event.target.tagName === 'I') {
      url = event.target.parentElement.getAttribute('href');
      linkElement = event.target.parentElement;
    }
    else {
      url = event.target.getAttribute('href');
    }

    let fulfillmentUuid = url.split("/").pop();
    let proctorId = linkElement.dataset.proctor;
    let sender = 'archimedes_fulfillment_page_ww_btn';
    const fulfillmentVideoHelper = new FulfillmentVideoHelper(fulfillmentUuid, proctorId, false, null);
    let message = fulfillmentVideoHelper.constructWsMessage(sender);
    fulfillmentVideoHelper.broadcastMessage(message);
    fulfillmentVideoHelper.showPauseModel(true);
    window.open(url, '_blank', 'height=525,width=384,noopener');
    event.preventDefault();
  }
}

export default WatcherPopout;
