const UrlPusherTestTakerView = ({ proctorConnected, testTakerConnected, logo, code }) => {
  const renderNotConnected = () => {
    return (
      <div key='disconnected'>
        <span className="fs-4"><i className="fas fa-ban text-muted mr-2 fa-xs"></i>Not connected</span>
      </div>
    )
  }

  const renderConnected = () => {
    return (
      <div key='connected'>
        <span className="fs-4"><i className="fa fa-circle text-success mr-2 fa-xs"></i>Connected</span>
      </div>
    )
  }

  return (
    <div className="card" style={{marginBottom: '0px'}}>
      <div className="card-body">
        <div className="row mb-3">
          <div className="col-md-12">
            <div dangerouslySetInnerHTML={{__html: logo}} className="text-center"></div>
            <h1 className="fs-3 text-center my-3">ProctorU Redirect Service</h1>
            <p className="text-center">
              This tool allows a ProctorU support representative
               to direct you safely to the appropriate URL(s).
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6 col-md-6 text-center">
            <div className="card">
              <div className="card-body">
                <h4>Your status</h4>
                <hr />
                {testTakerConnected ? renderConnected() : renderNotConnected() }
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 text-center">
            <div className="card">
              <div className="card-body">
                <h4>Support status</h4>
                <hr />
                {proctorConnected ? renderConnected() : renderNotConnected() }
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12 text-center">
            <div className="card">
              <div className="card-body">
              { code
                ? <h2 className="h1">{ code }</h2>
                : <p className="mt-4 mb-4">Generating verification code...</p>
              }
              </div>
            </div>
            {code && <p className="text-muted mt-3">You may need to read this code for verification purposes.</p> }
          </div>
        </div>
      </div>
    </div>
  )
}

export default UrlPusherTestTakerView;
