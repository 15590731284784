import {useEffect} from 'react';
import ActionButtons from "../Shared/ActionButtons";

const EnteredIntoExamProcedure = (props) => {
	const procedure = props.procedure;
	const sendEvent = props.sendEvent;
	const handleNext = props.handleNext;
	const openModal = props.openModal;
	const closeModal = props.closeModal;
	const markProcCompleted = props.markProcCompleted;
	const getFulfillmentEvents = props.getFulfillmentEvents

	const resolveEvent = 'Event::ResolutionStartedEnteredIntoExam';

	useEffect(() => {
		getFulfillmentEvents(['Event::ProctorExamLaunchCompleted'], (response) => {
			if(response.data.events.length > 0){
				markProcCompleted(procedure.id);
			} else {
				sendEvent('Event::ProctorExamLaunchStarted', {});
			}
		})
	},[]);

	useEffect(() => {
		const completeCount = document.querySelectorAll('#flight-path .completed').length;

		if(document.querySelector('#fulfillment-state') != null) {
			let fulfillmentStatus = document.querySelector('#fulfillment-state').dataset.status;
			let awaitingVerification = document.querySelector('#fulfillment-state').dataset.awaitingVerification;
			if (completeCount > 4 && fulfillmentStatus === 'running' && awaitingVerification == 'true') {
				document.querySelector('#unlock-authentication').classList.remove('disabled');
			}
		}
	});

	const completeProcedure = () => {
		sendEvent('Event::ProctorExamLaunchCompleted', {});
		markProcCompleted(procedure.id);
		handleNext();
	};

	const resolveProcedure = () => {
		openModal();
		sendEvent(resolveEvent, {});
	}
	return(
		<>
			<ActionButtons
				resovleBtnText={'Resolve...'}
				confirmBtnText={'Examinee Entered Correct Exam'}
				confirmBtnAction={completeProcedure}
				resolveBtnAction={resolveProcedure}
				closeModal={closeModal}
			/>
		</>
	)
}

export default EnteredIntoExamProcedure;
