import CalHeatMap from 'cal-heatmap';

class ActivityStatsHeatmap {
  constructor() {
    this.COMPONENT_SELECTOR = '.js-stats-colors';
    this.$statsColors = $(this.COMPONENT_SELECTOR);
    this.legend = this.$statsColors.data('value').map(Number);
    this.$calendars = $('.activity-stats-calendar');
  }

  init() {
    this.initCalHeatmap();
  }

  initCalHeatmap() {
    _.each(this.$calendars, (calendar) => {
      const $this = $(calendar);
      let cal = new CalHeatMap();
      cal.init(this.options($this));
    });
  }

  options($calendar) {
    return {
      itemSelector: $calendar[0],
      domain: "month",
      subDomain: "x_day",
      cellSize: 38,
      cellPadding: 2,
      subDomainTextFormat: "%d",
      weekStartOnMonday: false,
      range: 1,
      displayLegend: false,
      tooltip: true,
      start: new Date($calendar.data('year'), $calendar.data('month')-1),
      data: $calendar.data('activity'),
      itemName: "exam",
      domainLabelFormat: "",
      legend: this.legend,
      subDomainTextFormat: function(date, value) {
        return value;
      },
      highlight: ["now"]
    }
  }
}

export default ActivityStatsHeatmap;
