const PrechecksBodyCamera = ({
  canvasRef,
  videoRef,
  showCanvas,
  showVideo,
  height,
  width,
}) => {
  const displayValue = (show) => {
    return show ? 'inline' : 'none';
  };

  return (
    <div className="row my-3">
      <div className="col-md-12">
        <canvas
          ref={canvasRef}
          height={height}
          width={width}
          style={{ display: displayValue(showCanvas) }}
          aria-label={'Webcam captured image'}
        />
        <div role="region" aria-label="Webcam Stream">
          <video
            className={'bg-secondary'}
            ref={videoRef}
            height={height}
            width={width}
            autoPlay
            muted
            style={{ display: displayValue(showVideo) }}
            aria-hidden={'true'}
            aria-label={'Webcam stream'}
            role={'region'}
          />
        </div>
      </div>
    </div>
  );
};

PrechecksBodyCamera.propTypes = {};

PrechecksBodyCamera.defaultProps = {};

export default PrechecksBodyCamera;
