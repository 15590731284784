import React from 'react';

const SearchInput = ({ search, items: items, onSearchChange, placeholder }) => {
  return (
    <div className="lms-search-input">
      <div className="has-search">
        <span className="fa fa-search form-control-feedback"></span>
        <input
          type="text"
          className="form-control px-3"
          placeholder={placeholder}
          value={search}
          onChange={() => {
            onSearchChange(event, items);
          }}
        />
      </div>
    </div>
  );
};

export default SearchInput;
