class LocationSelector {
  constructor() {
    this.$roles = $('#user_role_id');
    this.$selected = this.$roles.find('option:selected').attr('data-role-type');
  }

  init() {
    this.hideLocationOnPageLoad();
    this.bindEventListeners();
  }

  hideLocationOnPageLoad() {
    if (this.$selected !== 'Role::ProctorU') {
      $('#proctoru_location_id').addClass('d-none')
    }
  }

  bindEventListeners() {
    this.$roles.on('change', (event) => { this.handleRoleChange(event) });
  }

  handleRoleChange(event) {
    let $target = $(event.target).find('option:selected').data('role-type');
    if($target == 'Role::ProctorU') {
      $('#proctoru_location_id').removeClass('d-none');
      this.handleLocationLabel('add');
    } else {
      $('#proctoru_location_id').addClass('d-none');
      this.handleLocationLabel('remove');
    }
  }

  handleLocationLabel(method){
    const locationLabel = document.querySelector(
      '[data-behavior="user-location-label"]'
    );
    if (locationLabel) locationLabel.classList[method]('required');
  }
}

export default LocationSelector;
