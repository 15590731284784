class RetakePolicy {
  constructor() {
    this.checkbox = document.querySelector(
      '[data-behavior="retake-policy-checkbox"]'
    );

    this.toggleRetakePeriods = this.toggleRetakePeriods.bind(this);
  }

  get enabled() {
    return this.checkbox;
  }

  init() {
    if (this.enabled) {
      this.toggleRetakePeriods();
      this.bindEventListeners();
    }
  }

  bindEventListeners() {
    this.checkbox.addEventListener('change', this.toggleRetakePeriods);
  }

  toggleRetakePeriods() {
    if (this.checkbox.checked) {
      this.toggleGroup('block');
    } else {
      this.toggleGroup('none');
    }
  }

  toggleGroup(style) {
    const selector = document.querySelector(
      '[data-behavior="retake-policy-form"]'
    );
    selector.style.display = style;
  }
}

export default RetakePolicy;
