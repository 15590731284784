import React from 'react';

/**
 * @typedef {Object} TestDriverButtonProps
 * @property {string} buttonClass - The CSS class for the button.
 * @property {string} link - The URL the button links to.
 * @property {string} buttonAction - The action associated with the button.
 * @property {boolean} useTestDeliveryProxy - Indicates if a test delivery proxy should be used.
 * @property {string} id - The ID associated with the button.
 * @property {boolean} disabled - Indicates if the button is disabled.
 * @property {string} buttonText - The text to display on the button.
 */
const TestDriverButton = ({
  buttonClass,
  link,
  buttonAction,
  useTestDeliveryProxy,
  id,
  disabled,
  buttonText,
}) => (
  <button
    className={`btn ${buttonClass} mb-4 mt-3 mr-1`}
    data-url={link}
    data-behavior={buttonAction}
    data-use-test-delivery-proxy={useTestDeliveryProxy}
    data-id={id}
    disabled={disabled}
  >
    {buttonText}
  </button>
);

export default TestDriverButton;
