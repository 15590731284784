import isInstitution from '../../../src/incident_report_center/utils/isInstitution'

class IncidentReportTablePagination extends React.Component {
  renderPagination = () => {
    if (isInstitution()) {
      return (
        <th colSpan='3' className="text-right">
          <ul className='pagination text-right'>
            {this.renderPrev()}
            {this.renderNext()}
          </ul>
        </th>
      )
    } else {
      return (
        <th colSpan='5' className="text-right">
          <div className='pagination justify-content-end'>
            {this.renderPrev()}
            {this.renderNext()}
          </div>
        </th>
      )
    }
  }

  renderButton = (classes, rel, text) => {
    if (text === 'Next') { classes += ' ml-2' }
    return (
      <a key={text} className={classes + ' btn btn-sm btn-secondary'} rel={rel} href='#' onClick={this.props.goToPage}>{text}</a>
    )
  }
  renderPrev = () => {
    if (this.props.page > 1) {
      return this.renderButton('prev', 'prev', 'Previous');
    }
  }

  renderNext = () => {
    if (this.props.page < this.calculatePages()) {
      return this.renderButton('next', 'next', 'Next');
    }
  }

  renderPages = () => {
    var component = this;
    var currentPage = this.props.page;
    var pageCount = this.calculatePagesCount();

    return pageCount.map(function(index) {
      return component.renderLI('page', '', index);
    });
  }

  calculatePages = () => {
    return Math.ceil(this.props.itemsCount / this.props.limit)
  }

  calculatePagesCount = () => {
    var pageArray = Array.from(
      new Array(this.calculatePages()), function(x, i) { return i + 1 }
    );

    if (this.props.page % 5 == 0) {
      return pageArray.slice(this.props.page - 5, this.props.page);
    } else {
      var start = Math.floor(this.props.page / 5) * 5;
      return pageArray.slice(start, start + 5);
    }
  }

  render = () => {
    return (
      <tr className='mt-4 mb-4 pt-4 pb-4'>
        <th colSpan='5'>
          <div className='form-group'>
            <label className='mr-3'>Showing</label>
            <select className=''
              defaultValue={this.props.limit}
              onChange={this.props.changePageLimit}>
              <option value='5'>5</option>
              <option value='10'>10</option>
              <option value='15'>15</option>
              <option value='20'>20</option>
              <option value='50'>50</option>
              <option value='100'>100</option>
            </select>
            <label className='ml-3'>
              of {this.props.itemsCount} results
            </label>
          </div>
        </th>
        {this.renderPagination()}
      </tr>
    )
  }
}

IncidentReportTablePagination.propTypes = {
  id: PropTypes.number.isRequired,
  goToPage: PropTypes.func.isRequired,
  changePageLimit: PropTypes.func.isRequired,
  itemsCount: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired
}

export default IncidentReportTablePagination;
