import Toast from '../utils/Toast';

class LocationActivators {
  constructor() {
    this.checkBoxes = document.querySelectorAll(
      '[data-behavior="toggle-location-activated"]'
    );
    this.handleClick = this.handleClick.bind(this);
  }

  init() {
    this.bindEventListeners();
  }

  bindEventListeners() {
    this.checkBoxes.forEach(checkbox => {
      checkbox.addEventListener('click', this.handleClick);
    });
  }

  handleClick(event) {
    this.toggleEnabled(event.target);
  }

  toggleEnabled(target) {
    $.ajax({
      url: target.dataset.url,
      dataType: 'json',
      contentType: 'application/json',
      method: 'PUT',
      data: JSON.stringify({
        location: {
          enabled: target.checked
        }
      }),
      success: function() {
        new Toast().success({ message: 'Location Updated' });
      },
      error: function(response) {
        new Toast().danger({
          message:
            response.errors || 'There was a problem updating this location'
        });
      }
    });
  }
}

export default LocationActivators;
