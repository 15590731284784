class BIReportGenerator {
  init() {
    for (const reportNumber of [1, 2, 3, 4, 5, 6]) {
      this.fetchReport(reportNumber);
    }
  }

  fetchReport(reportNumber) {
    let reportContainer = document.querySelector(
      `[data-behavior="bi-report-${reportNumber}"]`,
    );

    if (reportContainer) {
      const url = reportContainer.dataset['url'];
      let img = new Image();
      img.retryCount = 0;
      img.src = url;
      img.className = 'img-fluid';

      // Tableau doesn't always have our image ready on request.
      // Setting the original URL in the original image object (this)
      // in the onerror event will create a loop, that trys to
      // preload a valid image. This ensures we'll eventually get
      // our image when Tableau desides it's ready to let us have it.
      // Now limited to 5 retries to minimize performance impact of a
      // potentially infinite loop.
      img.onerror = function() {
        if (img.retryCount < 5) {
          img.src = url;
          img.retryCount++
        }
      }

      reportContainer.innerHTML = '';
      reportContainer.append(img);
    }
  }
}

export default BIReportGenerator;
