import ValidateAccessCode from './actions/ValidateAccessCode';
import ApplyAccessCode from './actions/ApplyAccessCode';

class AddAccessCode {
  constructor() {
    this.components = document.querySelectorAll('.js-student-access-code-component [type=submit]')
  }

  init() {
    this.components.forEach(component => {
      // get a user-facing name for the code (e.g. 'access' or 'Xvoucher') from the backend
      const name = component.dataset.name || 'access'
      component.addEventListener('click', (event) => this.handleClick(event, name))
    })
  }

  handleClick(event, name) {
    event.preventDefault();
    const validAccessCode = new ValidateAccessCode(name).init();
    if(!validAccessCode) { return false }
    else                 { new ApplyAccessCode(name).init(); }
  }
}

export default AddAccessCode;
