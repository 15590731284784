// Institutions Stats Widget Class
//
// The responsibility of this class is to delay loading of the
// institution's Stats Nav to after the page has loaded.

class InstitutionsStatsWidget {
  constructor() {
    this.institutionId = $('[data-behavior=institution-stats]').data('id');
    this.requestUri = `/institutions/${this.institutionId}/stats.json`;
    this.departmentsCount = $('[data-behavior=departments-count]');
    this.termsCount = $('[data-behavior=terms-count]');
    this.instructorsCount = $('[data-behavior=instructors-count]');
    this.iterationsCount = $('[data-behavior=iterations-count]');
    this.testTakersCount = $('[data-behavior=test-takers-count]');
    this.reservationsTodayCount = $('[data-behavior=reservations-today-count]');
    this.reservationsPastCount = $('[data-behavior=reservations-past-count]');
    this.reservationsFutureCount = $('[data-behavior=reservations-future-count]');
    this.reservationsThisWeekCount = $('[data-behavior=reservations-this-week-count]');
    this.reservationsThisMonthCount = $('[data-behavior=reservations-this-month-count]');
    this.reservationsLastMonthCount = $('[data-behavior=reservations-last-month-count]');
  }

  init() {
    this.queryInstitutionStats()
  }

  queryInstitutionStats() {
    if (!this.reservationsTodayCount.length) {
      this.requestUri += '?nav-only=true';
    }

    $.getJSON(this.requestUri, (response) => {
      this.departmentsCount.html(response.departments_count)
      this.termsCount.html(response.terms_count)
      this.instructorsCount.html(response.instructors_count)
      this.iterationsCount.html(response.iterations_count)
      this.testTakersCount.html(response.test_takers_count)

      this.reservationsTodayCount.html(response.reservations_today_count)
      this.reservationsPastCount.html(response.reservations_past_count)
      this.reservationsFutureCount.html(response.reservations_future_count)
      this.reservationsThisWeekCount.html(response.reservations_this_week_count)
      this.reservationsThisMonthCount.html(response.reservations_this_month_count)
      this.reservationsLastMonthCount.html(response.reservations_last_month_count)
    });
  }
}

export default InstitutionsStatsWidget
