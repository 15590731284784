import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import MediaDeletionHandler from '../MediaDeletionHandler';

const MediaImages = ({ allMedia, permission, images, target }) => {

  const [disable, setDisable] = React.useState(false);

  useEffect(() => {
    const imageDeletionIcons = document.querySelectorAll('[data-behavior="js-delete-image"]');
    imageDeletionIcons.forEach(
      icon => icon.addEventListener('click', (event) => {
        event.preventDefault();
        event.stopPropagation();
        setDisable(true)
        if(confirm(polyglot.t('view_media_deletion_confirm'))) {
          new MediaDeletionHandler().handleDeletion(event)
        }
        setDisable(false)
      })
    );
  }, [])

  return (
    <ul className="image-grid list-unstyled row">
      {
        images.map((image, index) => {
          return (
            <div
              className="view-media-image "
              id={`image_${image.image_id}`}
              key={image.image_id}
            >
              <li className="col">
                <div className="js-view-media">
                  <img
                    src={image.url}
                    alt={image.url}
                    className="rounded img-thumbnail"
                  />
                  {permission === 'true' ? (
                    <div className="js-view-media-delete-icon">
                      <button className="btn btn-sm btn-danger" data-behavior="js-delete-image" disabled={disable}>
                        Delete
                      </button>
                    </div>
                  ) : null}
                </div>
              </li>
            </div>
          );
        })
      }
    </ul>
  )
};

MediaImages.propTypes = {
  allMedia: PropTypes.array.isRequired,
  permission: PropTypes.string.isRequired,
  images: PropTypes.array.isRequired,
  target: PropTypes.string.isRequired,
};

export default MediaImages;
