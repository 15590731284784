class ShowActiveTab {
  constructor() {
    if (document.querySelector('#flight-path li.completed') !== null)
      if ($('#flight-path li.completed').last().next().length > 0)
        $('#flight-path li.completed').last().next().removeClass('disabled').find('a').tab('show')
      else
        $('#flight-path li.completed').last().removeClass('disabled').find('a').tab('show')
    else
      $("#flight-path .js-unstarted").removeClass('disabled').find('a').tab('show').addClass('active');
  }
}

export default ShowActiveTab;
