/*jshint esversion: 6 */

import { useEffect } from 'react';

const SecondCameraPositioningCard = ({ sendEvent, broadcastMessage }) => {
  useEffect(() => {
    sendEvent('Event::SecondCameraPositioningLoaded');
    broadcastMessage({
      sender: 'archimedes',
      messageType: 'request_second_camera_preview',
      data: {},
    });
  }, []);

  // Step contents are rendered by Guardian Browser
  return <div />;
};

export default SecondCameraPositioningCard;
