import PrechecksHeaderGroup from './CardSections/PrechecksHeaderGroup';
import PrechecksBodyText from './CardSections/PrechecksBodyText';
import { useEffect } from 'react';

const UtilityCard = ({
  id,
  icon,
  iconPrefix,
  title,
  bodyText,
  precheckData,
  sendEvent,
  broadcastMessage,
}) => {
  useEffect(() => {
    sendEvent('Event::ChromebookUtilityTabLoaded');
    // How can i get this to reliably fire on page reload?
    broadcastMessage({
      sender: 'archimedes',
      messageType: 'utility_tab_loaded',
      data: {},
    });
  }, []);

  return (
    <div className="container-fluid text-center precheck-card-content" id={id}>
      <PrechecksHeaderGroup title={title} />
      <PrechecksBodyText bodyText={bodyText} />
      <div id="utility-tab-content"></div>
    </div>
  );
};

UtilityCard.defaultProps = {
  title: 'ProctorU Guardian Utility',
  icon: 'fa-check-circle',
  iconPrefix: 'fal',
  statusColor: 'primary',
  bodyText:
    'Warning! Do not close this tab unless you have already completed your exam',
};

export default UtilityCard;
