import PropTypes from 'prop-types';
import DeleteIncident from '../DeleteIncident';

const SuspiciousBehavior = ({
  endTime,
  startTime,
  showInPlayer,
  uiMessageId,
  transformStore,
  isDeleteCandidate,
  body,
  institutionUser
}) => {
  const start = moment(startTime).format("hh:mm:ss")
  const end = moment(endTime).format("hh:mm:ss on MMM D, YYYY")
  const style = {
    wordBreak: "break-all"
  }

  return (
    <div className="row align-items-center">
      <div className="col-1">
        <div className="h-100 text-center d-flex align-items-center justify-content-center">
          <span className="fad fa-play-circle fa-lg player-icon cursor-pointer" onClick={showInPlayer}/>
        </div>
      </div>
      <div className="col-8">
        <small className="text-muted">
          {start} - {end}
        </small>
        <div>
          {body}
        </div>
      </div>
      <div className="col-3">
        {!institutionUser && (
        <DeleteIncident 
          uiMessageId={uiMessageId}
          transformStore={transformStore}
          isDeleteCandidate={isDeleteCandidate}
        />)}
      </div>
    </div>
  )
}

SuspiciousBehavior.propTypes = {
  endTime: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  showInPlayer: PropTypes.func.isRequired,
  uiMessageId: PropTypes.number,
  transformStore: PropTypes.func,
  isDeleteCandidate: PropTypes.bool,
  body: PropTypes.string
}

export default SuspiciousBehavior
