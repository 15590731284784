class PendingExamAlert {
  constructor() {
    this.wideAlerts = document.querySelectorAll('.alert-wide');
  }

  init() {
    this.wideAlerts.forEach(wideAlert => {
      if(wideAlert.textContent.includes('Exam created and pending review. We will respond')) {
        alert(polyglot.t('pending'))
      }
    });
  }
}

export default PendingExamAlert;
