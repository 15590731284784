import Dropzone from 'react-dropzone'

const BatchUsersView = ({choice, locations, handleClick, handleOnDrop}) => {
  return (
    <div>
      <div className="row">
        <div className="col-md-6">
          <ul className='nav nav-tabs nav-fill' role="tablist">
            <li onClick={ (event) => handleClick(event, 'onboard') } className="nav-item">
                <a href="#onboard" data-toggle="tab" className="nav-link active">
                <strong>Onboard</strong>
              </a>
            </li>
            <li onClick={ (event) => handleClick(event, 'offboard') } className="nav-item">
                <a href="#offboard" data-toggle="tab" className="nav-link">
                <strong>Offboard</strong>
              </a>
            </li>
          </ul>
          <div className='tab-content'>
            <div className='tab-pane active card card-body border-top-0'>
              <BatchUsersDirections choice={choice} locations={locations} handleOnDrop={handleOnDrop}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const BatchUsersDirections = ({choice, locations, handleOnDrop}) => {
  return (
    <div>
      <BatchUsersDownload choice={ choice } />
      <BatchUsersCriteria choice={ choice } locations={ locations } />
      <BatchUsersUpload choice={ choice } handleOnDrop={ handleOnDrop }/>
    </div>
  )
}

const BatchUsersDownload = ({choice}) => {
  return choice === 'onboard' ? (
    <div className="mb-4">
      <h4>1. Download the template</h4>
      <p>
        Click the link below to download the template.
      </p>
      <a href='/documents/batch_create_users.csv' target="_blank" className="text-primary">Download Template</a>
    </div>
  ) : (
    <div className="mb-4">
      <h4>1. Download the template</h4>
      <p>
        Click the link below to download the template.
      </p>
      <a href='/documents/batch_destroy_users.csv' target="_blank" className="text-primary">Download Template</a>
    </div>
  )
}

const BatchUsersCriteria = ({choice, locations}) => {
  return choice === 'onboard' ? (
    <div>
      <h4>2. Edit the template with the following criteria</h4>
      <p>
        All columns must be filled out - no columns can be blank.
      </p>
      <p>
        Emails must be valid emails.
      </p>
      <p>
        Zip must be a number (if the country is United States).
      </p>
      <p>
        Password must have eight characters.
      </p>
      <p>
        Country must be in the <a href="https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2#Officially_assigned_code_elements" target="_blank" className="text-primary">two-letter ISO-3166 format.</a>
      </p>
      <p>
        Location must one of the following:
      </p>
      <ul className='list-inline'>
        {
          locations.map((location, i) => {
            return (
              <li key={ i } className="mb-1 list-inline-item">
                <span className="badge badge-secondary js-tooltip-copy text-cursor"
                      data-clipboard-text={`${location}`}
                      title={`Click to copy location (${location})`}
                      data-copied-hint="Copied!"
                      data-toggle="tooltip">
                  { location }
                </span>
              </li>
            )
          })
         }
      </ul>
    </div>
    ) : (
      <div>
      <h4>2. Edit the template with the following criteria</h4>
        <p>
          All columns must be filled out - no columns can be blank.
        </p>
        <p>
          Emails must be valid emails.
        </p>
      </div>
    )
}

const BatchUsersUpload = ({handleOnDrop}) => {
  return (
    <div>
      <h4>3. Upload</h4>
      <Dropzone onDrop={handleOnDrop} accept="text/csv">
        {({getRootProps, getInputProps, isDragActive}) => {
          return (
            <div
              {...getRootProps()}
              className={`bg-light border p-5 image-dnd image-dnd-default dropzone text-center ${isDragActive ? 'dropzone--isActive' : ''}`}
            >
              <input {...getInputProps()} />
              <div>
                <div className="btn btn-primary mb-3">Upload</div>
                <div>
                    Or drag and drop your file here.
                </div>
              </div>
            </div>
          )
        }}
      </Dropzone>
    </div>
  )
}

export default BatchUsersView
