import GenericDropdown from './GenericDropdown';

const GenericTable = ({ headers, rows, loading }) => {
  const loadingTable = () => {
    return (
      <tbody>
        <GenericTable.TR>
          <GenericTable.TD colSpan={headers.length}>
            <h4 className="text-center">
              <span><i className="fas fa-circle-notch fa-spin fa-2x text-primary"></i></span>
            </h4>
          </GenericTable.TD>
        </GenericTable.TR>
      </tbody>
    )
  };

  const emptyTable = () => {
    return (
      <tbody>
        <GenericTable.TR>
          <GenericTable.TD colSpan={headers.length}>
            <h4 className="text-center mt-4">
              No records found.
            </h4>
          </GenericTable.TD>
        </GenericTable.TR>
      </tbody>
    )
  };

  const filledTable = () => {
    return (
      <tbody>
        {
          rows.map((row, index) => (
            <GenericTable.TR key={index}>
              {
                Object.values(row.columns).map((column, index) => (
                  <GenericTable.TD key={index}>
                    { column }
                  </GenericTable.TD>
                )
              )}
              <GenericTable.TD>
                {
                  Object.keys(row.tags).map((tag, index) => (
                    row.tags[tag] == true ? (<span className="badge badge-primary mr-1" key={index}> { tag } </span>) : ''
                  )
                )}
              </GenericTable.TD>
              <GenericTable.TD>
                <GenericDropdown links={row.links} icon="fa-ellipsis-h" align="right"/>
              </GenericTable.TD>
            </GenericTable.TR>
          )
        )}
      </tbody>
    )
  }

  const loadedTable = () => {
    return (rows.length > 0) ? filledTable() : emptyTable()
  }

  return (
    <div className="table-responsive">
      <table className="table table-semibordered table-hover table-default">
        <thead>
          <GenericTable.TR>
            { headers.map((header, index) => (
                <GenericTable.TH key={index}>
                  { header }
                </GenericTable.TH>
              )
            )}
          </GenericTable.TR>
        </thead>
        { loading ? loadingTable() : loadedTable() }
      </table>
    </div>
  );
}

GenericTable.TH = function TH(props) {
  return <th {...props} />;
};

GenericTable.TR = function TR(props) {
  return <tr {...props} />;
};

GenericTable.TD = function TD(props) {
  return <td {...props} />;
};

export default GenericTable;
