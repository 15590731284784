import Toast from '../utils/Toast';

class EuCitizensModal {
  constructor() {
    this.$modal = $('#gdpr-contact-info-modal');
    this.gdprAgreeTermsButton = document.querySelector(
      '[data-behavior="gdpr-terms-agreement-action"]'
    );
    this.gdprContactMethodSelect = document.querySelector(
      '[data-behavior="contact-method-select"]'
    );
    this.gdprContactMethodSelectLabel = document.querySelector(
      '[data-behavior="contact-method-label"]'
    );
    this.contactInfoLabel = document.querySelector(
      '[data-behavior="contact-info-label"]'
    );
    this.contactInfoInput = document.querySelector(
      '[data-behavior="contact-info-input"]'
    );
    this.contactInfoTextArea = document.querySelector(
      '[data-behavior="contact-info-input-textarea"]'
    );
    this.gdprSubmitContactButton = document.querySelector(
      '[data-behavior="submit-gdpr-contact"]'
    );
    this.euCitizenForm = document.querySelector(
      '[data-behavior="eu-citizen-info-form"]'
    );
    this.euCitizenFormYesRadio = document.querySelector(
      '[data-behavior="eu_citizen_confirmed_yes"]'
    );
    this.euCitizenFormNoRadio = document.querySelector(
      '[data-behavior="eu_citizen_confirmed_no"]'
    );

    this.handleAgreeTerms = this.handleAgreeTerms.bind(this);
    this.handleContactMethodChange = this.handleContactMethodChange.bind(this);
    this.submitContactInformation = this.submitContactInformation.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.updateInputField = this.updateInputField.bind(this);
  }

  init() {
    this.bindEventListeners();
  }

  bindEventListeners() {
    this.$modal.on('hidden.bs.modal', this.hideModal);
    this.euCitizenFormYesRadio.addEventListener('click', () => {
      this.$modal.modal();
    });
    this.gdprAgreeTermsButton.addEventListener('click', this.handleAgreeTerms);
    this.gdprContactMethodSelect.addEventListener(
      'change',
      this.handleContactMethodChange
    );
    this.gdprSubmitContactButton.addEventListener(
      'click',
      this.submitContactInformation
    );
  }

  hideModal() {
    if (!document.querySelector('div.alert.alert-danger.alert-wide')) {
      new Toast().danger({message: 'You must provide contact information.'})
    }
    // Set the yes radio button to be unselected.
    this.euCitizenFormYesRadio.checked = false;
  }

  handleAgreeTerms() {
    // Hide the agree button and show the select box for contact method
    const gdprTermsAgreementSection = document.querySelector(
      '[data-behavior="gdpr-terms-agreement"]'
    );
    gdprTermsAgreementSection.classList.add('d-none');
    this.gdprContactMethodSelect.classList.remove('d-none');
    this.gdprContactMethodSelectLabel.classList.remove('d-none');
  }

  handleContactMethodChange() {
    // Set the label for the input box to be a humanized value.
    const contactMethodSelect = this.gdprContactMethodSelect;
    const selectedIndex = contactMethodSelect.selectedIndex;

    const contactMethodSelectDataset = contactMethodSelect.options[selectedIndex].dataset;
    this.contactInfoLabel.innerText = contactMethodSelectDataset.humanizedWording;
    this.contactInfoInput.setAttribute("autocomplete", contactMethodSelectDataset.autocomplete);
    this.contactInfoTextArea.setAttribute("autocomplete", contactMethodSelectDataset.autocomplete);

    if (contactMethodSelect.value === 'postal_mail') {
      this.contactInfoInput.removeAttribute("id");
      this.contactInfoTextArea.setAttribute("id", this.contactInfoLabel.getAttribute("for"));
    } else {
      this.contactInfoTextArea.removeAttribute("id");
      this.contactInfoInput.setAttribute("id", this.contactInfoLabel.getAttribute("for"));
    }

    // Show the input field/label
    this.contactInfoLabel.classList.remove('d-none');
    this.contactInfoInput.classList.remove('d-none');
    var type = 'text';
    var placeholder = '';
    switch (contactMethodSelect.value) {
      case 'email':
        type = 'email';
        placeholder = 'your.email@gmail.com';
        break;
      case 'phone_call':
        type = 'tel';
        placeholder = '123-456-7890';
        break;
      case 'text_message':
        type = 'tel';
        placeholder = '123-456-7890';
        break;
      case 'postal_mail':
        type = 'address';
        placeholder = `7901 Jones Branch Dr, Ste 330, McLean, VA 22102`;
        break;
      default:
        // will not execute unless the user manually edits the page's HTML
        // but let's give the input field some valid values so we don't break the page
        type = 'email';
        placeholder = 'your.email@gmail.com';
    }
    this.updateInputField(type, placeholder);
    this.gdprSubmitContactButton.classList.remove('d-none');
    this.removeErrorMessages(this.contactInfoInput);
    this.removeErrorMessages(this.contactInfoTextArea);
  }

  updateInputField(type, placeholder) {
    if (type === 'address') {
      // the type is address so we need to change the input into a textarea
      this.contactInfoInput.value = '';
      this.contactInfoTextArea.classList.remove('d-none');
      this.contactInfoInput.classList.add('d-none');
    } else {
      // Clear out the hidden address field incase the user selected it.
      this.contactInfoTextArea.value = '';
      this.contactInfoTextArea.classList.add('d-none');
      this.contactInfoInput.classList.remove('d-none');
      this.contactInfoInput.value = '';
      this.contactInfoInput.type = type;
      this.contactInfoInput.placeholder = placeholder;
    }
  }

  submitContactInformation() {
    // Check if the field is empty, if it is, display an error and prevent submission.
    // The input field is hidden, so we need to ensure the text area has content
    if (this.contactInfoInput.classList.contains('d-none')) {
      if (!this.contactInfoTextArea.value) {
        this.renderError(this.contactInfoTextArea, polyglot.t('blank'));
        return;
      } else {
        this.removeErrorMessages(this.contactInfoTextArea);
      }
    } else {
      // TextArea is hidden, so we need to ensure the input field has content.
      if (!this.contactInfoInput.value) {
        this.renderError(this.contactInfoInput, polyglot.t('blank'));
        return;
      } else {
        this.removeErrorMessages(this.contactInfoInput);
      }
    }

    // Find the form on the main page, then add the value of the input and type of input
    // as hidden attributes on the main form, for the eu_contact_info attributes table
    // then submit the form that updates via the EuCitizensController.
    document.querySelector(
      '[data-behavior="contact-info-method"]'
    ).value = this.gdprContactMethodSelect.value;

    if (this.gdprContactMethodSelect.value === 'postal_mail') {
      document.querySelector(
        '[data-behavior="contact-info-content"]'
      ).value = this.contactInfoTextArea.value;
    } else {
      document.querySelector(
        '[data-behavior="contact-info-content"]'
      ).value = this.contactInfoInput.value;
    }
    // Submit the form
    this.euCitizenForm.submit();
  }

  renderError(element, msg) {
    const errorElement = element.parentElement.querySelector(
      'span.form-text.text-danger'
    );
    const errorBlock = element.parentElement.querySelector(
      '[data-element="error-block"]'
    );
    if (errorElement) {
      if (!errorBlock) {
        element.parentElement.removeChild(errorElement);
      } else {
        errorBlock.removeChild(errorElement);
      }
    }
    const errorSpan = this.buildErrorSpan(msg);

    if (errorBlock) {
      // element.parentElement.insertBefore(errorSpan, errorBlock);
      errorBlock.appendChild(errorSpan);
    } else {
      element.parentElement.appendChild(errorSpan);
    }
  }

  buildErrorSpan(msg) {
    const errorSpan = document.createElement('span');
    errorSpan.classList.add('form-text');
    errorSpan.classList.add('text-danger');
    errorSpan.appendChild(document.createTextNode(msg));
    return errorSpan;
  }

  removeErrorMessages(element) {
    const errorElement = element.parentElement.querySelector(
      'span.form-text.text-danger'
    );

    const errorBlock = element.parentElement.querySelector(
      '[data-element="error-block"]'
    );

    if (errorElement) {
      if (errorBlock) {
        errorBlock.removeChild(errorElement);
      } else {
        element.parentElement.removeChild(errorElement);
      }
    }
  }
}

export default EuCitizensModal
