import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ExpressConfigModalDropdown } from './ExpressConfigModalDropdown';

const ExpressConfigModal = ({
  terms,
  departments,
  latestExams,
  toggle,
  show,
  selectedTermName,
  selectedDepartmentName,
  selectedExamOptionsName,
  getSelectedTerm,
  getSelectedDepartment,
  getSelectedExamOptions,
  isConfigureBtnEnabled,
  handleConfigure,
  numberOfSelectedExams,
}) => {
  return (
    <>
      <Modal
        show={show}
        onHide={toggle}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton id="step-modal-header">
          <Modal.Title className="custom-header">
            Express Proctoring Configuration
          </Modal.Title>
        </Modal.Header>
        <Modal.Body id="step-modal-body">
          <p className="lms-gray-dark-text">
            You have selected {numberOfSelectedExams} exams. Express
            Configuration allows you to configure only three options and
            synchronize the exam with ProctorU.
          </p>
          <ExpressConfigModalDropdown
            terms={terms}
            departments={departments}
            latestExams={latestExams}
            selectedTermName={selectedTermName}
            selectedDepartmentName={selectedDepartmentName}
            selectedExamOptionsName={selectedExamOptionsName}
            getSelectedTerm={getSelectedTerm}
            getSelectedDepartment={getSelectedDepartment}
            getSelectedExamOptions={getSelectedExamOptions}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary-meazure small" onClick={toggle}>
            Cancel
          </Button>
          <Button
            variant="primary-meazure small"
            disabled={!isConfigureBtnEnabled}
            onClick={handleConfigure}
          >
            Configure {numberOfSelectedExams} exams
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export { ExpressConfigModal };
