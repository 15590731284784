import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

const ExpressConfigModalDropdown = ({
  terms,
  departments,
  latestExams,
  selectedTermName,
  selectedDepartmentName,
  selectedExamOptionsName,
  getSelectedTerm,
  getSelectedDepartment,
  getSelectedExamOptions,
}) => {
  return (
    <>
      <div className="small mb-1">
        Term<span className="text-danger">*</span>
      </div>
      <Dropdown className="lms-gray-dark-text small">
        <Dropdown.Toggle
          id="term-dropdown-button"
          className="mb-4 express-config-dropdown small"
        >
          {selectedTermName}
        </Dropdown.Toggle>
        <Dropdown.Menu className="w-100">
          {terms &&
            terms.map((term) => {
              return (
                <Dropdown.Item
                  id={term.id}
                  key={term.id}
                  value={term.id}
                  name={term.name}
                  className="express-config-dropdown-item lms-gray-dark-text small"
                  onClick={(e) => getSelectedTerm(e.target)}
                >
                  {term.name}
                </Dropdown.Item>
              );
            })}
        </Dropdown.Menu>
      </Dropdown>
      <div className="small mb-1">
        Service Line (Department)<span className="text-danger">*</span>
      </div>
      <Dropdown className="lms-gray-dark-text">
        <Dropdown.Toggle
          id="department-dropdown-button"
          className="mb-4 express-config-dropdown small w-100"
        >
          {selectedDepartmentName}
        </Dropdown.Toggle>
        <Dropdown.Menu className="w-100">
          {departments &&
            departments.map((department) => {
              return (
                <Dropdown.Item
                  id={department.id}
                  key={department.id}
                  value={department.id}
                  name={department.name}
                  className="express-config-dropdown-item lms-gray-dark-text"
                  onClick={(e) => getSelectedDepartment(e.target)}
                >
                  {department.name}
                </Dropdown.Item>
              );
            })}
        </Dropdown.Menu>
      </Dropdown>
      {latestExams && latestExams.length > 0 && (
        <>
          <div className="small mb-1">Copy additional settings from:</div>
          <Dropdown className="lms-gray-dark-text">
            <Dropdown.Toggle
              id="exam-options-dropdown-button"
              className="express-config-dropdown small w-100"
            >
              {selectedExamOptionsName}
            </Dropdown.Toggle>
            <Dropdown.Menu className="w-100">
              {latestExams.map((exam) => {
                return (
                  <Dropdown.Item
                    id={exam.id}
                    key={exam.id}
                    value={exam.id}
                    name={exam.name}
                    title={exam.name}
                    className="express-config-dropdown-item lms-gray-dark-text"
                    onClick={(e) => getSelectedExamOptions(e.target)}
                  >
                    {exam.name}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </>
      )}
    </>
  );
};

export { ExpressConfigModalDropdown };
