import { event } from '@fullstory/browser';
import csrfToken from '../utils/csrf';
class OverrideRetakePolicy {
  constructor() {
    this.btn_save_retake_policy = $('#btn_save_retake_policy');
    this.btn_cancel = $('#btn_cancel');
    this.modal = $('#overrideRetakePolicyModal');
    this.override_reason = $('#overrideReason');
    this.validation_error = $('#validation_error');
  }

  init() {
    this.bindEventListeners();
  }

  bindEventListeners() {
    this.btn_save_retake_policy.on('click', (event) =>
      this.handleOverrideRetakePolicy(event),
    );
    this.btn_cancel.on('click', (event) => this.handleCancel(event));
  }

  async handleOverrideRetakePolicy(event) {
    event.preventDefault();

    const override_reason = this.override_reason.val().trim();

    if (!this.validateFormFields()) return;

    const url = new URL(window.location.href);
    const userId = url.searchParams.get('user');
    const iteration_id = url.searchParams.get('iteration_id');

    const formData = {
      override_reason: override_reason,
      user: userId,
      iteration_id: iteration_id,
    };

    try {
      const response = await fetch('/reservations/save_retake_override', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken(),
        },
        credentials: 'same-origin',
        body: JSON.stringify(formData),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (!data.success) {
        console('Error overriding retake policy!');
        throw new Error(data.error);
      }
      this.formCleanUp();
      $('#overrideRetakePolicyModal').modal('hide');
      this.addAdminOverrideToURL();
    } catch (error) {
      console.error('Error:', error);
    }
  }

  addAdminOverrideToURL() {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const params = new URLSearchParams(url.search);
    params.set('is_admin_override', true);
    url.search = params.toString();
    window.location.href = url.toString();
  }

  handleCancel(event) {
    this.formCleanUp();
  }

  validateFormFields() {
    //validate override reason field
    const isOverrideReasonEmpty = this.override_reason.val().trim() === '';
    this.validation_error.toggle(isOverrideReasonEmpty);
    return !isOverrideReasonEmpty;
  }

  formCleanUp() {
    this.override_reason.val('');
    this.validation_error.hide();
  }
}
export default OverrideRetakePolicy;
