const PrechecksBodyText = ({ bodyText, bodyStyleClass }) => {
  return (
    <div className="row my-3 precheck-card-body">
      <div className="col-md-12">
        <p
          className={`fs-6 ${bodyStyleClass}`}
          dangerouslySetInnerHTML={{ __html: bodyText }}
        />
      </div>
    </div>
  );
};

PrechecksBodyText.propTypes = {
  bodyText: PropTypes.string.isRequired,
};

PrechecksBodyText.defaultProps = {
  bodyText: 'Generic Precheck Text',
  bodyStyleClass: 'text-dark',
};

export default PrechecksBodyText;
