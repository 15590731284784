import axios from 'axios';
import PropTypes from 'prop-types';
import BatchUsersView from './BatchUsersView';
import csrfToken from '../../../../src/utils/csrf';
import Toast from '../../../../src/utils/Toast';
import Clippy from '../../../../src/global/Clippy';

class BatchUsersApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      choice: 'onboard'
    }
    this.handleClick = this.handleClick.bind(this);
    this.handleOnDrop = this.handleOnDrop.bind(this);
  }

  componentDidMount() {
    new Clippy().init(); // needed because turbolinks, React, and jQuery aren't playing nicely together
  }

  handleClick = (event, choice) => {
    event.preventDefault();
    this.setState({choice: choice})
  }

  handleOnDrop = (files, rejecteFiles) => {
    if(!files) {new Toast().warning({ message: 'File not found.' }); return; }
    if(rejecteFiles.length > 0) {new Toast().warning({ message: 'Invalid file.' }); return; }
    event.preventDefault();
    const confirmed = confirm(`You're about to ${this.state.choice} users from ${files[0].name}. Continue?`)
    if (confirmed) {
      let formData = new FormData();
      formData.append('file', files[0])
      const headers = {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'multipart/form-data',
        'X-CSRF-Token': csrfToken()
      }
      axios({
        method: this.state.choice == 'offboard' ? 'DELETE' : 'POST',
        url: this.props.batchUrl,
        data: formData,
        headers: headers
      }).then(response => {
        if(response.data.messages.success.length > 0) {
          new Toast().sendToNotify('success', {
            message: `${response.data.messages.success}`,
            timeout: 0
          })
        }
        if(response.data.messages.warning.length > 0) {
          new Toast().sendToNotify('warning', {
            message: `${response.data.messages.warning}`,
            timeout: 0
          })
        }
      })
      .catch(error => {
        new Toast().sendToNotify('danger', {
          message: `${error.response.data.messages}`,
          timeout: 0
        })
      });
    }
  }

  render() {
    return(
      <div>
        <BatchUsersView choice={ this.state.choice }
                        locations={ this.props.locations }
                        handleClick={ this.handleClick }
                        handleOnDrop={ this.handleOnDrop }
                        batchUrl={ this.props.batchUrl }
        />
      </div>
    )
  }
}

export default BatchUsersApp
