import axios from 'axios';
import csrfToken from '../utils/csrf';
class CampusDropdown {
  constructor() {
    this.enrollmentSelect = document.querySelector('.enrollment-fields .enroll-select')
  }

  init() {
    if(this.enrollmentSelect) {
      this.enrollmentSelect.addEventListener('change', () => { this.handleChange() })
    }
  }

  handleChange() {
    const enrollmentPanel = this.enrollmentSelect.parentNode.parentNode.parentNode
    const campusSelect = enrollmentPanel.querySelector('select.campus-select');
    const campusGroup = enrollmentPanel.querySelector('.campus-group');
    const url = this.enrollmentSelect.options[this.enrollmentSelect.selectedIndex].getAttribute('data-campuses-url');
    const headers = {
      headers: {
        Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
      }
    }
    axios.get(url, {}, headers).then(response => {
      campusSelect.innerHTML = ''
      if(response.data.length > 0) {
        response.data.forEach(campus => {
          const option = document.createElement("option")
          option.value = campus.id
          option.text = campus.name
          campusSelect.appendChild(option);
        })
        campusSelect.disabled = false;
        campusGroup.classList.remove('d-none')
      } else {
        campusSelect.disabled = true;
        campusGroup.classList.add('d-none')
      }
    });
  }
}

export default CampusDropdown;
