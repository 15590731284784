import PropTypes from 'prop-types';

const Escalated = ({createdAt, showInPlayer, text}) => (
  <div className="row align-items-center">
    <div className="col-11 offset-1">
      <small className="text-muted">{createdAt}</small>
      <div>
        {text}
      </div>
    </div>
  </div>
)

Escalated.propTypes = {
  createdAt: PropTypes.string.isRequired,
  showInPlayer: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
}

export default Escalated
