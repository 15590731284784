import React, { Fragment } from 'react';

import ConfiguredEmptyExams from './ConfiguredEmptyExams';

const ExamTable = ({ id, exams, renderRow }) => {
  return (
    <>
      <div className="card">
        <table id={id} className="table table-sm lms-table-hover table-default mb-0">
          <tbody>
            {exams.length > 0 ? (
              exams.map((exam, index) => (
                <Fragment key={`${exam.id}_${index}`}>
                  {renderRow(exam)}
                </Fragment>
              ))
            ) : (
              <ConfiguredEmptyExams />
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default ExamTable;
