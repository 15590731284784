class MessageTemplateDropdown {
  constructor() {
    this.dropdownLinks = document.querySelectorAll("#email-dropdown a");
  }

  init() {
    this.firstDropdownLink = document.querySelector("#email-dropdown a")
    this.setDropdownText(this.firstDropdownLink.textContent)
    $(this.firstDropdownLink).tab('show');
    this.dropdownLinks.forEach(link => {
      link.addEventListener('click', event => {
        this.handleClick(event)
      })
    })

  }

  handleClick(event) {
    event.preventDefault();
    $(event.target).tab('show');
    if(document.querySelector("#email-dropdown .active"))
      document.querySelector("#email-dropdown .active").classList.remove('active')
    this.setDropdownText(event.target.textContent)
  }

  setDropdownText(text) {
    document.querySelector('#email-select').innerHTML = text + ' <span class="caret"></span>'
    document.querySelector('#email-select').classList.remove('active')
  }
}

export default MessageTemplateDropdown
