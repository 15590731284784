import { useEffect, useState, useRef } from 'react';
import ImageGroup from '../Shared/ImageGroup';
import PrechecksHeaderGroup from './CardSections/PrechecksHeaderGroup';
import PrechecksBodyCamera from './CardSections/PrechecksBodyCamera';
import PrechecksBodyText from './CardSections/PrechecksBodyText';
import PrechecksFooterButton from './CardSections/PrechecksFooterButton';

const GuidedLaunchCaptureLeftWallCard = ({
  id,
  icon,
  iconPrefix,
  title,
  your_image_text,
  paragraph1,
  btnText,
  backBtnText,
  handleNext,
  handlePrevious,
  sendEvent,
  streamWebcam,
  precheckData,
  glImages,
  getGlImages,
}) => {
  const userId = precheckData.user.id;
  const [pictureTaken, setPictureTaken] = useState(false);
  const canvasRef = useRef();
  const videoRef = useRef();
  const height = 240;
  const width = 320;
  const leftWallImg = precheckData.images.leftWallImg;

  useEffect(() => {
    sendEvent('Event::CaptureLeftWallLoaded');
    streamWebcam(videoRef.current);
  }, []);

  useEffect(() => {
    getGlImages();
  }, []);

  const handleBack = () => {
    sendEvent('Event::BackOnCaptureLeftWall');
    handlePrevious();
  };

  const handleCaptureLeftWallPictureBtn = () => {
    const context = canvasRef.current.getContext('2d');
    context.translate(0, 0);
    context.drawImage(videoRef.current, 0, 0, width, height);

    sendEvent(
      'Event::CaptureLeftWallCompleted',
      {
        createdById: userId,
        attachmentUrl: canvasRef.current.toDataURL('image/png'),
      },
      'image_checks',
      (response) => {
        if (response.status === 201) {
          setPictureTaken(true);
          handleNext();
        } else {
          setPictureTaken(false);
        }
      },
      (error) => {
        console.log(error);
      },
    );
  };

  return (
    <div className="container-fluid text-center precheck-card-content" id={id}>
      <PrechecksHeaderGroup
        title={title}
        image={leftWallImg}
        icon={icon}
        iconPrefix={iconPrefix}
      />
      <div className="text-left">
        <PrechecksBodyText bodyText={paragraph1} />
      </div>
      <div className={'row align-items-center'}>
        <div className={'col-md-6'}>
          <ImageGroup images={glImages} />
        </div>
        <div className={'col-md-6'}>
          <PrechecksBodyText
            bodyText={your_image_text}
            bodyStyleClass="precheck-primary font-weight-bold"
          />
          <PrechecksBodyCamera
            canvasRef={canvasRef}
            videoRef={videoRef}
            showCanvas={pictureTaken}
            showVideo={!pictureTaken}
            height={height}
            width={width}
            precheckData={precheckData}
          />
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <PrechecksFooterButton
          buttonText={backBtnText}
          handleClick={handleBack}
          buttonStyleClass="btn-precheck-secondary"
        />
        <PrechecksFooterButton
          buttonText={btnText}
          handleClick={handleCaptureLeftWallPictureBtn}
        />
      </div>
    </div>
  );
};

GuidedLaunchCaptureLeftWallCard.defaultProps = {
  title: polyglot.t('prechecks_guidedlaunch_capture_left_wall_title'),
  paragraph1: polyglot.t('prechecks_guidedlaunch_capture_left_wall_paragraph1'),
  btnText: polyglot.t('prechecks_guidedlaunch_capture_left_wall_button'),
  backBtnText: polyglot.t(
    'prechecks_guidedlaunch_capture_left_wall_back_button',
  ),
  your_image_text: polyglot.t(
    'prechecks_guidedlaunch_capture_left_wall_your_image_text',
  ),
  icon: 'fa-camera',
  iconPrefix: 'fal',
  statusColor: 'primary',
  iconColor: 'primary',
};

export default GuidedLaunchCaptureLeftWallCard;
