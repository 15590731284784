class InstitutionSelect2 {
  constructor() {
    $('.user-form-institution-select').select2({
      placeholder: $('.user-form-institution-select').data('placeholder'),
      theme: 'bootstrap4',
      delay: 500,
      ajax: {
        url: $('.user-form-institution-select').data('source'),
        dataType: 'json',
        data: function(params) {
          return {
            id: $(this).data('userId'),
            term: params.term
          }
        },
        processResults: function(data) { return { results: data } }
      },
      allowClear: true
    });
  }

  init() {
    this.handleChange();
  }

  handleChange() {
    $('.user-form-institution-select').on('change', function(e) {
      var $departmentSelect, $institutionSelect, $roleSelect, centralized, role;
      $institutionSelect = $(this);
      $roleSelect = $('#user_role_id');
      role = $roleSelect.find('option:selected').text();
      centralized = $institutionSelect.select2('data').centralized;
      $departmentSelect = $('.user-form-department-select');
      $departmentSelect.val(null).trigger('change');
      if ($institutionSelect.select2('val').length > 0) {
        $departmentSelect.prop('disabled', false);
      } else {
        $departmentSelect.prop('disabled', true);
      }
      if (centralized && role === 'Institution instructor') {
        if (confirm('This institution is centralized. Change the role to instructor observer?')) {
          return switch_selection_by_text($roleSelect, 'Institution instructor observer');
        }
      } else if (!centralized && role === 'Institution instructor observer') {
        if (confirm('This institution is decentralized. Change the role to instructor?')) {
          return switch_selection_by_text($roleSelect, 'Institution instructor');
        }
      }
    });
  }
}

export default InstitutionSelect2;
