class TermsOfServiceDialog {
  constructor() {
    this.tosCheckbox = $('#tosCheckbox');
    this.signInButton = $('#tosSignInButton');
  }

  init() {
    this.signInButton.prop('disabled', true);

    this.tosCheckbox.on('click change', (e) => {
      if (e.target.checked) {
        this.signInButton.prop('disabled', false);
        this.signInButton.removeClass('btn-light');
        this.signInButton.addClass('btn-primary');
      } else {
        this.signInButton.prop('disabled', true);
        this.signInButton.removeClass('btn-primary');
        this.signInButton.addClass('btn-light');
      }
    });
  }
}

export default TermsOfServiceDialog;
