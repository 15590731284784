import 'jquery-ui';
import 'jquery-ui/ui/widgets/sortable';
import axios from 'axios';
import csrfToken from '../utils/csrf';
import Toast from '../utils/Toast';

class SortProcedure {
  constructor() {
    this.$sortableRow = $('[data-behavior=sortable-container]');
  }

  init() {
    this.bindEventListeners();
  }

  bindEventListeners() {
    this.$sortableRow.sortable({
      handle: '.handle',
      axis: 'y',
      update: function() {
        if(this.hasAttribute("data-update-url")) {
          const headers = {
            headers: {
              Accept: 'application/json, text/plain, */*',
              'Content-Type': 'application/json',
              'X-CSRF-Token': csrfToken()
            }
          };
          axios.post($(this).data('update-url'), JSON.stringify($(this).sortable('serialize')), headers)
          .then(response => {
            new Toast().success({message: 'Procedure updated.'});
          })
          .catch(error => {
            new Toast().danger({message: 'Unable to update procedure.'});
          });
        } else {
          document
            .querySelectorAll('.procedure-position')
            .forEach((element, index) => {
              element.value = index;
          });
        }
      }
    });
  }
}

export default SortProcedure;
