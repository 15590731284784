import { Modal } from 'react-bootstrap';
import GenericButton from '../GenericButton';
const UrlPusherModalView = (
  {
    showModal,
    urls,
    handleClose,
    onClick
  }
) => {

  const generatePushUrls = () => {
    if (urls !== undefined) {
      return urls.map(url =>
        <GenericButton key={url.url} className="btn btn-primary btn-block" text={url.text} onClick={() => { onClick(url.url) } }/>
      )
    }
  }

  return  (
    <Modal show={showModal} onHide={handleClose} size="sm">
      <Modal.Header closeButton>
        Push URL
      </Modal.Header>
      <Modal.Body>
        { generatePushUrls() }
      </Modal.Body>
    </Modal>
  )
}

export default UrlPusherModalView;
