import PropTypes from 'prop-types'
import ChatMessage from './ChatMessage';
/**
 * Component represents chat messages.
 */
class ChatMessages extends React.Component {
  constructor(props) {
    super(props)
  }


  /**
   * @see Component#render()
   */
  render() {
    const messages = this.props.messages.map((message, i) => {
      return (
        <ChatMessage
          key={i}
          chatMessage={message.chatMessage}
          messageTime={message.messageTime}
          userType={message.userType}
        />
      );
    });

    return (
      <div className="text-chat-messages">
        {messages}
      </div>
    );
  }
}

ChatMessages.defaultProps = {
  messages: []
}

ChatMessages.propTypes = {
  messages: PropTypes.array.isRequired
}

export default ChatMessages
