import React, { useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import csrfToken from '../../src/utils/csrf';
import Toast from '../../src/utils/Toast';

const Detect = ({ denyListId, detect, url }) => {
  const [disabled, setDisabled] = useState(false);
  const [checked, setChecked] = useState(detect);
  const [label, setLabel] = useState(detect ? 'Disable' : 'Enable');
  const update_url = url || `${window.location.origin}/guardian_browser_denylists/${denyListId}`;

  const handleCheckboxChange = () => {
    setChecked(!checked);
    setDisabled(true);
    const headers = {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken(),
    };

    let params = {
      id: denyListId,
      detect: !checked,
    };

    axios
      .put(update_url, params, headers)
      .then((response) => {
        new Toast().success(response.data);
        setDisabled(false);
        setLabel((prevLabel) =>
          prevLabel === 'Enable' ? 'Disable' : 'Enable'
        );
      })
      .catch((error) => {
        new Toast().danger(error.response.data);
        setChecked(detect);
        setDisabled(false);
      });
  };

  return (
    <div>
      <label>
        <input
          type="checkbox"
          checked={checked}
          onChange={handleCheckboxChange}
          disabled={disabled}
        />
        <span style={{ marginLeft: '8px' }}>{label}</span>
      </label>
    </div>
  );
};

Detect.propTypes = {
  denyListId: PropTypes.number.isRequired,
  detect: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
};

export default Detect;
