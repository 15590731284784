class CancelledAppointment extends React.Component {
  render() {
    return (
      <div className="alert alert-danger alert-form">
        <h4 className="strong">Cancelled appointment!</h4>
        <p>It appears this appointment has been cancelled.</p>
        <p>If you need to continue onto the Flight Path below, please <a className='js-show-disabled-flight-path alert-link'>click here.</a></p>
      </div>
    )
  }
}

export default CancelledAppointment;
