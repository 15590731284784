import Cjs from '@meazure/copernicusjs';

function copernicus() {
  let instance = null;

  /** @type {import('@meazure/copernicusjs').IceServer[]} */
  const iceServers = [
    {
      urls: document.querySelector('span.env-data').getAttribute('turnServer'),
      username: document
        .querySelector('span.env-data')
        .getAttribute('turnUser'),
      credential: document
        .querySelector('span.env-data')
        .getAttribute('turnPass'),
    },
  ];

  return {
    /**
     * Return the CjsLib singleton instance for second camera
     *
     * @param {string} fulfillmentId
     * @param {HTMLVideoElement} videoElement
     * @param {string} videoInputDevice
     * @param {string} region - AWS region for recording/chat
     * @returns {Cjs}
     */
    getInstance: async (
      fulfillmentId,
      videoElement,
      videoInputDevice,
      region,
      videoLayout,
    ) => {
      if (!instance) {
        let userUUID = document
          .querySelector('span.user-data')
          .getAttribute('uuid');
        let userID = document
          .querySelector('span.user-data')
          .getAttribute('userId');

        console.log(userUUID, userID);

        /** @type {import('@meazure/copernicusjs').CjsSettings} */
        const settings = {
          userID,
          userUUID,
          role: 'secondCamera',
          exam: fulfillmentId,
          iceServers,
          domElements: {
            secondCamera: videoElement,
          },
          mediaServerUrl: new URL(
            document.querySelector('span.env-data').getAttribute('mediaServer'),
          ),
          videoServiceUrl: new URL(
            document.querySelector('span.env-data').getAttribute('videoServer'),
          ),
          region: region,
          videoLayout: videoLayout,
          devices: {
            webcam: videoInputDevice,
          },
        };

        /** @type {import('@meazure/copernicusjs').CjsCallbacks} */
        const callbacks = {
          onError: (event) => console.error(event),
          onConnect: (event) => console.debug(event),
          onDisconnect: (event) => console.debug(event),
          onEvent: (event) => console.debug(event),
        };
        console.info('Initializing CJS', settings);
        instance = new Cjs(settings, callbacks);
      }

      return instance;
    },
  };
}

module.exports = {
  copernicus: copernicus(),
};
