export function startVideoStream(audioDeviceId, videoDeviceId, videoElement) {
  navigator.mediaDevices.getUserMedia({
    audio: {
      deviceId: {
        exact: audioDeviceId
      }
    },
    video: {
      deviceId: {
        exact: videoDeviceId
      }
    }
  })
  .then(function (stream) {
    videoElement.srcObject = stream;
    videoElement.play();
  });
};

export function getMediaDevicesInfo(audio, video) {
  return new Promise((resolve, reject) => {
    if (audio || video) {
      navigator.mediaDevices
        .getUserMedia({ audio, video })
        .then((stream) => {
          navigator.mediaDevices.enumerateDevices()
            .then((mediaDevicesInfo) => {
              // stop streaming
              [...stream.getAudioTracks(), ...stream.getVideoTracks()]
                .forEach((track) => {
                  if (track) {
                    track.stop();
                  }
                });
              resolve(mediaDevicesInfo);
            })
            .catch((e) => {
              reject(new Error('Failed to get media devices info.'));
            });
        })
        .catch((e) => {
          reject(new Error('Audio and video should be allowed.'));
        });
    } else {
      // if we don't need to stream audio or video we don't get any device info
      resolve(null);
    }
  });
}
