import axios from 'axios';
import {useEffect, useState} from 'react';
import csrfToken from '../../../../src/utils/csrf';
import ActionButtons from "../Shared/ActionButtons";

const SecureEnvironmentProcedure = (props) => {
	const procedure = props.procedure;
	const sendEvent = props.sendEvent;
	const handleNext = props.handleNext;
	const openModal = props.openModal;
	const closeModal = props.closeModal;
	const markProcCompleted = props.markProcCompleted;
	const getFulfillmentEvents = props.getFulfillmentEvents;
	const fulfillment = props.fulfillment
	const [glImages, setGlImages] = useState([]);
	const getVideoPlayerEvent = props.getVideoPlayerEvent;
	const showInPlayer = props.showInPlayer;

	const resolveEvent = 'Event::ResolutionStartedSecureEnvironment';

	useEffect(() => {
		const getGlImages = async (img_size = null) => {
			let img_url = `${window.location.origin}/api/secure_browser/events/prechecks/${fulfillment.uuid}/images`;

			if (img_size != null) {
				img_url = `${img_url}?img_size=${img_size}`;
			}
			return await axios.get(
				img_url,
				{},
				{
					Accept: 'application/json',
					'Content-Type': 'application/json',
					'X-CSRF-Token': csrfToken()
				})
				.then(response => {
					if (response.data && response.data.hasOwnProperty('glImages')) {
						let newArray = JSON.parse(response.data.glImages);
						setGlImages(newArray);
					}
				})
				.catch(error => {
					console.log(error);
				});
		};

		getFulfillmentEvents(['Event::ProctorSecureEnvironmentCompleted'], (response) => {
			if(response.data.events.length > 0){
				markProcCompleted(procedure.id);
			} else {
				sendEvent('Event::ProctorSecureEnvironmentStarted', {});
				getGlImages();
			}
		})
	},[]);

	const completeProcedure = () => {
		sendEvent('Event::ProctorSecureEnvironmentCompleted', {});
		markProcCompleted(procedure.id);
		handleNext();
	};

	const resolveProcedure = () => {
		openModal();
		sendEvent(resolveEvent, {});
	}

	return(
		<>
			<ul className="flightpath-check gl-fp-secure-environment-photos list-unstyled">
				{glImages.map((item, index) => (
					<li key={index}>
						<div className="img-overlay">
							<p><small><b>{item.caption}</b></small></p>
							<img
								className="img-fluid mx-auto"
								data-behavior="picture-zoom"
								data-id={item.event_id}
								data-event-url={`/events/${item.event_id}`}
								src={item.image_url}
								width="3000"
								height="200"
							/>
						</div>
					</li>
				))}
			</ul>
			<br/>
			<ActionButtons
				resovleBtnText={'Resolve...'}
				confirmBtnText={'Env Presents No Security Risks'}
				confirmBtnAction={completeProcedure}
				resolveBtnAction={resolveProcedure}
			/>
		</>
	)
}

export default SecureEnvironmentProcedure;
