class AdjustContentHeight {
  constructor() {
    this.flightPathNav = document.querySelector('.nav-flightpath');
    this.activeTab = document.querySelector('.js-flightpath-card .tab-content');
  }

  init() {
    const flightPathHeight = this.flightPathNav?.offsetHeight;
    if(this.activeTab !== null) {
      this.activeTab.style.minHeight = `${flightPathHeight}px`
    }
  }
}

export default AdjustContentHeight;
