import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';

/**
 * @typedef {Object} CollapsibleCardProps
 * @property {string} headerText - The text to display in the card header.
 * @property {React.ReactNode} children - The content to display inside the card.
 */
const CollapsibleCard = ({ headerText, children }) => {
  const [expanded, setExpanded] = useState(true);

  return (
    <div className="card mb-4">
      <div
        className="card-header collapsible cursor-pointer"
        onClick={() => setExpanded(!expanded)}
      >
        <span className="mr-2">
          <i className={`fa fa-${expanded ? 'minus' : 'plus'}`} />
        </span>
        {headerText}
      </div>
      <Collapse in={expanded}>
        <div>{children}</div>
      </Collapse>
    </div>
  );
};

export default CollapsibleCard;
