import AdjustContentHeight from './shared_actions/AdjustContentHeight';
import UpdateNextAndPreviousButtons from './shared_actions/UpdateNextAndPreviousButtons';
import ShowNextTab from './shared_actions/ShowNextTab';

class NextButton {
  constructor() {
    this.nextButton = document.querySelector('.js-next-step');
  }

  init() {
    this.nextButton.addEventListener('click', (event) => { this.handleClick(event) });
  }

  handleClick(event) {
    event.preventDefault();
    new ShowNextTab();
    new UpdateNextAndPreviousButtons().init();
  }
}

export default NextButton;
