import 'jquery-ui/ui/widgets/sortable';
import PropTypes from 'prop-types';
import UrgencyLevelItem from './UrgencyLevelItem';

class UrgencyLevelPane extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.initializeSortable()
  }

  initializeSortable() {
    var component = this;

    $('.js-sortable').sortable({
      connectWith: '.js-sortable',
      update: function() {
        component.updateAllUrgencyLevels($(this))
      }
    });
  }

  updateAllUrgencyLevels($sortableEl) {
    var component = this;
    var params = {
      ids: $sortableEl.sortable('toArray'),
      priority: $sortableEl.data('priority')
    }

    $.ajax({
      type: 'PUT',
      url: '/urgency-levels/update_all',
      data: params,
      success: function(response) {
        component.props.toggleAlert('Your changes have been saved!', 'success');
      },
      error: function(response) {
        $('.js-sortable').sortable('cancel');
        component.props.
          toggleAlert('An error occured. Please refresh the page and try again.',
                      'danger');
      }
    });
  }

  cardClass() {
    return this.cardClassMap()[this.props.priority.toLowerCase()]
  }

  cardClassMap() {
    return {
      'high': 'border-danger',
      'medium': 'border-warning',
      'low': 'border-success'
    }
  }

  priorityMap() {
    return {
      'High': 'red',
      'Medium': 'yellow',
      'Low': 'green'
    }
  }

  cardClasses() {
    return ['card', this.cardClass()].join(' ')
  }

  render() {
    return (
      <div className="col-md-4">
        <div className={this.cardClasses()}>
          <div className="card-body">
            <h4 className="card-title">{this.props.priority}</h4>
            <ul className="list-group list-urgencies js-sortable"
              data-priority={this.priorityMap()[this.props.priority]}>
              { this.props.urgencyLevels.map(function(urgencyLevel) {
                return (
                  <UrgencyLevelItem key={urgencyLevel.id}
                    urgencyLevel={ urgencyLevel } />
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

UrgencyLevelPane.proptypes = {
  urgencyLevels: PropTypes.array.isRequired,
  priority: PropTypes.string.isRequired,
  toggleAlert: PropTypes.func.isRequired
}

export default UrgencyLevelPane;
