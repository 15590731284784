class GuidedLaunchOtherResources {
  constructor() {
    this.resourceCheckCheckbox =
      document.getElementById(
        'institution_enable_guided_launch_resource_check',
      ) ||
      document.getElementById(
        'department_enable_guided_launch_resource_check',
      ) ||
      document.getElementById('iteration_enable_guided_launch_resource_check');
    this.otherResourcesSection = document.getElementById(
      'gl_res_check_other_res_check',
    );
    this.otherResourcesCheckbox =
      document.getElementById('institution_enable_other_resources_check') ||
      document.getElementById('department_enable_other_resources_check') ||
      document.getElementById('iteration_enable_other_resources_check');

    this.toggleOtherResourcesSection =
      this.toggleOtherResourcesSection.bind(this);
  }

  init() {
    if (
      this.resourceCheckCheckbox &&
      this.otherResourcesSection &&
      this.otherResourcesCheckbox
    ) {
      this.bindEventListeners();
      this.toggleOtherResourcesSection();
    }
  }

  bindEventListeners() {
    this.resourceCheckCheckbox.addEventListener(
      'change',
      this.toggleOtherResourcesSection,
    );
  }

  toggleOtherResourcesSection() {
    if (this.resourceCheckCheckbox.checked) {
      this.otherResourcesSection.style.display = '';
    } else {
      this.otherResourcesSection.style.display = 'none';
      this.otherResourcesCheckbox.checked = false;
    }
  }
}

export default GuidedLaunchOtherResources;
