import PropTypes from 'prop-types';

const CopyPasteIncident = ({
  body,
  showInPlayer,
  subtype,
  createdAt
}) => {
  const icon = subtype === "copy" ? "fa-clone" : "fa-clipboard"

  return (
    <div className="row align-items-center">
      <div className="col-1">
        <div className="h-100 text-center d-flex align-items-center justify-content-center">
          <span className="fad fa-play-circle fa-lg player-icon cursor-pointer" onClick={showInPlayer}/>
        </div>
      </div>
      <div className="col-11">
        <small className="text-muted">{createdAt}</small>
        <div>
          {body}
        </div>
      </div>
    </div>
  );
}

CopyPasteIncident.propTypes = {
  body: PropTypes.string.isRequired,
  showInPlayer: PropTypes.func.isRequired,
  subtype: PropTypes.string.isRequired
}

export default CopyPasteIncident
