import PropTypes from 'prop-types';

const PeripheralEvent = ({ createdAt, text }) => {
  return (
    <div className="row align-items-center">
      <div className="col-1" />
      <div className="col-8">
        <small className="text-muted">{createdAt}</small>
        <div>{text}</div>
      </div>
    </div>
  );
};

PeripheralEvent.propTypes = {
  createdAt: PropTypes.string.isRequired,
  text: PropTypes.string,
};

export default PeripheralEvent;
