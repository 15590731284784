export const getChatRooms = async (page = 1, perPage = 20) => {
  const response = await fetch(`/chat_rooms?page=${page}&per_page=${perPage}`);
  const data = await response.json();
  return {
    chatRooms: data.chat_rooms,
    totalPages: data.total_pages,
    totalItems: data.total_items,
    currentPage: data.current_page,
  };
};

export const getChatRoom = async (id) => {
  const response = await fetch(`/chat_rooms/${id}`, {
    headers: {
      Accept: 'application/json',
    },
  });
  const chatRoom = await response.json();
  return chatRoom;
};
