import { useEffect, useState, useRef } from 'react';
import PrechecksBodyTitle from './CardSections/PrechecksBodyTitle';
import PrechecksBodyText from './CardSections/PrechecksBodyText';
import PrechecksFooterButton from './CardSections/PrechecksFooterButton';
import PrechecksBodyCamera from './CardSections/PrechecksBodyCamera';

const GuidedLaunchCellPhoneCheckCard = ({
  id,
  icon,
  title,
  paragraph1,
  handleNext,
  sendEvent,
  streamWebcam,
  precheckData,
  iconPrefix,
}) => {
  const phoneScanningImg = precheckData.images.cellPhoneCheckImg;
  const [roomScanPictureTaken, setRoomScanPictureTaken] = useState(false);
  const [btnText, setBtnText] = useState(
    polyglot.t('prechecks_guidedlaunch_cell_phone_check_button'),
  );
  const canvasRef = useRef();
  const videoRef = useRef();
  const height = 360;
  const width = 480;

  useEffect(() => {
    sendEvent('Event::CellPhoneCheckLoaded');
    streamWebcam(videoRef.current);
  }, []);

  const handleBtnClick = () => {
    sendEvent('Event::CellPhoneCheckCompleted');
    handleNext();
  };

  return (
    <div className="container-fluid text-center precheck-card-content" id={id}>
      <div className="mt-2">
        <img
          src={phoneScanningImg.src}
          height={'200'}
          alt={phoneScanningImg.altText}
        />
      </div>
      <PrechecksBodyTitle title={title} icon={icon} iconPrefix={iconPrefix} />
      <div className="text-left">
        <PrechecksBodyText bodyText={paragraph1} />
      </div>
      <PrechecksBodyCamera
        canvasRef={canvasRef}
        videoRef={videoRef}
        showCanvas={roomScanPictureTaken}
        showVideo={!roomScanPictureTaken}
        height={height}
        width={width}
        precheckData={precheckData}
        area-label="Webcam Stream"
      />
      <PrechecksFooterButton
        buttonText={btnText}
        handleClick={handleBtnClick}
      />
    </div>
  );
};

GuidedLaunchCellPhoneCheckCard.defaultProps = {
  title: polyglot.t('prechecks_guidedlaunch_cell_phone_check_title'),
  paragraph1: polyglot.t('prechecks_guidedlaunch_cell_phone_check_body'),
  icon: 'fa-camera',
  iconPrefix: 'fal',
  statusColor: 'primary',
  iconColor: 'primary',
};

export default GuidedLaunchCellPhoneCheckCard;
