import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';

class ProctorControlConfirmationButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirm: false,
    };
    this.target = React.createRef();
  }

  confirmTerminate = () => {
    const { id, action, name } = this.props;
    this.setState({ showConfirm: false });
    this.props.publishMessage(id, action, name);
  };

  render() {
    const { name, id, className, tooltipText, isBtnDisabled } = this.props;

    return (
      <div
        className={`m-1 flex-fill custom-tooltip`}
        style={{ width: '-webkit-fill-available' }}
      >
        <button
          disabled={isBtnDisabled}
          id={id}
          type="button"
          style={{ width: '-webkit-fill-available' }}
          className={`btn ${className}`}
          ref={this.target}
          onClick={() =>
            this.setState({
              showConfirm: !this.state.showConfirm,
            })
          }
        >
          {name}
        </button>
        <span className="tooltip-text">
          The <strong>{name} Button </strong>
          {tooltipText}
        </span>
        <Overlay
          target={this.target.current}
          show={this.state.showConfirm}
          placement="bottom"
        >
          <Popover id="popover-contained" className="custom-popover-arrow">
            <Popover.Content>
              <div>
                <span>Are you sure you wish to terminate this exam?</span>
                <div className="d-flex flex-row">
                  <button
                    className="btn btn-danger w-100"
                    onClick={this.confirmTerminate}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </Popover.Content>
          </Popover>
        </Overlay>
      </div>
    );
  }
}

export default ProctorControlConfirmationButton;
