import Dropzone from 'dropzone';

 class PictureDragDrop {
  constructor() {
    this.counter = 0;
  }

  init() {
    this._profilePictureDragDrop();
    this._initializeDragDropEvents($('#js-drag-and-drop'), $('.js-avatar-wrapper'));
  }

  _profilePictureDragDrop() {
    if (!$('#js-drag-and-drop').length) return;

    Dropzone.autoDiscover = false;
    Dropzone.options.jsDragAndDrop = {
      paramName: 'profile_picture[image]',
      method: 'patch'
    };

    const mediaDropzone = new Dropzone('#js-drag-and-drop');

    mediaDropzone.on('dragenter', (event) => {
      event.preventDefault();
      if ($('#event-media-modal').hasClass('in')) return;
      if (!this._containsFiles(event)) return;

      this.counter++;

      $('.js-image-overlay').addClass('in');
      $('.js-avatar-wrapper').addClass('with-overlay');
    });

    mediaDropzone.on('drop', (event) => {
      return this.counter--;
    });

    mediaDropzone.on('success', function(file, response) {
      $('.js-image-overlay').removeClass('in');
      $('.js-avatar-wrapper').removeClass('with-overlay');
      $('.js-detect').attr('src', response.url);
      $('.js-dnd-error').text('');
    });

    mediaDropzone.on('error', function(file, response) {
      $('.js-image-overlay').removeClass('in');
      $('.js-avatar-wrapper').removeClass('with-overlay');
      $('.js-dnd-error').text(response.image[0]);
    });
  }

  _initializeDragDropEvents(dropContainer, avatarContainer) {
    if (!dropContainer.length) {
      return;
    }

    $('body').append("<div class='image-overlay js-image-overlay'></div>");

    return $(document).bind({
      dragenter: (e) => {
        if ($('#event-media-modal').hasClass('in')) return;
        if (!this._containsFiles(e.originalEvent)) return;

        this.counter++;

        $('.js-image-overlay').addClass('in');

        if (!avatarContainer) return;

        avatarContainer.addClass('with-overlay');
      },
      dragleave: () => {
        if ($('#event-media-modal').hasClass('in')) return;

        this.counter--;

        if (this.counter === 0) {
          $('.js-image-overlay').removeClass('in');

          if (!avatarContainer) return;

          avatarContainer.removeClass('with-overlay');
        }
      }
    });
  }

  _containsFiles(e) {
    if(!e.dataTransfer.types) return false;

    for(const type of e.dataTransfer.types) {
      if (type === 'Files') return true;
    }
  }
}

export default PictureDragDrop;
