class ProctorControlButton extends React.Component {
  constructor(props) {
    super(props);
  }

  handleOnClick = () => {
    const { id, action, name } = this.props;
    this.props.publishMessage(id, action, name);
  };

  render() {
    const { name, id, className, tooltipText, isBtnDisabled } = this.props;
    return (
      <div
        className={`m-1 flex-fill custom-tooltip`}
        style={{ width: '-webkit-fill-available' }}
      >
        <button
          disabled={isBtnDisabled}
          id={id}
          type="button"
          style={{ width: '-webkit-fill-available' }}
          className={`btn ${className}`}
          onClick={this.handleOnClick}
        >
          {name}
        </button>
        <span className="tooltip-text">
          The <strong>{name} Button </strong>
          {tooltipText}
        </span>
      </div>
    );
  }
}

export default ProctorControlButton;
