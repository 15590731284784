const ExamInfoContainer = ({ precheckStep, precheckData }) => {
  const title = precheckData?.exam?.name ?? '';

  const institutionName = precheckData?.institution?.name ?? '';
  const instructorName = precheckData?.exam?.instructorName ?? '';
  const subtitle = instructorName
    ? polyglot.t('prechecks_exam_info_subtitle_institution_and_instructor', {
        institution_name: institutionName,
        instructor_name: instructorName,
      })
    : polyglot.t('prechecks_exam_info_subtitle_institution', {
        institution_name: institutionName,
      });

  return (
    <section
      role="region"
      aria-labelledby="prechecks-exam-info"
      style={{
        position: 'absolute',
        maxWidth: '18rem',
        color: '#6c757d',
        fontWeight: '600',
      }}
    >
      {title && (
        <h2 id="prechecks-exam-info" className="fs-6 mb-0">
          {title}
        </h2>
      )}
      {institutionName && <h3 style={{ fontSize: '0.75rem' }}>{subtitle}</h3>}
    </section>
  );
};

export default ExamInfoContainer;
