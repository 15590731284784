import React, { useContext } from 'react';
import { Context } from './ExamPickerContext';
import { Dropdown } from 'react-bootstrap';
import axios from 'axios';
import csrfToken from '../../src/utils/csrf';

const ConfiguredExamRow = ({ exam, handleConfiguredModal, handleUserReportModal }) => {
  const addQueryStringToUrl = (url, queryString) => {
    const urlObject = new URL(url);
    const searchParams = new URLSearchParams(urlObject.search);
    const params = new URLSearchParams(queryString);

    for (const [key, value] of params.entries()) {
      searchParams.set(key, value);
    }

    urlObject.search = searchParams.toString();

    return urlObject.toString();
  }

  const { editExamEndpoint, insertLinkEndpoint} = useContext(Context);
  const queryString = `exam_id=${exam.id}`;
  const newUrl = addQueryStringToUrl(insertLinkEndpoint, queryString);

  return (
    <tr className="h-3">
    <td className="lms-left-column">{exam.exam_name}</td>
    <td className="lms-right-column">
      <Dropdown className="lms-dropdown-container">
        <Dropdown.Toggle variant="primary-meazure" id="dropdown-basic" className="lms-dropdown">
          <i className="fa-solid fa-gear custom-gear-icon"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu id="steps-dropdown">
          <Dropdown.Item href="#" onClick={() => handleConfiguredModal(editExamEndpoint.replace('EXAM_ID', exam.id), 'proctoring_settings', exam)}>Proctor Settings</Dropdown.Item>
          <Dropdown.Item href="#" onClick={() => handleConfiguredModal(editExamEndpoint.replace('EXAM_ID', exam.id), 'department', exam)}>Department</Dropdown.Item>
          <Dropdown.Item href="#" onClick={() => handleConfiguredModal(editExamEndpoint.replace('EXAM_ID', exam.id), 'configuration', exam)}>Configuration</Dropdown.Item>
          <Dropdown.Item href="#" onClick={() => handleConfiguredModal(editExamEndpoint.replace('EXAM_ID', exam.id), 'appointment_windows', exam)}>Exam Window</Dropdown.Item>
          <Dropdown.Item href="#" onClick={() => handleConfiguredModal(editExamEndpoint.replace('EXAM_ID', exam.id), 'permitted_tools', exam)}>Allowances</Dropdown.Item>
          <Dropdown.Item href="#" onClick={() => handleConfiguredModal(editExamEndpoint.replace('EXAM_ID', exam.id), 'notifications', exam)}>Notifications</Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item href="#" onClick={(event) => handleUserReportModal(exam, event)}>User Report</Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item href={newUrl}>Insert Link</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </td>
  </tr>
  );
};

export default ConfiguredExamRow;
