import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';

/**
 * @typedef PrechecksLmiDownloadFormProps
 * @property {PrecheckData} precheckData
 * @property {SendEventFunction} sendEvent
 */

/**
 * @typedef PrechecksLmiDownloadFormRef
 * @property {() => void} downloadLMI
 */

/**
 * A form component for downloading LogMeIn (LMI) based on precheck data.
 * This component is hidden and automatically submits upon mounting if certain conditions are met.
 * @type {
 *   React.ForwardRefExoticComponent<
 *     & React.PropsWithoutRef<PrechecksLmiDownloadFormProps>
 *     & React.RefAttributes<PrechecksLmiDownloadFormRef>
 *   >
 * }
 */
const PrechecksLmiDownloadForm = forwardRef(
  /**
   * @param {PrechecksLmiDownloadFormProps} props
   * @param {React.ForwardedRef<PrechecksLmiDownloadFormRef>} ref
   * @returns {React.ReactElement}
   */
  (props, ref) => {
    const {
      lmiDownload: {
        userName,
        institutionName,
        proctoringLanguage,
        lmiDownloadId,
      },
      integratedLmiChat,
      lmiChannel: { channelName, entryId, appletAddress },
    } = props.precheckData;

    const formRef = useRef(/** @type {HTMLFormElement} */ null);

    const downloadLMI = () => {
      if (integratedLmiChat) return;
      formRef.current?.submit();
      props.sendEvent('Event::LmiDownload');
    };

    useEffect(() => {
      downloadLMI();
    }, []);

    useImperativeHandle(ref, () => ({
      downloadLMI,
    }));

    return (
      <form
        className="d-none"
        action={appletAddress}
        acceptCharset="UTF-8"
        method="post"
        ref={formRef}
        onSubmit={(e) => e.preventDefault()}
      >
        <input name="utf8" type="hidden" value="✓" />
        <input type="hidden" name="authenticity_token" value="" />
        <input type="hidden" name="name" id="name" value={userName} />
        <input
          type="hidden"
          name="comment1"
          id="comment1"
          value={institutionName}
        />
        <input
          type="hidden"
          name="comment2"
          id="comment2"
          value={proctoringLanguage}
        />
        <input
          type="hidden"
          name="comment3"
          id="comment3"
          value={lmiDownloadId}
        />
        <input type="hidden" name="comment4" id="speed-field" value="" />
        <input type="hidden" name="comment5" id="issues-field" value="" />
        <input type="hidden" name="Entry" id="Entry" value={channelName} />
        <input type="hidden" name="EntryID" id="EntryID" value={entryId} />
        <input type="hidden" name="tracking0" id="tracking0" maxLength={64} />
      </form>
    );
  },
);

export default PrechecksLmiDownloadForm;
