class GlFlightPathProcedures {
  constructor() {
    this.glFlightPathProcsContainer = document.querySelector('#gl-procs');
    this.glFlightPathProcs = $('#gl-procs textarea');
  }

  init() {
    const glFlightPathEnabled = document.querySelector(
      '[data-enable-gl-flight-path-checkbox=true]',
    );
    this.fObject = JSON.parse(
      glFlightPathEnabled.getAttribute('data-f-object'),
    );

    if (
      this.fObject?.modelType === 'Department' ||
      this.fObject?.modelType === 'Iteration'
    ) {
      this.setupDepartmentOrIteration(glFlightPathEnabled);
    } else {
      this.setupDefault(glFlightPathEnabled);
    }
  }

  setupDepartmentOrIteration(glFlightPathEnabled) {
    const glFlightPathCustomized = document.querySelector(
      '[data-customize-gl-flight-path-checkbox=true]',
    );

    const toggleProcsContainerVisibility = () => {
      if (glFlightPathEnabled.checked) {
        this.glFlightPathProcsContainer.classList.remove('d-none');
      } else {
        this.glFlightPathProcsContainer.classList.add('d-none');
      }
    };

    const fetchAndUpdatePartial = () => {
      $.ajax({
        url: '/shared/attributes/dynamic_gl_flight_path_procedures',
        type: 'GET',
        data: {
          model_type: this.fObject.modelType,
          model_id: this.fObject.modelId,
          customize_gl_flight_path: glFlightPathCustomized.checked,
        },
        success: (data) => {
          this.glFlightPathProcsContainer.innerHTML = data;
        },
        error: (xhr, status, error) => {
          const errorMessage = `An error occurred: ${status} - ${error}`; // Corrected error message format
          console.error(errorMessage);
        }
      });
    };

    if (glFlightPathEnabled) {
      glFlightPathEnabled.addEventListener('change', toggleProcsContainerVisibility);
    }

    if (glFlightPathEnabled) {
      glFlightPathEnabled.addEventListener('change', fetchAndUpdatePartial);
    }

    if (glFlightPathCustomized) {
      glFlightPathCustomized.addEventListener('change', fetchAndUpdatePartial);
    }

    toggleProcsContainerVisibility(); // Initial check
  }

  setupDefault(glFlightPathEnabled) {
    if (glFlightPathEnabled && this.glFlightPathProcs) {
      glFlightPathEnabled.addEventListener('change', (event) => {
        if (event.target.checked) {
          this.glFlightPathProcsContainer.classList.remove('d-none');
        } else {
          this.glFlightPathProcsContainer.classList.add('d-none');
        }
      });
    }

    if (glFlightPathEnabled.checked) {
      this.glFlightPathProcsContainer.classList.remove('d-none');
    }

    this.glFlightPathProcs.map((index, proc) => {
      const isEditable = $(proc).data('editable');

      if (!isEditable) {
        $(proc).summernote('disable');
      }
    });
  }
}

export default GlFlightPathProcedures;
