import React from 'react';
import { createRoot } from 'react-dom/client';
import RegionConfirmationModal from '../components/Institutions/RegionConfirmationModal.js';

document.addEventListener('turbolinks:load', function () {
  const regionSelect = document.querySelector(
    '[data-behavior="region-select"]',
  );
  const modalContainer = document.getElementById('react-modal-container');

  // Only run if we're on the new institution path
  if (modalContainer && modalContainer.dataset.path === 'new_institution') {
    // Create the root once and reuse it
    const root = createRoot(modalContainer);

    const handleConfirm = () => {
      renderRegionConfirmationModal(false, handleConfirm, handleCancel);
    };

    const handleCancel = () => {
      regionSelect.value = '';
      renderRegionConfirmationModal(false, handleConfirm, handleCancel);
    };

    // Helper function to render the modal
    const renderRegionConfirmationModal = (show, onConfirm, onCancel) => {
      root.render(
        <RegionConfirmationModal
          show={show}
          handleConfirm={onConfirm}
          handleCancel={onCancel}
        />,
      );
    };

    // Add event listener to region select element
    if (regionSelect) {
      regionSelect.addEventListener('change', function () {
        const selectedRegion = this.value;
        if (selectedRegion && selectedRegion.trim() !== '') {
          renderRegionConfirmationModal(true, handleConfirm, handleCancel);
        }
      });
    }
  }
});
