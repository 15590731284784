const DayOptions = [...Array(31).keys()].map((day) => {
  const dayPlusOne = day + 1;
  const value = `0${dayPlusOne}`.slice(-2);
  return <option key={dayPlusOne} value={value}>{dayPlusOne}</option>
})

// years 2018 - 1900
const YearOptions = _.range(moment().year(), 1900, -1).map((year) => {
  return <option key={year} value={year.toString()}>{year}</option>
})

const DateSelect = ({field, generatedId}) => {
  return (
    <>
      <div className="form-inline">
        <select
          id={`user_enrollments_attributes_${generatedId}_fields_${field.field_as_attribute}_2i`}
          name={`[user][enrollments_attributes][${generatedId}][fields][${field.field_as_attribute}(2i)]`}
          className="form-control w-auto month mr-1" data-behavior="date_select"
          data-type="date_select">
          <option value="">Month</option>
          <option value="01">January</option>
          <option value="02">February</option>
          <option value="03">March</option>
          <option value="04">April</option>
          <option value="05">May</option>
          <option value="06">June</option>
          <option value="07">July</option>
          <option value="08">August</option>
          <option value="09">September</option>
          <option value="10">October</option>
          <option value="11">November</option>
          <option value="12">December</option>
        </select>
        <select
          id={`user_enrollments_attributes_${generatedId}_fields_${field.field_as_attribute}_3i`}
          name={`[user][enrollments_attributes][${generatedId}][fields][${field.field_as_attribute}(3i)]`}
          className="form-control w-auto day mr-1"
          data-behavior="date_select"
          data-type="date_select">
            <option value="">Day</option>
            { DayOptions }
        </select>
        <select
          id={`user_enrollments_attributes_${generatedId}_fields_${field.field_as_attribute}_1i`}
          name={`[user][enrollments_attributes][${generatedId}][fields][${field.field_as_attribute}(1i)]`}
          className="form-control w-auto year"
          data-behavior="date_select"
          data-type="date_select">
            <option value="">Year</option>
            { YearOptions }
        </select>
      </div>
      <div className="form-text text-muted">{field.description}</div>
    </>
  )
}

export default DateSelect
