import axios from 'axios';
import PropTypes from 'prop-types';
import csrfToken from '../../src/utils/csrf';
import Toast from '../../src/utils/Toast';
import GenericButton from '../GenericButton';

class ActivateButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { disabled: false, text: 'Activate' };
    this.onClick = this.onClick.bind(this);
  }

  onClick(event) {
    event.preventDefault();
    this.setState({ disabled: true });
    const headers = {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken()
      }
    };
    let params = {
      iterationId: this.props.iterationId,
      institutionId: this.props.institutionId
    };
    axios.put(this.props.url, JSON.stringify(params), headers)
    .then(response => {
      this.setState({ text: 'Activated' });
      new Toast().success(response.data);
    })
    .catch(error => {
      this.setState({ disabled: false });
      new Toast().danger(error.response.data);
    });
  }

  render() {
    return (
      <GenericButton
        className="btn btn-primary btn-sm"
        onClick={this.onClick}
        disabled={this.state.disabled}
        text={this.state.text}
      />
    );
  }
}

ActivateButton.propTypes = {
  iterationId: PropTypes.number.isRequired,
  institutionId: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired
};

export default ActivateButton;
