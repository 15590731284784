import axios from 'axios';
import {useEffect, useState} from 'react';
import csrfToken from "../../../../src/utils/csrf";
import ActionButtons from "../Shared/ActionButtons";

const VerifyIdProcedure = (props) => {
	const procedure = props.procedure;
	const sendEvent = props.sendEvent;
	const handleNext = props.handleNext;
	const openModal = props.openModal;
	const closeModal = props.closeModal;
	const procedureCompleted = props.procedureCompleted
	const markProcCompleted = props.markProcCompleted;
	const getFulfillmentEvents = props.getFulfillmentEvents;
	const fulfillment = props.fulfillment;
	const [profilePhotoEvent, setProfilePhotoEvent] = useState(null);
	const [idPhotoEvent, setIdPhotoEvent] = useState(null);


	const resolveEvent = 'Event::ResolutionStartedVerifyId';

	useEffect(() => {
		getFulfillmentEvents(['Event::ProctorVerifyIdCompleted'], (response) => {
			if(response.data.events.length > 0){
				markProcCompleted(procedure.id);
			}
		})
	},[]);

	useEffect(() => {
		const getIdVerificationEvents = () => {
			axios.get(
				`${window.location.origin}/api/fulfillments/${fulfillment.uuid}/events/id_verification`,
				{},
				{
					Accept: 'application/json',
					'Content-Type': 'application/json',
					'X-CSRF-Token': csrfToken()
				}
			)
				.then( response => {
					console.log(response);
					setProfilePhotoEvent(response.data.profilePhoto);
					setIdPhotoEvent(response.data.idPhoto);
				})
				.catch( error => {
					console.log(error);
				});
		};

		getIdVerificationEvents()
	},[]);

	const onProctorVerifyIdStartedSettled = (_) => {
		sendEvent('Event::ProctorVerifyIdCompleted', {});
		markProcCompleted(procedure.id);
		handleNext();
	};

	const completeProcedure = () => {
		// passing the same callback for both success and error response
		// Here the purpose is to maintain the sequence of the execution irrespective of the return since that is the default behaviour
		// Need to pass a proper error callback function once the error flow is finalized
		sendEvent('Event::ProctorVerifyIdStarted', {}, 'gl_flightpath', onProctorVerifyIdStartedSettled, onProctorVerifyIdStartedSettled);
	};

	const resolveProcedure = () => {
		openModal();
		sendEvent(resolveEvent, {});
	}
	return(
		<>
			<ul className="flightpath-check list-unstyled">
				<li className="flightpath-confirmation js-picture-confirmation">
					<div className="inner">
						<h5 className="text-center">Test-Taker Photo</h5>
						<div className="img-overlay">
							{	profilePhotoEvent?.id ?						
									(<img 
										className="img-fluid mx-auto"
										data-behavior="picture-zoom"
										data-id={profilePhotoEvent?.id}
										data-event-url={`/events/${profilePhotoEvent?.id}`}
										src={profilePhotoEvent.attachmentUrl}
										width="3000"
										height="200"
									/>
								) :
								(
									<img 
										className="img-fluid mx-auto"
										src="/assets/id-owl-e2ee4e2b023b11088e8cacaf055bbef575ef2c256ef0a53cc009bfbb711a6a43.svg"
										width="3000"
										height="200"
									/>
								)
							}
							{/* <div className="img-options">
								<a className="option js-picture-confirmation-retake js-new-pic">Take picture confirmation</a>
							</div> */}
						</div>
					</div>
				</li>
				<li className="flightpath-confirmation js-picture-confirmation">
					<div className="inner">
						<h5 className="text-center">Test-Taker ID</h5>
						<div className="img-overlay">
							{	idPhotoEvent?.id ?						
								(<img 
									className="img-fluid mx-auto"
									data-behavior="picture-zoom"
									data-id={idPhotoEvent?.id}
									data-event-url={`/events/${idPhotoEvent?.id}`}
									src={idPhotoEvent.attachmentUrl}
									width="3000"
									height="200"
								/>
							) :
							(
								<img 
									className="img-fluid mx-auto"
									src="/assets/id-card-07c345e67d079c0d2dc22487b92289a21a57a4db3d87b339daa7eddb669c75eb.svg"
									// src="/assets/id-card-removed.svg"
									width="3000"
									height="200"
								/>
							)
							}
							{/* <div className="img-options">
								<a className="option js-picture-confirmation-retake js-new-pic">Retake</a>
							</div> */}
						</div>
					</div>
				</li>
			</ul>
			<ActionButtons
				resovleBtnText={'Resolve...'}
				confirmBtnText={'ID and Image Match'}
				confirmBtnAction={completeProcedure}
				resolveBtnAction={resolveProcedure}
			/>
		</>
	)
}

export default VerifyIdProcedure;
