const Email = ({field, generatedId}) => {
  return (
    <div>
      <input type="text"
        name={`user[enrollments_attributes][${generatedId}][fields][${field.field_as_attribute}]`}
        id={`[user][enrollments_attributes][${generatedId}][fields][${field.field_as_attribute}]`}
        className="form-control" data-type="email" />
      <div className="form-text text-muted">{field.description}</div>
    </div>
  )
}

export default Email
