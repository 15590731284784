/*jshint esversion: 6 */

import { useState } from 'react';

export const PrechecksVideoPlayer = ({ videoInfo, idPrefix }) => {
  const [isPlaying, setIsPlaying] = useState(true);

  const togglePlay = () => {
    const videoPlayerElement = document.getElementById(idPrefix + '-player');
    if (videoPlayerElement) {
      if (isPlaying) {
        videoPlayerElement.pause();
        setIsPlaying(false);
      } else {
        videoPlayerElement.play();
        setIsPlaying(true);
      }
    }
  };

  return (
    <div className={idPrefix + '-container'}>
      <button
        onClick={togglePlay}
        className="precheck-animation-play-pause"
        aria-label={(isPlaying ? 'Pause' : 'Play') + ' instruction video'}
      >
        {isPlaying ? (
          <i className={'fa fa-pause'}></i>
        ) : (
          <i className={'fa fa-play'}></i>
        )}
      </button>
      <video
        id={idPrefix + '-player'}
        src={videoInfo.src}
        loop
        autoPlay={true}
        controls={false}
        aria-label={videoInfo.altText}
      />
    </div>
  );
};
