import getDateTime from './shared/getDateTime';
import getTimezone from './shared/getTimezone';
import checkUrl from './shared/checkUrl';

class SlotsUrlBuilder {
  constructor() {
    this.reservationContent = document.querySelector('#reservation-content')
    this.url = this.reservationContent.getAttribute('data-url')
  }

  init() {
    this.addDateToUrl()
    this.addTimeZoneToUrl()
    this.addTakeItNowToUrl()
    this.addUserToUrl()
    this.addFulfillmentToUrl()
    this.addBlueBirdToUrl()
    this.addExamNameToUrl()
    this.addInstitutionToUrl()
    this.addTermToUrl()
    this.addPtcToUrl()
    this.addPreferredTimeFrameToUrl()
    this.addLocationToUrl()
    this.addVendorToUrl()
    this.addIterationToUrl()
    this.addLanguageToUrl()
    return this.url;
  }

  addDateToUrl() {
    const datetime = getDateTime().format('YYYY-MM-DDTHH:mm');
    this.url = `${checkUrl(this.url)}date=${datetime}`;
  }

  addTimeZoneToUrl() {
    const timezone = getTimezone();
    if (timezone) {
      this.url = `${checkUrl(this.url)}timezone=${timezone}`;
    }
  }

  addTakeItNowToUrl() {
    const takeNowDate = this.reservationContent.getAttribute('data-take-now');
    if (takeNowDate) {
      this.url = `${checkUrl(this.url)}now=true`;
    }
  }

  addUserToUrl() {
    const userUuid = this.reservationContent.getAttribute('data-user');
    if (userUuid) {
      this.url = `${checkUrl(this.url)}user=${userUuid}`;
    }
  }

  addFulfillmentToUrl() {
    const fulfillmentUuid = this.reservationContent.getAttribute('data-fulfillment');
    if (fulfillmentUuid) {
      this.url = `${checkUrl(this.url)}fulfillment=${fulfillmentUuid}`;
    }
  }

  addBlueBirdToUrl() {
    const bluebird = this.reservationContent.getAttribute('data-bluebird');
    if (bluebird) {
      this.url = `${checkUrl(this.url)}bluebird=${bluebird}`;
    }
  }

  addExamNameToUrl() {
    const examName = this.reservationContent.getAttribute('data-exam-name');
    if (examName) {
      this.url = `${checkUrl(this.url)}exam_name=${examName}`
    }
  }

  addInstitutionToUrl() {
    const institutionId = this.reservationContent.getAttribute('data-institution-id');
    if (institutionId) {
      this.url = `${checkUrl(this.url)}institution_id=${encodeURIComponent(institutionId)}`;
    }
  }

  addTermToUrl() {
    const termId = this.reservationContent.getAttribute('data-term-id');
    if (termId) {
      this.url = `${checkUrl(this.url)}term_id=${encodeURIComponent(termId)}`;
    }
  }

  addPtcToUrl() {
    const ptc = this.reservationContent.getAttribute('data-ptc');
    if (ptc) {
      this.url = `${checkUrl(this.url)}ptc=${encodeURIComponent(ptc)}`;
    }
  }

  addPreferredTimeFrameToUrl() {
    const preferredTimeframe = $('[data-behavior="preferred-time-select"]').find(':selected').val();
    if (preferredTimeframe) {
      this.url = `${checkUrl(this.url)}preferred_timeframe=${encodeURIComponent(preferredTimeframe)}`;
    }
  }

  addLocationToUrl() {
    const locationId = this.reservationContent.getAttribute('data-location-id');
    if (locationId) {
      this.url = `${checkUrl(this.url)}location_id=${encodeURIComponent(locationId)}`;
    }
  }

  addVendorToUrl() {
    const vendor = this.reservationContent.getAttribute('data-vendor');
    if (vendor) {
      this.url = `${checkUrl(this.url)}vendor=${encodeURIComponent(vendor)}`;
    }
  }

  addIterationToUrl() {
    const iterationId = this.reservationContent.getAttribute('data-iteration-id');
    const iterationParam = `iteration_id=${encodeURIComponent(iterationId)}`;

    if (iterationId && this.url.indexOf(iterationParam) < 0) {
      this.url = `${checkUrl(this.url)}${iterationParam}`;
    }
  }

  addLanguageToUrl() {
    const selectedLanguage = $('[data-behavior="preferred-proctoring-language"]').find(':selected').val();
    if (selectedLanguage) {
      this.url = `${checkUrl(this.url)}language_id=${encodeURIComponent(selectedLanguage)}`;
    }
  }
}

export default SlotsUrlBuilder
