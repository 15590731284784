import React from 'react';

export const NextExamCountdown = ({ time }) => {
  return (
    <div>
      NextExamCounter: {time.days} Days {time.hours} Hours {time.minutes}{' '}
      Minutes
    </div>
  );
};
