import PropTypes from 'prop-types';
import * as Events from './Events';
/**
 * The component creates incident element,
 * that displays date and provides UI to edit scores.
 */
class IncidentComponent extends React.Component {
  constructor(props) {
    super(props)
  }

  showInPlayer = () => {
    const { callbacks, incident } = this.props
    callbacks.fire({
      type: 'showEvent',
      data: { incident }
    })
  }

  chooseComponentByGroup = () => {
    const {
      incident: {
        score,
        uiMessageId,
        createdAt,
        body,
        feedable,
        feed_status: feedStatus,
        feed_url: feedUrl,
        feed_type: feedType,
      },
      group,
      transformStore,
      name,
      isDeleteCandidate,
      showEventFeed,
      institutionUser
    } = this.props

    const showInPlayer = this.showInPlayer

    switch (group) {
      case 'Security':
        return <Events.SecurityEvent {...{
          createdAt,
          showInPlayer,
          body,
          uiMessageId,
          transformStore,
          showEventFeed,
          feedable,
          feedStatus,
          feedUrl,
          feedType,
          institutionUser
        }} />
      case 'Scores':
        return (
          <Events.IncidentWithScores
            {...{
              score,
              createdAt,
              showInPlayer,
              uiMessageId,
              transformStore,
              isDeleteCandidate,
              showEventFeed,
              feedable,
              feedStatus,
              feedUrl,
              institutionUser
            }}
          />
        )
        break
      case 'Browser':
        return name == 'SystemKeys' ? (
          <Events.ConfirmationEvent {...{ createdAt, showInPlayer, body, institutionUser }} />
        ) : this.getBrowserIncidentByName(showInPlayer)
      case 'Prechecks':
        return this.getPreChecksIncidentByName(showInPlayer)
      default:
        return this.getExamGeneralByName(showInPlayer)
    }
  }

  getPreChecksIncidentByName = (showInPlayer) => {
    const {
      incident: { heading, createdAt, body, actionerName, uiMessageId, feedable, 
        body: text,
        feed_status: feedStatus,
        feed_url: feedUrl
      },
      name,
      showEventFeed,
      institutionUser
    } = this.props

    switch (name) {
      case 'IdConfirmation':
      case 'PictureConfirmation':
      case 'ExamRulesAgreementCheck':
      case 'SystemCheck':
        return <Events.ConfirmationEvent {...{ createdAt, showInPlayer, body, actionerName, institutionUser}} />
      case 'CaptureBelowWorkspaceCompleted':
      case 'CaptureBackWallCompleted':
      case 'CaptureWorkspaceCompleted':
      case 'CaptureLeftWallCompleted':
      case 'CaptureRightWallCompleted':
      case 'CaptureComputerCompleted':
        return <Events.CaptureRoomCompletedEvent {...{ createdAt, showInPlayer, body, actionerName, heading, institutionUser }} />
      case 'VirtualCamUsed':
        return <Events.NoTimestamp {...{ text, institutionUser }} />
      default:
        return <Events.GeneralEvent {...{
          createdAt, showInPlayer, body,
          showEventFeed,
          uiMessageId,
          feedStatus,
          feedUrl,
          name,
          institutionUser
        }} />
    }
  }

  getBrowserIncidentByName = (showInPlayer) => {
    const {
      incident: { body, subtype, createdAt, heading, uiMessageId },
      name, transformStore, isDeleteCandidate, institutionUser
    } = this.props

    switch (name) {
      case 'CopyPaste':
        return <Events.CopyPasteIncident {...{ showInPlayer, createdAt, body, subtype, institutionUser }} />
      case 'Url':
      case 'BrowserTab':
        return (
          <Events.URLOpenedIncident {...{ createdAt, showInPlayer, body, subtype, institutionUser }} />
        )
      case 'PrintScreenAttempt': {
        const text = heading
        return (
          <Events.NamedIncident
            {...{ text, createdAt, showInPlayer, institutionUser }}
          />
        )
      }
      case 'BrowserResized': {
        const text = subtype
        const deleteCheckbox = false
        return (
          <Events.NamedIncident
            {...{ createdAt, showInPlayer, text, deleteCheckbox, institutionUser }}
          />
        )
      }
      case 'MicDisconnected':
      case 'WebcamDisconnected': {
        const text = body
        return (
          <Events.NamedIncident
            {...{ createdAt, showInPlayer, text, transformStore, uiMessageId, institutionUser }}
          />
        )
      }
      default:
        return <Events.GeneralEvent {...{ body, createdAt, name, showInPlayer, heading, institutionUser }} />
    }
  }

  getExamGeneralByName(showInPlayer) {
    const {
      incident: {
        createdAt,
        heading,
        body: text,
        body,
        comment,
        uiMessageId,
        feedable,
        starts_at: startTime,
        ends_at: endTime,
        feed_status: feedStatus,
        feed_url: feedUrl
      },
      name,
      transformStore,
      isDeleteCandidate,
      timezone,
      showEventFeed,
      institutionUser
    } = this.props

    switch (name) {
      case 'Incident':
        const deleteCheckbox = true
        return (
          <Events.NamedIncident
            {...{
              createdAt,
              showInPlayer,
              body,
              text,
              uiMessageId,
              transformStore,
              isDeleteCandidate,
              deleteCheckbox,
              institutionUser
            }}
          />
        )
      case 'Comment':
        return (
          <Events.CommentEvent
            {...{
              createdAt,
              showInPlayer,
              text,
              body,
              uiMessageId,
              transformStore,
              heading,
              isDeleteCandidate,
              institutionUser
            }}
          />
        )
      case 'FulfillmentScheduled':
        return <Events.FulfillmentScheduled {...{ createdAt, showInPlayer, heading, institutionUser }} />
      case 'FulfillmentCreated':
      case 'ExamSavedAndClosed':
      case 'SessionWatching':
      case 'SessionWatched':
      case 'SessionWatchingStopped':
      case 'SessionAwaitingVerification':
      case 'ReservationCancelled':
      case 'CpuAndRamLoad':
      case 'OperatingSystemLog':
      case 'BrowserInformationLog':
      case 'ExtensionVersionLog':
      case 'CpuInformationLog':
      case 'CpuUsageLog':
      case 'MemoryInformationLog':
      case 'MemoryUsageLog':
      case 'DisplayInformationLog':
      case 'DownloadSpeedLog':
      case 'UploadSpeedLog':
      case 'FulfillmentAborted':
      case 'LaunchStart':
      case 'LaunchEnd':
        return <Events.SystemLoadEvent {...{ createdAt, text, institutionUser }} />
      case 'SessionVerified':
        return <Events.SessionEvent {...{ createdAt, text, institutionUser }} />
      case 'SuspiciousBehavior':
        return (
          <Events.SuspiciousBehavior
            {...{
              showInPlayer,
              startTime,
              endTime,
              uiMessageId,
              transformStore,
              isDeleteCandidate,
              text,
              body,
              institutionUser
            }}
          />
        )
      case 'SystemInformation':
        return <Events.SystemInformation {...{ createdAt, text, institutionUser }} />
      case 'TouchPoint':
        return <Events.TouchPoint {...{ createdAt, showInPlayer, text, comment, heading, institutionUser }} />
      case 'TestTakerActivity':
        return <Events.TestTakerActivity {...{ createdAt, showInPlayer, text, institutionUser }} />
      case 'Escalated':
        return <Events.Escalated {...{ createdAt, showInPlayer, text, institutionUser }} />
      case 'StaticImageDetected':
        return <Events.StaticImageEvent {...{ createdAt, showInPlayer, text, institutionUser }} />
      case 'ShortExamDuration':
      case 'PrechecksCompleted':
        return <Events.NoTimestamp {...{  createdAt, text }} />
      case 'Disconnections':
      case 'SessionInterrupted':
        return <Events.SessionInterrupted {...{ showInPlayer, createdAt, text, timezone, institutionUser }} />
      case 'WebcamName':
      case 'DeviceID':
      case 'MicName':
        return (
          <Events.PeripheralEvent
            {...{
              createdAt,
              text,
            }}
          />
        );
      default:
        return <Events.GeneralEvent {...{
          createdAt, showInPlayer, text, heading,
          transformStore, uiMessageId, showEventFeed, comment,
          feedable, feedStatus, feedUrl, name, institutionUser
        }} />
    }
  }

  render() {
    return <div className="list-group-item bg-transparent">{this.chooseComponentByGroup()}</div>
  }
}

IncidentComponent.propTypes = {
  incident: PropTypes.object.isRequired,
  transformStore: PropTypes.func.isRequired,
  group: PropTypes.string.isRequired,
  callbacks: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  isDeleteCandidate: PropTypes.bool.isRequired,
  timezone: PropTypes.string.isRequired,
  showEventFeed: PropTypes.bool,
  feedable: PropTypes.bool,
  feedStatus: PropTypes.string,
  feedUrl: PropTypes.string,
  feedType: PropTypes.string
}

export default IncidentComponent
