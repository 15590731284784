
class ContainerSwitcher {
  constructor() {
    this.COMPONENT_SELECTOR = "[data-behavior=container-switcher]"
    this.$switcher = $(this.COMPONENT_SELECTOR);
    this.$container = $(".app-layout > .container");
    this.toggled = false;
  }

  init() {
    this.bindEventListeners();
  }

  bindEventListeners() {
    this.$switcher.on("click", e => {
      this.toggleContainer(e);
    });
  }

  toggleContainer(e) {
    e.preventDefault();

    this.toggled = !this.toggled;
    this.toggleIcon();
    this.$container.toggleClass("container-fluid container");
  }

  toggleIcon() {
    if (this.toggled) {
      this.$switcher.attr("title", "Minimize content width");
      this.$switcher
        .find("span")
        .removeClass("fa-expand")
        .addClass("fa-compress");
    } else {
      this.$switcher.attr("title", "Maximize content width");
      this.$switcher
        .find("span")
        .removeClass("fa-compress")
        .addClass("fa-expand");
    }
  }
}

export default ContainerSwitcher
