class CompleteTab {
  constructor() {
    this.activeTab = document.querySelector('#flight-path li a.active').parentElement
  }

  init() {
    if(this.activeTab) {
      this.activeTab.classList.add('completed')
      this.activeTab.classList.remove('tab-incomplete')
      this.activeTab.classList.add('tab-complete')
      if(this.activeTab.nextElementSibling.classList.remove('disabled')) {
        this.activeTab.nextElementSibling.classList.remove('disabled')
      }
    }
  }
}

export default CompleteTab;
