import Toast from '../utils/Toast';

class WizardToast {
  constructor() {
    this.submitButton = document.querySelector('#submitButton');
  }

  init() {
    this.bindEventListener();
  }

  bindEventListener() {
    if (this.submitButton) {
      this.submitButton.addEventListener('click', this.showMessage.bind(this));
    }
  }

  showMessage() {
    new Toast().success({ message: polyglot.t('success_toast_message') });
  }
}

export default WizardToast;
