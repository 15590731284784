import CustomModal from '../../shared/CustomModal';

class LimitedEditLink {
  constructor() {
    this.links = document.querySelectorAll('[data-element="limited-edit-link"]');
    this.bindEventListener = this.bindEventListener.bind(this);
  }

  init() {
    if (!this.links || !this.links.length) return;

    [...(this.links)].forEach(this.bindEventListener);
  }

  bindEventListener(link) {
    link.addEventListener('click', this.showModal);
  }

  showModal(event) {
    event.preventDefault();

    const dataset = event.currentTarget.dataset;
    const modal = new CustomModal('iteration-edit-button-modal', {
      title: dataset.title,
      content: dataset.message,
      confirmLabel: dataset.confirmLabel,
      dismissLabel: dataset.dismissLabel,
      confirm: (confirmed) => {
        if (confirmed) {
          window.open(dataset.examUrl, '_blank');
        }
      }
    });
    modal.create();
  }
}

export default LimitedEditLink;
