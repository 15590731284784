import {useEffect} from "react";
import Toast from '../../../src/utils/Toast';
import SecureBrowserSubscription from "../../../src/channels/subscriptions/SecureBrowserSubscription";

const ToastEventText = (props) => {
	const userId = props.userId;
	const sbConnection = new SecureBrowserSubscription(userId);

	useEffect( () => {
		sbConnection.init();

		sbConnection.secureBrowser.received = (res) => {
			const results = (typeof res === 'string') ? JSON.parse(res): res;
			handleMessages(results);
		};
	}, []);

	const handleMessages = (data) => {
		console.log('message received')
		if (data.sender == 'archimedes') {
			console.log('message received from archimedes')
			if (data.messageType == 'text_alert') {
				console.log('message received from archimedes with the alert text')
				new Toast().danger({ message: data.alertText });
			}
		}
	};

	return (
		<></>
	)
}

export default ToastEventText;
