class IncreaseProgressBar {
  constructor() {
    this.progressBar = document.querySelector('#flight-path .js-flightpath-progress div');
    this.completeCount = document.querySelectorAll('#flight-path .completed').length;
    this.totalCount = document.querySelectorAll('#flight-path .nav-item').length;
    if(document.querySelector('#fulfillment-state') != null) {
      this.fulfillmentStatus = document.querySelector('#fulfillment-state').dataset.status;
      this.awaitingVerification = document.querySelector('#fulfillment-state').dataset.awaitingVerification;
      if (this.completeCount > 3 && this.fulfillmentStatus === 'running' && this.awaitingVerification == 'true') {
        document.querySelector('#unlock-authentication').classList.remove('disabled');
      }
    }
  }

  init() {
    if (this.progressBar) {
      this.completePercent = Math.ceil(this.completeCount / this.totalCount * 100);
      this.progressBar.setAttribute('aria-valuemin', this.completeCount);
      this.progressBar.setAttribute('aria-valuemax', this.completeCount);
      this.progressBar.setAttribute('aria-valuenow', this.completeCount);
      this.progressBar.style.width = `${this.completePercent}%`;
      this.progressBar.innerHTML = `${this.completePercent}%`;
    }
  }
}

export default IncreaseProgressBar;
