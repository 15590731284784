import ReviewQueueEntry from './ReviewQueueEntry';
import DefaultEntry from './DefaultEntry';

const EntryFactory = (props) => {
  switch (props.entry.entry_type) {
    case 'ReviewQueue':
      return <ReviewQueueEntry {...props} />
    default:
      return <DefaultEntry {...props} />
  }
}

export default EntryFactory;
