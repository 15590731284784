import csrfToken from './csrf';

export default function getRequestParams(body = {}, method = 'POST') {
  return {
    method: method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken()
    },
    body: JSON.stringify(body)
  };
}
