import React, { useEffect, useRef } from 'react';
import $ from 'jquery';
import 'select2';

/**
 * Select2AutoComplete component integrates the Select2 jQuery plugin with React.
 * It provides an autocomplete dropdown with AJAX support.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.url - The URL endpoint for fetching autocomplete options.
 * @param {string} props.placeholderLabel - The placeholder text for the select input.
 * @param {string} [props.selectedOptionId] - The ID of the initially selected option.
 * @param {string} [props.selectedOption] - The text of the initially selected option.
 * @param {string} props.fieldName - The name attribute for the hidden input field.
 *
 * @returns {JSX.Element} The rendered Select2AutoComplete component.
 */
const Select2AutoComplete = ({
  url,
  placeholderLabel,
  selectedOptionId,
  selectedOption,
  fieldName,
}) => {
  const selectRef = useRef(null);
  const hiddenInputRef = useRef(null);

  useEffect(() => {
    // Initialize the Select2 plugin on the select element
    const $select = $(selectRef.current);

    // Configure Select2 with AJAX settings
    $select.select2({
      ajax: {
        url: url, // URL endpoint for fetching autocomplete options
        dataType: 'json',
        delay: 250, // Delay in milliseconds before sending the request
        data: (params) => ({
          query: params.term, // Query parameter sent to the server
        }),
        processResults: (data) => ({
          results: data.map((instructor) => ({
            id: instructor.id,
            text: instructor.name,
          })),
        }),
        cache: true, // Cache the results
      },
      placeholder: placeholderLabel,
      minimumInputLength: 2, // Minimum number of characters required to trigger the search
      theme: 'bootstrap4', // Theme for the Select2 dropdown
    });

    // Set the initial value if `selectedOptionId` is not null or undefined
    if (selectedOptionId) {
      const option = new Option(selectedOption, selectedOptionId, true, true);
      $select.append(option).trigger('change');
    }

    // Update hidden input field on selection
    $select.on('select2:select', (e) => {
      const selectedValue = e.params.data.id;
      hiddenInputRef.current.value = selectedValue;
    });

    // Properly handle cleanup by destroying the Select2 instance on component unmount
    return () => {
      $select.select2('destroy');
    };
  }, [selectedOptionId, selectedOption]);

  return (
    <div data-testid={`select-wrapper-${fieldName}`}>
      <select
        ref={selectRef}
        className="form-control"
        defaultValue={selectedOptionId || ''}
      >
        <option value="">{placeholderLabel}</option>
        {selectedOptionId && (
          <option value={selectedOptionId}>{selectedOption}</option>
        )}
      </select>
      {hiddenInputRef && (
        <input
          type="hidden"
          ref={hiddenInputRef}
          name={fieldName}
          defaultValue={selectedOptionId}
        />
      )}
    </div>
  );
};

export default Select2AutoComplete;
