import PropTypes from 'prop-types'
import axios from 'axios'
import csrfToken from '../../../src/utils/csrf';

/*
 * React Component for "notepad form" on fulfillments#show.
 *
 * Child component of <Notepad>
 */
class NotepadForm extends React.Component {
  constructor(props){
    super(props)

    // refs
    this.notepad
    this.incident
    this.formGroupNotepad

    this.state = {
      commentError: "",
      formGroupClass: "",
      successfulSubmission: false
    }
    this.initialstate = this.state
  }

  componentDidMount() {
    let $modal = $(document.querySelector(".modal-incident"))
    let checkbox = this.incident
    $modal.on("hidden.bs.modal", function() {
      checkbox.checked = false
    })
  }

  createEvent = (event) => {
    event.preventDefault()
    let button = event.currentTarget
    let url = this.props.fulfillment.events_url
    let params = {
      format: "json",
      event: {
        comment: this.props.comment
      },
      type: 'Comment'
    }
    const headers = {
      headers: {
        Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
      }
    }

    button.classList.add("is-loading")
    axios.post(url, JSON.stringify(params), headers)
    .then(response => {
      this.handleSuccessfulEvent(response)
    }).catch((error) => {
        var error = error.response.data.user_message[0]
        this.setState({
          commentError: error,
          formGroupClass: "is-invalid"
        })
    }).then(() => {
      button.classList.remove("is-loading")
    })
  }

  handleSuccessfulEvent = (response) => {
    this.props.reset()
    this.notepad.value = ""
    this.setState(this.initialstate)
    this.setState({successfulSubmission: true})
  }

  disregardSubmission = (event) => {
    event.preventDefault()
    this.setState({ successfulSubmission: false })
  }

  flash = () => {
    return (
      <div className="alert alert-success alert-form" role="alert">
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={this.disregardSubmission}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        Comment successfully created!
      </div>
    )
  }

  render() {
    var formGroupClass = ["form-group", this.state.formGroupClass].join(" "),
      successFlash

    if (this.state.successfulSubmission) {
      successFlash = this.flash()
    }

    return (
      <div>
        {successFlash}

        <div className={formGroupClass} ref={ref => this.formGroupNotepad = ref}>
          <textarea
            ref={ref => this.notepad = ref}
            className="form-control"
            value={this.props.comment}
            onChange={this.props.updateComment}
            rows="5"
          />

          <span className="text-danger form-text">
            {this.state.commentError}
          </span>
        </div>

        <span className="float-right">
          <button
            type="submit"
            className="btn btn-primary"
            onClick={this.createEvent}
          >
            Add Note
          </button>
        </span>

        <div className="checkbox pln mt-2">
          <input
            type="checkbox"
            name="incident"
            id="incident"
            className="mr-2"
            ref={ref => this.incident = ref}
            onClick={this.props.openModal}
          />
          <label htmlFor="incident">
            <strong>Flag as incident</strong>
          </label>
        </div>
      </div>
    )
  }
}

NotepadForm.propTypes = {
  fulfillment: PropTypes.object.isRequired,
  comment: PropTypes.string,
  openModal: PropTypes.func.isRequired,
  updateComment: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired
}

export default NotepadForm
