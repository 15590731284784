import React, { useState } from 'react';
import axios from 'axios';
import csrfToken from '../../src/utils/csrf';
import { ExpressConfigModal } from './ExpressConfigModal';
import Toast from '../../src/utils/Toast';

const ExpressConfigModalContainer = ({
  terms,
  departments,
  latestExams,
  courseName,
  institutionId,
  idToken,
  toggle,
  show,
  setShow,
  setSpinnerClassNone,
  checkboxStates,
  updateExamData,
}) => {
  const [selectedTerm, setSelectedTerm] = useState({
    id: 0,
    name: 'Select option',
  });
  const [selectedDepartment, setSelectedDepartment] = useState({
    id: 0,
    name: 'Select option',
  });
  const [selectedExamOptions, setSelectedExamOptions] = useState({
    id: 0,
    name: 'Select option',
  });

  const getSelectedTerm = (term) => setSelectedTerm(term);
  const getSelectedDepartment = (department) =>
    setSelectedDepartment(department);
  const getSelectedExamOptions = (exam) => setSelectedExamOptions(exam);

  const isConfigureBtnEnabled =
    selectedTerm.id !== 0 && selectedDepartment.id !== 0;

  const handleConfigure = () => {
    const examsToConfigure = checkboxStates
      .map(
        (examStr) =>
          examStr.indexOf('_') >= 0 && {
            id: parseInt(examStr.split('_')[0]),
            category: examStr.split('_')[1],
          },
      )
      .filter(Boolean);
    const expressConfigData = {
      exams: examsToConfigure,
      course_name: courseName,
      term: selectedTerm.id,
      department: selectedDepartment.id,
      exam_options: selectedExamOptions.id,
      institution: institutionId,
      id_token: idToken,
    };
    setShow(toggle);
    setSpinnerClassNone(false);

    axios
      .post(`${window.location.origin}/api/copy_exams`, expressConfigData, {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken(),
      })
      .then((response) => {
        setSpinnerClassNone(true);
        if (response.status === 201) {
          new Toast().success({
            classList: ['lms-custom-success-toast', 'small'],
            message: 'Success! Exam(s) copied.',
          });
        } else {
          new Toast().danger({
            classList: ['lms-custom-danger-toast', 'small'],
            message: 'Error occurred. Exam(s) not copied.',
          });
        }
        updateExamData();
      })
      .catch((error) => {
        setSpinnerClassNone(true);
        new Toast().danger({
          classList: ['lms-custom-danger-toast', 'small'],
          message: error.message
            ? error.message
            : error.response.data.error.message,
        });
      });
  };

  return (
    <ExpressConfigModal
      terms={terms}
      departments={departments}
      latestExams={latestExams}
      toggle={toggle}
      show={show}
      selectedTermName={selectedTerm.name}
      selectedDepartmentName={selectedDepartment.name}
      selectedExamOptionsName={selectedExamOptions.name}
      getSelectedTerm={getSelectedTerm}
      getSelectedDepartment={getSelectedDepartment}
      getSelectedExamOptions={getSelectedExamOptions}
      isConfigureBtnEnabled={isConfigureBtnEnabled}
      handleConfigure={handleConfigure}
      numberOfSelectedExams={(checkboxStates || []).length}
    />
  );
};

export { ExpressConfigModalContainer };
