class CollapsibleTestTaker {
  constructor() {
    this.collapseTestTakerInfoBtn = document.querySelector(
      '[data-behavior="collapse-test-taker-info"]'
    );

    this.collapseTestTakerInfoBtnIcon = document.querySelector(
      '[data-behavior="collapse-test-taker-info"] span'
    );

    this.collapsibleData = document.querySelector(
      '[data-behavior="collapsible-test-taker-info"]'
    );

    this.state = {
      collapsed: false
    };

    this.collapse = this.collapse.bind(this);
  }

  init() {
    if (this.collapseTestTakerInfoBtn && this.collapsibleData) {
      this.collapseTestTakerInfoBtn.addEventListener('click', this.collapse);
    }
  }

  collapse(event) {
    event.preventDefault();

    if (!this.state.collapsed) {
      // The Test-Taker Data was not collapsed, so we want to hide it,
      // and update the icon to indicate the user can expand it.
      this.collapsibleData.classList.add('d-none');
      this.collapseTestTakerInfoBtnIcon.classList.add('fa-chevron-left');
      this.collapseTestTakerInfoBtnIcon.classList.remove('fa-chevron-down');

      this.updateState('collapsed', true);
    } else {
      // Because the information was hidden, we want to un-hide it
      // and update the icon accordingly to show the user can collapse it.
      this.collapsibleData.classList.remove('d-none');
      this.collapseTestTakerInfoBtnIcon.classList.add('fa-chevron-down');
      this.collapseTestTakerInfoBtnIcon.classList.remove('fa-chevron-left');

      this.updateState('collapsed', false);
    }
  }

  // Update the state with the element to be updated and the value to set it to
  // Example usage: this.updateState('collapsed', false)
  // Sets `this.state = { collapsed: false }`
  updateState(element, value) {
    let state = this.state;
    state = Object.assign(state, {
      [element]: value
    });
    this.state = state;
  }
}

export default CollapsibleTestTaker;
