import React, { useState, useEffect, useMemo } from 'react';
import {Tab, Tabs} from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import ExamPickerContext, { Context } from './ExamPickerContext';
import ExamTable from './ExamTable';
import Paginator from '../Paginator';
import UnconfiguredEmptyExams from './UnconfiguredEmptyExams';
import ConfiguredExamRow from './ConfiguredExamRow';
import UnconfiguredExamRow from './UnconfiguredExamRow';
import UserReport from '../UserReport'
import SearchInput from '../SearchInput';
import LMSExamModal from '../LMSExamModal';
import DeepCompare from '../DeepCompare';
import ExpressConfigModal from '../ExpressConfigModal';
import usePaginator from '../../hooks/usePaginator';
import {validateDetails} from '../../utils/ValidateDetails';
import Toast from '../../src/utils/Toast';
import '../../stylesheets/components/wizard/exam_picker.scss';

const ExamPicker = ({ createExamEndpoint, configureExamEndpoint, insertLinkEndpoint, editExamEndpoint, institutionId, departments, terms, latestExams, courseName, configuredExams, unconfiguredExams, idToken }) => {
  const context = ExamPickerContext(
    createExamEndpoint,
    configureExamEndpoint,
    insertLinkEndpoint,
    editExamEndpoint,
    institutionId,
    departments,
    terms,
    latestExams,
    courseName,
    configuredExams,
    unconfiguredExams,
    idToken,
  );

  const [pageUrl, setPageUrl] =  useState('');
  const [saveNow, setSaveNow] =  useState(false);
  const [exam, setExam] =  useState(undefined);
  const [search, setSearch] = useState('');
  const [examUpdated, setExamUpdated] = useState(false);
  const [examList, setExamList] = useState({
    unconfiguredExams: unconfiguredExams,
    configuredExams: configuredExams,
  });
  const paginator = usePaginator(unconfiguredExams, 10);
  const [paginatedExams, setPaginatedExams] = useState({
    exams: paginator.currentItems,
  });
  const [showModal, setShowModal] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [showReportLoadingProgress, setShowReportLoadingProgress] = useState(true);
  const [checkboxStates, setCheckboxStates] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isSearchUsed, setIsSearchUsed] = useState(false);
  const [searchableExams, setSearchableExams] = useState([]);
  const [spinnerClassNone, setSpinnerClassNone] = useState(true);
  const [examProcessed, setExamProcessed] = useState([]);

  const paginatorReport = usePaginator([], 10);

  const toggleModal = () => {
    setShowModal(!showModal)
  }

  const memoedUnconfiguredExams = useMemo(() => unconfiguredExams.map((exam) => validateDetails(exam, getLMSProviderName(idToken))), [unconfiguredExams]);
  const isCheckable = memoedUnconfiguredExams.filter(({isValidExam}) => isValidExam).length > 0 ? true : false;
  const spinnerClass = spinnerClassNone ? 'd-none' : 'd-block';

  const handleCheckboxChange = (selectedExam) => {
    if (checkboxStates.includes(selectedExam)) {
      setCheckboxStates(checkboxStates.filter((ex) => ex !== selectedExam));
    } else {
      setCheckboxStates([...checkboxStates, selectedExam]);
    }
  };

  const handleShowModal = () => {
    if (checkboxStates.length > 0) {
      setShowModal(true);
    } else {
        new Toast().danger({
        classList: ['lms-custom-danger-toast', 'small'],
        message: "No exam(s) selected",
      });
    }
  };

  const filterExams = (examsArray) => {
    const filteredExamsArray = examsArray.filter(({isValidExam}) => isValidExam);
    const modifiedExamsArray = filteredExamsArray.map(({id, quiz_category = ''}) => `${id}_${quiz_category}`);
    setCheckboxStates(modifiedExamsArray);
  }

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setCheckboxStates([]);
    if (!selectAll) {
      isSearchUsed ?  filterExams(searchableExams) : filterExams(memoedUnconfiguredExams);
    }
  };

  const handleConfiguredModal = (pageUrl, sectionName, exam) => {
    sessionStorage.removeItem("appointmentWindows")
    setSaveNow(true);
    setPageUrl(pageUrl.replace('proctoring_settings', sectionName));
    setExam(exam);
  }

  const handleUserReportModal = (exam, event) => {
    event.preventDefault();
    setExam(exam);
    setShowReportModal(true);
    setShowReportLoadingProgress(true);
  }

  const handleClose = () => {
    setShowReportModal(false);
  };

  const handleUnconfiguredModal = (exam) => {
    sessionStorage.removeItem("appointmentWindows")
    setSaveNow(false);
    setPageUrl('/institutions/proctor_configs/proctoring_settings');
    setExam(exam);
  }

  const handleSelectTabClick = (key) => {
    const {unconfiguredExams: newUnconfiguredExams, configuredExams: newConfiguredExams} = examList;
    setSearch('');
    if (key === 'unconfigured'){
      setSelectAll(false);
      setCheckboxStates([]);
      paginator.setAllItems(newUnconfiguredExams);
    }
    else {
      paginator.setAllItems(newConfiguredExams);
    }
  }

  const handleRefreshExamList = () => {
    if (!examUpdated) {
      setExamUpdated(true);
    }
  }

  const onSearchChange = ( { target }, items) => {
    setSelectAll(false);
    setIsSearchUsed(true);
    const filtered = items.filter( item => item.exam_name.toLowerCase().includes( target.value.toLowerCase() ));
    target.value.length === 0 ? paginator.setAllItems(items) : paginator.setAllItems(filtered);
    setSearch(target.value);
    setSearchableExams(filtered);
  };

  useEffect(() => {
    async function fetchData() {
      if (examUpdated) {
        setExamUpdated(false);
        await fetch(`/api/get_exams/${institutionId}`)
          .then(response => response.json())
          .then(data => {
            if (data) {
              const {unconfigured_exams, configured_exams} = data;
              setExamList({unconfiguredExams: unconfigured_exams, configuredExams: configured_exams});
              paginator.setAllItems(unconfigured_exams);
            }
        })
        .catch(error => console.error('Error updating exam data:', error));
      }
    }
    fetchData();
  }, [examUpdated]);

  DeepCompare(() => {
    const updatedExamsList = {
      exams: paginator.currentItems,
    };
    setPaginatedExams(updatedExamsList);
  }, [paginator.currentItems]);


  const updateExamData = () => {
    setExamUpdated(true);
    if (checkboxStates.length > 0) {
      setExamProcessed([...examProcessed, ...checkboxStates.map((id) => id.split('_')[0])]);
      setCheckboxStates([]);
    } else {
      setExamProcessed([...examProcessed, exam.id]);
    }
  };

  function getLMSProviderName(idToken) {
    if (idToken && idToken !== '') {
      const token = JSON.parse(idToken);
      const platformKey = 'https://purl.imsglobal.org/spec/lti/claim/tool_platform';
      return token[0][platformKey].product_family_code;
    }

    return '';
  }

  return (
    <>
      <div className={`text-center lms-green-spinner ${spinnerClass}`}>
        <Spinner animation="border" role="status" />
      </div>
      <div className="mb-2r px-4 pt-4">
        <div>
          <Context.Provider value={context}>
            <h2 className="mb-2r mt-3">{courseName} Exams</h2>
            <Tabs
              defaultActiveKey="unconfigured"
              id="tabs-exam-picker"
              className="mb-2r nav-fill lms-nav-custom"
              onSelect={(key) => handleSelectTabClick(key)}
            >
              <Tab eventKey="unconfigured" title="Unproctored Exams">
                {examList.unconfiguredExams.length > 0 ? (
                  <>
                  <div className="d-flex flex-wrap align-items-center mb-3">
                    <div className="copy-section">
                      <input
                        type="checkbox"
                        className="mr-2"
                        id="select-all-unconfigured-checkbox"
                        disabled={!isCheckable}
                        onChange={handleSelectAll}
                        checked={selectAll}
                      />
                      <span className="mr-3 mb-0 small">Select All</span>
                      <input
                        type="submit"
                        name="commit"
                        value={`Express Setup (${(checkboxStates || []).length} exams)`}
                        className="btn btn-white-meazure small"
                        id="express-setup-btn"
                        onClick={handleShowModal}
                      />
                    </div>
                    <div className="d-flex flex-wrap align-items-center ml-3 mr-3">
                      <div className="copy-section">
                        <input
                          type="button"
                          value="Refresh Exam List"
                          className="btn btn-white-meazure small"
                          onClick={handleRefreshExamList}
                        />
                      </div>
                    </div>

                    <div className="search-section">
                      <SearchInput search={ search } items={examList.unconfiguredExams} onSearchChange={ onSearchChange } placeholder="Search by exam name"/>
                    </div>
                  </div>
                  <ExamTable
                    id="unconfigured-exams-table"
                    exams={paginatedExams.exams}
                      renderRow={(exam) => <UnconfiguredExamRow exam={exam} checkboxStates={checkboxStates} lmsProviderName={getLMSProviderName(idToken)} examProcessed={examProcessed} validateDetails={validateDetails} handleCheckboxChange={handleCheckboxChange} handleUnconfiguredModal={handleUnconfiguredModal} />}
                  />
                </>
                ) : (
                  <UnconfiguredEmptyExams />
                )}
              </Tab>

              <Tab eventKey="configured" title="Proctored Exams">
                <div className="d-flex flex-wrap align-items-center mb-3">
                  <div className="search-section">
                    <SearchInput search={ search } items={examList.configuredExams} onSearchChange={ onSearchChange } placeholder="Search by exam name"/>
                  </div>
                </div>
                <ExamTable
                  id="configured-exams-table"
                  exams={paginatedExams.exams}
                  renderRow={(exam) => <ConfiguredExamRow exam={exam} handleConfiguredModal={handleConfiguredModal} handleUserReportModal={handleUserReportModal} />}
                />
              </Tab>
            </Tabs>
          </Context.Provider>
        </div>
      </div>
      <LMSExamModal
        pageUrl={pageUrl}
        institutionId={institutionId}
        exam={exam}
        saveNow={saveNow}
        setPageUrl={setPageUrl}
        updateExamData={updateExamData}
      />
      <UserReport
        exam={exam}
        showModal={showReportModal}
        paginatorReport={paginatorReport}
        showReportLoadingProgress={showReportLoadingProgress}
        setShowReportLoadingProgress={setShowReportLoadingProgress}
        handleClose={handleClose}
    />
      <div className="px-4 pb-4"><Paginator paginator={paginator} /></div>
      <ExpressConfigModal
        show={showModal}
        toggle={toggleModal}
        terms={terms}
        departments={departments}
        latestExams={latestExams}
        institutionId={institutionId}
        courseName={courseName}
        idToken={idToken}
        checkboxStates={checkboxStates}
        setShow={setShowModal}
        setSpinnerClassNone={setSpinnerClassNone}
        updateExamData={updateExamData}
      />
    </>
  );
};

export default ExamPicker;
