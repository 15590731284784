import Geolocator from './Geolocator';
import getParam from '../utils/getParam';
import setParams from '../utils/setParams';
import addDynamicEventListener from '../utils/dynamicListener';
import csrfToken from '../utils/csrf';
// LocationSearch
//
// This class handles the behavior of the PTC location search
// modal.
//
// It displays locations, information about those locations, and allows the
// user to confirm a location for their reservation.
class LocationSearch {
  constructor() {
    this.$modal = $('[data-behavior="location-search-modal"]');

    this.iterationId = this.$modal.data('iterationId');

    this.initLocationSearchBodySelectors();

    this.initLocationDetailBodySelectors();

    this.directToRemoteTesting = this.directToRemoteTesting.bind(this);
    this.searchByBrowserLocation = this.searchByBrowserLocation.bind(this);
  }

  initLocationSearchBodySelectors() {
    // location search modal content
    this.locationSearch = document.querySelector(
      '[data-behavior="location-search"]'
    );

    // Search Near Me button
    this.searchNearMeBtn = document.querySelector(
      '[data-behavior="search-near-me-btn"]'
    );

    // the dropdown for the max distance we want to search locations
    this.searchRadiusDropdown = document.querySelector(
      '[data-behavior="search-radius-dropdown"]'
    );

    // the text field for address input
    this.addressTextField = document.querySelector(
      '[data-behavior="address-text-field"]'
    );

    // the search button to search a custom address and radius
    this.searchAddressBtn = document.querySelector(
      '[data-behavior="search-address-btn"]'
    );

    // the container that holds the location table
    this.locationTableContainer = document.querySelector(
      '[data-behavior="location-table-container"]'
    );

    // the table to hold location data
    this.locationTable = document.querySelector(
      '[data-behavior="location-table"]'
    );

    // the button to go back to remote scheduling
    this.testRemotelyBtn = document.querySelector(
      '[data-behavior="test-remotely-btn"]'
    );

    this.switchToDigital = document.querySelector(
      '[data-behavior="switch-to-digital"]'
    );

    // the loading message element, displayed while fetching locations
    this.loadingMsg = document.querySelector('[data-behavior="loading-msg"]');

    // the element to display if there are no locations found
    this.blankSlateElement = document.querySelector(
      '[data-behavior="blank-slate-element"]'
    );

    // the string to display within the blank slate element
    this.blankSlateMsg = document.querySelector(
      '[data-behavior="blank-slate-msg"]'
    );

    // the element to display if there was an error getting the browser location
    this.browserLocationErrorMsg = document.querySelector(
      '[data-behavior="browser-location-error-msg"]'
    );

    // the link in the blank slate message to use the browser's location
    this.useCurrentLocationLink = document.querySelector(
      '[data-behavior="use-browser-location-link"]'
    );

    // the button to close search
    this.closeSearchBtn = document.querySelector(
      '[data-behavior="close-search-modal-btn"]'
    );
  }

  initLocationDetailBodySelectors() {
    this.locationDetails = {
      container: document.querySelector('[data-behavior="location-details"]'),
      nameField: document.querySelector('[data-behavior="location-name"]'),
      miscInfoField: document.querySelector(
        '[data-behavior="location-parking-instructions"]'
      ),
      addressField: document.querySelector(
        '[data-behavior="location-address"]'
      ),
      featureListHeader: document.querySelector(
        '[data-behavior="location-feature-list-header"]'
      ),
      featureList: document.querySelector(
        '[data-behavior="location-feature-list"]'
      ),
      googleMapsEmbed: document.querySelector(
        '[data-behavior="location-google-map"]'
      ),
      backToSearchBtn: document.querySelector(
        '[data-behavior="back-to-search-btn"]'
      ),
      closeBtn: document.querySelector(
        '[data-behavior="close-details-modal-btn"]'
      ),
      switchRemoteBtn: document.querySelector(
        '[data-behavior="test-remotely-details-btn"]'
      ),
    };
  }

  // initializes variables for sidebar selectors
  initSidebarSelectors() {
    // The panel to show when a location has been selected
    this.sidebarLocationPanel = document.querySelector(
      '[data-behavior="sidebar-location-panel"]'
    );

    // The name of the location to show in the sidebar
    this.sidebarLocationName = document.querySelector(
      '[data-behavior="sidebar-location-name"]'
    );

    // The address line one of the location to show in the sidebar
    this.sidebarLocationAddressLineOne = document.querySelector(
      '[data-behavior="sidebar-location-address-line-one"]'
    );

    // The address line two of the location to show in the sidebar
    this.sidebarLocationAddressLineTwo = document.querySelector(
      '[data-behavior="sidebar-location-address-line-two"]'
    );

    // the link to select another testing center if the location siderbar has an error
    this.locationSidebarErrorLink = document.querySelector(
      '[data-behavior="sidebar-location-error-link"]'
    );

    // the button to open back up the location search once a location has been selected
    this.switchTestingCenterBtn = document.querySelector(
      '[data-behavior="sidebar-switch-testing-center-btn"]'
    );

    // The panel to show when a location could not be fetched from the
    // session storage, but a location id is present in the parameters
    this.sidebarErrorPanel = document.querySelector(
      '[data-behavior="sidebar-error-panel"]'
    );
  }

  init() {
    // do not execute if the modal isn't here
    if (this.$modal.length === 0) {
      return;
    }

    this.geolocator = new Geolocator();

    // need to get address here too since it relies of the search near me button being defined
    this.currentUserAddress = this.searchNearMeBtn.dataset.address;

    // If a location has been selected, the 'location_id' param will be
    // present in the URL parameters, and the selected location data
    // should be present in the sessionStorage as 'ptcLocation'.
    const locationSelected = getParam('location_id') != '';
    const sessionPtcLocation = sessionStorage.getItem('ptcLocation');

    // if a location has been selected, render the sidebar
    if (locationSelected) {
      this.initSidebarSelectors();
      // if there is no location in the storage, render the error panel
      if (sessionPtcLocation != undefined) {
        this.initSidebar(JSON.parse(sessionPtcLocation));
      } else {
        this.renderSidebarErrorPanel();
      }
    // otherwise, render the location search modal
    } else {
      this.displayLocationSearchModal();
    }
    this.bindEventListeners();
  }

  bindEventListeners() {
    // 'Search Near Me' button clicked
    this.searchNearMeBtn.addEventListener('click', () => {
      // fill in the address text field with the user's current address
      this.addressTextField.value = this.currentUserAddress;
      this.searchByAddress(this.currentUserAddress);
    });
    // 'Search' button clicked with manual address and radius
    this.searchAddressBtn.addEventListener('click', () => {
      this.searchByAddress(this.addressTextField.value, this.selectedRadius());
    });
    // 'Use my current location' link clicked
    this.useCurrentLocationLink.addEventListener(
      'click',
      this.searchByBrowserLocation
    );
    // 'Close' button clicked on search modal
    this.closeSearchBtn.addEventListener('click',() => {
      this.hideLocationModal();
    });
    // 'Back to Search' button clicked
    this.locationDetails.backToSearchBtn.addEventListener('click', () => {
      this.hideLocationDetails();
      this.renderLocationSearch();
    });
    this.locationDetails.closeBtn.addEventListener('click', () => {
      this.hideLocationModal();
    });

    // 'Test remotely' button clicked
    if (this.locationDetails && this.locationDetails.switchRemoteBtn) {
      this.locationDetails.switchRemoteBtn.addEventListener('click', this.directToRemoteTesting);
    }
    if (this.testRemotelyBtn) {
      this.testRemotelyBtn.addEventListener('click', this.directToRemoteTesting);
    }
    if (this.switchToDigital) {
      this.switchToDigital.addEventListener('click', this.directToRemoteTesting);
    }
  }

  // Displays the location search modal with given options
  // The options default to having the modal not closable i.e.
  // the user cannot click out or press escape to close the modal
  displayLocationSearchModal(
    options = { backdrop: 'static', keyboard: false }
  ) {
    this.$modal.modal(options);
    this.hideLocationDetails();
    this.renderLocationSearch();
    this.locationDetails.backToSearchBtn.style = 'display: block';
    this.locationDetails.closeBtn.style = 'display: none';
  }

  hideLocationModal() {
    this.$modal.modal('hide');
  }

  // Necessary methods to run before we execute a search
  initializeSearch() {
    // hide and clear table
    this.hideLocationTableContainer();
    this.locationTable.innerHTML = '';
    // display loading message to user
    this.renderLoadingMsg();
    // hide blank slate msg if visible from last search
    this.hideBlankSlate();
    // hide browser location error msg if visible from last search
    this.hideErrorMsg();
  }

  // Find locations by a specified address
  searchByAddress(address, radius = 50) {
    this.initializeSearch();
    this.geolocator
      .geolocateByAddress(address)
      .then(latLonPair => {
        return this.fetchLocationData(
          latLonPair.latitude,
          latLonPair.longitude,
          radius
        );
      }).then(() => {
        this.addLogs(this.resultsCount);
      })
      .catch(() => {
        this.hideLoadingMsg();
        this.renderBlankSlate();
      });
  }

  // Add logs
  addLogs(resultsCount) {
    const data = {
      radius: this.searchRadiusDropdown[this.searchRadiusDropdown.selectedIndex].text,
      address: this.addressTextField.value,
      results: resultsCount
    };
    fetch('/ptc/logs', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken()
      },
      credentials: 'same-origin'
    });
  }

  // Find locations by browser location
  searchByBrowserLocation() {
    this.initializeSearch();
    this.geolocator.geolocateByBrowserLocation()
      .then(latLonPair => {
        this.geolocator
          .getAddressFromLatLong(latLonPair.latitude, latLonPair.longitude)
          .then(address => (this.addressTextField.value = address));
        this.fetchLocationData(
          latLonPair.latitude,
          latLonPair.longitude,
          this.selectedRadius()
        );
      })
      .catch(err => {
        this.renderErrorMsg(`Error getting current location: ${err}`);
        this.hideLoadingMsg();
      });
  }

  // Fetches location data from a given latitude, longitude, and radius
  fetchLocationData(latitude, longitude, radius) {
    // disable button so user won't click and fetch multiple times
    this.searchAddressBtn.disabled = true;
    this.searchAddressBtn.innerHTML = 'Searching...';

    let url = '/ptc/locations.json?';

    const params = {
      latitude: latitude,
      longitude: longitude,
      max_travel_distance: radius,
      sort_order: 'ASC',
      sort_keys: 'distance',
      iteration_id: this.iterationId
    };

    for (const param in params) {
      url += `${param}=${params[param]}&`;
    }

    return fetch(url)
      .then(response => {
        this.resultsCount = 0;
        if (response.ok) {
          return response.json();
        } else {
          const msg =
            'Unable to get location data. Check your internet connection and try searching again.';
          throw new Error(msg);
        }
      })
      .then(response => {
        this.resultsCount = response.locations.length;
        this.populateLocationTable(response.locations);
      })
      .catch(error => {
        this.renderErrorMsg(error.message);
      })
      .finally(() => {
        this.hideLoadingMsg();
        this.searchAddressBtn.disabled = false;
        this.searchAddressBtn.innerHTML = 'Search';
      });
  }

  // Adds location information to the location table
  populateLocationTable(locations) {
    // we now have a list of locations that include both
    // SmarterServices and Scantron locations.
    // use different logic to handle each of these
    this.renderLocationTableContainer();

    // prevents repeating locations if closed repeatedly before table renders
    locations.forEach(location => {
      const existing_location_ids = [...this.locationTable.getElementsByTagName('a')].map(
        element => element.dataset.behavior.replace('view-location-details-', '')
      );
      if (!existing_location_ids.includes(location.id)) {
        this.addLocationToTable(location);
      }
    });

    // if we are done appending locations but the table still has no rows,
    // render a blank slate
    if (this.locationTable.rows.length == 0) {
      this.hideLocationTableContainer();
      this.renderBlankSlate();
    }
  }

  // adds a location to the location search results table
  addLocationToTable(location) {
    const row = this.locationTable.insertRow(-1);
    this.populateDescriptionCell(location, row.insertCell(0));
    this.populateDistanceCell(location, row.insertCell(1));
    this.populateSelectBtnCell(location, row.insertCell(2));
  }

  // Populate the description cell of a location row
  populateDescriptionCell(location, cell) {
    cell.classList.add('pr-0')
    // the link to the detail page of the location
    const nameDetailLink = document.createElement('a');
    nameDetailLink.classList.add('location-detail-link');
    nameDetailLink.classList.add('text-primary');
    nameDetailLink.innerHTML = 'View Details';
    nameDetailLink.title = 'View Details';
    nameDetailLink.setAttribute(
      'data-behavior',
      `view-location-details-${location.id}`
    );
    nameDetailLink.href = 'javascript:void(0)';
    // we need to use addDynamicEventListener here
    // since our anchor tag is not initially in the DOM
    addDynamicEventListener(
      document.body,
      'click',
      `[data-behavior='view-location-details-${location.id}']`,
      () => {
        this.hideLocationSearch();
        this.renderLocationDetails(location);
      }
    );

    // append the rest of the location's description
    cell.innerHTML += `<strong>${location.name}</strong><br/>${location.addressFormatted}<br/>`;
    cell.appendChild(nameDetailLink);
  }

  // Populates the distance cell of a location row
  populateDistanceCell(location, cell) {
    cell.innerHTML = location.distanceFormatted;
    cell.classList.add('pl-4');
  }

  // Populates the 'Select' button of a location row
  populateSelectBtnCell(location, cell) {
    const selectBtn = document.createElement('button');
    const selectBtnContent = document.createTextNode('Select');
    selectBtn.appendChild(selectBtnContent);
    selectBtn.classList.add('btn');
    selectBtn.classList.add('btn-secondary');
    selectBtn.classList.add('float-right');
    cell.appendChild(selectBtn);
    selectBtn.addEventListener('click', () => {
      this.closeSearchBtn.disabled = true;
      this.handleLocationSelected(location);
    });
  }

  // Fires when a location is selected from the location search results table
  handleLocationSelected(location) {
    sessionStorage.setItem('ptcLocation', JSON.stringify(location));
    setParams([
      {
        key: 'location_id',
        value: location.id
      },
      {
        key: 'vendor',
        value: location.vendor
      }
    ])
  }

  // Directs the user back to their remote testing scheduling page
  directToRemoteTesting() {
    let url = new URL(window.location.href);
    url.searchParams.delete("ptc");
    url.searchParams.delete("location_id");
    url.searchParams.append("ptc", "false");
    url.searchParams.append("remotely", "Submit");
    window.location.href = url;
  }

  // Renders the location table container (and thus, the location table)
  renderLocationTableContainer() {
    this.locationTableContainer.style = 'display: block';
  }

  // Hides the location table container
  hideLocationTableContainer() {
    this.locationTableContainer.style = 'display: none';
  }

  // Renders the location search modal body
  renderLocationSearch() {
    this.locationSearch.style = 'display: block';
  }

  // Hides the location search modal body
  hideLocationSearch() {
    this.locationSearch.style = 'display: none';
  }

  // The radius to search in, selected by the user
  selectedRadius() {
    const options = this.searchRadiusDropdown.options;
    return options[this.searchRadiusDropdown.selectedIndex].value;
  }

  // Hides the location details modal body
  hideLocationDetails() {
    this.locationDetails.container.style = 'display: none;';
  }

  // Renders the loading message, to be seen while locations are being fetched
  renderLoadingMsg() {
    this.loadingMsg.style = 'display: block';
  }

  // Hides the loading message
  hideLoadingMsg() {
    this.loadingMsg.style = 'display: none';
  }

  // Renders the blank slate message, to be seen when no valid locations have been found
  renderBlankSlate() {
    this.blankSlateMsg.textContent = this.blankSlateMsg.textContent.replace(
      /\d+ miles/,
      `${this.selectedRadius()} miles`
    );
    this.blankSlateElement.style = 'display: block';
  }

  // Hides the blank slate message
  hideBlankSlate() {
    this.blankSlateElement.style = 'display: none';
  }

  // Renders the error message with a given string
  renderErrorMsg(msg) {
    const msgEl = document.createTextNode(msg);
    this.browserLocationErrorMsg.style = 'display: block';
    this.browserLocationErrorMsg.appendChild(msgEl);
  }

  // Hides the error message
  hideErrorMsg() {
    this.browserLocationErrorMsg.innerHTML = '';
    this.browserLocationErrorMsg.style = 'display: none';
  }

  // Initializes the sidebar that displays when a location has been selected
  initSidebar(location) {
    this.sidebarLocationPanel.style = 'display: block';
    this.sidebarErrorPanel.style = 'display: none';
    this.initSidebarDetails(location);
    this.initSidebarLinks(location);
  }

  // Iniailizes the sidebar details of a given location
  initSidebarDetails(location) {
    this.sidebarLocationName.innerHTML = location.name;
    this.sidebarLocationAddressLineOne.innerHTML = location.addressLineOne;
    this.sidebarLocationAddressLineTwo.innerHTML = location.addressLineTwo;
  }

  // Initializes the sidebar links for a given location
  // includes the 'more details' link and the 'select another testing center'
  // link
  initSidebarLinks(location) {
    this.switchTestingCenterBtn.addEventListener('click', () => {
      this.displayLocationSearchModal();
      this.closeSearchBtn.style = 'display: block';
      // display the results near the currently selected location
      // the search will work with the inline HTML, but replace it with
      // a comma for better UX
      this.addressTextField.value =
        `${location.addressLineOne}, ${location.addressLineTwo}`
      this.searchByAddress(location.addressFormatted);
    });
  }

  // Renders the sidebar error panel for when a location could
  // not be found in the session storage but a location id is present
  // in the URL param
  renderSidebarErrorPanel() {
    this.sidebarErrorPanel.style = 'display: block';
    this.sidebarLocationPanel.style = 'display: none';
  }

  // Renders the details modal for a given location
  renderLocationDetailsModal(location) {
    this.$modal.modal();
    this.hideLocationSearch();
    this.renderLocationDetails(location);
    this.locationDetails.backToSearchBtn.style = 'display: none';
    this.locationDetails.closeBtn.style = 'display: block';
  }

  // Renders a given location's details on the location details container
  renderLocationDetails(location) {
    this.locationDetails.container.style = 'display: block';
    this.locationDetails.nameField.innerText = location.name;
    this.locationDetails.addressField.innerHTML = location.addressFormatted;

    // handle misc info (parking info)
    this.locationDetails.miscInfoField.innerText = '';
    if (location.parkingInformation) {
      this.locationDetails.miscInfoField.innerText = location.parkingInformation;
    }

    // handle feature list
    this.locationDetails.featureList.innerHTML = '';
    if (location.featureList == undefined || location.featureList.length == 0) {
      this.locationDetails.featureListHeader.style = "display: none";
    } else {
      for (const featureDescription of location.featureList) {
        const featureElement = document.createElement('li');
        featureElement.appendChild(document.createTextNode(featureDescription));
        this.locationDetails.featureList.appendChild(featureElement);
      }
      this.locationDetails.featureListHeader.style = "display: block";
    }

    new Geolocator().renderGoogleMapsEmbed(
      this.locationDetails.googleMapsEmbed,
      location.latitude,
      location.longitude,
      location.addressFormatted
    );
  }
}

export default LocationSearch;
