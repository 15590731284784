import titleize from '../../src/utils/titleize';

class AssignedStartsRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sessionTimer: this.setCurrentTime(
        props.session.fulfillment_state_updated_at ||
          props.session.fulfillment_state_created_at,
      ),
    };

    this.startTimer = this.startTimer.bind(this);
  }

  componentDidMount() {
    this.startTimer();
  }
  componentWillUnmount() {
    clearInterval(this.timer);
  }

  startTimer() {
    if (this.timer) {
      clearInterval(this.timer); //Clears any existing Timer interval
    }
    this.timer = setInterval(() => {
      //Sets an interval to update the currentTime every second
      this.setState({
        sessionTimer: this.setCurrentTime(
          this.props.session.fulfillment_state_updated_at ||
            this.props.session.fulfillment_state_created_at,
        ),
      });
    }, 1000);
  }

  setCurrentTime(timeIn) {
    const startTime = moment.utc(timeIn).format('HH:mm:ss');
    const now = moment.utc().format('HH:mm:ss');

    return moment
      .utc(moment(now, 'hh:mm:ss').diff(moment(startTime, 'HH:mm:ss')))
      .format('mm:ss');
  }

  formatEvent(eventName) {
    return eventName ? eventName.split('::')[1] : '';
  }

  render() {
    const { session } = this.props;
    return (
      <tr>
        <td className="text-truncate">
          <a href={session.user_link} target="_blank">
            {session.user_id}
          </a>
        </td>
        <td className="text-truncate">{session.institution_name}</td>
        <td className="text-truncate">{session.exam_name}</td>
        <td className="text-truncate">{session.proctor_full_name}</td>
        <td>
          <span>
            {titleize(session.fulfillment_state, '_')}{' '}
            <div style={{ minWidth: '50px', display: 'inline-block' }}>
              ({this.state.sessionTimer || '--:--'})
            </div>
          </span>
        </td>
        <td>{this.formatEvent(session.waiting_proctor_type)}</td>
        <td>
          <div>
            <span className="dropdown">
              <button
                aria-expanded="false"
                aria-haspopup="true"
                className="btn btn-primary btn-sm dropdown-toggle"
                data-toggle="dropdown"
                id="dropdownMenuButton"
                type="button"
              >
                Options
                <span className="caret" />
              </button>
              <div
                aria-labelledby="dropdownMenuButton"
                className="dropdown-menu dropdown-menu-right"
              >
                <a
                  className="dropdown-item"
                  onClick={() => {
                    this.props.handleClick(session, 'PUT');
                  }}
                  style={{ cursor: 'pointer' }}
                  tabIndex="2"
                >
                  Snooze
                </a>
                <a
                  className="dropdown-item"
                  onClick={() => {
                    this.props.handleClick(session, 'DELETE');
                  }}
                  style={{ cursor: 'pointer' }}
                  tabIndex="3"
                >
                  Resolve
                </a>
              </div>
            </span>
            <a
              href={session.fulfillment_link}
              tabIndex="1"
              target="_blank"
              className="btn btn-secondary btn-sm ml-1 fulfillmentButton"
              role="button"
              style={{
                color: '#696969',
              }}
            >
              View
            </a>
          </div>
        </td>
      </tr>
    );
  }
}

export default AssignedStartsRow;
