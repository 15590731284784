/**
 * Component for Table loading effect
 */
class TableLoader extends React.Component {
  render = () => {
    return (
      <div className='row'>
       <div className='col-md-12'>
        <h1 className="fs-3 text-center">
          <i className="far fa-circle-notch fa-spin fa-2x text-primary"></i><br />
          <span>Searching for Incident Reports...</span>
        </h1>
       </div>
      </div>
    )
  }
}

export default TableLoader;
