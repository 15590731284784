import React, { useEffect, useState } from 'react';
import ChatWidget from '../ChatWidget';
import { ChatType } from '../constants';
import ChatRoomSubscription from '../../../src/channels/subscriptions/ChatRoomSubscription';

const GUARDIAN_NAMESPACE = 'PU_Native_Chat';
const GUARDIAN_CALLBACKS = {
  notifyNewMessage: 'notifyNewMessage',
  testTakerClosedChat: 'testTakerClosedChat',
  proctorClosedChat: 'proctorClosedChat',
};

/**
 *
 * @param {keyof GUARDIAN_CALLBACKS} callbackName
 * @param  {...any} args
 */
const callGuardianCallback = (callbackName, ...args) => {
  if (window?.[GUARDIAN_NAMESPACE]?.[callbackName]) {
    console.log('Calling Guardian callback', callbackName, args);
    window[GUARDIAN_NAMESPACE][callbackName](...args);
  }
};

/**
 * @typedef {Object} Message
 * @property {string} message
 * @property {number} userId
 * @property {number} timestamp
 */

/**
 * @typedef {Object} ChatAppProps
 * @property {number} userId
 * @property {number} chatRoomId
 * @property {typeof ChatType} chatType
 * @property {number} initialRoomSize
 * @property {boolean} proctorConnected
 * @property {Message[]} initialMessages
 */

/**
 * A chat application that allows proctors to communicate with test takers via chat rooms.
 *
 * @param {ChatAppProps} props
 * @returns  {React.ReactElement}
 */
export default function ChatApp({
  userId,
  chatRoomId,
  chatType,
  initialRoomSize,
  proctorConnected,
  initialMessages = [],
}) {
  /**
   * @type {[ChatRoomSubscription?, (socket: ChatRoomSubscription?) => void]}
   */
  const [socket, setSocket] = useState();
  const [isConnected, setIsConnected] = useState();
  /**
   * @type {[Message[], (messages: Message[]) => void]}
   */
  const [messages, setMessages] = useState(initialMessages);
  const [isProctorConnected, setIsProctorConnected] =
    useState(proctorConnected);
  const [roomSize, setRoomSize] = useState(initialRoomSize);
  const [isChatClosed, setIsChatClosed] = useState(false);

  const handleSocketConnect = () => {
    console.log('Connected');
    setIsConnected(true);
  };

  const handleNewChatMessage = (message) => {
    setMessages((prevMessages) => [...prevMessages, message]);
    if (message.user_id !== userId) {
      callGuardianCallback(GUARDIAN_CALLBACKS.notifyNewMessage, message);
    }
  };

  const handleSocketDisconnect = () => {
    console.log('Disconnected');
    setIsConnected(false);
  };

  const handleChatClosed = () => {
    socket?.connection?.unsubscribe();
    setSocket(undefined);
    setIsProctorConnected(false);
    setRoomSize(0);
    setMessages([]);
    setIsChatClosed(true);
    callGuardianCallback(GUARDIAN_CALLBACKS.proctorClosedChat);
  };

  const handleSocketMessage = (message) => {
    console.log('Received', message);

    switch (message.type) {
      case 'error':
        console.error('Error', message);
        break;
      case 'chat_received':
        handleNewChatMessage(message);
        break;
      case 'chat_room_updated':
        const { chat_room, room_size } = message;
        setRoomSize(room_size);
        setIsProctorConnected(chat_room.proctor_connected);
        if (!chat_room.active) {
          handleChatClosed();
        }
        break;
      case 'chat_room_closed':
        handleChatClosed();
        break;
      default:
        console.warn('Unknown message type', message.type);
    }
  };

  const handleSendChatMessage = (message) => {
    console.log('Sending a chat message to the room', message);
    socket?.sendMessage({
      user_id: userId,
      type: 'chat_sent',
      message,
    });
  };

  useEffect(() => {
    const newSocket = new ChatRoomSubscription(chatRoomId);
    newSocket.init();
    newSocket.connection.received = handleSocketMessage;
    newSocket.connection.disconnected = handleSocketDisconnect;
    newSocket.connection.connected = handleSocketConnect;

    setSocket(newSocket);

    return () => {
      newSocket.connection.unsubscribe();
    };
  }, [chatRoomId]);

  return (
    <ChatWidget
      userId={userId}
      chatRoomId={chatRoomId}
      chatType={chatType}
      isConnected={isConnected}
      isChatClosed={isChatClosed}
      messages={messages}
      roomSize={roomSize}
      isProctorConnected={isProctorConnected}
      onMessage={handleSendChatMessage}
    />
  );
}
