import { useEffect } from 'react';
import PrechecksHeaderGroup from './CardSections/PrechecksHeaderGroup';
import PrechecksBodyText from './CardSections/PrechecksBodyText';
import PrechecksFooterButton from './CardSections/PrechecksFooterButton';
import ImageGroup from '../Shared/ImageGroup';
import GenericSpinner from '../GenericSpinner';

const GuidedLaunchRoomScanReviewCard = ({
  id,
  icon,
  iconPrefix,
  title,
  paragraph1,
  paragraph2,
  paragraph3,
  againBtnText,
  secureBtnText,
  precheckData,
  sendEvent,
  handleNext,
  handlePrevious,
  glImages,
  getGlImages,
}) => {
  const headerImage = precheckData.images.reviewScanImg;

  useEffect(() => {
    sendEvent('Event::ReviewScanLoaded');
    getGlImages('150');
  }, []);

  const handleAgainBtnClick = () => {
    sendEvent('Event::ScanAgain');
    handlePrevious(true);
  };

  const handleSecureBtnClick = () => {
    sendEvent('Event::RoomScanReviewCompleted');
    handleNext();
  };

  const criteriaList = () => {
    return (
      <ul>
        <li>{polyglot.t('prechecks_guidedlaunch_review_scan_criteria1')}</li>
        <li>{polyglot.t('prechecks_guidedlaunch_review_scan_criteria2')}</li>
        <li>{polyglot.t('prechecks_guidedlaunch_review_scan_criteria3')}</li>
        <li>{polyglot.t('prechecks_guidedlaunch_review_scan_criteria4')}</li>
      </ul>
    );
  };

  return (
    <div className="container-fluid text-center precheck-card-content" id={id}>
      <PrechecksHeaderGroup
        title={title}
        image={headerImage}
        icon={icon}
        iconPrefix={iconPrefix}
      />
      <div className="text-left">
        <PrechecksBodyText bodyText={paragraph1} />
        {criteriaList()}
        <PrechecksBodyText bodyText={paragraph2} />
      </div>
      {glImages.length === 0 ? (
        <GenericSpinner />
      ) : (
        <ImageGroup images={glImages} />
      )}
      <div className="text-left">
        <PrechecksBodyText bodyText={paragraph3} />
      </div>
      <div className="d-flex justify-content-center">
        <PrechecksFooterButton
          buttonText={againBtnText}
          handleClick={handleAgainBtnClick}
          buttonStyleClass="btn-precheck-secondary"
        />
        <PrechecksFooterButton
          buttonText={secureBtnText}
          handleClick={handleSecureBtnClick}
        />
      </div>
    </div>
  );
};

GuidedLaunchRoomScanReviewCard.defaultProps = {
  title: polyglot.t('prechecks_guidedlaunch_review_scan_title'),
  paragraph1: polyglot.t('prechecks_guidedlaunch_review_scan_paragraph1'),
  paragraph2: polyglot.t('prechecks_guidedlaunch_review_scan_paragraph2'),
  paragraph3: polyglot.t('prechecks_guidedlaunch_review_scan_paragraph3'),
  icon: 'fa-camera',
  iconPrefix: 'fal',
  statusColor: 'primary',
  againBtnText: polyglot.t('prechecks_guidedlaunch_review_scan_again_button'),
  secureBtnText: polyglot.t('prechecks_guidedlaunch_review_scan_secure_button'),
};

export default GuidedLaunchRoomScanReviewCard;
