class TableStats {
  constructor() {
    this.table = $(".table-stats");
    this.tabNames = [
      "departments",
      "terms",
      "instructors",
      "iterations",
      "enrollments"
    ];
  }

  init() {
    this.bindEventListeners();
    this.setActiveTab();
  }

  bindEventListeners() {
    const t = this;

    this.table.delegate("td, th", "mouseover mouseleave", e => {
      t.toggleTableClasses(e);
    });
  }

  toggleTableClasses(e) {
    const $this = $(e.currentTarget);
    const $table = $this.closest("table");
    const index = $this.index();

    if (e.type == "mouseover") {
      $table.find(`tr td:nth-child(${index + 1})`).addClass("hover");
      $table.find(`th:nth-child(${index + 1})`).addClass("hover");
    } else {
      $table.find(`tr td:nth-child(${index + 1})`).removeClass("hover");
      $table.find(`th:nth-child(${index + 1})`).removeClass("hover");
    }
  }

  setActiveTab() {
    const t = this;
    const pathName = window.location.pathname;
    let index = 0;

    _.map(this.tabNames, (tab, index) => {
      if (pathName.indexOf(tab) > -1) {
        t.table.find(`th:nth-child(${index + 1})`).addClass("active");
        t.table.find(`tr td:nth-child(${index + 1})`).addClass("active");
      }
    });
  }
}

export default TableStats;
