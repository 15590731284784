import React from 'react';
import { WizardStepMenu } from './WizardStepMenu';

const WizardStepMenuContainer = ({ currentStep }) => {
  const stepMenu = [
    {
      menuIdx: 1,
      menuLabel: 'Proctoring Settings',
    },
    {
      menuIdx: 2,
      menuLabel: 'Department',
    },
    {
      menuIdx: 3,
      menuLabel: 'Configurations',
    },
    {
      menuIdx: 4,
      menuLabel: 'Exam Windows',
    },
    {
      menuIdx: 5,
      menuLabel: 'Allowances',
    },
    {
      menuIdx: 6,
      menuLabel: 'Notifications',
    },
  ];

  return currentStep <= stepMenu.length ? (
    <WizardStepMenu currentStep={currentStep} stepMenu={stepMenu} />
  ) : null;
};

export { WizardStepMenuContainer };
