import ServiceLine from '../ServiceLine';

export default function setIterationType(iterationType) {
    const iterationTypeSelector =
      document.querySelector('[data-element="iteration-types"]') ||
      document.querySelector('[data-element="iteration-type-hidden"]');
    if (iterationType) {
      iterationTypeSelector.value = iterationType;
      iterationTypeSelector.dispatchEvent(new Event('change'));
    } else {
      new ServiceLine().handleChange();
    }
  }
