import $ from 'jquery';
import 'select2';

$(document).on('turbolinks:load', function () {
  initializeFacultyUserAutocomplete();
});

$(document).on('cocoon:after-insert', function (e, insertedItem) {
  initializeFacultyUserAutocomplete(insertedItem);
});

function initializeFacultyUserAutocomplete(scope) {
  var $element = scope
    ? $(scope).find('.faculty-user-autocomplete')
    : $('.faculty-user-autocomplete');

  $element.each(function () {
    $(this).select2({
      ajax: {
        url: function () {
          return $(this).data('url');
        },
        dataType: 'json',
        delay: 250,
        data: function (params) {
          return {
            query: params.term,
          };
        },
        processResults: function (data) {
          return {
            results: data,
          };
        },
        cache: true,
      },
      minimumInputLength: 2,
      dropdownParent: $(this).parent(),
      theme: 'bootstrap4',
    });

    $(this).on('select2:open', function () {
      $(this)
        .siblings('.select2-container')
        .find('.select2-selection')
        .addClass('form-control');
    });
  });
}
