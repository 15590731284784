import handleIterationChange from './utils/handleIterationChange';

class PresetsBrowserTabs {
  constructor() {
    this.iterationBrowerTabs = document.querySelector('[data-element="browser_tabs"]');
    this.addUrlButton = document.querySelector('[data-element="add-new-url"]');
  }

  init() {
    if(this.iterationBrowerTabs) {
      this.iterationBrowerTabs.addEventListener('change', (event) => { handleIterationChange(event) })
    }
  }

  browserTabChange() {
    if (this.addUrlButton) {
      if (this.iterationBrowerTabs.value === 'restricted') {
        this.addUrlButton.classList.remove('disabled');
      } else {
        this.addUrlButton.classList.add('disabled');
      }
    }
  }
}

export default PresetsBrowserTabs;
