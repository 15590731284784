import axios from 'axios';

import csrfToken from '../../src/utils/csrf';

/** @typedef {import('../../types').Language} Language */

/**
 * @typedef LanguageDropdownProps
 * @property {string} selectLanguage
 * @property {Language[]} languages
 * @property {string} icon
 * @property {boolean} displayAsNavItem
 */

/**
 * @param {LanguageDropdownProps} props
 * @returns {React.ReactElement}
 */
const LanguageDropdown = ({
  selectLanguage,
  languages,
  icon,
  displayAsNavItem,
}) => {
  /**
   * Handles when a language is selected from the dropdown menu.
   * @param {React.MouseEvent<HTMLButtonElement>} event
   * @returns {void}
   */
  const handleItemClicked = (event) => {
    const languageName = event.currentTarget.value;
    const url = `${window.location.origin}/account/change_language`;
    const data = { preferred_language: languageName };
    const config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken(),
      },
    };

    axios
      .patch(url, data, config)
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div
      className={`dropdown ${displayAsNavItem ? '' : 'position-absolute'}`}
      style={{ right: 0, zIndex: 1051 }}
    >
      <button
        className={`btn dropdown-toggle ${displayAsNavItem ? 'text-white' : 'precheck-dark'}`}
        type="button"
        data-toggle="dropdown"
        aria-expanded="false"
      >
        <i className={icon}></i>
        {selectLanguage}
      </button>
      <div className="dropdown-menu" style={{ zIndex: 1051 }}>
        {languages.map(({ name, locale }) => (
          <button
            key={locale}
            value={name}
            className="dropdown-item precheck-dropdown-item"
            type="button"
            onClick={handleItemClicked}
          >
            {polyglot.t(`prechecks_language_dropdown_${locale}`)}
          </button>
        ))}
      </div>
    </div>
  );
};

/** @type {LanguageDropdownProps} */
LanguageDropdown.defaultProps = {
  selectLanguage: polyglot.t('prechecks_language_dropdown_select'),
  languages: [
    { name: 'prechecks_language_dropdown_en', locale: 'en' },
    { name: 'prechecks_language_dropdown_es', locale: 'es' },
    { name: 'prechecks_language_dropdown_fr_ca', locale: 'fr_ca' },
  ],
  icon: 'fal fa-globe',
  displayAsNavItem: false,
};

export default LanguageDropdown;
