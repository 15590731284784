import csrfToken from '../utils/csrf';

class AbortButton {
  constructor() {
    this.abortButton = document.querySelector('#abort-fulfillment');
    this.abortFulfillment = this.abortFulfillment.bind(this);
  }

  init() {
    if (this.abortButton) {
      this.bindEventListeners();
    }
  }

  bindEventListeners() {
    this.abortButton.addEventListener('click', this.abortFulfillment);
  }

  abortFulfillment() {
    const confirmationMessage = 'You are about to abort the fulfillment. Proceed?';
    const userConfirmation = confirm(confirmationMessage);
    if (userConfirmation) {
      fetch(this.abortButton.dataset.url, {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
        }
      });
      this.abortButton.classList.add('d-none');
    }
  }
}

export default AbortButton;
