import Toast from '../../utils/Toast';

class DestroyExternalReviewNote {
  constructor() {
    this.notesStream = document.querySelector("[data-behavior=external-review-note-stream]")
    this.count = document.querySelector('.ern_size_incident_report_center_incident_report')
  };

  init() {
    this.bindEventListeners();
  }

  bindEventListeners() {
    if(this.notesStream) {
      this.notesStream.addEventListener('click', (event) => { this.handleClick(event) })
    }
  }

  handleClick(event) {
    event.preventDefault();
    if(event.target.getAttribute('data-behavior') === 'destroy-external-review-note' ) {
      if (confirm('Are you sure you want to remove this?')) {
        $.ajax({
          method: 'DELETE',
          url: event.target.getAttribute('data-url'),
          contentType: 'application/json',
          success: () => {
            const card = event.target.closest('.card')
            card.parentNode.removeChild(card)
            this.count.innerHTML = parseInt(this.count.textContent) - 1
            new Toast().success({message: "Successfully removed external note."})
          },
          error: function(response) {
            new Toast().danger({message: "Unable to remove this External Review Note."})
          }
        });
      }
    }
  }
}

export default DestroyExternalReviewNote
