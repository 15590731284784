class Select2Inviter {
  constructor() {
    ($('.iteration-form-user-select')).select2({
      placeholder: 'Select a user',
      theme: 'bootstrap4',
      ajax: {
        url: ($('.iteration-form-user-select')).data('source'),
        data: function(search, page) {
          return {
            search: search.term
          };
        },
        processResults: function(users, page) {
          return { results: users };
        }
      },
      minimumInputLength: 2
    });
  }
}

export default Select2Inviter;
