class AdditionalEnrollmentField {
  constructor() {
    this.fieldTypeInput = document.querySelectorAll(
      '[data-behavior=field-type]'
    );
    this.targetNode = document.getElementById(
      'js-cocoon-additional-enrollment-fields'
    );
    this.selectOptions = document.querySelectorAll(
      '[data-behavior="select-options"]'
    );
    this.addOptionButtons = document.querySelectorAll(
      '[data-behavior="add-option"]'
    );

    this.handleAddOptionButtonClick = this.handleAddOptionButtonClick.bind(
      this
    );
    this.handleFieldTypeChange = this.handleFieldTypeChange.bind(this);
  }

  init() {
    this.fieldTypeInput.forEach(fieldType => {
      if (fieldType.value == 'checkbox') {
        this.hideRequiredInputField(fieldType);
      } else if (fieldType.value == 'select') {
        this.showSelectOptionsFields(fieldType);
      }
    });
    this.bindEventListeners(this.fieldTypeInput);
    this.bindButtonEventListeners(this.addOptionButtons);
    if(this.targetNode) {
      this.addAdditionalEnrollmentFieldObserver();
    }
  }

  addAdditionalEnrollmentFieldObserver() {
    const observerConfig = {
      childList: true,
      subtree: false,
      attributes: false
    };

    const callback = () => {
      const allFieldTypeInputs = document.querySelectorAll(
        '[data-behavior=field-type]'
      );
      const addOptionButtons = document.querySelectorAll(
        '[data-behavior="add-option"]'
      );
      this.bindEventListeners(allFieldTypeInputs);
      this.bindButtonEventListeners(addOptionButtons);
    };

    const observer = new MutationObserver(callback);

    observer.observe(this.targetNode, observerConfig);
  }

  bindEventListeners(nodeList) {
    nodeList.forEach(input => {
      input.addEventListener('change', event => {
        this.handleFieldTypeChange(event.target);
      });
    });
  }

  bindButtonEventListeners(nodeList) {
    nodeList.forEach(btn => {
      if (!(btn.getAttribute('hasListener') == 'true')) {
        btn.setAttribute('hasListener', 'true');
        btn.addEventListener('click', event => {
          this.handleAddOptionButtonClick(event);
        });
      }
    });
  }

  handleFieldTypeChange(fieldType) {
    if (fieldType.value == 'checkbox') {
      this.hideRequiredInputField(fieldType);
      this.hideSelectOptionsFields(fieldType);
    } else if (fieldType.value == 'select') {
      this.showSelectOptionsFields(fieldType);
      this.showRequiredInputField(fieldType);
    } else {
      this.showRequiredInputField(fieldType);
      this.hideSelectOptionsFields(fieldType);
    }
  }

  handleAddOptionButtonClick(event) {
    event.preventDefault();
    const selectOptionsFields = event.target.parentElement.parentElement;
    const blankOptionField = selectOptionsFields.querySelector(
      "input[data-behavior='blank-option-field']"
    );
    const optionsInputFields = selectOptionsFields.querySelector(
      "div[data-behavior='options-input-fields']"
    );
    const clonedField = blankOptionField.cloneNode();
    clonedField.classList.remove('d-none');
    optionsInputFields.append(clonedField);
  }

  hideRequiredInputField(fieldType) {
    const requiredInputField = this.getRequiredInputField(fieldType);
    const requiredCheckbox = requiredInputField.querySelector(
      "input[type='checkbox']"
    );
    requiredCheckbox.checked = false;
    requiredInputField.style.display = 'none';
  }

  showSelectOptionsFields(fieldType) {
    const selectOptionsFields = this.getSelectOptionsFields(fieldType);
    selectOptionsFields.classList.remove('d-none');
  }

  hideSelectOptionsFields(fieldType) {
    const selectOptionsFields = this.getSelectOptionsFields(fieldType);
    selectOptionsFields.classList.add('d-none');
  }

  showRequiredInputField(fieldType) {
    const requiredInputField = this.getRequiredInputField(fieldType);
    requiredInputField.style.display = '';
  }

  getRequiredInputField(el) {
    return el.parentElement.parentElement.parentElement.querySelector(
      "div[data-behavior='required']"
    );
  }

  getSelectOptionsFields(el) {
    return el.parentElement.parentElement.parentElement.querySelector(
      "div[data-behavior='select-options']"
    );
  }
}

export default AdditionalEnrollmentField
