import { Modal } from 'react-bootstrap';
import GenericButton from '../../../GenericButton';

const ResolveModal = (props) => {
	const showModal = props.showModal;
	const openModal = props.openModal;
	const closeModal = props.closeModal;
	const sendEvent = props.sendEvent;
	const procedure = props.procedure;

	const MODAL_EVENTS = {
		'verify_id': {
			confirm: 'Event::VerifyIdIssueResolved',
			is_submit: 'Event::NotifyIsVerifyIdStep'
		},
		'resource_validation': {
			confirm: 'Event::ResourceValidationIssueResolved',
			is_submit: 'Event::NotifyIsResourceValidation'
		},
		'secure_environment': {
			confirm: 'Event::SecureEnvironmentIssueResolved',
			is_submit: 'Event::NotifyIsSecureEnvironment'
		},
		'phone_check': {
			confirm: 'Event::PhoneCheckIssueResolved',
			is_submit: 'Event::NotifyIsPhoneCheck'
		},
		'instructions': {
			confirm: 'Event::ResolvedInstructionsIssue',
			is_submit: 'Event::NotifyIsInstructionStep'
		},
		'enter_into_exam': {
			confirm: 'Event::EnteredIntoExamIssueResolved',
			is_submit: 'Event::NotifyIsEnteredIntoExam'
		},
		'verify_test_submission': {
			confirm: 'Event::VerifyTestSubmissionIssueResolved',
			is_submit: 'Event::NotifyIsVerifyTestSubmission'
		},
		'fulfill_session': {
			open: '',
			confirm: '',
			is_submit: ''
		}
	};

	const resolveIssue = () => {
		if (MODAL_EVENTS[procedure.proc_type] && MODAL_EVENTS[procedure.proc_type].confirm.length > 0) {
			sendEvent(MODAL_EVENTS[procedure.proc_type].confirm, {});
		}
		closeModal();
	};

	const sendIssueToIs = () => {
		const commentEl = document.querySelector('#resolveText');
		let commentValue = '';
		if (MODAL_EVENTS[procedure.proc_type] && MODAL_EVENTS[procedure.proc_type].is_submit.length > 0) {
			if (commentEl && commentEl.value.length > 0) {
				commentValue = commentEl.value;
				sendEvent('Event::TouchPoint', {
					touchPointKind: 'Intervention',
					comment: commentValue
				});
			}
			sendEvent(MODAL_EVENTS[procedure.proc_type].is_submit, {});
		}
		closeModal();
	};

	return  (
		<Modal show={showModal} onHide={closeModal} size="md">
			<Modal.Header closeButton>
				Resolve Issue with Guided Launch
			</Modal.Header>
			<Modal.Body>
				<div style={{width: '100%'}}>
					<label htmlFor={'resolveText'}>Please log the steps you are taking to resolve this issue:</label>
					<textarea id={'resolveText'} style={{width: '100%'}} rows={4}/>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<div className={'row col-md-12 px-0'}>
					<div className={'text-left pull-left col-md-3 px-0'}>
						<GenericButton className={'btn btn-light border-danger ml-2'} onClick={sendIssueToIs} text={'Notify IS'}/>
					</div>
					<div className={'text-right pull-right col-md-9 px-0'}>
						<GenericButton className={'btn btn-secondary mr-2'} onClick={closeModal} text={'Cancel'}/>
						<GenericButton className={'btn btn-primary'} onClick={resolveIssue} text={'Mark as Resolved'}/>
					</div>
				</div>
			</Modal.Footer>
		</Modal>
	)
}

export default ResolveModal;
