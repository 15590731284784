import csrfToken from '../utils/csrf';
import Toast from '../utils/Toast';
import axios from 'axios';

class TransferButton {
  constructor() {
    this.component = document.querySelector('.js-transfer')
    this.transferForm = document.querySelector('.transfer-form')
    this.closeTransfer = document.querySelector('.close-transfer')
  }

  init() {
    this.bindEventListeners()
  }

  bindEventListeners() {
    this.component.addEventListener('click', (event) => { this.showModal() })
    this.transferForm.addEventListener('submit', (event) => { this.submitForm(event) })
    this.closeTransfer.addEventListener('click', (event) => { this.hideModal(event) })
  }

  showModal() {
    $('#transfer-form-modal').modal('show')
  }

  hideModal(event = null) {
    if (event != null) { event.preventDefault(); }
    $('#transfer-form-modal').modal('hide')
  }

  transferData() {
    const currentUser = this.getInputFieldValue('current-user')
    const proctor = this.getInputFieldValue('proctor');
    const sub_role = this.getInputFieldValue('sub-role')
    if (currentUser == null || proctor == null) { return null }
    const comment = currentUser + " transferred to " + proctor + " (" + sub_role + ")."
    return {
      type: 'Transfer',
      event: { comment: comment, proctor: proctor, sub_role: comment }
    }
  }

  getInputFieldValue(fieldName) {
    if(document.querySelector(`.transfer-${fieldName}`).value.length <= 0){
      new Toast().warning({message: `${fieldName} is required.`})
      return null;
    } else {
      return $(`.transfer-${fieldName}`).first().val();
    }
  }

  submitForm(event) {
    event.preventDefault()

    const headers = {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken()
      }
    }
    const data = this.transferData()
    if(data != null) {
      axios.post(this.transferForm.getAttribute('action'), JSON.stringify(data), headers)
      .then(response => {
        this.handleSuccess(event);
      })
      .catch(error => {
        new Toast().danger({message: 'Unable to transfer.'});
      });
    }
  }

  handleSuccess(event) {
    const liItem = `<li class="list-group-item warning-fade">
                      <div class="media">
                        <div class="float-left">
                          <div class="iconbox">
                            <div class="icon">
                              <span class="fa fa-${ event.icon }"></span>
                            </div>
                          </div>
                        </div>
                        <div class="media-body">
                          <small class="text-muted mx-auto">${ event.date }</small>
                          <strong class="media-heading mb-0">
                            ${ event.heading }
                          </strong>
                          <div class="mt-3">
                            ${ event.comment }
                          </div>
                        </div>
                      </div>
                    </li>`
    this.hideModal()
    new Toast().success({message: event.comment })
    if (liItem.length) {
      $('ul.timeline').prepend(liItem)
    }
  }

  renderToast() {
    const toast = new Toast(this.$rootNodeSelector)
    toast.success({message: "Successfully transferred."})
  }
}

export default TransferButton;
