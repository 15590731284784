import React from 'react';

import UseAppointmentWizard, { Context } from './UseAppointmentWizard';
import Root from './Root';

const AppointmentWizardNew = ({
  mountpoint,
  form,
  previousStepUrl,
  handleChangeElement,
}) => {
  const controls = UseAppointmentWizard(
    mountpoint,
    form,
    previousStepUrl,
    null,
    null,
    handleChangeElement,
  );

  return (
    <Context.Provider value={controls}>
      <Root />
    </Context.Provider>
  );
};

export default AppointmentWizardNew;
