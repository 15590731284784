class ExamFulfilledModal {
  constructor() {
    this.examFulfilledModal = $('#exam-fulfilled-modal');
    this.closeExamFulfilledModal = $('#close-exam-fulfilled-modal');
  }

  init() {
    if (this.examFulfilledModal) {
      this.examFulfilledModal.modal()
    }
    document.querySelector('[data-behavior="cancel-modal-button"]').addEventListener('click', this.handleCancelClick);
  }

  handleCancelClick(e) {
    e.preventDefault();
    $('#exam-fulfilled-modal').modal('hide');
  }

}
export default ExamFulfilledModal

