import { useEffect, useMemo, useState } from 'react';

import enMessages from '../../javascript/messages/en.json';
import esMessages from '../../javascript/messages/es.json';
import frMessages from '../../javascript/messages/fr.json';

export default function useLocale() {
  const [locale, setLocale] = useState('en');

  useEffect(() => {
    setLocale(
      document.querySelector('span.user-data')?.getAttribute('locale') ||
        window.navigator.language ||
        'en',
    );
  }, []);

  const messages = useMemo(() => {
    let messages = { ...enMessages };

    if (locale.startsWith('fr')) {
      messages = _.merge(messages, frMessages);
    } else if (locale.startsWith('es')) {
      messages = _.merge(messages, esMessages);
    } else if (locale.startsWith('ja')) {
      // TODO: japanese translations
    } else if (locale.startsWith('de')) {
      // TODO: german translations
    }

    return messages;
  }, [locale]);

  return { locale, setLocale, messages };
}
