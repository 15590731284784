import addDynamicEventListener from '../../utils/dynamicListener';

class RetakePolicy {
  constructor() {
    this.retakePolicyModal = $("[data-modal-id='retake-policy-modal']");
    this.enforceRetakePolicy = this.retakePolicyModal.length > 0;

    this.scheduleExamSelector = '[data-behavior="schedule-exam"]';

    this.onDemandSubmitButton = document.querySelector(
      'input[name="commit"]'
    );
    this.onDemandSelection = document.querySelector(
      '#on_demand_true'
    );

    this.handleOnDemandScheduleClick = this.handleOnDemandScheduleClick.bind(this);
    this.handleScheduleClick = this.handleScheduleClick.bind(this);
  }

  init() {
    if (this.enforceRetakePolicy) {
      this.bindEventListeners();
    }
  }

  bindEventListeners() {
    // first check if we're on the 'On Demand' flow, and bind event listeners to the
    // on demand selector if appropriate
    if (this.onDemandSubmitButton) {
      this.onDemandSubmitButton.addEventListener('click', this.handleOnDemandScheduleClick);
    } else {
      // otherwise assume we're on the regular flow
      addDynamicEventListener(
        document.body,
        'click',
        this.scheduleExamSelector,
        (event) => { this.handleScheduleClick(event) }
      );
    }
  }

  handleOnDemandScheduleClick(event) {
    if (this.onDemandSelection.checked === true) {
      event.preventDefault();
      this.showRetakePolicyModal();
    }
  }

  handleScheduleClick(event) {
    event.preventDefault();
    this.showRetakePolicyModal();
  }

  showRetakePolicyModal() {
    this.retakePolicyModal.modal('show');
  }
}

export default RetakePolicy;
