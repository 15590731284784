import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import RenderLMSComponent from '../RenderLMSComponent';
import WizardStepMenu from '../WizardStepMenu';

function LMSExamModal({
  showModal,
  showProgress,
  pageUrl,
  saveNow,
  exam,
  step,
  html,
  enableButton,
  setShowProgress,
  setShowModal,
  setHTML,
  handleSubmit,
  handleNext,
  handlePrevious,
  handleClose,
  handleChangeElement,
}) {
  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        centered
        dialogClassName="modal-dialog-full-width"
      >
        <Modal.Header closeButton id="step-modal-header">
          <Modal.Title className="custom-header">
            {!saveNow
              ? 'Manual Proctoring Configuration'
              : 'Express Proctoring Configuration'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body id="step-modal-body">
          {!showProgress && <h2 className="mb-2rem">{exam?.exam_name}</h2>}
          {!saveNow && !showProgress && (
            <div className="mb-2rem">
              <WizardStepMenu currentStep={step} />
            </div>
          )}
          <RenderLMSComponent
            pageUrl={pageUrl}
            saveNow={saveNow}
            showProgress={showProgress}
            exam={exam}
            step={step}
            html={html}
            setShowProgress={setShowProgress}
            setShowModal={setShowModal}
            setHTML={setHTML}
            handleChangeElement={handleChangeElement}
          />
        </Modal.Body>
        <Modal.Footer id="step-modal-footer">
          <div className="lms-modal-buttons">
            <div className="lms-modal-back">
              {step > 1 && !saveNow && (
                <Button
                  variant="secondary-meazure small"
                  onClick={handlePrevious}
                >
                  Back
                </Button>
              )}
            </div>
            <div className="lms-modal-next">
              {saveNow ? (
                <Button
                  variant="primary-meazure small"
                  onClick={handleSubmit}
                  disabled={!enableButton}
                >
                  Save Changes
                </Button>
              ) : (
                <Button
                  variant="primary-meazure small"
                  onClick={handleNext}
                  disabled={!enableButton}
                >
                  {step == 6 ? 'Save Changes' : 'Next'}
                </Button>
              )}
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export { LMSExamModal };
