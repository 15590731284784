import PropTypes from 'prop-types';

const Button = ({
  className = " ",
  type,
  icon,
  callback
}) => (
  <a
    aria-hidden="true"
    type={type}
    onClick={callback}
    className={`fa ${icon} ${className}`}
  />
)

Button.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired
}

export default Button
