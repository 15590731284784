import { useEffect, useRef, useState } from 'react';

import PrechecksBodyText from './CardSections/PrechecksBodyText';
import PrechecksFooterButton from './CardSections/PrechecksFooterButton';
import PrechecksHeaderGroup from './CardSections/PrechecksHeaderGroup';
import PrechecksLmiDownloadForm from './CardSections/PrechecksLmiDownloadForm';
import { PrechecksVideoPlayer } from './PrechecksVideoPlayer';

const Platform = Object.freeze({
  WINDOWS: 'windows',
  MAC: 'mac',
});

/**
 * @typedef DownloadInstructionsProps
 * @property {PrecheckData} precheckData
 * @property {Platform} platform The platform/user agent the download instructions are rendered for.
 * @property {Function} downloadLMI The function to handle the LMI download action.
 */

/**
 * Renders the LMI download instructions based on the platform (Mac or Windows).
 *
 * @param {DownloadInstructionsProps} props The props for the component.
 * @returns {React.ReactElement}
 */
const DownloadInstructions = ({
  precheckData,
  platform = Platform.MAC,
  downloadLMI,
}) => {
  const lmiVideo =
    platform === Platform.MAC
      ? precheckData.videos.logmeinrescueMacMp4
      : precheckData.videos.logmeinrescueWindowsMp4;

  return (
    <>
      <div className="text-left">
        <PrechecksBodyText
          bodyText={polyglot.t('prechecks_lmi_instructions_text1')}
        />
        <div className="col-md-12">
          <ol className="p-0">
            <li>
              <span
                className="d-inline"
                dangerouslySetInnerHTML={{
                  __html: polyglot.t(
                    `prechecks_lmi_instructions_${platform}_list1`,
                  ),
                }}
              />
              &nbsp;
              <a
                className="precheck-a d-inline"
                onClick={(event) => downloadLMI(event)}
                href="#"
              >
                <u>
                  {polyglot.t('prechecks_lmi_instructions_lmi_download_link')}
                </u>
              </a>
            </li>
            <li>
              <span
                dangerouslySetInnerHTML={{
                  __html: polyglot.t('prechecks_lmi_instructions_list2'),
                }}
              ></span>
            </li>
          </ol>
        </div>
      </div>

      <PrechecksVideoPlayer
        videoInfo={lmiVideo}
        idPrefix="lmi-download-instruction"
      />

      <div className="text-left pt-4">
        <PrechecksBodyText
          bodyText={polyglot.t(`prechecks_lmi_instructions_${platform}_text2`)}
        />
      </div>
    </>
  );
};

/**
 * @typedef LmiDownloadCardProps
 * @property {string} id
 * @property {string} icon
 * @property {string} iconPrefix
 * @property {() => void} handleNext
 * @property {PrecheckData} precheckData
 * @property {SendEventFunction} sendEvent
 * @property {boolean} lmiConnected
 * @property {boolean} lmiConnectedFlipper
 */

/**
 * Renders a card component with instructions and buttons for downloading LMI (LogMeIn).
 * @param {LmiDownloadCardProps} props
 * @returns {React.ReactNode}
 */
export default function LmiDownloadCard({
  id,
  icon = 'fa-hourglass-half',
  iconPrefix = 'fal',
  handleNext,
  precheckData,
  sendEvent,
  lmiConnected,
  lmiConnectedFlipper,
}) {
  const [loadingText, setLoadingText] = useState('');
  const ref = useRef(/** @type {PrechecksLmiDownloadFormRef} */ null);

  const {
    images: { connectingImg },
    integratedLmiChat,
  } = precheckData;
  const platform = navigator.userAgent.includes('Mac')
    ? Platform.MAC
    : Platform.WINDOWS;

  const handleDownloadLmi = (event) => {
    event.preventDefault();
    ref.current?.downloadLMI();
  };

  useEffect(() => {
    if (lmiConnectedFlipper && lmiConnected) handleNext();
  }, [lmiConnected, lmiConnectedFlipper]);

  useEffect(() => {
    if (!lmiConnectedFlipper) return;
    const timeoutId = setTimeout(() => {
      setLoadingText(polyglot.t('prechecks_lmi_instructions_loading'));
    }, 2000);
    return () => clearTimeout(timeoutId);
  }, [lmiConnectedFlipper]);

  return (
    <div className="container-fluid text-center precheck-card-content" id={id}>
      <PrechecksHeaderGroup
        title={polyglot.t(`prechecks_lmi_instructions_title`)}
        image={connectingImg}
        icon={lmiConnectedFlipper ? '' : icon}
        iconPrefix={lmiConnectedFlipper ? '' : iconPrefix}
      />

      <DownloadInstructions
        precheckData={precheckData}
        lmiConnectedFlipper={lmiConnectedFlipper}
        platform={platform}
        downloadLMI={handleDownloadLmi}
      />

      {!integratedLmiChat && (
        <PrechecksLmiDownloadForm
          sendEvent={sendEvent}
          precheckData={precheckData}
          ref={ref}
        />
      )}

      {lmiConnectedFlipper ? (
        <div className="pt-2">
          <div className="spinner-border precheck-primary">
            <div className="sr-only" role="alert" aria-live="assertive">
              {loadingText}
            </div>
          </div>
          <div className="precheck-gray secondary-text">
            {polyglot.t('prechecks_lmi_instructions_spinner_text')}
          </div>
        </div>
      ) : (
        <PrechecksFooterButton
          buttonText={polyglot.t('prechecks_lmi_instructions_next_button')}
          handleClick={handleNext}
        />
      )}
    </div>
  );
}
