import "bootstrap-slider";

class Settings {
  constructor() {
    this.COMPONENT_SELECTOR = '[data-behavior=settings-form]';
    this.lmiAuthCodeCheckbox = $('.js-lmi-auth-code-checkbox');
  }

  init() {
    this.initializeSliders();
    this.activateRadioButtons();
    this.initializeLmiAuthCodeEditing();
    this.initBaselines();
  }

  initializeSliders() {
    $('[data-behavior=slider]').each((_index, element) => {
      const value = parseInt($(element).val());
      $(element).slider({tooltip: 'always'});
      $(element).slider('setValue', value);
    });

    ['illumination', 'audio'].forEach(eventType => {
      ['true', 'false'].forEach(tf => {
        var enableOrDisable = tf === 'true' ? 'enable' : 'disable';
        var addOrRemoveClass = tf === 'true' ? 'removeClass' : 'addClass';
        $(document).on('click', `#use_manual_${eventType}_${tf}`, () => {
          $(`#calculated-${eventType}`)[addOrRemoveClass](
            'calculated-value-bold'
          );
          $(`#${eventType}-slider`).slider({tooltip: 'always'});
          $(`#${eventType}-slider`).slider(enableOrDisable);
        });
      });
    });
  }
  // Add active state to radio button groups.
  activateRadioButtons() {
    $(':input:checked')
      .parent('.btn')
      .addClass('active');
  }

  // Add functionality when editing LMI auth code.
  initializeLmiAuthCodeEditing() {
    this.lmiAuthCodeCheckbox.on('change', e => {
      const $this = $(e.currentTarget);
      const $checkboxElement = $this.closest('.checkbox');
      const $formGroupElement = $checkboxElement.prev();

      if ($this.prop('checked')) {
        $formGroupElement
          .addClass('bg-danger disabled')
          .find('.form-control')
          .prop('disabled', 'disabled');
        $checkboxElement.next().val('Remove code');
      } else {
        $formGroupElement
          .removeClass('bg-danger disabled')
          .find('.form-control')
          .prop('disabled', false);
        $checkboxElement.next().val('Update code');
      }
    });
  }

  initBaselines() {
    $('.js-baseline').each(function() {
      let $baseline = $(this);
      if ($baseline.hasClass('calculated')) {
        $baseline.find('.calculated-value').addClass('calculated-value-bold');
        $baseline.find('[data-behavior=slider]').slider('disable');
        $baseline.find(':radio')[0].checked = false;
        $baseline.find(':radio')[1].checked = true;
      }
    });
  }
}

export default Settings;
