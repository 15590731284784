import Toast from '../../utils/Toast';

class InvitationBuilder {
  constructor() {
    this.COMPONENT_SELECTOR = '#single-invitation-template';
    this.$modal = $(this.COMPONENT_SELECTOR);
    this.$modalBody = this.$modal.find('#js-modal-body');
    this.$modalTogglerEl = $('#show-invitation-modal');
    this.examName = this.$modalTogglerEl.data('exam-name');
    this.$form = this.$modal.find('[data-behavior=invitation-form]');
    this.formUrl = this.$modalTogglerEl.data('form-url');
  }

  init() {
    this.bindEventListeners();
  }

  bindEventListeners() {
    this.$modalTogglerEl.on('click', (e) => {
      this.openModal(e);
    });

    this.$modal.on('show.bs.modal', () => {
      this.setForm;
      this.setSelectS2;
    });

    this.$modal.find('form').on('submit', (e) => {
      this.submitInvitation(e);
    });

    this.$modal.on('hidden.bs.modal', () => {
      this.destroySelectS2;
    });
  }

  openModal(e) {
    e.preventDefault();
    this.$modal.modal('show');
  }

  closeModal() {
    this.$modal.modal('hide');
  }

  setForm() {
    this.$modal.find('.cancel-exam-name').html(this.examName);
  }

  resetForm() {
    this.$form[0].reset();
  }

  setSelectS2() {
    const currentPath = window.location.href;
    const splitPath = currentPath.replace(/^https?:\/\//, '').split('/');
    const institutionId = splitPath[2];

    this.$modal.find('input#user_id').select2({
      ajax: {
        url: `/institutions/${institutionId}/users/user_search`,
        dataType: 'json',
        data: (user) => {
          return {
            search: user,
            page_limit: 10
          };
        },
        results: (data) => {
          return {
            results: data.users
          };
        }
      },
      allowClear: true
    });
  }

  destroySelectS2() {
    this.$modal.find('input#user_id').select2('destroy');
  }

  submitInvitation(e) {
    e.preventDefault();

    $(e.target).find('input').prop('disabled', true)

    const formData = {
      invitation_reservation: {
        email: this.$modal.find('input[name=email]').val(),
        user_id: this.$modal.find('input[name=user_id]').val()
      }
    }

    $.ajax({
      type: 'POST',
      url: this.formUrl,
      data: JSON.stringify(formData),
      dataType: 'json',
      contentType: 'application/json',
      success: this.handleSuccess.bind(this),
      error: this.handleError.bind(this)
    });

    $(e.target).find('input').prop('disabled', false)
  }

  handleSuccess(response) {
    this.closeModal();
    this.resetForm();
    new Toast().success({ message: 'Your invitation has been sent.' });
  }

  handleError(response) {
    const errors = response.responseJSON.errors;
    new Toast().danger({ message: errors.invitation });
  }
}

export default InvitationBuilder;
