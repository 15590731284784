class DismissableAlerts {
  constructor() {
    this.alertWideCloseSelector = '[data-behavior=close-alert-wide]';
  }

  init() {
    this.bindEventListeners();
  }

  bindEventListeners() {
    // Allow closing of wide alerts.
    $(document).on('click', this.alertWideCloseSelector, e => {
      e.preventDefault();
      const $this = $(e.currentTarget);
      $this.closest('.alert').remove();
    });
  }
}

export default DismissableAlerts;
