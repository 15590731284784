import PropTypes from 'prop-types';
import isInstitution from '../../../src/incident_report_center/utils/isInstitution'
import Select2Institution from '../../Select2/Select2Institution';

class ReviewQueueFilter extends React.Component{
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.initializeDatePicker();
    $('#select2-institution').on('change', (event) => {
      this.props.updateInstitutionId(event.target.value);
    });
  }

  initializeDatePicker() {
    if ($('.report-date-picker').length > 1) {
      $('.report-date-picker').datetimepicker({
        format: 'MM/DD/YYYY',
        ignoreReadonly: true,
        icons: {
          next: 'fa fa-arrow-right',
          previous: 'fa fa-arrow-left'
        }
      });
    }
  }

  institutionFilters() {
    if (!isInstitution()) {
      return (
        <Select2Institution />
      )
    }
  }

  examFilter() {
    return (
      <div className='form-group'>
        <label htmlFor='exam'>{ polyglot.t('exam') }</label>
        <input className='form-control' id='exam' type='text'
          placeholder={ polyglot.t('exam') }
          value={ this.props.filterParams.exam }
          onChange={ this.props.updateFilterState } />
      </div>
    )
  }

  filterButtons() {
    return (
      <div className={`form-group mb-0`}>
        <span className='float-right'>
          <button className='btn btn-secondary mr-2' id='reset'
            onClick={ this.props.submitFilterForm }>
            { polyglot.t('reset') }
          </button>
          <button className='btn btn-primary' id='submit'
            onClick={ this.props.submitFilterForm }>
            { polyglot.t('search') }
          </button>
        </span>
      </div>
    );
  }

  startDateFiler() {
    return (
      <div className='form-group'>
        <label htmlFor='startDate'>{ polyglot.t('start_date') }</label>
        <input readOnly className='form-control report-date-picker'
          id='startDate' type='text' value={ this.props.filterParams.startDate }
          placeholder={ polyglot.t('date_text') }
          onBlur={ this.props.updateFilterState } />
      </div>
    )
  }

  endDateFiler() {
    return (
      <div className='form-group'>
        <label htmlFor='endDate'>{ polyglot.t('end_date') }</label>
        <input readOnly className='form-control report-date-picker'
          id='endDate' type='text' value={ this.props.filterParams.endDate }
          placeholder={ polyglot.t('date_text') }
          onBlur={ this.props.updateFilterState } />
      </div>
    )
  }

  testTakerFilter() {
    return (
      <div className='form-group'>
        <label htmlFor='testTaker'>Name</label>
        <input className='form-control' id='testTaker' type='text'
          placeholder={ polyglot.t('test_taker_name_only_placeholder') }
          value={ this.props.filterParams.testTaker }
          onChange={ this.props.updateFilterState } />
      </div>
    )
  }

  render() {
    return (
      <div id='filter-content'>
        <div className='card-body'>
          <div className='row'>
            <div className='col-6 col-sm-4'>
              { this.testTakerFilter() }
            </div>
            <div className='col-6 col-sm-4'>
              { this.institutionFilters() }
            </div>
            <div className='col-6 col-sm-4'>
              { this.examFilter() }
            </div>
          </div>
          <div className='row'>
            <div className='col-6 col-sm-4'>
              { this.startDateFiler() }
            </div>
            <div className='col-6 col-sm-4'>
              { this.endDateFiler() }
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 float-right">
              { this.filterButtons() }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ReviewQueueFilter.propTypes = {
    submitFilterForm: PropTypes.func.isRequired,
    updateFilterState: PropTypes.func.isRequired,
    updateInstitutionId: PropTypes.func.isRequired,
    filterParams: PropTypes.object.isRequired
  }

export default ReviewQueueFilter;
