import PropTypes from 'prop-types'

/**
 * Component for Incident Creation Modal.
 */

class IncidentModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isFulfillmentCancelled: false
    }
  }

  inverseFulfillmentState = () => {
    this.setState({
      isFulfillmentCancelled: !this.state.isFulfillmentCancelled
    })
  }

  flash = () => {
    return (
      <div className="alert alert-danger alert-form m-2" role="alert">
        {this.props.modalErrors}
      </div>
    )
  }

  stopSessionCheckbox = () => {
    if (this.props.isStopSessionCheckboxVisible() == true) {
      return (
        <div className="checkbox pln mt-2 col-sm-5">
          <label>
            <input
              type="checkbox"
              id="stop_session"
              onChange={this.inverseFulfillmentState}
            />
            <strong>Stop session</strong>
          </label>
        </div>
      )
    }
  }

  render() {
    let errorEl

    if (this.props.modalError) {
      errorEl = this.flash()
    }

    return (
      <div className="modal modal-incident fade" tabIndex="-1" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Create Incident</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form name="notepadModalForm">
              <div className="modal-body modal-body-form p-0">
                {errorEl}
                <ul className="list-unstyled mb-0">
                  <li className="js-focus-comment pt-3 pb-3">
                    <div className="form-group row m-0">
                      <label
                        htmlFor="comment"
                        className="col-sm-3 col-form-label"
                      >
                        Comment
                      </label>
                      <div className="col-sm-9">
                        <textarea
                          className="form-control"
                          id="comment"
                          value={this.props.comment}
                          onChange={this.props.updateComment}
                        />
                      </div>
                    </div>
                  </li>
                  <li className="js-focus-urgency pt-3 pb-3">
                    <div className="form-group row m-0">
                      <label
                        htmlFor="incident_subtype"
                        className="col-sm-3 col-form-label"
                      >
                        Type
                      </label>
                      <div className="col-sm-9">
                        <select
                          className="form-control"
                          id="incident_subtype"
                          onChange={this.props.updateIncidentSubtype}
                          required
                          defaultValue=""
                        >
                          <option value="" disabled>
                            Select an incident type
                          </option>
                          {this.props.incidentSubtypes.map(function(
                            incident_subtype
                          ) {
                            return (
                              <option
                                key={incident_subtype.id}
                                value={incident_subtype.id}
                              >
                                {incident_subtype.status}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                    </div>
                  </li>
                  <li className="pt-3 pb-3">
                    <div className="form-group row m-0">
                      <label
                        htmlFor="chat_log"
                        className="col-sm-3 col-form-label"
                      >
                        Chat Log
                      </label>
                      <div className="col-sm-9">
                        <textarea
                          className="form-control"
                          id="chat_log"
                          onChange={this.props.updateChatLog}
                        />
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="modal-footer">
                {this.stopSessionCheckbox()}
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={(event) => this.props.saveIncident(this.state.isFulfillmentCancelled, event)}
                >
                  Save changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

IncidentModal.propTypes = {
  incidentSubtypes: PropTypes.array.isRequired,
  comment: PropTypes.string,
  updateComment: PropTypes.func.isRequired,
  updateIncidentSubtype: PropTypes.func.isRequired,
  saveIncident: PropTypes.func.isRequired,
  updateChatLog: PropTypes.func.isRequired,
  modalError: PropTypes.bool.isRequired,
  modalErrors: PropTypes.string,
  isStopSessionCheckboxVisible: PropTypes.func.isRequired
}

export default IncidentModal
