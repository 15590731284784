import Callbacks from '../../utils/Callbacks.js';
import consumer from '../consumer'

/**
 * This will be included in the VideoPlayer.js file for updates on when an exam starts.
 */

class ExamStartNotification {
  constructor(fulfillmentUuid) {
    this.identifier = { channel: 'FulfillmentChannel', uuid: fulfillmentUuid };
    this.subscribe = this.subscribe.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleUnmount = this.handleUnmount.bind(this);
    this.callbacks = new Callbacks();
  }

  init(examStart, audioChange) {
    this.examStart = examStart;
    this.audioChange = audioChange;
    this.subscribe();
  }

  subscribe() {
    this.callbacks.on(this.handleUnmount);
    this.examNotifications = consumer.subscriptions.create(
      this.identifier, { received: data => this.handleChange(data) }
    );
  }

  handleChange(data) {
    switch (data.subtype) {
      case 'start':
        this.examStart(data);
        break;
      case 'audio_level_is_high':
        this.audioChange(data);
        break;
      default:
        break;
    }
  }

  handleUnmount(data) {
    if (data.type === 'unmountExamStartNotification' && this.examNotifications) {
      this.examNotifications.unsubscribe();
    }
  }
}

export default ExamStartNotification;
