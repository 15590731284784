class Select2Institution extends React.Component {
  constructor(props) {
    super(props);
    this.$selector = '#select2-institution';
  }

  getInstitutionValue() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('institution') ?? 0;
  }

  getInstitutionStatusFilter() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('status') ?? 'active';
  }

  componentDidMount() {
    let status_filter = this.getInstitutionStatusFilter();
    $(this.$selector).select2({
      placeholder: 'Find by Name',
      theme: 'bootstrap4',
      minimumInputLength: 2,
      width: 'auto',
      ajax: {
        url: '/institutions',
        dataType: 'json',
        delay: 500,
        data: function (params) {
          return { search: params.term, status: status_filter };
        },
        processResults: function (data) {
          return { results: data.institutions };
        },
      },
      allowClear: true,
    });
    this.setInitialSelectionForSelect2();
  }

  setInitialSelectionForSelect2() {
    if (
      this.getInstitutionValue().length < 1 ||
      this.getInstitutionValue() == 0
    ) {
      return;
    }
    $.ajax({
      url: '/institutions',
      data: {
        id: this.getInstitutionValue(),
      },
      dataType: 'json',
    }).then((data) => {
      for (var d = 0; d < data.institutions.length; d++) {
        var institution = data.institutions[d];
        var option = new Option(institution.text, institution.id, true, true);
        $(this.$selector).append(option);
      }
      $(this.$selector).val(this.getInstitutionValue()).trigger('change');
    });
  }

  render() {
    return (
      <div>
        <label htmlFor="institution">Institution</label>
        <select
          name="institution"
          id="select2-institution"
          placeholder="Find by Name"
          className="form-control"
        ></select>
      </div>
    );
  }
}

export default Select2Institution;
