import PropTypes from 'prop-types';

const SystemLoadEvent = ({ createdAt, text }) => (
  <div className="row align-items-center">
    <div className="col-11 offset-1">
      <small className="text-muted">{createdAt}</small>
      <div>
        {text}
      </div>
    </div>
  </div>
)

SystemLoadEvent.propTypes = {
  createdAt: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
}

export default SystemLoadEvent
