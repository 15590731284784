import { useLayoutEffect, useState } from 'react';

/**
 * https://usehooks.com/useorientation
 * @returns {SecondCameraScreenOrientation}
 */
export default function useOrientation() {
  const [orientation, setOrientation] = useState(
    /** @type {SecondCameraScreenOrientation} */
    {
      angle: 0,
      type: 'landscape-primary',
    },
  );

  useLayoutEffect(() => {
    const handleChange = () => {
      const { angle, type } = window.screen.orientation;
      setOrientation({
        angle,
        type,
      });
    };

    const handle_orientationchange = () => {
      setOrientation({
        angle: window.orientation,
        type: 'unknown',
      });
    };

    if (window.screen?.orientation) {
      handleChange();
      window.screen.orientation.addEventListener('change', handleChange);
    } else {
      handle_orientationchange();
      window.addEventListener('orientationchange', handle_orientationchange);
    }

    return () => {
      if (window.screen?.orientation) {
        window.screen.orientation.removeEventListener('change', handleChange);
      } else {
        window.removeEventListener(
          'orientationchange',
          handle_orientationchange,
        );
      }
    };
  }, []);

  return orientation;
}
