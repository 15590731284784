import React, { useState } from 'react';

import { MacDownloadModal } from '../../../Exam/DownloadGuardianBrowser';

/** @typedef {import('../../../../types').GuardianPlatform} GuardianPlatform */

/**
 * @typedef ActionLinkAttributes
 * @property {string} href
 * @property {React.MouseEventHandler} [onClick]
 */

/**
 * @param {Object} props
 * @param {GuardianPlatform} props.guardianBrowserPlatform
 * @param {GuardianDownloadUrls} props.guardianBrowserDownloadUrls
 * @param {(value: boolean) => void} props.setShowGuardianMacDownload
 * @param {boolean} props.isGuardianExtensionEnabledForAny
 * @param {string} props.guardianExtensionUrl
 * @returns {React.ReactElement}
 */
const DownloadButton = (props) => {
  /** @returns {ActionLinkAttributes} */
  const getActionLinkAttributes = () => {
    if (props.isGuardianExtensionEnabledForAny) {
      return {
        href: props.guardianExtensionUrl,
      };
    }

    switch (props.guardianBrowserPlatform) {
      case 'WINDOWS':
        return {
          href: props.guardianBrowserDownloadUrls.windows,
        };
      case 'MAC':
        return {
          href: '#',
          onClick: (event) => {
            event.preventDefault();
            props.setShowGuardianMacDownload(true);
          },
        };
    }
  };

  const attributes = getActionLinkAttributes();

  if (!attributes) {
    return <></>;
  }

  return (
    <a
      className="btn btn-secondary action-btn"
      href={attributes.href}
      target="_blank"
      role="button"
      onClick={attributes.onClick}
    >
      <i className="fa fa-download action-btn-icon"></i>
      {polyglot.t('my_sessions_v2_action_links_download_guardian')}
    </a>
  );
};

/**
 * @typedef ActionLinksProps
 * @property {GuardianPlatform} guardianBrowserPlatform
 * @property {string} faqUrl
 * @property {string} testItOutUrl
 * @property {GuardianDownloadUrls} guardianBrowserDownloadUrls
 * @property {boolean} isGuardianExtensionEnabledForAny
 * @property {string} guardianExtensionUrl
 */

/**
 * @param {ActionLinksProps} props
 * @returns {React.ReactElement}
 */
export const ActionLinks = (props) => {
  const [showGuardianMacDownload, setShowGuardianMacDownload] = useState(false);

  return (
    <div className="action-links">
      <p className="helper-text">
        {polyglot.t('my_sessions_v2_action_links_get_started_helper')}
      </p>
      <a
        className="btn btn-secondary action-btn"
        href={props.faqUrl}
        target="_blank"
      >
        <i className="fa fa-arrow-up-right-from-square action-btn-icon"></i>
        {polyglot.t('my_sessions_v2_action_links_guides_support')}
      </a>
      {(props.guardianBrowserPlatform === 'MAC' ||
        props.guardianBrowserPlatform === 'WINDOWS') && (
        <div className="d-none d-lg-inline">
          <a
            className="btn btn-secondary action-btn"
            href={props.testItOutUrl}
            target="_blank"
          >
            <i className="fa fa-laptop action-btn-icon"></i>
            {polyglot.t('my_sessions_v2_action_links_test_your_equipment')}
          </a>
          <DownloadButton
            guardianBrowserDownloadUrls={props.guardianBrowserDownloadUrls}
            guardianBrowserPlatform={props.guardianBrowserPlatform}
            setShowGuardianMacDownload={setShowGuardianMacDownload}
            guardianExtensionUrl={props.guardianExtensionUrl}
            isGuardianExtensionEnabledForAny={
              props.isGuardianExtensionEnabledForAny
            }
          />
        </div>
      )}

      <MacDownloadModal
        guardianBrowserDownloadUrls={props.guardianBrowserDownloadUrls}
        showGuardianMacDownload={showGuardianMacDownload}
        setShowGuardianMacDownload={setShowGuardianMacDownload}
      />
    </div>
  );
};
