import { OpenVidu } from 'openvidu-browser'

class CameraFeed {
  constructor() {
    this.OV = null;
    this.session = null;
    this.publisherCamera = null;
    this.cameraSelect = document.querySelector(
      '[data-behavior="camera-name"]'
    );
    this.webrtcStatsElement = document.querySelector(
      '[data-behavior="webrtc-stats"]'
    );
    this.handleCameraSelectChange = this.handleCameraSelectChange.bind(this);
  }

  init() {
    this.bindEventListeners();
    window.onbeforeunload = function () {
      if (this.session) this.session.disconnect();
    };
    return this.joinSession();
  }

  joinSession() {
    this.self = this;
    this.OV = new OpenVidu();
    this.session = this.OV.initSession();

    return this.getToken().then(token => {
      if (token === null) {
        return;
      }

      this.session.connect(token)
        .then(() => {
          let options = {
            resolution: '320x240',
            frameRate: 30,
            insertMode: 'REPLACE'
          };

          this.publisherCamera = this.OV.initPublisher(
            "publisherCamera",
            options,
            () => {}
          );

          // Publish stream
          this.session.publish(this.publisherCamera);
        })
        .then (() => {
          // display stream element
          document.querySelector(
            '[data-behavior="camera-feed"]'
          ).style.display = 'block'
        })
        .catch(() => {});
    }).catch(() => {});
  }

  leaveSession() {
    this.session.disconnect();
  }

  gatherStats() {
    return new Promise((resolve, reject) => {
      let streamManager = this.session.streamManagers[0];
      // race condition in which we try to gather stats before the
      // stream is fully published. even when chaining this after the init()
      // method, this condition is still possible. we still want to continue
      // with the page functionality though, so resolve successfully in this case
      if (!streamManager) {
        return resolve(true);
      }
      // getStats(null) gathers stats from all time for this RTCPeerConnection
      streamManager.stream.getRTCPeerConnection().getStats(null).then(payload => {
        const jsonPayload = {};
        for(let metric of payload.values()) {
          jsonPayload[metric.id] = metric;
        }

        this.webrtcStatsElement.value = JSON.stringify(jsonPayload);
        resolve(true);
      }).catch(() => {});
    }).catch(() => {});
  }

  getToken() {
    return new Promise((resolve, reject) => {
        $.ajax({
            type: 'GET',
            url: '/users/system-metrics/openvidu/token',
            success: response => resolve(response.token),
            error: (error) => {
              console.log(error)
              resolve(null)
            }
        });
    });
  }

  bindEventListeners() {
    if (this.cameraSelect) {
      this.cameraSelect.addEventListener(
        'change',
        this.handleCameraSelectChange
      );
    }
  }

  handleCameraSelectChange() {
    if (!this.cameraSelect) {
      return;
    }
    const select = this.cameraSelect;
    const selectedCamera = select.options[select.selectedIndex];
    this.toggleCamera(selectedCamera.value);
  }

  toggleCamera(deviceLabel) {
    if (!deviceLabel) { return };
    this.OV.getDevices().then(devices => {
      // Getting only the video devices
      let videoDevices = devices.filter(device => device.kind === 'videoinput');

      if (videoDevices && videoDevices.length > 1){
        videoDevices.map((device) => {
          if (device.label == deviceLabel) {
            // unpublish
            this.session.unpublish(this.publisherCamera);

            let options = {
              videoSource: device.deviceId,
              resolution: '320x240',
              frameRate: 30,
              insertMode: 'REPLACE'
            };

            // setup new publisher
            this.publisherCamera = this.OV.initPublisher(
              "publisherCamera",
              options,
              () => {}
            );

            // publish publisher
            this.session.publish(this.publisherCamera);
          }
        });
      }
    });
  }
}

export default CameraFeed;
