import { useEffect, useState } from 'react';
import axios from 'axios';

import csrfToken from '../../src/utils/csrf';
import PrechecksHeaderGroup from './CardSections/PrechecksHeaderGroup';
import PrechecksBodyText from './CardSections/PrechecksBodyText';
import PrechecksFooterButton from './CardSections/PrechecksFooterButton';
import { PrechecksVideoPlayer } from './PrechecksVideoPlayer';

const ProctorConnectionStatus = ({ proctorConnected }) => {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="d-flex flex-row justify-content-end">
          <div>
            <i
              className={`far ${
                proctorConnected
                  ? 'fa-check-circle precheck-dark'
                  : 'fa-circle-notch fa-spin precheck-dark'
              } mr-2`}
            ></i>
            <span className="precheck-dark fs-6">
              {polyglot.t(
                proctorConnected
                  ? 'prechecks_examlobby_liveplus_connection_status_connected_to_proctor'
                  : 'prechecks_examlobby_liveplus_connection_status_connecting_to_proctor',
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const AverageWaitTime = ({ precheckData, extendedWait }) => {
  return (
    <p className="mb-4">
      {polyglot.t('prechecks_examlobby_liveplus_average_wait_time_text')}
      <span
        className="precheck-dark rounded px-1"
        style={{
          backgroundColor: 'rgba(50, 124, 122, 0.1)',
          fontWeight: '500',
        }}
      >
        {extendedWait
          ? precheckData.extendedWaitTime
          : precheckData.averageWaitTime}
        {polyglot.t('prechecks_examlobby_liveplus_average_wait_time_minutes')}
      </span>
    </p>
  );
};

const LmiConnectInstructions = ({ precheckData, isMac = true }) => {
  return (
    <PrechecksVideoPlayer
      idPrefix="lmi-connect-instruction"
      videoInfo={
        isMac
          ? precheckData.videos.lmiConnectInstructionMacMp4
          : precheckData.videos.lmiConnectInstructionWindowsMp4
      }
    />
  );
};

const ExamLobbyCard = ({
  id,
  icon,
  iconPrefix,
  title,
  bodyText,
  extensionBodyText,
  extensionBodyText2,
  buttonText,
  precheckData,
  sendEvent,
  broadcastMessage,
  proctorConnected,
  handleBeginExam,
  lmiConnected,
}) => {
  const [extendedWait, setExtendedWait] = useState(false);
  const deskImg = precheckData.images.deskImg;
  const supportVideo = precheckData.videos.extensionSupportMp4;
  const isExtension = precheckData.extensionOverride;
  const isRecordPlus = !precheckData.livePlus;
  const isMac = navigator.userAgent.includes('Mac');
  const isIntegratedLmiChat = precheckData.integratedLmiChat;
  const isEnableNativeChatForLiveProctoring =
    precheckData.enableNativeChatForLiveProctoring;
  const proctoruIcon = precheckData.images.proctoruIcon;
  const imageTag = `<img width="2%" src="${proctoruIcon?.src}" alt="${proctoruIcon?.altText}"/>`;
  const updatedBodyText = bodyText.replace('__image_tag__', imageTag);
  const showBeginExamButton =
    isRecordPlus ||
    (precheckData.guidedLaunch && !precheckData.removeBeginExam);
  const supportText = isExtension
    ? polyglot.t('prechecks_examlobby_auto_extension_text')
    : polyglot.t('prechecks_examlobby_auto_browser_text');

  const getTitle = () => {
    if (extendedWait) {
      return polyglot.t('prechecks_examlobby_liveplus_title_extended_wait');
    }
    if (showBeginExamButton) {
      return polyglot.t('prechecks_examlobby_title');
    }
    return title;
  };

  const handleBeginExamClick = () => {
    sendEvent('Event::BeginExam');
    handleBeginExam();
  };

  useEffect(() => {
    if (isIntegratedLmiChat) {
      broadcastMessage({
        sender: 'archimedes',
        messageType: 'open_integrated_lmi_chat',
        data: {},
      });
    }

    if (isEnableNativeChatForLiveProctoring) {
      broadcastMessage({
        sender: 'archimedes',
        messageType: 'open_native_chat',
        data: {},
      });
    }
  }, []);

  useEffect(() => {
    if (precheckData.guidedLaunch) {
      if (precheckData.removeBeginExam) {
        sendEvent('Event::GuidedLaunchBeginExamLoaded');
      } else {
        sendEvent('Event::GuidedLaunchWaitingScreenLoaded');
      }
    }
  }, []);

  useEffect(() => {
    // TODO: consider pulling all API calls out into a separate API file that we can type with an interface
    axios
      .post(
        `${window.location.origin}/api/secure_browser/events/session_awaiting_verification`,
        {
          uuid: precheckData.fulfillment.uuid,
          event: { created_by_id: precheckData.user.id },
        },
        {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken(),
        },
      )
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Check if waiting time is longer than expected
  useEffect(() => {
    if (showBeginExamButton) return;
    const averageWaitTime = precheckData.averageWaitTime;
    if (!averageWaitTime) return;
    const avgWaitTimeMs = averageWaitTime * 60 * 1000;
    const id = setTimeout(() => {
      setExtendedWait(true);
    }, avgWaitTimeMs);
    return () => clearTimeout(id);
  }, [precheckData.averageWaitTime]);

  return (
    <div className="container-fluid text-center" id={id}>
      {!showBeginExamButton && lmiConnected && (
        <ProctorConnectionStatus proctorConnected={proctorConnected} />
      )}

      <div className="precheck-card-content">
        <PrechecksHeaderGroup
          title={getTitle()}
          image={deskImg}
          icon={extendedWait ? 'fa-exclamation-circle' : icon}
          iconPrefix={iconPrefix}
        />

        {!showBeginExamButton && (
          <AverageWaitTime
            precheckData={precheckData}
            extendedWait={extendedWait}
          />
        )}

        {isRecordPlus ? (
          <>
            <PrechecksBodyText bodyText={supportText} />
            {isExtension && (
              <div className="mb-4">
                <PrechecksVideoPlayer
                  idPrefix={'support-video'}
                  videoInfo={supportVideo}
                />
              </div>
            )}
          </>
        ) : (
          <div className="text-left">
            {isExtension ? (
              <>
                <PrechecksBodyText bodyText={extensionBodyText} />
                <PrechecksBodyText bodyText={extensionBodyText2} />
                <ul className="list-unstyled pl-2">
                  {!showBeginExamButton && (
                    <li className="mb-2">
                      <i
                        className={`far fa-check-circle precheck-dark fa-1x mr-2`}
                        aria-hidden="true"
                      />
                      {polyglot.t(
                        'prechecks_examlobby_liveplus_extension_item1',
                      )}
                    </li>
                  )}
                  <li className="mb-2">
                    <i
                      className={`far fa-check-circle precheck-dark fa-1x mr-2`}
                      aria-hidden="true"
                    />
                    {polyglot.t('prechecks_examlobby_liveplus_extension_item2')}
                  </li>
                  <li className="mb-2">
                    <i
                      className={`far fa-check-circle precheck-dark fa-1x mr-2`}
                      aria-hidden="true"
                    />
                    {polyglot.t('prechecks_examlobby_liveplus_extension_item3')}
                  </li>
                </ul>
              </>
            ) : (
              <>
                <PrechecksBodyText
                  bodyText={
                    isIntegratedLmiChat
                      ? polyglot.t(
                          'prechecks_examlobby_liveplus_browser_integrated_chat_text',
                        )
                      : updatedBodyText
                  }
                />
                <ul className="list-unstyled pl-2">
                  {!showBeginExamButton && (
                    <li className="mb-2">
                      <i
                        className={`far fa-check-circle precheck-dark fa-1x mr-2`}
                        aria-hidden="true"
                      />
                      {isIntegratedLmiChat
                        ? polyglot.t(
                            'prechecks_examlobby_liveplus_browser_integrated_chat_item1',
                          )
                        : polyglot.t(
                            'prechecks_examlobby_liveplus_browser_item1',
                          )}
                    </li>
                  )}
                  <li className="mb-2">
                    <i
                      className={`far fa-check-circle precheck-dark fa-1x mr-2`}
                      aria-hidden="true"
                    />
                    {polyglot.t('prechecks_examlobby_liveplus_browser_item2')}
                  </li>
                  <li className="mb-2">
                    <i
                      className={`far fa-check-circle precheck-dark fa-1x mr-2`}
                      aria-hidden="true"
                    />
                    {polyglot.t('prechecks_examlobby_liveplus_browser_item3')}
                  </li>
                </ul>
                {!isIntegratedLmiChat && (
                  <>
                    <div className="text-center">
                      <LmiConnectInstructions
                        precheckData={precheckData}
                        isMac={isMac}
                      />
                    </div>
                    <br /> {}
                    <PrechecksBodyText
                      bodyText={polyglot.t(
                        'prechecks_examlobby_liveplus_text2',
                      )}
                    />
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>

      {showBeginExamButton && (
        <PrechecksFooterButton
          buttonText={buttonText}
          handleClick={handleBeginExamClick}
        />
      )}
    </div>
  );
};

ExamLobbyCard.defaultProps = {
  icon: 'fa-check-circle',
  statusColor: 'primary',
  iconPrefix: 'fal',
  title: polyglot.t('prechecks_examlobby_liveplus_title_normal_wait'),
  bodyText: polyglot.t('prechecks_examlobby_liveplus_text'),
  extensionBodyText: polyglot.t('prechecks_examlobby_liveplus_extension_text'),
  extensionBodyText2: polyglot.t(
    'prechecks_examlobby_liveplus_extension_text2',
  ),
  buttonText: polyglot.t('prechecks_examlobby_button'),
  proctorConnected: false,
};

export default ExamLobbyCard;
