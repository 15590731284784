export function getDeviceFriendlyName(deviceLabel) {
  let regexPatterns = [
    ['Ultrawide', /.*ultra\s*wide.*/gi],
    ['Dualwide', /.*dual\s*wide.*/gi],
    ['Front', /.*front.*/gi],
    ['Back', /.*back.*/gi],
    ['Webcam', /.*webcam.*/gi],
  ];
  for (let i = 0; i < regexPatterns.length; i++) {
    const [label, regex] = regexPatterns[i];
    if (regex.test(deviceLabel)) {
      return label;
    }
  }

  return deviceLabel;
}

export function getPrioritizedDeviceList(deviceList) {
  let frontCameras = [];
  let backCameras = [];
  let otherCameras = [];
  let frontRegex = /.*front.*/gi;
  let backRegexes = [/.*ultra\s*wide.*/gi, /.*dual\s*wide.*/gi, /.*back.*/gi];

  let backCamCounter = 1;

  for (let i = 0; i < deviceList.length; i++) {
    if (/.*desk\s*view.*/gi.test(deviceList[i].label)) {
      continue; // ignore desk view camera
    } else if (frontRegex.test(deviceList[i].label)) {
      frontCameras.push({
        device: deviceList[i],
        label: getDeviceFriendlyName(deviceList[i].label),
      });
    } else {
      let isBackCamera = false;
      for (let j = 0; j < backRegexes.length; j++) {
        if (backRegexes[j].test(deviceList[i].label)) {
          let backCameraLabel = getDeviceFriendlyName(deviceList[i].label);
          if (backCameraLabel === 'Back') {
            backCameraLabel = 'Back Cam ' + backCamCounter;
            backCamCounter++;
          }
          backCameras.push({
            device: deviceList[i],
            priority: j,
            label: backCameraLabel,
          });
          isBackCamera = true;
          break;
        }
      }
      if (!isBackCamera) {
        otherCameras.push({
          device: deviceList[i],
          label: getDeviceFriendlyName(deviceList[i].label),
        });
      }
    }
  }
  if (frontCameras.length > 0) {
    return frontCameras.toSorted((c1, c2) => c1.priority - c2.priority);
  } else {
    return [...backCameras, ...otherCameras];
  }
}
