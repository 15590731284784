class ExamWindows {
  constructor() {
    this.iterationTypeSelector =
      document.querySelector('[data-element="iteration-types"]') ||
      document.querySelector('[data-element="iteration-type-hidden"]');
    this.examWindowsNavLink = document.querySelector('[data-element="exam-windows-nav-link"]');
    this.examWindowsBlock = document.querySelector('[data-element="exam-windows-block"]');
  }

  init() {
    if (!this.iterationTypeSelector) { return; }
    this.bindEventListeners();
    this.handleWindows();
  }

  bindEventListeners() {
    this.iterationTypeSelector.addEventListener('change', () => { this.handleWindows() });
  }

  handleWindows() {
    const selectedType = this.iterationTypeSelector.value
    const automatedTypes = [ 'Iteration::AutomatedLiveLaunch' ];
    let iterationId= $('#iteration_exam_id').val();

    if((iterationId == undefined || iterationId == '') && selectedType == 'Iteration::Automatic') {
        $('.exam-window-picker').remove();
    }
    if (automatedTypes.indexOf(selectedType) > -1) {
      this.examWindowsBlock.classList.add('d-none');
      this.examWindowsNavLink.classList.add('d-none');
    } else {
      this.examWindowsBlock.classList.remove('d-none');
      this.examWindowsNavLink.classList.remove('d-none');
      if ($('.exam-window-picker').length === 0 && selectedType != 'Iteration::Automatic') {
        $('.js-add-datetimepicker').trigger('click');
      }
    }
  }
}

export default ExamWindows
