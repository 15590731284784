import { AccommodationNames } from '../../../constants/accommodations';

/**
 * @typedef WelcomeAccommodationsProps
 * @property {[Accommodation]} accommodations
 * @property {string} durationModifierAccommodation
 */
/**
 * @param {WelcomeAccommodationsProps} props
 * @returns {React.ReactElement}
 */
const WelcomeAccommodations = ({
  accommodations,
  durationModifierAccommodation,
}) => {
  /** @type {React.CSSProperties} */
  const gridStyles = {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, auto)',
    gridGap: '5rem',
    margin: 'auto',
    width: '75%',
  };

  const displayAccommodation = (accommodation) => {
    return accommodation.name === AccommodationNames.OTHER
      ? accommodation.note
      : accommodation.name;
  };

  return (
    <>
      {accommodations.length === 0 && !durationModifierAccommodation && (
        <p className="mt-2 ml-4 text-left">
          {polyglot.t('prechecks_welcome_accommodations_no_accommodations')}
        </p>
      )}

      {(accommodations.length > 0 || durationModifierAccommodation) && (
        <ul className="mt-2" style={gridStyles}>
          {accommodations.map((accommodation) => (
            <li key={accommodation.id}>
              {displayAccommodation(accommodation)}
            </li>
          ))}
          {durationModifierAccommodation && (
            <li key="accommodation_duration_modifier">
              {durationModifierAccommodation}
            </li>
          )}
        </ul>
      )}
    </>
  );
};

WelcomeAccommodations.propTypes = {
  accommodations: PropTypes.array.isRequired,
  durationModifierAccommodation: PropTypes.string,
};

WelcomeAccommodations.defaultProps = {
  accommodations: [],
  durationModifierAccommodation: null,
};

export default WelcomeAccommodations;
