import Toast from '../../src/utils/Toast';

class ConfirmClearNoShows {
  constructor() {
    this.confirmBtn = document.querySelector(
      '#confirm-clear-no-shows'
    );
  }

  init() {
    this.bindEventListeners();
  }

  bindEventListeners() {
    this.confirmBtn.addEventListener('click', this.hideRemoveNoShowsBtn);
  }

  hideRemoveNoShowsBtn() {
    document.querySelector('#launch-remove-no-shows-modal').style.display = "none";
    new Toast().success({message: "No show(s) successfully deleted."})
  }
}

export default ConfirmClearNoShows;
