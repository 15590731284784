/**
 * Handles the given test-taker resource category by checking whether the given
 * internationalized text is for "Scratch Paper". If so, then this function will
 * return the internationalized text for "Note Taking Surface" instead.
 * Otherwise, the original category is simply returned.
 * @see https://proctorujira.atlassian.net/browse/DEV-22525
 * @note If any new language is added under 'locales' directory, make sure to
 *   add the value for the `scratch_paper` key here, in order for this to work
 *   as expected.
 * @todo Figure out a better solution (and hopefully dissolve this function).
 * @param {string} category
 * @returns {string}
 */
export const handleScratchPaperTextTranslation = (category) => {
  switch (category) {
    // en.yml
    case 'scratch Paper':
    // es.yml
    case 'papel de borrador':
    // fr_ca.yml
    case 'papier brouillon':
    // de.yml
    case 'schmierpapier':
    // ja.yml
    case 'メモ用紙':
      return 'Note Taking Surface';
    default:
      return category;
  }
};
