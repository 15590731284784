class AppNavigation {
  constructor() {
    this.COMPONENT_SELECTOR = '[data-behavior=app-navigation]'
    this.$appNavigation = $(this.COMPONENT_SELECTOR);
    this.$appNavigationToggler =
      this.$appNavigation.find('[data-behavior=app-navigation-toggler]');
    this.$collapsible =
      this.$appNavigation.find('.app-navigation-list.collapse');
    this.$list = this.$appNavigation.find('.app-navigation-list');
    this.$listItems = this.$appNavigation.find('.app-navigation-list > li');
  }

  init() {
    this.listenForHover();
    this.initializeToggleable();
    this.toggleVisibility();
  }

  listenForHover() {
    this.$listItems.on('mouseover', (e) => {
      $(e.currentTarget).addClass('hover');
      this.$list.addClass('hover');
      this.$listItems.not($(e.currentTarget)).removeClass('hover');
    });

    this.$listItems.on('mouseout', (e) => {
      $(e.currentTarget).removeClass('hover');
      this.$list.removeClass('hover');
    });
  }

  initializeToggleable() {
    this.$appNavigationToggler.on('click', (e) => {
      e.preventDefault();
      const $icon = $(e.currentTarget).find('span.fa');

      this.$collapsible.toggleClass('in');
      if ($icon.hasClass('fa-angle-down')) {
        $icon.removeClass('fa-angle-down').addClass('fa-angle-up');
      } else {
        $icon.removeClass('fa-angle-up').addClass('fa-angle-down');
      }
    });
  }

  toggleVisibility() {
    let width = $(window).width();
    let isVisible = this.$collapsible.hasClass('is-visible');

    if (width < 992 && isVisible) {
      this.$collapsible.removeClass('is-visible');
    } else if (width > 991 && !isVisible) {
      this.$collapsible.addClass('is-visible');
    }

    $(window).on('resize', (e) => {
      let width = $(e.currentTarget).width();
      let isVisible = this.$collapsible.hasClass('is-visible');

      if (width < 992 && isVisible) {
        this.$collapsible.removeClass('is-visible');
      } else if (width > 991 && !isVisible) {
        this.$collapsible.addClass('is-visible');
      }
    });
  }
}

export default AppNavigation;
