class GuardianExtensionDownload {
  constructor() {
    this.pollingIntervalId = null;
    this.extensionSupportInfo = document.querySelector(
      '[data-element="extension-info"]',
    );
    this.pleaseUseChrome = document.getElementById('use-chrome-row');
    this.downloadInfo = document.getElementById('download-info-row');
    this.redirectingInfo = document.getElementById('redirecting-info-row');
  }

  init() {
    if (!this.extensionSupportInfo) {
      return;
    }

    const dataset = this.extensionSupportInfo.dataset;
    this.secureBrowserEnabled = dataset?.secureBrowserEnabled === 'true';
    this.guardianExtensionOverrideEnabled =
      dataset.guardianExtensionOverrideEnabled === 'true';
    this.redirectLink = dataset.redirectLink;
    this.isTestItOut = dataset?.isTestItOut === 'true';

    if (
      (this.isTestItOut || this.secureBrowserEnabled) &&
      this.guardianExtensionOverrideEnabled
    ) {
      this.pollForExtensionInBackground(500);
    }

    window.onbeforeunload = this.stopPolling.bind(this);
  }

  pollForExtensionInBackground(delay) {
    const isChrome = !!window.chrome;
    if (!isChrome) {
      this.pleaseUseChrome.classList.remove('d-none');
      return;
    }

    this.pollingIntervalId = setInterval(async () => {
      const isValid = await this.getRunningExtensionVersion();
      if (isValid) {
        this.startRedirect();
      } else {
        this.showDownloadInfo();
      }
    }, delay);
  }

  async getRunningExtensionVersion() {
    if (chrome.runtime === undefined) {
      return false;
    }
    const extensionId = this.extensionSupportInfo.getAttribute(
      'data-guardian-extension-id',
    );
    const message = { message: 'checkExtension' };
    return new Promise((resolve) => {
      chrome.runtime.sendMessage(extensionId, message, (response) => {
        resolve(response?.id === extensionId);
      });
    });
  }

  startRedirect() {
    clearInterval(this.pollingIntervalId);

    this.downloadInfo.classList.add('d-none');
    this.redirectingInfo.classList.remove('d-none');
    this.setDownloadMessages();
    window.location.href = this.redirectLink;
  }

  showDownloadInfo() {
    this.downloadInfo.classList.remove('d-none');
    this.redirectingInfo.classList.add('d-none');
    this.setDownloadMessages();
  }

  setDownloadMessages() {
    if (this.isTestItOut) {
      this.setTestItOutMessages();
    } else {
      this.setStartExamMessages();
    }
  }

  setTestItOutMessages() {
    // Test it out elements
    document
      .getElementById('download-guardian-extension-test-your-equipment')
      .classList.remove('d-none');
    document
      .getElementById('automatic-redirection-message-test-your-equipment')
      .classList.remove('d-none');
    document
      .getElementById('button-manual-redirect-test-your-equipment')
      .classList.remove('d-none');

    // Start exam elements
    document
      .getElementById('download-guardian-extension-start-exam')
      .classList.add('d-none');
    document
      .getElementById('automatic-redirection-message-start-exam')
      .classList.add('d-none');
    document
      .getElementById('button-manual-redirect-start-exam')
      .classList.add('d-none');
  }

  setStartExamMessages() {
    // Test it out elements
    document
      .getElementById('download-guardian-extension-test-your-equipment')
      .classList.add('d-none');
    document
      .getElementById('automatic-redirection-message-test-your-equipment')
      .classList.add('d-none');
    document
      .getElementById('button-manual-redirect-test-your-equipment')
      .classList.add('d-none');

    // Start exam elements
    document
      .getElementById('download-guardian-extension-start-exam')
      .classList.remove('d-none');
    document
      .getElementById('automatic-redirection-message-start-exam')
      .classList.remove('d-none');
    document
      .getElementById('button-manual-redirect-start-exam')
      .classList.remove('d-none');
  }

  stopPolling() {
    if (this.pollingIntervalId) {
      clearInterval(this.pollingIntervalId);
    }
  }
}

export default GuardianExtensionDownload;
