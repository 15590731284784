import 'select2';

class SelectTags {
  constructor() {
    $('.searchable-select2').select2({
      theme: 'bootstrap4',
      allowClear: true
    });


    $('[data-behavior="select-tags"]').select2({
      theme: 'bootstrap4',
      tokenSeparators: [',', ' ']
    });

    if($('.service-type-list-select2').length > 0 ) {
      $('.service-type-list-select2').select2({
        theme: 'bootstrap4',
        tokenSeparators: [',', ' '],
        maximumSelectionLength: document.querySelector('.service-type-list-select2').getAttribute('data-maximumSelectionLength')
      })
    }

    if($('.languages-select2').length > 0 ) {
      $('.languages-select2').select2({
        theme: 'bootstrap4',
        tokenSeparators: [',', ' ']
      })
    }
  }
}

export default SelectTags;
