import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import DateTimePicker from 'react-datetime-picker';
import { Context } from './UseAppointmentWizard';

const GenerateWindow = () => {
  const history = useHistory();
  const ctx = useContext(Context);
  const { addAppointmentWindow, form } = ctx;
  const [startsAt, changeStartsAt] = useState(new Date());
  const timeNow = new Date();
  const twoHoursAhead = new Date(
    timeNow.setHours(timeNow.getHours() + 1 + form.iteration_duration / 60)
  );
  timeNow.setMinutes(timeNow.getMinutes() + (form.iteration_duration % 60));

  const [endsAt, changeEndsAt] = useState(twoHoursAhead);

  const changeEndDate = (value) => {
    if (new Date(value) < twoHoursAhead) {
      return;
    }
    changeEndsAt(value);
  };

  const goBack = () => history.push('/');
  
  const addWindow = () => {
    addAppointmentWindow({
      startsAt,
      endsAt: new Date(endsAt - form.iteration_duration * 1000 * 60 - 36e5),
      readOnly: false,
    });
    history.push('/');
  };

  return (
    <div className="lms-generate-appointment-container">
      <article className="lms-generate-appointment-modal">
        <h4 className="mb-4">Generate an Appointment Window</h4>
        <h5 className="mb-3">Exam Window Start</h5>
        <DateTimePicker
          className="mb-2"
          onChange={changeStartsAt}
          value={startsAt}
          clearIcon={null}
          disableCalendar={true}
          calendarIcon={null}
        />
        <h5 className="mb-3">Exam Window End</h5>
        <DateTimePicker
          className="mb-2"
          onChange={changeEndDate}
          value={endsAt}
          clearIcon={null}
          disableCalendar={true}
          calendarIcon={null}
        />
        <i>
          Enter the Start and End of the Exam Window from the LMS. This will be
          used to calculate the Proctoring Appointment Window.&nbsp;
          {/*<a href="#">Learn more...</a>*/}
        </i>
        <div className="lms-buttons-container">
          <button onClick={goBack} className="btn btn-light lms-btn-light">
            Cancel
          </button>
          <button onClick={addWindow} className="btn btn-primary ml-2">
            Generate Appointment Window...
          </button>
        </div>
      </article>
    </div>
  );
};

export default GenerateWindow;
