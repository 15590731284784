import React, { useContext } from 'react';
import { Context } from './ExamPickerContext';

const ConfiguredEmptyExams = () => {
  const { courseName } = useContext(Context);

  return (
    <tr>
      <td className="text-center">No exams configured in {courseName}.</td>
    </tr>
  );
};

export default ConfiguredEmptyExams;
