import PropTypes from 'prop-types';
import axios from 'axios';
import csrfToken from '../../../src/utils/csrf';

class IncidentTypeFilter extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      error: null
    };
    this.$selector = "#subtype";
  }

  componentDidMount() {
    const headers = {
      headers: {
        Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
      }
    }

    axios.get('/incident-reports/incident_subtypes', {}, headers)
      .then(response => {
        const $subtype = $(this.$selector);
        const incidentSubtypes = response.data.incidentSubtypes;

        for (let i in incidentSubtypes) {
          let incidentValue = incidentSubtypes[i].id;
          let incidentLabel = incidentSubtypes[i].status;
          let option = new Option(incidentLabel, incidentValue);
          $subtype.append(option);
        }
        this.setState({isLoaded: true});

        $subtype.select2({
          placeholder: polyglot.t('filter_by_type'),
          theme: 'bootstrap4',
          width: 'style',
          allowClear: true,
          templateSelection: this.formatOption
        });

        $subtype.on('change', (event) => {
          if ($(event.currentTarget).val() == null) {
            $subtype.select2('val', event.removed.id)
            return false
          }

          this.props.updateSubtypeFilterState($subtype.val());
        });
      })
      .catch( error => {
        console.log(error);
      });
  }

  formatOption(subtype) {
    if(subtype.text.length > 30) {
      return subtype.text.substring(0,30).trim() + '...';
    } else {
      return subtype.text;
    }
  }

  render() {
    return (
      <select className='form-control'
              name='subtype'
              id='subtype'
              type='text'
              multiple='multiple'
              onChange={this.props.updateSubtypeFilterState}>
      </select>
    );
  }
}

IncidentTypeFilter.propTypes = {
  updateSubtypeFilterState: PropTypes.func.isRequired,
  filterParams: PropTypes.object.isRequired
}

export default IncidentTypeFilter;
