class TransactionsToggle {
  constructor() {
    this.toggles = document.querySelectorAll('.js-transaction-toggle')
  }

  init() {
    this.toggles.forEach((toggle) => {
      toggle.addEventListener('click', (event) => {
        this.handleClick(toggle, event);
      })
    })
  }

  handleClick(toggle, event) {
    event.preventDefault();
    toggle.parentNode.parentNode.nextElementSibling.nextElementSibling.classList.toggle('show');
  }
}

export default TransactionsToggle;
