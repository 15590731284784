import Callbacks from '../../utils/Callbacks.js';
import consumer from '../consumer';
import Toast from '../../utils/Toast';

/**
 * Instead of initializing this for each individual Turbolinks route that needs live notifications, we initialize it in the
 * VidoePlayerController so that the video player controller can pass the handleEvents action to the live_plus to handle
 * the video play rewind on toast click.
 */

class LivePlusNotifications {
  constructor(fulfillmentUuid) {
    this.identifier = { channel: 'FulfillmentNotificationsChannel', uuid: fulfillmentUuid };
    this.subscribe = this.subscribe.bind(this);
    this.createToast = this.createToast.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleUnmount = this.handleUnmount.bind(this);
    this.callbacks = new Callbacks();
  }

  init(callBack) {
    this.callBack = callBack
    this.subscribe();
  }

  subscribe() {
    this.callbacks.on(this.handleUnmount);
    this.fulfillmentNotifications = consumer.subscriptions.create(
      this.identifier, {
        received: data => {
          // check if toast with same message already exists. if so, hide it
          const toasts = this.getToastsByMessage(data.heading);
          this.hideToasts(toasts);

          // create the toast
          this.createToast(data, toasts);

          // do not prepend badge if there were no previous corresponding toasts
          if (toasts.length > 0) {
            // since the new toast is not included in the toast array, add one to
            // displayed count
            this.prependToastBadge(` ${toasts.length + 1}`);
          }
        }
      }
    );
  }

  createToast(data, toasts) {
    new Toast().danger({
      classList: ['alert-transparent'],
      message: data.heading,
      createdAt: data.event.created_at_iso,
      sort: 'desc',
      timeout: 0,
      onClick: this.handleClick,
      event: data.event,
      type: data.event_type,
      toasts: toasts
    });
  }

  // prepends a badge with a corresponding number to the most recent toast
  prependToastBadge(count) {
    const newToastEl = document.querySelector('.alert-toast');
    const numberEl = document.createElement('span');
    numberEl.className = 'badge badge-danger mr-3';
    numberEl.textContent = count;

    newToastEl.prepend(numberEl);
  }

  handleClick(data) {
    /**
     * We need to build the object structure here that the handleEvents() is expecting
     * {
     *  data: {
     *          incident: {
     *            createdAtIso: "",
     *            type: "",
     *            startsAt: ""
     *          }
     *   }
     *  type: "showEvent"
     * }
     *
     **/
    const { created_at } = data.event
    const { type } = data
    const eventData = {
      data: {
        incident: {
          type: type,
          createdAtISO: created_at
        }
      },
      type: 'showEvent'
    }

    this.callBack(eventData)
  }

  handleUnmount(data) {
    if (data.type === 'unmountFulfillmentNotifications' && this.fulfillmentNotifications) {
      this.fulfillmentNotifications.unsubscribe();
    }
  }

  // gets all toasts containing a specified message
  getToastsByMessage(message) {
    return [...document.querySelectorAll('.alert-toast')].filter(
      toast => toast.textContent.includes(message)
    );
  }

  // hides all specified toasts
  hideToasts(toasts) {
    toasts.forEach(toast => (toast.style.display = 'none'));
  }
}

export default LivePlusNotifications;
