import csrfToken from '../utils/csrf';
import Toast from '../utils/Toast';
import pluralize from '../utils/pluralize';

class AccessCodeCreator {
  constructor() {
      this.form = document.getElementsByClassName(
        'js-batch-create-access-codes'
      )[0];
      this.url = this.form.getAttribute('action');

      this.currentCountEl = document.getElementsByClassName(
        'js-access-code-size'
      )[0];
      this.unusedCountEl = document.getElementsByClassName(
        'js-access-code-unused'
      )[0];
      this.currentCount = parseInt(this.currentCountEl.innerText);
      this.unusedCount = parseInt(this.unusedCountEl.innerText);

      this.codeTypeSelector = document.querySelector('[data-behavior="code-type-selector"]');
      this.monetaryAmountGroup = document.querySelector('[data-behavior="monetary-amount-group"]');
      this.durationAmountGroup = document.querySelector('[data-behavior="duration-amount-group"]');
      this.monetaryInput = document.getElementById('monetary_amount');

      this.showExpirationCheckbox = document.querySelector('[data-behavior="expiration?"]');
      this.expirationDateRow = document.querySelector('[data-behavior="expiration-date-row"]');

      this.submit = this.submit.bind(this);
      this.handleCheckboxClick = this.handleCheckboxClick.bind(this);
      this.handleTypeSelectorChange = this.handleTypeSelectorChange.bind(this);
    }

    init() {
      this.bindEventListeners();
    }

    bindEventListeners() {
      this.form.addEventListener('submit', this.submit);
      this.codeTypeSelector.addEventListener('change', this.handleTypeSelectorChange);
      this.showExpirationCheckbox.addEventListener('click', this.handleCheckboxClick);
    }

    submit(event) {
      event.preventDefault();
      this.toggleDisableForButton();

      this.create();
    }

    toggleDisableForButton() {
      this.form.querySelector('[type=submit]').disabled = !this.form.querySelector('[type=submit]').disabled;
    }

    create() {
      const formattedData = Object.keys(this.data())
        .map(key => {
          return key + '=' + this.data()[key];
        })
        .join('&');

      fetch(this.url, {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': csrfToken()
        },
        body: formattedData
      })
        .then(response => {
          if (!response.ok) {
            this.handleSubmitError();
          } else {
            return response.text();
          }
        })
        .then(response => {
          const count = JSON.parse(response).count;
          this.reset();
          this.resolveUrl(count);
          this.resolveView(count);
          this.toast(count);
          this.toggleDisableForButton();
        })
        .catch(err => {
          this.handleSubmitError(err);
        });
    }

    handleTypeSelectorChange(event) {
      const selector = event.target;

      if (selector.value === 'AccessCode::Monetary') {
        this.monetaryAmountGroup.classList.remove('d-none')
        this.durationAmountGroup.classList.add('d-none');
        this.monetaryInput.required = true;
      } else if(selector.value === 'AccessCode::Duration') {
        this.monetaryAmountGroup.classList.add('d-none')
        this.durationAmountGroup.classList.remove('d-none');
        this.monetaryInput.required = false;
      } else {
        this.monetaryAmountGroup.classList.add('d-none')
        this.durationAmountGroup.classList.add('d-none');
      }
    }

    handleCheckboxClick(event) {
      const checkbox = event.target;
      this.expirationDateRow.style.display = checkbox.checked ? 'block' : 'none';
    }

    handleSubmitError(err = null) {
      const toastMessage = err || 'Sorry, a network error has occurred.';
      new Toast().danger({
        message: toastMessage
      });
    }

    data() {
      const expiration_present = document.querySelector('[data-behavior="expiration?"]').checked;
      const codeType = document.getElementById('code_type').value;
      let amount = '';

      if(codeType === 'AccessCode::Monetary') {
        amount = document.getElementById('monetary_amount').value;
      } else {
        amount = document.getElementById('duration_amount').value;
      }

      return {
        dltoken: document.getElementById('dltoken').value,
        count: document.getElementById('count').value,
        code_type: codeType,
        amount: amount,
        name: document.getElementById('name').value,
        expiration: expiration_present ? this.selectedDate() : 'NULL'
      };
    }

    reset() {
      this.form.reset();
      this.expirationDateRow.style.display = 'none';
      this.codeTypeSelector.value = '';
      this.monetaryAmountGroup.classList.add('d-none');
      this.durationAmountGroup.classList.add('d-none');
    }

    resolveUrl(count) {
      const url = `/access-codes/download_file?count=${count}`;
      window.location.href = url;
    }

    resolveView(count) {
      const currentCount = this.currentCount + count;
      const unusedCount = this.unusedCount + count;
      this.currentCountEl.innerText = currentCount;
      this.unusedCountEl.innerText = unusedCount;

      this.currentCount = currentCount;
      this.unusedCount = unusedCount;
    }

    toast(count) {
      new Toast().success({
        message: `Successfully added ${pluralize(count, 'Access Code')}!`
      });
    }

    selectedDate() {
      const dateSelectField = document.querySelector('[data-behavior="expiration"]');
      return new Date(dateSelectField.value);
    }
  }

export default AccessCodeCreator
