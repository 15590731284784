class BootstrapTooltip {
  constructor() {
    if ($('[data-toggle=tooltip], [rel=tooltip]').length) {
      $('body').tooltip({
        container: 'body',
        html: true,
        boundary: 'window',
        selector: '[data-toggle=tooltip], [rel=tooltip]'
      });
    }
  }
}

export default BootstrapTooltip;
