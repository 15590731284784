import csrfToken from '../utils/csrf';
import Toast from '../utils/Toast';
import axios from 'axios';

class TouchPointButton {
  constructor() {
    if (document.getElementById('video-player-container')) {
      this.rootNodeSelector = '#video-player-container'
    } else {
      this.rootNodeSelector = 'body'
    }
    this.component = document.querySelector('.js-touchpoint')
    this.closeTouchPointsEl = document.querySelector('.js-close-touch-points')
    this.touchPointsForm = document.querySelector('.touch-points-form')
    this.touchPointsSubmitButton = document.querySelector('.js-submit-touch-points')
  }

  init() {
    this.bindEventListeners()
  }

  bindEventListeners() {
    if(this.component) {
      this.component.addEventListener('click', (event) => { this.showModal(event) })
      this.closeTouchPointsEl.addEventListener('click', (event) => { this.hideModal(event) })
      this.touchPointsForm.addEventListener('submit', (event) => { this.submitForm(event) })
    }
  }

  showModal() {
    $('#touchpoint-form-modal').modal('show')
  }

  hideModal(event = null) {
    if (event != null) { event.preventDefault(); }
    $('#touchpoint-form-modal').modal('hide')
  }

  touchPointsData() {
    return {
      type: 'TouchPoint',
      event: {
        comment: this.getInputFieldValue('details'),
        proctor: this.getInputFieldValue('user'),
        touch_point_kind: this.getInputFieldValue('kind')
      }
    }
  }

  getInputFieldValue(fieldName) {
    return $(`.js-touch-points-${fieldName}`).first().val();
  }

  submitForm(event) {
    event.preventDefault()

    this.touchPointsSubmitButton.disabled = true;
    
    const headers = {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken()
      }
    }

    axios.post(this.touchPointsForm.getAttribute('action'), JSON.stringify(this.touchPointsData()), headers)
    .then(response => {
      this.handleSuccess(event);
      this.touchPointsSubmitButton.disabled = false;
    })
    .catch(error => {
      new Toast().danger({message: 'Unable to create touch point.'});
      this.touchPointsSubmitButton.disabled = false;
    });
  }

  handleSuccess(event) {
    const liItem = `<li class="list-group-item warning-fade">
                      <div class="media">
                        <div class="float-left">
                          <div class="iconbox">
                            <div class="icon">
                              <span class="fa fa-${ event.icon }"></span>
                            </div>
                          </div>
                        </div>
                        <div class="media-body">
                          <small class="text-muted mx-auto">${ event.date }</small>
                          <strong class="media-heading mb-0">
                            ${ event.heading }
                          </strong>
                          <div class="mt-3">
                            ${ event.comment }
                          </div>
                        </div>
                      </div>
                    </li>`

    if (liItem.length) {
      $('#touchpoint-form-modal').modal('hide')
      $('ul.timeline').prepend(liItem)
      this.renderToast()
    }
  }

  renderToast() {
    const toast = new Toast(this.$rootNodeSelector)
    toast.success({message: "Touch point successfully created."})
  }
}

export default TouchPointButton;
