import axios from 'axios';
import Summernote from '../shared/Summernote';
import csrfToken from '../utils/csrf';
import Toast from '../utils/Toast';

class FlagNotes {
  constructor() {
    this.flagNotesButton = document.querySelector('.js-fulfillment-flag-notes')
    this.flagNotesParent = document.querySelector('.js-flag-notes-parent')
    this.flagNotesMeta = document.querySelector('.js-flag-notes-meta')
  }

  init() {
    if(this.flagNotesButton) {
      this.flagNotesButton.addEventListener('click', (event) => {this.handleClick(event)})
    }
  }

  handleClick(event) {
    event.preventDefault();
    if(this.flagNotesButton.textContent === 'Edit') {
      this.flagNotesButton.textContent = 'Save';
      new Summernote('#js-flag-notes').init();
    } else {
      const notes = $('#js-flag-notes').summernote('code')
      const params = {
        notes: notes,
        enrollment_id: this.flagNotesParent.getAttribute('data-enrollment-id')
      }
      const headers = {
        headers: {
          Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken()
        }
      }
      axios.post(this.flagNotesParent.getAttribute('data-url'), params, headers).
      then(response => {
        const time = moment().format('L');
        if(this.flagNotesMeta) {
          this.flagNotesMeta.textContent = `Last updated on ${time}`;
        }
      }).catch(error => {
        new Toast().danger({ message: "Flag notes are empty. " })
      })
      this.flagNotesButton.textContent = 'Edit';
      $('#js-flag-notes').summernote('destroy')
    }
  }
}

export default FlagNotes;
