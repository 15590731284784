import Toast from '../utils/Toast';

// Registration
//
// This is responsible for the institution dropdown
// on the account sign up page for the instructors or students
class InstitutionSelector {
  init() {
    this.bindEventListeners();
  }

  bindEventListeners() {
    $(document).on("change", "[data-behavior=enrollment-select]", e => {
      const $selectedInstitutions = $(
        "[data-behavior=enrollment-select] :selected"
      );

      const disabledInstitutions = $selectedInstitutions.map(
        (index, option) => {
          return $(option).data("prevent-account-creation");
        }
      );

      disabledInstitutions.map((index, enrollmentDisabled) => {
        if (!enrollmentDisabled) return;
        this.displayMessage(e, $selectedInstitutions);
      });
    });
  }

  displayMessage(e, $selectedInstitutions) {
    let $this = $(e.currentTarget);

    const institutionDisabled = $this
      .find(":selected")
      .data("prevent-account-creation");
    const url = $this.find(":selected").data("prevent-enrollments-url");

    $.getJSON(url, data => {
      new Toast().danger({
        message: data.message,
        timeout: 60000,
        replaceable: true
      });
    });

    if (institutionDisabled) {
      // if there is more than one institution dropdown on the page, we want to
      // delete that dropdown from the page since it's an invalid selection
      // if there is only one dropdown, we should clear it instead.
      if ($selectedInstitutions.length >= 2) {
        // Deletes the nested field container when on a page with more than one
        // institution enrollment
        $this.parents(".nested-fields").remove();
      } else {
        $this.val('').change();
      }
    }
  }
}

export default InstitutionSelector;
