import PropTypes from 'prop-types';

const StaticImageEvent = ({createdAt, showInPlayer, text}) => {
  return (
    <div className="row align-items-center">
      <div className="col-1">
        <div className="h-100 text-center d-flex align-items-center justify-content-center">
          <span className="fad fa-play-circle fa-lg player-icon cursor-pointer" onClick={showInPlayer}/>
        </div>
      </div>
      <div className="col-11">
        <small className="text-muted">{createdAt}</small>
        <div>
          {text}
        </div>
      </div>
    </div>
  )
}

StaticImageEvent.propTypes = {
  createdAt: PropTypes.string.isRequired,
  showInPlayer: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired
}

export default StaticImageEvent
