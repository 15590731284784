const PaymentsMessage = ({ messageTimer }) => {
  const text1 = polyglot.t('prechecks_payments_step_text_1');
  const text2 = polyglot.t('prechecks_payments_step_text_2');

  return (
    <div style={{ marginTop: 120 }}>
      <h4>
        {text1} {messageTimer} {text2}
      </h4>
    </div>
  );
};

export default PaymentsMessage;
