import { event } from '@fullstory/browser';
class SchedulingPolicy {
  constructor() {
    this.number_of_retakes_allowed = 25;
    this.ddl_number_of_retakes = document.querySelector('#number_of_retakes');
    this.row_number_of_retakes = document.querySelector('#row_number_retakes');

    //Radio button group
    this.retake_policy_options = document.querySelector(
      '#retake_policy_options',
    );
    //retake delay rows
    this.row_first_retake_delay = document.querySelector(
      '#row_first_retake_delay',
    );
    this.row_second_retake_delay = document.querySelector(
      '#row_second_retake_delay',
    );
    this.row_additional_retakes_delay = document.querySelector(
      '#row_additional_retakes_delay',
    );

    //Retake delay textboxes
    this.first_retake_delay = document.querySelector('#first_retake_delay');
    this.second_retake_delay = document.querySelector('#second_retake_delay');
    this.additional_retakes_delay = document.querySelector(
      '#additional_retakes_delay',
    );

    this.retake_options_main_block = document.querySelector(
      '#retake_options_main_block',
    );
  }
  init() {
    this.bindEventListeners();
  }

  bindEventListeners() {
    this.ddl_number_of_retakes.addEventListener(
      'change',
      this.showHideRetakeFields.bind(this),
    );
    this.first_retake_delay.addEventListener('change', this.limitInputValue);
    this.second_retake_delay.addEventListener('change', this.limitInputValue);
    this.additional_retakes_delay.addEventListener(
      'change',
      this.limitInputValue,
    );
    this.retake_policy_options.addEventListener(
      'change',
      this.handlePolicySelectionChange.bind(this),
    );
  }
  showHideRetakeFields(event) {
    var dll_value = event.target.value;
    if (dll_value == 1) {
      this.row_first_retake_delay.style.display = '';
      this.row_second_retake_delay.style.display = 'none';
      this.row_additional_retakes_delay.style.display = 'none';
    } else if (dll_value == 2) {
      this.row_first_retake_delay.style.display = '';
      this.row_second_retake_delay.style.display = '';
      this.row_additional_retakes_delay.style.display = 'none';
    } else if (dll_value > 2) {
      this.row_first_retake_delay.style.display = '';
      this.row_second_retake_delay.style.display = '';
      this.row_additional_retakes_delay.style.display = '';
    } else {
      this.row_first_retake_delay.style.display = 'none';
      this.row_second_retake_delay.style.display = 'none';
      this.row_additional_retakes_delay.style.display = 'none';
    }
  }
  limitInputValue() {
    if (this.value < 1) this.value = 1;
    else if (this.value > 365) this.value = 365;
  }
  handlePolicySelectionChange(event) {
    var selectedOption = event.target.value;
    this.ddl_number_of_retakes.value = '';

    if (selectedOption == 'custom_retake_policy') {
      this.retake_options_main_block.style.display = '';
    } else {
      this.first_retake_delay.value = '';
      this.second_retake_delay.value = '';
      this.additional_retakes_delay.value = '';

      this.retake_options_main_block.style.display = 'none';
      this.row_first_retake_delay.style.display = 'none';
      this.row_second_retake_delay.style.display = 'none';
      this.row_additional_retakes_delay.style.display = 'none';
    }
  }
}
export default SchedulingPolicy;
