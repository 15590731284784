import consumer from '../consumer'

class SecureBrowserSubscription {
    constructor(userId) {
        this.identifier = { channel: 'SecureBrowserChannel', user_id: userId };
        this.secureBrowser = null;
    }

    init() {
        console.log('secure_browser#init');
        this.subscribe();
    }

    subscribe() {
        console.log("secure_browser#subscribing");
        this.secureBrowser = consumer.subscriptions.create(
            this.identifier, {
                received: (data) => { console.log(`Received ${data}`) },
                connected: () => { console.log('Connected'); },
                disconnected: () => { console.log('Disconnected'); }
          }
        );

        consumer.connection.webSocket.onerror = function (error) {
            console.error('Websocket connection error!', error);
        };
    }

  sendMessage(data) {
    console.log('Sending', data);
    if (this.secureBrowser) {
      this.secureBrowser.send(data);
    }
  }
}

export default SecureBrowserSubscription;
