import PropTypes from 'prop-types';
import EventFeeder from '../EventFeeder';

const buttonNames = [
  "AudioLevelChanged",
  "BrowserIncognito",
  "BrowserResized",
  "BrowserTab",
  "CameraRemoved",
  "ConsoleOpened",
  "CopyPaste",
  "GazeOffScreen",
  "NoGazeDetected",
  "NoOneInTheFrame",
  "SessionInterrupted",
  "SuspiciousBehavior",
]

const GeneralEvent = ({
  createdAt,
  showInPlayer,
  transformStore,
  uiMessageId,
  showEventFeed,
  feedable,
  heading,
  comment,
  feedStatus,
  name,
  feedUrl,
}) => {
  const showPlayButton = (name) => buttonNames.includes(name)

  return (
    <div className="row align-items-center">
      <div className="col-1">
        <div className="h-100 text-center d-flex align-items-center justify-content-center">
          { showPlayButton(name) && <span className="fad fa-play-circle fa-lg player-icon cursor-pointer" onClick={showInPlayer}/> }
        </div>
      </div>
      <div className="col-8">
        <small className="text-muted">{createdAt}</small>
        <div>
          {heading}
        </div>
        {
          comment && (
          <div>
            {comment}
          </div>
          )
        }
      </div>
      <div className="col-3">
        { showEventFeed && feedable &&
          <EventFeeder feedStatus={feedStatus} feedUrl={feedUrl} transformStore={transformStore} uiMessageId={uiMessageId} />
        }
      </div>
    </div>
  )
}

GeneralEvent.propTypes = {
  createdAt: PropTypes.string.isRequired,
  showInPlayer: PropTypes.func.isRequired,
  transformStore: PropTypes.func,
  uiMessageId: PropTypes.number,
  showEventFeed: PropTypes.bool,
  feedable: PropTypes.bool,
  feedStatus: PropTypes.string,
  feedUrl: PropTypes.string,
  heading: PropTypes.string
}

export default GeneralEvent
