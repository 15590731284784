const TextField = ({field, generatedId}) => {
  return (
    <div>
      <input
        type="text"
        className="form-control"
        name={`[user][enrollments_attributes][${generatedId}][fields][${field.field_as_attribute}]`}
        id={`[user][enrollments_attributes][${generatedId}][fields][${field.field_as_attribute}]`}
        data-type="text_field" />
      <span className="form-text text-muted">{field.description}</span>
    </div>
  )
}

export default TextField
