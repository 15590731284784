import PropTypes from 'prop-types';
import axios from 'axios';

import EventsTimelineSubscription from '../../../src/channels/subscriptions/EventsTimelineSubscription';
import csrfToken from '../../../src/utils/csrf';
import Toast from '../../../src/utils/Toast';

const GROUPS_MAP = {
  "Prechecks": [
    "Event::GuardianExtension",
    "Event::SystemCheck",
    "Event::VerificationFailed",
    "Event::VerificationPassed",
    "Event::VerificationRetried",
    "Event::EnvironmentConfirmation",
    "Event::LightCheckSkipped",
    "Event::VolumeCheckSkipped",
    "Event::VirtualCamUsed",
    "Event::IdConfirmation",
    "Event::PictureConfirmation",
    "Event::ExamRulesAgreementCheck",
    "Event::CaptureBelowWorkspaceLoaded",
    "Event::CaptureBelowWorkspaceCompleted",
    "Event::BackOnCaptureBelowWorkspace",
    "Event::CaptureBackWallLoaded",
    "Event::CaptureBackWallCompleted",
    "Event::BackOnCaptureBackWall",
    "Event::CaptureWorkspaceLoaded",
    "Event::CaptureWorkspaceCompleted",
    "Event::BackOnCaptureWorkspaceStep",
    "Event::BackOnProfilePhotoStep",
    "Event::ResourceSelectionBack",
    "Event::CaptureLeftWallLoaded",
    "Event::CaptureLeftWallCompleted",
    "Event::BackOnCaptureLeftWall",
    "Event::BackOnIdPhotoStep",
    "Event::CaptureRightWallLoaded",
    "Event::CaptureRightWallCompleted",
    "Event::BackOnCaptureRightWall",
    "Event::CaptureComputerLoaded",
    "Event::CaptureComputerCompleted",
    "Event::BackOnCaptureComputer",
  ],
  Scores: [
    "Event::MotionDetected",
    "Event::LightLevelChanged",
    "Event::AudioLevelChanged",
    "Event::MultipleDesktops",
    "Event::OutsideMaterials",
    "Event::ProfileFaceDetected",
    "Event::RemoteControl",
    "Event::LowAudioLevel",
    "Event::NoOneInTheFrame",
    "Event::MultiplePersonsIdentified",
    "Event::ElectronicDeviceDetected"
  ],
  Browser: [
    "Event::BrowserFullscreen",
    "Event::BrowserMaximized",
    "Event::BrowserResized",
    "Event::LostFocus",
    "Event::ConsoleOpened",
    "Event::CopyPaste",
    "Event::BrowserTab",
    "Event::Url",
    "Event::SystemKeys",
    "Event::PrintScreenAttempt",
    "Event::BrowserIncognito",
    "Event::MicDisconnected",
    "Event::WebcamDisconnected",
    "Event::MouseLeave",
    "Event::MouseBack"
  ],
  Security: [
    'Contraband',
    'Presence',
    "Event::ApplicationTerminated",
    "Event::ApplicationDetected",
    "Event::RunningAppList",
    "Event::WindowsActivationStatus",
    "Event::URLBlocked",
    "Event::MultipleKeyboardsDetected",
    "Event::MultipleMousesDetected",
    "Event::DevicePluggedIn",
    "Event::PrintScreen",
    "Event::WindowsKeyPrintScreen",
    "Event::WindowsTaskBarOpened",
    "Event::URLVisited",
    "Event::KioskModeOn",
    "Event::KioskModeOff",
    "Event::NetworkData",
  ],
};

const EVENT_TYPES_MAP = {
  "Event::MotionDetected": "motion_detected",
  "Event::LightLevelChanged": "light_level_changed",
  "Event::AudioLevelChanged": "audio_level_changed",
  "Event::MultipleDesktops": "multiple_desktops",
  "Event::OutsideMaterials": "outside_materials",
  "Event::NoOneInTheFrame": "no_one_in_the_frame",
  "Event::ProfileFaceDetected": "profile_face_detected",
  "Event::MultiplePersonsIdentified": "multiple_persons_identified",
  "Event::RemoteControl": "remote_control",
  "Event::LowAudioLevel": "low_audio_level",
  "Event::PrintScreenAttempt": "print_screen_attempt",
  "Event::ElectronicDeviceDetected": "electronic_device_detected"
};

let incidents = [];

class IncidentsModel {
  /**
   * Construct exam iteration model.
   * @param {String} userId - a user identifier
   * @param {String} fulfillmentId - a fulfillment identifier
   * @param {String} streamHost - a stream host
   * @param {Function} onIncidentsStoreChanged - function, invokes on receiving new incident from backend
   * @param {String} eventsPath - relative path to api endpoint
   * @param {Function} resolve - promise success callback
   */
  constructor(
    {
      userId,
      fulfillmentId,
      streamHost,
      onIncidentsStoreChanged,
      eventsPath,
      callbacks,
      timezone,
      examStatus,
    },
    resolve
  ) {
    this.userId = userId;
    this.fulfillmentId = fulfillmentId;
    this.streamHost = streamHost;
    this.onIncidentsStoreChanged = onIncidentsStoreChanged;
    this.eventsPath = eventsPath;
    this.callbacks = callbacks;
    this.timezone = timezone;
    this.examStatus = examStatus;
    this.deleteCandidates = []; // array of uiMessageId of incidents being prepared for deleting.
    this.processing = false;
    this.$incidents = this.fetchIncidentsData(resolve)  ;
    this.showEventFeed = false;
    this.callbacks.on((event) => {
      if (event.type === "getAllIncidents") {
        if (this.$incidents && this.$incidents.done) {
          this.$incidents.done(() => {
            this.$incidents = null;
          });
        } else {
          this.sendIncidentsToVideoPlayer();
        }
      }
      if (event.id === "closeWSConnection") {
        this.eventsTimeline.unsubscribe();
      }
    });
  }

  getIncidentsByType() {
    this.addSecurityEventTypes();
    const incidentsByType = _.groupBy(
      incidents.filter( incident => (incident.uuid === this.fulfillmentId) ),
      "type");
    const sortedIncidents = _.map(incidentsByType, (incidents, type) => {
      let group;
      _.find(
        GROUPS_MAP,
        (value, key) => _.includes(value, type) && (group = key)
      );
      const totalScores = this.getTotalScoresByType(incidents);
      let header = '';
      if (incidents[0].type === 'Event::LostFocus') {
        header = 'Third-party application use';
      } else {
        header = incidents[0].typeName;
      }
      let anyEventsMissingFeedStatus = this.eventMustBeFed(incidents);
      const name = type.substr(7); //Avoid from "Event::"
      return { group, name, header, totalScores, incidents, anyEventsMissingFeedStatus };
    });
    this.summarizeFaceIncidentsScores(sortedIncidents);
    // incidents[0] is always last created incident and sortedIncidents[0] is corresponding group
    if (sortedIncidents && sortedIncidents.length) {
      sortedIncidents[0].highlight = incidents[0].highlight;
    }
    return sortedIncidents;
  }

  getTotalScoresByType(incidents) {
    let totalScores = 0;
    incidents.forEach(
      incident => !incident.markAsDeleted && (totalScores += incident.score)
    );
    return totalScores;
  }

  eventMustBeFed(incidents) {
    let statuses = incidents.map(incident => incident.feed_status);
    let feedables = incidents.map(incident => incident.feedable);
    return statuses.some(status => (status === null)) && feedables.some(feedable => (feedable === true))
  }

  summarizeFaceIncidentsScores(sortedIncidents) {
    let facesIncidentsTotalScores = 0;
    const indexesArr = [];
    const incidentsToSummarize = ["NoOneInTheFrame", "MultiplePersonsIdentified", "ProfileFaceDetected"];

    incidentsToSummarize.forEach( incident => {
      let index = _.findIndex(sortedIncidents, { name: incident});
      ~index && indexesArr.push(index);
   });

    indexesArr.forEach( item => {
      facesIncidentsTotalScores += sortedIncidents[item].totalScores;
    });
    indexesArr.forEach( item => {
      sortedIncidents[item].totalScores = facesIncidentsTotalScores;
    });
  }

  parseResponse(data) {
    if (data) {
      const events = data.events ? data.events : [data];
      _.each(events, event => incidents.push(event));
      this.showEventFeed = data.showEventFeed;
    }
  }

  getShowEventFeed() {
    return this.showEventFeed;
  }

  sendIncidentsToVideoPlayer() {
    const incidentsToVideoPlayer = incidents.filter( incident => {
      if(incident.type === 'Event::LostFocus') {
        incident.body = null;
      }

      if(!incident.markAsDeleted) {
        return incident.type === "Event::Incident" || incident.type === "Event::SuspiciousBehavior"
      }
      return false;
    });

    if (this.examStatus == "running") {
      this.callbacks.fire({
        type: "allIncidents",
        data: incidentsToVideoPlayer
      });
    }
  }

  sendNewIncidentToVideoPlayer(incident) {
    this.callbacks.fire({
      type: "newIncident",
      data: incident
    });
  }

  fetchIncidentsData(resolve) {
    incidents.length && (incidents.length = 0);

    return axios.get(this.eventsPath, this.getHeaders())
      .then(response => { this.parseResponse(response.data)  })
      .then(response => { resolve(response); })
      .then(() => { this.sendIncidentsToVideoPlayer() })
      .then(() => { new EventsTimelineSubscription(
                      this.fulfillmentId,
                      this.examStatus,
                      this.timezone,
                      this.onIncidentsStoreChanged,
                      incidents
                    ).init()
      })
      .catch(error => { console.error(error) })

  }

  createIncident(incident) {
    const { type, created_at, score, createdAtISO, startTime: starts_at, endTime: ends_at, comment } = incident;
    const data = type === "suspicious_behavior" ? { type, starts_at, ends_at, comment } : { type, score, created_at, createdAtISO };
    data.uuid = this.fulfillmentId;
    data.created_by_id = this.userId;
    axios.post("/api/scores", data, this.getHeaders())
      .then((response) => { this.parseResponse(response.data) })
      .then(() => { this.updateView() })
      .catch(error => console.error(error));
  }

  deleteIncidents() {
    const requests = [];
    const incidentsGeneral = [];
    const incidentsWithScores = [];

    this.deleteCandidates.forEach((id => {
      const incident = _.find(incidents, { uiMessageId: id});
      if (incident.type == "Event::Incident") {
        incidentsGeneral.push(id);
      } else {
        incidentsWithScores.push(id);
      }
    }));

    incidentsGeneral.length && requests.push(this.deleteIncidentsRequest("/api/fulfillment_incidents", incidentsGeneral, this.fulfillmentId));
    incidentsWithScores.length && requests.push(this.deleteIncidentsRequest("/api/scores", incidentsWithScores, this.fulfillmentId));

    if (requests.length > 0) {
      this.enableProcess();
      $.when.apply(this, requests)
        .done(() => { this.removeDeletedIncidents() })
        .done(() => { this.disableProcess() })
        .done(() => { this.updateView() })
        .fail(response => console.error(response));
    }
  }

  deleteIncidentsRequest(endpoint, idsArray, uuid) {
    return axios.delete(endpoint, { data: { ids: idsArray, uuid: uuid } }, this.getHeaders())
    .then(response => {
      new Toast().success({message: "Events successfully deleted."})
    })
  }

  updateIncidentScore({ incidentId, score }) {
    const incident = _.find(incidents, _incident => _incident.uiMessageId == incidentId);
    const type = EVENT_TYPES_MAP[incident.type];
    const params = { id: incidentId, type: type, uuid: this.fulfillmentId, score }
    if (incident) {
      axios.put("/api/scores", params, this.getHeaders())
        .then(() => { incident.score = score })
        .then(() => { this.updateView() })
        .catch(errors => { console.error(errors) });
    }
  }

  updateIncidentComment({ incidentId, comment }) {
    const incident = _.find(
      incidents,
      _incident => _incident.uiMessageId == incidentId
    );
    if (incident) {
      const params = {
        data: { id: incidentId, type: type, uuid: this.fulfillmentId, comment }
      }
      axios.put("/api/scores", params, this.getHeaders())
        .then(() => { incident.comment = comment })
        .then(() => { this.updateView() })
        .catch(errors => { console.error(errors) });
    }
  }

  updateEventFeed({ eventId, state}) {
    const incident = _.find(
      incidents,
      _incident => _incident.uiMessageId == eventId
    );
    incident.feed_status = state;
  }

  addToDeleteCandidates(incidentId) {
    this.deleteCandidates.push(incidentId);
  }

  removeFromDeleteCandidate(incidentId) {
    this.deleteCandidates = _.without(this.deleteCandidates, incidentId)
  }

  removeDeletedIncidents() {
    incidents = _.reject(incidents, inc => this.deleteCandidates.indexOf(inc.uiMessageId) > -1);
    this.emptyDeleteCandidates();
  }

  emptyDeleteCandidates() {
    this.deleteCandidates.length = 0;
  }

  enableProcess() {
    this.processing = true;
  }

  disableProcess() {
    this.processing = false;
  }

  removeIncidentById(incidentId) {
    incidents = _.reject(incidents, inc => inc.uiMessageId === incidentId);
  }

  updateView() {
    this.onIncidentsStoreChanged();
  }

  getHeaders() {
    return {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken()
      }
    }
  }

  addSecurityEventTypes() {
    let newSecurityHeaders = incidents
      .filter(incident => !GROUPS_MAP.Security.includes(incident.type) && incident.feed_type == 'CONTRABAND')
      .map(incident => incident.type);
    GROUPS_MAP.Security = [...GROUPS_MAP.Security, ...new Set(newSecurityHeaders)];
  }
}

export default IncidentsModel
