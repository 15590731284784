const FlightpathProgressBar = ({ totalProcs, currentProcIndex }) => {
  let currentValue = Math.ceil((currentProcIndex / totalProcs) * 100);

  return (
    <div className={'flightpath-progress'}>
      <div className={'progress js-flightpath-progress mb-0 rounded-0'}>
        <div
          className={'progress-bar'}
          style={{ width: `${currentValue}%` }}
          aria-valuenow={currentValue}
          aria-valuemin={0}
          aria-valuemax={100}
        >
          {`${currentValue}%`}
        </div>
      </div>
    </div>
  );
};

export default FlightpathProgressBar;
