import React, { useContext } from 'react';
import WindowRow from './WindowRow';
import { Context } from './UseAppointmentWizard';
import RequestApproval from './RequestApproval';
import '../../stylesheets/components/wizard/appointment_wizard.scss';

const Root = () => {
  const {
    areWindowsValid,
    addAppointmentWindow,
    windows,
    windowsAvailable,
    form,
    mountpoint,
    previousStepUrl,
    examPickerUrl,
    isNewExam, // flag to indicate that we are on edit mode
  } = useContext(Context);

  const token = document.querySelector('meta[name="csrf-token"]')?.content;

  return (
    <>
    <div className="lms-container small" id="wizard">
        <h3 className='lms-title mb-4'>Exam Windows</h3>
        {windowsAvailable && !areWindowsValid() && (
          <div className="d-flex flex-column warning-container-layout warning-container-style mb-4">
            <div className="warning-text-layout warning-text-style">
              <p>
                <b>Note:</b> Please increase the Exam Window Length to accommodate both the Exam Duration ({ form.iteration_duration }) + 30 minutes.
              </p>
              <p>
                We have documentation on&nbsp;
                <a href="#" className="warning-best-practices-link">
                  recommended exam window practices.
                </a>
              </p>
              <p>
                You can contact us for special approval of your current Exam Windows configuration.
              </p>
            </div>
            <RequestApproval
                mountpoint={mountpoint}
                windows={windows}
                token={token}
                isNewExam={isNewExam}
                examPickerUrl={examPickerUrl}
            />
          </div>
        )}

        {!windowsAvailable ? (
          <div className="flex-column">
            <div className="add-window-layout">
              <p className="mb-2">You need at least one Appointment Window to continue...</p>
              <button onClick={addAppointmentWindow} className="btn main-add-window-button main-add-window-button-text">
                Add Window
              </button>
            </div>
          </div>
          ) : (
            <div className="d-flex flex-column w-100 window-container">
              {Object.keys(windows).map((name, index) => (
                <WindowRow key={name} name={name} index={index} examDuration={form.iteration_duration} />
              ))}
            </div>
          )
        }
        {areWindowsValid() && (
          <div className="d-flex pl-0">
            <button
              onClick={addAppointmentWindow}
              className="lms-toolbar btn-outline-meazure"
            >
              Add Window
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default Root;
