import Callbacks from '../../utils/Callbacks.js';
import consumer from '../consumer'

class EventsTimelineSubscription {
  constructor(fulfillmentId, fulfillmentStatus, timezone, updateEventTimeline, events) {
    this.timezone = timezone
    this.identifier = { channel: 'EventsTimelineChannel', fulfillment_uuid: fulfillmentId };
    this.callbacks = new Callbacks();
    this.fulfillmentStatus = fulfillmentStatus
    this.updateEventTimeline = updateEventTimeline
    this.events = events
  }

  init() {
    if (this.fulfillmentStatus === 'running') {
      this.subscribe();
    }
  }

  subscribe() {
    this.callbacks.on(() => { this.handleUnmount() });
    this.eventsTimeline = consumer.subscriptions.create(
      this.identifier, { received: data => {
        this.handleReceivedEvent(data) } }
    );
  }

  handleUnmount(data) {
    if (data?.type === 'unmountEventsTimeline' && this.eventsTimeline) {
      this.eventsTimeline.unsubscribe();
    }
  }

  handleReceivedEvent(originalEvent) {
    const event = _.clone(originalEvent);
    const { type, createdAt, staticImageDetectedISO } = event;

    event.createdAt = this.convertDateAccordingTimezone(createdAt);

    if(event.type === 'Event::LostFocus') {
      event.body = null;
    } else if (event.type === 'Event::StaticImageDetected' && staticImageDetectedISO) {
      event.body = this.convertDateAccordingTimezone(staticImageDetectedISO);
    } else if (_.includes(['Event::Incident', 'Event::SuspiciousBehavior'], type)) {
      this.sendNewEventToVideoPlayer(event);
    }
    if (!this.events.map(e => this.eventIdentifierString(e)).includes(this.eventIdentifierString(event))) {
      this.events.unshift(event);
    }
    this.updateEventTimeline();
  }

  convertDateAccordingTimezone(date) {
    return moment(date).utcOffset(this.timezone).format("h:mma on MMM D, YYYY");
  }

  sendNewEventToVideoPlayer(event) {
    this.callbacks.fire({
      type: "newEvent",
      data: event
    });
  }

  eventIdentifierString(event) {
    return `${event.feed_type}_${event.id}`;
  }

}

export default EventsTimelineSubscription;
