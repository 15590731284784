import axios from 'axios';
import {useEffect, useState} from 'react';
import ActionButtons from "../Shared/ActionButtons";
import csrfToken from "../../../../src/utils/csrf";
import parse from "html-react-parser";

const ResourceValidationProcedure = (props) => {
	const procedure = props.procedure
	const sendEvent = props.sendEvent;
	const handleNext = props.handleNext;
	const openModal = props.openModal;
	const closeModal = props.closeModal;
	const fulfillment = props.fulfillment;
	const markProcCompleted = props.markProcCompleted;
	const procedureCompleted = props.procedureCompleted
	const getFulfillmentEvents = props.getFulfillmentEvents;
	const showInPlayer = props.showInPlayer
	const getVideoPlayerEvent = props.getVideoPlayerEvent
	const resolveEvent = 'Event::ResolutionStartedResourceValidation';

	const [noResourcesAllowed, setNoResourcesAllowed] = useState(false);
	const [allowedResources, setAllowedResources] = useState({});
	const [otherResources, setOtherResources] = useState({});

	useEffect(() => {
		getFulfillmentEvents(['Event::ProctorResourceValidationCompleted', 'Event::ResourceCheckSkipped'], (response) => {
			if(response.data.events.length > 0){
				markProcCompleted(procedure.id);
			} else {
				sendEvent('Event::ProctorResourceValidationStarted', {});
			}
		})
	},[]);

	useEffect(() => {
		axios.get(
			`${window.location.origin}/api/secure_browser/fulfillments/${fulfillment.uuid}/allowed_resources`,
			{},
			{
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'X-CSRF-Token': csrfToken()
			})
			.then( response => {
				if (response.data) {
					let data = response.data;
					setNoResourcesAllowed(data.no_resources_allowed);
					setAllowedResources(data.allowed_resources);
					setOtherResources(data.other_resources);
				}
			})
			.catch( error => {
				console.log(error);
			});
	}, []);

	// useEffect(() => {
	// 	getVideoPlayerEvent('Event::SelectResourcesCompleted', (response) => {
	// 		if(response.data.event?.type) {
	// 			showInPlayer(response.data.event)
	// 		}
	// 	})
	// },[]);

	const completeProcedure = () => {
		sendEvent('Event::ProctorResourceValidationCompleted', {});
		markProcCompleted(procedure.id);
		handleNext();
	};

	const resolveProcedure = () => {
		openModal();
		sendEvent(resolveEvent, {});
	}

	return(
		<>
			{ noResourcesAllowed ? (
      otherResources && otherResources.length > 0 ? (
        <div className="col-md-12">
					<div className={'col-md-12 text-center'}><h5>Other Resources</h5></div>
					<div className="col-md-12"></div>
          <div>{parse(otherResources)}</div>
        </div>
      ) : (
        <div className={'strong'}>No resources allowed.</div>
      )
    ) : (
						<>
							<div className="row">
								<div className={'col-md-12 text-center'}><h5>Allowed Resources</h5></div>
								{Object.entries(allowedResources).map(([section, hash]) => {
									return (
										<div className="col-md-12 mb-2" key={`resources_${section}`}>
											<span className={'mb-4 fw-bold'}>{section.toUpperCase()}</span>
											<ul className="precheck-align-li">
												{Object.entries(hash).map(([key, value]) => {
													return (
														<li key={`${section}_${value}`}>
															<span className="precheck-grid-li">{value}</span>
														</li>
													)
												})}
											</ul>
										</div>
									)
								})}
							</div>
							<div className="row">
								{otherResources.length > 0 &&
									<>
										<div className={'col-md-12 text-center'}><h5>Other Resources</h5></div>
										<div className="col-md-12">
										<div>{parse(otherResources)}</div>
										</div>
									</>
								}
							</div>
						</>
					)
			}
			<ActionButtons
				resovleBtnText={'Resolve...'}
				confirmBtnText={'Images Match Allowed Resources'}
				confirmBtnAction={completeProcedure}
				resolveBtnAction={resolveProcedure}
			/>
		</>
	)
}

export default ResourceValidationProcedure;
