import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import UseAppointmentWizard, { Context } from './UseAppointmentWizard';
import Root from './Root';

const AppointmentWizard = ({
  mountpoint,
  form,
  previousStepUrl,
  examPickerUrl,
  isNewExam,
}) => {
  const controls = UseAppointmentWizard(
    mountpoint,
    form,
    previousStepUrl,
    examPickerUrl,
    isNewExam,
  );

  return (
    <Context.Provider value={controls}>
      <Root />
    </Context.Provider>
  );
};

export default AppointmentWizard;
