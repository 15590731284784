import { useEffect } from 'react';
import PrechecksHeaderGroup from './CardSections/PrechecksHeaderGroup';
import PrechecksBodyText from './CardSections/PrechecksBodyText';
import PrechecksFooterButton from './CardSections/PrechecksFooterButton';
import { PrechecksVideoPlayer } from './PrechecksVideoPlayer';

const GuidedLaunchInstructionsCard = ({
  id,
  icon,
  precheckData,
  iconPrefix,
  title,
  paragraph1,
  paragraph2,
  buttonText,
  handleNext,
  sendEvent,
  handleLastPage,
}) => {
  const generalScanMp4 = precheckData.videos.generalScanMp4;
  const phoneScanningImg = precheckData.images.phoneScanningImg;

  useEffect(() => {
    sendEvent('Event::RoomScanInstructionsLoaded');
  }, []);

  const handleBtnClick = () => {
    sendEvent('Event::RoomScanInstructionsComplete');
    handleNext();
  };

  return (
    <div className="container-fluid text-center precheck-card-content" id={id}>
      <PrechecksHeaderGroup
        title={title}
        image={phoneScanningImg}
        icon={icon}
        iconPrefix={iconPrefix}
      />
      <div className="text-left">
        <PrechecksBodyText bodyText={paragraph1} />
      </div>
      <PrechecksVideoPlayer
        videoInfo={generalScanMp4}
        idPrefix="general-scan"
      />
      <div className="text-left">
        <PrechecksBodyText bodyText={paragraph2} />
      </div>
      <div className="text-center">
        <a href="#" className="precheck-a" onClick={handleLastPage}>
          {polyglot.t('prechecks_guidedlaunch_begin_scan_opt_out')}
        </a>
      </div>
      <PrechecksFooterButton
        buttonText={buttonText}
        handleClick={handleBtnClick}
      />
    </div>
  );
};

GuidedLaunchInstructionsCard.defaultProps = {
  title: polyglot.t('prechecks_guidedlaunch_instructions_title'),
  paragraph1: polyglot.t('prechecks_guidedlaunch_instructions_paragraph1'),
  paragraph2: polyglot.t('prechecks_guidedlaunch_instructions_paragraph2'),
  icon: 'fa-camera',
  iconPrefix: 'fal',
  link: polyglot.t('prechecks_guidedlaunch_instructions_link'),
  statusColor: 'primary',
  buttonText: polyglot.t('prechecks_guidedlaunch_instructions_button'),
};

export default GuidedLaunchInstructionsCard;
