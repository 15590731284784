import React, { useContext } from 'react';
import { Context } from './UseAppointmentWizard';
import Datetime from 'react-datetime';
import moment from 'moment';

const WindowRow = ({ name, index, examDuration }) => {
  const { windows, updateAppointmentWindow, deleteAppointmentWindow } =
    useContext(Context);

  const window = windows[name];
  const {
    name: windowName = '',
    startsAt,
    endsAt,
    readOnly,
    lastAppointmentWindow,
  } = window;
  const availableWindowInMinutes = Number(examDuration) + 30;
  const examWindowName = `Exam Window # ${index + 1}`;
  const expectedDateFormat = 'MM/DD/YYYY @';
  const expectedTimeFormat = 'hh:mm a ZZ';
  const examWindowPickerProps =
    readOnly == true
      ? { className: 'w-100 small', disabled: true, readOnly: true }
      : {
          className: ['w-100 background-input-read-only small'],
          readOnly: true,
          placeholder: 'mm/dd/yyyy @ 0:00 am -0500',
        };

  const updateStartsAt = (startsAt) => {
    updateAppointmentWindow(name, { ...window, startsAt: new Date(startsAt) });
  };
  const updateEndsAt = (endsAt) => {
    updateAppointmentWindow(name, { ...window, endsAt: new Date(endsAt) });
  };

  const deleteWindow = () => deleteAppointmentWindow(name);

  var isAfterCurrentDate = function (current) {
    var yesterday = moment().subtract(1, 'day');
    return endsAt === null
      ? current.isAfter(yesterday)
      : current.isBetween(startsAt, endsAt, 'days', '[]');
  };

  var isAllowedToEndDate = function (currentDate) {
    return currentDate.isSameOrAfter(moment(startsAt));
  };

  return (
    <div className="d-flex flex-column w-100 windows-container-layout">
      <div
        className={
          'd-flex windows-row-header-layout window-row-header-style ' +
          (readOnly == false ? 'window-row-header-no-column' : '')
        }
      >
        <div className="window-row-header-text">{examWindowName}</div>
        {readOnly == true ? (
          <div className="window-row-header-readonly-text">
            Can be modified only at LMS settings
          </div>
        ) : (
          <button
            onClick={deleteWindow}
            className="btn window-row-header-delete-btn"
          >
            Delete
          </button>
        )}
      </div>
      <div className="container px-3 window-row-body-style">
        <div className="main">
          <div className="lms-left-side">
            <span className="window-row-body-start-date-label">Exam Start</span>
            <Datetime
              dateFormat={expectedDateFormat}
              timeFormat={startsAt !== null ? expectedTimeFormat : false}
              initialValue={startsAt !== null ? new Date(startsAt) : startsAt}
              onChange={updateStartsAt}
              inputProps={examWindowPickerProps}
              isValidDate={isAfterCurrentDate}
            />
          </div>
          <div className="lms-right-side pr-0">
            <span className="window-row-body-end-date-label">Exam End</span>
            <Datetime
              dateFormat={expectedDateFormat}
              timeFormat={endsAt !== null ? expectedTimeFormat : false}
              initialValue={endsAt !== null ? new Date(endsAt) : endsAt}
              onChange={updateEndsAt}
              inputProps={examWindowPickerProps}
              isValidDate={isAllowedToEndDate}
            />
          </div>
        </div>
        <div className="main-last-appointment">
          <div className="lms-left-side">
            <span className="window-row-last-appointment-label">
              Last appointment time (calculated)
            </span>
            <Datetime
              dateFormat={expectedDateFormat}
              timeFormat={expectedTimeFormat}
              value={
                endsAt !== null
                  ? moment(new Date(endsAt))
                      .subtract(availableWindowInMinutes, 'minutes')
                      .toDate()
                  : null
              }
              initialValue={new Date(lastAppointmentWindow)}
              inputProps={{ disabled: true, className: 'small w-100' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WindowRow;
