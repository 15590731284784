import { useEffect } from 'react';
import parse from 'html-react-parser';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import { useTranslate } from 'react-polyglot';

import ChargeDevice from '../../../assets/images/second_camera/charge-device.svg';
import PhoneCoffeeMug from '../../../assets/images/second_camera/setup-phone.svg';
import useCjsStream from '../../hooks/useCjsStream';
import useIsCharging from '../../hooks/useIsCharging';
import useOrientation from '../../hooks/useOrientation';
import { SECOND_CAMERA_SCREENS } from './App';

export default function PhonePosition(props) {
  const t = useTranslate();
  const { isCharging, setIsCharging } = useIsCharging();
  const { type } = useOrientation();
  // TODO: would be nice if this could use the region and setCurrentStep from the context
  const { connected, videoRef } = useCjsStream(props.region, () =>
    props.setCurrentStep(SECOND_CAMERA_SCREENS.EXAM_COMPLETE),
  );

  useEffect(() => {
    if (type.startsWith('landscape')) {
      void document.documentElement?.requestFullscreen?.();
    } else {
      if (document.fullscreenElement) {
        void document.exitFullscreen();
      }
    }
  }, [type]);

  const isPortrait = type?.startsWith('portrait');

  const handleNext = () => {
    setIsCharging(true);
  };

  return (
    <>
      <div className="flex-item p-2">
        <video ref={videoRef} autoPlay playsInline />
      </div>

      <div className="flex-item column p-2">
        {isCharging ? (
          <>
            <div className="d-flex justify-content-center">
              <Image className="img-responsive" src={PhoneCoffeeMug} />
            </div>

            <div className="text-center">
              <h6>
                <i className="fa-solid fa-camera-web mr-2"></i>
                {t('phone_position.rotate_heading')}
              </h6>
              <p
                dangerouslySetInnerHTML={{
                  __html: t('phone_position.cup_position_workspace'),
                }}
              />
              <p>{parse(t('phone_position.finish_setup'))}</p>
            </div>
          </>
        ) : (
          <>
            <div className="d-flex justify-content-center">
              <Image className="img-responsive" src={ChargeDevice} />
            </div>

            <div className="text-center">
              <h6>
                <div
                  className="spinner-border spinner-border-sm mr-2"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
                {t('phone_position.plugin_heading')}
              </h6>
              <p>{t('phone_position.plugin_outlet')}</p>
              <div>
                <Button
                  className="button-responsive"
                  variant="primary"
                  onClick={handleNext}
                >
                  {t('nav_button.next')}
                </Button>
              </div>
            </div>
          </>
        )}
      </div>

      {isPortrait && (
        <div className="landscape-overlay d-flex justify-content-center">
          <div className="text-center mt-5">
            <div>{t('phone_position.rotate_instructions')}</div>
            <i className="fa-regular fa-mobile fa-5x mt-2" />
          </div>
        </div>
      )}
    </>
  );
}
