/**
 * This class handles Annual fee section on the Iteration form
 */

class AnnualFee {
  constructor() {
    this.annualFeeCheckBox = document.querySelector(
      '[data-element="annual-fee-checkbox"]'
    );
    this.annualFeeRadios = document.querySelectorAll(
      '[data-element="annual-fee-radio"]'
    );
    this.annualFeeCost = document.querySelector(
      '[data-element="annual-fee-cost"]'
    );
    this.annualFeeSuspended = document.querySelector(
      '[data-element="annual-fee-suspended"]'
    );
    this.handleAnnualFeeCheckBox = this.handleAnnualFeeCheckBox.bind(this);
  }

  init() {
    if (this.annualFeeCheckBox != null) {
      this.bindEventListeners();
      this.handleOnLoad();
    }
  }

  bindEventListeners() {
    this.annualFeeCheckBox.addEventListener('change', this.handleAnnualFeeCheckBox);
  }

  handleOnLoad() {
    this.handleAnnualFeeCheckBox();
  }

  handleAnnualFeeCheckBox() {
    const annualFeeChecked = this.annualFeeCheckBox.checked;
    this.changeRadiosState(!annualFeeChecked);
    this.annualFeeCost.disabled = !annualFeeChecked;
    if (this.annualFeeSuspended) {
      this.annualFeeSuspended.disabled = !annualFeeChecked;
    }
  }

  changeRadiosState(state) {
    for (let i = 0; i < this.annualFeeRadios.length; i++) {
      this.annualFeeRadios[i].disabled = state;
    }
  }
}

export default AnnualFee;
