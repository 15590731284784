import React from 'react';
import obfuscationIcon from '../../../assets/images/video/display-blur.svg';

class HoverOverIcon extends React.Component {
  constructor(props) {
    super(props);
    this.features = [
      {
        id: 'screen-obfuscation-enabled',
        text: '  Screen Blur Enabled',
        prop: this.props.enableScreenObfuscation,
        type: 'img',
        src: obfuscationIcon,
      },
      {
        id: 'video-recording-disabled',
        text: '  Playback Disabled',
        prop: this.props.videoRecordingDisabled,
        type: 'icon',
        className: 'fa-regular fa-video-slash fa-lg',
      },
    ];
  }

  hoverTextToggle = (featureId, text) => {
    const element = document.getElementById(featureId);
    if (element) {
      element.textContent = text;
    }
  };

  render() {
    let renderedFeatureCount = 0;

    return (
      <div>
        {this.features.map((feature) => {
          if (!feature.prop) return null;
          const topPosition = 10 + 35 * renderedFeatureCount;
          renderedFeatureCount++;
          return (
            <div
              key={feature.id}
              className={`badge badge-pill badge-warning hover-over-icon-pos`}
              style={{ top: `${topPosition}px`, left: '10px' }}
              onMouseEnter={() =>
                this.hoverTextToggle(feature.id, feature.text)
              }
              onMouseLeave={() => this.hoverTextToggle(feature.id, '')}
              data-testid={`badge-${feature.id}`}
            >
              {feature.type === 'img' ? (
                <img
                  src={feature.src}
                  alt="obfuscation icon"
                  data-testid={`img-${feature.id}`}
                />
              ) : (
                <i
                  className={feature.className}
                  data-testid={`icon-${feature.id}`}
                ></i>
              )}
              <span id={feature.id} />
            </div>
          );
        })}
      </div>
    );
  }
}

export default HoverOverIcon;
