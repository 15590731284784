import { useState } from 'react';

const usePaginator = (items, itemsPerPage) => {
  const [itemOffset, setItemOffset] = useState(0);
  const [allItems, setAllItems] = useState(items || []);
  const [currentPage, setCurrentPage] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = allItems.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(allItems.length / itemsPerPage);

  const handlePageClick = (event) => {
    calculateAndSetOffset(event.selected);
    setCurrentPage(event.selected);
  };

  const calculateAndSetOffset = (selectedPage) => {
    const newOffset = (selectedPage * itemsPerPage) % allItems.length;
    setItemOffset(newOffset);
  };

  const itemOffsetToShow = () => {
    return itemOffset + 1;
  };

  const endOffsetToShow = () => {
    return endOffset < allItems.length ? endOffset : allItems.length;
  };

  return {
    itemOffset,
    allItems,
    currentPage,
    currentItems,
    pageCount,
    itemsPerPage,
    calculateAndSetOffset,
    setCurrentPage,
    handlePageClick,
    itemOffsetToShow,
    endOffsetToShow,
    setAllItems,
  };
};

export default usePaginator;
