import axios from 'axios';
import Toast from '../utils/Toast';
import csrfToken from '../utils/csrf';
import IncreaseProgressBar from './shared_actions/IncreaseProgressBar';
import CompleteTab from './shared_actions/CompleteTab';
import ShowNextTab from './shared_actions/ShowNextTab';
import UpdateNextAndPreviousButtons from './shared_actions/UpdateNextAndPreviousButtons';

class CompleteCheckbox {
  constructor() {
    this.completeCheckboxes = document.querySelectorAll('#flight-path input[name=complete]');
  }

  init() {
    this.bindEventListeners();
  }

  bindEventListeners() {
    this.completeCheckboxes.forEach((checkbox) => {
      checkbox.addEventListener('click', (event) => { this.handleClick(checkbox, event) })
    });
  }

  handleClick(checkbox, event) {
    const spinner = checkbox.closest('.checkbox').querySelector('.checkbox-spin')
    const checkmark = checkbox.closest('.checkbox').querySelector('.checkbox-complete')
    const label = checkbox.closest('label').querySelector('strong')
    this.showSubmissionConfirmationAlert(checkbox);
    if (!checkbox.checked) {
      spinner.classList.add('d-none')
      return;
    }
    spinner.classList.remove('d-none');
    checkbox.disabled = true;
    checkbox.checked = true
    label.textContent = 'Completing...'
    let data = {
      type: 'FlightPath',
      event: {
        procedure_id: checkbox.getAttribute('data-procedure')
      }
    };

    const headers = {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken()
      }
    }

    axios.post(checkbox.getAttribute('data-url'), JSON.stringify(data), headers)
    .then(response => {
      setTimeout(function() {
        spinner.classList.add('d-none')
        checkmark.classList.remove('d-none')
        label.textContent = 'Completed Step'
        new CompleteTab().init();
        new IncreaseProgressBar().init();
        setTimeout(function() {
          new ShowNextTab();
          new UpdateNextAndPreviousButtons().init();
        }, 750)
      }, 300);
    }).catch(error => {
      label.textContent = 'Complete Step'
      checkbox.checked = false
      checkbox.disabled = false;
      spinner.classList.add('d-none');
      new Toast().danger({message: 'Unable to complete flight path.'});
    });
  }

  showSubmissionConfirmationAlert(checkbox) {
    if (checkbox.classList.contains('end-appointment')) {
      if (confirm("Please confirm the test-taker has submitted their exam. If they have not, the test-taker will be logged out of the learning management system and disconnected from ProctorU immediately.")) {
        checkbox.checked =  true;
      } else {
        checkbox.checked = false
        checkbox.disabled = false;
      }
    }
  }
}

export default CompleteCheckbox;

