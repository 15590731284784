import DOMPurify from 'dompurify';
import _ from 'lodash';
import { useEffect } from 'react';
import PrechecksBodyText from './CardSections/PrechecksBodyText';
import PrechecksFooterButtonGroup from './CardSections/PrechecksFooterButtonGroup';
import PrechecksHeaderGroup from './CardSections/PrechecksHeaderGroup';

const OTHER_RESOURCES = 'other_resources';

/**
 * @typedef GuidedLaunchSelectResourcesProps
 * @property {string} id
 * @property {string} icon
 * @property {string} iconPrefix
 * @property {string} titleResources - The title to display when resources are available.
 * @property {string} titleNoResources - The title to display when no resources are available.
 * @property {string} body - The body text of the component.
 * @property {string} buttonText - The text for the next button.
 * @property {string} skipButtonText - The text for the skip button.
 * @property {string} backButtonText - The text for the back button.
 * @property {() => void} handleNext - Function to transition to the next step.
 * @property {() => void} handlePrevious - Function to transition to the previous step.
 * @property {ResourceSelectionHandler} handleResourceSelection - Object containing data and functions for resource selection.
 * @property {SendEventFunction} sendEvent
 * @property {PrecheckData} precheckData
 */

/**
 * @param {GuidedLaunchSelectResourcesProps} props
 * @returns {React.ReactElement}
 */
const GuidedLaunchSelectResources = ({
  id,
  icon,
  iconPrefix,
  titleResources,
  titleNoResources,
  body,
  buttonText,
  skipButtonText,
  backButtonText,
  handleNext,
  handlePrevious,
  handleResourceSelection,
  sendEvent,
  precheckData,
}) => {
  const allowedResources = precheckData.allowedResources;
  const otherResources = precheckData.otherResources;
  const otherResourcesCheckEnabled = precheckData.otherResourcesCheckEnabled;
  const noResources =
    precheckData.emptyResources &&
    (!otherResourcesCheckEnabled || !otherResources);
  const title = noResources ? titleNoResources : titleResources;
  const resourcesImg = precheckData.images.resourcesImg;
  const { setSelectedResources, selectedResources, handleResourceChange } =
    handleResourceSelection;

  useEffect(() => {
    sendEvent('Event::SelectResourcesLoaded');
  }, []);

  const handleBtnClick = () => {
    const comment = Object.keys(selectedResources)
      .map((text) => `- ${_.startCase(text)}`)
      .join('\n');
    sendEvent('Event::SelectResourcesCompleted', { comment });
    handleNext();
  };

  const handleSkipBtnClick = () => {
    sendEvent('Event::ResourceCheckSkipped');
    setSelectedResources({});
    handleNext();
  };

  const handleBackBtnClick = () => {
    sendEvent('Event::ResourceSelectionBack', {});
    handlePrevious();
  };

  const buttonObjects = [
    {
      buttonText: backButtonText,
      handleClick: handleBackBtnClick,
      buttonStyleClass: 'btn-precheck-secondary',
    },
    {
      buttonText: buttonText,
      handleClick: handleBtnClick,
      buttonStyleClass: 'btn-precheck-primary',
      disabled: Object.values(selectedResources).every(
        (bool) => bool === false,
      ),
    },
    {
      buttonText: skipButtonText,
      handleClick: handleSkipBtnClick,
      buttonStyleClass: 'btn-precheck-secondary',
    },
  ];

  const renderResourceGrid = () => {
    if (!noResources) {
      return (
        <div className="form-group text-left js-resource-switch pt-3">
          {!precheckData.emptyResources && (
            <div
              className="card-columns"
              role="group"
              aria-labelledby="prechecks-body-title"
            >
              {Object.entries(allowedResources).map(
                ([resourceCategory, resources]) => {
                  return resources.map((resource) => {
                    const resourceId = _.uniqueId('resource-');

                    return (
                      <div className="col mb-2" key={`resources_${resource}`}>
                        <div className={`card h-100`}>
                          <div className="precheck-resource-card card-body d-flex align-items-center justify-content-between">
                            <p className="m-0" id={resourceId}>
                              {resource}
                            </p>
                            <ResourceCheckBox
                              resourceId={resourceId}
                              selectedResources={selectedResources}
                              handleResourceChange={handleResourceChange}
                              resource={resource}
                              resourceCategory={resourceCategory}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  });
                },
              )}
            </div>
          )}
          {otherResources && otherResourcesCheckEnabled && (
            <div className="row m-0">
              <div className="col mb-3">
                <div className={`card h-100`}>
                  <div className="precheck-resource-card card-body d-flex justify-content-between">
                    <div className="w-75">
                      <h6 className="card-title">
                        {polyglot.t(
                          'prechecks_guidedlaunch_select_resources_other_resources',
                        )}
                      </h6>
                      <p
                        className="m-0 pt-2 gl-other-resources-text"
                        id={_.uniqueId('resource-')}
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            precheckData.otherResources,
                          ),
                        }}
                      />
                    </div>
                    <ResourceCheckBox
                      resourceId={_.uniqueId('resource-')}
                      selectedResources={selectedResources}
                      handleResourceChange={handleResourceChange}
                      resource={OTHER_RESOURCES}
                      resourceCategory={OTHER_RESOURCES}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    }
  };

  return (
    <div className="container-fluid text-center" id={id}>
      <PrechecksHeaderGroup
        title={title}
        image={resourcesImg}
        icon={icon}
        iconPrefix={iconPrefix}
      />
      <PrechecksBodyText bodyText={body} />
      {renderResourceGrid()}
      <PrechecksFooterButtonGroup buttonProps={buttonObjects} />
    </div>
  );
};

/**
 * @typedef ResourceCheckBoxProps
 * @property {string} resourceId - Unique identifier for the resource checkbox.
 * @property {Record<string, boolean>} selectedResources - Object containing the current selected resources.
 * @property {HandleResourceChangeFunction} handleResourceChange - Function to handle changes in resource selection.
 * @property {string} resource - The name of the resource associated with this checkbox.
 * @property {string} resourceCategory - The category of the resource associated with this checkbox.
 */

/**
 * @param {ResourceCheckBoxProps} props
 * @returns {React.ReactElement}
 */
const ResourceCheckBox = ({
  resourceId,
  selectedResources,
  handleResourceChange,
  resource,
  resourceCategory,
}) => {
  const isSelected = selectedResources[resource] || false;

  return (
    <input
      type="checkbox"
      aria-labelledby={resourceId}
      className={`mr-2 precheck-primary-checkbox ${
        isSelected ? 'checked' : ''
      }`}
      checked={isSelected}
      onChange={(event) =>
        handleResourceChange(event, resource, resourceCategory)
      }
    />
  );
};

GuidedLaunchSelectResources.defaultProps = {
  titleResources: polyglot.t('prechecks_guidedlaunch_select_resources_title'),
  titleNoResources: polyglot.t('prechecks_no_allowed_resources'),
  body: polyglot.t('prechecks_guidedlaunch_select_resources_body'),
  icon: 'fa-pen-alt',
  iconPrefix: 'fal',
  statusColor: 'primary',
  buttonText: polyglot.t('prechecks_guidedlaunch_select_resources_next_button'),
  skipButtonText: polyglot.t(
    'prechecks_guidedlaunch_select_resources_skip_button',
  ),
  backButtonText: polyglot.t(
    'prechecks_guidedlaunch_select_resources_back_button',
  ),
};

export default GuidedLaunchSelectResources;
