import axios from 'axios';
import csrfToken from '../utils/csrf';
import Toast from '../utils/Toast';

class Escalator {
  constructor() {
    this.COMPONENT_SELECTOR = '.escalation-status';
    this.escalations = document.querySelectorAll(this.COMPONENT_SELECTOR);
  }

  init() {
    this.bindEventListeners();

  }

  bindEventListeners() {
    this.escalations.forEach((escalation) => {
      escalation.addEventListener('change', (event) => {
        this.handleChange(escalation)
      });

      escalation.addEventListener('focus', (event) => {
        this.handleFocus(escalation);
      });
    })
  }

  handleFocus(escalation) {
    escalation.setAttribute('data-prev-index', escalation.selectedIndex);
  }

  handleChange(escalation) {
    const params = {
      status: escalation.options[escalation.selectedIndex].value
    };
    const headers = {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken()
      }
    }
    axios.put(escalation.getAttribute('data-url'), JSON.stringify(params), headers)
    .then(response => {
      new Toast().success({message: 'Escalation status has changed.'});
      escalation.parentNode.classList.remove('is-invalid');
    })
    .catch(error => {
      if (error?.response?.status == 403) {
        new Toast().danger({message: 'Insufficient user privileges to change Escalation status'});
      } else {
        new Toast().danger({message: 'Unable to change escalation status'});
      }
      escalation.parentNode.classList.add('is-invalid');
      escalation.selectedIndex = escalation.getAttribute('data-prev-index');
    });
  }
}

export default Escalator;
