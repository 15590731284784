import moment from 'moment';
import getMoment from './shared/getMoment';
import getDate from './shared/getDate';
import getDateTime from './shared/getDateTime';
import minDate from './shared/minDate';
import maxDate from './shared/maxDate';

class DatePicker {
  constructor() {
    this.datePicker = $('#reservation-date-picker');
    this.date = getDate(this.datePicker.attr('data-date'));
    this.minDate = minDate(this.datePicker.attr('data-min'));
    this.maxDate = maxDate(this.datePicker.attr('data-max'));
  }

  init() {
    this.datePicker.datetimepicker(this.getDateTimePickerConfig()).on('dp.change', (e) => {
      this.checkDisabledTimeIntervals()
    })
    $('.datepicker .datepicker-days table').addClass('table');
  }

  checkDisabledTimeIntervals() {
    const timePicker = $('#reservation-time-picker')
    let date = getDateTime();
    $.each(timePicker.data('disabled-time-intervals'), function(i, v) {
      let end, start;
      start = getMoment(v[0]);
      end = getMoment(v[1]);
      if (date.isBetween(start, end)) {
        date = end;
        return false;
      }
    });
    return timePicker.data('DateTimePicker').date(date);
  };

  getDateTimePickerConfig() {
    const userLang = document.querySelectorAll("[data-user-lang]");

    let config = {
      defaultDate: this.date,
      minDate: this.minDate,
      maxDate: this.maxDate,
      inline: true,
      icons: {
        next: 'fa fa-arrow-right',
        previous: 'fa fa-arrow-left'
      },
      format: 'DD/MM/YYYY',
    };

    if (userLang.length > 0) {
      config.locale = userLang[0].dataset['userLang'];
    }

    return config;
  };
}

 export default DatePicker;
