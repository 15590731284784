class Logger {
  constructor() {
    if (window.Sentry) {
      this.sentry = window.Sentry;
      this.context = {};
    }
  }

  info(message) {
    if (this.sentry) {
      this.sentry.addBreadcrumb({
        category: 'info',
        message
      });
    }
  }

  error(message, error) {
    if (this.sentry) {
      this.info(message);
      this.sentry.captureException(error);
    }
  }

  captureMessage(message) {
    if (this.sentry) {
      this.info(message)
      this.sentry.captureMessage(message)
    }
  }

  setContext(context) {
    if (this.sentry) {
      this.sentry.configureScope(scope => scope.setUser({id : context.userId}))
      this.sentry.setTag(context);
    }
  }
}

export default Logger;
