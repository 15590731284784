import { useReducer, createContext } from 'react';

const ADD_WINDOW = 'ADD_WINDOW';
const UPDATE_WINDOW = 'UPDATE_WINDOW';
const DELETE_WINDOW = 'DELETE_WINDOW';

const REDUCER = (state, { type, payload }) => {
  switch (type) {
    case ADD_WINDOW:
      const length = Object.keys(state.windows).length;

      const addState =  { ...state, windows: { ...state.windows, [length + 1]: payload }};

      return addState
    case UPDATE_WINDOW:
      const { name, window } = payload;

      const updateState =  { ...state, windows: { ...state.windows, [name]: window } };
      storeWindowData({starts_at: window.startsAt, ends_at: window.endsAt, name: payload.name});

      return updateState
    case DELETE_WINDOW:
      const windows = { ...state.windows };
      delete windows[payload];

      const deleteState = { ...state, windows };

      removeWindowData(payload.name);

      return deleteState;
    default:
      console.error('Invalid action: ', type);
      return state;
  }
};

const storeWindowData = ({starts_at, ends_at, name}) => {
  let sessionWindows = [];
  if (sessionStorage.getItem("appointmentWindows")) {
    sessionWindows = JSON.parse(sessionStorage.getItem("appointmentWindows"));
  }
  const windowFound = sessionWindows.find(({name: sName}) => sName == name);
  if (windowFound) {
    windowFound.starts_at = starts_at;
    windowFound.ends_at = ends_at;
  } else {
    sessionWindows.push({starts_at, ends_at, name});
  }
  if (sessionWindows.length > 0) {
    sessionStorage.setItem("appointmentWindows", JSON.stringify(sessionWindows));
  }
}

const removeWindowData = (name) => {
  let sessionWindows;
  if (sessionStorage.getItem("appointmentWindows")) {
    sessionWindows = JSON.parse(sessionStorage.getItem("appointmentWindows"));
    const idx = sessionWindows.findIndex(({name: sName}) => sName == name);
    sessionWindows.splice(idx, 1);
    sessionStorage.setItem("appointmentWindows", JSON.stringify(sessionWindows));
  }
}

const processedWindowsData = (windowsData) =>  Array.isArray(windowsData) ? windowsData : JSON.parse(windowsData);

const UseAppointmentWizard = (mountpoint, form, previousStepUrl, examPickerUrl, isNewExam, handleChangeElement) => {
  let formWindows = {};
  if (form.windows) {
    let newWindows = {};
    if (Array.isArray(form.windows) || Array.isArray(JSON.parse(form.windows))) {
      processedWindowsData(form.windows).map(({name = '', ends_at, starts_at}, key) => {
        if (starts_at && ends_at) {
          newWindows[key] = {
            'startsAt': starts_at,
            'endsAt': ends_at,
            'name': name || key,
            'readOnly': true,
          };
          storeWindowData({starts_at: starts_at, ends_at: ends_at, name: name || key});
        }
      });
    } else {
      Object.keys(form.windows).forEach((key) => {
        const obj = form.windows[key]
        if (obj['starts_at'] && obj['ends_at']) {
          newWindows[key] = {
            'startsAt': obj['starts_at'],
            'endsAt': obj['ends_at'] || obj['endsAt'],
            'name': obj['name'] || key,
            'readOnly': true,
          };
          storeWindowData({starts_at: obj['starts_at'] || obj['startsAt'], ends_at: obj['ends_at'] || obj['endsAt'], name: obj['name']});
        }
      });
    }
    formWindows = newWindows
  }

  const initialState = {
    windows: formWindows,
    form,
    mountpoint,
    previousStepUrl,
    examPickerUrl,
    isNewExam,
  };

  const [state, dispatch] = useReducer(REDUCER, initialState);
  const { windows } = state;

  const windowsAvailable = !!Object.keys(windows).length;

  const addAppointmentWindow = (window = {}) => {
    window.startsAt ||= null;
    window.endsAt ||= null;
    window.readOnly = false;
    storeWindowData({starts_at: window.startsAt, ends_at: window.endsAt , name: Object.keys(windows).length+1});
    dispatch({ type: ADD_WINDOW, payload: window });
    handleChangeElement(false, false);
  };

  const updateAppointmentWindow = (name, window) => {
    dispatch({ type: UPDATE_WINDOW, payload: { name, window } });
  };

  const deleteAppointmentWindow = (name) => {
    dispatch({ type: DELETE_WINDOW, payload: name });
    if (Object.keys(windows).length === 1) {
      handleChangeElement(false, false);
    }
  };

  const truncate = (num) => {
    const regex = new RegExp('^-?\\d+(?:.\\d{2})?');
    const match = num.toString().match(regex);

    if (match !== null) {
      return Number(match[0]);
    } else {
      return 0;
    }
  };

  let durationOfHoursInAllWindows = truncate(

    Object.keys(windows).reduce((sum, name) => {
      const window = windows[name];
      if (window.startsAt === null || window.endsAt === null) {
        return 0;
      }

      return sum + (new Date(window.endsAt) - new Date(window.startsAt)) / 36e5;
    }, 0)
  );

  const areWindowsValid = () => {
    if (durationOfHoursInAllWindows === 0) {
      return false;
    }
    const valid = form.iteration_expected_test_takers / Math.ceil(durationOfHoursInAllWindows) <= 300;
    if (valid) {
      handleChangeElement(true, false);
    }
    return valid;
  };

  return {
    ...state,
    areWindowsValid,
    addAppointmentWindow,
    updateAppointmentWindow,
    deleteAppointmentWindow,
    durationOfHoursInAllWindows,
    windowsAvailable,
  };
};

export const Context = createContext({});

export default UseAppointmentWizard;
