import Toast from '../../../utils/Toast'

class Checkboxes {
  constructor() {
    this.iterationServiceTypeList = document.querySelector('#iteration_service_type_list');
    this.checkboxes = document.querySelectorAll('input[type=checkbox]');
  }

  init() {
    if(this.iterationServiceTypeList) {
      this.checkboxes.forEach(checkbox => {
        checkbox.addEventListener('change', (event) => { this.handleChange(event) });
      })
    }
  }

  handleChange(event) {
    const checkbox = event.target;
    const checkboxElements = [
      'iteration_exam_attributes_override_throttles',
      'iteration_on_demand_scheduling',
      'iteration_disable_verification_step'
    ];

    const select = this.iterationServiceTypeList;
    const selectedServiceType = select.options[select.selectedIndex];
    const isUnintegratedAuto = selectedServiceType && selectedServiceType.value === 'unintegrated-auto';

    if(isUnintegratedAuto && checkboxElements.indexOf(checkbox.id) > -1) {
      if(!checkbox.checked) {
        checkbox.checked = true;
        new Toast().warning({
          message: polyglot.t('unintegrated_auto_checkbox_warning'),
          replaceable: true, timeout: 15000
        });
      }
    }
  }
}

export default Checkboxes;
