import getRequestParams from '../utils/getRequestParams';

class EscalateButton {
  constructor() {
    this.escalateBtn = document.querySelector('[data-btn-selector="escalate"]');
  }

  init() {
    if (this.escalateBtn) {
      this.bindEventListeners();
    }
  }

  bindEventListeners() {
    this.escalateBtn.addEventListener('click', this.sendRequest.bind(this));
  }

  sendRequest(event) {
    if (!confirm(event.target.getAttribute('data-confirmation'))) { return; }

    fetch(event.target.getAttribute('data-url'), getRequestParams({}, 'PUT'))
      .then(() => this.escalateBtn.classList.add('d-none'))
      .catch(() => alert(event.target.getAttribute('data-error')));
  }
}

export default EscalateButton;
