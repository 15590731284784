import { useEffect, useState } from 'react';

import BiometricsConsentModal from '../BiometricsConsentModal';
import PrechecksFooterButton from '../CardSections/PrechecksFooterButton';
import WelcomeHeader from './WelcomeHeader';

/**
 * @typedef WelcomeCardProps
 * @property {string} id
 * @property {string} title
 * @property {string} buttonText
 * @property {() => void} handleNext
 * @property {SendEventFunction} sendEvent
 * @property {PrecheckData} precheckData
 */

/**
 * @param {WelcomeCardProps} props
 * @returns {React.ReactElement}
 */
const WelcomeCard = ({
  id,
  title,
  buttonText,
  handleNext,
  sendEvent,
  precheckData,
}) => {
  const [isBiometricsConsentModalShown, setIsBiometricsConsentModalShown] =
    useState(false);

  useEffect(() => {
    sendEvent('Event::Welcome');
    if (precheckData?.livePlus) {
      sendEvent('Event::LivePlusRecordingMethod');
    }
  }, []);

  const handleFooterButtonClick = () => {
    sendEvent('Event::GetStarted');
    if (precheckData.facialComparisonEnabled) {
      setIsBiometricsConsentModalShown(true);
      return;
    }
    handleNext();
  };

  const onBiometricsConsentReceived = () => {
    handleNext();
  };

  return (
    <div
      className="container-fluid text-center precheck-card-content position-relative"
      id={id}
    >
      <WelcomeHeader title={title} precheckData={precheckData} />

      <PrechecksFooterButton
        buttonText={buttonText}
        handleClick={handleFooterButtonClick}
      />

      <BiometricsConsentModal
        show={isBiometricsConsentModalShown}
        onHide={() => setIsBiometricsConsentModalShown(false)}
        onAgree={onBiometricsConsentReceived}
      />
    </div>
  );
};

WelcomeCard.defaultProps = {
  statusColor: 'primary',
  title: polyglot.t('prechecks_welcome_title'),
  bodyText: polyglot.t('prechecks_welcome_body_record_plus'),
  buttonText: polyglot.t('prechecks_welcome_button_record_plus'),
};

export default WelcomeCard;
