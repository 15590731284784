import React, { useState, useEffect } from 'react';

import { LMI_STATUS } from './lib/lmiUtils';
import TestUi from './TestUi';

const FAILURE_TIMEOUT = 30_000;

/**
 * @typedef {Object} MessageEvent
 * @property {string} data
 * @property {string} origin
 */

/**
 * @typedef {Object} GuardianLog
 * @property {string} url
 * @property {string} message
 */

/**
 * An application that tests the LMI chat widget is functioning properly.
 *
 * Contains the logic for the LMI chat test application.
 *
 * @returns {React.ReactElement}
 */
export default function App() {
  /**
   * @type {[MessageEvent[], (messages: MessageEvent[]) => void]}
   */
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [status, setStatus] = useState(false);
  const [serverStatus, setServerStatus] = useState(null);
  const [failureReason, setFailureReason] = useState('');

  /**
   * @param {GuardianLog} log
   */
  const handleGuardianLogMessage = (log) => {
    // @type {MessageEvent}
    const event = {
      origin: log?.url ? new URL(log.url).origin : window.location.origin,
      data: {},
    };
    for (const key in log) {
      if (key !== 'url') {
        event.data[key] = log[key];
      }
    }
    handleAddMessage(event);
  };

  /**
   * @param {MessageEvent} event
   */
  const handleAddMessage = (event) => {
    console.log('Message received:', event);
    setMessages((prevMessages) => [...prevMessages, event]);
    handleStatus(event);
  };

  const handleStatus = (event) => {
    setIsLoading(false);
    if (event.data?.hostedErrorName === 'FAILED_TO_LOAD_LMI_CHAT_SCRIPT') {
      setFailureReason(
        'The LMI chat widget failed to load. Refresh the page and try again. If it fails again, the service might be blocked',
      );
      setStatus(LMI_STATUS.FAILURE);
    }
    setStatus(LMI_STATUS.SUCCESS);
  };

  const handleTestStart = () => {
    setIsLoading(true);

    setTimeout(() => {
      if (status === LMI_STATUS.SUCCESS) {
        return;
      }
      setFailureReason(
        'The test has timed out... LMI took too long to respond to the client.',
      );
      setStatus(LMI_STATUS.FAILURE);
      setIsLoading(false);
    }, FAILURE_TIMEOUT);

    if (typeof window.PU_Secure_Browser === 'object') {
      // This will only work in the Guardian browser
      window.PU_Secure_Browser.registerLogListener(handleGuardianLogMessage);
      window.PU_Secure_Browser.testLmiConnection();
    } else {
      // Uncomment me to enable this is a fallback for when we are running in a normal browser
      // openChatWindow();
    }
  };

  // Check with the archimedes server to see if LMI is experiencing an outage
  useEffect(() => {
    (async () => {
      const response = await fetch('/testitout/test_lmi_outage');
      const data = await response.json();

      if (data?.status === 'ok') {
        setServerStatus(LMI_STATUS.SUCCESS);
      } else if (data?.status === 'error') {
        setFailureReason('LMI is likely experiencing an outage.');
        setStatus(LMI_STATUS.FAILURE);
        setServerStatus(LMI_STATUS.FAILURE);
      }
    })();
  }, []);

  // Add an event listener to listen for messages from LMI that might be posted to the parent window
  useEffect(() => {
    const onLmiMessage = (event) => {
      if (event.origin.includes('logmeinrescue.com')) {
        // Do not process messages from anything besides LMI
        handleAddMessage(event);
      }

      return;
    };

    window.addEventListener('message', onLmiMessage);

    return () => {
      window.removeEventListener('message', onLmiMessage);
    };
  }, []);

  return (
    <TestUi
      isLoading={isLoading}
      handleTestStart={handleTestStart}
      messages={messages}
      status={status}
      serverStatus={serverStatus}
      failureReason={failureReason}
    />
  );
}
