// LiveChat
//
// This class handles the LiveChat widget for student views
class LiveChat {
  constructor() {
    this.liveChatData = document.querySelector('[data-element="live-chat"]');
    if (this.liveChatData) {
      let dataset = this.liveChatData.dataset;
      this.liveChatApiCode = dataset['apiCode'];
      this.liveChatGroupId = dataset['groupId'];
      this.liveChatName = dataset['userName'];
      this.liveChatEmail = dataset['userEmail'];
    }
    this.setupLiveChat = this.setupLiveChat.bind(this);
  }

  init() {
    if (this.liveChatApiCode) {
      this.setupLiveChat();
    }
  }

  setupLiveChat() {
    window.__lc = {visitor: {}};
    window.__lc.license = this.liveChatApiCode;
    if (this.liveChatGroupId) {
      window.__lc.group = this.liveChatGroupId;
    }

    if (this.liveChatName) {
      window.__lc.visitor.name = this.liveChatName;
    }

    if (this.liveChatEmail) {
      window.__lc.visitor.email = this.liveChatEmail;
    }
    // Deletes taken from original 2015 file, assuming this is important
    delete(window.LC_API);
    delete(window.LC_Invite);
    delete(window.__lc_iframe_current_skill);
    delete(window.__lc_inited);
    delete(window.__lc_lang);
    //

    let lc = document.createElement("script");
    lc.type = "text/javascript";
    lc.async = true;
    lc.src = `${document.location.protocol}//cdn.livechatinc.com/tracking.js`;
    let s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(lc, s);
  }
}

export default LiveChat
