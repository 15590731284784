import Toast from '../../../utils/Toast';

// Specific to Cirrus
class TestDeliveryIdValidator {
  constructor() {
    this.validatorButton = document.querySelector(
      '[data-element="test-delivery-validator"]'
    );
    this.testDeliveryID = document.querySelector(
      '[name="iteration[test_delivery_fields_hash][test_delivery_id]"]'
    );
    this.handleValidate = this.handleValidate.bind(this);
  }

  init() {
    if(this.validatorButton) {
      this.bindEventListeners();
    }
  }

  bindEventListeners() {
    this.validatorButton.addEventListener('click', this.handleValidate);
  }

  handleValidate(event) {
    event.preventDefault();
    const url = event.target.dataset.url;
    $.ajax({
      type: 'GET',
      url: url,
      contentType: 'application/json',
      data: { 'platform': 'cirrus', 'test_delivery_id': this.testDeliveryID.value, type: 'id' },
      success: (response) => this.handleSuccess(response),
      error: (response) =>this.handleError(response)
    });
  }

  handleSuccess(data) {
    new Toast().success({
      message: data['message']
    });
  }

  handleError(data) {
    new Toast().danger({
      message: data.responseJSON['message']
    });
  }
}

export default TestDeliveryIdValidator;
