const PaymentsIframe = ({ id }) => {
  return (
    <div
      id={id}
      style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0 }}
    >
      <iframe
        src="/students/order"
        frameBorder="0"
        height={600}
        width={1000}
        title="Payments iframe"
        name="payments-iframe"
        loading="eager"
        id="payments-iframe"
      />
    </div>
  );
};

export default PaymentsIframe;
