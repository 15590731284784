class FormLegendNavigation {
  constructor() {
    let COMPONENT_SELECTOR = '[data-component=institution-form-legend]';
    this.$listItems = $(this.COMPONENT_SELECTOR)
      .find('.list-form-nav-item:not(.list-form-nav-item-disabled)');
    this.$errors = $('.is-invalid');
  }

  init() {
    this.errorExists = this.errorExists();
    this.renderFormErrors();
  }

  // Rendering Form Errors
  renderFormErrors() {
    if (! this.$errors.length) {
      return;
    }

    this.appendFormError();
    this.setActiveListItemWithError();
  }

  appendFormError() {
    this.$errors.map((index, element) => {
      const tabName = $(element).closest('.js-form').data('tab');

      if (this.errorExists(tabName)) {
        return;
      }

      $(`.js-${tabName}`).append(this.errorHtml());
    });
  }

  errorExists(tabName) {
    return $(`.js-${tabName}`).find('.js-error-tab').length;
  }

  errorHtml() {
    return `<span class="institution-error fa fa-exclamation-circle js-error-tab"></span>`;
  }

  setActiveListItemWithError() {
    const $activeTab = $('.js-error-tab').first().parent();
    const tabName = $activeTab.data('tab');

    $activeTab.tab('show');
    $('[data-tab=general]').removeClass('active');
    $(`[data-tab=${tabName}]`).addClass('active');
  }
}

export default FormLegendNavigation;
