import startCase from 'lodash/startCase';
import DOMPurify from 'dompurify';
import { handleScratchPaperTextTranslation } from '../../../src/utils/ScratchPaperResourceTranslationHelper';

/**
 * @typedef WelcomeAllowedResourcesProps
 * @property {{ [resourceType: string]: string[] }} allowedResources
 * @property {boolean} noResourcesAllowed
 * @property {string} otherResources
 */
/**
 * @param {WelcomeAllowedResourcesProps} props
 * @returns {React.ReactElement}
 */
const WelcomeAllowedResources = ({
  allowedResources,
  noResourcesAllowed,
  otherResources,
}) => {
  const categories = Object.keys(allowedResources).sort();

  /** @type {React.CSSProperties} */
  const gridStyles = {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 45%)',
    gridGap: '2rem',
    justifyContent: 'center',
    marginTop: '1rem',
  };

  const getOtherResources = () => ({
    __html: DOMPurify.sanitize(otherResources),
  });

  return (
    <>
      {noResourcesAllowed && (
        <p className="mt-2 ml-4 text-left">
          {polyglot.t(
            'prechecks_welcome_allowed_resources_no_allowed_resources',
          )}
        </p>
      )}

      {!noResourcesAllowed &&
        (categories.length === 0 && !otherResources ? (
          <p className="mt-2 ml-4 text-left">
            {polyglot.t(
              'prechecks_welcome_allowed_resources_external_allowed_resources',
            )}
          </p>
        ) : (
          <div style={gridStyles}>
            {categories.map((category) => (
              <div key={category} className="card text-left">
                <div className="card-body">
                  <h4>
                    {startCase(handleScratchPaperTextTranslation(category))}
                  </h4>
                  <ul className="mb-0">
                    {allowedResources[category].map((resource, i) => (
                      <li key={i}>{resource}</li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
            {otherResources && (
              <div className="card text-left" style={{ gridColumn: '1 / 3' }}>
                <div className="card-body">
                  <h4>{polyglot.t('prechecks_allowed_resources_other')}</h4>
                  <p dangerouslySetInnerHTML={getOtherResources()}></p>
                </div>
              </div>
            )}
          </div>
        ))}
    </>
  );
};

WelcomeAllowedResources.propTypes = {
  allowedResources: PropTypes.object.isRequired,
  noResourcesAllowed: PropTypes.bool.isRequired,
};

WelcomeAllowedResources.defaultProps = {
  allowedResources: {},
  noResourcesAllowed: false,
  otherResources: '',
};

export default WelcomeAllowedResources;
