class BrightspaceIntegration {
  constructor() {
    const url = new URL(location.href);
    this.userId = url.searchParams.get('x_a');
    this.userKey = url.searchParams.get('x_b');
    this.appId = $('.app-id').html();
    this.appKey = $('.app-key').html();
    this.baseUri = $('.base-uri').html();
    this.redirectUri = $('.redirect-uri').html();
  }

  init() {
    if (this.appKey != null) {
      $.ajax({
        type: 'PUT',
        url: this.redirectUri,
        data: {
          brightspace_integration: {
            app_id: this.appId,
            app_key: this.appKey,
            x_a: this.userId,
            x_b: this.userKey,
            base_uri: this.baseUri,
            redirect_uri: this.redirectUri
          }
        }
      });
    }
  }
};

export default BrightspaceIntegration;
