class GenericSelect2 {
  constructor() {
    this.select2 = 'makeS2';
  }

  init() {
    $('.makeS2').each(function () {
      const selectElement = $(this);
      const labelId = selectElement.attr('aria-labelledby');
      const placeholder = selectElement.data('placeholder');

      selectElement.select2({
        placeholder: placeholder,
        theme: 'bootstrap4',
        width: 'auto',
      });

      const select2Container = selectElement.next('.select2-container');
      select2Container.attr('aria-labelledby', labelId);

      const ariaLabelledBy = selectElement
        .closest('form')
        .find(`label[for='${selectElement.attr('id')}']`)
        .attr('id');
      select2Container
        .find('.select2-selection')
        .attr('aria-labelledby', ariaLabelledBy);
    });
  }
}

export default GenericSelect2;
