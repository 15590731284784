// For the Bootstrap datetimepicker located on a Schedules index
//
// Handles initializing the datetimepicker and making sure it modifies the URL
// correctly so the user can select dates and have the page refresh accordingly
//
class DatePicker {
  constructor() {
    // the main datetimepicker element
    // must be jquery as this is a bootstrap-jquery element
    this.$scheduleDatePicker = $('#schedule-date-picker');
  }

  init() {
    this.initDatePicker();
  }

  // initializes the datetimepicker with correct date formats and iconography
  initDatePicker() {
    this.$scheduleDatePicker.datetimepicker({
      defaultDate: moment(
        $('#schedule-date-picker').data('date'),
        'YYYY MM DD'
      ),
      icons: {
        next: 'fa fa-arrow-right',
        previous: 'fa fa-arrow-left'
      }
    });

    // replaces the date param in the url with the selected date in YYYY-MM-DD format
    this.$scheduleDatePicker.on('dp.change', (e) => {
      const stem = location.pathname.match(/(.*\/schedule)/)[1];
      const date = '$1' + e.date.format('YYYY-MM-DD');
      const dateParam = location.search.replace(/(date=)[^\&]+/, date);
      location.href = [stem, dateParam].join('');
    });
  }
}

export default DatePicker;
