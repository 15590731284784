import Cost from './Cost';
import ServiceLine from './ServiceLine';
import Split from './Split';
import setIterationType from './utils/setIterationType';

class Department {
  constructor() {
    this.iterationTypeSelector =
      document.querySelector('[data-element="iteration-types"]') ||
      document.querySelector('[data-element="iteration-type-hidden"]');
    this.iterationServiceTypeList = document.querySelector('#iteration_service_type_list');
    this.iterationDepartment = document.querySelector('[data-element="iteration-department"]');
    this.enableGuardianBrowser = document.querySelector('[data-element="enable-guardian-browser"]');
  }

  init() {
    if (this.iterationDepartment) {
      this.iterationDepartment.addEventListener('change', () => { this.handleDepartmentChange() }
      )
    }
    // Force the department change event to account for Instructors that have a
    // department added to their account.
    if (this.isCreating && document.querySelector('[data-staff]').dataset.staff === 'false') {
      this.handleDepartmentChange();
    }
  }

  handleDepartmentChange() {
    if (!this.iterationDepartment || !this.iterationTypeSelector) {
      return;
    }
    const select = this.iterationDepartment;
    const selectedDepartment = select.options[select.selectedIndex];
    const iterationType = selectedDepartment.getAttribute('data-default-iteration-type');
    const serviceType = selectedDepartment.getAttribute('data-default-service-type');
    this.setServiceTypeList(serviceType);
    setIterationType(iterationType);
    this.changeCostAndSplit();
    this.setAnnualFee();
    this.setGuardianWorkflow(selectedDepartment.getAttribute('data-guardian-workflow'));
  }

  setGuardianWorkflow(guardianWorkflowRequired) {
    if (this.enableGuardianBrowser != null) {
      if (guardianWorkflowRequired == 'true') {
        this.enableGuardianBrowser.checked = true;
        this.enableGuardianBrowser.disabled = true;
      } else {
        this.enableGuardianBrowser.disabled = false;
      }
    }
  }

  setServiceTypeList(serviceType) {
    if (!this.iterationServiceTypeList || !serviceType) {
      return;
    }
    const select = this.iterationServiceTypeList;
    select.value = serviceType;
    this.iterationServiceTypeList.dispatchEvent(new Event('change'));
  }

  changeCostAndSplit() {
    if (document.querySelector('[data-staff]').dataset.staff === 'false') {
      new Cost().setCost()
      new Split().setSplit()
    } else {
      const confirmChange = confirm("Would you like to set the cost and split to the department's defaults?")
      if (confirmChange) {
        new Cost().setCost()
        new Split().setSplit()
      }
    }
  }

  isCreating() {
    document.body.dataset.page === 'institutions:iterations:new';
  }

  setAnnualFee() {
    const annualFeeData = document.querySelector(
      '#iteration_exam_attributes_department_id > option:checked'
    ).dataset;
    const annualFeeEnabled = document.querySelector(
      '#iteration_annual_fee_attributes_annual_fee'
    );
    const studentPayModel = document.querySelector(
      '#iteration_annual_fee_attributes_pay_model_student'
    );
    const institutionPayModel = document.querySelector(
      '#iteration_annual_fee_attributes_pay_model_institution'
    );
    const annualFeeSuspended = document.querySelector(
      '#iteration_annual_fee_attributes_suspended'
    );
    const parentId = document.querySelector(
      '#iteration_annual_fee_attributes_parent_id'
    );

    const {
      annualFee,
      annualFeeCostId,
      annualPayModel,
      annualSuspended,
      annualParentId
    } = annualFeeData;

    if (annualFee !== undefined && annualFeeEnabled !== null) {
      const costId = document.querySelector(
        `#iteration_annual_fee_attributes_cost_id option[value="${annualFeeCostId}"]`
      );

      if (annualFee !== annualFeeEnabled.checked.toString()) {
        annualFeeEnabled.click();
      }

      if (annualFeeEnabled.checked) {
        const isStudentPay = annualPayModel == 'student';
        const isAnnualSuspended = annualSuspended == 'true'

        annualFeeSuspended.checked = isAnnualSuspended;
        costId.selected = true;
        parentId.value = annualParentId;
        studentPayModel.checked = isStudentPay;
        institutionPayModel.checked = !isStudentPay;
      }
    }
  }
}

export default Department;
