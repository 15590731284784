import React, { useState } from 'react';

/**
 * @typedef ExamDetailsAccordionProps
 * @property {Fulfillment} fulfillment
 */

/**
 * @param {ExamDetailsAccordionProps} props
 * @returns {React.ReactElement}
 */
export default function ExamDetailsAccordion(props) {
  const [isOpen, setIsOpen] = useState(false);

  const onToggle = () => setIsOpen(!isOpen);

  return (
    <>
      <div className="text-left">
        <button
          className="font-weight-bold"
          onClick={onToggle}
          aria-expanded={isOpen}
          aria-controls="exam-details-content"
          style={{
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            padding: 0,
          }}
        >
          {isOpen ? (
            <>
              {polyglot.t('order_receipt_hide_details')}
              <i className="ml-2 fa-solid fa-chevron-down" />
            </>
          ) : (
            <>
              {polyglot.t('order_receipt_see_details')}
              <i className="ml-2 fa-solid fa-chevron-right" />
            </>
          )}
        </button>
      </div>

      {isOpen && (
        <div
          id="exam-details-content"
          className="text-muted"
          style={{
            display: 'grid',
            gridTemplateColumns: '1.5rem auto',
            gap: '0.25rem',
            marginLeft: '-0.25rem',
          }}
        >
          {props.fulfillment.startDate && props.fulfillment.startTime && (
            <>
              <div className="text-center">
                <i className="fas fa-calendar-alt"></i>
              </div>
              <div>
                {props.fulfillment.startDate}
                {' @ '}
                {props.fulfillment.startTime}
              </div>
            </>
          )}

          {props.fulfillment.duration && (
            <>
              <div className="text-center">
                <i className="fa-regular fa-watch"></i>
              </div>
              <div>{props.fulfillment.duration}</div>
            </>
          )}

          {props.fulfillment.term && (
            <>
              <div className="text-center">
                <i className="fas fa-circle"></i>
              </div>
              <div>{props.fulfillment.term}</div>
            </>
          )}

          {props.fulfillment.institution && (
            <>
              <div className="text-center">
                <i className="fas fa-university"></i>
              </div>
              <div>{props.fulfillment.institution}</div>
            </>
          )}
        </div>
      )}
    </>
  );
}
