class OverrideRescheduleLeadTime {
  constructor() {
    this.checkbox = document.querySelector(
      '[data-behavior="override-reschedule-lead-time"]'
    );
    this.groupDiv = document.querySelector(
      '[data-behavior="override-reschedule-lead-time-group"]'
    );
    this.toggleOverride = this.toggleOverride.bind(this);
  }

  init() {
    if (this.checkbox) {
      this.checkbox.addEventListener('change', this.toggleOverride);
      this.toggleOverride();
    }
  }

  toggleOverride() {
    this.groupDiv.style.display = this.checkbox.checked ? 'block' : 'none';
  }
}

export default OverrideRescheduleLeadTime;
