// Iteration Template Loader
//
// The loader executes 5 core actions.
// - Listen for the user to choose an IterationTemplate.
// - Fetch the data for that template from the server.
// - Uncheck all current checkboxes to get a clean slate.
// - Update all checkboxes with their appropriate values from the template.
// - Update all other fields with their appropriate values from the template.
class TemplateLoader {
  constructor() {
    this.COMPONENT_SELECTOR = '[data-behavior=load-iteration-template]';
    this.$templateLoader = $(this.COMPONENT_SELECTOR);
    this.institutionId = window.location.pathname.split('/')[2];
    this.$checkBoxes = $('.js-allowed-resources input[id^="iteration_"]');
    this.fieldsArray = [
      'notes_html',
      'notify_on_schedule_emails',
      'contact_name',
      'contact_email',
      'contact_phone'
    ];
  }

  init() {
    this.$templateLoader.on('change', (e) => {
      this.fetchTemplate(e, this.renderTemplate.bind(this));
    });
  }

  // Returns the URL to make the JSON request to.
  // @param{iterationTemplateId} - Integer
  //
  // Returns a String.
  url(iterationTemplateId) {
    return [
      '/institutions',
      this.institutionId,
      'iteration-templates',
      `${iterationTemplateId}.json`
    ].join('/');
  }

  // Send a request to valid URL and store template data.
  // @param{e} - Event
  // @param{callback} - Callback function to run after JSON is returned.
  fetchTemplate(e, callback) {
    const templateId = $(e.currentTarget).val();

    // Check if the templateId is `nil`. Return if so.
    if (this.hasInvalidTemplateId(templateId)) {
      return;
    }

    $.getJSON(this.url(templateId), (response) => {
      this.processResponse(response)
    }).then(callback);
  }

  // Checks to see if the given argument's length is less than 1.
  // @param{templateId} - Integer
  //
  // Returns a Boolean.
  hasInvalidTemplateId(templateId) {
    return templateId.length < 1;
  }

  // Set the variable `templateData` equal to the returned JSON.
  processResponse(response) {
    this.templateData = response;
  }

  // Once the data has been returned from the server, render it
  // onto the page.
  renderTemplate() {
    this.uncheckCheckboxes();
    this.setDataFields();
    this.setFields();
  }

  // Before we update the checkboxes, uncheck/unselect all of them
  // to get a clean slate.
  uncheckCheckboxes() {
    this.$checkBoxes.prop('checked', false)
      .closest('.checkbox')
      .removeClass('selected');
  }

  // Set all the fields that are stored in hstore, `data`, column.
  setDataFields() {
    const data = this.templateData.data;

    _.each(data, (value, field) => {
      const $field = $(`#iteration_${field}`);

      // First check to see if field is `other_resources`. With some older
      // iterations, we used to save a value called `other_resources`
      // as a data column. Without explicity selecting the textarea,
      // the textarea value won't get set.
      if (field === 'other_resources') {
        try {
          $('#iteration_other_resources').summernote('code', value);
          } catch (err) {
            $('#iteration_other_resources').val(value);
          }
      } else if ($field.prop('type') === 'checkbox') {
        $field.prop('checked', value === 'true' || value === '1')
          .addClass('selected');
      } else {
        $field.val(value);
      }
    });
  }

  // Set all of the other fields that are not part of the hstore, `data` column.
  setFields() {
    const data = this.fieldsArray;

    _.each(data, (field) => {
      if (field === 'notes_html') {
        // .code causes an error during of trying to decode a raw html in the notes_html
        try {
          $('#iteration_notes_html').summernote('code', this.templateData[field]);
          } catch (err) {
            $('#iteration_notes_html').val(this.templateData[field]);
          }
      } else {
        $(`#iteration_${field}`).val(this.templateData[field]);
      }
    });
  }
}

export default TemplateLoader;
