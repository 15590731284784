/**
  * @function displayMonitorResolution
  *
  * @desc monitor resolution and then
  * displays it on the test-it-out / system metrics page.
*/
function displayMonitorResolution() {
  const monitorWidth = window.screen.width;
  const monitorHeight = window.screen.height;

  const monitorWidthElement = document.querySelector('[data-behavior="primary-monitor-width"]')
  const monitorHeightElement = document.querySelector('[data-behavior="primary-monitor-height"]')

  monitorWidthElement.value = monitorWidth;
  monitorHeightElement.value = monitorHeight;
}

module.exports = displayMonitorResolution;
