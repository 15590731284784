class ResourcesCheckboxes {
  constructor() {
    this.permittedResources = document.querySelectorAll('.js-resource-switch [type=checkbox]');
  }

  init() {
    this.bindEventListeners();
  }

  bindEventListeners() {
    this.permittedResources.forEach((resource) => {
      resource.addEventListener('click', () => {
        this.handleChange(resource);
      })
    })
  }

  handleChange(resource) {
    const noResourcesAllowed = document.querySelector('.js-black-sheep');
    if (noResourcesAllowed.checked) {
      noResourcesAllowed.checked = false;
    }
  }

  uncheckAllowedResources(){
    this.permittedResources.forEach((resource) => {
      resource.checked = false;
    })
  }
}

export default ResourcesCheckboxes;
