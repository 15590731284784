import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

/** @typedef {import('../../types').GuardianDownloadUrls} GuardianDownloadUrls */
/** @typedef {import('../../types').GuardianPlatform} GuardianPlatform */

/**
 * @typedef DownloadGuardianBrowserProps
 * @property {GuardianPlatform} guardianBrowserPlatform
 * @property {GuardianDownloadUrls} guardianBrowserDownloadUrls
 */

/**
 * @param {DownloadGuardianBrowserProps} props
 * @returns {React.ReactElement}
 */
export default function DownloadGuardianBrowser(props) {
  /** @type {[boolean, (value: boolean) => void]} */
  const [showGuardianMacDownload, setShowGuardianMacDownload] = useState(false);

  if (props.guardianBrowserPlatform === 'UNSUPPORTED') {
    return <></>;
  }

  return (
    <>
      <div>
        <DownloadLink
          guardianBrowserDownloadUrls={props.guardianBrowserDownloadUrls}
          guardianBrowserPlatform={props.guardianBrowserPlatform}
          setShowGuardianMacDownload={setShowGuardianMacDownload}
        />

        <p className="card-text">
          {polyglot.t('order_receipt_guardian_browser_description')}
        </p>
      </div>

      <MacDownloadModal
        guardianBrowserDownloadUrls={props.guardianBrowserDownloadUrls}
        showGuardianMacDownload={showGuardianMacDownload}
        setShowGuardianMacDownload={setShowGuardianMacDownload}
      />
    </>
  );
}

/**
 * @param {Object} props
 * @param {GuardianPlatform} props.guardianBrowserPlatform
 * @param {GuardianDownloadUrls} props.guardianBrowserDownloadUrls
 * @param {(value: boolean) => void} props.setShowGuardianMacDownload
 * @returns {React.ReactElement}
 */
function DownloadLink(props) {
  return (
    <h3 className="fs-5 card-title mb-0">
      {props.guardianBrowserPlatform === 'WINDOWS' && (
        <a href={props.guardianBrowserDownloadUrls.windows} target="_blank">
          <i className="fa-solid fa-download mr-2"></i>
          {polyglot.t('order_receipt_download_guardian_browser')}
        </a>
      )}

      {props.guardianBrowserPlatform === 'MAC' && (
        <a
          href="#"
          role="button"
          onClick={(e) => {
            e.preventDefault();
            props.setShowGuardianMacDownload(true);
          }}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              props.setShowGuardianMacDownload(true);
            }
          }}
          tabIndex="0"
        >
          <i className="fa-solid fa-download mr-2"></i>
          {polyglot.t('order_receipt_download_guardian_browser')}
        </a>
      )}
    </h3>
  );
}

/**
 * @param {Object} props
 * @param {boolean} props.showGuardianMacDownload
 * @param {(value: boolean) => void} props.setShowGuardianMacDownload
 * @param {GuardianDownloadUrls} props.guardianBrowserDownloadUrls
 * @returns {React.ReactElement}
 */
export function MacDownloadModal(props) {
  return (
    <Modal
      show={props.showGuardianMacDownload}
      onHide={() => props.setShowGuardianMacDownload(false)}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {polyglot.t('secure_browser_download_modal_heading')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{polyglot.t('secure_browser_download_modal_body')}</p>
        <p>
          <a
            href="https://support.proctoru.com/hc/en-us/articles/24760032813453-How-to-Download-Install-and-Uninstall-the-Guardian-Browser#h_01HRAVKGD8YSA23PFFEDHPJNJQ"
            target="_blank"
          >
            {polyglot.t('secure_browser_download_identify_processor')}
          </a>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <a
          href={props.guardianBrowserDownloadUrls.macIntel}
          className="btn btn-primary mt-1"
          id="mac-intel-download-link"
        >
          {polyglot.t('secure_browser_download_button_apple_intel')}
        </a>
        <a
          href={props.guardianBrowserDownloadUrls.macAppleSilicon}
          className="btn btn-primary mt-1"
          id="mac-m-download-link"
        >
          {polyglot.t('secure_browser_download_button_apple_silicon')}
        </a>
      </Modal.Footer>
    </Modal>
  );
}
