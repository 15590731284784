import Toast from '../utils/Toast';
import Clippy from '../global/Clippy';

class ExamUnlocker {
  constructor() {
    this.unlockExamEl = document.querySelector('[data-behavior="unlock-exam"]');
    this.examUrlsContainer = document.querySelector(
      '[data-behavior="unlock-test-delivery-links"]'
    );
    this.handleUnlockExam = this.handleUnlockExam.bind(this);
  }

  get isTestDelivery() {
    return !!this.unlockExamEl;
  }

  init() {
    // bail out if this isn't a Test Delivery fulfillment.
    if (!this.isTestDelivery) return;

    this.bindEventListeners();
  }

  bindEventListeners() {
    this.unlockExamEl.addEventListener('click', this.handleUnlockExam);
  }

  handleUnlockExam(event) {
    this.disableUnlockExamEl();
    this.fetchUnlockExamResource(event);
  }

  disableUnlockExamEl() {
    // cache the original text inside the button.
    this.cachedUnlockExamElText = this.unlockExamEl.textContent;

    // disable the button.
    this.unlockExamEl.classList.add('disabled');
    this.unlockExamEl.textContent = 'Generating exam link...';
  }

  fetchUnlockExamResource(event) {
    const dataset = event.target.dataset;

    $.ajax({
      type: 'PUT',
      url: dataset.url,
      contentType: 'application/json',
      data: JSON.stringify({ proctor: dataset.proctor }),
      success: this.handleSuccess.bind(this),
      error: this.handleError.bind(this)
    });
  }

  handleSuccess(response) {
    let html = '';
    const startupLink = `<h4 class="mb-3">Cirrus Startup Link</h4>
                        <div class="input-group">
                          <input class="form-control" readonly="" type="text" value="${response.startup_link}">

                          <span class="input-group-addon bg-white">
                            <a class="js-tooltip-copy text-cursor"
                              title="Copy Startup Link"
                              data-clipboard-text="${response.startup_link}"
                              data-copied-hint="Copied!"
                              data-toggle="tooltip">
                              <span class="fa fa-clipboard"></span>
                            </a>
                          </span>
                        </div>`;
    const proctorLink = `<h4 class="mb-3 mt-3">Cirrus Proctor Link</h4>
                        <div class="input-group">
                          <input class="form-control" readonly="" type="text" value="${response.proctor_link}">

                          <span class="input-group-addon bg-white">
                            <a class="js-tooltip-copy text-cursor"
                              title="Copy Proctor Link"
                              data-clipboard-text="${response.proctor_link}"
                              data-copied-hint="Copied!"
                              data-toggle="tooltip">
                              <span class="fa fa-clipboard"></span>
                            </a>
                          </span>
                        </div>`;

    if (response.startup_link) {
      html += startupLink;
    }
    if (response.proctor_link) {
      html += proctorLink;
    }

    // replace loading text with text to "Regenerate" the link.
    this.unlockExamEl.textContent = polyglot.t('regenerate_exam_link');
    // enable the button.
    this.unlockExamEl.classList.remove('disabled');

    this.examUrlsContainer.innerHTML = html;
    this.examUrlsContainer.classList.add('success-fade');

    // re-init Clippy
    new Clippy().init();
  }

  handleError(retryCount) {
    new Toast().danger({
      message: 'Unable to unlock the exam at this time.'
    });
    this.replaceCachedButton();
  }

  replaceCachedButton() {
    // replace loading text with cached copy of text.
    this.unlockExamEl.textContent = this.cachedUnlockExamElText;
    // enable the button.
    this.unlockExamEl.classList.remove('disabled');
  }
}

export default ExamUnlocker;
