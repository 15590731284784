import updateDatePicker from './shared/updateDatePicker';
import updateTimePicker from './shared/updateTimePicker';
import Slots from './Slots';

class EarliestSlotsButton {
  constructor() {
    this.slots = document.querySelector('.slots')
  }

  init() {
    if (this.slots) {
      this.slots.addEventListener('click', (e) => {
        if(e.target.id == 'js-reservation-reset-beginning') {
          const datetime = this.getEarliestDateTime()
          updateTimePicker(datetime)
          updateDatePicker(datetime)
          new Slots().init()
        }
      })
    }
  }

  getEarliestDateTime() {
    const formattedTime = $('#js-reservation-reset-beginning').data('time')
    return moment(formattedTime, 'YYYY-MM-DD HH:mm:ss Z').format();
  }
}

 export default EarliestSlotsButton;
