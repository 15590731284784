import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import PropTypes from 'prop-types';

class DeleteIncident extends React.Component {
  constructor(props) {
    super(props)
  }

  toggleDeleteCandidate = (event) => {
    const { uiMessageId, transformStore } = this.props
    const action = event.target.checked
      ? 'addToDeleteCandidates'
      : 'removeFromDeleteCandidate'

    transformStore({
      type: action,
      params: uiMessageId
    })
  }

  render() {
    const { isDeleteCandidate, incidentType } = this.props
    var deleteCandidateClassName = incidentType ? incidentType : 'incident'

    return (
      <span className={`delete-${deleteCandidateClassName}`}>
        <OverlayTrigger
          overlay={<Tooltip id='tooltip'>Delete events</Tooltip>}
          placement='top'
          trigger={['click', 'hover']}
          animation={false}
        >
          <input
            type='checkbox'
            defaultChecked={isDeleteCandidate}
            onClick={this.toggleDeleteCandidate}
          />
        </OverlayTrigger>
      </span>
    )
  }
}

DeleteIncident.propTypes = {
  uiMessageId: PropTypes.number.isRequired,
  transformStore: PropTypes.func.isRequired,
  isDeleteCandidate: PropTypes.bool.isRequired,
  incidentType: PropTypes.string
}

export default DeleteIncident
