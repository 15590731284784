import React, { useState } from 'react';

const RequestApproval = (props) => {
  const {windows, mountpoint, token, isNewExam, examPickerUrl} = props || {};

  const requestApproval = () => {

    let cleanWindows = {};
    Object.keys(windows).forEach((key) => {
      const obj = windows[key]
      cleanWindows[key] = { 'starts_at': obj['startsAt'], 'ends_at': obj['endsAt'], 'name': obj['name'] }
    });

    fetch(mountpoint, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token,
      },
      body: JSON.stringify({ wizards_form_objects_proctor_config: { windows: cleanWindows } }),
    }).then((response) => {
      if (!isNewExam) {
        return window.location = examPickerUrl;
      } else if (response.ok) {
          return window.location = response.url;
      }

      console.error('Error: ', response);
    });
  };

  return (
    <>
      <button type="button" className="btn contact-button-layout contact-button-style contact-button-text" data-toggle="modal" data-target="#requestApprovalModal">
        Contact Us for Approval
      </button>

      <div className="modal fade" id="requestApprovalModal" role="dialog" aria-labelledby="requestApprovalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="d-flex lms-modal-layout">
            <div id="step-modal-header" className="d-flex lms-modal-header">
              <div className="custom-header modal-title h4" id="requestApprovalLabel">Get Approval</div>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="d-flex lms-modal-body-layout lms-modal-body-text">
              I understand that by requesting special approval for these Appointment Windows may lead in extra time for exam activation.
            </div>
            <div className="d-flex lms-modal-footer-layout">
              <button className="btn back-button-layout btn-secondary-meazure small" data-dismiss="modal">Cancel</button>
              <button onClick={requestApproval} className="btn next-button-layout btn-primary-meazure small">
                Request Approval
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestApproval;
