class AutoModal {
  init() {
    this.extensionSupportInfo = document.querySelector('[data-element="extension-info"]');

    if (!this.extensionSupportInfo) {
      return;
    }

    const isProductionAttr = this.extensionSupportInfo.getAttribute('data-is-production');
    const isProduction = isProductionAttr === 'true';

    this.modalOptions = {
      backdrop: isProduction ? 'static' : true,
      keyboard: !isProduction
    };

    this.secureBrowserEnabled = this.extensionSupportInfo.dataset.secureBrowserEnabled;

    if(this.secureBrowserEnabled === 'false') {
      this.checkEnvironment();
    }
  }

  /**
   * Browser detection
   * @param {string} browser - browser name
   * @return {boolean}
   */
  isBrowserFor(browser) {
    const isFirefox = typeof InstallTrigger !== 'undefined';
    const isChrome = !!window.chrome;
    let browserName;

    if (isFirefox) {
      browserName = 'firefox';
    } else if (isChrome) {
      browserName = 'chrome';
    } else {
      browserName = 'unknown';
    }

    return browser === browserName;
  }

  checkChromeExtension() {
    const extensionId = this.selectExtensionIdToCheck();
    const message = {
      message: 'checkExtension'
    };

    if (chrome.runtime === undefined) {
      this.showWarningModal(extensionId);
    } else {
      chrome.runtime.sendMessage(extensionId, message, (response) => {
        let verification_result = null;
        if (response) {
          verification_result = response.id === extensionId;
        }
        if (!verification_result) {
          this.showWarningModal(extensionId);
        }
      });
    }
  }

  checkFirefoxExtension() {
    const XPIUrl = 'https://s3-us-west-2.amazonaws.com/proctoru-assets/extension/firefox-extension-latest.xpi';
    const installExtensionModal = _ => {
      if (document.getElementById('modal-window-container')) { return };
      const modalBodyContent = `You will need to install the ProctorU Extension.
      Please download it <a style="text-decoration:underline;" href="${XPIUrl}">here</a>.`;
      $('#verify-content').html(modalBodyContent);
      $('#verify').modal(this.modalOptions);
    };
    setTimeout(_ => installExtensionModal(), 200);
  }

  selectExtensionIdToCheck() {
    const isAncoris = this.extensionSupportInfo.getAttribute('data-is-ancoris');

    return (isAncoris === 'true') ?
      this.extensionSupportInfo.getAttribute('data-ancoris-extension-id')
      :
      this.extensionSupportInfo.getAttribute('data-extension-id');
  }

  showWarningModal(extensionId) {
    const text = `You will need to install the ProctorU Extension. Please download it
      <a style="text-decoration:underline;" href="https://chrome.google.com/webstore/detail/${extensionId}" target="_blank">
      here</a>.`;
    $('#verify-content').append(text);
    $('#verify').modal(this.modalOptions);
  }

  checkEnvironment() {
    const isChrome = this.isBrowserFor('chrome');
    const isFirefox = this.isBrowserFor('firefox');

    if (isChrome) {
      this.checkChromeExtension();
    } else if (isFirefox) {
      this.checkFirefoxExtension();
    } else {
      const text = 'Please, use Google Chrome to take the exam';
      $('#verify-content').text(text);
      $('#verify').modal(this.modalOptions);
    }
  }
}

export default AutoModal;
