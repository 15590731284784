import { createContext } from 'react';

const ExamPickerContext = (createExamEndpoint, configureExamEndpoint, insertLinkEndpoint, editExamEndpoint, institutionId, departments, terms, latestExams, courseName, configuredExams, unconfiguredExams) => {
  const state = {
    createExamEndpoint,
    configureExamEndpoint,
    insertLinkEndpoint,
    editExamEndpoint,
    institutionId,
    departments,
    terms,
    latestExams,
    courseName,
    configuredExams,
    unconfiguredExams,
  };

  return {
    ...state,
  };
};
export const Context = createContext({});
export default ExamPickerContext;
