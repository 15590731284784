class CircleSpinner extends React.Component {
  constructor(props){
    super(props)
  }

  render() {
    return (
      <span className="spinner-container">
        <img src={this.props.path} alt="spinner" />
      </span>
    )
  }
}

export default CircleSpinner;
