import axios from 'axios';
import csrfToken from '../utils/csrf';

class CanvasIntegration {
  constructor() {
    const url = new URL(location.href);
    this.integration_data_element = document.querySelector('[data-element="integration-data"]');
    this.code = url.searchParams.get('code');
    this.refreshToken = '';
  }

  init() {
    if (this.code && this.integration_data_element) {
      this.setIntegrationData();
      this.createCanvasIntegration();
    }
  }

  setIntegrationData() {
    const client_data = this.integration_data_element.dataset;
    this.clientId = client_data.clientId;
    this.clientSecret = client_data.clientSecret;
    this.baseUri = client_data.baseUrl;
    this.redirectUri = client_data.redirectUri;
  }

  createCanvasIntegration() {
    const headers = {
      headers: {
        Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
      }
    };
    axios.post(this.redirectUri, this.canvasIntegrationParams(), headers)
      .then(response => {
        window.location = this.redirectUri;
      })
      .catch(error => {
        window.location = this.redirectUri + '/new';
      });
  }

  canvasIntegrationParams() {
    return {
      canvas_integration: {
        client_id: this.clientId,
        client_secret: this.clientSecret,
        base_uri: this.baseUri
      },
      code: this.code,
      redirect_uri: this.redirectUri
    };
  }
}

export default CanvasIntegration;
