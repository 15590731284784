import PropTypes from 'prop-types';
import DeleteIncident from '../DeleteIncident';

const NamedIncident = ({
  createdAt,
  showInPlayer,
  text,
  isDeleteCandidate,
  uiMessageId,
  transformStore,
  deleteCheckbox,
  institutionUser
}) => (
  <div className="row align-items-center">
    <div className="col-1">
      <div className="h-100 text-center d-flex align-items-center justify-content-center">
        <span className="fad fa-play-circle fa-lg player-icon cursor-pointer" onClick={showInPlayer}/>
      </div>
    </div>
    <div className="col-10">
      <small className="text-muted">{createdAt}</small>
      <div>
        {text}
      </div>
    </div>
    <div className="col-1">
      <span>
        {(deleteCheckbox && !institutionUser) ? (
          <DeleteIncident
            uiMessageId={uiMessageId}
            transformStore={transformStore}
            isDeleteCandidate={isDeleteCandidate}
          />
        ) : null}
      </span>
    </div>
  </div>
)

NamedIncident.propTypes = {
  createdAt: PropTypes.string.isRequired,
  showInPlayer: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  uiMessageId: PropTypes.number,
  transformStore: PropTypes.func,
  isDeleteCandidate: PropTypes.bool
}

export default NamedIncident
