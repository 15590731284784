class ReviewQueueEntry extends React.Component {
  constructor(props) {
    super(props)
  }

  getTimeRemaining(completeBy) {
    const currentTime = moment()
    const completeByTime = moment(completeBy)
    const timeRemaining = completeByTime.fromNow()
    const hourDiff = completeByTime.diff(currentTime, 'hours')
    let status = ''
    if (hourDiff > 0 && hourDiff < 2) {
      status = 'warning'
    } else if (hourDiff < 0) {
      status = 'danger'
    }

    return (
      <span className='dashboard__entry__time_remaining'>
        <span className={`dashboard__entry__indicator ${status}`} />
        {timeRemaining}
      </span>
    )
  }

  filterActions(actions, userId, disabled) {
    if (userId === window.data.userId && !disabled) {
      return actions.filter(({ event }) => ['complete', 'drop'].includes(event))
    } else if (userId !== null) {
      return [] // Don't display any actions
    } else {
      return actions.filter(({ event }) => (!disabled && ['start'].includes(event)))
    }
  }

  render() {
    const {
      renderAction,
      entry: {
        assignedTo,
        disabled,
        id,
        fulfillmentUrl,
        watcherWindowUrl,
        tt_id, 
        user_id,
        data: {
          complete_by,
          exam_name,
          actions,
          type
        }
      }
    } = this.props
    return (
      <tr className={ disabled ? 'd-none' : '' }>
        <td>
          <ul className="list-unstyled">
            <li><b>Test-taker ID:</b> {tt_id}</li>
            <li><b>Exam:</b> {exam_name}</li>
            <li>
              <strong>
                <a href={fulfillmentUrl} target="_blank">Fulfillment</a>
              </strong>
              { watcherWindowUrl &&
                <strong>
                  <a href={watcherWindowUrl} className="ml-2" target="_blank">Watcher Window</a>
                </strong>
              }
            </li>
          </ul>
        </td>
        <td>
          { this.getTimeRemaining(complete_by) }
        </td>
        <td>
          {type}
        </td>
        <td className="text-right">
          { disabled && assignedTo && `Assigned to: ${assignedTo}`}
          { this.filterActions(actions, user_id, disabled).map(action => { return renderAction(action, id) }) }
        </td>


      </tr>
    )
  }
}

export default ReviewQueueEntry;
