import Toast from '../utils/Toast';

class AccessCodeShipments {
  constructor() {
    this.$datePickers = $('.js-acs-date-picker');
    this.showButton = document.getElementsByClassName('js-acs-show');
    this.$selectInput = $('.js-acs-select');
    this.modal = document.getElementById('js-acs-modal');
  }

  execute() {
    this.bindEventListeners();
    this.enableSelect();
    this.initializeDatePickers();
  }

  bindEventListeners() {
    [...this.showButton].forEach(btn => btn.addEventListener('click', this.handleClickEvent.bind(this)));
  }

  handleClickEvent(event) {
    event.preventDefault();

    const currentTarget = event.currentTarget;

    fetch(currentTarget.getAttribute('data-url'), {
      credentials: 'same-origin'
    })
      .then(response => {
        this.handleClickComplete(currentTarget);
        if (!response.ok) {
          this.handleClickError();
        } else {
          return response.text();
        }
      })
      .then(html => {
        this.handleClickSuccess(html);
      })
      .catch(err => {
        this.handleClickError(err);
      });
  }

  handleClickComplete(button) {
    button.innerText = 'View';
  }

  handleClickSuccess(response) {
    this.modal.getElementsByClassName('modal-dialog')[0].innerHTML = response;
    $(this.modal).modal();
  }

  handleClickError(err = null) {
    const message = err == null ? 'Sorry, a network error has occurred.' : 'Sorry, we couldn\'t load this item. Please try again.';

    new Toast().danger({ message: message });
  }

  enableSelect() {
    this.$selectInput.select2({
      placeholder: $(this).data('placeholder'),
      allowClear: true,
      theme: 'bootstrap4'
    });
  }

  initializeDatePickers() {
    this.$datePickers.each((idx, datePicker) => {
      $(datePicker).datetimepicker({
        format: 'YYYY-MM-DD',
        icons: {
          next: 'fa fa-arrow-right',
          previous: 'fa fa-arrow-left'
        }
      });
    });
  }
};

export default AccessCodeShipments;
