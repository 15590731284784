import React, { useEffect, useState } from 'react';
import { LMSExamModal } from './LMSExamModal';
import Toast from '../../src/utils/Toast';

const EXCLUDE_ELEMENTS = [
  'wizards_form_objects_proctor_config_current_step',
  'authenticity_token',
  '_method',
  'wizards_form_objects_proctor_config_exam_name',
];

const PAGES = {
  1: '/institutions/proctor_configs/proctoring_settings',
  2: '/institutions/proctor_configs/department',
  3: '/institutions/proctor_configs/configuration',
  4: '/institutions/proctor_configs/appointment_windows',
  5: '/institutions/proctor_configs/permitted_tools',
  6: '/institutions/proctor_configs/notifications',
};

function LMSExamModalContainer({
  pageUrl = '',
  institutionId,
  exam = undefined,
  saveNow = false,
  setPageUrl,
  updateExamData,
}) {
  if (pageUrl === '') {
    return;
  }

  const getStepNumberFromPageUrl = () => {
    const stepPage = pageUrl.split('/').pop();
    const initialStep = 1;
    for (const key in PAGES) {
      if (PAGES.hasOwnProperty(key)) {
        if (PAGES[key].split('/').pop() === stepPage) {
          return parseInt(key);
        }
      }
    }

    return initialStep;
  };

  const [step, setStep] = useState(getStepNumberFromPageUrl());
  const [html, setHTML] = useState({ __html: '' });
  const [showModal, setShowModal] = useState(true);
  const [showProgress, setShowProgress] = useState(true);
  const [enableButton, setEnableButton] = useState(false);

  const submitFormData = async ({ pageUrl, exam, institutionId }) => {
    const iframe = document.getElementById('lmsIFrame');
    const postData = new URLSearchParams();

    //Show progress on the last step or when explicitly requested
    if (step === 6 || saveNow) {
      setShowProgress(true);
    }

    try {
      let response;

      //When the step is an appointment, get data from session storage
      if (pageUrl.lastIndexOf('/appointment_windows') > 0) {
        const appointmentWindows = sessionStorage.getItem('appointmentWindows');
        response = await fetch(pageUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.getElementById('authenticity_token').value,
          },
          body: JSON.stringify({
            wizards_form_objects_proctor_config: {
              windows: appointmentWindows,
            },
          }),
        });
      } else {
        const elements = [
          ...iframe.contentWindow.document.querySelectorAll(
            `[id^="wizards_form_objects_"]`,
          ),
        ];
        // Append required data
        const sectionName = pageUrl.substring(pageUrl.lastIndexOf('/') + 1);
        postData.append('_method', 'put');
        postData.append(
          'authenticity_token',
          document.getElementById('authenticity_token').value,
        );
        postData.append(
          'wizards_form_objects_proctor_config[current_step]',
          sectionName,
        );
        postData.append(
          'wizards_form_objects_proctor_config[exam_name]',
          exam.exam_name,
        );
        postData.append(
          'wizards_form_objects_proctor_config[institution_id]',
          institutionId,
        );
        postData.append(
          'wizards_form_objects_proctor_config[exam_id]',
          saveNow ? exam.id : '',
        );

        //Loop through the form data
        elements.forEach((element) => {
          if (element.value) {
            if (
              !postData.has(element.name) &&
              !EXCLUDE_ELEMENTS.includes(element.id)
            ) {
              if (element.type === 'checkbox') {
                postData.append(element.name, element.checked ? '1' : '0');
              } else if (element.type === 'radio') {
                postData.append(element.name, element.checked ? '1' : '0');
              } else {
                postData.append(element.name, element.value);
              }
            }
          }
        });

        response = await fetch(pageUrl, { body: postData, method: 'POST' });
      }

      if (!response.ok) {
        throw new Error(`Failed to submit form. Status: ${response.status}`);
      }

      const statusCode = await response.status;
      return statusCode;
    } catch (error) {
      console.error('Error submitting form: ', error.message);
      new Toast().danger({
        message: "We couldn't save changes. Please try again.",
        timeout: 5000,
        classList: ['lms-custom-danger-toast', 'small'],
      });
    }
  };

  const handlePrevious = () => {
    setShowProgress(true);
    setHTML({ __html: '' });
    setStep(step - 1);
  };

  const handleClose = () => {
    setShowModal(false);
    setShowProgress(false);
    setPageUrl('');
    setHTML({ __html: '' });
    setStep(1);
  };

  const handleSubmit = async () => {
    if (document.getElementById('new_wizards_form_objects_proctor_config')) {
      const status = await submitFormData({
        pageUrl,
        exam,
        institutionId,
      });

      if (status >= 200 && status < 300) {
        new Toast().success({
          message: 'Exam update has been successfully completed.',
          timeout: 3_000,
          classList: ['lms-custom-success-toast', 'small'],
        });
        setShowModal(false);
        setShowProgress(false);
        setPageUrl('');
      }
    }
    setHTML({ __html: '' });
  };

  const handleNext = async () => {
    setStep(step + 1);

    //Submit the form
    const status = await submitFormData({
      pageUrl,
      exam,
      institutionId,
    });

    if (status >= 200 && status < 300) {
      if (step === 6) {
        updateExamData();
        new Toast().success({
          message: 'Exam configuration has been successfully completed.',
          timeout: 3_000,
          classList: ['lms-custom-success-toast', 'small'],
        });
        setShowModal(false);
        setShowProgress(false);
        setPageUrl('');
      }
    }
  };

  useEffect(() => {
    if (!saveNow) {
      let actionPage = PAGES[step];
      if (actionPage) {
        setPageUrl(actionPage);
      } else {
        setStep(1);
        setHTML({ __html: '' });
      }
    }
  }, [step, saveNow]);

  const handleChangeElement = (event, isDropdowndown) => {
    if (isDropdowndown) {
      // If the element is a dropdown, it should check the content
      setEnableButton(event.target.value !== '');
    } else {
      // if the element is not a dropdown, it should check if exists
      setEnableButton(event);
    }
  };

  return (
    <LMSExamModal
      showModal={showModal}
      showProgress={showProgress}
      pageUrl={pageUrl}
      saveNow={saveNow}
      exam={exam}
      step={step}
      html={html}
      enableButton={enableButton}
      setShowProgress={setShowProgress}
      setShowModal={setShowModal}
      setHTML={setHTML}
      handleSubmit={handleSubmit}
      handleNext={handleNext}
      handlePrevious={handlePrevious}
      handleClose={handleClose}
      handleChangeElement={handleChangeElement}
    />
  );
}

export { LMSExamModalContainer };
