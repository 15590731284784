import React, { useState } from 'react';

import { useNextExamCountdownHook } from '../Hooks/NextExamCountdownHook';
import { NextExamCountdown } from './NextExamCountdown';
import { ReservationListItem } from './ReservationListItem';

export const ScheduledExams = () => {
  const { time } = useNextExamCountdownHook();
  const [exams, setExams] = useState([1, 2, 3, 4, 5]);
  return (
    <div className="card">
      <div className="card-header bg-dark text-white">
        <NextExamCountdown time={time} />
      </div>
      <div className="card-body">
        {exams.map((eachExam) => (
          <ReservationListItem key={eachExam}>
            <button>Reschedule</button>
            <button>Cancel</button>
          </ReservationListItem>
        ))}
      </div>
    </div>
  );
};
