import Toast from '../../utils/Toast';

class SubmitRetakeForm {
  constructor(event, form) {
    this.picture = event.currentTarget;
    this.form = form
  }

  init() {
    const allowedExts = ['jpg', 'png', 'jpeg']
    if (allowedExts.indexOf(this.picture.value.split('.').pop().toLowerCase()) == -1) {
      new Toast().warning({message: `Invalid file extension. Only the following are allowed: ${allowedExts.join(', ')}`})
    } else {
     this.form.submit()
    }
  }
}

export default SubmitRetakeForm
