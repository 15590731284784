import PropTypes from 'prop-types';
import EventFeeder from '../EventFeeder';

const ConfirmationEvent = ({
  createdAt,
  showInPlayer,
  body,
  actionerName,
  transformStore,
  uiMessageId,
  showEventFeed,
  feedable,
  feedStatus,
  feedUrl,
}) => (
  <div className="row align-items-center">
    <div className="col-1">
      <div className="h-100 text-center d-flex align-items-center justify-content-center">
        <span className="fad fa-play-circle fa-lg player-icon cursor-pointer" onClick={showInPlayer}/>
      </div>
    </div>
    <div className="col-8">
      <small className="text-muted">{actionerName}, {createdAt}</small>
      <div dangerouslySetInnerHTML={{ __html: body }} />
    </div>
    <div className="col-3">
      { showEventFeed && feedable &&
        <EventFeeder feedStatus={feedStatus} feedUrl={feedUrl} transformStore={transformStore} uiMessageId={uiMessageId} />
      }
    </div>
  </div>
)

ConfirmationEvent.propTypes = {
  createdAt: PropTypes.string.isRequired,
  showInPlayer: PropTypes.func.isRequired,
  body: PropTypes.string.isRequired,
  actionerName: PropTypes.string,
  uiMessageId: PropTypes.number,
  transformStore: PropTypes.func,
  showEventFeed: PropTypes.bool,
  feedable: PropTypes.bool,
  feedStatus: PropTypes.string,
  feedUrl: PropTypes.string,
  feedType: PropTypes.string,
}

export default ConfirmationEvent
