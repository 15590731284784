import ShowRetakeForm from './shared_actions/ShowRetakeForm';
import SubmitRetakeForm from './shared_actions/SubmitRetakeForm';

class IdConfirmation {
  constructor() {
    this.retakeIdConfirmationLink = document.querySelector('.js-id-confirmation-retake');
    this.retakeIdConfirmationFile = document.querySelector('.js-id-confirmation-retake-file');
    this.form = document.querySelector('.js-id-confirmation-retake-form')
  }

  init() {
    this.bindEventListeners();
  }

  bindEventListeners() {
    if(this.retakeIdConfirmationLink) {
      this.retakeIdConfirmationLink.addEventListener('click', (event) => {
        new ShowRetakeForm(event).init();
      });
    }
    if(this.retakeIdConfirmationFile) {
      this.retakeIdConfirmationFile.addEventListener('change', (event) => {
        new SubmitRetakeForm(event, this.form).init();
      });
    }
  }
}

export default IdConfirmation;
