import GenericButton from './GenericButton';

const GenericDropdown = ({ links, icon, text, align }) => {
  return (
    <div className="dropdown">
      <GenericButton
        className="btn btn-secondary"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="true"
        text={null}
        icon={icon}
         data-boundary="viewport"
      ></GenericButton>
      <div
        className={`dropdown-menu ${align == 'right' ? 'dropdown-menu-right' : ''}`}
        style={{ 'z-index': '1100' }}>
        {
          Object.keys(links).map((link, index) => (
            <a href={links[link]} key={index} className="dropdown-item">
              { link }
            </a>
          )
        )}
      </div>
    </div>
  )
}


export default GenericDropdown;
