import 'core-js/stable';
import 'regenerator-runtime/runtime';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';

global.React = React;
global.ReactDOM = ReactDOM;
global.PropTypes = PropTypes;

require('turbolinks').start();
require('jquery');

/** @type {RequireContext} */
const webpackContext = require.context('src', true, /(?:\.js|\.js\.min)$/);
for (const key of webpackContext.keys()) {
  webpackContext(key);
}

/** @type {RequireContext} */
const componentRequireContext = require.context('components');
const ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);

// Unmount components before Turbolinks navigates to a new page
document.addEventListener('turbolinks:before-render', () => {
  ReactRailsUJS.unmountComponents();
});

// Mount components after Turbolinks loads a page
document.addEventListener('turbolinks:load', () => {
  ReactRailsUJS.mountComponents();
});

// Mount React components when new elements are inserted by Cocoon
$(document).on('cocoon:after-insert', (e, insertedItem) => {
  // Unmount any existing components in the inserted item
  ReactRailsUJS.unmountComponents(insertedItem[0]);
  // Then mount the new components
  ReactRailsUJS.mountComponents(insertedItem[0]);
});

import 'underscore';
import 'lodash';
import 'autosize';
import 'popper.js';
import 'bootstrap';
import 'trix';
import '@nathanvda/cocoon';
import 'jquery-countdown';
import 'jquery.cookie';
import 'jquery-ujs';
import 'select2';
import 'summernote';
import 'summernote/src/styles/summernote-bs4.scss';
import 'packs/password_toggle';

$.fn.datetimepicker = require('eonasdan-bootstrap-datetimepicker');

import 'swfobject';
import 'packs/institution_form';
import 'packs/faculty_user_autocomplete';
