class BlackOutForm {
  constructor() {
    this.repeatIntervalField = document.querySelector(
      '[data-behavior="blackout-repeat-interval"]'
    );

    this.repeatUntilFieldGroup = document.querySelector(
      '[data-behavior="blackout-repeat-until"]'
    );

    this.handleChange = this.handleChange.bind(this);
  }

  init() {
    this.handleOnload();
    this.bindEventListeners();
  }

  handleOnload() {
    this.handleChange();
  }

  bindEventListeners() {
    this.repeatIntervalField.addEventListener('change', this.handleChange);
  }

  handleChange() {
    const value = this.repeatIntervalField.value;

    if (value === 'none') {
      this.repeatUntilFieldGroup.classList.add('d-none');
    } else {
      this.repeatUntilFieldGroup.classList.remove('d-none');
    }
  }
}

export default BlackOutForm;
