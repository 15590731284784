import TestDeliverySettingsInstanceValidator from './TestDeliverySettingsInstanceValidator';

class TestDeliverySettingsValidator {

  init() {
    var self = this;
    $('#test_delivery [data-toggle="tab"]').on('shown.bs.tab', function(event){
      event.preventDefault();
      var tab = document.querySelector(event.target.getAttribute('href'));
      new TestDeliverySettingsInstanceValidator(tab).init();
    });

    // Bind events to the preselected tab panel
    new TestDeliverySettingsInstanceValidator().init();
  }

}

export default TestDeliverySettingsValidator;
