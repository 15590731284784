const NumberField = ({field, generatedId}) => {
  return (
    <div>
      <input type="text"
        name={`user[enrollments_attributes][${generatedId}][fields][${field.field_as_attribute}]`}
        id={`[user][enrollments_attributes][${generatedId}][fields][${field.field_as_attribute}]`}
        className="form-control" data-type="number_field" />
      <span className="form-text text-muted">{field.description}</span>
    </div>
  )
}

export default NumberField
