import PropTypes from 'prop-types';

class GenericButton extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { text, icon, ...rest } = this.props;

    return (
      <button
        {...rest}
        className={`${this.props.className} ${this.props.hidden ? 'd-none' : ''}`}
      >
        { icon ? <span className={`fa ${icon} ${text ? 'mr-2' : ''}`}></span> : null}
        { text }
      </button>
    );
  }
}

GenericButton.propTypes = {
  className: PropTypes.string.isRequired,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  hidden: PropTypes.bool,
  role: PropTypes.string,
  onclick: PropTypes.func,
  icon: PropTypes.string
};

GenericButton.defaultProps = {
  disabled: false,
  text: 'Submit',
  type: 'button',
  className: 'btn btn-secondary',
  hidden: false,
  role: 'button'
}

export default GenericButton
