import React, { useState } from 'react';

import { ReservationListItem } from './ReservationListItem';

export const ExamHistory = () => {
  const [exams, setExams] = useState([1, 2, 3]);
  return (
    <div className="card">
      <div className="card-header">Exam History</div>
      <div className="card-body">
        {exams.map((eachExam) => (
          <ReservationListItem key={eachExam} />
        ))}
      </div>
    </div>
  );
};
