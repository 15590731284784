import React, { useState, useEffect, useMemo } from 'react';
import { RenderLMSComponent } from './RenderLMSComponent';
import Toast from '../../src/utils/Toast';

const toast = new Toast();

function RenderLMSComponentContainer({
  pageUrl = '',
  saveNow,
  showProgress,
  exam,
  step,
  html,
  setShowProgress,
  setShowModal,
  setHTML,
  handleChangeElement,
}) {
  if (pageUrl === '') {
    return;
  }
  const [formData, setFormData] = useState(undefined);
  const [sessionFormData, setSessionFormData] = useState(undefined);

  const getFormData = () => {
    if (!exam) {
      return null;
    }

    const formData = new URLSearchParams();
    for (const pair of new FormData(
      document.getElementById('new_wizards_form_objects_proctor_config'),
    )) {
      formData.append(pair[0], pair[1]);
    }

    //Update the form data with the actual exam values
    for (const [key, value] of Object.entries(exam)) {
      const newKey = `wizards_form_objects_proctor_config[${key}]`;
      formData.set(newKey, value === null ? '' : value);
    }
    formData.append('save_flag', false);
    return formData;
  };

  const isAppointmentWindows = useMemo(
    () => pageUrl.indexOf('/appointment_windows') > 0,
  );

  const initialPostUrl = useMemo(() =>
    pageUrl
      .substring(0, pageUrl.lastIndexOf('/'))
      .concat('/proctoring_settings'),
  );

  const handleInitialElementValue = (step, formData) => {
    switch (step) {
      case 1:
        handleChangeElement(
          {
            target: {
              value:
                formData.iteration_term_id === null
                  ? ''
                  : formData.iteration_term_id,
            },
          },
          true,
        );
        break;
      case 2:
        handleChangeElement(
          {
            target: {
              value:
                formData.exam_department_id === null
                  ? ''
                  : formData.exam_department_id,
            },
          },
          true,
        );
        break;
      case 4:
        handleChangeElement(formData.windows.length > 0, false);
        break;
      default:
        handleChangeElement(true, false);
        break;
    }
  };

  useEffect(() => {
    async function getHAMLMarkup() {
      try {
        setShowModal(true);
        setShowProgress(true);

        let response;
        let backendHtmlString;
        //Only call this POST on the first step to create form
        const canGetFormData = saveNow || step == 1;
        if (canGetFormData) {
          const formDataResponse = await fetch(
            initialPostUrl.replace('/proctoring_settings', '/get_form_data'),
          );
          const data = await formDataResponse.json();
          setSessionFormData(data);
          handleInitialElementValue(step, data);
          setFormData(JSON.stringify(data));

          // only request to /proctoring_settings when trying to configure a new exam
          if (!saveNow) {
            //TODO:  Do we fire/forget this?
            const postFormData = getFormData();
            response = await fetch(initialPostUrl, {
              body: postFormData,
              method: 'POST',
            });

            //We need to set the form windows with the exam for
            const formDataWithWindows = data;
            formDataWithWindows['windows'] =
              exam?.windows || formDataWithWindows['windows'];
            setFormData(JSON.stringify(formDataWithWindows));
          }
        }

        //Fetching the HAML HTML
        if (!isAppointmentWindows) {
          response = await fetch(pageUrl, { method: 'GET' });
          backendHtmlString = await response.text();
        }

        setShowProgress(false);
        return { __html: backendHtmlString || '' };
      } catch (error) {
        console.error('Error', error);
        setShowProgress(false);
        toast.danger({
          message: "We couldn't save changes. Please try again.",
          timeout: 5000,
        });
      }
    }

    getHAMLMarkup().then((result) => setHTML(result));
  }, [pageUrl, exam]);

  useEffect(() => {
    if (sessionFormData) {
      handleInitialElementValue(step, sessionFormData);
    }
  }, [step]);

  return (
    <RenderLMSComponent
      showProgress={showProgress}
      isAppointmentWindows={isAppointmentWindows}
      html={html}
      pageUrl={pageUrl}
      formData={formData}
      step={step}
      handleChangeElement={handleChangeElement}
    />
  );
}

export { RenderLMSComponentContainer };
