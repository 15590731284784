import handleIterationChange from './utils/handleIterationChange';

class PresetsCopyPaste {
  constructor() {
    this.allowCopyPaste = document.querySelector('[data-element="allow_copy_paste"]');
  }

  init() {
    if (this.allowCopyPaste) {
      this.allowCopyPaste.addEventListener('change', (event) => { handleIterationChange(event) })
    }
  }
}

export default PresetsCopyPaste;
