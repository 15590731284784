import axios from 'axios';
import csrfToken from '../../utils/csrf';

class ProcessButton {
  constructor() {
    this.processButton = document.querySelector('.js-irc-process')
    this.form = document.querySelector('.js-irc-form-container')
  }

  init() {
    this.bindEventListener()
  }

  bindEventListener() {
    this.processButton.addEventListener('click', (event) => { this.startProcessing(event) });
  }

  startProcessing(event) {
    event.preventDefault();
    const headers = {
      headers: {
        Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
      }
    }
    axios.post(event.currentTarget.getAttribute('data-href'), {}, headers).then(response => {
      this.removeOverlay()
    })
  }

  removeOverlay() {
    this.form.classList.remove('unprocessed');
  }
}

export default ProcessButton
