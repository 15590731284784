import React, { useMemo } from 'react';

import moment from 'moment';
import { DayPicker } from 'react-day-picker/dist/cjs';
import * as Locale from 'react-day-picker/dist/cjs/locale';

// TODO: We can't use third party CSS modules like this in staging or prod yet
// import 'react-day-picker/src/style';

/**
 * @typedef {Object} DatePickerProps
 *
 * @property {string} [date] The initial date value, defaults to the current date
 * @property {string} [min] The minimum date for the date picker, if there is one
 * @property {string} [max] The maximum date for the date picker, if there is one
 * @property {string} [userLang] The language for the date and time picker
 * @property {(value: string) => void} [onChange] The function to call when the date changes
 */

/**
 * @param {DatePickerProps} props The props for the component
 * @returns {React.ReactElement}
 */
export default function DatePicker({ date, min, max, userLang, onChange }) {
  const selected = new Date(date ?? null);

  const [fromYear, toYear] = [
    min ? parseInt(min.substring(0, 4), 10) : new Date().getFullYear(),
    max ? parseInt(max.substring(0, 4), 10) : new Date().getFullYear() + 5,
  ];

  const isValidDate = (date) => !isNaN(date);

  const disabledDates = useMemo(() => {
    const minDate = new Date(min);
    const maxDate = new Date(max);

    return {
      before: isValidDate(minDate) ? minDate : null,
      after: isValidDate(maxDate) ? maxDate : null,
    };
  }, [min, max]);

  const handleSelect = (value) => {
    onChange?.(moment(value).format());
  };

  const mapLocale = (lang) => {
    const localeMap = {
      de: Locale.de,
      en: Locale.enUS,
      es: Locale.es,
      fr_ca: Locale.frCA,
      ja: Locale.ja,
    };
    return localeMap[lang] ?? 'en-US';
  };

  return (
    <>
      {/* NOTE: See https://daypicker.dev/playground for help configuring */}
      <DayPicker
        captionLayout="dropdown"
        mode="single"
        showOutsideDays
        fixedWeeks
        ISOWeek
        fromYear={fromYear}
        toYear={toYear}
        selected={selected}
        onSelect={handleSelect}
        disabled={disabledDates}
        locale={mapLocale(userLang)}
      />
    </>
  );
}
