class PrintReceipt {
  constructor() {
    this.COMPONENT_SELECTOR = document.querySelector('[data-behavior="print-receipt"]');
  }

  init() {
    this.bindEventListeners()
  }

  bindEventListeners() {
    this.COMPONENT_SELECTOR.addEventListener('click',() => { this.handleClick() })
    this.COMPONENT_SELECTOR.addEventListener('mouseleave',() => { this.handleMouseLeave() })
    this.COMPONENT_SELECTOR.addEventListener('mouseover',() => { this.handleMouseOver() })
  }

  handleClick() { print() }
  handleMouseLeave() {  document.querySelector('.print-text').style.color = '#89969c';  }
  handleMouseOver() { document.querySelector('.print-text').style.color = '#89969c'; }
}

export default PrintReceipt;
