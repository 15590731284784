// ExamLocker
//
// This class allows the test-taker the unlock their exam for
// the Test Delivery platform.

class ExamUnlocker {
  constructor(data) {
    this.unlocker = document.querySelector('.js-unlocker');
    if(this.unlocker) {
      this._url =  this.unlocker.getAttribute('data-url');
      this._proctor =  this.unlocker.getAttribute('data-proctor');
      this._code =  this.unlocker.getAttribute('data-code');
    }
  }

  init() {
    if(this.unlocker) {
      this.unlocker.addEventListener('click', (event) => { this.handleClick(event) } )
    }
  }

  handleClick(event) {
    event.preventDefault();
    $.ajax({
      type: 'GET',
      url: this._url,
      contentType: 'application/json',
      dataType: 'text'
    }).success(function(response, statusText, xhr) {
      if (xhr.status == 200) {
        window.open(response, '_blank');
      } else {
        $('#start-modal').html(response);
        $('#start-modal').modal('show');
      }
    }).error(function() {
      window.alert('There was an error unlocking the exam. Your proctor will help you.');
    });
  }
}

export default ExamUnlocker;
