import RescueInstantChatLoader from '../utils/RescueInstantChatLoader';

const ENTRY_ID = '1559375086';
const CHANNEL_NAME = 'ProctorU Channel'

class LmiChat {
  init() {
    const container = document.querySelector('#ICContainer');
    this.userName = container.dataset.username;
    this.phoneNumber = container.dataset.phone;
    this.institution = container.dataset.institution;
    this.lmiInstitutionName = container.dataset.lmiInstitutionName;
    this.fulfillmentId = container.dataset.fulfillmentId;
    this.ICLoader = null;
    this.loadLmiChat();
  }

  loadLmiChat() {
    window.location.hash = '';
    this.ICLoader = new RescueInstantChatLoader();
    this.ICLoader.ICContainer = 'ICContainer';
    this.ICLoader.HostedCSS = 'https://www.proctoru.com/student/instantchat.css';
    this.ICLoader.HostedLanguagesForChatOnlyMode = 'https://secure.logmeinrescue.com/InstantChat/LanguagesForChatOnlyMode.js';
    this.ICLoader.HostedLanguagesForAppletMode = 'https://secure.logmeinrescue.com/InstantChat/LanguagesForAppletMode.js';
    this.ICLoader.EntryID = ENTRY_ID;
    this.ICLoader.ChannelName = CHANNEL_NAME;
    this.ICLoader.Name = this.userName;
    this.ICLoader.Comment1 = this.lmiInstitutionName;
    this.ICLoader.Comment2 = this.phoneNumber;
    this.ICLoader.Comment3 = this.fulfillmentId;
    this.ICLoader.Tracking0 = '';
    this.ICLoader.Language = '';
    this.ICLoader.HostedErrorHandler = () => {};
    this.ICLoader.Start();
  }
}

export default LmiChat;
