import axios from 'axios';
import Toast from '../utils/Toast';
import csrfToken from '../utils/csrf';
class AuthorizeCanvasIntegration {
  constructor() {
    this.authorizeButton = document.querySelector('[data-element="authorize-button"]');
    this.onAuthorizeClick = this.onAuthorizeClick.bind(this);
    this.canvasIntegrationParams = this.canvasIntegrationParams.bind(this);
    this.setIntegrationParams = this.setIntegrationParams.bind(this);
  }

  init() {
    this.authorizeButton.addEventListener('click', this.onAuthorizeClick);
  }

  onAuthorizeClick() {
    this.setIntegrationParams();
    const headers = {
      headers: {
        Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
      }
    };
    axios.post(this.redirectUri + '/validate', this.canvasIntegrationParams(), headers)
      .then(response => {
        window.location = this.baseUri + '/login/oauth2/auth?client_id=' +
                          this.clientId + '&response_type=code&redirect_uri=' +
                          this.redirectUri + '&scope=' +
                          this.scopes;
      })
      .catch(error => {
        new Toast().danger({message: `Operation failed. Reason: ${error}`})
      });
  }

  setIntegrationParams() {
    const client_data = this.authorizeButton.dataset;
    this.redirectUri = client_data.redirectUri;
    this.clientId = document.getElementById('canvas_integration_client_id').value;
    this.clientSecret = document.getElementById('canvas_integration_client_secret').value;
    this.baseUri = document.getElementById('canvas_integration_base_uri').value;
    this.scopes = client_data.scopes;
  }

  canvasIntegrationParams() {
    return JSON.stringify({
      canvas_integration: {
        client_id: this.clientId,
        client_secret: this.clientSecret,
        base_uri: this.baseUri,
      },
    });
  }
}

export default AuthorizeCanvasIntegration;
