import consumer from '../channels/consumer';

class AadhaarVerificationStatus {
  init() {
    this.setupChannel();
  }

  setupChannel() {
    this.AadhaarVerificationStatus = consumer.subscriptions.create(
      {
        channel: 'AadhaarVerificationStatusChannel',
      },
      {
        received: (data) => {
          this.handleAadhaarVerificationUpdate(data);
        },
      },
    );
  }

  handleAadhaarVerificationUpdate(data) {
    console.log('Received Aadhaar Verification Update:', data);

    const statusElement = document.querySelector(
      '[data-behavior="aadhar-verification-status"]',
    );

    if (statusElement) {
      const userId = statusElement.dataset.userId;

      if (userId && userId == data.user_id.toString()) {
        statusElement.innerText = data.status;
      }
    }
  }
}

export default AadhaarVerificationStatus;
