import moment from 'moment';

class RowMapper {
  constructor(rows) {
    this.rows = rows;
    this.mapped_rows = [];
    this.institution_id = null;
  }

  map() {
    let rows_length = this.rows.length;
    for (let i = 0; i < rows_length; i++) {
      let row = this.rows[i];
      let tags = [];
      let links = [];
      let tag = {};
      let link = {};
      let columns = [];
      let column = {};
      Object.keys(row).forEach((key) => {
        if (['institution_id'].includes(key)) {
          this.institution_id = row[key];
        }
        if (
          [
            'vip',
            'scheduled',
            'awaiting_verification',
            'active_fulfillment',
          ].includes(key)
        ) {
          const humanized_key = this.humanize(key);
          tag[humanized_key] = row[key];
        } else if (['institution_id'].includes(key)) {
          link['View Institution'] =
            `${window.location.origin}/institutions/${row[key]}`;
          link['Edit Institution'] =
            `${window.location.origin}/institutions/${row[key]}/edit`;
        } else if (['iteration_id'].includes(key)) {
          link['View Exam'] =
            `${window.location.origin}/institutions/${this.institution_id}/iterations/${row[key]}`;
          link['Edit Exam'] =
            `${window.location.origin}/institutions/${this.institution_id}/iterations/${row[key]}/edit`;
        } else if (['reservation_uuid'].includes(key)) {
          link['View Session'] =
            `${window.location.origin}/reservations/${row[key]}`;
        } else if (['fulfillment_uuid'].includes(key)) {
          link['View Appointment'] =
            `${window.location.origin}/fulfillments/${row[key]}`;
          link['Flightpath'] =
            `${window.location.origin}/fulfillments/${row[key]}/flight_path`;
          link['Watcher Window'] =
            `${window.location.origin}/internal/watcher/popouts/videos/${row[key]}`;
        } else if (['test_taker_id'].includes(key)) {
          link['View User'] = `${window.location.origin}/users/${row[key]}`;
        }
      });

      if (row.hasOwnProperty('fulfillment_actual_started_at')) {
        column['Started At'] = moment
          .utc(row['fulfillment_actual_started_at'])
          .local()
          .format('h:mmA on MMM D, YYYY');
        let duration = moment.duration(
          moment.utc().diff(moment(row['fulfillment_actual_started_at'])),
        );
        column['Time Since Start'] = `${duration.minutes()} minutes`;
      } else {
        column['Started At'] = '';
        column['Time Since Start'] = '';
      }

      if (row.hasOwnProperty('test_taker_id')) {
        column['Test-taker ID'] = row['test_taker_id'];
      } else {
        column['Test-taker ID'] = '';
      }

      if (row.hasOwnProperty('institution_name')) {
        column['Institution'] = row['institution_name'];
      } else {
        column['Institution'] = '';
      }
      if (row.hasOwnProperty('test_name')) {
        column['Exam'] = row['test_name'];
      } else {
        column['Exam'] = '';
      }
      if (row.hasOwnProperty('proctor_name')) {
        column['Proctor'] = row['proctor_name'];
      } else {
        column['Proctor'] = '';
      }
      this.institution_id = null;
      links.push(link);
      tags.push(tag);
      columns.push(column);
      this.mapped_rows.push({
        tags: tags[0],
        links: links[0],
        columns: columns[0],
        id: row['fulfillment_uuid'],
      });
    }
    return this.mapped_rows;
  }

  humanize(tag) {
    let words = tag.split('_');
    let words_length = words.length;
    for (let i = 0; i < words_length; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
    return words.join(' ');
  }
}

export default RowMapper;
