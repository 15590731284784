class UcardScheduleModal {
  constructor() {
    this.ucardsModalContent = $('#ucards-modal-content');
    this.scheduleGlobalUcardLink = document.querySelector(
      '[data-behavior="schedule-global-ucard"]'
    );

    this.handleClick = this.handleClick.bind(this);
  }

  init() {
    this.bindEventListeners();
  }

  bindEventListeners() {
    if (!this.scheduleGlobalUcardLink) {
      return;
    }
    this.scheduleGlobalUcardLink.addEventListener('click', this.handleClick);
  }

  handleClick(e) {
    e.preventDefault();
    this.renderModal();
  }

  renderModal() {
    $.ajax({
      type: 'GET',
      url: '/students/ucards/schedule_global_ucard',
      success: (response) => {
        this.handleSuccess.bind(this)(response);
      },
      error: () => {
        this.handleError();
      }
    });
  }

  handleSuccess(response) {
    if (response.location) {
      Turbolinks.visit(response.location);
    } else {
      this.ucardsModalContent.html(response);
      $('#ucards-modal').modal();
    }
  }

  handleError() {
    return alert(
      'There was an error when redirecting to schedule your session.'
    );
  }
}

export default UcardScheduleModal
