class ErrorFieldFocus {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => this.focusFirstErrorElement());
    if (document.readyState === 'interactive' || document.readyState === 'complete' ) {
      this.focusFirstErrorElement();
    }
  }

  focusFirstErrorElement() {
    const error = document.querySelector('.form-text.error');
    if ((error !== null) && (error.parentNode.children.length > 0) && this.visibleErrors(error)) {
      const firstErrorId = error.parentNode.children[0].id;
      if(firstErrorId) { const firstErrorField = document.querySelector(`#${firstErrorId}`); }
      if (firstErrorField) { return firstErrorField.focus(); }
    }
  }

  visibleErrors(error){
    return (!error.classList.contains('d-none') &&
            !error.classList.contains('display-none'));
  }
}

export default ErrorFieldFocus;
