class SubmitButtonToggle {
  constructor() {
    this.conditionRadioButtons = document.querySelectorAll('[data-behavior="toggle-condition"]')
  }

  init() {
    this.bindEventListeners()
    this.handleConditionChange()
  }

  bindEventListeners() {
    this.conditionRadioButtons.forEach(condition => {
      condition.addEventListener('change', () => {this.handleConditionChange()});
    })
  }

  handleConditionChange(){
    const validGroup = document.querySelector('[data-group="valid"]');
    const invalidGroup = document.querySelector('[data-group="invalid"]');
    const target = document.querySelector('[data-behavior="toggle-condition"]:checked')

    if(target) {
      if (target.value === 'rejected') {
        validGroup.style.display = 'none';
        invalidGroup.style.display = '';
      } else {
        validGroup.style.display = '';
        invalidGroup.style.display = 'none';
      }
    }
  }
}

export default SubmitButtonToggle;
