
class FileTransferConfiguration {
  constructor() {
    this.platforms = ['s3'];
    this.storagePlatformSelect = document.querySelector('select[data-behavior="storage-platform-select"]');
    
    this.s3Fields = document.querySelector('div[data-behavior="s3-fields"]');
    this.handleStoragePlatformSelectChange = this.handleStoragePlatformSelectChange.bind(this);
    this.handleStoragePlatformFields = this.handleStoragePlatformFields.bind(this);
  }

  init() {
    if (this.storagePlatformSelect) {
      this.bindEventListeners();
    }
  }

  bindEventListeners() {
    this.storagePlatformSelect.addEventListener('change', this.handleStoragePlatformSelectChange)
    this.handleStoragePlatformSelectChange()
  }

  hideAllFields() {
    this.s3Fields.style = 'display: none';
  }

  handleStoragePlatformSelectChange() {
    const storagePlatform = $('#institution_file_transfer_configuration_attributes_storage_platform').val();
    this.hideAllFields();
    switch (storagePlatform) {
      case 's3':
        this[`${storagePlatform}Fields`].style = 'display: block';
        this.handleStoragePlatformFields(storagePlatform);
        break;
      default:
        this.handleStoragePlatformFields('');
    }
  }

  handleStoragePlatformFields(platform) {
    if(platform != '') {
      const formBlock = this[`${platform}Fields`];

      formBlock.querySelectorAll("input[name]").forEach(el => {
        el.required = true;
      });
      formBlock.querySelectorAll("label").forEach(el => {
        el.classList.add('required');
      });
    }

    this.platforms.forEach( pForm => {
      if( platform != pForm) {
        let unusedFormBlock = this[`${pForm}Fields`];
        if(unusedFormBlock) {
          unusedFormBlock.querySelectorAll("input[type='text']").forEach( el => {
            el.required = false;
          });
          unusedFormBlock.querySelectorAll("label").forEach( el => {
            el.classList.remove('required');
          });
        }
      }
    })
  }
}

export default FileTransferConfiguration;
