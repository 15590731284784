class UnlockExam {
  constructor() {
    this.unlockExamBtn = document.querySelector('[data-selector="unlock-exam-btn"]');
    this.sendExamPasswordToJava = this.sendExamPasswordToJava.bind(this);
  }

  init() {
    if (this.unlockExamBtn) {
      this.bindEventListeners();
      this.checkVisibility();
    }
  }

  bindEventListeners() {
    this.unlockExamBtn.addEventListener("click", this.sendExamPasswordToJava);
  }

  checkVisibility() {
    const isVisible = this.unlockExamBtn.getAttribute("data-is-visible");
    if (isVisible === "true") {
      this.unlockExamBtn.classList.remove("d-none");
    }
  }

  sendExamPasswordToJava() {
    const params = {
      uuid: this.unlockExamBtn.getAttribute("data-fulfillment-uuid"),
      password: this.unlockExamBtn.getAttribute("data-exam-password")
    };
    fetch("/api/unlock_exams", {
      method: "POST",
      credentials: "same-origin",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(params)
    }).then(() => {
      this.unlockExamBtn.classList.add("disabled");
    }).catch(() => {
      alert("Error: Could not connect extension.");
    });
  }
}

export default UnlockExam;
