var mobx = require('mobx');

/**
 * Video player data.
 * Changes invokes rendering of video player.
 */
class VideoPlayerStore {
  constructor() {
    const videoData = {
      live: false,
      showStudentStatus: false,
      screenStatus: 'disconnected',
      play: true,
      seeking: false,
      fetching: false,
      speedValue: 1,
      durationValue: 0,
      durationTime: 0,
      chunksDuration: 0,
      incidents: null,
      refinedExamCompleted: null,
      videoPace: null,
      screenVideo: {
        chunks: null,
        currentChunk: 0,
        currentPlayer: 1,
        error: null,
      },
      videoDiscrepancy: 0,
      liveDurationTime: 0,
      lastScreenVideoCurrentTime: 0,
      lastScreenVideoChunk: 0,
      syncTime: null,
      isTimerStopped: false,
      hideControls: false,
      totalDuration: null,
    };

    this.videoData = mobx.observable(videoData);
  }

  /**
   * Update video player data.
   * @param {object} data - updated data.
   */
  setData(data) {
    Object.keys(data).forEach((param) => {
      this.videoData[param] = data[param];
    });
  }
}

export default VideoPlayerStore;
