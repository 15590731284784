import { useEffect, useRef } from 'react';
import { isEqual } from 'lodash';

const useDeepCompareMemoize = (value) => {
  const ref = useRef();

  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
};

const DeepCompare = (callback, dependencies) => {
  useEffect(() => {
    return callback();
  }, dependencies.map(useDeepCompareMemoize));
};

export default DeepCompare;
