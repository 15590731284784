import axios from 'axios';
import PropTypes from 'prop-types';
import csrfToken from '../../../src/utils/csrf'

class EventFeeder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      feedType: this.props.feedType,
      status: this.props.feedStatus,
      disabled: false
    }
  }

  handleClick = (state) => {
    if ( ['CONTRABAND', 'PRESENCE'].includes(this.state.feedType) ) {
      this.createOrUpdateEventFeed('patch', state);
    } else {
      if (this.state.status == null) {
        this.createOrUpdateEventFeed('post', state);
      } else {
        this.createOrUpdateEventFeed('put', state);
      }
    }
  }

  createOrUpdateEventFeed = (method, state) => {
    this.setState({disabled: true});
    const url = new URL(this.props.feedUrl)
    const params = new URLSearchParams(url.search);
    const headers = {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken()
      }
    };
    axios({
      method: method,
      url: url.pathname,
      headers: headers,
      params: {
        fulfillment_id: params.get('fulfillment'),
        event_id: params.get('event'),
        user_id: params.get('user'),
        status: state
      }
    })
    .then(response => {
      this.setState({status: state, disabled: false})
      this.props.transformStore({
        type: "updateEventFeed",
        params: {
          eventId: this.props.uiMessageId,
          state: state
        }
      });
    });
  }


  render() {
    return (
      <span className="float-right">
        <span className={`btn btn-sm btn-secondary ${this.state.status == 'confirmed' ? 'active' : ''} ${this.state.disabled == true ? 'disabled' : ''} mr-1`}
              onClick={() =>  this.handleClick('confirmed')}
        >
          <i className="fa fa-thumbs-up"></i>
        </span>
        <span className={`btn btn-sm btn-secondary ${this.state.status == 'denied' ? 'active' : ''} ${this.state.disabled == true ? 'disabled' : ''}`}
              onClick={() =>  this.handleClick('denied')}
        >
          <i className="fa fa-thumbs-down"></i>
        </span>
      </span>
    )
  }
}

EventFeeder.propTypes = {
  feedStatus: PropTypes.string, //confirmed, denied, or null
  feedUrl: PropTypes.string.isRequired, // event reviews URL
  transformStore: PropTypes.func.isRequired, // manipulates incidents data store (IncidentsModel)
  uiMessageId: PropTypes.number.isRequired, //event ID
  feedType: PropTypes.string, // Type of event
};

export default EventFeeder;
