class EnableRockwellVoucher {
  constructor() {
    this.checkbox = document.querySelector(
      '[data-behavior="enable-rockwell-vouchers-checkbox"]',
    );
    this.voucherGroup = document.querySelector(
      '[data-behavior="enable-rockwell-vouchers-group"]',
    );

    this.toggleVoucherGroup = this.toggleVoucherGroup.bind(this);
  }

  init() {
    this.toggleVoucherGroup();
    this.bindEventListeners();
  }

  bindEventListeners() {
    if (this.checkbox) {
      this.checkbox.addEventListener('change', this.toggleVoucherGroup);
    }
  }

  toggleVoucherGroup() {
    if (this.checkbox) {
      this.voucherGroup.style.display = this.checkbox.checked
        ? 'block'
        : 'none';
    }
  }
}

export default EnableRockwellVoucher;
