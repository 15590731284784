/**
 * setParams - Sets URL parameters and reloads the page with those parameters.
 *
 * @param  {Array} params The params to be set. An Array of objects with the form
 *                        { key: String, value: String / Number }
 */
export default function setParams(params) {
  let url = window.location.href;
  const urlParams = url.replace('?', '&').split('&');

  for (const param of params) {
    const paramPair = urlParams.filter(p => {
      return p.includes(param.key);
    })[0];
    if (paramPair == null) {
      url += `&${param.key}=${param.value}`;
    } else {
      url = url.replace(paramPair, `${param.key}=${param.value}`);
    }
  }
  window.location.href = url;
}
