import csrfToken from '../utils/csrf';

class WalkMe {
  constructor() {
    this.bindEventListeners();
  }

  init() {
    this.fetchUser();
  }

  bindEventListeners() {
    this.handleError = this.handleError.bind(this);
    this.fetchUser = this.fetchUser.bind(this);
  }

  handleError() {
    console.error("Unable to set user for WalkMe.")
  }

  // Fetch walkme user and store in window object
  fetchUser() {
    if(!window.sessionStorage.walkMeCache || window.sessionStorage.walkMeCache === 'undefined') {
      fetch('/walk-me', {
        method: 'GET',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'content-type': 'application/json',
          'X-CSRF-Token' : csrfToken()
        }
      })
      .then( response => {
        if (response.ok) {
          return response.json();
        } else {
          this.handleError();
        }
      })
      .then( data => {
        if( typeof data !== 'undefined') {
          window.sessionStorage.walkMeCache = JSON.stringify(data);
          window.walkMeUser = data;
        }
      })
      .catch( error => {
        this.handleError();
      });
    } else {
      window.walkMeUser = JSON.parse(window.sessionStorage.walkMeCache);
    }
  }
}

export default WalkMe;
