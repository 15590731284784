import Toast from '../utils/Toast';

// AccommodationCreator
//
// Creates the Accommodation in the background, receives the ID,
// and populates the hidden input on the form to be submitted
//  to the user's accommodation_enrollments
class AccommodationCreator {
  constructor(button) {
    this.accommodationButton = button;
    this.handleCreation = this.handleCreation.bind(this);
  }

  init() {
    this.accommodationButton.addEventListener('click', this.handleCreation);
  }

  handleCreation(event) {
    event.preventDefault();
    const url = event.target.dataset.url;
    const input = $(event.target)
      .closest('.row')
      .find('[data-element="accommodation-input"]');
    this.sendRequest(event, url, input);
  }

  sendRequest(event, url, input) {
    $.ajax({
      type: 'POST',
      url: url,
      contentType: 'application/json',
      data: JSON.stringify({ note: input.val() }),
      dataType: 'json',
      success: data => {
        this.handleSuccess(data, input, event);
      },
      error: this.handleError
    });
  }

  handleSuccess(data, input, event) {
    this.addValueToHiddenInput(event, data);
    this.disableInputAndHideButton(input);
    this.widenInput(event);
  }

  addValueToHiddenInput(event, data) {
    $(event.target)
      .closest('.row')
      .find('[data-element="accommodation-id-value"]')
      .val(data['id']);
  }

  disableInputAndHideButton(input) {
    input.prop('disabled', true);
    $(this.accommodationButton)
      .parent()
      .hide();
  }

  widenInput(event) {
    $(event.target)
      .closest('.row')
      .find('.col-md-10')
      .removeClass('col-md-10')
      .addClass('col-md-12');
  }

  handleError(data) {
    new Toast().danger({
      message: data.responseJSON['message']
    });
  }
}

export default AccommodationCreator;
