import PropTypes from 'prop-types';

class IncidentReportBlankRow extends React.Component {
  constructor(props){
    super(props);
  }

  render = () => {
    return (
      <tr>
        <td colSpan='11'>
          <div className='row'>
            <div className='col-md-12'>
              <h1 className='fs-3 text-center'>
                <img src={this.props.gifPath} width="75" /><br />
                <span>{this.props.blankMsg}</span>
              </h1>
            </div>
          </div>
        </td>
      </tr>
    )
  }
}

IncidentReportBlankRow.propTypes = {
  gifPath: PropTypes.string.isRequired,
  blankMsg: PropTypes.string.isRequired
}

export default IncidentReportBlankRow;
