import moment from 'moment';

class TimezoneWarning {
  constructor() {
    this.timezoneWarning = $('#timezone-warning')
  }

  init() {
    if(this.timezoneWarning.data('timezone') !== moment().format('ZZ')) {
      this.timezoneWarning.show()
    }
  }
}

export default TimezoneWarning;
