import csrfToken from '../utils/csrf';
import Toast from '../utils/Toast';

class ClassicNotepad {
  constructor() {
    this.submitButton = document.querySelector('input[type=submit]#create-comment')
    this.panel = this.submitButton.parentElement
    this.notepad = this.panel.querySelector('#notepad')
    this.incident = this.panel.querySelector('#incident')
    this.notePadContainer = this.notepad.parentElement
  }

  init() {
    this.submitButton.addEventListener('click', (event) => { this.handleClick(event) })
  }

  handleClick() {
    if (this.notepad.value.trim().length < 1) {
      new Toast().warning({message: 'Please add a comment.'})
      return;
    }
    const params = this.buildParams();
    const headers = {
      headers: {
        Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
      }
    }
    axios.post(this.submitButton.getAttribute('data-url'), JSON.stringify(params), headers).
    then(response => {
      if (document.querySelector('.js-notes-bare').length) {
        new Toast().success({message: 'TouchPoint saved.'})
      } else {
        this.createNewEvent(response)
      }
    }).catch(error => {
      new Toast().danger({message: `There was an error: ${error}`})
    })

  }

  buildParams() {
    let type = 'Comment';
    let touch_point_kind = null;
    if (document.querySelector('.js-notes-bare').length) {
      type = 'TouchPoint'
      touch_point_kind = document.querySelector('#touch_point_kind').value
    }
    if (this.incident) { type = 'Incident' }
    const params = {
      type: type,
      event: {
        comment: this.notepad.value,
        touch_point_kind: touch_point_kind
      }
    }
    return params
  }

  createNewEvent(response) {
    let heading = 'Comment'
    new Toast().success({message: 'Comment saved.'})
    const newEvent = document.querySelector('.js-event-empty').cloneNode(true);
    newEvent.querySelector('.time').innerHTML = response.date;
    newEvent.querySelector('.body').innerHTML = response.comment;
    if (this.incident) {
      heading = 'Incident Report'
      this.incident.checked = false;
      newEvent.querySelector('.fa').classList.add('fa-exclamation');
      newEvent.classList.add('list-group-item-danger bg-danger');
      newEvent.querySelector('.icon').classList.add('incident');
    } else {
      newEvent.querySelector('.fa').classList.add('fa-comment');
    }
    newEvent.querySelector('.heading').innerHTML = `${heading} created by ${response.name}`;
    newEvent.classList.remove('d-none js-event-empty');
    const timeline = document.querySelector('.timeline')
    timeline.insertBefore(newEvent, timeline.firstChild);
  }
}

export default ClassicNotepad;
