// For the general settings page on the institutions#edit page
class GeneralSettingsDepartmentsForm {
  constructor() {
    this.handleSecuritySettingsCheckBox = this.handleSecuritySettingsCheckBox.bind(this);

    this.securitySettingsCheckBox = document.querySelector('#security_settings_check_box');
    this.securitySettings = document.querySelector('#security_settings');
  }

  init() {
    if (this.securitySettingsCheckBox.checked) {
      $(this.securitySettings).show();
    } else {
      $(this.securitySettings).hide();
    }

    this.bindEventListeners();
  }

  bindEventListeners() {
    this.securitySettingsCheckBox.addEventListener('change', this.handleSecuritySettingsCheckBox);
  }

  handleSecuritySettingsCheckBox(event) {
    if (event.target.checked) {
      $(this.securitySettings).show();
    } else {
      $(this.securitySettings).hide();
    }
  }
}

export default GeneralSettingsDepartmentsForm;
