import axios from 'axios';
import Callbacks from '../../../src/utils/Callbacks';
import ChatView from './ChatView';
import CopernicusHelper from '../../../utils/CopernicusHelper';

class AudioCommunicator {
  /**
   * @param {String} userId - a user identifier
   * @param {String} userUuid - a user identifier
   * @param {String} studentId - a student identifier
   * @param {String} fulfillmentId - a fulfillment identifier
   * @param {String} sessionUuid - UUID for the session
   * @param {string} videoLayout - The layout of the video combined, legacy, or combinedWithSecondCamera
   * @param {String} mediaServerUrl - Url for copernicus instance
   * @param {String} videoServiceUrl - Url for video service
   * @param {import('react').RefObject<HTMLAudioElement>} audioOutput - a ref to the audio element
   * @param {{url: string, username: string, credential: string}[]} iceServers - Ice server information
   * @param {Function} onIncomingCall - invokes when user calls proctor
   * @param {Function} onCallResponse - start timer when call was accepted
   * @param {Function} onEndCall - clear state when call was finished
   * @param {String} region - AWS region for recording/chat
   */
  constructor(
    userId,
    userUuid,
    studentId,
    fulfillmentId,
    sessionUuid,
    videoLayout,
    mediaServerUrl,
    videoServiceUrl,
    audioOutput,
    iceServers,
    onIncomingCall,
    onCallResponse,
    onEndCall,
    displayChatMessages,
    callSound,
    region,
  ) {
    this.userId = userId;
    this.userUuid = userUuid;
    this.studentId = studentId;
    this.fulfillmentId = fulfillmentId;
    this.sessionUuid = sessionUuid;
    this.videoLayout = videoLayout;
    this.mediaServerUrl = mediaServerUrl;
    this.videoServiceUrl = videoServiceUrl;
    this.audioOutput = audioOutput;
    this.iceServers = iceServers;
    this.onIncomingCall = onIncomingCall;
    this.onCallResponse = onCallResponse;
    this.onEndCall = onEndCall;
    this.displayChatMessages = displayChatMessages;
    this.ringingInterval = null;
    this.soundInterval = null;
    this.messageSoundUrl = callSound;
    this.deviceId = null;
    this.cjs = null;
    this.region = region;
  }

  /**
   * Initialize the chat communicator.
   */
  async init() {
    this.createMessageSound();

    /** @type {import('@meazure/copernicusjs').CjsSettings} */
    const options = {
      role: 'proctor',
      userID: this.userId,
      userUUID: this.userUuid,
      exam: this.fulfillmentId,
      videoLayout: this.videoLayout,
      mediaServerUrl: new URL(this.mediaServerUrl),
      videoServiceUrl: new URL(this.videoServiceUrl),
      region: this.region,
      iceServers: this.iceServers,
      domElements: {
        audioOutput: this.audioOutput,
      },
      devices: {
        audioInput: this.deviceId,
      },
    };

    /** @type {import('@meazure/copernicusjs').CjsCallbacks} */
    const callbacks = {
      onCallStarted: async (event) => {
        // There is an incoming call from the student that we should accept
        await this.callStarted();
      },
      onCallAccepted: (event) => {
        // We have accepted the call from the student
        this.callAccepted();
      },
      onCallEnded: (event) => {
        this.endCall();
      },
      onCallRejected: (event) => {
        this.endCall();
      },
      onCallFailed: (event) => {
        this.endCall();
      },
      onMessageProcessed: (event) => {
        this.messageProcessed(event);
      },
    };

    this.cjs = await CopernicusHelper.getInstance(
      'ChatApp',
      options,
      callbacks,
    );
  }

  createMessageSound() {
    this.messageSound = new Audio(this.messageSoundUrl);
    this.messageSound.load();
  }

  async startCall() {
    if (this.cjs === null) {
      return console.error('[ChatApp] cannot start call, cjs is null');
    }
    await this._setDeviceId();

    this.ringingInterval = setInterval(() => {
      this.messageSound.play();
    }, 2000);
    await this.cjs.startCall(this.userId, this.studentId);
    this.onCallResponse();
  }

  async acceptCall() {
    await this.cjs.acceptIncomingCall(this.userId);
    this.clearAudioChat();
  }

  async callStarted() {
    this.onIncomingCall();
    this.soundInterval = setInterval(() => {
      this.messageSound.play();
    }, 1500);
    await this._setDeviceId();
  }

  callAccepted() {
    this.clearAudioChat();
    this.onCallResponse();
  }

  endCall() {
    if (this.cjs === null) {
      return console.error('[ChatApp] cannot end call, cjs is null');
    }
    this.cjs.stopCall();
    this.onEndCall();
    this.clearAudioChat();
  }

  messageProcessed(parsedMessage) {
    //when chatCommunicator was creating an instance the fulfillmentId is used as the fulfillment which is then used as the uuid, is this a mistake?
    if (parsedMessage.userType == 'student') {
      const body = {
        message: parsedMessage.chatMessage,
        fulfillment_uuid: this.fulfillmentId,
        notification_type: 'chat',
      };
      $(window).trigger('pushNotification', body);
    }
    this.displayChatMessages(parsedMessage);
  }

  mute() {
    if (this.cjs === null) {
      return console.error('[ChatApp] cannot mute, cjs is null');
    }
    this.cjs.muteProctorMicrophone();
  }

  /**
   * clears audio chat sound and ringing
   */
  clearAudioChat() {
    clearInterval(this.soundInterval);
    clearInterval(this.ringingInterval);
  }
  /**

   * Ask for mic permissions and get the audio device id.
   *
   * @returns {Promise<String>} - the audio device id
   */
  async _audioDeviceId() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      stream.getTracks().forEach((t) => t.stop());
      const devices = await navigator.mediaDevices.enumerateDevices();
      const audioInput = devices.find(
        (d) => d.kind === 'audioinput' && d.deviceId !== 'default',
      );
      return audioInput?.deviceId;
    } catch (err) {
      console.error('[ChatApp] error getting audio device id', err);
      return null;
    }
  }

  /**
   * Attempts to set `this.deviceId` and inform cjs of the change.
   *
   * @returns {Promise<void>}
   */
  async _setDeviceId() {
    if (this.cjs === null) {
      return console.error('[ChatApp] cannot set device id, cjs is null');
    }

    const newDeviceId = await this._audioDeviceId();

    if (newDeviceId === null) {
      return alert(
        'Please grant access to your microphone and refresh the page to use audio chat.',
      );
    }

    if (newDeviceId !== this.deviceId) {
      this.deviceId = newDeviceId;
      await this.cjs.changeDevice('chat', this.deviceId);
    }
  }
}

// TODO: this thing is an awful mess and lets please get rid of it one day
/**
 * Main component for chat app.
 */
class ChatApp extends React.Component {
  constructor(props) {
    super(props);
    const {
      fulfillmentId,
      javaBackendHost,
      proctorName,
      chatEnabled,
      expanded,
    } = props;

    this.state = {
      messages: [],
      activeCall: false,
      acceptedCall: false,
      callDuration: 0,
      proctorName: proctorName,
      chatEnabled: chatEnabled,
      microphoneIsMuted: false,
      expanded: expanded,
    };

    this.audioOutput = React.createRef();

    this.getMessagesHistory(fulfillmentId, javaBackendHost);
  }

  componentDidMount() {
    const {
      fulfillmentId,
      userId,
      userUuid,
      studentId,
      videoLayout,
      javaBackendSocket,
      callSound,
      proctorName,
      sessionIsRunning,
      sessionUuid,
      streamHost,
      videoService,
      iceServers,
      region,
    } = this.props;
    this.interval = null;
    this.callbacks = new Callbacks();

    $('#chat-app').on('updateChatApp', (event, data) => {
      this.setState({ proctorName: event.detail || data });
    });

    if (sessionIsRunning) {
      this.audioCommunicator = new AudioCommunicator(
        userId,
        userUuid,
        studentId,
        fulfillmentId,
        sessionUuid,
        videoLayout,
        streamHost,
        videoService,
        audioOutput,
        iceServers,
        this.onIncomingCall,
        this.onCallResponse,
        this.onEndCall,
        this.displayChatMessages,
        callSound,
        region,
      );
      void this.audioCommunicator.init();

      $('#chat-app').on('lockChat', (event) => {
        this.setState({ chatEnabled: false });
      });

      $('#chat-app').on('unlockChat', (event) => {
        this.setState({ chatEnabled: true });
      });

      this.callbacks.on(this.handleEvents);
    }
  }

  /**
   * Handle various events
   * @param {Object} event - an incoming event
   */
  handleEvents = (event) => {
    const eventHandlers = {
      unmountChat: (data) => {
        // this.chatCommunicator.closeSocket();
      },
    };

    const eventHandler = eventHandlers[event.type];
    eventHandler && eventHandler(event.data);
  };

  handleCollapse = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  /**
   * Start call timer.
   */
  startCallTimer = () => {
    if (this.interval) clearInterval(this.interval);
    this.interval = setInterval(() => this.tick(), 1000);
  };

  /**
   * Timer implementation.
   */
  tick = () => {
    this.setState({ callDuration: this.state.callDuration + 1 });
  };

  /**
   * Incoming call callback. Changes state of component.
   */
  onIncomingCall = () => {
    this.notify_proctor();
    this.audioCommunicator.soundInterval = setInterval(() => {
      this.messageSound.play();
    }, 1500);
    this.setState({ activeCall: true });
  };

  /**
   * Notification of the proctor about an incoming call
   */
  notify_proctor = () => {
    const body = {
      message: 'Student is calling',
      fulfillment_uuid: this.props.fulfillmentId,
      notification_type: 'chat',
    };
    $(window).trigger('pushNotification', body);
  };

  /**
   * Display message from proctor
   */

  displayChatMessages = (message) => {
    const messageObject = {
      chatMessage: message.chatMessage,
      userType: message.userType,
      messageTime: message.messageTime,
    };
    this.addMessage(messageObject);
  };

  /**
   * Send request to java and get messages history.
   */
  getMessagesHistory = (fulfillmentId, javaBackendHost) => {
    // TODO: this should probably no longer be supported
    axios({
      method: 'GET',
      url: `https://${javaBackendHost}/chat/history/${fulfillmentId}?fid=${fulfillmentId}`,
    })
      .then((response) => {
        Array.isArray(response.data) &&
          this.setState({ messages: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /**
   * Start call with student.
   */
  startCall = (event) => {
    event.stopPropagation();
    this.setState({ acceptedCall: true });
    void this.audioCommunicator.startCall();
  };

  /**
   * End call with student.
   */
  endCall = (event) => {
    event.stopPropagation();
    this.audioCommunicator.endCall();
    this.setState({
      activeCall: false,
      acceptedCall: false,
      callDuration: null,
      microphoneIsMuted: false,
    });
    clearInterval(this.interval);
  };

  /**
   * Start timer and change voice chat state to accepted call.
   */
  onCallResponse = () => {
    this.setState({ acceptedCall: true, activeCall: true });
    this.startCallTimer();
    this.audioCommunicator.clearAudioChat();
  };

  /**
   * Accept student's call.
   */
  acceptCall = async (event) => {
    event.stopPropagation();
    this.setState({ acceptedCall: true });
    this.startCallTimer();
    await this.audioCommunicator.acceptCall();
  };

  /**
   * Reset state on end call.
   */
  onEndCall = () => {
    this.setState({
      activeCall: false,
      callDuration: null,
      acceptedCall: false,
      microphoneIsMuted: false,
    });
    clearInterval(this.interval);
  };

  /**
   * Mute microphone.
   */
  switchAudioOutputState = (event) => {
    event.stopPropagation();
    this.setState({ microphoneIsMuted: !this.state.microphoneIsMuted });
    this.audioCommunicator.mute();
  };

  /**
   * Handles send message event.
   */
  sendHandler = (chatMessage) => {
    const messageObject = {
      id: 'chatMessage',
      message: chatMessage,
      exam: this.props.fulfillmentId,
      proctorName: this.state.proctorName || this.props.proctorName,
    };
    this.sendMessage(messageObject);
  };

  /**
   * Send message to server.
   */
  sendMessage = (messageObject) => {
    void this.audioCommunicator.cjs.sendChatMessage(messageObject);
  };

  /**
   * Adds message to messages array.
   */
  addMessage = (message) => {
    const { messages } = this.state;
    messages.push(message);
    this.setState({ messages });
  };

  /**
   * @see Component#render()
   */
  render() {
    const {
      messages,
      activeCall,
      callDuration,
      acceptedCall,
      chatEnabled,
      microphoneIsMuted,
    } = this.state;
    const { studentName, sessionIsRunning } = this.props;

    return (
      <div>
        <audio id="audioOutput" ref={this.audioOutput} autoPlay />

        <ChatView
          studentName={studentName}
          chatEnabled={chatEnabled}
          sessionIsRunning={sessionIsRunning}
          messages={messages}
          sendHandler={this.sendHandler}
          startCall={this.startCall}
          acceptCall={this.acceptCall}
          endCall={this.endCall}
          switchAudioOutputState={this.switchAudioOutputState}
          activeCall={activeCall}
          callDuration={callDuration}
          acceptedCall={acceptedCall}
          microphoneIsMuted={microphoneIsMuted}
          expanded={this.state.expanded}
          handleCollapse={this.handleCollapse}
          useNativeChat={this.props.useNativeChat ?? false}
          nativeChatPath={this.props.nativeChatPath}
          nativeChatProps={this.props.nativeChatProps}
        />
      </div>
    );
  }
}

export default ChatApp;
