import React from 'react';

export const ReservationListItem = ({ children }) => {
  return (
    <div>
      <h5 className="card-title">Reservation List Item</h5>
      {children}
    </div>
  );
};
