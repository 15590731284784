import PropTypes from 'prop-types';

class IncidentAlert extends React.Component {
  componentDidMount() {
    this.timeoutAlert()
  }

  componentWillUnmount() {
    clearTimeout(this._timer);
  }

  timeoutAlert() {
    this._timer != null ? clearTimeout(this._timer) : null;
    this._timer = setTimeout(() => {
      this.props.hideAlert();
    }, 5000);
  }

  classes() {
    return ['alert', 'alert-form', 'alert-' + this.props.type].join(' ')
  }

  render() {
    var alertClass = this.classes();

    return (
      <div className="col-md-12">
        <div className={alertClass} role="alert">
          {this.props.text}
        </div>
      </div>
    );
  }
}

IncidentAlert.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  hideAlert: PropTypes.func.isRequired
}

export default IncidentAlert;
