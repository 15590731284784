import React, { useState } from 'react';

import { ReservationListItem } from './ReservationListItem';

export const UnscheduledExams = () => {
  const [exams, setExams] = useState([1, 2, 3]);
  return (
    <div className="card">
      <div className="card-header bg-dark text-white">Unscheduled Exams</div>
      <div className="card-body">
        {exams.map((eachExam) => (
          <ReservationListItem key={eachExam}>
            <button>Schedule</button>
          </ReservationListItem>
        ))}
      </div>
    </div>
  );
};
