import consumer from '../consumer';

class FulfillmentVideoSubscription {
  constructor(fulfillmentId, proctorId) {
    this.identifier = {
      channel: 'FulfillmentVideoChannel',
      fulfillment_uuid: fulfillmentId,
      proctor_id: proctorId,
    };
    this.fulfillmentWindow = null;
  }

  init() {
    console.log('fulfillment_video#init');
    this.subscribe();
  }

  subscribe() {
    console.log('fulfillment_video#subscribing');
    this.fulfillmentWindow = consumer.subscriptions.create(this.identifier, {
      received: (data) => {
        console.log(`Received ${data}`);
      },
      connected: () => {
        console.log('Connected');
      },
      disconnected: () => {
        console.log('Disconnected');
      },
    });

    consumer.connection.webSocket.onerror = function (error) {
      console.error('Websocket connection error!', error);
    };
  }

  sendMessage(data) {
    console.log('Sending', data);
    if (this.fulfillmentWindow) {
      this.fulfillmentWindow.send(data);
    }
  }
}

export default FulfillmentVideoSubscription;
