export default function isInstitution() {
  var url = window.location.href,
      index = url.indexOf('institution');

  if (index < 0) {
    return false;
  } else {
    return true;
  }
}
