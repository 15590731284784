class ProctorLanguageSelection {
  constructor() {
    this.languageSelect = document.querySelector(
      '[data-behavior="preferred-proctoring-language"]',
    );
  }

  init() {
    this.initEventListeners();
  }

  // Initialize event listeners for language selection
  initEventListeners() {
    this.languageSelect?.addEventListener('change', (event) => {
      this.updateLanguageInputs(event.target.value);
    });
  }

  updateLanguageInputs(language_id) {
    $('input[name="reservation[language_id]"]').val(language_id);
  }
}

export default ProctorLanguageSelection;
