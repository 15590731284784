import PropTypes from 'prop-types';
import EventFeeder from '../EventFeeder';
import DeleteIncident from '../DeleteIncident';
import Button from '../Button';

class IncidentWithScores extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      scoreEditMode: false,
      value: this.props.score || 0,
      defaultScore: this.props.score || 0
    }
  }

  handleChange = ({ target: t }) => {
    t.value >= 0 && this.setState({ value: t.value })
  }

  resetScoresToDefault = () => {
    const score = this.state.defaultScore
    this.setState({
      value: score,
      scoreEditMode: false
    })
  }

  switchToEditMode = () => {
    this.setState({ scoreEditMode: true })
  }

  confirm = (event) => {
    this.setState({ scoreEditMode: false })
    this.saveAndExit()
    event.preventDefault()
  }

  saveAndExit = () => {
    const score = parseInt(this.state.value) || 0
    const { uiMessageId, transformStore } = this.props
    this.setState({
      scoreEditMode: false,
      defaultScore: score,
      value: score
    })
    transformStore({
      type: "updateIncidentScore",
      params: {
        incidentId: uiMessageId,
        score
      }
    })
  }

  render() {
    const { createdAt,
      showInPlayer,
      isDeleteCandidate,
      transformStore,
      uiMessageId,
      showEventFeed,
      feedable,
      feedStatus,
      feedUrl,
      institutionUser
       } = this.props
    const { value, scoreEditMode } = this.state
    return (
      <div className="row align-items-center">
        <div className="col-1">
          <div className="h-100 text-center d-flex align-items-center justify-content-center">
            <span className="fad fa-play-circle fa-lg player-icon cursor-pointer" onClick={showInPlayer}/>
          </div>
        </div>
        <div className="col-8">
          <small className="text-muted">{createdAt}</small>
          <div>
            {
              scoreEditMode
              ? <form className="form-inline" onSubmit={confirm}>
                  <span className="scores-edit-container form-group">
                    <input
                      min="0"
                      autoFocus
                      type="number"
                      className="form-control"
                      value={value}
                      onChange={this.handleChange}
                    />
                    <Button
                      icon={"fa-check fa-border"}
                      type={"submit"}
                      callback={this.confirm}
                    />
                    <Button
                      icon={"fa-ban fa-border"}
                      callback={this.resetScoresToDefault}
                    />
                  </span>
                </form>
              : <span>
                  <span className="points-amount">{`+${value} points`}</span>
                  <Button
                    icon={"fa-pencil-alt edit-scores-icon"}
                    callback={this.switchToEditMode}
                  />
                </span>
            }
            {!institutionUser && (
            <DeleteIncident 
              uiMessageId={uiMessageId}
              transformStore={transformStore}
              isDeleteCandidate={isDeleteCandidate}
        />)}
          </div>
        </div>
        <div className="col-3">
          { showEventFeed && feedable &&
            <EventFeeder feedStatus={feedStatus} feedUrl={feedUrl} transformStore={transformStore} uiMessageId={uiMessageId} />
          }
        </div>
      </div>
    )
  }
}

IncidentWithScores.propTypes = {
  score: PropTypes.number.isRequired,
  createdAt: PropTypes.string.isRequired,
  showInPlayer: PropTypes.func.isRequired,
  uiMessageId: PropTypes.number.isRequired,
  transformStore: PropTypes.func.isRequired,
  isDeleteCandidate: PropTypes.bool.isRequired,
  showEventFeed: PropTypes.bool,
  feedable: PropTypes.bool,
  feedStatus: PropTypes.string,
  feedUrl: PropTypes.string
}

export default IncidentWithScores
