import PropTypes from 'prop-types';

const FulfillmentScheduled = ({
  createdAt,
  showInPlayer,
  text,
  heading
}) => {
  return (
    <div className="row align-items-center">
      <div className="col-11 offset-1">
        <small className="text-muted">{createdAt}</small>
        <div>
          {heading}
        </div>
        <div>
          {text}
        </div>
      </div>
    </div>
  );
}

FulfillmentScheduled.propTypes = {
  createdAt: PropTypes.string.isRequired,
  showInPlayer: PropTypes.func.isRequired,
  transformStore: PropTypes.func,
  uiMessageId: PropTypes.number,
  showEventFeed: PropTypes.bool,
  feedable: PropTypes.bool,
  feedStatus: PropTypes.string,
  feedUrl: PropTypes.string,
  heading: PropTypes.string
}

export default FulfillmentScheduled;
