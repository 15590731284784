// For the general settings page on the institutions#edit page
class GeneralSettingsForm {
  constructor() {
    this.notifyOnEventsCheckbox = document.querySelector(
      '[data-behavior=notify-institution-checkbox]'
    );

    this.eventEmailsInput = document.querySelector(
      '[data-behavior=notify-institution-form-group]'
    );

    this.displayDurationCheckbox = document.querySelector(
      '[data-behavior=display-duration-checkbox]'
    );

    this.alternateDisplayInput = document.querySelector(
      '[data-behavior=display-duration-message-form-group]'
    );

    this.handleNotifyToggle = this.handleNotifyToggle.bind(this);
    this.handleDurationToggle = this.handleDurationToggle.bind(this);
    this.handleSecuritySettingsCheckBox = this.handleSecuritySettingsCheckBox.bind(this);

    this.securitySettingsCheckBox = document.querySelector('#security_settings_check_box');
    this.securitySettings = document.querySelector('#security_settings');
  }

  init() {
    if (this.displayDurationCheckbox.checked) {
      this.alternateDisplayInput.style.display = 'none';
    } else {
      this.alternateDisplayInput.style.display = 'block';
    }

    if (this.securitySettingsCheckBox.checked) {
      $(this.securitySettings).show();
    } else {
      $(this.securitySettings).hide();
    }

    this.bindEventListeners();
  }

  bindEventListeners() {
    this.notifyOnEventsCheckbox.addEventListener('change', this.handleNotifyToggle);
    this.displayDurationCheckbox.addEventListener('change', this.handleDurationToggle);
    this.securitySettingsCheckBox.addEventListener('change', this.handleSecuritySettingsCheckBox);
  }

  handleNotifyToggle(event) {
    if (event.target.checked) {
      this.eventEmailsInput.classList.add('has-warning');
    } else {
      this.eventEmailsInput.classList.remove('has-warning');
    }
  }

  handleDurationToggle(event) {
    if (event.target.checked) {
      $(this.alternateDisplayInput).slideUp('fast');
    } else {
      $(this.alternateDisplayInput).slideDown('fast');
    }
  }

  handleSecuritySettingsCheckBox(event) {
    if (event.target.checked) {
      $(this.securitySettings).show();
    } else {
      $(this.securitySettings).hide();
    }
  }
}

export default GeneralSettingsForm;
