class QuickLauncher {
  constructor() {
    this.COMPONENT_SELECTOR = '.js-quick-launch-container';
    this.$input = $(this.COMPONENT_SELECTOR).find('input');
    this.$target = $(this.COMPONENT_SELECTOR).find('.js-dropdown-container');
    this.$outside = $('body, body *').not('.js-dropdown-container');
    this.$button = $(this.COMPONENT_SELECTOR).find('button');

  }

  init() {
    this.bindEventListeners();
  }

  bindEventListeners() {
    this.$input.on('keyup', (event) => { this.queryFulfillment(event) });
    this.$input.on('click', (event) => { this.queryFulfillment(event) });
    this.$outside.on('click', (event) => { this.closeQuickLauncher(event) });

    this.$button.on('click', (event) => {
      event.preventDefault(event);
      this.queryFulfillment(event)
    });

  }

  queryFulfillment(event) {
    if (this.isEmpty()) {
      this.closeQuickLauncher();
      return;
    }

    if (this.invalidFulfillment()) {
      this.invalidLength();
    } else if (!event.ctrlKey) {
      this.fetchFulfillment();
    }
  }

  isEmpty() {
    return this.$input.val().length == 0;
  }

  invalidFulfillment() {
    return this.$input.val().length != 36
  }

  fetchFulfillment() {
    const uuid = this.$input.val();
    const url = `/fulfillments/${uuid}`
    $.getJSON(url, () => {
      this.openQuickLauncher(uuid);
    }).fail(() => {
      this.throwNotFound();
    });
  }

  openQuickLauncher(uuid) {
    if (this.templateExists('valid')) {
      return;
    }

    const templateData = {
      classes: 'valid',
      uuid: uuid
    }

    const template = `<ul class="dropdown-menu dropdown-quick-launch ${templateData.classes} p-0" role="menu">
                        <li>
                          <a role="menuitem" href="/fulfillments/${templateData.uuid}" target="_blank" class="dropdown-item p-3">
                            <i class="fa fa-clipboard block"></i>
                            Full page
                          </a>
                        </li>
                        <li>
                          <a role="menuitem" href="/fulfillments/${templateData.uuid}/flight_path" class="quick-launch-launcher dropdown-item p-3">
                            <i class="fa fa-rocket block"></i>
                            Launcher
                          </a>
                        </li>
                        <li>
                          <a role="menuitem" href="/internal/watcher/popouts/videos/${templateData.uuid}" class="quick-launch-watcher dropdown-item p-3">
                            <i class="fa fa-binoculars block"></i>
                            Watcher
                          </a>
                        </li>
                      </ul>
                      `

    this.$target.html(template);
    this.bindLinkEventListeners();
    this.$target.removeClass('d-none');
  }

  bindLinkEventListeners() {
    document.querySelector('.quick-launch-watcher').addEventListener('click', (event) => {
      event.preventDefault();
      window.open(event.target.getAttribute('href'), '_blank', 'height=525,width=384,noopener');
    });
    document.querySelector('.quick-launch-launcher').addEventListener('click', (event) => {
      event.preventDefault();
      window.open(event.target.getAttribute('href'), '_blank', 'height=600, width=600')
    });

  }

  throwError() {
    if (this.templateExists('invalid')) {
      return;
    }

    const templateData = {
      classes: 'invalid',
      text: 'You have entered an invalid Appointment ID. Please try again.'
    }
    const template = `<ul class="dropdown-menu dropdown-quick-launch ${templateData.classes}" role="menu">
                        <li class="dropdown-item p-2" role="presentation">
                          ${templateData.text}
                      </li>
                    </ul>`

    this.$target.html(template);
    this.$target.removeClass('d-none');
  }

  invalidLength() {
    if (this.templateExists('invalid_length')) {
      return;
    }

    const templateData = {
      classes: 'invalid',
      text: 'The ID you have entered must be 36 characters.'
    }
    const template = `<ul class="dropdown-menu dropdown-quick-launch ${templateData.classes}" role="menu">
                        <li class="dropdown-item p-2" role="presentation">
                          ${templateData.text}
                      </li>
                    </ul>`

    this.$target.html(template);
    this.$target.removeClass('d-none');
  }

  throwNotFound() {
    if (this.templateExists('not-found')) {
      return;
    }

    const templateData = {
      classes: 'not-found',
      text: 'Sorry, but that appointment does not exist.'
    }
    const template = `<ul class="dropdown-menu dropdown-quick-launch ${templateData.classes}" role="menu">
                        <li class="dropdown-item p-2" role="presentation">
                          ${templateData.text}
                      </li>
                    </ul>`

    this.$target.html(template);
    this.$target.removeClass('d-none');
  }

  templateExists(templateClass) {
    return $(this.COMPONENT_SELECTOR).find(`.${templateClass}`).length > 0;
  }

  closeQuickLauncher() {
    this.$target.addClass('d-none').empty();
  }
}

export default QuickLauncher;
