import { useEffect, useRef, useState } from 'react';
import PrechecksHeaderGroup from './CardSections/PrechecksHeaderGroup';
import PrechecksHeaderIcon from './CardSections/PrechecksHeaderIcon';
import PrechecksBodyText from './CardSections/PrechecksBodyText';
import PrechecksBodyCamera from './CardSections/PrechecksBodyCamera';
import PrechecksFooterButtonGroup from './CardSections/PrechecksFooterButtonGroup';

const ProfilePhotoCard = ({
  id,
  icon,
  iconColor,
  iconPrefix,
  title,
  backButtonText,
  handleNext,
  handlePrevious,
  sendEvent,
  streamWebcam,
  precheckData,
}) => {
  const userId = precheckData.user.id;
  const videoLayout = precheckData.fulfillment.videoLayout;
  const identificationImg = precheckData.images.identificationImg;
  const [pictureTaken, setPictureTaken] = useState(false);
  const [takeButtonDisabled, setTakeButtonDisabled] = useState(true);
  const [takeButtonText, setTakeButtonText] = useState(
    polyglot.t('prechecks_profile_photo_take_photo_button'),
  );
  const canvasRef = useRef();
  const videoRef = useRef();
  const height = videoLayout == 'legacy' ? 240 : 180;
  const width = 320;
  const titleText =
    pictureTaken == false
      ? polyglot.t('prechecks_profile_photo_text_1')
      : polyglot.t('prechecks_profile_photo_text_2');
  const profilePhotoOutlineData = precheckData.assets.profilePhotoOutline;
  const outlineHidden = pictureTaken == false ? false : true;
  const overlayBg = useRef();
  const overlayIcon = useRef();
  const overlayHidden = true;
  const overlayStyling = {
    backgroundColor: 'white',
    width: '320px',
    height: videoLayout == 'legacy' ? '240px' : '180px',
    left: '29.7%',
    border: 'gray solid 1px',
  };

  useEffect(() => {
    sendEvent('Event::ProfilePhotoStepLoaded');
    streamWebcam(videoRef.current);
    setTakeButtonDisabled(false);
  }, []);

  const handleTakePhoto = () => {
    setTakeButtonDisabled(true);
    overlayBg.current.hidden = false;
    overlayIcon.current.hidden = false;
    setTimeout(() => (overlayBg.current.hidden = true), 800);
    setTimeout(() => (overlayIcon.current.hidden = true), 800);
    const context = canvasRef.current.getContext('2d');
    context.translate(0, 0);
    context.drawImage(videoRef.current, 0, 0, width, height);
    sendEvent('Event::TakeProfilePhoto');
    sendEvent(
      'Event::PictureConfirmation',
      {
        createdById: userId,
        attachmentUrl: canvasRef.current.toDataURL('image/png'),
        // sending true means that the face is verified
        data: true,
      },
      'image_checks',
      (response) => {
        if (response.status === 201) {
          setPictureTaken(true);
          setTakeButtonText(
            polyglot.t('prechecks_profile_photo_retake_button'),
          );
          setTakeButtonDisabled(false);
        }
      },
      (error) => {
        setTakeButtonDisabled(false);
      },
    );
  };

  const handleRetake = () => {
    sendEvent('Event::ProfilePhotoRetake');
    setPictureTaken(false);
    setTakeButtonText(polyglot.t('prechecks_profile_photo_take_photo_button'));
    setTakeButtonDisabled(false);
  };

  const handleBackBtnClick = () => {
    sendEvent('Event::BackOnProfilePhotoStep', {});
    handlePrevious();
  };

  const handleNextBtnClick = () => {
    sendEvent('Event::ProfilePhotoComplete');
    handleNext();
  };

  const profilePhotoBtns =
    pictureTaken == false
      ? [
          {
            buttonText: backButtonText,
            handleClick: handleBackBtnClick,
            buttonStyleClass: 'btn-precheck-secondary',
          },
          {
            buttonText: takeButtonText,
            handleClick: handleTakePhoto,
            disabled: takeButtonDisabled,
            buttonStyleClass: 'btn-precheck-primary',
          },
        ]
      : [
          {
            buttonText: backButtonText,
            handleClick: handleBackBtnClick,
            buttonStyleClass: 'btn-precheck-secondary',
          },
          {
            buttonText: takeButtonText,
            handleClick: handleRetake,
            disabled: takeButtonDisabled,
            buttonStyleClass: 'btn-precheck-secondary',
          },
          {
            buttonText: polyglot.t('prechecks_profile_photo_next_button'),
            handleClick: handleNextBtnClick,
            disabled: !pictureTaken,
            buttonStyleClass: 'btn-precheck-primary',
          },
        ];

  return (
    <div className="container-fluid text-center precheck-card-content" id={id}>
      <PrechecksHeaderGroup
        title={title}
        image={identificationImg}
        icon={icon}
        iconPrefix={iconPrefix}
      />
      <PrechecksBodyText bodyText={titleText} />
      <div className="position-relative">
        <PrechecksBodyCamera
          canvasRef={canvasRef}
          videoRef={videoRef}
          showCanvas={pictureTaken}
          showVideo={!pictureTaken}
          height={height}
          width={width}
          precheckData={precheckData}
        />
        <div
          className="position-absolute fixed-top mt-3"
          hidden={outlineHidden}
        >
          <img
            src={profilePhotoOutlineData.src}
            alt={profilePhotoOutlineData.altText}
            height={videoLayout == 'legacy' ? '224' : '162'}
          />
        </div>
        <div
          className="position-absolute fixed-top"
          hidden={overlayHidden}
          ref={overlayBg}
          style={overlayStyling}
        ></div>
        <div
          className="position-absolute fixed-top mt-5 pt-5"
          hidden={overlayHidden}
          ref={overlayIcon}
        >
          <PrechecksHeaderIcon icon={icon} iconColor={iconColor} />
        </div>
      </div>
      <PrechecksFooterButtonGroup buttonProps={profilePhotoBtns} />
    </div>
  );
};

ProfilePhotoCard.defaultProps = {
  icon: 'fa-check-circle',
  iconPrefix: 'fal',
  statusColor: 'primary',
  iconColor: 'success',
  title: polyglot.t('prechecks_profile_photo_title'),
  backButtonText: polyglot.t('prechecks_profile_photo_back_button'),
};

export default ProfilePhotoCard;
