import { useState } from 'react';

export const useNextExamCountdownHook = () => {
  const [time, setTime] = useState({ days: 0, hours: 0, minutes: 0 });

  useState(() => {
    const intervalId = setInterval(() => {
      setTime((time) => ({ ...time, minutes: time.minutes + 1 }));
    }, 1000);
    return () => clearInterval(intervalId);
  });

  return { time };
};
