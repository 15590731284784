class UpdateCostAndSplit {
  constructor() {
    this.costSplit = $('.update-cost-split');
  }

  init() {
    this.costSplit.on('change', () => { this.handleChange() })
  }

  handleChange() {
    let setCostSplit = false;
    const splitId = this.costSplit.find(':selected').data('default-split');
    const costId = this.costSplit.find(':selected').data('default-cost');
    if (splitId || costId) {
      setCostSplit = confirm("Would you like to set the cost and split to the Department's defaults? Selecting cancel will only set the department.");
      if (setCostSplit) {
        if (splitId)
          $('#split_id').val(splitId).trigger('change');
        if (costId)
          return $('#cost_id').val(costId).trigger('change');
      }
    }
  }
}

export default UpdateCostAndSplit;
