import csrfToken from '../utils/csrf';

class LaunchExam {
  constructor() {
    this.unlockEtsUnifyExam = document.querySelector('[data-btn-selector="unlock-ets-unify-exam"]');
    this.examLaunchLabel = document.querySelector('[data-behavior="exam-launch-label"]');
  }

  init() {
    if (this.unlockEtsUnifyExam) {
      this.state = document.querySelector(
        '[data-behavior="ets-unify-fulfillment-state"]'
      );
      this.bindEventListeners();
    }
  }

  bindEventListeners() {
    this.unlockEtsUnifyExam.addEventListener('click', this.launch_exam.bind(this));
  }

  launch_exam(event) {
    if (!confirm(event.target.getAttribute('data-confirmation'))) { return; }
    
    this.unlockEtsUnifyExam.classList.add("disabled");
    const url = event.target.getAttribute('data-url');

    fetch(url, {
      method: "POST",
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'X-CSRF-Token': csrfToken()
      },
      body: JSON.stringify(null)
    }).then(response => {
      if (response.ok) {
        this.responseMessage('Exam Launch successful.', 'success');
        console.log(response);
      } else {
        this.responseMessage('An error occurred. Please try again and contact your administrator if the error persists.', 'danger');
      }
    }).then(() => {
      this.unlockEtsUnifyExam.innerText = "Re-Launch Exam"
      this.examLaunchLabel.classList.remove('d-none');
    }).catch(() => {
      alert(event.target.getAttribute('data-error'));
    }).finally(() => {
      this.unlockEtsUnifyExam.classList.remove("disabled");
    });
  }

  responseMessage(msg, type) {
    this.examLaunchLabel.innerHTML = `<code class= 'text-${type}'>${msg}</code>`;
  }
}

export default LaunchExam;
