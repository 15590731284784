class OtherResourcesEditor {
  constructor() {
    this.textarea = $('[data-element="other-resources-editor"]');
  }

  init() {
    if (this.textarea.length > 0) {
      this.textarea.summernote(this.editorConfig());
    } else if (window.Sentry) {
      window.Sentry.captureException('Textarea for Summernote was not found. Check your selector.');
    }
  }

  editorConfig() {
    return {
      disableDragAndDrop: true,
      height: 200,
      toolbar: [
        ["style", ["style"]],
        ["fontsize", ["fontsize"]],
        ["color", ["color"]],
        ["style", ["bold", "italic", "underline", "clear"]],
        ["para", ["ul", "ol"]],
        ["insert", ["link"]],
        ["help", ["help"]],
        ["view", ["fullscreen", "codeview"]]
      ],

      onPaste: function(event) {
        const bufferText = (event.originalEvent.clipboardData || window.clipboardData).getData('Text');
        if (bufferText.length === 0 || bufferText.match(/^http.*\.(gif|jpg|jpeg|tiff|png|bmp)$/)) {
          document.execCommand('insertText', false, bufferText);
          event.preventDefault();
        }
      },

      onImageUpload: function(file) { }
    }
  }
}

export default OtherResourcesEditor;
