export default function handleUnintegratedAutoServiceType(isUintegratedAuto) {
  if (isUintegratedAuto) {
    const enableOverrideThrottlesCheckboxEl = document.getElementById('iteration_exam_attributes_override_throttles');
    const enableOnDemandSchedulingCheckboxEl = document.getElementById('iteration_on_demand_scheduling');
    const disableVerificationStepCheckboxEl = document.getElementById('iteration_disable_verification_step');

    enableOverrideThrottlesCheckboxEl.checked = true;
    enableOnDemandSchedulingCheckboxEl.checked = true;
    disableVerificationStepCheckboxEl.checked = true;
  }
}
