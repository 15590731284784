class OverrideCancellationLeadTime {
  constructor() {
    this.checkbox = document.querySelector(
      '[data-behavior="override-cancellation-lead-time"]'
    );

    this.toggleOverride = this.toggleOverride.bind(this);
  }

  get enabled() {
    return this.checkbox;
  }

  init() {
    if (this.enabled) {
      this.toggleOverride();
      this.bindEventListeners();
    }
  }

  bindEventListeners() {
    this.checkbox.addEventListener('change', this.toggleOverride);
  }

  toggleOverride() {
    if (this.checkbox.checked) {
      this.toggleGroup('block');
    } else {
      this.toggleGroup('none');
    }
  }

  toggleGroup(style) {
    const selector = document.querySelector(
      '[data-behavior="override-cancellation-lead-time-group"]'
    );
    selector.style.display = style;
  }
}

export default OverrideCancellationLeadTime;
