import handleUnintegratedAutoServiceType from './utils/handleUnintegratedAutoServiceType'

class IterationType {
  constructor() {
    this.iterationTypeSelector =
      document.querySelector('[data-element="iteration-types"]') ||
      document.querySelector('[data-element="iteration-type-hidden"]');
    this.iterationServiceTypeList = document.querySelector('#iteration_service_type_list');
  }

  init() {
    if (!this.iterationTypeSelector) { return }
    if (!this.iterationServiceTypeList) { return }
    this.iterationTypeSelector.addEventListener('change', () => { this.handleIterationTypeChange() });
  }

  handleIterationTypeChange() {
    if (!this.iterationTypeSelector) { return; }
    const selectedType = this.iterationTypeSelector.value;
    const serviceTypeSelector = this.iterationServiceTypeList;
    const selectedServiceType = serviceTypeSelector.options[serviceTypeSelector.selectedIndex];
    const isUnintegratedAuto = selectedServiceType && selectedServiceType.value === 'unintegrated-auto';
    handleUnintegratedAutoServiceType(isUnintegratedAuto);
  }
}

export default IterationType;
