import Polyglot from 'node-polyglot/lib/polyglot'; // full path helps type checker (unlike 'node-polyglot' alone)

class Translations {
  constructor() {
    const element = document.querySelector("[data-behavior=app-layout]");
    if(element != null) {
      const translations = element.dataset.translations;
      window.polyglot = /** @type {Polyglot} */ new Polyglot({ phrases: JSON.parse(translations) });
    }
  }

  init() {
    if(window.polyglot !== undefined) {
      this.setLocale();
    }
  }

  setLocale() {
    if (["fr", "fr-fr"].indexOf(this.getLocale()) >= 0) {
      window.polyglot.currentLocale = "fr";
    } else {
      window.polyglot.currentLocale = "en";
    }
  }

  getLocale() {
    if (navigator.languages) {
      return navigator.languages[0];
    }
    navigator.language || navigator.userLanguage;
  }
}

export default Translations;
