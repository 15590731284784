import React, { useEffect } from 'react';

import ReactPaginate from 'react-paginate';

const Paginator = ({ paginator }) => {
  useEffect(() => {
    paginator.calculateAndSetOffset(0);
    paginator.setCurrentPage(0);
  }, [paginator.allItems]);

  return (
    <>
      {paginator.allItems.length > paginator.itemsPerPage ? (
        <footer className="w-100 mt-3">
          <div className="d-flex lms-footer-main">
            <div className="lms-paginator-results">
              {' '}
              Showing <b>{paginator.itemOffsetToShow()}</b> to{' '}
              <b>{paginator.endOffsetToShow()}</b> of{' '}
              <b>{paginator.allItems.length}</b> results
            </div>
            <div id="lms-pagination">
              <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={paginator.handlePageClick}
                marginPagesDisplayed={1}
                pageRangeDisplayed={1}
                pageCount={paginator.pageCount}
                previousLabel="<"
                containerClassName="pagination pagination-sm flex-sm-wrap mb-0"
                pageLinkClassName="page-num"
                previousClassName="page-num"
                nextClassName="page-num"
                breakClassName="page-num"
                activeLinkClassName="active"
                disabledClassName="btn-disabled-meazure"
                forcePage={paginator.currentPage}
              />
            </div>
          </div>
        </footer>
      ) : null}
    </>
  );
};

export default Paginator;
