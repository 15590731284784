import React from 'react';
import { Modal, Button } from 'react-bootstrap';

import AadharCard from '../../../../assets/images/aadhar_card.png';

const AadhaarPopup = ({
  show,
  onClose,
  onConsent,
  declineBtnText,
  acceptBtnText,
}) => {
  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="compact-title">
          Do you consent to using your Aadhaar card?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <img
          src={AadharCard}
          alt="Aadhaar Card Image"
          className="aadhaar-image mx-auto"
        />
        <p>
          Do you want to use your Aadhaar Card for identity verification? If you
          are selecting yes, ensure your Aadhaar number is linked to your mobile
          phone number.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          {declineBtnText}
        </Button>
        <Button variant="primary" onClick={onConsent}>
          {acceptBtnText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AadhaarPopup;
