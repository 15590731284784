import PropTypes from 'prop-types';

class UrgencyLevelItem extends React.Component {
  render() {
    var urgencyLevel = this.props.urgencyLevel;

    return (
      <li className="list-group-item urgency-sort-item"
        id={urgencyLevel.id}>
        { urgencyLevel.status }
      </li>
    );
  }
}

UrgencyLevelItem.propTypes = {
  urgencyLevel: PropTypes.object.isRequired
}

export default UrgencyLevelItem;
