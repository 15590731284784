import getDate from './getDate';

export default function updateDatePicker(datetime) {
  const dateInput = $('#date-input');
  const date = getDate(datetime);
  const event = new CustomEvent('datePickerChange', {
    detail: { value: date.toString() },
  });
  dateInput.value = date.toString();
  dateInput.dispatchEvent(event);
}
