class SlotGreeting {
  constructor() {
    this.greeting = document.querySelector('.greeting')
  }

  hide() {
    if(this.greeting) {
      this.greeting.classList.add('d-none')
    }
  }
}

export default SlotGreeting
