class AssignPasswordCheckbox {
  constructor() {
    this.assignPassword = document.querySelector('#user_assign_password');
  }

  init() {
    this.assignPassword.addEventListener('change', (event) => { this.handleChange(event) })
    this.checkPassword()
  }

  handleChange(event) {
    this.checkPassword();
  }

  checkPassword() {
    const password = document.querySelector('#password');
    if (this.assignPassword.checked) {
      password.classList.remove('d-none')
      password.querySelectorAll('input', 'select', 'textarea').forEach(el => el.disabled = false);
    } else {
      password.classList.add('d-none')
      password.querySelectorAll('input', 'select', 'textarea').forEach(el => el.disabled = true);
    }
  }
}

export default AssignPasswordCheckbox;
