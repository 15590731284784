import React, { useState, useMemo } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  AddressElement,
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';

import csrfToken from '../../src/utils/csrf';

/** @typedef {import('@stripe/stripe-js').PaymentMethodCreateParams} PaymentMethodCreateParams */
/** @typedef {import('@stripe/stripe-js').PaymentMethodCreateParams.BillingDetails} PaymentMethodCreateParams.BillingDetails */
/** @typedef {import('@stripe/stripe-js').StripeAddressElementChangeEvent} StripeAddressElementChangeEvent */
/** @typedef {import('@stripe/stripe-js').StripeElementsOptions} StripeElementsOptions */
/** @typedef {import('@stripe/stripe-js').StripeError} StripeError */

/**
 * @typedef {Object} StripePaymentFormProps
 * @property {string} stripePublishableKey
 * @property {number} amount
 * @property {number} id
 * @property {string} commitUrl
 */

/**
 * @typedef {Omit<StripePaymentFormProps, 'stripePublishableKey'>} CheckoutFormProps
 */

/**
 * @typedef CommitResponse
 * @property {string} [message]
 * @property {number} order_id
 * @property {string} client_secret
 * @property {string} status
 * @property {string} receipt_url
 */

/**
 * @param {CheckoutFormProps} props
 * @returns {React.ReactElement}
 */
const CheckoutForm = ({ id, amount, commitUrl }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [billingDetails, setBillingDetails] = useState(
    /** @type {PaymentMethodCreateParams.BillingDetails} */ ({}),
  );

  const disablePayment = !stripe || !elements || isLoading;

  /**
   * @param {StripeAddressElementChangeEvent} event
   */
  const handleAddressChange = (event) => {
    setBillingDetails(event.value);
  };

  /**
   * @param {StripeError} error
   */
  const handleError = (error) => {
    console.error('Error:', error);
    setIsLoading(false);
    setMessage(error.message);
  };

  const dismissMessage = () => {
    setMessage(null);
  };

  // TODO: refactor this function to be a lot more readable please 😊
  const handleSubmit = async (e) => {
    e.preventDefault();

    dismissMessage();

    if (!stripe) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      // Show validation errors to the user so they can correct their payment details
      handleError(submitError);
      return;
    }

    // Create the ConfirmationToken using the details collected by the Payment Element
    // and additional shipping information
    const { error, confirmationToken } = await stripe.createConfirmationToken({
      elements,
      params: {
        payment_method_data: {
          billing_details: billingDetails,
        },
      },
    });

    if (error) {
      // This point is only reached if there's an immediate error when
      // creating the ConfirmationToken. Show the error to your customer (for example, payment details incomplete)
      handleError(error);
      return;
    }

    // https://docs.stripe.com/payments/finalize-payments-on-the-server?platform=web&type=payment#submit-payment
    try {
      console.log('confirmationToken', confirmationToken);
      const {
        payment_method_preview: { card, billing_details },
      } = confirmationToken;

      const body = {
        order: {
          id,
          confirmation_token: confirmationToken.id,
          intended_total: amount,
          card_number: card.last4,
          card_name: card.brand,
          card_month: card.exp_month,
          card_year: card.exp_year,
          billing_street1: billing_details.address.line1,
          billing_street2: billing_details.address.line2,
          billing_city: billing_details.address.city,
          billing_state: billing_details.address.state,
          billing_zip: billing_details.address.postal_code,
        },
      };

      const response = await fetch(commitUrl, {
        method: 'PATCH',
        headers: {
          'X-CSRF-Token': csrfToken(),
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(body),
      });

      /** @type {CommitResponse} */
      const data = await response.json();

      if (!response.ok) {
        throw new Error((data ?? {}).message ?? 'An error occurred');
      }

      if (data.status === 'succeeded') {
        // Send the user to the receipt page if the payment was successful
        window.location = data.receipt_url;
        return;
      }

      if (data.status === 'requires_action') {
        // TODO: handle next actions if needed (like 3D secure) and then make another trip to the server to confirm
        // https://docs.stripe.com/payments/accept-a-payment-synchronously
        //
        // // Use Stripe.js to handle the required next action
        // const { error, paymentIntent } = await stripe.handleNextAction({
        //   clientSecret: response.clientSecret,
        // });
        // if (error) {
        //   throw new Error(error.message);
        // }
        // // Make another trip to the server to confirm the payment
        // const confirmResponse = await fetch(commitUrl, {
        //   method: 'PATCH',
        //   headers: {
        //     'X-CSRF-Token': csrfToken(),
        //     'Content-Type': 'application/json',
        //     Accept: 'application/json',
        //   },
        //   body: JSON.stringify({
        //     order: {
        //       id,
        //       payment_intent_id: paymentIntent.id,
        //     },
        //   }),
        // });
        // const confirmData = await confirmResponse.json();
        // if (!confirmResponse.ok) {
        //   throw new Error((confirmData ?? {}).message ?? 'An error occurred');
        // }
        // if (confirmData.status === 'succeeded') {
        //   // Send the user to the receipt page if the payment was successful
        //   window.location = data.receipt_url;
        //   return;
        // }
      }

      throw new Error(`Unexpected status: ${data.status}`);
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <div className="stripe-checkout-form">
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        <AddressElement
          options={{ mode: 'billing' }}
          onChange={handleAddressChange}
        />

        {stripe && elements && (
          <button
            disabled={disablePayment}
            id="submit"
            className={`mt-4 btn btn-primary w-100 ${
              disablePayment ? 'disabled-submit-btn' : 'enabled-submit-btn'
            }`}
          >
            {isLoading ? (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">
                  {polyglot.t('payment_stripe_submitting')}...
                </span>
              </>
            ) : (
              polyglot.t('payment_stripe_pay_now')
            )}
          </button>
        )}

        {message && (
          <div className="alert alert-wide alert-danger mt-4" role="alert">
            {message}
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={dismissMessage}
            >
              <span aria-hidden={true}>&times;</span>
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

/**
 * @param {StripePaymentFormProps} props
 * @returns {React.ReactElement}
 */
export default function StripePaymentForm({
  stripePublishableKey,
  amount,
  id,
  commitUrl,
}) {
  // Make sure to memoize the stripe promise so it's only created once and not on every render
  const stripePromise = useMemo(
    () => loadStripe(stripePublishableKey),
    [stripePublishableKey],
  );

  /** @type {StripeElementsOptions} */
  const options = {
    mode: 'payment',
    amount,
    // TODO: pass the currency in as a prop, so it can be dynamic
    currency: 'usd',
    appearance: {
      theme: 'stripe',
    },
    // NOTE: only allow card payments for now, see the server side for more details
    payment_method_types: ['card'],
  };

  return (
    <div className="card p-4">
      <p>
        {polyglot.t('payment_stripe_payment_information_pre') + ' '}
        <strong>{polyglot.t('payment_stripe_pay_now')}</strong>
        {' ' + polyglot.t('payment_stripe_payment_information_post')}
      </p>
      <div className="card p-4">
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm id={id} amount={amount} commitUrl={commitUrl} />
        </Elements>
      </div>
      <small className="text-muted text-right">
        <i className="fa-solid fa-lock mr-1"></i>
        {polyglot.t('payment_stripe_payment_processed_by')}
      </small>
    </div>
  );
}
