class ProcedureList {
  init(SharedClass = null) {
    this.anchor = document.querySelector('.js-notes-anchor');
    this.bindEventListeners();
    if (SharedClass != null)
    {
        this.bindSummerNote(SharedClass);
    }
  }

  bindSummerNote(SharedClass) {
    let fp_form_box = document.querySelector('.add-procedure-box');
    if (fp_form_box) {
        $('.add-procedure-box').on('cocoon:after-insert', function(){
            new SharedClass.Summernote('.fp-html-editor').init(true);
        })
    }
  }
  bindEventListeners() {
    const target = document.querySelector('.js-notes');
    if (this.anchor && target) {
      this.anchor.addEventListener('click', (e) => {
        e.preventDefault();
        target.scrollIntoView({
          behavior: 'smooth', // smooth scroll
          block: 'center'
        });
      })
    }
  }
}

export default ProcedureList;
