class UserAgentNotifications {
  constructor() {
    this.disabledStartButtons = document.querySelectorAll('.disabled.start_button');
  }

  init() {
    this.handleDisabledStartButtons();
  }

  handleDisabledStartButtons() {
    [...this.disabledStartButtons].forEach((btn) => {
      btn.removeAttribute('href')
      btn.style.pointerEvents = 'auto'
      btn.title = polyglot.t('unsupported_os_or_browser')
    });
  }
}

export default UserAgentNotifications;
