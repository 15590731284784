import axios from 'axios';
import UrlPusherProctorView from './UrlPusherProctorView';
import UrlPusherTestTakerView from './UrlPusherTestTakerView';
import consumer from '../../src/channels/consumer';
import csrfToken from '../../src/utils/csrf';
import Toast from '../../src/utils/Toast';

class UrlPusherApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      proctorConnected: false,
      testTakerConnected: false,
      code: null,
      showModal: false
    }
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleUrl = this.handleUrl.bind(this);
  }

  componentDidMount() {
    document.addEventListener('turbolinks:render', this.setupSubscription())
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

  handleSubscriptionState(data) {
    this.setState({
      testTakerConnected: data.test_taker,
      proctorConnected: data.proctor,
      code: data.code
    })
  }

  handleUrl(url) {
    const headers = {
      headers: {
        Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
      }
    }
    axios.post(window.origin + '/push', JSON.stringify({ id: this.props.id, url: url }), headers)
  }

  setupSubscription() {
    consumer.subscriptions.create({channel: 'PushChannel',
        id: this.props.id,
        role: this.props.role
      }, {
        role: this.props.role,
        id: this.props.id,
        handleSubscriptionState: this.handleSubscriptionState.bind(this),
        received: function(data) {
          if(data.url !== null && this.role == 'student') {
            let seconds = 10000
            new Toast().success({
              message: `A support representative is sending you a link.
              In about ${seconds / 1000 } seconds, a new tab or window will
              open for the following URL: <br /><br /> ${data.url}`,
              timeout: seconds
            });
            setTimeout(function() { window.open(data.url, '_blank').focus() }, seconds);
          }
          this.handleSubscriptionState(data)
        },
        connected: function() {
          const params = { role: this.role, id: this.id }
          window.addEventListener("beforeunload", () => { // handle closing out of window
            this.perform('disconnect', params)
            consumer.subscriptions.remove(this)

          })
          window.addEventListener("onbeforeunload", () => { // handle refreshing page
            this.perform('disconnect', params)
            consumer.subscriptions.remove(this)

          })
          window.addEventListener("onpopstate", () => { // handle React state
            this.perform('disconnect', params)
            consumer.subscriptions.remove(this)

          })
          document.addEventListener("turbolinks:before-render", () => { //handle clicking links on page
            this.perform('disconnect', params)
            consumer.subscriptions.remove(this)
          })
        }
      }
    )
  }

  render() {
    if (this.props.role == 'student') {
     return <UrlPusherTestTakerView proctorConnected={ this.state.proctorConnected }
                                    testTakerConnected={ this.state.testTakerConnected }
                                    logo={ this.props.logo }
                                    code={ this.state.code }
            />
    } else {
      return <UrlPusherProctorView proctorConnected={ this.state.proctorConnected }
                                   testTakerConnected={ this.state.testTakerConnected }
                                   code={ this.state.code }
                                   urls={ this.props.urls }
                                   showModal={ this.state.showModal }
                                   handleShow={ this.handleShow }
                                   handleClose={ this.handleClose}
                                   handleUrl={ this.handleUrl }
              />
    }
  }
}

export default UrlPusherApp;
