class Select2DataRegion extends React.Component {
  constructor(props) {
    super(props);

    const initialValue = this.getDataRegion() || Object.keys(props.options)[0];
    this.state = {
      selectedValue: initialValue,
    };
  }

  getDataRegion() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('data_region');
  }

  handleChange = (event) => {
    const value = event.target.value;
    this.setState({ selectedValue: value });
  };

  render() {
    const { options } = this.props;
    const { selectedValue } = this.state;

    return (
      <div>
        <label htmlFor="data_region">Data region</label>
        <select
          name="data_region"
          id="select2-dataregion"
          className="form-control"
          value={selectedValue}
          onChange={this.handleChange}
        >
          {Object.entries(options).map(([value, label]) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
      </div>
    );
  }
}

export default Select2DataRegion;
