import { useEffect } from 'react';

const ShareScreenpageCard = ({ id, sendEvent, broadcastMessage }) => {
  useEffect(() => {
    sendEvent('Event::ShareScreenpageLoaded');
    broadcastMessage({
      sender: 'archimedes',
      messageType: 'request_screen_share',
      data: {},
    });
  }, []);

  return (
    <div>
      <div id={id}></div>
    </div>
  );
};

export default ShareScreenpageCard;
