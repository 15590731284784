import PropTypes from 'prop-types';

const URLOpenedIncident = ({ createdAt, showInPlayer, body, subtype }) => (
  <div className="row align-items-center">
    <div className="col-1">
      <div className="h-100 text-center d-flex align-items-center justify-content-center">
        <span className="fad fa-play-circle fa-lg player-icon cursor-pointer" onClick={showInPlayer}/>
      </div>
    </div>
    <div className="col-11">
      <small className="text-muted">{createdAt}</small>
      { subtype === "url"
        ? <div className="text-muted">
            <a href={body}>{body}</a>
          </div>
        : <div className="text-muted">
            New tab opened
          </div>
        }
    </div>
  </div>
)

URLOpenedIncident.propTypes = {
  createdAt: PropTypes.string.isRequired,
  showInPlayer: PropTypes.func.isRequired,
  body: PropTypes.string.isRequired,
  subtype: PropTypes.string.isRequired
}

export default URLOpenedIncident
