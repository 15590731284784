// IterationCheckboxes
//
// This class handles pages that include lists of iterations
// with checkboxes attached to each row. It provides the functionality to check
// a master checkbox in the table header, and have each checkbox's value match
// that master checkbox's value.
class IterationCheckboxes {
  constructor() {
    this.allIterationsCheckbox = document.querySelector('.iteration-check-all');
    this.iterationCheckboxes = document.querySelectorAll('.iteration-check');

    this.handleAllIterationsCheckboxChange = this.handleAllIterationsCheckboxChange.bind(
      this
    );
  }

  init() {
    this.allIterationsCheckbox.addEventListener(
      'change',
      this.handleAllIterationsCheckboxChange
    );
  }

  handleAllIterationsCheckboxChange(event) {
    const checkedValue = event.target.checked;
    [...this.iterationCheckboxes].forEach(checkbox => {
      checkbox.checked = checkedValue;
    });
  }
}

export default IterationCheckboxes
