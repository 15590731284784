import Plyr from 'plyr';

class MediaPlayerRenderer {
  constructor(allowDownload = null) {
    var download = allowDownload == true ? 'download' : null;
    this.cameraPlayer = new Plyr('#cameraPlayer', {
      controls: ['fullscreen', download],
      invertTime: false,
      toggleInvert: false,
      speed: { selected: 1, options: [0.5, 1, 2, 3, 5, 10] },
    });
    this.screenPlayer = new Plyr('#screenPlayer', {
      controls: [
        'play',
        'progress',
        'current-time',
        'volume',
        'settings',
        'fullscreen',
        download,
      ],
      invertTime: false,
      toggleInvert: false,
      speed: { selected: 1, options: [0.5, 1, 2, 3, 5, 10] },
    });
    this.players = [this.cameraPlayer, this.screenPlayer];

    document.querySelectorAll('[data-plyr="fullscreen"]').forEach((element) => {
      element.style.marginRight = 0;
    });
  }

  init() {
    if (this.cameraPlayer && this.screenPlayer) {
      this.players.forEach((player) => {
        player.on('play', (event) => {
          const target = this.otherPlayer(player);

          if (target.playing === false) {
            target.currentTime = player.currentTime;
            target.play();
          }
        });

        player.on('pause', (event) => {
          const target = this.otherPlayer(player);

          if (target.playing === true) {
            target.pause();
          }
        });

        player.on('seek', (event) => {
          const target = this.otherPlayer(player);

          target.currentTime = player.currentTime;
        });

        player.on('ratechange', (event) => {
          const target = this.otherPlayer(player);

          target.speed = player.speed;
        });

        player.on('volumechange', (event) => {
          const target = this.otherPlayer(player);

          target.volume = player.volume;
        });

        player.on('ended', (event) => {
          const target = this.otherPlayer(player);

          player.restart();
          target.restart();
        });
      });
    }
  }

  otherPlayer(currentPlayer) {
    const id = currentPlayer.elements.original.id;

    if (id === 'cameraPlayer') {
      return this.screenPlayer;
    } else {
      return this.cameraPlayer;
    }
  }
}

export default MediaPlayerRenderer;
