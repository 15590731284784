class Select2User extends React.Component {
  constructor(props) {
    super(props);
    this.$selector = '#select2-user';
  }

  getInstitutionValue() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('institution') ?? 0;
  }

  componentDidMount() {
    $(this.$selector).select2({
      placeholder: 'Find by Name',
      theme: 'bootstrap4',
      delay: 500,
      ajax: {
        url: '/users',
        dataType: 'json',
        data: function (params) {
          return { search: params.term };
        },
        processResults: function (data) {
          let results = [];
          data.forEach((user) => {
            results.push({
              id: user.id,
              text: user.institution
                ? `${user.name} (${user.institution})`
                : user.name,
            });
          });
          return { results: results };
        },
      },
      allowClear: true,
    });
  }

  render() {
    return (
      <div>
        <label htmlFor="user">User</label>
        <select
          name="user"
          id="select2-user"
          placeholder="Find by Name"
          className="form-control"
        ></select>
      </div>
    );
  }
}

export default Select2User;
