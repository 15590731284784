// PasswordReveal
//
// Shows and hides the password on institutions#edit/Test Delivery
class PasswordReveal {
  constructor() {
    this.testDeliveryPasswords = document.querySelectorAll('.js-tdf-reveal');
  }

  init() {
    this.bindEventListener()
  }

  bindEventListener() {
    this.testDeliveryPasswords.forEach(password => {
      password.addEventListener('click', () => { this.handleClick(password) })
    })
  }

  handleClick(password) {
    if(password.querySelector('.fa').classList.contains('fa-eye-slash')){
      password.querySelector('.fa').classList.remove('fa-eye-slash');
      password.querySelector('.fa').classList.add('fa-eye');
      password.closest('.input-group').querySelector('.form-control').type = 'text';
    } else {
      password.querySelector('.fa').classList.add('fa-eye-slash');
      password.querySelector('.fa').classList.remove('fa-eye');
      password.closest('.input-group').querySelector('.form-control').type = 'password';
    }
  }
}

export default PasswordReveal;
