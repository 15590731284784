/**
 * This class handles Cancellations section on the Institution form
 */

class AutoCancellations {
  constructor() {
    this.cancellationsCheckbox = document.querySelector(
      '[data-element="enable-cancellations-checkbox"]'
    )
    this.cancellationsThreshold = document.querySelector(
      '[data-element="auto-cancellation-threshold"]'
    )
    this.handleCancellationsCheckbox = this.handleCancellationsCheckbox.bind(this)
  }

  init() {
    if (this.cancellationsCheckbox != null) {
      this.bindEventListeners()
      this.handleCancellationsCheckbox()
    }
  }

  bindEventListeners() {
    this.cancellationsCheckbox.addEventListener('change', this.handleCancellationsCheckbox)
  }

  handleCancellationsCheckbox() {
    this.cancellationsThreshold.disabled = !this.cancellationsCheckbox.checked
  }
}

export default AutoCancellations;
