// TemplateReverter
//
// This class handles the `Revert to Original' button on our message templates
// edit page. This button reverts the state of the subject and body back to their
// most recently saved state.
//
class TemplateReverter {
  constructor() {
    this.revertTemplateButton = document.querySelector(
      '[data-behavior="revert-message-template"]'
    );

    this.handleRevertTemplateButtonClick = this.handleRevertTemplateButtonClick.bind(
      this
    );
  }

  init() {
    this.revertTemplateButton.addEventListener(
      'click',
      this.handleRevertTemplateButtonClick
    );
  }

  handleRevertTemplateButtonClick(event) {
    event.preventDefault();

    const subject = document.getElementById('message_template_subject');
    const originalSubjectContent = subject.getAttribute('data-original');
    const trix = document.querySelector('trix-editor');
    const editor = trix.editor;
    const originalBodyContent = trix.getAttribute('data-original');

    editor.setSelectedRange([0, editor.getDocument().getLength()]);
    editor.insertHTML(originalBodyContent);
    subject.value = originalSubjectContent;
  }
}

export default TemplateReverter;
