import { useEffect, useState } from 'react';

import useBattery from './useBattery';

export default function useIsCharging() {
  const [isCharging, setIsCharging] = useState(false);
  const { supported, charging } = useBattery();

  useEffect(() => {
    setIsCharging(supported && charging);
  }, [supported, charging]);

  return { isCharging, setIsCharging };
}
