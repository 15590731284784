class ExamPanel {
  constructor() {
    this.examPanelDropdownLinks = document.querySelectorAll('#exam-tabs a');
  }

  init() {
    this.bindEventListeners()
  }

  bindEventListeners() {
    this.examPanelDropdownLinks.forEach(link  => {
      link.addEventListener('click', (event) => { this.handleClick(link, event) })
    })
  }

  handleClick(link, event) {
    event.preventDefault();
    const dropdown = document.querySelector('#exam-select-dropdown')
    dropdown.classList.toggle('show');
    dropdown.getElementsByClassName('dropdown-menu')[0].classList.toggle('show');
    $(link).tab('show');
    link.classList.remove('active');
    dropdown.getElementsByClassName('dropdown-toggle')[0].innerHTML = `${link.textContent}<span class="caret"></span>`;
    event.stopPropagation();
  }
}

export default ExamPanel;
