import moment from "moment";

/**
 * Single message component.
 */
class ChatMessage extends React.Component {
  render() {
    const { userType, chatMessage, messageTime } = this.props;
    return (
      <div className={`message ${userType === 'student' ? 'from-user' : 'from-proctor'}`}>
        <div className="message-body">
          <span className="message-content-text">{ chatMessage }</span>
        </div>
        <div className="message-date">
          { moment.utc(messageTime).local().format("h:mm A") }
        </div>
      </div>
    )
  }
}

export default ChatMessage
