class SamlRegistration {
  init() {
    this.getTimeZone();
    this.preselectTimeZone();
  }

  getTimeZone() {
    this.tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  preselectTimeZone() {
    if (this.tz && this.tz !== '') {
      let tzSelect = document.getElementById('user_timezone');

      if(tzSelect.selectedIndex === 0) { // Only auto-selected on first page load. On form error, keep previous value
        let tzOptions = tzSelect.options;
        for (var i = 0; i < tzOptions.length; i++) {
          let optionText = tzOptions[i].text;

          if(optionText.includes(this.tz)) {
            tzOptions[i].selected = true;
            break;
          }
        }
      }
    }
  }
}

export default SamlRegistration;
