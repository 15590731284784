import PrechecksHeaderGroup from './CardSections/PrechecksHeaderGroup';
import PrechecksFooterButton from './CardSections/PrechecksFooterButton';
import { useEffect } from 'react';
import { handleScratchPaperTextTranslation } from '../../src/utils/ScratchPaperResourceTranslationHelper';

const AllowedResourcesCard = ({
  id,
  icon,
  iconPrefix,
  titleResources,
  titleNoResources,
  buttonText,
  handleNext,
  sendEvent,
  precheckData,
}) => {
  const allowedResources = precheckData.allowedResources;
  const emptyResources = precheckData.emptyResources;
  const title = emptyResources ? titleNoResources : titleResources;
  const resourcesImg = precheckData.images.resourcesImg;

  useEffect(() => {
    sendEvent('Event::AllowedResourcesLoaded');
  }, []);

  const handleBtnClick = () => {
    sendEvent('Event::AllowedResourcesReviewed');
    handleNext();
  };

  const renderResourceGrid = () => {
    if (!emptyResources) {
      return (
        <div className="form-group text-left js-resource-switch">
          <div className="card-columns">
            {Object.entries(allowedResources).map(([section, hash]) => {
              return (
                <div className="col mb-2" key={`resources_${section}`}>
                  <div className="card h-100">
                    <div className="card-body">
                      <h5>
                        {handleScratchPaperTextTranslation(
                          section,
                        ).toUpperCase()}
                      </h5>
                      <ul className="precheck-align-li">
                        {Object.entries(hash).map(([key, value]) => {
                          return (
                            <li key={`${section}_${value}`}>
                              <span className="precheck-grid-li">{value}</span>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  };

  return (
    <div className="container-fluid text-center" id={id}>
      <PrechecksHeaderGroup
        title={title}
        image={resourcesImg}
        icon={icon}
        iconPrefix={iconPrefix}
      />
      {renderResourceGrid()}
      <PrechecksFooterButton
        buttonText={buttonText}
        handleClick={handleBtnClick}
      />
    </div>
  );
};

AllowedResourcesCard.defaultProps = {
  titleResources: polyglot.t('prechecks_allowed_resources'),
  titleNoResources: polyglot.t('prechecks_no_allowed_resources'),
  icon: 'fa-pen-alt',
  iconPrefix: 'fal',
  statusColor: 'primary',
  buttonText: polyglot.t('prechecks_allowed_resources_next_button'),
};

export default AllowedResourcesCard;
