import axios from 'axios';
import csrfToken from '../../utils/csrf';
import AccessCodeMessage from './AccessCodeMessage';

class ApplyAccessCode {
  constructor(name) {
    this.component = document.querySelector('.js-student-access-code-component');
    this.form = this.component.querySelector('.form-group');
    this.input = document.querySelector('.js-access-code');
    this.value = this.input.value;
    this.message = new AccessCodeMessage(name)
  }

  init() {
    const data = {
      code: this.value,
      fulfillment: this.input.getAttribute('data-fulfillment')
    };

    const headers = {
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
        }
      }
    axios.patch(this.input.getAttribute('data-url'),  JSON.stringify(data), headers)
    .then(response => {
        this.findStatusCode(response.status, response);
    }).catch(error => {
        this.findStatusCode(error.response.status)
    });
    this.input.focus();
  }

  toast(type) {
    this.message.toast(type)
  }

  findStatusCode(statusCode, response = {}) {
    switch(statusCode) {
      case 200:
        this.renderTotalWithAccessCode(response.data);
        break;
      case 417:
        this.toast('notfound');
        break;
      case 419:
        this.toast('expired');
        break;
      case 420:
        this.toast('used');
        break;
      case 422:
        this.toast('invalid');
        break;
      }
  }

  renderTotalWithAccessCode(response) {
    const accessCodeValue = response.access_code_value;
    const newTotal = '$' + response.total.toFixed(2);
    const total = document.querySelector('.js-order-total');
    total.textContent = newTotal;
    if (parseFloat(total.textContent.substring(1, total.textContent.length)) < response.total) {
      const balance_payment = document.querySelector('[class^=balance_payment]')
      balance_payment.parentNode.removeChild(balance_payment);
      document.querySelector('.student-checkout').textContent = 'Charge to Account';
    }
    const accessCodeTableRow = `<tr>
            <th class="text-success">${this.message.used()}</th>
            <th class="text-success text-right">-$${(accessCodeValue / 100).toFixed(2)}</th>
          </tr>`;
    document.querySelector('tr.totals').insertAdjacentHTML('beforebegin', accessCodeTableRow);
    this.component.querySelector('input').value = '';
    setTimeout(() => this.toast('success'))
  }
}

export default ApplyAccessCode
