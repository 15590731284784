import PropTypes from 'prop-types';
import TimeLine  from './TimeLine';
/**
 * Comments and Activity Stream container component
 */

class ActivityContainer extends React.Component {
  constructor(props) {
    super(props);
  }

  render = () => {
    if (this.props.fulfillment.automatic_iteration == false) {
        if (this.props.previousFulfillment) {
          return (
            <div>
              <TimeLine
                fulfillment={this.props.previousFulfillment}
              />
              <TimeLine
                fulfillment={this.props.fulfillment}
              />
            </div>
          )}
        else {
          return (
          <TimeLine
            fulfillment={this.props.fulfillment}
          />
        )}
    }
  }
}

ActivityContainer.propTypes = {
  fulfillment: PropTypes.object.isRequired,
  urgencyLevels: PropTypes.array.isRequired,
  previousFulfillment:PropTypes.object
}

export default ActivityContainer
