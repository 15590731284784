import React from 'react';
import PropTypes from 'prop-types';

const MediaAllSingleVideo = ({
  mainIndex,
  obj,
  objIndex,
  type,
  showTooltip,
}) => {
  const mediaType = type + 's';
  type = type  == 'combined_screen' ? 'combined Video' : type;
  const mediaLabel = type[0].toUpperCase() + type.substring(1);

  if (!obj.hasOwnProperty('waiting')) {
    return (
      <li className="m-1">
        <input
          type="checkbox"
          id={`allmedia_${mediaType}_${mainIndex}_${objIndex}`}
          className="mr-2"
          data-mediatype={mediaType}
          data-live-plus="false"
          data-position={`${mainIndex}_${objIndex}`}
        />
        <label
          className="media_object mr-2"
          htmlFor={`allmedia_${mediaType}_${mainIndex}_${objIndex}`}
        >
          {`${mediaLabel} ${objIndex + 1}`}
        </label>
        {showTooltip && (
          <a
            className="js-tooltip text-cursor"
            title={`<div class="text-left">Session ID: ${obj.session_id}<br>Time: ${obj.time}</div>`}
            data-position="top"
            data-toggle="tooltip"
          >
            <span className="fa fa-info-circle"></span>
          </a>
        )}
      </li>
    );
  } else if (obj.waiting || obj.data.url || obj.enabled) {
    return (
      <li className="m-1">
        <input
          type="checkbox"
          id={`allmedia_${mediaType}_${mainIndex}_${objIndex}`}
          className="mr-2"
          data-mediatype={mediaType}
          data-live-plus="true"
          data-position={`${mainIndex}_${objIndex}`}
        />
        <label
          className="media_object"
          htmlFor={`allmedia_${mediaType}_${mainIndex}_${objIndex}`}
        >
          {`${mediaLabel} ${objIndex + 1}`}
        </label>
      </li>
    );
  }
};

MediaAllSingleVideo.propTypes = {
  mainIndex: PropTypes.number.isRequired,
  obj: PropTypes.object.isRequired,
  objIndex: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  showTooltip: PropTypes.bool.isRequired,
};

export default MediaAllSingleVideo;
