class DisableVerificationStepCheckbox {
  constructor() {
    this.iterationTypeSelector =
     document.querySelector('[data-element="iteration-types"]') ||
     document.querySelector('[data-element="iteration-type-hidden"]');
  }

  init() {
    if (this.iterationTypeSelector) {
      this.handleDisableVerificationStepCheckbox();
      this.iterationTypeSelector.addEventListener('change', () => { this.handleDisableVerificationStepCheckbox() });
    }
  }

  handleDisableVerificationStepCheckbox() {
    const selectedType = this.iterationTypeSelector.value;
    const disableVerificationStepCheckbox = document.querySelector(
      '[data-behavior="disable-verification-step-checkbox"]'
    );
    if (disableVerificationStepCheckbox) {
      if (selectedType === 'Iteration::AutomatedUnintegrated') {
        disableVerificationStepCheckbox.classList.remove('d-none');
      } else {
        disableVerificationStepCheckbox.classList.add('d-none');
      }
    }
  }
}

export default DisableVerificationStepCheckbox;
