class UpdateNextAndPreviousButtons {

  constructor(button, step) {
    this.nextButton = document.querySelector('.js-next-step');
    this.previousButton = document.querySelector('.js-previous-step');
    this.isLastStep = document.querySelector('.js-last-procedure a')?.classList.contains('active');
    this.isFirstStep = document.querySelector('.js-first-procedure a')?.classList.contains('active');
    this.activeStep = document.querySelector('.nav-flightpath .nav-link.active');
  }

  init () {
    if (this.activeStep !== null) {
      if (this.activeStep.parentNode.nextElementSibling === null || this.activeStep.parentNode.nextElementSibling.classList.contains('disabled')) {
        this.nextButton.classList.add('disabled');
      } else {
        this.nextButton.classList.remove('disabled');
      }

      if (this.activeStep.parentNode.previousElementSibling === null) {
        this.previousButton.classList.add('disabled');
      } else {
        this.previousButton.classList.remove('disabled');
      }
    }
  }
}

export default UpdateNextAndPreviousButtons;
