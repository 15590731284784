import React from 'react';

const ExamConfigurationInfo = ({
  errorMsg,
  examInUse,
  examUrl,
  handleUnConfiguredExamClick,
}) => {
  const createLink = (url) => (
    <a href={url ?? ''} target="_parent">
      here
    </a>
  );
  const url = <div> Click {createLink(examUrl)} to edit in LMS. </div>;
  return (
    <>
      {!errorMsg ? (
        examInUse ? (
          <div>Processing Exam...</div>
        ) : (
          <input
            name="commit"
            defaultValue="Manual Configuration"
            className="btn btn-primary-meazure m-0 manual-configuration-button"
            onClick={handleUnConfiguredExamClick}
            readOnly
          />
        )
      ) : (
        <p className="configuration-info">
          Incomplete Data - {errorMsg}. {url}
        </p>
      )}
    </>
  );
};

export { ExamConfigurationInfo };
