// For displaying times on Schedule index pages
// Makes sure only one time is displayed for multiple schedules with the same time
//
// For instance, if we have 10 schedules that all start at 8:00 AM,
// and those schedules are listed in order of start date,
// only the first listed schedule would have '8:00 AM' displayed
// in the 'Time' column
//
class TimeDisplay {
  constructor() {
    // the collection of schedule start time elements
    this.startTimes = document.getElementsByClassName('schedule-start-time');
  }

  init() {
    // we don't need to run this if there are no start times being displayed
    if (!this.startTimes || this.startTimes.length == 0) {
      return;
    }
    this.hideEqualTimes();
  }

  hideEqualTimes() {
    // the most recent displayed time
    let mostRecentDisplayedTime;

    // array of start times
    const startTimesArr = [...this.startTimes];

    // iterate over the start times array,
    // if we see a time that matches the most recent displayed time (e.g. we've already seen this),
    // do not display this element
    // else, set the most recent displayed time to this element
    startTimesArr.forEach(el => {
      if (el.innerHTML == mostRecentDisplayedTime) {
        el.style.display = 'none';
      } else {
        mostRecentDisplayedTime = el.innerHTML;
      }
    });
  }
}

export default TimeDisplay;
