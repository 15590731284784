import React, { useEffect, useState } from 'react';

import moment from 'moment';

import DatePicker from './DatePicker';
import TimePicker from './TimePicker';

const INPUT_DATE_FORMAT = 'HH:mm';

/**
 * @typedef {Object} DateTimeContainerProps
 *
 * @property {string} [date] The initial date value, defaults to the current date
 * @property {string} [min] The minimum date for the date picker, if there is one
 * @property {string} [max] The maximum date for the date picker, if there is one
 * @property {string} [userLang='en'] The language for the date and time picker
 * @property {boolean} [showTime=true] Whether or not to show the time picker
 */

/**
 * @param {DateTimeContainerProps} props The props for the component
 * @returns {React.ReactElement}
 */
export default function DateTimeContainer({
  min,
  max,
  date,
  userLang = 'en',
  showTime = true,
}) {
  const datetime = moment(date);
  const [dateState, setDateState] = useState(datetime.format());
  const [timeState, setTimeState] = useState(
    datetime.format(INPUT_DATE_FORMAT),
  );

  const handleDateChange = (value) => {
    setDateState(value);
  };

  const handleTimeChange = (value) => {
    setTimeState(value);
  };

  useEffect(() => {
    const dateInput = document.getElementById('date-input');
    const timeInput = document.getElementById('time-input');
    const dateCallback = (e) => {
      setDateState(e.detail.value);
    };
    const timeCallback = (e) => {
      setTimeState(e.detail.value);
    };

    dateInput.addEventListener('datePickerChange', dateCallback);
    timeInput.addEventListener('timePickerChange', timeCallback);

    return () => {
      dateInput.removeEventListener('datePickerChange', dateCallback);
      timeInput.removeEventListener('timePickerChange', timeCallback);
    };
  }, []);

  return (
    <div
      className="card-body p-0"
      id="datePickerContainer"
      data-testid="datePickerContainer"
    >
      <input
        type="hidden"
        id="date-input"
        data-testid="date-input"
        name="date-input"
        value={dateState}
        onChange={(e) => setDateState(e.target.value)}
      />
      <input
        type="hidden"
        id="time-input"
        data-testid="time-input"
        name="time-input"
        value={timeState}
        onChange={(e) => setTimeState(e.target.value)}
      />

      <DatePicker
        date={dateState}
        min={min}
        max={max}
        userLang={userLang}
        onChange={handleDateChange}
      />
      <hr />
      {showTime && (
        <TimePicker
          time={timeState}
          dateFormat={INPUT_DATE_FORMAT}
          onChange={handleTimeChange}
        />
      )}
    </div>
  );
}
