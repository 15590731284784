class CustomModal {
  constructor(modalId, params) {
    this.modalId = modalId;
    this.confirm = params.confirm;
    this.title = params.title;
    this.content = params.content;
    this.confirmLabel = params.confirmLabel;
    this.dismissLabel = params.dismissLabel;
    this.body = document.querySelector('body');
  }

  create() {
    this.body.insertAdjacentHTML('beforeend', this.modalBlock());

    const modal = $(`#${this.modalId}`).modal();

    const yesButton = document.querySelector(`#${this.modalId}-yes`);
    const noButton = document.querySelector(`#${this.modalId}-no`);

    yesButton.addEventListener('click', () => {
      this.confirm(true);
      modal.modal('hide');
    });

    noButton.addEventListener('click', () => {
      this.confirm(false);
      modal.modal('hide');
    });

    modal.on('hidden.bs.modal', () => {
      modal.remove();
    });
  }

  modalBlock() {
    return `
      <div class="modal fade in" id="${this.modalId}" style="display: block;">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button id="${this.modalId}-close" aria-label="Close" class="close" data-dismiss="modal" type="button">
                <span aria-hidden="true">×</span>
              </button>
              <h4 class="modal-title">${this.title || ''}</h4>
            </div>
            <div class="modal-body">${this.content}</div>
            <div class="modal-footer">
              <button id="${this.modalId}-no" class="btn btn-secondary" type="submit">${this.dismissLabel || 'No'}</button>
              <button id="${this.modalId}-yes" class="btn btn-primary" type="button">${this.confirmLabel || 'Yes'}</button>
            </div>
          </div>
        </div>
      </div>
    `
  }
}

export default CustomModal;
