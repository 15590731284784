class SelectAllResourcesLink {
  constructor() {
    this.link = document.querySelector('.js-iteration-checkboxes-toggle')
    const resourceNodes = document.querySelectorAll('.js-resource-switch [type=checkbox]')
    this.permittedResources = Array.from(resourceNodes)
    this.allChecked = false;
  }

  init() {
    this.bindEventListeners();
  }

  bindEventListeners() {
    this.link.addEventListener('click', (event) => { this.handleClick(event) })
  }

  handleClick(event) {
    event.preventDefault();
    const noResources = document.querySelector('.js-black-sheep')
    for (const resource of this.permittedResources) {
      if(this.allChecked === false){
        resource.checked = true;
        noResources.checked = false
      }
      else{
        resource.checked = false
        noResources.checked = true
      }
    }
    this.allChecked = !this.allChecked;
  }
}

export default SelectAllResourcesLink
