class ShowRetakeForm {
  constructor(event, text) {
    event.preventDefault();
    this.link = event.currentTarget;
    this.text = text;
  }

  init() {
    let form = this.link.closest('.flightpath-confirmation').querySelector('form');
    if (this.link.getAttribute('data-open') === 'false') {
      form.classList.add('opened');
      this.link.setAttribute('data-open', true)
      this.link.textContent = 'Retaking...';
      this.link.closest('.img-overlay').classList.add('hover');
    } else {
      form.classList.remove('opened');
      this.list.setAttribute('data-open', false)
      this.list.textContent = this.text;
      this.link.closest('.img-overlay').classList.remove('hover');
    }
  }
}
export default ShowRetakeForm;
