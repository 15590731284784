import axios from 'axios';
import csrfToken from '../../utils/csrf';
class EditLink {
  constructor() {
    this.editIncidentSubtypes = document.querySelectorAll('.edit-incident-subtype')
    this.incidentSubtypeSelectDropdown = document.querySelectorAll('.js-incident-subtype-select')
    this.cancelEditIncidentSubtypes = document.querySelectorAll('.cancel-edit-incident-subtype')
    this.submitEditIncidentSubtypes = document.querySelectorAll('.submit-edit-incident-subtype')
    this.errorUpdatingIncidentType = document.querySelector('.error-updating-incident-type')
    this.newIncidentSubtypeId
    this.newIncidentSubtypeText
    this.oldIncidentSubtypeText
  }

  init() {
    this.bindEventListener()
  }

  bindEventListener() {
    this.editIncidentSubtypes.forEach(editBtn => {
      editBtn.addEventListener('click', event => {
        this.editIncidentType(event) });
      })
    this.cancelEditIncidentSubtypes.forEach(cancelEditBtn => {
      cancelEditBtn.addEventListener('click', event => {
        this.cancelEdit(event) });
      })
    this.submitEditIncidentSubtypes.forEach(submitEditBtn => {
      submitEditBtn.addEventListener('click', event => {
        this.submitEdit(event) });
      })
    this.incidentSubtypeSelectDropdown.forEach(selectDiv => {
      selectDiv.querySelector('select').addEventListener('change', (event) => {
        this.trackDropdown(event) });
      })
  }

  trackDropdown(e) {
    this.newIncidentSubtypeId = e.currentTarget.value
    let newIncidentSubtypeStatus = e.currentTarget.options[e.currentTarget.selectedIndex].text
    this.newIncidentSubtypeText = newIncidentSubtypeStatus
  }

  disableButtons() {
    this.editIncidentSubtypes.forEach(editBtn => {
      editBtn.disabled = true
      })
  }

  enableButtons() {
    this.editIncidentSubtypes.forEach(editBtn => {
      editBtn.disabled = false
      })
  }

  editIncidentType(e) {
    e.preventDefault()
    let dataRole = e.currentTarget.getAttribute('data-role')
    document.querySelector('[data-id='+dataRole+'].js-incident-subtype-text').classList.add('d-none')
    document.querySelector('[data-role='+dataRole+'].edit-incident-subtype').classList.add('d-none')
    document.querySelector('[data-id='+dataRole+'].js-incident-subtype-select').classList.remove('d-none')
    document.querySelector('[data-id='+dataRole+'].submit-edit-incident-subtype').classList.remove('d-none')
    document.querySelector('[data-id='+dataRole+'].cancel-edit-incident-subtype').classList.remove('d-none')
    this.errorUpdatingIncidentType.classList.add('d-none')
    this.disableButtons()
  }

  cancelEdit(e) {
    e.preventDefault()
    let dataId = e.currentTarget.getAttribute('data-id')
    document.querySelector('[data-id='+dataId+'].js-incident-subtype-text').classList.remove('d-none')
    document.querySelector('[data-role='+dataId+'].edit-incident-subtype').classList.remove('d-none')
    document.querySelector('[data-id='+dataId+'].js-incident-subtype-select').classList.add('d-none')
    document.querySelector('[data-id='+dataId+'].submit-edit-incident-subtype').classList.add('d-none')
    document.querySelector('[data-id='+dataId+'].cancel-edit-incident-subtype').classList.add('d-none')
    this.enableButtons()
  }

  submitEdit(e) {
    e.preventDefault()
    let dataId = e.currentTarget.getAttribute('data-id')
    document.querySelector('[data-id='+dataId+'].js-incident-subtype-text').classList.remove('d-none')
    document.querySelector('[data-role='+dataId+'].edit-incident-subtype').classList.remove('d-none')
    document.querySelector('[data-id='+dataId+'].js-incident-subtype-select').classList.add('d-none')
    document.querySelector('[data-id='+dataId+'].submit-edit-incident-subtype').classList.add('d-none')
    document.querySelector('[data-id='+dataId+'].cancel-edit-incident-subtype').classList.add('d-none')
    this.enableButtons()

    this.oldIncidentSubtypeText = document.querySelector('[data-id='+dataId+'].js-incident-subtype-text').innerText

    let incidentSubtypeToEdit = dataId.substr(dataId.lastIndexOf('_') + 1)

    let incidentSubtypeIds = {
      id: incidentSubtypeToEdit,
      incident_subtype_id: this.newIncidentSubtypeId
    }

    const headers = {
      headers: {
        Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
      }
    }

    axios.put(`/incident-reports/incident_report_urgency_levels/`, incidentSubtypeIds, headers)
      .then(response => {
        // console.log(response)
        document.querySelector('[data-id='+dataId+'].js-incident-subtype-text').innerText = this.newIncidentSubtypeText
        this.errorUpdatingIncidentType.classList.remove('d-none')
        this.errorUpdatingIncidentType.classList.add('text-success')
        this.errorUpdatingIncidentType.innerText = 'The Academic Integrity Type has been successfully updated.'
      })
      .catch((err) => {
        console.log("Error: ", err);
        document.querySelector('[data-id='+dataId+'].js-incident-subtype-text').innerText = this.oldIncidentSubtypeText
        this.errorUpdatingIncidentType.classList.remove('d-none')
        this.errorUpdatingIncidentType.classList.add('text-danger')
        this.errorUpdatingIncidentType.innerText = "Heads up! The Academic Integrity Type hasn't been updated. Please reload the page and try again."
      })
  }

}

export default EditLink
