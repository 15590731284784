/**
 * This class handles Proctoru section on the Iteration form
 */

class LocationHandler {
  constructor() {
    this.nonGlobalLocations = Array.from(document.querySelectorAll('.non-global'))
    this.handleNonGlobalLocationChange = this.handleNonGlobalLocationChange.bind(this)
  }

  init() {
    if (this.nonGlobalLocations.length) {
      this.bindEventListeners()
      this.handleNonGlobalLocationChange()
    }
  }

  bindEventListeners() {
    this.nonGlobalLocations.map((location) => {
      location.addEventListener('change', this.handleNonGlobalLocationChange)
    })
  }

  handleNonGlobalLocationChange() {
    const globalLocation = document.getElementsByClassName('global')[0]
    const hiddenGlobalLocation = document.getElementsByClassName('hidden-global')[0]
    const anySelected = this.nonGlobalLocations.some((location) => location.checked)
    globalLocation.checked = globalLocation.checked ? true : anySelected
    globalLocation.disabled = anySelected
    hiddenGlobalLocation.disabled = !anySelected
  }
}

export default LocationHandler;
