import { useEffect, useState } from 'react';

import { I18n } from 'react-polyglot';

import useLocale from '../../hooks/useLocale';
import SecondCameraSubscription from './EventConsumer';
import ExamComplete from './ExamComplete';
import MediaPermission from './MediaPermission';
import PhonePosition from './PhonePosition';
import { StreamContext } from './contexts';

import '../../stylesheets/components/second_camera/second_camera.scss';

export const SECOND_CAMERA_SCREENS = {
  MEDIA_PERMISSION: 'MEDIA_PERMISSION',
  POSITION_PHONE: 'POSITION_PHONE',
  SELECT_CAMERA: 'SELECT_CAMERA',
  EXAM_COMPLETE: 'EXAM_COMPLETE',
};

/**
 * @typedef {keyof SECOND_CAMERA_SCREENS} Screen
 */

/**
 * @type {Record<Screen, React.ComponentType>}
 */
export const SECOND_CAMERA_COMPONENTS = {
  MEDIA_PERMISSION: MediaPermission,
  POSITION_PHONE: PhonePosition,
  EXAM_COMPLETE: ExamComplete,
};

const App = (props) => {
  /** @type {[Screen, (screen: Screen) => void]} */
  const [currentStep, setCurrentStep] = useState(
    SECOND_CAMERA_SCREENS.MEDIA_PERMISSION,
  );
  const [deviceList, setDeviceList] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState('');
  const [videoStream, setVideoStream] = useState(null);
  const { locale, messages } = useLocale();

  useEffect(() => {
    // subscribe to examComplete Event
    let parts = window.location.pathname.split('/');
    let fulfillmentId = parts[3];
    let secondCamSubscription = new SecondCameraSubscription(
      fulfillmentId,
      () => window.dispatchEvent(new Event('examComplete')),
    );
    secondCamSubscription.subscribe();

    // on unmount unsubscribe
    return () => {
      secondCamSubscription.unsubscribe();
    };
  }, []);

  let contextObj = {
    deviceList,
    setDeviceList,
    selectedDevice,
    setSelectedDevice,
    videoStream,
    setVideoStream,
  };

  const SecondCameraComponent = SECOND_CAMERA_COMPONENTS[currentStep];

  return (
    <I18n locale={locale} messages={messages}>
      <StreamContext.Provider value={contextObj}>
        <div
          id="main-container"
          className="d-flex flex-wrap justify-content-center align-content-center align-items-center overflow-auto vw-100 vh-100"
        >
          {/* TODO: region is only used in one screen, maybe it should be in the context? */}
          <SecondCameraComponent
            setCurrentStep={setCurrentStep}
            region={props.region}
          />
        </div>
      </StreamContext.Provider>
    </I18n>
  );
};

export default App;
export { StreamContext };
