function isBlackboardProvider(lmsProviderName) {
  return lmsProviderName === 'BlackboardLearn';
}

function validationBlackboardProvider(property, lmsProviderName) {
  return (
    property.prop === 'iteration_duration' &&
    isBlackboardProvider(lmsProviderName)
  );
}

export function validateDetails(exam, lmsProviderName) {
  const minDuration = 1;
  const regex =
    /^\[\s*(\{"starts_at"\s*:\s*null,\s*"ends_at"\s*:\s*null\s*\})?\s*\]$|^null$/;
  let missingDetails = [];

  const propertiesToCheck = [
    { prop: 'exam_name', message: 'exam name' },
    {
      prop: 'iteration_duration',
      message: 'exam duration',
      condition: (val) => !val || val <= minDuration,
    },
    { prop: 'iteration_exam_password', message: 'exam password' },
    { prop: 'iteration_expected_test_takers', message: 'test-takers' },
    { prop: 'iteration_exam_url', message: 'exam url' },
    {
      prop: 'windows',
      message: 'exam window',
      condition: (val) => regex.test(val),
    },
  ];

  propertiesToCheck.forEach((property) => {
    let value = exam[property.prop];
    if (
      !validationBlackboardProvider(property, lmsProviderName) &&
      (!value || (property.condition && property.condition(value)))
    ) {
      missingDetails.push(property.message);
    }
  });

  if (missingDetails.length > 0) {
    let missingStr = missingDetails.join(', ');
    exam['isValidExam'] = false;
    exam['errorMsg'] = missingStr;
  } else {
    exam['isValidExam'] = true;
    exam['errorMsg'] = null;
  }
  return exam;
}
