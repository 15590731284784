import GenericButton from '../../GenericButton';

const PrechecksFooterButtonGroup = ({ buttonProps }) => {
  return (
    <div className="row">
      <div className="col-md-12">
        {buttonProps.map(
          ({ buttonText, handleClick, disabled, buttonStyleClass }, index) =>
            buttonText !== null ? (
              <GenericButton
                className={`btn ${buttonStyleClass} precheck-button`}
                key={`prechecks-button-${index}`}
                text={buttonText}
                onClick={handleClick}
                disabled={disabled}
              />
            ) : null,
        )}
      </div>
    </div>
  );
};

PrechecksFooterButtonGroup.propTypes = {
  buttonProps: PropTypes.array.isRequired,
};

PrechecksFooterButtonGroup.defaultProps = {
  buttonProps: [
    {
      buttonText: polyglot.t('prechecks_card_sections_next_button'),
      handleClick: null,
      disabled: false,
      buttonStyleClass: 'btn-precheck-primary',
    },
  ],
};

export default PrechecksFooterButtonGroup;
