class DisplayDob {
  constructor() {
    this.submitBtn = $('[data-behavior="account-submit"]');
    this.dobContainer = $('#dob-container');
    this.enrollmentsContainer = document.querySelector(
      '#enrollments-container',
    );

    /** @type {MutationObserver} */
    this.observer = null;
  }

  init() {
    this.submitBtn?.on('click', () => this.#destroyObserver());
    this.#initObserver();
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  handleSelectChange() {
    const enrollmentSelectMenus = this.#getSelectMenus();

    let renderDobForm = false;
    enrollmentSelectMenus.forEach((menu) => {
      const showDob =
        menu.options[menu.selectedIndex].getAttribute('data-show-dob');
      this.#removeMessage(menu);
      if (showDob === 'true') {
        renderDobForm = true;
        this.#addMessage(menu);
      }
    });

    renderDobForm
      ? this.dobContainer.removeClass('d-none')
      : this.dobContainer.addClass('d-none');
  }

  //#region private methods

  #refreshEnrollmentSelectMenus() {
    const enrollmentSelectMenus = this.#getSelectMenus();

    enrollmentSelectMenus.forEach((menu) => {
      menu.addEventListener('change', this.handleSelectChange);
    });
  }

  #initObserver() {
    const handleMutation = (mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
          this.#refreshEnrollmentSelectMenus();
        }
      }
    };

    this.observer = new MutationObserver(handleMutation);
    this.observer.observe(this.enrollmentsContainer, {
      childList: true,
    });
  }

  #destroyObserver() {
    this.observer.disconnect();
  }

  #getSelectMenus() {
    return document.querySelectorAll('[data-behavior="enrollment-select"]');
  }

  #addMessage(menu) {
    const messageElement = document.createElement('span');
    messageElement.className = 'dob-required-message text-danger';
    messageElement.textContent = polyglot.t(
      'institutions_form_enrollments_dob_msg',
    );
    menu.parentNode.appendChild(messageElement);
  }

  #removeMessage(menu) {
    const messageElement = menu.parentNode.querySelector(
      '.dob-required-message',
    );
    if (messageElement) {
      messageElement.remove();
    }
  }

  //#endregion private methods
}

export default DisplayDob;
