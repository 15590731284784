class ImageZoom {

  constructor() {
    this.COMPONENT_SELECTOR = '[data-behavior=picture-zoom]'
  }

  init() {
    this.unbindEventListeners()
    this.bindEventListeners()
  }

  // prevent multiple events being triggered when navigating between pages.
  unbindEventListeners() {
    $(document).off('click', this.COMPONENT_SELECTOR)
  }

  bindEventListeners() {
    $(document).on('click', this.COMPONENT_SELECTOR, (e) => {
      this.fetchEvent(e)
    })
  }

  fetchEvent(event) {
    event.preventDefault()
    const eventId = event.currentTarget.dataset.id
    const currentPicturePath = event.currentTarget.src.split('/')[event.currentTarget.src.split('/').length - 1];
    if(this.cachedEvent(eventId) !== null) {
      const cachedPicturePath = this.cachedEvent(eventId).attachment_url.split('/')[this.cachedEvent(eventId).attachment_url.split('/').length - 1];
    }
    if (typeof(cachedPicturePath) !== 'undefined' && currentPicturePath === cachedPicturePath) {
      this.zoomIn(this.cachedEvent(eventId))
    } else {
      $.getJSON(event.currentTarget.dataset.eventUrl, (data) => {
        this.zoomIn(data)
        this.cache(data)
      })
    }
  }

  cache(data) {
    localStorage.setItem(this.cachedKey(data.id), JSON.stringify(data))
  }

  isCached(eventId) {
    // return false if browser doesn't support localStorage
    if (! window.localStorage)
      return false

    return this.cachedEvent(eventId) != null
  }

  cachedEvent(eventId) {
    const eventData = localStorage.getItem(this.cachedKey(eventId))
    return JSON.parse(eventData)
  }

  cachedKey(eventId) {
    return `event-${ eventId }`
  }

  zoomIn(image) {
    this.appendTemplateContainer(image)
    this.showModal(image.id)
  }

  appendTemplateContainer(image) {
    const template = this.template(image)
    const $container = $('.picture-zoom-container')

    if ($container.length) {
      $container.replaceWith(template)
    } else {
      $('body').append(template)
    }
  }

  showModal(imageId) {
    $(`#image_${ imageId }`).modal({
      keyboard: true,
      show: true
    })
  }

  template(image) {
    const templateData = {
      url: image.attachment_url,
      heading: image.heading,
      createdAt: image.created_at,
      imgId: `image_${ image.id }`
    }

    return `<div class="picture-zoom-container" data-behavior="picture-zoom-container">
            <div id="${templateData.imgId}" class="modal fade" role="dialog" tabindex="-1">
              <div class="modal-dialog modal-xl">
                <div class="modal-content">
                  <div class="modal-header">
                    <h4 class="modal-title">${templateData.heading} - ${templateData.createdAt}</h4>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                  </div>
                  <div class="modal-body">
                    <div class="card">
                      <img class="modal-image-fill" src='${templateData.url}'>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>`
  }
}

export default ImageZoom;
