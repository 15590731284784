import PropTypes from 'prop-types'

const Checkbox = ({generatedId, field}) => {
  return (
    <div className="mt-2">
      <input type="hidden"
        name={`user[enrollments_attributes][${generatedId}][fields][${field.field_as_attribute}]`}
        value="0" />
      <label className="aef-label">
        <input type="checkbox"
          value='1'
          name={`[user][enrollments_attributes][${generatedId}][fields][${field.field_as_attribute}]`}
          id={`[user][enrollments_attributes][${generatedId}][fields][${field.field_as_attribute}]`}
          className="aef-checkbox" data-type="checkbox"/>
      </label>
      <div className="form-text text-muted">
        {field.description}
      </div>
    </div>
  )
}

Checkbox.propTypes = {
  field: PropTypes.object.isRequired,
  generatedId: PropTypes.number.isRequired,
}

export default Checkbox
