import PropTypes from 'prop-types';

class StatusIcons extends React.Component {
  render() {
    return (
      <div className="row m-3 icons">
        <div className="col-md-2"></div>
        {this.cameraIcon()}
        {this.micIcon()}
        {this.computerIcon()}
        {this.internetIcon()}
        <div className="col-md-2"></div>
      </div>
    );
  }

  cameraIcon() {
    return (
      <div className="col-md-2 text-center">
        <img
          src={this.props.cameraPath}
          alt={`Camera Icon with ${this.props.cameraStatus} status`}
          height="100"
        ></img>
      </div>
    );
  }

  micIcon() {
    return (
      <div className="col-md-2 text-center">
        <img
          src={this.props.micPath}
          alt={`Microphone Icon with ${this.props.micStatus} status`}
          height="100"
        ></img>
      </div>
    );
  }

  computerIcon() {
    return (
      <div className="col-md-2 text-center">
        <img
          src={this.props.computerPath}
          alt={`Computer Icon with ${this.props.computerStatus} status`}
          height="100"
        ></img>
      </div>
    );
  }

  internetIcon() {
    return (
      <div className="col-md-2 text-center">
        <img
          src={this.props.internetPath}
          alt={`Internet Speed Icon with ${this.props.internetStatus} status`}
          height="100"
        ></img>
      </div>
    );
  }
}

StatusIcons.propTypes = {
  cameraStatus: PropTypes.string.isRequired,
  micStatus: PropTypes.string.isRequired,
  computerStatus: PropTypes.string.isRequired,
  internetStatus: PropTypes.string.isRequired,
  cameraPath: PropTypes.string.isRequired,
  micPath: PropTypes.string.isRequired,
  computerPath: PropTypes.string.isRequired,
  internetPath: PropTypes.string.isRequired,
};

export default StatusIcons;
