class AlignFooterElements {
  #throttled = false;

  constructor() {
    this.typeColumn = $('#type-col');
    this.priceColumn = $('#price-col');
    this.total = $('#total');
    this.price = $('#price');
    this.appoinments = $('#appointments');
    this.appoinmentsTotal = $('#appointmentsTotal');
    this.premiums = $('#premiums');
    this.premiumsTotal = $('#premiumsTotal');

    this.#handleResize = this.#handleResize.bind(this);
  }

  init() {
    window.addEventListener('resize', this.#handleResize);
    this.#placeFooterElements();
  }

  #handleResize = () => {
    if (!this.#throttled) {
      this.#placeFooterElements();
      this.#throttled = true;
      setTimeout(() => {
        this.#throttled = false;
      }, 500);
    }
  };

  #placeFooterElements() {
    this.total?.offset({ left: this.typeColumn?.offset()?.left });
    this.price?.offset({ left: this.priceColumn?.offset()?.left });
    this.appoinments?.offset({ left: this.typeColumn?.offset()?.left });
    this.appoinmentsTotal?.offset({ left: this.priceColumn?.offset()?.left });
    this.premiums?.offset({ left: this.typeColumn?.offset()?.left });
    this.premiumsTotal?.offset({ left: this.priceColumn?.offset()?.left });
  }
}

export default AlignFooterElements;
