import addDynamicEventListener from '../utils/dynamicListener';
import csrfToken from '../../src/utils/csrf';
import axios from 'axios';

// BoldChatEventLogger
//
// This creates an event to log when the bold 360 chat is used.
class BoldChatEventLogger {
  constructor() {
    this.chatData = document.querySelector('[data-behavior="bold-chat-interaction-events-url"]')
  }

  init() {
    if (this.chatData) {
      this.bindEventListeners()
    }
  }

  bindEventListeners() {
    addDynamicEventListener(
      document.body,
      'click',
      "img[src='http://s3.amazonaws.com/ps.projects/ProctorU/Assets/bot+icon.png']",
      () => { this.handleBoldChatClick() }
    );
  }

  handleBoldChatClick() {
    const headers = {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken()
      }
    }
    const url = this.chatData.dataset.url

    axios.post(url, {}, headers)
  }
}

export default BoldChatEventLogger
