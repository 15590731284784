import axios from 'axios';
import MediaImages from '../../components/Fulfillments/Media/Templates/MediaImages';
import MediaScreenshots from '../../components/Fulfillments/Media/Templates/MediaScreenshots';
import MediaVideos from '../../components/Fulfillments/Media/Templates/MediaVideos';
import MediaAll from '../../components/Fulfillments/Media/Templates/MediaAll';


const AxiosConfig = {
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  }
}

const MediaTypes = {
  'images': MediaImages,
  'screenshots': MediaScreenshots,
  'videos': MediaVideos,
}

class MediaObject {
  constructor() {
    this.imagesLoaded = false
    this.videosLoaded = false
    this.screenshotsLoaded = false
    this.recordingsLoaded = false
    this.combined_screensLoaded = false

    this.mediaObjects = [];
    this.images = [];


    this.itemsToDownload = {
      images: [],
      videos: [],
      screenshots: [],
      combined_screens: [],
      recordings: []
    };

    this.permissionToDelete = document.getElementById('media-modal').getAttribute('data-permission');
    this.disableVideoRecording = document.getElementById('media-modal').getAttribute('data-disable-video-recording');

  }

  init() {
    const self = this;

    $(document).on('click', '#media-modal [href^="#"]', function(e) {
      e.preventDefault();
      self._loadMediaObjects($(this));
    });

    $(document).on('click', '#launch-media-modal', function(e) {
      e.preventDefault();
      self._loadMediaObjects($('[href="#images"]'));
    });

    $(document).on('click', '.request-resource', function(e) {
      e.preventDefault();
      self._requestMediaObjects(e.target.dataset);
    });

    $(document).on('click', '.retrieve-all-media', function(e) {
      e.preventDefault();
      self._enableDisableRetrievalButtons(true);
      self._retrieveAllMediaObjects();
      self._enableDisableRetrievalButtons(false);
    });

    $(document).on('click', '.retrieve-selected-media', function(e) {
      e.preventDefault();
      this.disabled = true;
      self._enableDisableRetrievalButtons(true);
      self._retrieveSelectedMediaObjects();
      self._enableDisableRetrievalButtons(false);
    });

    this._setupVideoPausing();
  }

  // Reload links if it's been more than 5 minutes, to avoid
  // user experiencing errors due to expired links
  _checkTargetNeedsReloaded(target) {
    const targetKey = `${target}_LoadedAt`;
    const fiveMinutes = 5 * 60 * 1000;
    const lastReload = this[targetKey];
    return !lastReload || Date.now() - lastReload > fiveMinutes;
  }

  _updateTargetLastReload(target) {
    const targetKey = `${target}_LoadedAt`;
    this[targetKey] = Date.now();
  }

  _requestMediaObjects(resource) {
    const href = $('#launch-media-modal').data('href');
  
    //build the URL based on the resource data uuid. 
    //in some instances it is uuid, and others it is fulfillment_uuid
    const id = resource.uuid || resource.fulfillment_uuid;
    const url = `${(href || window.location.href)}/request_resource?resource=${resource.target}&fulfillment_uuid=${id}`;
    
    const target = resource.target == 'combined_screens' ? 'combined videos' : resource.target
    axios.get(url, AxiosConfig)
      .then(() => {
      $(`#${resource.element}`).html(polyglot.t('media_request', { resource: target }));
    });
  }

  _loadMediaObjects(el) {
    const mediaTypes = ['images', 'screenshots', 'videos', 'recordings', 'combined_screens'];
    const targetHref = el.attr('href');
    const target = targetHref.slice(1);

    if (target !== 'allmedia') {
      if (this._checkTargetNeedsReloaded(target)) { this.getObjects(target); }
    } else {
      mediaTypes.forEach( mediaType => {
        if (this._checkTargetNeedsReloaded(target)) {
          this.getObjects(mediaType);
        }
      });
    }
  }

  getObjects(target) {
    const href = $('#launch-media-modal').data('href');
    const url = `${(href || window.location.href)}/${target}` + (href ? '?fulfillment=true' : '');

    const targetHref = `#${target}`;
    const allMediaTargetHref = `#allmedia_objects`;

    this._updateTargetLastReload(target);

    this._toggleLoader(targetHref, 'show');

    this.mediaObjects.forEach(mediaObj => {
      mediaObj[target] = [];
    });

    if(this.disableVideoRecording === "true") {
      $(targetHref).html(`<p class='mb-0'>${polyglot.t('disable_video_recording_reason')}</p>`);
      $(allMediaTargetHref).html(`<p class='mb-0'>${polyglot.t('disable_video_recording_reason')}</p>`);
      const download_buttons = document.getElementById("download_buttons");
      if(download_buttons) {
        download_buttons.style.display ='none';
      }
    } else {
        axios.get(url, AxiosConfig)
        .then( response => {
          let fulfillmentFound = false;
          if (response.data.length > 0) {
            if (target == 'images') {
              this.images = response.data;
            } else {
              response.data.forEach(object => {
                this.mediaObjects.forEach(mediaObj => {
                  if (mediaObj.fulfillment_uuid == object.fulfillment_uuid) {
                    fulfillmentFound = true;

                    this._addItemToAllMediaArray(target, object, mediaObj);
                  }
                });

                if (!fulfillmentFound) {
                  const fulfillmentObj = {
                    fulfillment_uuid: object.fulfillment_uuid,
                    fulfillment_start: object.fulfillment_start,
                    fulfillment_status: object.fulfillment_status,
                    videos: [],
                    recordings: [],
                    combined_screens: [],
                    images: [],
                    screenshots: []
                  };

                  this._addItemToAllMediaArray(target, object, fulfillmentObj);

                  this.mediaObjects.push(fulfillmentObj);
                }
                fulfillmentFound = false;
              });
            }

            const allData = {allMedia: this.mediaObjects, permission: this.permissionToDelete, images: this.images, target: target}

            const MediaComponentTarget = target == 'screenshots' || target == 'images' ? target : 'videos'
            const MediaComponent = MediaTypes[MediaComponentTarget]
            ReactDOM.render(<MediaComponent {...allData} />, $(targetHref).get(0))
            ReactDOM.render(<MediaAll {...allData} />, $(allMediaTargetHref).get(0))

            this._addAllMediaCheckboxListeners();

            const allMediaItems = document.querySelectorAll(`[data-mediatype="${target}"]`);

            [...allMediaItems].forEach(
              obj => obj.addEventListener('change', (event) => { this.handleAddRemoveMediaDownloadItems(event) })
            );
            const videoConverterDivText = document.getElementById('video-converter-text')
            if(videoConverterDivText) {
              videoConverterDivText.innerHTML = `${polyglot.t('video_codec_converter')}`
            }
            const recordingConverterDivText = document.getElementById('recording-converter-text')
            if(recordingConverterDivText) {
              recordingConverterDivText.innerHTML = `${polyglot.t('video_codec_converter')}`
            }
          } else {
              $(targetHref).html(`<p class='mb-0'>Sorry, no ${target == 'combined_screens' ? 'combined videos' : target} available.</p>`);
            if (this.mediaObjects.length == 0 && this.images.length == 0) {
              $(allMediaTargetHref).html(`<p class='mb-0'>${polyglot.t('no_media')}</p>`);
            }
          }
      }).catch((error) => {
        $(targetHref).html("<p class='mb-0 text-danger'>An error occurred.</p>");
      }).finally(() => this._toggleLoader(targetHref, 'hide'))
    }

  }

  _addItemToAllMediaArray(target, responseObj, mediaObj) {
    switch(target) {
      case 'recordings':
        if (!responseObj.hasOwnProperty('waiting')) {
          responseObj.urls.forEach(url => {
            mediaObj[target].push({
              session_id: responseObj.session_id,
              time: url.time,
              url: url.url
            });
          });
        } else {
          mediaObj[target].push(responseObj);
        }
        break;
      case 'videos':
        if (!responseObj.hasOwnProperty('waiting')) {
          responseObj.video_urls.forEach(url => {
            mediaObj[target].push({
              url: url
            });
          });
        } else {
          mediaObj[target].push(responseObj);
        }
        break;
      case 'combined_screens':
        if (!responseObj.hasOwnProperty('waiting')) {
          responseObj.video_urls.forEach(url => {
            mediaObj[target].push({
              url: url
            });
          });
        } else {
          mediaObj[target].push(responseObj);
        }
        break;
      case 'screenshots':
        mediaObj[target].push(responseObj);
        break;
      default:
        mediaObj[target] = mediaObj[target].concat(responseObj.urls);
    }
  }

  _toggleLoader(el, action) {
    if (action === 'show') {
      $(el).addClass('with-loader');
    } else {
      $(el).removeClass('with-loader');
    }
  }

  handleAddRemoveMediaDownloadItems(event) {
    const mediaType = event.target.dataset.mediatype;
    const position = event.target.dataset.position;
    const checked = event.target.checked;
    const itemIndex = this.itemsToDownload[mediaType].indexOf(position);

    if(checked) {
      this.itemsToDownload[mediaType].push(position);
    } else {
      if (itemIndex > -1) {
        this.itemsToDownload[mediaType].splice(itemIndex, 1);
      }
    }
  }

  _addAllMediaCheckboxListeners() {
    const mediaTypes = ['images', 'videos', 'screenshots', 'recordings', 'combined_screens'];

    mediaTypes.forEach( type => {
      let allMediaItems = document.querySelectorAll(`[data-mediatype="${type}"]`);

      [...allMediaItems].forEach(
        obj => obj.addEventListener('change', (event) => {this.handleAddRemoveMediaDownloadItems(event) })
      );
    });
  }

  _retrieveAllMediaObjects() {
    let requestMessageNeeded = false;

    this.mediaObjects.forEach( (object, obj_index) => {
      object.recordings.forEach( (recording, rec_index) => {
        if (recording.hasOwnProperty('waiting')) {
          if (recording.data.url) {
            window.open(recording.data.download_url);
          } else if (recording.enabled) {
            requestMessageNeeded = true;
            this._requestMediaObjects({fulfillment_uuid: object.fulfillment_uuid, target: 'recordings', element: 'recordings_' + obj_index + '_' + rec_index});
          }
        } else {
          window.open(recording.url, '_blank');
        }
      });

      object.videos.forEach( (video, vid_index) => {
        if (video.hasOwnProperty('waiting')) {
          if (video.data.url) {
            window.open(video.data.download_url);
          } else if (video.enabled) {
            requestMessageNeeded = true;
            this._requestMediaObjects({fulfillment_uuid: object.fulfillment_uuid, target: 'videos', element: 'videos_' + obj_index + '_' + vid_index});
          }
        } else {
          window.open(video.url, '_blank');
        }
      });

      object.combined_screens.forEach( (combined_screen, combined_index) => {
        if (combined_screen.hasOwnProperty('waiting')) {
          if (combined_screen.data.url) {
            window.open(combined_screen.data.download_url);
          } else if (combined_screen.enabled) {
            requestMessageNeeded = true;
            this._requestMediaObjects({fulfillment_uuid: object.fulfillment_uuid, target: 'combined_screens', element: 'combined_screens_' + obj_index + '_' + combined_index});
          }
        } else {
          window.open(combined_screen.url, '_blank');
        }
      });

      object.screenshots.forEach( screenshot => {
        window.open(screenshot.url, '_blank');
      });
    });

    this.images.forEach( image => {
      window.open(image.url, '_blank');
    });

    if(requestMessageNeeded) {
      const allMediaRequestDiv = document.getElementById('all_media_requested');
      allMediaRequestDiv.innerHTML = polyglot.t('all_media_request');
    }
  }

  _retrieveSelectedMediaObjects() {
    let requestMessageNeeded = false;
    this.itemsToDownload.recordings.forEach( recording => {
      let rec_position = recording.split('_');
      if( this.mediaObjects[rec_position[0]].recordings[rec_position[1]].hasOwnProperty('waiting')) {
        if (this.mediaObjects[rec_position[0]].recordings[rec_position[1]].data.url) {
          window.open(this.mediaObjects[rec_position[0]].recordings[rec_position[1]].data.download_url);
        } else if (this.mediaObjects[rec_position[0]].recordings[rec_position[1]].enabled) {
          requestMessageNeeded = true;
          this._requestMediaObjects({fulfillment_uuid: this.mediaObjects[rec_position[0]].recordings[rec_position[1]].fulfillment_uuid, target: 'recordings', element: 'recordings_' + recording});
        }
      } else {
        window.open(this.mediaObjects[rec_position[0]].recordings[rec_position[1]].url, '_blank');
      }
    });

    this.itemsToDownload.videos.forEach( video => {
      let video_position = video.split('_');
      if( this.mediaObjects[video_position[0]].videos[video_position[1]].hasOwnProperty('waiting')) {
        if (this.mediaObjects[video_position[0]].videos[video_position[1]].data.url) {
          window.open(this.mediaObjects[video_position[0]].videos[video_position[1]].data.download_url);
        } else if (this.mediaObjects[video_position[0]].videos[video_position[1]].enabled) {
          requestMessageNeeded = true;
          this._requestMediaObjects({fulfillment_uuid: this.mediaObjects[video_position[0]].videos[video_position[1]].fulfillment_uuid, target: 'videos', element: 'videos_' + video});
        }
      } else {
        window.open(this.mediaObjects[video_position[0]].videos[video_position[1]].url, '_blank');
      }
    });

    this.itemsToDownload.combined_screens.forEach( combined_screen => {
      let combined_screen_position = combined_screen.split('_');
      if( this.mediaObjects[combined_screen_position[0]].combined_screens[combined_screen_position[1]].hasOwnProperty('waiting')) {
        if (this.mediaObjects[combined_screen[0]].combined_screens[combined_screen_position[1]].data.url) {
          window.open(this.mediaObjects[combined_screen_position[0]].combined_screens[combined_screen_position[1]].data.download_url);
        } else if (this.mediaObjects[combined_screen_position[0]].combined_screens[combined_screen_position[1]].enabled) {
          requestMessageNeeded = true;
          this._requestMediaObjects({fulfillment_uuid: this.mediaObjects[combined_screen_position[0]].combined_screens[combined_screen_position[1]].fulfillment_uuid, target: 'combined_screens', element: 'combined_screens_' + combined_screen});
        }
      } else {
        window.open(this.mediaObjects[combined_screen[0]].combined_screens[combined_screen[1]].url, '_blank');
      }
    });

    this.itemsToDownload.images.forEach( image => {
      window.open(this.images[image].url, '_blank');
    });

    this.itemsToDownload.screenshots.forEach( screenshot => {
      let screenshot_position = screenshot.split('_');
      window.open(this.mediaObjects[screenshot_position[0]].screenshots[screenshot_position[1]].url, '_blank');
    });

    if(requestMessageNeeded) {
      const allMediaRequestDiv = document.getElementById('all_media_requested');
      allMediaRequestDiv.innerHTML = polyglot.t('all_media_request');
    }

    this._resetDownloadItems();
  }

  _resetDownloadItems() {
    this.itemsToDownload = {
      images: [],
      videos: [],
      screenshots: [],
      combined_screens: [],
      recordings: []
    };

    const mediaCheckboxes = document.querySelectorAll("[data-mediatype]");
    mediaCheckboxes.forEach(checkbox => {
      checkbox.checked = false;
    });
  }

  _enableDisableRetrievalButtons(disable) {
    const retrievalButtons = document.querySelectorAll('.retrieve-media-btn');
    retrievalButtons.forEach(btn => {
      btn.disabled = disable;
    });
  }

  _setupVideoPausing() {
    $('#media-modal').on('hidden.bs.modal', function(e) {
      document.querySelectorAll('.video-player').forEach(function(player) {
        player.pause();
      });
    });
  }
}

export default MediaObject;
