import AdjustContentHeight from './shared_actions/AdjustContentHeight';
import UpdateNextAndPreviousButtons from './shared_actions/UpdateNextAndPreviousButtons';
import ShowPreviousTab from './shared_actions/ShowPreviousTab';

class PreviousButton {
  constructor() {
    this.previousButton = document.querySelector('.js-previous-step');
  }

  init() {
    this.previousButton.addEventListener('click', (event) => { this.handleClick(event) });
  }

  handleClick(event) {
    event.preventDefault();
    new ShowPreviousTab();
    new UpdateNextAndPreviousButtons().init();
  }
}

export default PreviousButton;
