import Checkbox from './Checkbox'
import DateSelect from './DateSelect'
import Email from './Email'
import NumberField from './NumberField'
import Select from './Select'
import TextField from './TextField'

const FieldTypes = {
  'checkbox': Checkbox,
  'date_select': DateSelect,
  'email': Email,
  'number_field': NumberField,
  'select': Select,
  'text_field': TextField,
}

const Container = ({fields, generatedId}) => {
    return (
      <div className="bg-light p-3 mb-3 border border-1">
        {
          fields.map((field) => {
            const data = { field, generatedId }
            const EnrollmentField = FieldTypes[field.field_type]

            return (
              <div className="form-group row" data-form-group={field.field_as_attribute} key={`${field.field_type}_${field.field}`}>
                <label className={`col-sm-4 col-form-label  ${field.required ? 'required' : ''}`}
                  htmlFor={`[user][enrollments_attributes][${generatedId}][fields][${field.field_as_attribute}]`}>
                  {field.field}
                </label>
                <div className="col-form-field">
                  <EnrollmentField {...data} />
                  <span className="d-none form-text text-danger error" data-type="error">can't be blank</span>
                </div>
              </div>
            )
          })
        }
      </div>
    )
}

export default Container
