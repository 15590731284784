import GenericButton from '../GenericButton';
import UrlPusherModalView from './UrlPusherModalView';

const UrlPusherProctorView = (
  {
    proctorConnected,
    testTakerConnected,
    code,
    showModal,
    handleShow,
    handleClose,
    handleUrl,
    urls
  }
) => {
  const renderNotConnected = () => {
    return (
      <p><i className="fas fa-ban text-muted mr-2 fa-xs"></i>Not connected</p>
    )
  }

  const renderConnected = () => {
    return (
      <p><i className="fa fa-circle text-success mr-2 fa-xs"></i>Connected</p>
    )
  }

  const pushText = () => {
    return (testTakerConnected ? 'Push URL' : 'Test-Taker is unavailable')
  }

  return (

    <div>
      <div className="card mb-3" style={{marginBottom: '0px'}}>
        <div className="card-body">
          <div className="card mb-2">
            <div className="card-body">
              <div className="row pb-0 pt-2 text-center">
                <div className="col-sm-4">
                  <strong>Your status</strong>
                  { proctorConnected ? renderConnected() : renderNotConnected() }
                </div>
                <div className="col-sm-4">
                  <strong>Their status</strong>
                  { testTakerConnected ? renderConnected() : renderNotConnected() }
                </div>
                <div className="col-sm-4">
                  <strong>Code</strong>
                  <p>{code ? code : 'Generating code' }</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <GenericButton className="btn btn-primary btn-block" text={pushText()} onClick={handleShow} disabled={!testTakerConnected}/>
            </div>
          </div>
        </div>
      </div>
      <UrlPusherModalView showModal={showModal} handleClose={handleClose} onClick={handleUrl} urls={urls}/>
    </div>
  )
}

export default UrlPusherProctorView;
