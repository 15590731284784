import PropTypes from 'prop-types';
import UrgencyLevelPane from './UrgencyLevelPane';
import IncidentAlert from '../Shared/IncidentAlert';

class UrgencyLevelPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alertVisible: false,
      alertText: '',
      alertType: ''
    }
  }

  filterUrgency = (priority) => {
    return this.props.urgencyLevels.filter(function(obj) {
      return obj.priority === priority
    });
  }

  toggleAlert = (text, type) => {
    this.setState({
      alertVisible: true,
      alertText: text,
      alertType: type
    });
  }

  hideAlert = () => {
    this.setState({ alertVisible: false });
  }

  render = () => {
    let alertEl;

    if (this.state.alertVisible) {
      alertEl = (
          <IncidentAlert text={this.state.alertText}
            type={this.state.alertType}
            hideAlert={this.hideAlert} />
      );
    }

    return (
      <div className="row">
        {alertEl}
        <UrgencyLevelPane priority='High'
          urgencyLevels={this.filterUrgency('red')}
          toggleAlert={this.toggleAlert} />
        <UrgencyLevelPane priority='Medium'
          urgencyLevels={this.filterUrgency('yellow')}
          toggleAlert={this.toggleAlert} />
        <UrgencyLevelPane priority='Low'
          urgencyLevels={this.filterUrgency('green')}
          toggleAlert={this.toggleAlert} />
      </div>
    );
  }
}


UrgencyLevelPanel.propTypes = {
  urgencyLevels: PropTypes.array.isRequired
}

export default UrgencyLevelPanel;
