import {useEffect} from 'react';
import ActionButtons from "../Shared/ActionButtons";

const PhoneCheckProcedure = (props) => {
	const procedure = props.procedure;
	const sendEvent = props.sendEvent;
	const handleNext = props.handleNext;
	const openModal = props.openModal;
	const closeModal = props.closeModal;
	const markProcCompleted = props.markProcCompleted;
	const getFulfillmentEvents = props.getFulfillmentEvents;
	const getVideoPlayerEvent = props.getVideoPlayerEvent;
	const showInPlayer = props.showInPlayer

	const resolveEvent = 'Event::ResolutionStartedPhoneCheck';

	useEffect(() => {
		getFulfillmentEvents(['Event::ProctorPhoneCheckCompleted'], (response) => {
			if(response.data.events.length > 0){
				markProcCompleted(procedure.id);
			} else {
				sendEvent('Event::ProctorPhoneCheckStarted', {});
			}
		})
	},[]);

	// useEffect(() => {
	// 	getVideoPlayerEvent('Event::CellPhoneCheckLoaded', (response) => {
	// 		if(response.data.event?.type) {
	// 			showInPlayer(response.data.event)
	// 		}
	// 	})
	// },[]);

	const completeProcedure = () => {
		sendEvent('Event::ProctorPhoneCheckCompleted', {});
		markProcCompleted(procedure.id);
		handleNext();
	};

	const resolveProcedure = () => {
		openModal();
		sendEvent(resolveEvent, {});
	}
	return(
		<>
			<ActionButtons
				resovleBtnText={'Resolve...'}
				confirmBtnText={'Phone Secured'}
				confirmBtnAction={completeProcedure}
				resolveBtnAction={resolveProcedure}
			/>
		</>
	)
}

export default PhoneCheckProcedure;
