class LockableFieldToggle {
  constructor() {
    this.toggleButton = document.querySelector(
      '[data-behavior="lockable-field-toggle"]'
    );
    this.lockableFields = document.querySelectorAll(
      '[data-behavior="lockable-field"]'
    );
    this.handleToggle = this.handleToggle.bind(this);
  }

  init() {
    this.bindEventListeners();
  }

  bindEventListeners() {
    this.toggleButton.addEventListener('click', this.handleToggle);
  }

  handleToggle(event) {
    event.preventDefault();

    this.toggleFields();
  }

  toggleFields() {
    this.toggleButton.querySelector('span').classList.toggle('fa-lock');
    this.toggleButton.querySelector('span').classList.toggle('fa-unlock');

    this.lockableFields.forEach(field => {
      field.disabled = !field.disabled;
    });
  }
}

export default LockableFieldToggle;
