import csrfToken from '../utils/csrf';
import Toast from '../utils/Toast';

/** AccessCodesReactivator
 *  Controls the action of the 'reactivate' button on access codes index page
 */

class AccessCodeReactivator {
  constructor() {
    this.base_url = `${document.location.origin}/access-codes/`;
    this.buttons = document.querySelectorAll(
      'button[name="reactivate-button"]'
    );
    this.submit = this.submit.bind(this);
  }

  init() {
    this.bindEventListeners();
  }

  reactivate(accessCodeId) {
    const url = `${this.base_url}${accessCodeId}/reactivations`;
    fetch(url, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': csrfToken()
      }
    })
      .then(response => {
        if (!response.ok) {
          this.handleSubmitError();
        } else {
          this.handleSubmitSuccess(accessCodeId);
        }
      })
      .catch(err => {
        this.handleSubmitError(err);
      });
  }

  submit(event) {
    const accessCodeId = event.target.dataset.accessCodeId;
    this.reactivate(accessCodeId);
  }

  handleSubmitError(error = null) {
    const toastMessage = error || window.polyglot.t('access_codes_index_toast_error');
    new Toast().danger({
      message: toastMessage
    });
  }

  handleSubmitSuccess(accessCodeId) {
    const orderLinkString = `[name="order-link-element"][data-access-code-id="${accessCodeId}"]`;
    const usedAtString = `[name="used-at-element"][data-access-code-id="${accessCodeId}"]`;
    const actionString = `[name="action-element"][data-access-code-id="${accessCodeId}"]`;

    [
      document.querySelector(orderLinkString),
      document.querySelector(usedAtString),
      document.querySelector(actionString)
    ].forEach(element => {
      element.innerHTML = '';
    });
    const toastMessage = window.polyglot.t('access_codes_index_toast_success');
    new Toast().success({
      message: toastMessage
    });
  }

  bindEventListeners() {
    this.buttons.forEach(button => {
      button.addEventListener('click', this.submit);
    });
  }
}

export default AccessCodeReactivator;
