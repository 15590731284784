import PropTypes from 'prop-types';
import MetricsAccordion from './MetricsAccordion';
import csrfToken from '../../src/utils/csrf';

class SystemDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      notFoundItems: this.getNotFoundResults(),
      cameraSettings: this.filterNotFoundResults(this.props.cameraSettings),
      computerSettings: this.filterNotFoundResults(this.props.computerSettings),
      internetSettings: this.filterNotFoundResults(this.props.internetSettings),
    };
  }

  filterSettingNotFoundResults(settings) {
    const { helpLinks } = this.props;
    const filteredArray = [];

    settings.results.map((result) => {
      if (result.hasOwnProperty('not_found') && result.not_found) {
        let tmpArray = result.message.split(':').map((item) => item.trim());
        if (
          result.hasOwnProperty('type') &&
          helpLinks.hasOwnProperty(result.type) &&
          this.props.firefoxData.warning !== false
        ) {
          tmpArray.push(helpLinks[result.type]);
        }
        filteredArray.push(tmpArray);
      }
    });

    return filteredArray;
  }

  getNotFoundResults() {
    const { cameraSettings, computerSettings, internetSettings } = this.props;
    return [
      ...this.filterSettingNotFoundResults(cameraSettings),
      ...this.filterSettingNotFoundResults(computerSettings),
      ...this.filterSettingNotFoundResults(internetSettings),
    ];
  }

  filterNotFoundResults(settings) {
    const filteredResults = [];

    settings.results.map((result) => {
      if (!result.hasOwnProperty('not_found') || !result.not_found) {
        filteredResults.push(result);
      }
    });

    settings.results = filteredResults;
    return settings;
  }

  checkSuccessful() {
    const { cameraSettings, computerSettings, internetSettings } = this.state;
    const { firefoxData } = this.props;

    return (
      cameraSettings.status &&
      computerSettings.status &&
      internetSettings.status &&
      !firefoxData.hasOwnProperty('message')
    );
  }

  renderSuccessMessage() {
    if (this.checkSuccessful()) {
      return (
        <div className="tio-success">
          <h3>{polyglot.t('system_metric_equipment_success_header')}</h3>
          <p>
            <span className="mb-5">
              {polyglot.t('system_metrics_equipment_check_warning')}
            </span>
            <br />
          </p>
          {this.unifyBrowserDownload()}
        </div>
      );
    } else {
      return null;
    }
  }

  sendEvent() {
    return fetch(`/internal/unify-browser`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': csrfToken(),
        Accept: 'application/json',
        dataType: 'application/json',
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        event: 'Event::UnifyBrowserLinkClicked',
      }),
    });
  }

  unifyBrowserDownload() {
    const { unifyDownloadOption } = this.props;

    const unifyBtnClicked = () => {
      this.sendEvent();
    };

    if (unifyDownloadOption.hasOwnProperty('link')) {
      return (
        <p style={{ fontSize: '1.3em' }}>
          {polyglot.t('system_metrics_unify_download_option_1')}{' '}
          <a
            className="unify-browser-download-link"
            href={unifyDownloadOption.link}
            target="_blank"
            onClick={unifyBtnClicked}
          >
            {polyglot.t('system_metrics_unify_download_option_link')}
          </a>{' '}
          {polyglot.t('system_metrics_unify_download_option_2')}
        </p>
      );
    } else {
      return null;
    }
  }

  renderUnifyDownloadOption() {
    if (!this.checkSuccessful()) {
      return (
        <div className="unify-browser-download">
          <span className="mb-5">{this.unifyBrowserDownload()}</span>
          <br />
        </div>
      );
    } else {
      return null;
    }
  }

  renderWarning() {
    const { notFoundItems } = this.state;
    const firefoxOnlyEnabled =
      this.props.firefoxData.warning === false
        ? polyglot.t('system_metrics_warning_header_firefox_only')
        : polyglot.t('system_metrics_warning_header');

    if (notFoundItems.length > 0) {
      return (
        <div className="tio-warning">
          <h3 className="mb-3">{firefoxOnlyEnabled}</h3>
          <div>
            {notFoundItems.map((item, index) => {
              return (
                <p key={`not_found_${index}`}>
                  {item[0]}:<br />
                  <span className="system-metric">{item[1]}</span>
                  <br />
                  {item.length > 2 && (
                    <a
                      className="learn-more"
                      href={item[2].link}
                      target="_blank"
                    >
                      {item[2].text}
                    </a>
                  )}
                </p>
              );
            })}
            {this.renderFirefoxMessage()}
          </div>
        </div>
      );
    }
    return null;
  }

  renderFirefoxMessage() {
    const { firefoxData, helpLinks } = this.props;

    if (firefoxData.hasOwnProperty('warning') && firefoxData.warning) {
      return (
        <div>
          <br />
          <br />
          <p>
            {firefoxData.message}
            <br />
            {helpLinks.hasOwnProperty('browser') && (
              <a
                className="learn-more"
                href={helpLinks.browser.link}
                target="_blank"
              >
                {helpLinks.browser.text}
              </a>
            )}
          </p>
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    const { cameraSettings, computerSettings, internetSettings } = this.state;
    const { helpLinks } = this.props;
    return (
      <div>
        {this.renderSuccessMessage()}
        {this.renderUnifyDownloadOption()}
        {this.renderWarning()}
        <MetricsAccordion settings={cameraSettings} helpLinks={helpLinks} />
        <MetricsAccordion settings={computerSettings} helpLinks={helpLinks} />
        <MetricsAccordion settings={internetSettings} helpLinks={helpLinks} />
      </div>
    );
  }
}

SystemDetails.propTypes = {
  firefoxData: PropTypes.object.isRequired,
  computerSettings: PropTypes.object.isRequired,
  cameraSettings: PropTypes.object.isRequired,
  internetSettings: PropTypes.object.isRequired,
  helpLinks: PropTypes.object.isRequired,
  unifyDownloadOption: PropTypes.object.isRequired,
};

export default SystemDetails;
