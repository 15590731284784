import React from 'react';
import MediaAllSingleVideo from './MediaAllSingleVideo';
import PropTypes from 'prop-types';

const MediaAll = ({ allMedia, permission, images }) => {
  return (
    <div>
      {allMedia.map((mediaObj, mediaIndex) => {
        return (
          <div key={`allMedia_${mediaIndex}`}>
            <div>
              <span className="bold-text calculated-value-bold">
                Fulfillment{' '}
                {`${mediaIndex + 1} (${mediaObj.fulfillment_status}) ${
                  mediaObj.fulfillment_start
                }`}
              </span>
            </div>
            <ul className="choices__list">
              {mediaObj.videos.map((vidObj, vidIndex) => {
                return (
                  <MediaAllSingleVideo
                    key={`videos_${mediaIndex}_${vidIndex}`}
                    mainIndex={mediaIndex}
                    obj={vidObj}
                    objIndex={vidIndex}
                    type="video"
                    showTooltip={false}
                  />
                );
              })}
              {mediaObj.screenshots.map((screehshot_obj, screenshot_index) => {
                return (
                  <li
                    className="m-1"
                    key={`screenshots_${mediaIndex}_${screenshot_index}`}
                  >
                    <input
                      type="checkbox"
                      id={`allmedia_screenshots_${mediaIndex}_${screenshot_index}`}
                      className="mr-2"
                      data-mediatype="screenshots"
                      data-position={`${mediaIndex}_${screenshot_index}`}
                    />
                    <label
                      className="mediaObject"
                      htmlFor={`allmedia_screenshots_${mediaIndex}_${screenshot_index}`}
                    >
                      Screenshot {screenshot_index + 1}
                    </label>
                  </li>
                );
              })}
              {mediaObj.recordings.map((recObj, recIndex) => {
                return (
                  <MediaAllSingleVideo
                    key={`recordings_${mediaIndex}_${recIndex}`}
                    mainIndex={mediaIndex}
                    obj={recObj}
                    objIndex={recIndex}
                    type="recording"
                    showTooltip={true}
                  />
                );
              })}
               {mediaObj.combined_screens.map((combinedObj, combinedIndex) => {
                return (
                  <MediaAllSingleVideo
                    key={`combined_screens_${mediaIndex}_${combinedIndex}`}
                    mainIndex={mediaIndex}
                    obj={combinedObj}
                    objIndex={combinedIndex}
                    type="combined_screen"
                    showTooltip={true}
                  />
                );
              })}
            </ul>
          </div>
        );
      })}
      {images.length > 0 &&
        <div>
          <span className="bold-text calculated-value-bold">Images</span>
          <ul className="choices__list">
            {images.map((image, imageIndex) => {
              return (
                <li className="m-1" key={`images_${imageIndex}`}>
                  <input
                    type="checkbox"
                    id={`allmedia_images_${imageIndex}`}
                    className="mr-2"
                    data-mediatype="images"
                    data-position={imageIndex}
                  />
                  <label
                    className="mediaObject"
                    htmlFor={`allmedia_images_${imageIndex}`}
                  >
                    Image {imageIndex + 1}
                  </label>
                </li>
              );
            })}
          </ul>
        </div>
      }
    </div>
  );
};

MediaAll.propTypes = {
  allMedia: PropTypes.array.isRequired,
  permission: PropTypes.string.isRequired,
  images: PropTypes.array.isRequired,
};

export default MediaAll;
