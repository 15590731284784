// Geolocator
//
// This class contains methods for geolocating based on
// the Google Maps Geocoder.
class Geolocator {
  /**
   * initGeocoder - Initializes a google maps Geocoder if
   * this class's geocoder has not yet been initialized.
   */
  initGeocoder() {
    if (
      this.geocoder == null &&
      typeof google === 'object' &&
      typeof google.maps === 'object'
    ) {
      this.geocoder = new google.maps.Geocoder();
    }
  }

  /**
   * geolocateByAddress - Retrieves a latitude and longitude pair
   * based off of a given address.
   *
   * If the geocoder succeeds to retrieve a latitude and longitude from
   * the given address, a resolved Promise is returned containing an object of the form:
   * { latitude: {float}, longitude: {float} }. Otherwise, a rejected Promise is returned.
   *
   * @param  {String} address
   * @return {Promise} the result of the geolocation.
   */
  geolocateByAddress(address) {
    // initialize the geocoder if it hasn't been initialized yet
    this.initGeocoder();

    return new Promise((resolve, reject) => {
      // if we couldn't initialize the geocoder, fail out
      if (this.geocoder == null) {
        reject();
      }

      // geocode address and use the returned coordinates to fetch location data
      this.geocoder.geocode({ address: address }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results[0] != null) {
            resolve({
              latitude: results[0].geometry.location.lat(),
              longitude: results[0].geometry.location.lng()
            });
          } else {
            reject();
          }
        } else {
          reject();
        }
      });
    });
  }


  /**
   * geolocateByBrowserLocation - Retrieves a latitude and longitude pair
   * based off the browsers location.
   *
   * If the broweer succeeds at fetching the location, a resolved Promise is
   * returned containing an object of the form:
   * { latitude: {float}, longitude: {float} }. Otherwise, a rejected Promise is returned.
   * This rejected promise contains an error message corresponding to why
   * the browser failed to geolocate.
   *
   * @return {Promise} the result of the geolocation.
   */
  geolocateByBrowserLocation() {
    // initialize the geocoder if it hasn't been initialized yet
    this.initGeocoder();

    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        const pos = navigator.geolocation.getCurrentPosition(
          pos => {
            resolve({
              latitude: pos.coords.latitude,
              longitude: pos.coords.longitude
            });
          },
          err => {
            reject(this.getBrowserLocationErrorMessage(err));
          }
        );
      } else {
        reject(this.getBrowserLocationErrorMessage());
      }
    });
  }


  /**
   * getAddressFromLatLong - Retrieves an address from a given latitude and
   * longitude.
   *
   * If the geocoder success at fetching the address, a resolved Promise is
   * returned containing the formatted address string. Otherwise, a rejected Promise
   * is returned.
   *
   * @param  {Number} latitude  the latitude
   * @param  {Number} longitude the longitude
   * @return {Promise} the result of the geolocation.
   */
  getAddressFromLatLong(latitude, longitude) {
    this.initGeocoder();

    return new Promise((resolve, reject) => {
      const latLng = new google.maps.LatLng(latitude, longitude);
      this.geocoder.geocode({ latLng: latLng }, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          resolve(results[0].formatted_address);
        } else {
          reject();
        }
      });
    });
  }


  /**
   * getBrowserLocationErrorMessage - Retrieves an error messaged based off
   * the given browser location error.
   *
   * @param  {Object} error = null  the browser location error
   * @return {String}               the error message
   */
  getBrowserLocationErrorMessage(error = null) {
    let msg;
    if (error == null) {
      msg = 'Geolocation is not supported by your browser.';
    } else {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          msg = 'User denied the request for Geolocation.';
          break;
        case error.POSITION_UNAVAILABLE:
          msg = 'Your current location is unavailable.';
          break;
        case error.TIMEOUT:
          msg = 'The request to get your current location timed out.';
          break;
        case error.UNKNOWN_ERROR:
          msg = 'An unknown error occurred.';
          break;
      }
    }
    return msg;
  }


  /**
   * renderGoogleMapsEmbed - Renders a Google Maps Embed based on
   * an element, latitude, longitude, and address.
   *
   * @param  {Object} element   the HTML element to display the map in
   * @param  {Number} latitude  the latitude of the map's marker
   * @param  {Number} longitude the longitude of the map's marker
   * @param  {String} address   the address to take the user to when the marker
   *                            is clicked
   */
  renderGoogleMapsEmbed(element, latitude, longitude, address) {
    // fail out if we can't find google
    if (google == null) {
      return;
    }

    const pos = {
      lat: latitude,
      lng: longitude
    };
    const map = new google.maps.Map(element, {
      center: pos,
      zoom: 6
    });

    // if address is HTML formatted (includes <br> tags), take those out
    const cleanAddress = address.replace('<br/>', ', ');

    const marker = new google.maps.Marker({ position: pos, map: map });
    marker.addListener('click', () => {
      const url = `https://maps.google.com/maps?saddr=Current+Location&daddr=${cleanAddress}`;
      window.open(url, '_blank');
    });
  }
}

export default Geolocator;
