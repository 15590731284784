/**
 * Component for sending messages in chat.
 */
import autosize from 'autosize';

class ChatInput extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      chatInput: ''
    }

    // ref
    this.chatinput = null
  }

  componentDidMount() {
    autosize(this.chatinput);
  }
  /**
   * Sends inputted message with current date.
   */
  handleSubmit = (event) => {
    event.preventDefault();
    this.sendMessage();
  }

  /**
   * Changes state with input.
   */
  handleInputChange = (event) => {
    this.setState({ chatInput: event.target.value });
  }

  /**
   * Changes state with input.
   */
  generatePlaceholder = () => {
    const { chatEnabled, sessionIsRunning } = this.props;

    if (!sessionIsRunning) {
      return 'The session must be running to chat.';
    }
    else if(!chatEnabled) {
      return 'Please start watching the session to chat or call';
    }
    else {
      return 'Type and hit enter to send a message';
    }
  }

  handleKeyPress = (event) => {
    if (event.key == 'Enter' && !event.shiftKey){
      event.preventDefault();
      this.sendMessage();
    }
  }

  sendMessage = () => {
    if (this.state.chatInput.trim()) {
      this.props.sendHandler(this.state.chatInput);
      this.setState({ chatInput: '' });
    }
    this.chatinput.style.height = '32px';
  }

  /**
   * @see Component#render()
   */
  render() {
    const { chatEnabled, sessionIsRunning } = this.props;
    return (
      <form
        onSubmit={this.handleSubmit}
        className="p-3 text-chat-form-container"
        ref={c=>this.form=c}
      >
        <div className="text-chat-input-container">
          <textarea
            type="text"
            className="form-control"
            disabled={!(sessionIsRunning && chatEnabled)}
            placeholder={this.generatePlaceholder()}
            rows={1}
            value={this.state.chatInput}
            ref={c => this.chatinput = c}
            onChange={this.handleInputChange}
            onKeyPress={this.handleKeyPress}
          />
          <button
            className="btn btn-primary"
            type="submit"
            disabled={!(sessionIsRunning && chatEnabled)}
            style={{maxHeight: '100%'}}>
            <i
              className="fa fa-paper-plane"
              style={{display: 'inline'}}
              type="submit"
            ></i>
          </button>
        </div>
      </form>
    );
  }
}

export default ChatInput
