import Toast from '../utils/Toast';

class StudentsReservationSelector {
  constructor() {
    this.RESERVATION_SELECTOR = '[data-behavior=reservation-select]';
  }

  init() {
    this.bindEventListeners();
  }

  bindEventListeners() {
    const slots = document.querySelector('.slots')
    if(slots) {
      slots.addEventListener('click', (e) => {
        const targetEl = e.target;
        if (targetEl.dataset && targetEl.dataset.behavior) {
          const behavior = targetEl.dataset.behavior;

          switch (behavior) {
            case 'reservation-select':
              $(targetEl.parentElement)
                .children('form')
                .children('input[name="ptc_location"]')
                .val(sessionStorage.getItem('ptcLocation'));
              this.toggleReservationPreview(e);
              break;
            case 'reservation-cancel-selection':
              this.handleCancelBtnClick(e);
              break;
            default:
              break;
          }
        }
      });
    }
  }

  toggleReservationPreview(e) {
    e.preventDefault();
    const $this = $(e.target);
    const $slot = $this.closest('.slot');
    const $metaContent = $slot.find('.js-reservation-meta');
    const $confirmationContent = $slot.find('.js-reservation-confirmation');
    const $bookItBtn = $slot.find('.js-book-it');

    $('.js-reservation-confirmation').addClass('d-none');
    $('.js-reservation-meta').removeClass('d-none');
    $(this.RESERVATION_SELECTOR).removeClass('d-none');
    $('.js-book-it').addClass('d-none');
    $('.slot').removeClass('active');

    $this.addClass('d-none');
    $slot.addClass('active');
    $metaContent.addClass('d-none');
    $bookItBtn.removeClass('d-none');
    $confirmationContent.removeClass('d-none');

    const btnIdArray = e.target.id.split('_');
    const scheduleBtnId = 'schedule_btn_' + btnIdArray[btnIdArray.length-1];
    const scheduleBtn = document.querySelector("#" + scheduleBtnId);
    scheduleBtn.focus();
  }

  handleCancelBtnClick(e) {

    document.querySelectorAll('.js-reservation-confirmation').forEach( item => {
      item.classList.add('d-none');
    });
    document.querySelectorAll('.js-reservation-meta').forEach( item => {
      item.classList.remove('d-none');
    });
    document.querySelectorAll(this.RESERVATION_SELECTOR).forEach( item => {
      item.classList.remove('d-none');
    });

    document.querySelectorAll('.js-book-it').forEach( item => {
      item.classList.add('d-none');
    });

    document.querySelectorAll('.slot').forEach( item => {
      item.classList.remove('active');
    });

    const btnIdArray = e.target.id.split('_');
    const selectBtnId = 'select_btn_' + btnIdArray[btnIdArray.length-1];
    const selectBtn = document.querySelector("#" + selectBtnId);
    selectBtn.focus();
  }
}
export default StudentsReservationSelector;
