import PropTypes from 'prop-types'

const statuses = {
  disconnected: 'disconnected',
  connecting: 'connecting',
  connected: 'connected',
  finished: 'finished',
  error: 'error'
}

/**
 * The component shows student stream status.
 */
class StreamStatus extends React.Component {
  /**
   * Component's constructor
   */
  constructor(props) {
    super(props)

    this.state = {
      status: statuses.disconnected,
      showStatusText: false
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.status !== this.props.status) {
      this.setState({ status: this.props.status })
    }
  }

  onMouseEnter = () => {
    this.setState({ showStatusText: true })
  }

  onMouseLeave = () => {
    this.setState({ showStatusText: false })
  }

  getStatusText = (status) => {
    switch (status) {
      case statuses.disconnected:
        return "Not started"
      case statuses.connecting:
        return "Connecting"
      case statuses.connected:
        return "Connected"
      case statuses.finished:
        return "Finished"
      case statuses.error:
        return "Error"
    }
  }

  render() {
    const { status, showStatusText } = this.state

    return (
      <div className={`stream-status stream-status-${status}`} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
        {showStatusText && (<div className="stream-status-text">{this.getStatusText(status)}</div>)}
        <div className="stream-status-indicator"></div>
      </div>
    )
  }
}

StreamStatus.propTypes = {
  status: PropTypes.string
}

export default StreamStatus
