import {useEffect, useState} from 'react';
import ActionButtons from "../Shared/ActionButtons";

const InstructionsProcedure = (props) => {
	const procedure = props.procedure
	const sendEvent = props.sendEvent;
	const handleNext = props.handleNext;
	const openModal = props.openModal;
	const closeModal = props.closeModal;
	const markProcCompleted = props.markProcCompleted;
	const getFulfillmentEvents = props.getFulfillmentEvents

	const resolveEvent = 'Event::ResolutionStartedInstructions';

	useEffect(() => {
		getFulfillmentEvents(['Event::ProctorInstructionsCompleted'], (response) => {
			if(response.data.events.length > 0){
				markProcCompleted(procedure.id);
			} else {
				sendEvent('Event::ProctorInstructionsStarted', {});
			}
		})
	},[]);

	const completeProcedure = () => {
		sendEvent('Event::ProctorInstructionsCompleted', {});
		markProcCompleted(procedure.id);
		handleNext();
	};

	const resolveProcedure = () => {
		openModal();
		sendEvent(resolveEvent, {});
	}
	return(
		<>
			<ActionButtons
				resovleBtnText={'Resolve...'}
				confirmBtnText={'Complete'}
				confirmBtnAction={completeProcedure}
				resolveBtnAction={resolveProcedure}
			/>
		</>
	)
}

export default InstructionsProcedure;
