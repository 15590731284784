class DownloadModal {
  constructor() {
    this.downloadModal = document.querySelector('[data-toggle=download-modal]');
  }

  init() {
    if(this.downloadModal) {
      this.bindEventListeners();
    }
  }

  bindEventListeners() {
    this.downloadModal.addEventListener('click', () => { this.openModal() });
  }

  openModal() {
    const button = $(this.downloadModal)
    let target = $(button.data('target'));
    if (target) {
      target.modal({ keyboard: false, show: false, backdrop: 'static' });
      target.modal('show');
    }
    this.interval = setInterval(() => { this.checkDlTokenCookieAndPossiblyHideModal() }, 1000);
  }

  checkDlTokenCookieAndPossiblyHideModal() {
    let cookieValue = $.cookie('dltoken');
    if (cookieValue) { this.hideModalAndDeleteCookie(); }
  }

  hideModalAndDeleteCookie() {
    clearInterval(this.interval);
    $.removeCookie('dltoken', { path: '/' });
    $('#downloading-modal').modal('hide');
  }
}

export default DownloadModal;
