import checkUrl from './shared/checkUrl';
import getDateTime from './shared/getDateTime';
import getTimezone from './shared/getTimezone';

class TimezonePicker {
  constructor() {
    this.timezonePicker = document.querySelector('#timezone');
  }

  init() {
    if(this.timezonePicker) {
      this.timezonePicker.addEventListener('change', (event) => { this.handleClick(event) })
    }
  }

  handleClick(event) {
    event.preventDefault();
    let currentUrl = window.location.href;

    let dateIndex = currentUrl.indexOf('date=');
    if (dateIndex > -1) {
      let dateIndexEnd = currentUrl.indexOf('&', dateIndex);
      if (dateIndexEnd === -1) {
        dateIndexEnd = currentUrl.length;
      }
      currentUrl = currentUrl.substring(0, dateIndex) + currentUrl.substring(dateIndexEnd + 1, currentUrl.length);
    }
    let timezoneIndex = currentUrl.indexOf('timezone=');
    if (timezoneIndex > -1) {
      currentUrl = currentUrl.substring(0, timezoneIndex - 1);
    }
    currentUrl = (checkUrl(currentUrl)) + "date=" + (getDateTime().format('YYYY-MM-DDTHH:mm'));
    currentUrl = (checkUrl(currentUrl)) + "timezone=" + (getTimezone());
    window.history.replaceState( null, "", currentUrl );
  }
}

export default TimezonePicker
