import getRequestParams from '../utils/getRequestParams';

class UnlockAuthentication {
  constructor() {
    this.unlockAuthBtn = document.querySelector('[data-btn-selector="unlock-authentication"]');
    this.unlockExamBtn = document.querySelector('[data-btn-selector="unlock-exam"]');
    this.state = document.querySelector('[data-behavior="fulfillment-state"]');
    this.label = document.querySelector('[data-behavior="verified-label"]');
  }

  init() {
    if (this.unlockAuthBtn) {
      this.unlockAuthBtn.body = { uuid: this.state.getAttribute('data-fulfillment-uuid') };
      this.bindEventListeners();
    }
  }

  bindEventListeners() {
    this.unlockAuthBtn.addEventListener('click', this.sendRequest.bind(this));
  }

  sendRequest(event) {
    if (!confirm(event.target.getAttribute('data-confirmation'))) { return; }

    fetch(event.target.getAttribute('data-url'), getRequestParams(event.target.body, 'PUT'))
      .then(() => this.unlock(event))
      .catch(() => alert(event.target.getAttribute('data-error')));
  }

  unlock(event) {
    this.unlockAuthBtn.classList.add('d-none');
    this.unlockExamBtn.classList.remove('d-none');
    if (this.label) {
      this.label.classList.remove('d-none');
    }
    fetch('/api/unlock_authentications', getRequestParams(event.target.body))
      .catch(() => alert(event.target.getAttribute('data-error')));
  }
}

export default UnlockAuthentication;
