import { Alert } from "react-bootstrap"
import PropTypes from 'prop-types';

class AlertDialog extends React.Component {
  constructor(props) {
    super(props)

    //ref
    this.alertElem
  }

  componentDidMount() {
    const alertElemHeight = ReactDOM.findDOMNode(this.alertElem).offsetHeight
    window.scrollTo(0, window.scrollY + alertElemHeight)
  }

  componentWillUnmount() {
    const alertElemHeight = ReactDOM.findDOMNode(this.alertElem).offsetHeight
    window.scrollTo(0, window.scrollY - alertElemHeight)
  }

  render() {
    const {
      deleteCandidatesAmount,
      deleteIncidents,
      handleAlertDismiss,
      processing
    } = this.props
    return (
      <Alert
        bsStyle="warning"
        onDismiss={handleAlertDismiss}
        ref={node => (this.alertElem = node)}
        className="m-0"
      >
        {
          processing ?
            <div className="text-center p-3">
              <h4>
                Processing... Please wait
              </h4>
              <div>
                <span className="fa fa-spinner fa-fw fa-pulse fa-3x"></span>
              </div>
            </div>
            :
            <div className="text-center p-3">
              <h4>
                Are you sure you want to delete these events?
              </h4>
              <div className="buttons-container">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={deleteIncidents}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleAlertDismiss}
                >
                  No
                </button>
              </div>
            </div>
        }
      </Alert>
    )
  }
}

AlertDialog.propTypes = {
  deleteCandidatesAmount: PropTypes.number.isRequired,
  handleAlertDismiss: PropTypes.func.isRequired,
  deleteIncidents: PropTypes.func.isRequired,
  processing: PropTypes.bool.isRequired
};

export default AlertDialog
