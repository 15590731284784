import PropTypes from 'prop-types';

class EventModal extends React.Component {
  constructor(props) {
    super(props)
    const today = new Date()

    this.state = {
      type: "multiple_persons_identified",
      score: 10,
      minutes: today.getMinutes(),
      hours: today.getHours(),
      startTime: this.getCurrentTime(),
      endTime: this.getTimeInSecond(),
      date: this.getTodayDate(),
      dateInMonth: this.getDateInMonth()
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target
    this.setState({ [name]: value })
  }

  onSubmit = (event) => {
    event.preventDefault()
    const { minutes, hours, date, type, score, startTime, endTime, comment } = this.state
    const { transformStore, close } = this.props
    const created_at = new Date(`${date} ${hours}:${minutes}`).toISOString()
    const incident = { type, score, created_at, startTime, endTime, comment }

    transformStore({
      type: "createIncident",
      params: incident
    })
    
    close()
  }

  getTodayDate = () => {
    return moment().format("YYYY-MM-DD")
  }

  getDateInMonth = () => {
    return moment().add(1, "months").format("YYYY-MM-DD")
  }

  getCurrentTime = () => {
    return moment().format("HH:mm:ss")
  }

  getTimeInSecond() {
    return moment().add(1, "s").format("HH:mm:ss")
  }

  renderRemoteControlMessage = () => {
    if (this.state.type == "remote_control") {
      return (
        <div className="form-group row callout callout-info">
          <div className="col-sm-12">
            Please consider question & answering time when creating this type of event.
          </div>
        </div>
      )
    }
  }

  renderFormElements = () => {
    const { score, date, hours, minutes, type, dateInMonth, startTime, endTime, comment } = this.state
    const maxLength = 240
    const isValidLength = comment && comment.length >= maxLength
    const invalidLengthMessage = 'Character limit is exceeded. Please edit your comment, only 240 characters are allowed.'
    const style = {
      color: 'red',
      'font-size': '13px'
    }

    if(type === "suspicious_behavior") {
      return (
        <div>
          <div className="form-group row">
            <label
              htmlFor="date-input"
              className="col-sm-3 col-form-label"
            >
              Date
            </label>
            <div className="col-sm-9">
              <input
                type="date"
                value={date}
                min="2017-03-05"
                max={dateInMonth}
                className="form-control"
                id="date-input"
                name="date"
                onChange={this.handleChange}
                required
              />
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="date-input"
              className="col-sm-3 col-form-label"
            >
              Start time
            </label>
            <div className="col-sm-9">
              <input
                type="time"
                step="1"
                value={startTime}
                className="form-control"
                name="startTime"
                onChange={this.handleChange}
                required
              />
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="date-input"
              className="col-sm-3 col-form-label"
            >
              End time
            </label>
            <div className="col-sm-9">
              <input
                type="time"
                step="1"
                value={endTime}
                className="form-control"
                name="endTime"
                onChange={this.handleChange}
                required
              />
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="date-input"
              className="col-sm-3 col-form-label"
            >
              Comment
            </label>
            <div className="col-sm-9">
              <textarea
                type="text"
                className="col-sm-12"
                maxLength={maxLength}
                placeholder="Comment"
                value={this.state.comment}
                name="comment"
                onChange={this.handleChange}
              />
              {
                isValidLength &&
                <div className="invalid-length" style={style}>
                  {invalidLengthMessage}
                </div>
              }
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div className="form-group row">
            <label
              htmlFor="score-input"
              className="col-sm-3 col-form-label"
            >
              Score
            </label>
            <div className="col-sm-9">
              <input
                type="number"
                value={score}
                min="0"
                className="form-control"
                id="score-input"
                name="score"
                onChange={this.handleChange}
                required
              />
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="date-input"
              className="col-sm-3 col-form-label"
            >
              Time
            </label>
            <div className="col-sm-5">
              <input
                type="date"
                value={date}
                min="2017-03-05"
                max={dateInMonth}
                className="form-control"
                id="date-input"
                name="date"
                onChange={this.handleChange}
                required
              />
            </div>
            <div className="col-sm-2 hours">
              <input
                type="number"
                value={hours}
                min="0"
                max="23"
                className="form-control"
                name="hours"
                onChange={this.handleChange}
                required
              />
            </div>
            <div className="col-sm-2">
              <input
                type="number"
                value={minutes}
                min="0"
                max="59"
                className="form-control"
                name="minutes"
                onChange={this.handleChange}
                required
              />
            </div>
          </div>
        </div>
      )
    }
  }

  render() {
    const { type } = this.state
    const widthStyle = {
      minWidth: '600px'
    }

    return (
      <div
        className="modal fade"
        id="new-incident-modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
      >
        <div className="modal-dialog" style={widthStyle} role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title col-lg-11" id="myModalLabel">Add new event</h4>
              <button
                type="button"
                className="close"
                onClick={this.props.close}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form onSubmit={this.onSubmit}>
              <div className="modal-body">
                <div className="form-group row">
                  <label
                    htmlFor="event-type-input"
                    className="col-sm-3 col-form-label"
                  >
                    Event type
                  </label>
                  <div className="col-sm-9">
                    <select
                      className="form-control"
                      id="event-type-input"
                      name="type"
                      value={type}
                      onChange={this.handleChange}
                    >
                      <option value="multiple_persons_identified">
                        Multiple Persons Identified
                      </option>
                      <option value="no_one_in_the_frame">
                        Nobody In The Frame
                      </option>
                      <option value="motion_detected">
                        Motion Detected
                      </option>
                      <option value="light_level_changed">
                        Light Level Changed
                      </option>
                      <option value="audio_level_changed">
                        Audio Level Changed
                      </option>
                      <option value="profile_face_detected">
                        Profile Face Detected
                      </option>
                      <option value="multiple_desktops">
                        Multiple Desktops
                      </option>
                      <option value="suspicious_behavior">
                        Suspicious Behavior
                      </option>
                      <option value="outside_materials">
                        Outside Materials
                      </option>
                      <option value="remote_control">
                        Remote Control
                      </option>
                      <option value="low_audio_level">
                        Low Audio Level
                      </option>
                    </select>
                  </div>
                </div>
                {this.renderFormElements()}
                {this.renderRemoteControlMessage()}
              </div>
              <div className="modal-footer">
                <div className="form-group row">
                  <div className="col-sm-12">
                    <button type="submit" className="btn btn-primary">
                      Add Event
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

EventModal.propTypes = {
  close: PropTypes.func.isRequired,
  transformStore: PropTypes.func.isRequired
}

export default EventModal
