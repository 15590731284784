class MaxNoShows {
  constructor() {
    this.checkbox = document.querySelector(
      '[data-behavior="max-no-shows-checkbox"]'
    );
    this.numNoShows = document.querySelector(
      '[data-behavior="max-no-shows-value"]'
    );

    this.resetNoShowsValue = document.querySelector(
      '[data-behavior="reset-max-no-shows-value"]'
    );

    this.alertTextValue = document.querySelector(
      '[data-behavior="alert-text-value"]'
    );

    this.toggleMaxNoShows = this.toggleMaxNoShows.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  init() {
    this.toggleMaxNoShows();
    this.bindEventListeners();
  }

  bindEventListeners() {
    this.checkbox.addEventListener('change', this.toggleMaxNoShows);
    const form = this.checkbox.closest('form');
    if (form) {
      form.addEventListener('submit', this.handleSubmit);
    }
  }

  toggleMaxNoShows() {
    if (this.checkbox.checked) {
      this.toggleGroup('block');
      this.toggleCopy('Disable Max No Shows');
      this.numNoShows.setAttribute('min', '1');
      this.resetNoShowsValue.setAttribute('min', '1');
    } else {
      this.toggleGroup('none');
      this.toggleCopy('Enable Max No Shows');
      this.numNoShows.removeAttribute('min');
      this.resetNoShowsValue.removeAttribute('min');
      this.resetValues();
    }
  }

  handleSubmit(event) {
    if (this.checkbox.checked && this.isAlertTextEmpty()) {
      alert("Alert Text is required.");
      event.preventDefault();
      location.reload();
    }
  }

  isAlertTextEmpty() {
    return this.alertTextValue.value.trim() === "";
  }

  toggleGroup(style) {
    const selector = document.querySelector(
      '[data-behavior="max-no-shows-group"]'
    );
    selector.style.display = style;
  }

  toggleCopy(text) {
    const selector = document.querySelector(
      '[data-behavior=max-no-shows-text]'
    );
    selector.innerHTML = text;
  }

  resetValues() {
    [
      ...document.querySelectorAll(
        '[data-behavior="max-no-shows-value"], [data-behavior="reset-max-no-shows-value"], [data-behavior="alert-text-value"]'
      )
    ].forEach(selector => {
      if (selector.getAttribute('data-behavior') === 'alert-text-value') {
        selector.value = '';
      } else {
        selector.value = '1';
      }
    });
  }
}

export default MaxNoShows;
