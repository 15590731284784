import csrfToken from '../utils/csrf';
import ShowRetakeForm from './shared_actions/ShowRetakeForm';
import SubmitRetakeForm from './shared_actions/SubmitRetakeForm';
import Toast from '../utils/Toast';
import axios from 'axios';

class PictureConfirmation {
  constructor() {
    this.setDefaultProfilePictureLink = document.querySelector('.js-picture-confirmation-set-as-default');
    this.retakePictureConfirmationLink = document.querySelector('.js-picture-confirmation-retake');
    this.retakePictureConfirmationFile = document.querySelector('.js-picture-confirmation-retake-file');
    this.form = document.querySelector('.js-picture-confirmation-retake-form')

  }

  init() {
    this.bindEventListeners();
  }

  bindEventListeners() {
    if (this.setDefaultProfilePictureLink) {
      this.setDefaultProfilePictureLink.addEventListener('click', (event) => {
        event.preventDefault()
        this.setDefaultProfilePicture(event);
      });
    }

    if(this.retakePictureConfirmationLink) {
      this.retakePictureConfirmationLink.addEventListener('click', (event) => {
        event.preventDefault()
        new ShowRetakeForm(event).init();
      });
    }

    if(this.retakePictureConfirmationFile) {
      this.retakePictureConfirmationFile.addEventListener('change', (event) => {
        event.preventDefault()
        new SubmitRetakeForm(event, this.form).init();
      });
    }
  }

  setDefaultProfilePicture(event) {
    event.preventDefault();
    const data = {
      event_id: this.setDefaultProfilePictureLink.dataset.event_id
    };
    const headers = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken()
      }
    }
    axios.post(this.setDefaultProfilePictureLink.dataset.url, JSON.stringify(data), headers)
    .then(response => {
      new Toast().success({message: 'Default picture has been set.'});
      document.querySelector('.js-detect').setAttribute('src', response.data.url);
    })
    .catch(error => {
      new Toast().danger({message: 'Unable to set default picture'});
    });
  }
}

export default PictureConfirmation;
