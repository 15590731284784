import React from 'react';
import { ExamConfigurationInfo } from './ExamConfigurationInfo';

const ExamConfigurationInfoContainer = ({
  errorMsg,
  examInUse,
  examUrl,
  handleUnConfiguredExamClick,
}) => {
  return (
    <ExamConfigurationInfo
      errorMsg={errorMsg}
      examInUse={examInUse}
      examUrl={examUrl}
      handleUnConfiguredExamClick={handleUnConfiguredExamClick}
    />
  );
};

export { ExamConfigurationInfoContainer };
