class PtcOption {
  constructor() {
    this.checkbox = document.querySelector('[data-behavior="ptc-checkbox"]');
    this.optionGroup = document.querySelector('[data-behavior="ptc-option-group"]');
    this.packageIdTextField = document.querySelector('[data-behavior="external-id-value"]');
    this.togglePtcOption = this.togglePtcOption.bind(this);
  }

  get enabled() {
    return this.checkbox;
  }

  init() {
    if (this.enabled) {
      this.togglePtcOption();
      this.bindEventListeners();
    }
  }

  bindEventListeners() {
    this.checkbox.addEventListener('change', this.togglePtcOption);
  }

  togglePtcOption() {
    const inPerson = this.checkbox.checked;
    this.toggleDisplay(this.optionGroup, inPerson ? 'block' : 'none');
    if (!inPerson) {
      this.resetValues(this.packageIdTextField);
    }
  }

  toggleDisplay(domNode, value) {
    domNode.style.display = value;
  }

  resetValues(domNode) {
    if (domNode != null) domNode.value = '';
  }
}

export default PtcOption;
