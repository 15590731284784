import axios from 'axios';
import csrfToken from '../src/utils/csrf';
import FulfillmentVideoSubscription from '../src/channels/subscriptions/FulfillmentVideoSubscription';

class FulfillmentVideoHelper {
  constructor(fulfillmentUuid, userId, isWatcher, videoController) {
    this.fulfillmentUuid = fulfillmentUuid;
    this.userId = userId;
    this.isWatcher = isWatcher;
    this.videoController = videoController;

    this.fulVidConnection = new FulfillmentVideoSubscription(
      fulfillmentUuid,
      userId,
    );
  }

  initializeWebSocket() {
    this.fulVidConnection.init();

    if (this.fulVidConnection.fulfillmentWindow !== null) {
      this.fulVidConnection.fulfillmentWindow.received = (res) => {
        const results = typeof res === 'string' ? JSON.parse(res) : res;
        this.handleMessages(results);
      };
    }
  }

  constructWsMessage(sender) {
    return {
      sender: sender,
      messageType: 'pause_videos',
      data: {},
    };
  }

  async broadcastMessage(message) {
    await axios
      .post(
        `${window.location.origin}/api/fulfillments/video_player/${this.fulfillmentUuid}/${this.userId}/broadcasting`,
        { msg: JSON.stringify(message) },
        {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken(),
        },
      )
      .then((response) => {
        console.log('Message sent:', response);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handlePauseVideos(source) {
    const pause_fulfillment_window = [
      'archimedes_watcher_window',
      'archimedes_fulfillment_page_ww_btn',
    ];

    if (
      (this.isWatcher && source === 'archimedes_fulfillment_page') ||
      (!this.isWatcher &&
        pause_fulfillment_window.includes(source) &&
        this.videoController != null)
    ) {
      let play = false;
      this.videoController.playPauseVideo(play);
      this.videoController.videoPlayerStore.setData({ play });
    }

    if (!this.isWatcher && source === 'archimedes_watcher_window') {
      this.showPauseModel(true);
    }
  }

  handleMessages(data) {
    let messageType = data.messageType;
    let sender = data.sender;
    console.log('Got the message: ', data);
    switch (messageType) {
      case 'pause_videos':
        this.handlePauseVideos(sender);
        break;
    }
  }

  showPauseModel(showHide) {
    let modalElement = document.querySelector('.pause-video-modal');

    if (showHide) {
      modalElement.style.display = 'block';
    } else {
      modalElement.style.display = 'none';
    }
  }
}

export default FulfillmentVideoHelper;
