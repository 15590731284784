import { useEffect, useState } from 'react';

import PrechecksBodyText from './CardSections/PrechecksBodyText';
import PrechecksHeaderGroup from './CardSections/PrechecksHeaderGroup';

/**
 * @typedef {Object} SecondCameraLaunchCardProps
 * @property {string} [id]
 * @property {string} [icon]
 * @property {string} [iconPrefix]
 * @property {string} [title]
 * @property {PrecheckData} precheckData
 * @property {SendEventFunction} sendEvent
 */

const QRCodeSection = ({ showQrCode, launchUrl, codeExpiry }) => (
  <div className="row mt-4 mb-4">
    <div className="text-center col-md-12">
      {showQrCode ? (
        <img
          src={`/internal/qr_code?url=${encodeURIComponent(launchUrl)}`}
          alt="QR Code"
          className="img-fluid mx-auto col-md-2 offset-md-5"
        />
      ) : (
        <>
          {polyglot.t('prechecks_second_camera_link_instructions')}
          <br />
          <div className="precheck-primary mt-2">{launchUrl}</div>
        </>
      )}
      <div className="precheck-primary mt-2">
        <p
          dangerouslySetInnerHTML={{
            __html: polyglot.t(
              'prechecks_second_camera_launch_code_expiry_text',
              { expiry_at: codeExpiry },
            ),
          }}
        />
      </div>
    </div>
  </div>
);

/**
 * @param {SecondCameraLaunchCardProps} props
 * @returns {React.ReactElement}
 */
const SecondCameraLaunchCard = ({
  id,
  icon = 'fa-camera-web',
  iconPrefix = 'fal',
  title = polyglot.t('prechecks_second_camera_launch_title'),
  precheckData,
  sendEvent,
}) => {
  const [showQrCode, setShowQrCode] = useState(true);
  const image = precheckData.images.scanQrCodeImg;
  const launchUrl = `${window.origin}/${precheckData.secondCameraLaunchUrl}`;
  const codeExpiry = precheckData.secondCameraUrlExpiryInHr * 60;

  /** @type {React.MouseEventHandler<HTMLAnchorElement>} */
  const handleQrCodeToggle = (event) => {
    event.preventDefault();
    setShowQrCode(!showQrCode);
  };

  useEffect(() => {
    sendEvent('Event::SecondCameraLaunchLoaded');
  }, []);

  return (
    <div className="container-fluid text-center precheck-card-content" id={id}>
      <div className="row">
        <span className="ml-auto">
          <div className="spinner-border spinner-border-sm mr-2" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          {polyglot.t('prechecks_second_camera_connecting')}
        </span>
      </div>
      <PrechecksHeaderGroup
        title={title}
        image={image}
        icon={icon}
        iconPrefix={iconPrefix}
      />
      <QRCodeSection
        showQrCode={showQrCode}
        launchUrl={launchUrl}
        codeExpiry={codeExpiry}
      />
      <div className="row text-left">
        <PrechecksBodyText
          bodyText={polyglot.t('prechecks_second_camera_launch_instructions')}
        />
        <div className="row col-md-12 precheck-card-body">
          <p
            className="fs-6"
            dangerouslySetInnerHTML={{
              __html: polyglot.t(
                'prechecks_second_camera_launch_camera_mic_instructions',
              ),
            }}
          />
        </div>
        <div>
          <a href="#" className="precheck-a" onClick={handleQrCodeToggle}>
            {showQrCode
              ? polyglot.t('prechecks_second_camera_launch_qr_trouble')
              : polyglot.t('prechecks_second_camera_launch_link_trouble')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default SecondCameraLaunchCard;
