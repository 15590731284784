import axios from 'axios';
import { snakeCase, mapKeys } from 'lodash';
import Toast from '../../src/utils/Toast';
class ProctorActionsButton {
  constructor() {
    this.proctorActionsModal = document.getElementById('proctor-actions-modal');
    this.closeProctorActionsBtn = document.getElementById('close-proctor-actions-btn');
    this.proctorActionsBtn = document.getElementById("proctor-actions-btn");
    this.examineKioskModeToggle = document.querySelector('#examine-kiosk-mode-toggle')
    this.fulfillmentUuid = document.querySelector('#fulfillment-uuid').value
    this.userId = document.querySelector('#user-id').value
    this.enabledIcon = document.querySelector('.enabled-icon');
    this.disabledIcon = document.querySelector('.disabled-icon');
    this.examineKioskModeLabel = document.querySelector('label[for="examine-kiosk-mode-toggle"]')
    this.examineKioskModeState = localStorage.getItem('examineKioskModeState') === 'true' || false;
    if(this.examineKioskModeToggle) {
      this.examineKioskModeToggle.checked = this.examineKioskModeState;
    }
  }

  init() {
    this.bindEventListeners();
    this.updateKioskUi();
  }

  bindEventListeners() {
    if (this.proctorActionsBtn) this.proctorActionsBtn.addEventListener('click', (event) => this.showModal())

    if (this.proctorActionsModal && this.closeProctorActionsBtn) {
      this.closeProctorActionsBtn.addEventListener('click', (event) => this.hideModal(event));
    }

    if (this.examineKioskModeToggle) {
      this.examineKioskModeToggle.addEventListener('change', (event) => this.toggleExamineKioskMode(event))
    }
  }

  csrfToken() {
    const csrfTokenEl = document.querySelector('meta[name="csrf-token"]');
    return csrfTokenEl == null ? '' : csrfTokenEl.content;
  }

  sendEvent(eventType, fulfillmentUuid, payload={}, responseFunction= undefined, errorFunction= undefined) {
    const eventPayload = {
      uuid: fulfillmentUuid, 
      created_by_id: this.userId,
      event_type: eventType
    };

    // this would be an axios call or possible action cable sequence to send the events
    console.log(`Sending ${eventType} event:`, eventPayload);
    axios.post(
      `${window.location.origin}/api/secure_browser/events/activity_tabs`,
      mapKeys(eventPayload, (value, key) => snakeCase(key)),
      {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken()
      }
    )
      .then( response => {
        console.log(response);
        if ( responseFunction !== undefined ) {
          responseFunction(response);
        }
      })
      .catch( error => {
        console.log(error);
        if ( errorFunction !== undefined ) {
          errorFunction(error);
        }
      });
  };

  showModal = () => {
    if (this.proctorActionsModal) {
      this.proctorActionsModal.style.display = 'block';
    }
  }
  hideModal = () => {
    if (this.proctorActionsModal) {
      this.proctorActionsModal.style.display = 'none';
    }
  }

  updateKioskUi = () => {
    if (this.examineKioskModeToggle && this.examineKioskModeToggle.checked) {
      this.examineKioskModeLabel.style.color = 'gray'
      this.enabledIcon.style.display = 'inline';
      this.disabledIcon.style.display = 'none';
    } else {
      this.examineKioskModeLabel.style.color = 'red'
      this.enabledIcon.style.display = 'none';
      this.disabledIcon.style.display = 'inline';
    }
  }

  toggleExamineKioskMode = (event) => {
    const newState = event.target.checked;
    localStorage.setItem('examineKioskModeState', newState);

    if (newState) {
      this.sendEvent('kiosk_mode_on', this.fulfillmentUuid)
      this.examineKioskModeLabel.style.color = 'gray'
      this.enabledIcon.style.display = 'inline';
      this.disabledIcon.style.display = 'none';
    } else {
      this.sendEvent('kiosk_mode_off', this.fulfillmentUuid)
      this.examineKioskModeLabel.style.color = 'red'
      this.enabledIcon.style.display = 'none';
      this.disabledIcon.style.display = 'inline';
      new Toast().danger({ message: "Examine kiosk disabled" })
    }
  }
}

export default ProctorActionsButton;
