import { Accordion, Card, NavItem, Nav, Button, Collapse } from "react-bootstrap"
import { uniqueId } from "lodash"
import classNames from "classnames/bind"
import PropTypes from 'prop-types';
import IncidentComponent from './IncidentComponent';
import AlertDialog from './AlertDialog';
import EventModal from './EventModal';

import GMap from './GMap';

const tabsNames = {
  GENERAL: "Exam general",
  PRE_CHECKS: "Prechecks",
  BROWSER: "Browser",
  SCORES: "Scores",
  SECURITY: "Security"
}

/**
 * The component creates incidents dashboard.
 */
class IncidentsDashboard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tabActive: tabsNames.GENERAL,
      showModal: false,
      showAlert: false,
      expanded: true,
      onFulfillmentsPage: this.checkUrl()
    }
  }

  checkUrl = () => {
    return window.location.pathname.indexOf("fulfillments") > 0
  }

  handleTabSelect = (name) => {
    this.setState({
      tabActive: name
    })
  }

  isActive = (selectedTab) => {
    return selectedTab === this.state.tabActive ? "active" : " "
  }

  openModal = (event) => {
    event.stopPropagation()
    this.setState({ showModal: true }, () => $("#new-incident-modal").modal())
    event.preventDefault()
  }

  closeModal = (event) => {
    $("#new-incident-modal").modal("hide")
    this.setState({ showModal: false })
  }

  handleAlertDismiss = () => {
    const { transformStore } = this.props
    transformStore({
      type: "emptyDeleteCandidates"
    })
  }

  deleteIncidents = () => {
    const { uiMessageId, transformStore } = this.props
    transformStore({
      type: "deleteIncidents",
      params: uiMessageId
    })
  }

  cleanDisconnectionTimeline = (incidents) => {
    let checkForLossAndReestablish = false
    incidents.forEach((incident, index) => {
      if( incident.body.indexOf("Stream connection was lost") > -1 ||
        incident.body.indexOf("Exam was disconnected") > -1) {
        checkForLossAndReestablish = true
      }

      if ((incident.body.indexOf("Exam disconnection resolved") > -1 ||
        incident.body.indexOf("Stream connection re-established") > -1) &&
        checkForLossAndReestablish){
        incident.markAsDeleted = true
      }

      if ((incident.body.indexOf("Test-taker was disconnected") > -1 ||
        incident.body.indexOf("Stream connection loss") > -1) &&
        checkForLossAndReestablish) {
        incident.markAsDeleted = true
        checkForLossAndReestablish = false
      }
    })

    return incidents
  }

  getCards = () => {
    let cards = []
    const { GENERAL, SCORES } = tabsNames
    const { incidentsByType, transformStore, callbacks, deleteCandidates, timezone, showEventFeed } = this.props
    incidentsByType.forEach(category => {
      let incidentComponents = []
      const {
        name,
        incidents,
        totalScores,
        header,
        group = GENERAL,
        highlight,
        anyEventsMissingFeedStatus
      } = category
      const icon = incidents[0].icon
      const iconColor = incidents[0].iconColor
      const showScores = group === SCORES
      if (highlight) {
        category.highlight = false
      }
      let incidents_for_timeline = incidents
      if (name === "Disconnections" || name === "SessionInterrupted") {
        // We want to consolidate the connect/disconnect events on the timeline
        incidents_for_timeline = this.cleanDisconnectionTimeline(incidents)
      }
      incidents_for_timeline.forEach(incident => {
        const isDeleteCandidate = deleteCandidates.indexOf(incident.uiMessageId) > -1

        !incident.markAsDeleted  &&
          incidentComponents.push(
            <IncidentComponent
              callbacks={callbacks}
              name={name}
              transformStore={transformStore}
              incident={incident}
              key={uniqueId()}
              group={group}
              isDeleteCandidate={isDeleteCandidate}
              timezone={timezone}
              showEventFeed={showEventFeed}
              institutionUser={this.props.institutionUser}
            />
          )
      })
      incidentComponents.length &&
        this.state.tabActive === group &&
        cards.push(
          <Card
            key={uniqueId()}
            className="rounded-0 border-left-0 border-right-0"
            id={name}
          >
            <Card.Header className="p-0 bg-white">
              <Accordion.Toggle as={Button} variant="link" eventKey={name}
                className="btn-block text-left p-3 text-decoration-none"
              >
                {
                  this.renderCategory(
                  icon,
                  header,
                  incidentComponents.length,
                  totalScores,
                  showScores,
                  iconColor,
                  highlight,
                  showEventFeed,
                  anyEventsMissingFeedStatus
                )}
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={name}>
              <div className="list-group list-group-flush bg-light border-top">
                {incidentComponents}
              </div>
            </Accordion.Collapse>
          </Card>
        )
    })

    const mapCard = this.getCardWithMap()
    mapCard && cards.push(mapCard)

    return cards
  }

  getCardWithMap = () => {
    if (this.state.tabActive === tabsNames.BROWSER) {
      const name = "Location"
      const icon = "map-marker"
      const isExamRunning = this.props.examStartedAt
      const isBrowserTab = this.state.tabActive === 'Browser'
      const isRenderPlaceholder = !isExamRunning && isBrowserTab
      const locationCount = isRenderPlaceholder ? 0 : 1
      return (
        <Card
          key={uniqueId()}
          className="rounded-0 border-left-0 border-right-0"
          id={name}
        >
          <Card.Header className="p-0 bg-white">
            <Accordion.Toggle as={Button} variant="link" eventKey={name}
              className="btn-block text-left p-3 text-decoration-none"
            >
              { this.renderCategory(icon, name, locationCount) }
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey={name}>
            <div className="list-group list-group-flush bg-light border-top">
              { isExamRunning && <GMap gMap={this.props.gMap} /> }
              {isRenderPlaceholder && (this.locationPlaceholder())}
            </div>
          </Accordion.Collapse>
        </Card>
      )
    }
    return null
  }

  renderCategory = (
    icon,
    header,
    numIncidents,
    totalScores,
    showScores,
    iconColor,
    highlight,
    showEventFeed,
    anyEventsMissingFeedStatus,
    ) => {
    return (
      <div className={classNames("row", highlight ? "warning-fade" : null)}>
        <div className="col-1 p-0">
          <div className={`text-${iconColor} h-100 text-center d-flex align-items-center justify-content-center`}>
            <i className={`far fa-lg fa-${icon}`}></i>
          </div>
        </div>
        <div className="col-11">
          <div className="media-heading mb-0">
              <span className="badge badge-secondary d-inline mr-2">{ numIncidents }</span>
              {header}

            { showScores &&
              <div className="ml-1 scores badge badge-secondary d-inline">
                {totalScores}
                { header == "Light level changed" ? null : " | 100" }
              </div>
            }
            <div className="float-right">
              { showEventFeed && anyEventsMissingFeedStatus &&
                  <i className="fa fa-exclamation-triangle mr-3"
                     data-placement="top"
                     data-toggle="tooltip"
                     data-tooltip-behavior="true"
                     title= "An event needs to be reviewed for the machine learning algorithm."></i>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

  locationPlaceholder = () => {
    return (
     <div className="row">
        <div className="col-sm-12 text-center p-4 fs-5">
          <i className="far fa-exclamation-triangle"></i>
          <p className="fs-5">
            Oops! Location is not available.
            <br/>
            The session is probably not running.
          </p>
        </div>
      </div>
    )
  }

  getTitle = () => {
    return this.props.previousFulfillment ? `Previous Appointment on ${this.props.scheduledStart}` : 'Activity'
  }

  renderButton = () => {
    if(!this.props.showTopRightButton) { return }
    if (!this.props.previousFulfillment) {
      return (
      <Button
        className={
          "btn-secondary btn-sm"
        }
        onClick={this.openModal}
      >
        Add New Event
      </Button>
    )}
    else {
      return (
        <a
          className={
            "btn-secondary btn-sm btn"
          }
          href={`/fulfillments/${this.props.fulfillmentId}`}
          onClick={(e) => e.stopPropagation()}
        >
          View
        </a>)
    }
  }

  render() {
    const { transformStore, deleteCandidates, processing, showSecurityTab } = this.props
    const { GENERAL, PRE_CHECKS, BROWSER, SCORES, SECURITY } = tabsNames

    return (
      <div className="card mb-4">
        <div
          className="card-header collapsible cursor-pointer"
          onClick={() => {
            this.setState({ expanded: !this.state.expanded })
          }}
        >
          <span className="mr-2">
            { this.state.expanded
              ? <i className="fa fa-minus" />
              : <i className="fa fa-plus" />
            }
          </span>
          {this.getTitle()}
          <div className="float-right">
            {this.renderButton()}
          </div>
        </div>
        <Collapse in={this.state.expanded}>
          <div>
            <div className="row">
              <div className="col-md-12">
                <Nav
                  justify
                  variant="pills"
                  defaultActiveKey={GENERAL}
                  onSelect={this.handleTabSelect}
                  className="p-1"
                >
                  <Nav.Link eventKey={GENERAL} className={this.isActive(GENERAL)}>
                    General
                  </Nav.Link>
                  <Nav.Link
                    eventKey={PRE_CHECKS}
                    className={this.isActive(PRE_CHECKS)}
                  >
                    Prechecks
                  </Nav.Link>
                  <Nav.Link eventKey={BROWSER} className={this.isActive(BROWSER)}>
                    Browser
                  </Nav.Link>
                  <Nav.Link eventKey={SCORES} className={this.isActive(SCORES)}>
                    Scores
                  </Nav.Link>
                  { showSecurityTab &&
                    <Nav.Link eventKey={SECURITY} className={this.isActive(SECURITY)}>
                      Security
                    </Nav.Link>
                  }
                </Nav>
              </div>
            </div>
            <div>
              {deleteCandidates.length > 0 &&
                <AlertDialog
                  deleteCandidatesAmount={deleteCandidates.length}
                  handleAlertDismiss={this.handleAlertDismiss}
                  deleteIncidents={this.deleteIncidents}
                  processing={processing}
                />
              }
              <Accordion>
                {this.getCards()}
              </Accordion>
            </div>
          </div>
        </Collapse>
        {this.state.showModal && (
          <EventModal
            transformStore={transformStore}
            close={this.closeModal}
          />
        )}
      </div>
    )
  }
}

IncidentsDashboard.propTypes = {
  incidentsByType: PropTypes.array.isRequired,
  transformStore: PropTypes.func.isRequired,
  gMap: PropTypes.object.isRequired,
  callbacks: PropTypes.object.isRequired,
  deleteCandidates: PropTypes.array.isRequired,
  examStartedAt: PropTypes.string,
  processing: PropTypes.bool.isRequired,
  previousFulfillment: PropTypes.bool,
  timezone: PropTypes.string.isRequired,
  showEventFeed: PropTypes.bool.isRequired
}

export default IncidentsDashboard
