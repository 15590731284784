import getRequestParams from '../utils/getRequestParams';
import Toast from '../utils/Toast';
class RetryButton {
  constructor() {
    this.retryBtn = document.querySelector('[data-btn-selector="retry-session"]');
  }

  init() {
    if (this.retryBtn) {
      this.bindEventListeners();
    }
  }

  bindEventListeners() {
    this.retryBtn.addEventListener('click', this.sendRequest.bind(this));
  }

  sendRequest(event) {
    if (!confirm(event.target.getAttribute('data-confirmation'))) { return; }
    this.retryBtn.classList.add('d-none')
    fetch(event.target.getAttribute('data-url'), getRequestParams({}, 'PUT'))
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          this.retryBtn.classList.add('d-none');
          new Toast().success({message: data.message})
        } else {
          new Toast().danger({message: data.message})
          this.retryBtn.classList.remove('d-none');
        }
      })
      .catch((error) => {
        new Toast().danger({message: error})
        this.retryBtn.classList.remove('d-none');
      });
  }
}

export default RetryButton;
