import React from 'react';

import { ExamPageHeader } from './Components/ExamPageHeader';
import { ActionLinks } from './Components/ActionLinks';
import { ScheduledExams } from './Components/ScheduledExams';
import { UnscheduledExams } from './Components/UnscheduledExams';
import { ExamHistory } from './Components/ExamHistory';

/**
 * @typedef MySessionsProps
 * @property {string} guardianExtensionUrl
 * @property {GuardianPlatform} guardianBrowserPlatform
 * @property {GuardianDownloadUrls} guardianBrowserDownloadUrls
 * @property {boolean} isValidUserAgent
 * @property {boolean} isUCardNeeded
 * @property {string} advanceCancellationReasonId
 * @property {User} currentUser
 * @property {boolean} shouldOpenExamFulfilledModal
 * @property {boolean} shouldOpenBrowserDownloadModal
 * @property {string} testItOutUrl
 * @property {string} faqUrl
 * @property {boolean} isUcardCreateable
 * @property {boolean} isSchedulingAllowed
 * @property {boolean} isGuardianExtensionEnabledForAny
 * @property {boolean} isESPrechecksEnabledForAny
 * @property {boolean} isLoggedFromLms
 * @property {string} timestamp
 * @property {Reservation} closestReservation
 * @property {Reservation[]} reservations
 * @property {SessionHistory[]} sessionHistory
 * @property {string} currentUserData
 */

/**
 * @param {MySessionsProps} props
 * @returns {React.ReactElement}
 */
export default function MySessions(props) {
  return (
    <div className="my-sessions-v2">
      <ExamPageHeader />
      <ActionLinks {...props} />
      <ScheduledExams />
      <UnscheduledExams />
      <ExamHistory />
    </div>
  );
}
