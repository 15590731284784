import { useEffect } from 'react';
import PrechecksHeaderGroup from './CardSections/PrechecksHeaderGroup';
import PrechecksBodyText from './CardSections/PrechecksBodyText';
import PrechecksFooterButton from './CardSections/PrechecksFooterButton';

const GuidedLaunchBeginScanCard = ({
  id,
  icon,
  iconPrefix,
  title,
  paragraph,
  buttonText,
  handleNext,
  sendEvent,
  precheckData,
  handleLastPage,
}) => {
  const phoneScanningImg = precheckData.images.phoneScanningImg;

  useEffect(() => {
    sendEvent('Event::BeginScanLoaded');
  }, []);

  const handleBtnClick = () => {
    sendEvent('Event::BeginScanCompleted');
    handleNext();
  };

  const criteriaList = () => {
    return (
      <ul>
        <li>{polyglot.t('prechecks_guidedlaunch_begin_scan_criteria1')}</li>
        <li>{polyglot.t('prechecks_guidedlaunch_begin_scan_criteria2')}</li>
        <li>{polyglot.t('prechecks_guidedlaunch_begin_scan_criteria3')}</li>
        <li>{polyglot.t('prechecks_guidedlaunch_begin_scan_criteria4')}</li>
      </ul>
    );
  };

  const imageMain = () => {
    return (
      <div
        className="precheck-bg-light m-1 position-relative"
        style={{
          width: 200,
          height: 150,
          border: '1px solid #327C7A',
          borderStyle: 'dashed',
        }}
      >
        <button
          type="button"
          className="position-absolute fixed-top mt-5 btn border-0"
          aria-label={polyglot.t(
            'prechecks_guidedlaunch_review_scan_add_button',
          )}
          onClick={handleBtnClick}
        >
          <i
            className="fal fa-plus fa-2x precheck-primary"
            aria-hidden="true"
          />
        </button>
      </div>
    );
  };

  const imageHelperDiv = () => {
    const imageHelperArr = [];
    for (let i = 0; i <= 4; i++) {
      imageHelperArr.push(
        <div
          key={i}
          className="precheck-bg-light m-1"
          style={{ width: 200, height: 150 }}
        ></div>,
      );
    }
    return imageHelperArr.map((pictureHolder) => pictureHolder);
  };

  return (
    <div className="container-fluid text-center precheck-card-content" id={id}>
      <PrechecksHeaderGroup
        title={title}
        image={phoneScanningImg}
        icon={icon}
        iconPrefix={iconPrefix}
      />
      <div className="text-left">
        <PrechecksBodyText bodyText={paragraph} />
        {criteriaList()}
      </div>
      <div className="d-flex justify-content-center flex-wrap my-4">
        {imageMain()}
        {imageHelperDiv()}
      </div>
      <div className="text-center">
        <a href="#" className="precheck-a" onClick={handleLastPage}>
          {polyglot.t('prechecks_guidedlaunch_begin_scan_opt_out')}
        </a>
      </div>
      <PrechecksFooterButton
        buttonText={buttonText}
        handleClick={handleBtnClick}
      />
    </div>
  );
};

GuidedLaunchBeginScanCard.defaultProps = {
  title: polyglot.t('prechecks_guidedlaunch_begin_scan_title'),
  paragraph: polyglot.t('prechecks_guidedlaunch_begin_scan_paragraph'),
  icon: 'fa-camera',
  statusColor: 'primary',
  buttonText: polyglot.t('prechecks_guidedlaunch_begin_scan_button'),
};

export default GuidedLaunchBeginScanCard;
