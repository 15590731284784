class AssignedStartsUserEditor {
  constructor() {
    this.roleSelect = document.querySelector(
      '[data-element="user-role-select"]'
    );
    this.formFields = document.querySelector('[data-form="assigned-starts"]');

    this.handleChange = this.handleChange.bind(this);
  }

  get isExamProctor() {
    return (
      this.roleSelect.options[this.roleSelect.selectedIndex].text ===
      'Exam proctor'
    );
  }

  get formFieldsExist() {
    return !!this.formFields;
  }

  init() {
    // Bail out if the assigned starts forms don't exist on the page
    if (!this.formFieldsExist) return;
    this.handlePageLoad();
    this.bindEventListeners();
  }

  handlePageLoad() {
    this.handleChange();
  }

  bindEventListeners() {
    this.roleSelect.addEventListener('change', this.handleChange);
  }

  handleChange() {
    if (this.isExamProctor) {
      this.showAssignedStartsFields();
    } else {
      this.hideAssignedStartsFields();
    }
  }

  showAssignedStartsFields() {
    this.formFields.classList.remove('d-none');
  }

  hideAssignedStartsFields() {
    this.formFields.classList.add('d-none');
  }
}

export default AssignedStartsUserEditor;
