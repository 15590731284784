import PropTypes from 'prop-types';
import Callbacks from "../../../src/utils/Callbacks.js"
import IncidentsDashboard from './IncidentsDashboard'
import IncidentsModel from './IncidentsModel'

class IncidentsController extends React.Component {
  constructor(props) {
    super(props)

    this.dataStore = null
    this.callbacks = new Callbacks()

    this.state = {
      isDataStoreReady: false,
      incidents: [],
      processing: false,
      deleteCandidates: [],
      showEventFeed: false,
    }
  }

  componentDidMount() {
    const { userId, fulfillmentId, eventsPath, streamHost, timezone, examStatus, expanded, showTopRightButton } = this.props

    const paramsToIncidentsModel = {
      userId,
      fulfillmentId,
      streamHost,
      onIncidentsStoreChanged: () => { this.updateState() },
      callbacks: this.callbacks,
      eventsPath,
      timezone,
      examStatus
    }

    this.createDataModel(paramsToIncidentsModel)
      .then(() => {
        this.updateState();
      })
      .catch(error => console.error("Error. physicalEvents WS closed.", error))
  }

  createDataModel = (paramsToIncidentsModel) => {
    return new Promise(resolve => {
      this.dataStore = new IncidentsModel(paramsToIncidentsModel, resolve)
    })
  }

  transformStore = ({ type, params }) => {
    this.dataStore[type].call(this.dataStore, params)
    this.updateState()
  }

  updateState() {
    const incidentsByType = this.dataStore.getIncidentsByType()
    this.setState({
      incidents: [...incidentsByType],
      processing: this.dataStore.processing,
      deleteCandidates: this.dataStore.deleteCandidates,
      isDataStoreReady: true,
      showEventFeed: this.dataStore.getShowEventFeed()
    })
  }

  render() {
    const { incidents,
      deleteCandidates,
      processing,
      showEventFeed
    } = this.state
    return (
      <div>
        <IncidentsDashboard
          callbacks={ this.callbacks }
          incidentsByType={ incidents }
          deleteCandidates={ deleteCandidates }
          transformStore={ this.transformStore }
          gMap={ this.props.center }
          examStartedAt={ this.props.examStartedAt }
          processing={ processing }
          timezone={ this.props.timezone }
          showEventFeed={ showEventFeed }
          previousFulfillment={ this.props.previousFulfillment }
          scheduledStart={ this.props.scheduledStart }
          fulfillmentId={ this.props.fulfillmentId}
          showTopRightButton={ this.props.showTopRightButton }
          showSecurityTab={ this.props.showSecurityTab }
          institutionUser={ this.props.institutionUser }
        />
      </div>
    )
  }
}

IncidentsController.propTypes = {
  center: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired,
  fulfillmentId: PropTypes.string.isRequired,
  eventsPath: PropTypes.string.isRequired,
  streamHost: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
  examStartedAt: PropTypes.string,
  examStatus: PropTypes.string,
  showEventFeed: PropTypes.func,
  previousFulfillment: PropTypes.bool.isRequired,
  scheduledStart: PropTypes.string.isRequired,
  showSecurityTab: PropTypes.bool.isRequired,
}

export default IncidentsController
