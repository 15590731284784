import PropTypes from 'prop-types'
import Spinner from '../../GenericSpinner';
class TimeLine extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      events: [],
      loading: true,
      onFulfillmentsPage: this.checkUrl()
    }
  }

  componentDidMount() {
    let id = this.props.fulfillment.id
  }

  componentDidUpdate(prevProps) {
    if (prevProps.event !== this.props.event) {
      let events = this.state.events.slice()
      events.unshift(this.props.event)
      this.setState({ events: events })
    }
  }

  checkUrl = () => window.location.pathname.indexOf('fulfillments') > 0

  handleData = (data) => {
    this.setState({
      events: data.events,
      loading: false
    })
  }

  render() {
    let timelineEvents

    if (this.state.loading) {
      timelineEvents = <Spinner />;
    } else {
      if (this.state.events.length > 0) {
        timelineEvents = this.state.events.map(function(event) {
          return <TimelineEvent key={event.id} event={event} />
        })
      } else {
        timelineEvents = (
          <div className="well m-2">No recorded events yet.</div>
        )
      }
    }

    return (
      <div className="card">
        <div className="card-header collapse-button">
          <h4 className="card-title">
            <span className='mr-2'><i className="fa fa-minus"></i></span>
            Activity
          </h4>
        </div>
        <div className="panel-collapse collapse show">
          <div className="card-body p-0">
            <ul className="list-unstyled timeline list-group" id="js-timeline">
              {timelineEvents}
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

TimeLine.propTypes = {
  event: PropTypes.object,
  fulfillment: PropTypes.object.isRequired
}

export default TimeLine
